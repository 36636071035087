import { useSelector } from "react-redux";
import ProfileSettings from "./ProfileSettings";
import Card from "../../../components/card/Card";
import React, { useEffect, useState } from "react";
import { getEmailId } from "../../../utils/localStorageIndex";
import { fetchPersonalInfo } from "../../../services/sharedServices";
import { Box, Text, SimpleGrid, Flex, HStack } from "@chakra-ui/react";

// Custom components
import Banner from "./components/Banner";
import General from "./components/General";

// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";

function AdminProfile() {
  const emaiId = getEmailId();
  const [userDetails, setUserDetails] = useState({});
  const [currentPage, setCurrentPage] = useState("view");
  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { profileLogoFlag } = sideMenuStatus;

  useEffect(() => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then((res) => {
        if (res.result) {
          setUserDetails(res.data[0]);
          setCurrentPage("view");
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  }, [profileLogoFlag]);

  function renderFormattedString(strtemp) {
    var finalString = "...";
    if (strtemp.trim().length > 0) {
      finalString = strtemp;
    }
    return finalString;
  }

  function renderFullName() {
    var fullName = null;
    if (
      userDetails.first_name !== null &&
      userDetails.first_name !== undefined &&
      userDetails.last_name !== null &&
      userDetails.last_name !== undefined
    ) {
      fullName = renderFormattedString(userDetails.first_name);
      fullName = fullName + "  " + renderFormattedString(userDetails.last_name);
      return fullName;
    } else {
      return "...";
    }
  }

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card w={"100%"}>
          <Flex w={"100%"} justifyContent={"end"}>
            <Flex borderWidth={"1px"} borderRadius={"10px"}>
              <HStack
                w={"100px"}
                borderTopLeftRadius={"10px"}
                borderBottomLeftRadius={"10px"}
                _hover={{ cursor: "pointer" }}
                onClick={() => setCurrentPage("view")}
                bg={currentPage === "view" ? "gray.200" : "gray.500"}
                boxShadow={currentPage === "view" ? "md" : ""}
                h={"40px"}
                m={0}
                px={2}
                fontSize={"18px"}
              >
                <Text w="100%" textAlign="center">
                  View
                </Text>
              </HStack>
              <HStack
                w={"100px"}
                bg={currentPage === "edit" ? "gray.200" : "gray.500"}
                borderTopRightRadius={"10px"}
                borderBottomRightRadius={"10px"}
                _hover={{ cursor: "pointer" }}
                onClick={() => setCurrentPage("edit")}
                boxShadow={currentPage === "edit" ? "md" : ""}
                h={"40px"}
                px={2}
                fontSize={"18px"}
              >
                <Text w="100%" textAlign="center">
                  Edit
                </Text>
              </HStack>
            </Flex>
          </Flex>
        </Card>
        {currentPage === "view" ? (
          <SimpleGrid
            mt={5}
            mb="20px"
            columns={{ sm: 1, lg: 2 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <Flex direction="column">
              <Banner
                gridArea="1 / 1 / 2 / 2"
                banner={banner}
                avatar={userDetails.photo_url ? userDetails.photo_url : avatar}
                name={renderFullName()}
                job={
                  userDetails.role !== null && userDetails.role !== undefined
                    ? userDetails.role
                    : "..."
                }
                email={
                  userDetails.email !== null && userDetails.email !== undefined
                    ? userDetails.email
                    : "..."
                }
                phone={
                  userDetails.phone_number !== null &&
                  userDetails.phone_number !== undefined
                    ? userDetails.phone_number
                    : "..."
                }
                join_date={userDetails.date}
                social_about={userDetails.social_about}
              />
            </Flex>
            {/* Column Right */}
            <Flex direction="column">
              <General
                gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
                minH="365px"
                pe="20px"
              />
            </Flex>
          </SimpleGrid>
        ) : null}
        {currentPage === "edit" ? <ProfileSettings /> : null}
      </Box>
    </>
  );
}

export default AdminProfile;

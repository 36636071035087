import React, { useEffect, useState } from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { getPageConfig } from "../../services/sharedServices";

export default function Footer(props) {
  const textColor = useColorModeValue("gray.400", "white");
  const { toggleColorMode } = useColorMode();
  const [name, setName] = useState(null)
  const [version, setVersion] = useState(null)
  const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;


  useEffect(() => {
		if (subdomain != null) {
			let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
			fetchConfDetails(subdomain_val);
		}
	}, []);

	const fetchConfDetails = (sub_domain) => {
		let paramObj = {
			subdomain: sub_domain,
		};
		getPageConfig(paramObj)
			.then((res) => {
				if (res?.data?.length > 0) {
					if(res?.data[0]?.info?.name) setName(res?.data[0]?.info?.name)
					if(res?.data[0]?.info?.version) setVersion(res?.data[0]?.info?.version)

				}
			})
			.catch((err) => {
				// Do Nothing
			});
	};
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px={{ base: "30px", md: "50px" }}
      pb="30px"
      p={props?.padding ? props?.padding : null}
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          { name ? name:'Lingolet AI'} (v {version}), all rights reserved.
          <Link
            mx="3px"
            color={textColor}
            href = { window.location.hostname === "lspcloud.ai" ? "https://lspcloud.ai":"https://lingolet.ai"}
            target="_blank"
            fontWeight="700"
          >
             { window.location.hostname === "lingolet.ai"  && 'Lingolet Connect'}
          </Link>
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="mailto:support@lingolet.ai"
          >
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://lingolet.ai/licenses"
          >
            License
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://lingolet.ai/terms-of-service"
          >
            Terms of Use
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href="https://lingolet.ai/blogs"
          >
            Blog
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}

// React Imports
import React, { useState, useEffect } from "react";
import {
	Input,
	Text,
	Flex,
	Spacer,
	Box,
	Button,
	useToast,
	useColorModeValue
} from "@chakra-ui/react";

// Component Imports
import Card from "../../../components/card/Card";
import { useBrandBg } from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Services
import { getEmailId } from "../../../utils/localStorageIndex";
import { addSubDomainForAccountAdmin, delSubDomainForAccountAdmin,getBusinessDetailsForAccountAdmin } from "../../../services/accountServices";

function SubDomainSetup() {
	const toast = useToast();
	// useColorModeValue(Light, Dark)
	const brandBg = useBrandBg()
	const textInputColor = useColorModeValue("gray.700", "gray.100");
	const textColor = useColorModeValue("gray.700", "gray.800");
	const contentBg = useColorModeValue("blue.100", "gray.400");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const [subdomain, setSubDomain] = useState("");
	const [subdomainValue, setSubDomainValue] = useState("customsubdomain")
	const [errMessage, setErrMessage] = useState('');

	useEffect(() => {
		fetchBusinessDetails();
	}, []);

	// Todo:Check Business logo and  background
	const fetchBusinessDetails = () => {
		var apiData = { email: getEmailId() };
		getBusinessDetailsForAccountAdmin(apiData)
        .then(res => {
                console.log(res)
				if (res.result && res.data[0] !== null && res.data.length) {
					setSubDomain(res.data[0].subdomain)
				} else {
				}
			})
			.catch(err => {
			});
	};

	// Todo:Check First Character
	const handleSubdomainChange = e => {
		const newValue = e.target.value;
		if (/^\d/.test(newValue)) {
			setErrMessage("Sub Domain cannot start with number.");
		} else {
			setErrMessage("");
			setSubDomainValue(newValue);
		}
	};

	// Todo:Store Subdomain Data
	const storeSubDomainData = () => {
		let obj = { subdomain: subdomain };
		addSubDomainForAccountAdmin(obj)
			.then(res => {
				if (res?.result) {
					if (res?.data?.length > 0) {
						if (res?.data[0]?.status) {
							toast(toastFunctionToaster(res?.message, 'success'));
							fetchBusinessDetails()
						} else {
							toast(toastFunctionToaster(res?.message, 'error'));
						}
					} else {
						toast(toastFunctionToaster("There is an error while setting your business", "error"));
					}
				} else {
					toast(toastFunctionToaster("Unable to setup the subdomain for your business", "error"));
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	// Todo:Remove Subdomain Data
	const DeleteSubDomainFunc = (sub_domain) => {
		let obj = { subdomain: sub_domain };
		delSubDomainForAccountAdmin(obj)
			.then(res => {
				if (res?.result) {
					if (res?.data?.length > 0) {
						if (res?.data[0]?.status) {
							toast(toastFunctionToaster(res?.message, 'success'));
							fetchBusinessDetails()
						} else {
							toast(toastFunctionToaster(res?.message, 'error'));
						}
					} else {
						toast(toastFunctionToaster("There is an error while setting your subdomain", "error"));
					}
				} else {
					toast(toastFunctionToaster("Unable to setup the subdomain for your business", "error"));
				}
			})
			.catch(err => {
				console.log(err);
			});
	};


	return (
		<Flex w="100%" h="70vh" position="relative" p="3" borderRadius="15px" bg={brandBg} ml="2" overflow={'hidden'} overflowY={'auto'} mt={"24"}>
			{subdomain ? (
				<Card w={"100%"} mr="3">
					<Flex w={'100%'}>
						<Text my={5}>Your Subdomain:</Text>
						<Text my={5} ml={2} fontWeight={500}>{subdomain}</Text>
					</Flex>
				</Card>
			) : <Card w={"100%"} mr="3">
				<Text my={5}>You haven't set your own subdomain yet...</Text>
			</Card>}
			{subdomain ? (
				<Card w={"100%"}>
					<Flex w={'100%'} direction={'column'}>
						<Text my={5}>Your have already setup you subdomain as below:</Text>
						<Flex w={'100%'} align={'center'}>
							<Text my={5} ml={2} fontWeight={500} rounded={'md'}
								p={2} color={textColor} bg={contentBg}>{subdomain}</Text>
							<Spacer />
							<Button
								colorScheme="red"
								onClick={() => DeleteSubDomainFunc(subdomain)}
								size={'md'}>
								Delete Subdomain
							</Button>
						</Flex>
						<Text mt={5} fontSize={'xs'}>To Change your subdomain again, please contact support.</Text>
					</Flex>
				</Card>
			) : <Flex w={"100%"}>
				<Card w={"100%"} p={5} py={10}>
					<Box w={"100%"}>
						<Text textAlign={"center"} fontSize={"20px"} fontWeight={"600"}>
							Add your custom subdomain
						</Text>
						<Text textAlign={"center"} fontSize={"20px"} fontWeight={"600"}>
							{subdomainValue}.{window?.location?.hostname}
						</Text>
					</Box>
					<Box my="3" w="80%" mx="auto">
						<Input
							type="text"
							placeholder="Sub Domain"
							color={textInputColor}
							maxLength="20"
							value={subdomainValue}
							onChange={handleSubdomainChange}></Input>
						<Text color="red.500" fontSize="13px" mt="2">
							Note* Maximum length 20 chars + numbers (can not start with number, can end with number)
						</Text>
						<Text color="red.600" mt="1" fontSize="13px" fontWeight={"600"}>
							{errMessage}
						</Text>
					</Box>
					<Box w="100%" textAlign={"right"}>
						<Button
							size="sm"
							bg={pillButtonBg}
							//colorScheme={green}
							isDisabled={!subdomainValue?.length ? true : false}
							onClick={() => storeSubDomainData()}>
							Set Custom Subdomain
						</Button>
					</Box>
				</Card>
			</Flex>}
		</Flex>
	);
}

export default SubDomainSetup;

import React, { useState } from 'react';
import { Box, Input, Text, Button, Switch, VStack, useToast } from '@chakra-ui/react';
import { toastFunctionToaster } from '../../../../utils/toastFunction';
import { manageLingoletIdBank } from '../../../../services/accountServices';
import { INFO_TYPES } from './constants';

const BankInfo = ({ bankList, handleEdit }) => {
  const [editableBanks, setEditableBanks] = useState(bankList);
  const toast = useToast();

  const handleInputChange = (e, index, field) => {
    const updatedBanks = [...editableBanks];
    updatedBanks[index][field] = e.target.value;
    setEditableBanks(updatedBanks);
  };

  const handleToggleChange = async (e, index, field) => {
    try {
      const updatedBanks = [...editableBanks];
      updatedBanks[index][field] = e.target.checked;

      const params = {
        identity_uuid: updatedBanks[index].identity_uuid,
        identity_bank_uuid: updatedBanks[index].identity_bank_uuid
      }

      const response = await manageLingoletIdBank(params);
      if (response?.data[0]?.status) {
        setEditableBanks(updatedBanks);
        toast(toastFunctionToaster("Updated successfully", "success"));
      } else {
        toast(toastFunctionToaster("Failed to update", "error"));
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to update", "error"));      
    }
  };

  return (
    <>
      {editableBanks.length > 0 ? (
        editableBanks.map((bank, index) => (
          <Box key={bank.identity_bank_uuid} id={`${bank.identity_uuid}-${bank.identity_bank_uuid}`} mt={4} p={4} border="1px" borderColor="gray.200" borderRadius="md">
            <Text fontWeight="bold" mb={2}>
              Bank Information {index + 1}
            </Text>

            <VStack align="start" spacing={2}>
              <Text><strong>Bank Name:</strong></Text>
              <Input value={bank.identity_bank_name} onChange={(e) => handleInputChange(e, index, 'identity_bank_name')} />

              <Text><strong>Location:</strong></Text>
              <Input value={bank.identity_bank_location || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_location')} />

              <Text><strong>Branch:</strong></Text>
              <Input value={bank.identity_bank_branch || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_branch')} />

              <Text><strong>Address:</strong></Text>
              <Input value={bank.identity_bank_address || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_address')} />

              <Text><strong>Account Type:</strong></Text>
              <Input value={bank.identity_bank_account_type} onChange={(e) => handleInputChange(e, index, 'identity_bank_account_type')} />

              <Text><strong>Account Description:</strong></Text>
              <Input value={bank.identity_bank_account_description || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_account_description')} />

              <Text><strong>Account Number:</strong></Text>
              <Input value={bank.identity_bank_account_number} onChange={(e) => handleInputChange(e, index, 'identity_bank_account_number')} />

              <Text><strong>SFT/Routing Number:</strong></Text>
              <Input value={bank.identity_bank_sft_routing_transit || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_sft_routing_transit')} />

              <Text><strong>SWIFT:</strong></Text>
              <Input value={bank.identity_bank_account_swift || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_account_swift')} />

              <Text><strong>IBAN:</strong></Text>
              <Input value={bank.identity_bank_account_iban || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_account_iban')} />

              <Text><strong>Currency Code:</strong></Text>
              <Input value={bank.identity_bank_currency_code || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_currency_code')} />

              <Text><strong>Currency Symbol:</strong></Text>
              <Input value={bank.identity_bank_currency_symbol || ''} onChange={(e) => handleInputChange(e, index, 'identity_bank_currency_symbol')} />

              <Text><strong>Active:</strong></Text>
              <Switch isChecked={bank.identity_bank_active} onChange={(e) => handleToggleChange(e, index, 'identity_bank_active')} />
            </VStack>

            <Button mt={4} colorScheme="blue" onClick={() => handleEdit(INFO_TYPES.BANK,editableBanks[index])}>
              Save
            </Button>
          </Box>
        ))
      ) : (
        <Text mt={2}>No bank information available</Text>
      )}
    </>
  );
};

export default BankInfo;

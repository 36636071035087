
const AmazonPolyTranslateList = [
    {
        "Language": "Arabic",
        "LanguageCode": "arb",
        "Voices": [
            { "Name": "Zeina", "Gender": "Female" },
        ]
    },
    {
        "Language": "Arabic (Gulf)",
        "LanguageCode": "ar-AE",
        "Voices": [
            { "Name": "Hala", "Gender": "Female" },
            { "Name": "Zayd", "Gender": "Male" },
        ]
    },
    {
        "Language": "Dutch (Belgian)",
        "LanguageCode": "nl-BE",
        "Voices": [
            { "Name": "Lisa", "Gender": "Female" },
        ]
    },
    {
        "Language": "Catalan",
        "LanguageCode": "ca-ES",
        "Voices": [
            { "Name": "Arlet", "Gender": "Female" },
        ]
    },
    {
        "Language": "Chinese (Cantonese)",
        "LanguageCode": "yue-CN",
        "Voices": [
            { "Name": "Hiujin", "Gender": "Female" },
        ]
    },
    {
        "Language": "Chinese (Mandarin)",
        "LanguageCode": "cmn-CN",
        "Voices": [
            { "Name": "Zhiyu", "Gender": "Female" },
        ]
    },
    {
        "Language": "Danish",
        "LanguageCode": "da-DK",
        "Voices": [
            { "Name": "Naja", "Gender": "Female" },
            { "Name": "Mads", "Gender": "Male" },
            { "Name": "Sofie", "Gender": "Female" },
        ]
    },
    {
        "Language": "Dutch",
        "LanguageCode": "nl-NL",
        "Voices": [
            { "Name": "Laura", "Gender": "Female" },
            { "Name": "Lotte", "Gender": "Female" },
            { "Name": "Ruben", "Gender": "Male" },
        ]
    },
    {
        "Language": "English (Australian)",
        "LanguageCode": "en-AU",
        "Voices": [
            { "Name": "Nicole", "Gender": "Female" },
            { "Name": "Olivia", "Gender": "Female" },
            { "Name": "Russell", "Gender": "Male" },
        ]
    },
    {
        "Language": "English (British)",
        "LanguageCode": "en-GB",
        "Voices": [
            { "Name": "Amy", "Gender": "Female" },
            { "Name": "Emma", "Gender": "Female" },
            { "Name": "Brian", "Gender": "Male" },
            { "Name": "Arthur", "Gender": "Male" },
        ]
    },
    {
        "Language": "English (Indian)",
        "LanguageCode": "en-IN",
        "Voices": [
            { "Name": "Aditi", "Gender": "Female" },
            { "Name": "Raveena", "Gender": "Female" },
            { "Name": "Kajal", "Gender": "Female" },
        ]
    },
    {
        "Language": "English (Ireland)",
        "LanguageCode": "en-IE",
        "Voices": [
            { "Name": "Niamh", "Gender": "Female" },
        ]
    },
    {
        "Language": "English (New Zealand)",
        "LanguageCode": "en-NZ",
        "Voices": [
            { "Name": "Aria", "Gender": "Female" },
        ]
    },
    {
        "Language": "English (South African)",
        "LanguageCode": "en-ZA",
        "Voices": [
            { "Name": "Ayanda", "Gender": "Female" },
        ]
    },
    {
        "Language": "English (US)",
        "LanguageCode": "en-US",
        "Voices": [
            { "Name": "Danielle", "Gender": "Female" },
            { "Name": "Gregory", "Gender": "Male" },
            { "Name": "Ivy", "Gender": "Female" },
            { "Name": "Joanna", "Gender": "Female" },
            { "Name": "Kendra", "Gender": "Female" },
            { "Name": "Kimberly", "Gender": "Female" },
            { "Name": "Salli", "Gender": "Female" },
            { "Name": "Joey", "Gender": "Male" },
            { "Name": "Justin", "Gender": "Male" },
            { "Name": "Kevin", "Gender": "Male" },
            { "Name": "Matthew", "Gender": "Male" },
            { "Name": "Ruth", "Gender": "Female" },
            { "Name": "Stephen", "Gender": "Male" },
        ]
    },
    {
        "Language": "English (Welsh)",
        "LanguageCode": "en-GB-WLS",
        "Voices": [
            { "Name": "Geraint", "Gender": "Male" },
        ]
    },
    {
        "Language": "Finnish",
        "LanguageCode": "fi-FI",
        "Voices": [
            { "Name": "Suvi", "Gender": "Female" },
        ]
    },
    {
        "Language": "French",
        "LanguageCode": "fr-FR",
        "Voices": [
            { "Name": "Celine", "Gender": "Female" },
            { "Name": "Léa", "Gender": "Female" },
            { "Name": "Mathieu", "Gender": "Male" },
            { "Name": "Rémi", "Gender": "Male" },
        ]
    },
    {
        "Language": "French (Belgian)",
        "LanguageCode": "fr-BE",
        "Voices": [
            { "Name": "Isabelle", "Gender": "Female" },
        ]
    },
    {
        "Language": "French (Canadian)",
        "LanguageCode": "fr-CA",
        "Voices": [
            { "Name": "Chantal", "Gender": "Female" },
            { "Name": "Gabrielle", "Gender": "Female" },
            { "Name": "Liam", "Gender": "Male" },
        ]
    },
    {
        "Language": "German",
        "LanguageCode": "de-DE",
        "Voices": [
            { "Name": "Marlene", "Gender": "Female" },
            { "Name": "Vicki", "Gender": "Female" },
            { "Name": "Hans", "Gender": "Male" },
            { "Name": "Daniel", "Gender": "Male" },
        ]
    },
    {
        "Language": "German (Austrian)",
        "LanguageCode": "de-AT",
        "Voices": [
            { "Name": "Hannah", "Gender": "Female" },
        ]
    },
    {
        "Language": "Hindi",
        "LanguageCode": "hi-IN",
        "Voices": [
            { "Name": "Aditi", "Gender": "Female" },
            { "Name": "Kajal", "Gender": "Female" },
        ]
    },
    {
        "Language": "Icelandic",
        "LanguageCode": "is-IS",
        "Voices": [
            { "Name": "Dora", "Gender": "Female" },
            { "Name": "Karl", "Gender": "Male" },
        ]
    },
    {
        "Language": "Italian",
        "LanguageCode": "it-IT",
        "Voices": [
            { "Name": "Carla", "Gender": "Female" },
            { "Name": "Bianca", "Gender": "Female" },
            { "Name": "Giorgio", "Gender": "Male" },
            { "Name": "Adriano", "Gender": "Male" },
        ]
    },
    {
        "Language": "Japanese",
        "LanguageCode": "ja-JP",
        "Voices": [
            { "Name": "Mizuki", "Gender": "Female" },
            { "Name": "Takumi", "Gender": "Male" },
            { "Name": "Kazuha", "Gender": "Female" },
            { "Name": "Tomoko", "Gender": "Female" },
        ]
    },
    {
        "Language": "Korean",
        "LanguageCode": "ko-KR",
        "Voices": [
            { "Name": "Seoyeon", "Gender": "Female" },
        ]
    },
    {
        "Language": "Norwegian",
        "LanguageCode": "nb-NO",
        "Voices": [
            { "Name": "Liv", "Gender": "Female" },
            { "Name": "Ida", "Gender": "Female" },
        ]
    },
    {
        "Language": "Polish",
        "LanguageCode": "pl-PL",
        "Voices": [
            { "Name": "Ewa", "Gender": "Female" },
            { "Name": "Maja", "Gender": "Female" },
            { "Name": "Jacek", "Gender": "Male" },
            { "Name": "Jan", "Gender": "Male" },
            { "Name": "Ola", "Gender": "Female" },
        ]
    },
    {
        "Language": "Portuguese (Brazilian)",
        "LanguageCode": "pt-BR",
        "Voices": [
            { "Name": "Camila", "Gender": "Female" },
            { "Name": "Vitoria", "Gender": "Female" },
            { "Name": "Ricardo", "Gender": "Male" },
            { "Name": "Thiago", "Gender": "Male" },
        ]
    },
    {
        "Language": "Portuguese (European)",
        "LanguageCode": "pt-PT",
        "Voices": [
            { "Name": "Ines ", "Gender": "Female" },
            { "Name": "Cristiano", "Gender": "Male" },
        ]
    },
    {
        "Language": "Romanian",
        "LanguageCode": "ro-RO",
        "Voices": [
            { "Name": "Ines ", "Carmen": "Female" },
        ]
    },
    {
        "Language": "Russian",
        "LanguageCode": "ru-RU",
        "Voices": [
            { "Name": "Tatyana ", "Carmen": "Female" },
            { "Name": "Maxim ", "Carmen": "Male" },
        ]
    },
    {
        "Language": "Spanish (European)",
        "LanguageCode": "es-ES",
        "Voices": [
            { "Name": "Conchita ", "Carmen": "Female" },
            { "Name": "Lucia ", "Carmen": "Female" },
            { "Name": "Enrique ", "Carmen": "Male" },
            { "Name": "Sergio ", "Carmen": "Male" },
        ]
    },
    {
        "Language": "Spanish (Mexican)",
        "LanguageCode": "es-MX",
        "Voices": [
            { "Name": "Mia ", "Carmen": "Female" },
            { "Name": "Andrés ", "Carmen": "Male" },

        ]
    },
    {
        "Language": "Spanish (US)",
        "LanguageCode": "es-US",
        "Voices": [
            { "Name": "Lupe", "Carmen": "Female" },
            { "Name": "Penelope ", "Carmen": "Female" },
            { "Name": "Miguel ", "Carmen": "Male" },
            { "Name": "Pedro ", "Carmen": "Male" },

        ]
    },
    {
        "Language": "Swedish",
        "LanguageCode": "sv-SE",
        "Voices": [
            { "Name": "Astrid", "Carmen": "Female" },
            { "Name": "Elin ", "Carmen": "Female" },
        ]
    },
    {
        "Language": "Turkish",
        "LanguageCode": "tr-TR",
        "Voices": [
            { "Name": "Filiz", "Carmen": "Female" },
            { "Name": "Burcu ", "Carmen": "Female" },
        ]
    },
    {
        "Language": "Welsh",
        "LanguageCode": "cy-GB",
        "Voices": [
            { "Name": "Gwyneth", "Carmen": "Female" },
        ]
    },
]

export default AmazonPolyTranslateList;
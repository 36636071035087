import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
    Button,
    Flex,
    Text,
    useToast,
    FormControl,
    useColorModeValue,
  } from '@chakra-ui/react';
  import Card from '../../../../components/card/Card.js';
  import InputField from '../../../../components/fields/InputField';
  import { getEmailId } from '../../../../utils/localStorageIndex';
  import {
    updateUserInfo,
    fetchPersonalInfo,
  } from '../../../../services/sharedServices';
  
  export default function Settings() {
    // Todo:Defined States
    const emaiId = getEmailId();
    const toast = useToast();
    const navigate = useNavigate();
    const [loading, isLoading] = useState(false);
    const [updateInfoObj, setUpdateInfoObj] = useState({
      social_github: '',
      social_linkedin: '',
      social_twitter: '',
      email: emaiId,
      group: '',
    });
  
    // Todo:Fetch User Details
    useEffect(() => {
      fetchUserDetails();
    }, []);
  
    const fetchUserDetails = () => {
      let objData = {
        email: emaiId,
      };
      fetchPersonalInfo(objData)
        .then(res => {
          if (res.result) {
            setUpdateInfoObj(res.data[0]);
            isLoading(false);
          } else {
            isLoading(false);
          }
        })
        .catch(err => {
          isLoading(false);
          if (err) {
          }
        });
    };
  
    // on change update form value fields
    const onUpdateField = e => {
      const nextFormState = {
        ...updateInfoObj,
        [e.target.name]: e.target.value,
      };
      setUpdateInfoObj(nextFormState);
    };
  
    // Todo:Save User Details
    const submitUserData = event => {
      event.preventDefault();
      updateInfoObj.email = emaiId;
      isLoading(true);
      updateUserInfo(updateInfoObj)
        .then(res => {
          if (res.result) {
            isLoading(false);
            if (updateInfoObj.group === '100' || updateInfoObj.group === '200'){
              navigate('/admin/profile');
            } else  if (updateInfoObj.group === '300' || updateInfoObj.group === '400'){
              navigate('/business/profile');
            }
            toastFunction(res.message, 'success');
          } else {
            isLoading(false);
            toastFunction(res.error, 'success');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction(err.message, 'success');
        });
    };
  
    // Todo:Toaster Function
    const toastFunction = (message, status) => {
      return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
      });
    };
  
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    return (
      <FormControl>
        <Card mb="20px" pb="50px">
          <Flex direction="column" mb="40px" ms="10px">
            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
              Social Profiles
            </Text>
            <Text fontSize="md" color={textColorSecondary}>
              Here you can set user social profiles
            </Text>
          </Flex>
          <InputField
            mb="25px"
            id="social_twitter"
            label="Twitter Username"
            placeholder="Twitter Username"
            value={updateInfoObj.social_twitter}
            name="social_twitter"
            onChange={onUpdateField}
          />
          <InputField
            mb="25px"
            id="social_linkedin"
            label="LinkedIn Username"
            placeholder="LinkedIn Username"
            value={updateInfoObj.social_linkedin}
            name="social_linkedin"
            onChange={onUpdateField}
          />
          <InputField
            mb="25px"
            id="github_username"
            label="Github Username"
            placeholder="Github Username"
            value={updateInfoObj.social_github}
            name="social_github"
            onChange={onUpdateField}
          />
          <Button
            variant="brand"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            ms="auto"
            onClick={e => {
              submitUserData(e);
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Save changes
          </Button>
        </Card>
      </FormControl>
    );
  }
  
import React from "react";
import { BiUserCircle, BiMoney, BiDollarCircle } from "react-icons/bi";
import { Icon } from "@chakra-ui/react";
import {
  AiOutlineSetting,
  AiOutlineDashboard,
  AiOutlineMobile,
  AiOutlineSelect,
  AiOutlineLink,
  AiOutlineUser,
  AiOutlineCar,
  AiFillCreditCard,
  AiOutlineGift,
  AiOutlineSecurityScan,
  AiOutlineUserSwitch,
  AiOutlineUserAdd,
  AiOutlineOrderedList,
  AiOutlineBoxPlot,
  AiOutlineUngroup,
  AiOutlineTranslation,
} from "react-icons/ai"; 

// Business Componenr Imports
import SubscriberDashboard from "../komponents/subscriber/dashboard/SubscriberDashboard";
import SubscriberEvents from "../komponents/subscriber/dashboard/SubscriberEvents";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";

// Subscriber LingoLinks
import LinkSubscriberManage from "../komponents/subscriber/links/LinkSubscriberManage";
import LinkSubscriberCalls from "../komponents/subscriber/links/LinkSubscriberCalls";
import SubscriberInfo from "../komponents/subscriber/SubscriberInfo";
// import LinkCustomers from "../komponents/business/customers/LinkCustomers";
// import LinkCustomerCalls from "../komponents/business/customers/LinkCustomerCalls";
// import LinkCustomerManage from "../komponents/business/customers/LinkCustomerManage";


// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import LinkSubscriberTranscription from "../komponents/subscriber/links/LinkSubcriberTranscription";
import CardListing from "../komponents/subscriber/links/CardListing";
import CardUsage from "../komponents/subscriber/links/CardUsage";
import ApplicationSettings from "../komponents/common/ApplicationSettings";
import SubscriberSchedule from "../komponents/subscriber/SubscriberSchedule";

import SubLanguagePreference from "../komponents/subscriber/LanguagePreference";
import Sharing from "../komponents/subscriber/links/sharing/Sharing";
import LinkSubscriberLingoCardCallsHistory from "../komponents/subscriber/links/LinkSubscriberLingoCardCallsHistory";
import Reporting from "../komponents/subscriber/reporting/Reporting";
import { TbFileReport } from "react-icons/tb";
import ProjectListing from "../komponents/documentai/ProjectListing";
import ShareAccessControl from "../komponents/documentai/shareControl/ShareAccessControl";
import ContentList from "../komponents/documentai/shareControl/ContentList";
import ProjectContent from "../komponents/documentai/ProjectContent";
import ContentWorkflowMgmt from "../komponents/documentai/ContentWorkflowMgmt";
import ContentWorkflowResults from "../komponents/documentai/ContentWorkflowResult";



const subscriberRoutes = (isLingoCardEnabled = false, locationUuid=null, accountUuid=null, subsAppDetails = '') =>{
  const documentAiSupport = (subsAppDetails?.split(",")?.indexOf("di") >=0 ? true : false)
  return [
  {
    name: "Dashboard",
    path: "/subscriber",
    icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Dashboard",
        layout: "/subscriber",
        path: "/dashboard",
        component: <SubscriberDashboard />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
      },
      {
				name: "Subscriber Info",
				layout: "/subscriber",
				path: "/subscriberinfo",
				component: <SubscriberInfo />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
 
      {
        name: "Events",
        layout: "/subscriber",
        path: "/events",
        component: <SubscriberEvents />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineBoxPlot} width="20px" height="20px" />,
      },
    ],
  },
  {
    name: "Document AI",
    path: "/subscriber",
    icon: <Icon as={AiOutlineTranslation} width="25px" height="25px" />,
    collapse: true,
    isHidden: !documentAiSupport,
    items: [
      {
        name: "Projects",
        layout: "/subscriber",
        path: "/projects",
        component: <ProjectListing/>,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
      {
        name: "Share Control",
        layout: "/subscriber",
        path: "/project/share-control",
        component: <ShareAccessControl />,
        secondary: true,
        showToMenu: false,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
      {
        name: "Contents",
        layout: "/subscriber",
        path: "/project/content/list",
        component: <ContentList/>,
        secondary: true,
        showToMenu: false,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
      {
        name: "Content",
        layout: "/subscriber",
        path: "/project/content",
        component: <ProjectContent />,
        secondary: true,
        showToMenu: false,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
      {
        name: "Workflow",
        layout: "/subscriber",
        path: "/project/content/workflow",
        component: <ContentWorkflowMgmt />,
        secondary: true,
        showToMenu: false,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
      {
        name: "Workflow",
        layout: "/subscriber",
        path: "/project/content/workflow_result",
        component: <ContentWorkflowResults />,
        secondary: true,
        showToMenu: false,
        icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
      },
    ],
  },
  {
    name: "LingoLink",
    path: "/subscriber",
    icon: <Icon as={AiOutlineLink} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
				name: "LingoLink",
				layout: "/subscriber",
				path: "/lingolinklisting",
				component: <LinkSubscriberManage />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Transcripts",
				layout: "/subscriber",
				path: "/transcripts",
				component: <LinkSubscriberTranscription/>,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Call History",
				layout: "/subscriber",
				path: "/callhistory",
				component: <LinkSubscriberCalls />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "LingoCard Calls",
				layout: "/subscriber",
				path: "/subscribercalls",
				component: <LinkSubscriberLingoCardCallsHistory />,
				secondary: true,
				showToMenu: isLingoCardEnabled,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "LingoCard",
				layout: "/subscriber",
				path: "/lingocardlisting",
				component: <CardListing accountUuid = {accountUuid} locationUuid={locationUuid} isLingoCardEnabled={isLingoCardEnabled} />,
				secondary: true,
				showToMenu: isLingoCardEnabled,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "LingoCard Usage",
				layout: "/subscriber",
				path: "/lingocard/usage",
				component: <CardUsage/> ,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Schedule",
				layout: "/subscriber",
				path: "/schedule",
				component: <SubscriberSchedule/>,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Language Preference",
				layout: "/subscriber",
				path: "/languagepreference",
				component: <SubLanguagePreference/> ,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Sharing",
				layout: "/subscriber",
				path: "/sharing",
				component: <Sharing/> ,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
    ]
  },
  {
    name: "Settings",
    path: "/subscriber",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
        // {
        //     name: "Team Members",
        //     layout: "/subscriber",
        //     path: "/team",
        //     component: <AccountTeam />,
        //     secondary: true,
        //     showToMenu: true,
        //     icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        // },
        {
            name: "Application settings",
            layout: "/subscriber",
            path: "/applicationsettings",
            component: <ApplicationSettings />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },        
        {
            name: "Profile",
            layout: "/subscriber",
            path: "/profile",
            component: <BusinessProfile />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
        }      
    ],
  },
  {
    name: "Help",
    path: "/subscriber",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Get Help",
        layout: "/subscriber",
        path: "/help",
        component: <Helper />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
      {
        name: "Compliance",
        layout: "/subscriber",
        path: "/trust",
        component: <PlatformTrust />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
    ],
  },
  {
		name: "Reporting",
		path: "/subscriber",
		icon: <Icon as={AiOutlineGift} width="25px" height="25px" />,
		collapse: true,
		items: [
		  {
			name: "Report",
			layout: "/subscriber",
			path: "/reporting",
			component: <Reporting />,
			secondary: true,
			showToMenu: true,
			icon: <Icon as={TbFileReport} width="20px" height="20px" />,
		  }
    ]
  }
].filter(item=> !item.isHidden)
}

export default subscriberRoutes;

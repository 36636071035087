import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";

const ReportingTable = ({ reports, onEdit }) => {
  return (
    <Table variant="striped">
      <Thead>
        <Tr>
          <Th>Schedule Type</Th>
          <Th>Schedule Date</Th>
          <Th>Status</Th>
          <Th>Reported</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {reports.map((report) => (
          <Tr key={report.reporting_uuid}>
            <Td>{report.schedule_type}</Td>
            <Td>{report.schedule_date}</Td>
            <Td>{report.is_done ? "Yes" : "No"}</Td>
            <Td>{report.status}</Td>
            <Td>
              <Button size="sm" onClick={() => onEdit(report)}>
                Edit
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ReportingTable;

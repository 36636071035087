// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner, useColorModeValue, Input, TableContainer, Heading, Menu, MenuButton, Button, MenuList, MenuItem, Tabs, TabList, Tab, TabPanels, TabPanel, FormControl, FormLabel, Switch, Spacer } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg, useGreenBg, useNoResultFoundText, usePillButtonBg, usePillButtonBgSelected, useRedBg, useTextColor } from "../../../../../theme/globalColorTheme";
import Card from "../../../../../components/card/Card";
import LocationAndSubscribers from "../../components/LocationAndSubscribers";
import moment from 'moment'
import LinkMetrics from "../../../../subscriber/dashboard/component/LinkMetrics";
import SubscriberEventMetrics from "../../../../subscriber/dashboard/component/SubscriberEventMetrics";
import { getSubsEventsMetrics, getSubsLinksMetric } from "../../../../../services/accountServices";
import { useEffect } from "react";

function LocationSubscriberDashboard({ locationList, setLocationList, locationLoading }) {
    const boostlingoExp = process.env.REACT_APP_BOOSTBOOST_EXP;
    const brandBg = useBrandBg();
    const [subscriber, setSubscriber] = useState(null);
    const [currentSelection, setCurrentSelection] = useState("links");
    const [loading, setLoading] = useState(true);
    const [eventLoading, setEventLoading] = useState(true);
    const [linkStats, setLinkStats] = useState([]);
    const [eventStats, setEventStats] = useState([]);
    const [durationValue, setDurationValue] = useState("all");


    const showExpDate = () => {
        let result = "error";
        if (boostlingoExp != null && boostlingoExp != undefined) {
            var expDate = JSON.parse(boostlingoExp);
            if (moment(expDate.expiresAt).isValid()) {
                result = moment(expDate.expiresAt).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
            }
        }
        return result;
    };

    const handleDurationType = type => {
        if (type?.length) {
            setDurationValue(type);
        }
    };

    const collectSubLinkCount = () => {
        if (!subscriber) return
        setLoading(true);
        let paramObj = {
            duration: durationValue,
            key: "location",
            subscriber_uuid: subscriber?.sub_uuid
        };
        getSubsLinksMetric(paramObj)
            .then(res => {
                if (res?.data?.length > 0) {
                    setLinkStats(res?.data);
                    setLoading(false);
                } else {
                    setLinkStats([]);
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const collectEventsCount = () => {
        if (!subscriber) return
        setEventLoading(true);
        let paramObj = {
            duration: durationValue,
            subscriber_uuid: subscriber?.sub_uuid
        };
        getSubsEventsMetrics(paramObj)
            .then(res => {
                if (res?.data?.length > 0) {
                    setEventStats(res?.data);
                    setEventLoading(false);
                } else {
                    setEventStats([]);
                    setEventLoading(false);
                }
            })
            .catch(err => {
                setEventLoading(false);
            });
    };

    function onSubscriberClick(_, subData) {
        setSubscriber(subData);
    }

    useEffect(() => {
        collectEventsCount()
        collectSubLinkCount()
    }, [subscriber])

    return (
        <Flex h='calc(100vh - 185px)'>
            <Flex
                flex="1"
                bg={brandBg}
                borderRadius={"10px"}
                h="full"
                p={1}
            >
                <Box w="full">
                    <Card p='2' h="full">
                        <Box h="full" overflow="hidden">
                            <Flex w={"100%"} direction={"column"}>
                                <Flex w={"100%"} py="1" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
                                    <Flex w={"100%"} direction={"column"}>
                                        <Text fontWeight={"500"} ml={1} fontSize={"18px"}>
                                            Dashboard
                                        </Text>
                                        <Text fontSize={"xs"} ml={1}>
                                            {showExpDate()}
                                        </Text>
                                    </Flex>
                                    <Spacer />
                                    <Flex minW={"100px"} maxW={"100px"} bg={usePillButtonBg} rounded={"md"} p={1} pl={2}>
                                        <Text
                                            _hover={{ cursor: "pointer" }}
                                            onClick={() => {
                                                // collectSubLinkCount();
                                                // setCurrentSelection("links");
                                            }}
                                            p={2}
                                            rounded={"md"}
                                            color={currentSelection === "links" ? "white" : useTextColor}
                                            bg={currentSelection === "links" ? usePillButtonBgSelected : usePillButtonBg}
                                        >
                                            LingoLink
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            {
                                !subscriber ? <Flex w={'100%'} p={2}>
                                    <Text w={'100%'} bg={'red.100'} py={3} color={useNoResultFoundText}>Select <b>Subscriber</b> from right side panel</Text>
                                </Flex> : <Box h="full" overflowY="auto" className="custom-scrollbar">
                                    {currentSelection === "links" ? (
                                        <LinkMetrics
                                            linkStats={linkStats}
                                            eventStats={eventStats}
                                            sendDurationType={handleDurationType}
                                            loadingValue={loading || eventLoading ? true : false}
                                            triggerOnClick={false}
                                             />
                                    ) : currentSelection === "workflows" ? (
                                        <SubscriberEventMetrics
                                            eventStats={eventStats} />
                                    ) : null}
                                </Box>
                            }

                        </Box>
                    </Card>
                </Box>
            </Flex>
            <LocationAndSubscribers 
                loading={locationLoading} 
                locationList={locationList} 
                setLocationList={setLocationList} 
                subsId={subscriber?.user_uuid} 
                onClick={onSubscriberClick} />
        </Flex>
    );
}

export default LocationSubscriberDashboard;

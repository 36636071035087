// Chakra React Imports
import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";

function TabThreeHelper(props) {
	const brandBg = useColorModeValue("blue.50", "navy.900");
	return (
		<Box
			w="100%"
			h="70vh"
			position="relative"
			p="3"
			borderRadius="15px"
			bg={brandBg}
			ml="2"
		>
			<Text>{props?.text?props?.text : 'How To Section'}</Text>
		</Box>
	);
}

export default TabThreeHelper;

// React Imports
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FaList, FaIdCard, FaTable } from "react-icons/fa";
import { Flex, Input, Button, InputLeftElement, InputGroup, Icon, Stack, useColorModeValue } from "@chakra-ui/react";

// Component Imports
import ClientData from "./ClientData";
import Card from "../../../../../components/card/Card";
import { useTextColor } from "../../../../../theme/globalColorTheme";

function ClientHome() {
	const blackWhiteColor = useTextColor();
	const [selectedView, setSelectedView] = useState("table");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

	return (
		<Card w={"100%"} h={"100%"} rounded={"md"} p={5} mx={3} mt="3">
			<Flex justifyContent={"space-between"}>
				<Flex alignItems={"center"}>
					<Stack spacing={4}>
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<Icon as={AiOutlineSearch} color="gray.300" />
							</InputLeftElement>
							<Input type="text" placeholder="Filter" />
						</InputGroup>
					</Stack>
					<Stack spacing={4} ml="4" w="500px">
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<Icon as={AiOutlineSearch} color="gray.300" />
							</InputLeftElement>
							<Input type="text" placeholder="Search" />
						</InputGroup>
					</Stack>
					<Button size="sm" ml="4" borderRadius={"0"} colorScheme="green">
						Go
					</Button>
				</Flex>
				<Stack direction="row" spacing={1}>
					<Button
						onClick={() => {
							setSelectedView("table");
						}}
						leftIcon={<FaTable />}
						borderRadius={"10px"}
						size="sm"
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={selectedView === "table" ? "black" : blackWhiteColor}
						bg={selectedView === "table" ? pillButtonBgHighlight : pillButtonBg}
					>
						Table View
					</Button>
					<Button
						leftIcon={<FaList />}
						borderRadius={"10px"}
						size="sm"
						// colorScheme={selectedView === "list" ? "purple" : "yellow"}
						// fontWeight={"normal"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={selectedView === "list" ? "black" : blackWhiteColor}
						bg={selectedView === "list" ? pillButtonBgHighlight : pillButtonBg}
						onClick={() => {
							setSelectedView("list");
						}}
					>
						List View
					</Button>
					<Button
						size={"sm"}
						color={selectedView === "card" ? "black" : blackWhiteColor}
						leftIcon={<FaIdCard />}
						borderRadius={"10px"}
						bg={selectedView === "card" ? pillButtonBgHighlight : pillButtonBg}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						onClick={() => {
							setSelectedView("card");
						}}
					>
						Card View
					</Button>
				</Stack>
			</Flex>
			<ClientData viewType={selectedView} />
		</Card>
	);
}

export default ClientHome;

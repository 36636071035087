import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";

function CustomBreadcrum() {
  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { customBreadcumArr } = sideMenuStatus;
  const [breadCrumData, setBreadCrumData] = useState([]);

  useEffect(() => {
    setBreadCrumData(customBreadcumArr);
  }, [customBreadcumArr]);

  let secondaryText = useColorModeValue("gray.700", "white");

  const renderBReadcrum = breadCrumData?.map((data, index) => (
    <BreadcrumbItem key={index} color={secondaryText} fontSize="sm">
      {/* <BreadcrumbLink href="#" color={secondaryText}> */}
      <BreadcrumbLink color={secondaryText} href={data?.path}>
        {data?.name}
      </BreadcrumbLink>
      {/* </BreadcrumbLink> */}
    </BreadcrumbItem>
  ));
  return <Breadcrumb>{renderBReadcrum} </Breadcrumb>;
}

export default CustomBreadcrum;

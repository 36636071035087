// React Chakra Imports
import React from "react";
import { GoDotFill } from "react-icons/go";
import { SiClickhouse } from "react-icons/si";
import { TbBrandElastic } from "react-icons/tb";
import { PiLineSegment } from "react-icons/pi";
import { FaAws, FaCloudflare } from "react-icons/fa";
import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { AiOutlineRight, AiFillCheckCircle, AiFillLock } from "react-icons/ai";

// Component Import
import { useBrandBg } from "../../../theme/globalColorTheme";

function TrustOverview() {
	const brandBg = useBrandBg();
	const trustItems = [
		{
			title: "Infrastructure security",
			content: [
				"Encryption key access restricted",
				"Unique account authentication enforced",
				"Production application access restricted",
			],
			more: "+ 15 more",
		},
		{
			title: "Organizational security",
			content: [
				"Asset disposal procedures utilized",
				"Production inventory maintained",
				"Portable media encrypted",
			],
			more: "+ 15 more",
		},
		{
			title: "Product security",
			content: [
				"Data encryption utilized",
				"Control self-assessments conducted",
				"Penetration testing performed",
			],
			more: "+ 2 more",
		},
		{
			title: "Internal security procedures",
			content: [
				"Continuity and Disaster Recovery plans established",
				"Continuity and disaster recovery plans tested",
				"Cybersecurity insurance maintained",
			],
			more: "+ 5 more",
		},
		{
			title: "Data and privacy",
			content: [
				"Data retention procedures established",
				"Customer data deleted upon leaving",
				"Data classification policy established",
			],
		},
	];

	const cloudServices = [
		{
			icon: FaAws,
			name: "Amazon Web Services",
			feature: "Cloud provider",
		},
		{
			icon: FaCloudflare,
			name: "Cloudflare",
			feature: "Cloud monitoring",
		},
		{
			icon: TbBrandElastic,
			name: "Elastic",
			feature: "Engineering",
		},
		{
			icon: PiLineSegment,
			name: "Segment",
			feature: "Data analytics",
		},
		{
			icon: SiClickhouse,
			name: "Clickhouse",
			feature: "Data Processing",
		},
	];

	return (
		<Flex justifyContent="space-between">
			<Box w="30%">
				<Text fontSize={"18px"} fontWeight="500">
					Compliance
				</Text>
				<Flex
					alignItems={"center"}
					borderWidth={"1px"}
					borderRadius={"5px"}
					h="80px"
					mt="3"
					pl="20px"
				>
					<Box
						h="50px"
						w="50px"
						borderRadius={"50px"}
						bg={brandBg}
						mr="2"
					></Box>
					<Text>SOC 2</Text>
				</Flex>
				<Flex
					mt="3"
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Text fontSize={"18px"} fontWeight={"500"}>
						Resources{" "}
					</Text>
					<Text fontSize={"16px"} fontWeight={"400"}>
						View all 2 Resources
					</Text>
				</Flex>
				<Flex
					alignItems={"center"}
					borderWidth={"1px"}
					borderRadius={"5px"}
					h="80px"
					mt="3"
					px="20px"
					justifyContent={"space-between"}
				>
					<Text>SOC 2 Type II</Text>
					<Icon as={AiFillLock}></Icon>
				</Flex>
				<Flex
					alignItems={"center"}
					borderWidth={"1px"}
					borderRadius={"5px"}
					h="80px"
					mt="3"
					px="20px"
					justifyContent={"space-between"}
				>
					<Text>2023 Penetration Test</Text>
					<Icon as={AiFillLock}></Icon>
				</Flex>
			</Box>
			<Box w="65%">
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Flex alignItems={"center"}>
						<Text mr="3" fontSize={"18px"} fontWeight="500">
							Controls
						</Text>
						<Text borderWidth={"0.5px"} px="3" borderRadius={"5px"}>
							<Icon
								as={GoDotFill}
								pt="1"
								color="green.500"
							></Icon>
							Updated 36 minutes ago
						</Text>
					</Flex>
					<Text>View all 74 controls</Text>
				</Flex>
				<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
					{trustItems.map((item, index) => (
						<Box
							key={index}
							w="49%"
							borderWidth={"1px"}
							borderRadius={"5px"}
							p="10px"
							py="15px"
							mt="3"
						>
							<Flex
								alignItems={"center"}
								justifyContent={"space-between"}
							>
								<Text fontSize={"16px"} fontWeight={"500"}>
									{item.title}
								</Text>
								<Icon as={AiOutlineRight} fontWeight={"bold"} />
							</Flex>
							<Box mt="3">
								{item.content.map(
									(contentItem, contentIndex) => (
										<Flex
											alignItems={"center"}
											mb="1"
											key={contentIndex}
										>
											<Icon
												as={AiFillCheckCircle}
												color={"green.500"}
											/>
											<Text
												ml="2"
												fontSize={"15px"}
												whiteSpace="nowrap"
												overflow="hidden"
												textOverflow="ellipsis"
											>
												{contentItem}
											</Text>
										</Flex>
									)
								)}
							</Box>
							{item.more && <Text>{item.more}</Text>}
						</Box>
					))}
				</Flex>
				<Box>
					<Flex
						alignItems={"center"}
						justifyContent={"space-between"}
						my="6"
					>
						<Text mr="3" fontSize={"18px"} fontWeight="500">
							Platform
						</Text>
						<Text>View all 6 Platform Components</Text>
					</Flex>
					<Box borderWidth={"1px"} borderRadius={"5px"} p="20px">
						{cloudServices.map((service, index) => (
							<Flex
								key={index}
								alignItems="center"
								mt={index !== 0 ? "3" : undefined}
							>
								<Icon
									as={service.icon}
									mr="3"
									fontSize="20px"
								/>
								<Text>{service.name}</Text>
								<Text ml="3" fontSize="md">
									&#8226; {service.feature}
								</Text>
							</Flex>
						))}
					</Box>
				</Box>
			</Box>
		</Flex>
	);
}

export default TrustOverview;

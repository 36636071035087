import React, { useEffect, useState } from "react";

// Chakra Import
import {
  Flex,
  Box,
  Text,
  Avatar,
  Spinner,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

// Component Import
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// API Call
import { fetchBusinessUserList } from "../../../services/adminServices";

function BusinessUserList(props) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const business_uuId = props?.businessId;
  const [loading, isLoading] = useState(false);
  const [businessUserId, setBusinessUserId] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");

  useEffect(() => {
    if (props?.businessId?.length) {
      fetchBusinessUserDetails();
    }
  }, [props?.businessId]);

  const fetchBusinessUserDetails = () => {
    isLoading(true);
    let obj = {
      business_uuid: business_uuId,
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchBusinessUserList(obj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.records?.length) {
          setBusinessList(res?.data[0]?.records);
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          setBusinessList([]);
          isLoading(false);
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster(err?.message, "error"));
      });
  };

  const renderBusinessList =
    businessList !== null && businessList?.length
      ? businessList?.map((data, index) => (
          <Box
            key={index}
            borderRadius={"10px"}
            p="2"
            mb="3"
            cursor={"pointer"}
            boxShadow={"md"}
            bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
            borderColor={data?.is_disabled === true ? "green.200" : "red.200"}
            borderWidth={businessUserId === data?.user_uuid ? "4px" : "1px"}
            onClick={() => {
              props?.returnBusinessUserDetails(data);
              setBusinessUserId(data?.user_uuid);
            }}
          >
            <Flex w={"100%"}>
              <Flex alignItems={"center"}>
                <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                  <Avatar
                    h={{ base: "40px", "2xl": "50px" }}
                    w={{ base: "40px", "2xl": "50px" }}
                    src={avatar}
                    me="16px"
                  />
                </Box>
              </Flex>
              <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
              >
                <Text fontWeight={"bold"} wordBreak={"break-all"}>
                  {data?.email}
                </Text>
                <Flex>
                  <Text as="span" mr="1">
                    {data?.first_name}
                  </Text>
                  <Text as="span">{data?.last_name}</Text>
                </Flex>
                <Text fontSize={"11px"}>{data?.user_type}</Text>
              </Flex>
            </Flex>
          </Box>
        ))
      : null;

  return (
    <>
      {businessList !== null && businessList?.length ? (
        <>
          <Box
            w={"100%"}
            p="2"
            mb={5}
            borderBottomWidth={"1px"}
            borderBottomStyle="dotted"
          >
            <Text fontWeight={"500"}>Business User List</Text>
          </Box>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" h="60vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Box p="2">{renderBusinessList}</Box>
          )}
        </>
      ) : (
        <Text p="2" fontWeight={"500"}>
          No Record Found
        </Text>
      )}
    </>
  );
}

export default BusinessUserList;

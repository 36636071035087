import React, { useState } from "react";
import { HStack, Icon, Box } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa"; 

const StarRating = ({ rating, setRating }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setRating(index);
  };

  return (
    <HStack>
      {Array(5)
        .fill("")
        .map((_, index) => {
          const starIndex = index + 1;
          return (
            <Box
              key={starIndex}
              onClick={() => handleClick(starIndex)}
              onMouseEnter={() => handleMouseEnter(starIndex)}
              onMouseLeave={handleMouseLeave}
              cursor="pointer"
            >
              <Icon
                as={FaStar}
                boxSize={8}
                color={starIndex <= (hoverRating || rating) ? "yellow.400" : "gray.300"}
              />
            </Box>
          );
        })}
    </HStack>
  );
};

export default StarRating;

// Chakra Imports
import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import {
  Box,
  Card,
  Text,
  Icon,
  FormLabel,
  Flex,
  Button,
} from "@chakra-ui/react";

// Component Imports
import WorkflowServiceModal from "./WorkflowServiceModal";

function WorkflowServices() {
  // Todo:Render Workflow List
  const renderWorkflowServices = () => {
    return (
      <Flex w={"100%"}>
        <Box minW={"150px"} fontSize={"sm"} align="center" p="2">
          <Text>Mar 12,2024,16:26 pm</Text>
          <Button size="xs" bg={"green.100"} mt={1}>
            Provider Name
          </Button>
        </Box>
        <Box
          w={"100%"}
          borderLeftWidth={"0.05px"}
          borderLeftColor={"gray.200"}
          ml={1}
          pl={2}
        >
          <Flex
            w={"100%"}
            align={"center"}
            borderBottomWidth={"0.05px"}
            borderBottomColor={"gray.200"}
          >
            <Text fontWeight="bold">Transcription</Text>
            <Text fontSize={"xs"} ml={2}>
              (Audio File)
            </Text>
          </Flex>

          <Flex
            w={"100%"}
            align={"center"}
            borderBottomWidth={"0.05px"}
            borderBottomColor={"gray.200"}
            py="2"
          >
            <Text mr={1}>
              Source Language
              <Text
                as="span"
                m={1}
                p={1}
                fontSize={"xs"}
                bg={"blue.100"}
                rounded={"md"}
              >
                Hindi, Indian
              </Text>
            </Text>
            <Text ml={5} mr={1}>
              Target Language(s)
            </Text>
          </Flex>
          <Flex w={"100%"} mt={1} py="1">
            <Text color={"blue.400"} fontSize={"xs"}>
              Submitted By: guptasuraj55@gmail.com
            </Text>
          </Flex>
        </Box>
        <Flex
          minW={"100px"}
          borderLeftWidth={"0.05px"}
          borderLeftColor={"gray.200"}
          ml={1}
          pl={2}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box textAlign="center" w="100%">
            <Text>Status</Text>
            <Text color="green" fontWeight={"bold"}>
              ENABLED
            </Text>
          </Box>
        </Flex>
        <Flex
          minW={"250px"}
          borderLeftWidth={"0.05px"}
          borderLeftColor={"gray.200"}
          ml={1}
          pl={2}
          flexWrap={"wrap"}
        >
          <Flex w="100%">
            <Text w="50%"> Cost:- </Text>
            <Text ml="2" color="green">
              {" "}
              0.01{" "}
            </Text>
          </Flex>
          <Flex w="100%" mt="1">
            <Text w="50%"> Unit Quantity:- </Text>
            <Text ml="2" color="green">
              {" "}
              0.01{" "}
            </Text>
          </Flex>
          <Flex w="100%" mt="1">
            <Text w="50%"> Unit Type:- </Text>
            <Text ml="2" color="green">
              {" "}
              Words{" "}
            </Text>
          </Flex>
          <Flex w="100%" mt="1">
            <Text w="50%"> Currency:- </Text>
            <Text ml="2" color="green">
              {" "}
              ${" "}
            </Text>
          </Flex>
        </Flex>
        <Flex
          minW={"150px"}
          borderLeftWidth={"0.05px"}
          borderLeftColor={"gray.200"}
          ml={1}
          pl={2}
          alignItems="center"
        >
          <Box textAlign="center" w="100%">
            <Text>Overhead %</Text>
            <Text color="green" fontWeight={"bold"}>
              50%
            </Text>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent={"center"}
          borderLeftWidth={"0.05px"}
          borderLeftColor={"gray.200"}
          p={2}
        >
          <WorkflowServiceModal buttonName={"Edit"} />
        </Flex>
      </Flex>
    );
  };

  return (
    <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Flex
        mt="5"
        p="20px"
        bg="#fff"
        borderWidth="0.5px"
        alignItems={"center"}
        borderRadius={"20px"}
        justifyContent={"space-between"}
      >
        <Text>Workflow Services</Text>
        <WorkflowServiceModal buttonName={"Add New Services"} />
      </Flex>
      <Card mt="5" borderWidth="0.5px" borderColor={"#d7d7d7"} p="2">
        {renderWorkflowServices()}
      </Card>
    </Box>
  );
}

export default WorkflowServices;

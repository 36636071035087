
export const SCHEDULE_DETAILS = {
    "service_type": "Translator",
    "content_type": "Conversation",
    "requested_date": "",
    "requested_time": "",
    "request_duration": 60,
    "request_frequency": "daily"
}

export const SCHEDULE_SERVICE_TYPES = {
    "TRANSLATOR":"Translator",
    "INTERPRETER":'Interpreter',
    "TRANSCRIBER":'Transcriber'
}

export const SCHEDULE_FREQUENCY = {
    ONCE:"once",
    DAILY:"daily",
    WEEKLY:"weekly",
    MONTHLY:"monthly",
    CUSTOM:"custom"
}


export const SCHEDULE_CONTENT_TYPE = {
    CONVERSATION:"Conversation",
    DOCUMENT:"Document"
}

export const SCHEDULE_STATUS = {
    OPEN:'open',
    CLOSED:'closed',
    CANCELED:"cancel"
}

export const SIDEBAR_TYPES = {
    CREATE:"create",
    UPDATE:"update"
}
import moment from "moment";
import React, { useState, useEffect, memo } from "react";


// Chakra Import
import { MinusIcon, AddIcon } from '@chakra-ui/icons'
import {
	Box,
	Flex,
	Text,
	Icon,
	Button,
	Spacer,
	Spinner,
	useToast,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Heading,
	TableContainer,
	Select,
} from "@chakra-ui/react";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiOutlineCaretRight,
	AiOutlineUpCircle,
	AiOutlineUser,
} from "react-icons/ai";

// Api Call Imports
import {
	fetchAccountDescription,
	fetchAccountDetails,
} from "../../../services/businessServices";

// Component Imports
import Card from "../../../components/card/Card";
import { fetchEventData } from "../../../services/accountServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useBrandBg, useRedBg, useGreenBg, useNoDataBandBg, useNoResultFoundText, useTextColor } from "../../../theme/globalColorTheme";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { format } from "date-fns";


function AccountsEvents() {
	const redBg = useRedBg();
	const greenBg = useGreenBg();
	const toast = useToast();
	const brandBg = useBrandBg();
	const AccountLocationAndSubMenu = useTextColor()
	const noEventDataFoundText = useNoResultFoundText()
	const [loading, isLoading] = useState(true);
	const [accountId, setAccountId] = useState('')
	const [locationId, setLocationId] = useState()
	const [subscriberId, setSubscriberId] = useState()
	const [subLoading, setSubLoading] = useState(false);
	const [accountList, setAccountList] = useState(null);
	const [locationData, setLocationData] = useState(null);
	const [eventLoader, setEventLoader] = useState(false)
	const [eventList, setEventList] = useState(null)
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
	const [emailColFilter, setEmailColFilter] = useState('')
	const [routeColFilter, setRouteColFilter] = useState('')
	const [statusColFilter, setStatusColFilter] = useState('')
	const [tableHeaders, setTableHeaders] = useState({ Email: [], Route: [], Status: [], Date: [] })
	const [filteredEventsData, setFilteredEventsData] = useState([])
	const [selectedDates, setSelectedDates] = useState([new Date("05-01-2024"), new Date()])

	useEffect(()=>{
		if(accountId && selectedDates[0] < selectedDates[1]){
			collectEventData(accountId, locationId, subscriberId)
		}
	}, [selectedDates])

	useEffect(() => {
		collectAccountDetails();
	}, []);

	useEffect(() => {
		handleUpdateColumnfilterOptions()

	}, [filteredEventsData])

	useEffect(() => {
		handleUpdateFiltereEventdData()
	}, [emailColFilter, statusColFilter, routeColFilter, eventList])

	useEffect(() => {
		if (accountList?.length) {
			renderAccountData(accountList)
		}
	}, [accountList])

	useEffect(() => {
		if (locationData?.length) {
			renderLocationData(accountList)
		}
	}, [locationData])

	const handleUpdateColumnfilterOptions = () => {
		const newUniqueRouteTypes = new Set();
		const newUniqueEmails = new Set()
		const newUniqueStatus = new Set()

		filteredEventsData?.forEach(eventItem => {
			newUniqueRouteTypes.add(eventItem?.event_route);
			newUniqueEmails.add(eventItem?.event_caller)
			if (!eventItem.event_status) {
				newUniqueStatus.add('Fail')
			} else {
				newUniqueStatus.add('Success')
			}
		});
		setTableHeaders({ Email: [...newUniqueEmails], Route: [...newUniqueRouteTypes], Status: [...newUniqueStatus], Date: [] })
	}

	const handleUpdateFiltereEventdData = () =>{
		const filteredEventData = eventList?.filter(
			event => (
				(emailColFilter === '' || event?.event_caller.includes(emailColFilter))
				&& (routeColFilter === '' || event?.event_route.includes(routeColFilter))
				&& (statusColFilter === '' || String(event?.event_status) === statusColFilter)))
		setFilteredEventsData(
			filteredEventData
		)
	}

	// Todo: Fetch Account Data
	const collectAccountDetails = () => {
		isLoading(true);
		let paramObj = {
			key: 'active',
			type: 'min',
			records_per_page: 0,
			page_id: 0,
		};
		fetchAccountDetails(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.total_records) {
					isLoading(false);
					setAccountList(res?.data[0]?.records);
					toast(toastFunctionToaster(res.message, "success"));

				} else {
					isLoading(false);
					setAccountList([]);
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountList([]);
				toast(toastFunctionToaster('Something Went Wrong', "error"));

			});
	};

	// Todo: Fetch Location & Subscriber Data
	const collectlocationData = (account_uuid) => {
		setSubLoading(true);
		let paramObj = {
			link_account_uuid: account_uuid
		};
		fetchAccountDescription(paramObj)
			.then(res => {
				setSubLoading(false);
				if (res?.data[0]?.status) {
					setSubLoading(false);
					setLocationData(res?.data[0]?.locations);
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					setSubLoading(false);
					setLocationData([]);
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				setSubLoading(false);
				setLocationData([]);
				toast(toastFunctionToaster("Something went wrong!!!", "error"));
			});
	};

	// Todo: Fetch All Events List
	const collectEventData = (accountId, locationId, subscriberId) => {
		setEventLoader(true)
		if (accountId !== null) {
			let obj = {
				"account_uuid": accountId,
				"location_uuid": locationId,
				"subscriber_uuid": subscriberId,
				"duration": "all",
				"start_date": format(selectedDates[0],'dd-MM-yyyy'),
				"end_date": format(selectedDates[1],'dd-MM-yyyy'),
				"limit": 1000
			}
			fetchEventData(obj).then((res) => {
				setEventLoader(false)

				if (res?.result && res?.data?.length) {
					setEmailColFilter('')
					setStatusColFilter('')
					setRouteColFilter('')
					setEventList(res?.data)
					toast(toastFunctionToaster(res.message, "success"));
				}
				else {
					setEventList([])
					toast(toastFunctionToaster(res.message, "error"));
				}
			}).catch((err) => {
				setEventList([])
				setEventLoader(false)
				toast(toastFunctionToaster('Something Went Wrong', "error"));
			})
		}

	}

	// Todo:Render Account Data
	const renderAccountData = () => {
		if (accountList?.length) {
			return (
				loading ? (
					<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
						<Spinner size={"xl"} />
					</Flex>
				) : (
					<Accordion allowToggle border="0px" >
						{accountList?.map((data) => (
							<AccordionItem key={data?.link_account_uuid} border="0px">
								{({ isExpanded }) => (
									<>
										<Flex w={'100%'} direction={'column'} mb={1} rounded={'md'} bg={'gray.300'}>
											<AccordionButton onClick={() => {
												!isExpanded && collectlocationData(data?.link_account_uuid
												)
												setAccountId(data?.link_account_uuid)
											}}>
												<Box cursor={'pointer'} fontSize='17px' as='span' flex='1' textAlign='left'>
													<Flex justifyContent={'space-between'} cursor={'pointer'}>
														{data?.link_account_title}
														<Button
															cursor="pointer"
															size="xs" borderRadius={'5px'} colorScheme="green"
															onClick={(e) => {
																e.stopPropagation();
																setAccountId(data?.link_account_uuid)
																setLocationId(null)
																setSubscriberId(null)
																collectEventData(data?.link_account_uuid, null, null)
															}}
															isDisabled={eventLoader ? true : false} isLoading={eventLoader ? true : false}>
															Event
														</Button>
													</Flex>
												</Box>
												{isExpanded ? (
													<MinusIcon fontSize='12px' />
												) : (
													<AddIcon fontSize='12px' />
												)}
											</AccordionButton>
											<AccordionPanel p={0} bg={'gray.200'}>
												{renderLocationData(data?.link_account_uuid)}
											</AccordionPanel>
										</Flex>
									</>
								)}
							</AccordionItem>
						))}

					</Accordion>)
			);
		}
		else {
			return (
				<Text fontSize='16px' fontWeight={'bold'} bg={redBg} p="2">No Account Available</Text>
			)
		}
	}

	// Todo:Render Location And Subscriber Data
	const renderLocationData = (accountUuid) => {
		if (locationData?.length) {
			return (
				subLoading ? (
					<Flex w={"100%"} p={5} justifyContent={'center'} direction={"column"} align={"center"} >
						<Spinner size={"xl"} />
					</Flex>
				) : (
					<Accordion allowToggle border="0px" p="0px" pl="10px">
						{locationData?.map((data) => (
							<AccordionItem key={data?.location_uuid} border="0px">
								{({ isExpanded }) => (
									<>
										<AccordionButton >
											<Box
												bg={isExpanded ? 'blue.100' : null}
												cursor={'pointer'} fontSize='16px' as='span' flex='1' textAlign='left' >
												<Flex justifyContent={'space-between'} cursor={'pointer'}>
													{data?.location_title}
													<Button
														cursor="pointer"
														size="xs" borderRadius={'5px'} colorScheme="green"
														onClick={(e) => {
															e.stopPropagation();
															setAccountId(accountUuid)
															setLocationId(data?.location_uuid)
															setSubscriberId(null)
															collectEventData(accountUuid, data?.location_uuid, null)
														}}
														isDisabled={eventLoader ? true : false} isLoading={eventLoader ? true : false}>
														Event
													</Button>
												</Flex>
											</Box>
											{isExpanded ? (
												<Icon as={AiOutlineCaretDown} fontSize='14px' />
											) : (
												<Icon as={AiOutlineCaretUp} fontSize='14px' />
											)}
										</AccordionButton>
										<AccordionPanel p={0} ml="10px">
											{data?.subscribers?.length ? data?.subscribers?.map((sub, index) => (
												<Flex p={2} justifyContent={'space-between'} cursor={'pointer'}>
													<Flex alignItems={'center'}>
														<Icon as={AiOutlineUser}
															h={6} w={6}
															p={1} rounded={'md'} bg={'gray.300'} fontSize='15px' />
														<Flex w={'100%'} direction={'column'}>
															<Text fontSize='15px' ml="2">{sub?.first_name}{' '}{sub?.last_name}</Text>
															<Text fontSize='15px' ml="2">{sub?.email}</Text>
														</Flex>
													</Flex>
													<Button
														cursor="pointer"
														size="xs" borderRadius={'5px'} colorScheme="green"
														onClick={(e) => {
															e.stopPropagation();
															setAccountId(accountUuid)
															setLocationId(data?.location_uuid)
															setSubscriberId(sub?.sub_uuid)
															collectEventData(accountUuid, data?.location_uuid, sub?.sub_uuid)
														}}
														isDisabled={eventLoader ? true : false} isLoading={eventLoader ? true : false}>
														Event
													</Button>
												</Flex>
											)) : <Text fontSize='15px' fontWeight={'bold'} color={'red.300'} p="2">No Subscriber Available</Text>}
										</AccordionPanel>
									</>
								)}
							</AccordionItem>
						))
						}
					</Accordion >)
			);
		}
		else {
			return (<Text w="90%" mx="auto" fontSize='15px' bg={'red.100'} p="2">Account has no location.</Text>)
		}
	}


	// Todo:Render Event Data
	const RenderEventsList = memo(() =>{
		return (
			eventList !== null && eventList?.length > 0 ? (
				filteredEventsData?.map((eventItem, index) => (
					<Tr key={index + eventItem?.datetime_created}>
						<Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
						<Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
						<Td color='black' bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
						<Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
					</Tr>
				))
			) : (
				null
			)
		)
	})

	const renderTableFilter = (placeholder, options) => {
		if(placeholder === "Date"){
			return(<RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />)
		}
		if (options.length) {
			if (placeholder === 'Status') {
				return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
					{options?.map(option => <option key={option} value={option === 'Success'}>{option}</option>)}
				</Select>)
			}
			return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
				{options?.map(option => <option key={option} value={option}>{option}</option>)}
			</Select>)
		}
	}

	const handleColumnFilterChange = (value, col) => {
		switch (col) {
			case "Email":
				setEmailColFilter(value)
				break;
			case "Route":
				setRouteColFilter(value)
				break
			case "Status":
				setStatusColFilter(value)
				break
			default:
				break;
		}
	}

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				<Card p={1} mx={3} alignItems="center" rounded={'md'} py={5} mt={1}>
					<Flex w={'100%'} direction={'row'} maxH={'60px'}>
						<Text fontWeight={"500"} fontSize={"18px"}>
							Account Events
						</Text>
						<Spacer />
					</Flex>
				</Card>
				<Card w={'100%'} h={'calc(100vh - 200px)'} p={1} mx={3} mt={1} alignItems="center" rounded={'md'}>
					<Flex w={'100%'} h="100%">
						<Box
							w={!configurationCollapseFlag ? "95%" : "75%"}
							bg={brandBg}
							p={1}
							borderRadius={"10px"}
							overflow="hidden"
							overflowY="auto"
							css={{
								'&::-webkit-scrollbar': {
									width: '4px',
								},
								'&::-webkit-scrollbar-track': {
									width: '6px',
								},
								'&::-webkit-scrollbar-thumb': {
									background: { brandBg },
									borderRadius: '24px',
								},
							}}>
							<Flex w={"100%"} direction={"column"} p='10px'>
								{eventLoader ? (
									<Flex alignItems="center" justifyContent="center" h="50vh">
										<Spinner size="lg" />
									</Flex>
								) :
									eventList?.length ? (
										<>
											<Flex direction="column">
												<Text mb="2" fontWeight={'bold'}>Event List</Text>
												<Spacer />
											</Flex>
											<TableContainer border="1px solid #d7d7d7" borderRadius="5px" >
												<Table variant="simple">
													<Thead>
														<Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
															{Object.keys(tableHeaders).map(header => (
																<Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
																	{header}
																</Th>
															))}
														</Tr>
														<Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
															{Object.entries(tableHeaders).map(([header, options]) => (
																<Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
																	{renderTableFilter(header, options)}
																</Th>
															))}
														</Tr>
													</Thead>
													<Tbody>
														<RenderEventsList />
													</Tbody>
												</Table>
											</TableContainer>
										</>
									) : <Flex w={"100%"} p={5} bg={useNoDataBandBg} borderLeftWidth={"5px"}
										borderLeftColor={"red.500"}>
										<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
											No Event Data based on selection..
										</Heading>
									</Flex>
								}
							</Flex>
						</Box>
						{configurationCollapseFlag ? (
							<Flex w="25%">
								<Box
									w="100%"
									position="relative"
									p="3"
									borderRadius="15px"
									bg={brandBg}
									ml="2">
									<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
										<AiFillRightCircle
											cursor="pointer"
											size="20px"
											onClick={() => {
												setConfiguationCollapseFlag(!configurationCollapseFlag);
											}}
										/>
									</Box>
									<Box w="100%" h="100%"
										color={'gray.800'}
										overflow="auto"
										css={{
											'&::-webkit-scrollbar': {
												width: '4px',
											},
											'&::-webkit-scrollbar-track': {
												width: '6px',
											},
											'&::-webkit-scrollbar-thumb': {
												background: { brandBg },
												borderRadius: '24px',
											},
										}}>
										<Text fontWeight={'600'} mb="4" color={useTextColor}>Accounts, Locations & Subscribers</Text>
										{renderAccountData()}
									</Box>
								</Box>
							</Flex>
						) : (
							<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
								<Box w={"100%"} h="100%">
									<AiFillLeftCircle
										cursor="pointer"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
										size="20px"
									/>
									<Text
										fontSize="16px"
										mt="2"
										fontWeight='bold'
										css={{
											writingMode: "vertical-lr",
											textOrientation: "mixed",
										}}>
										Accounts
									</Text>
								</Box>
							</Box>
						)}
					</Flex>
				</Card>
			</Box>
		</>
	)
}

export default AccountsEvents;

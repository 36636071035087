// Chakra Imports
import React from "react";
import {
	Text,
	Button,
	Drawer,
	DrawerBody,
	useDisclosure,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

function ReportingPanel() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Button
				m={1}
				w="auto"
				size="sm"
				colorScheme="blue"
				borderRadius={"5px"}
				onClick={() => onOpen()}
			>
				Main Side Panel
			</Button>

			<Drawer onClose={onClose} isOpen={isOpen} size={"lg"}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Drawer Content</DrawerHeader>
					<DrawerBody>
						<Text>
							Lorem ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the
							industry's standard dummy text ever since the 1500s,
							when an unknown printer took a galley of type and
							scrambled it to make a type specimen book. It has
							survived not only five centuries, but also the leap
							into electronic typesetting, remaining essentially
							unchanged.
						</Text>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default ReportingPanel;

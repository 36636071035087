import { Box, Flex, Image, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ContentUpload from './ContentUpload'
import { fetchLocationInfo } from '../../../services/accountServices';
import { connect } from 'react-redux';
import Card from '../../../components/card/Card';

const AccountDesign = ({accountUploadFlag}) => {
    const [loading, isLoading] = useState(false)
    const [accountUuid, setAccountUuid] = useState(null)
    const [accountInfo, setAccountInfo] = useState(null)
    const cardBg = useColorModeValue("#EFF4FB", "inherit")

    useEffect(() => {
        collectLocationInfo()
    }, [accountUploadFlag])

    const collectLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'location',
        };
        fetchLocationInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.uuid);
                    setAccountInfo(res?.data[0]?.info);
                } else {
                    isLoading(false);
                    setAccountUuid(null);
                    setAccountInfo(null);

                }
            })
            .catch(err => {
                // isLoading(false);
                setAccountUuid(null);
                setAccountInfo(null);
            });
    };


    return (
        loading ? (<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="70vh">
            <Spinner />
        </Flex>) :
            (<Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3} gap={3}>
                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1}>
                    <Flex bg={'cardBg'} w="100%" h="full" alignItems="center" minH={'40vh'}>
                        {accountInfo?.[0]?.logo ? <Flex w="60%" justifyContent="center">
                            <Image src={accountInfo?.[0]?.logo} alt='logo' height="150px" />
                        </Flex> : <Flex w="60%" justifyContent="center"><Text>No logo image found</Text></Flex>}
                        <Box w="40%">
                            <ContentUpload uploadValue={'logo'} account_Id={accountUuid} />
                        </Box>
                    </Flex>
                </Card>

                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1}>
                    <Flex bg={cardBg} w="100%" h="full" minH={'40vh'} alignItems="center">
                    {accountInfo?.[0]?.background ? <Flex w="60%" justifyContent="center">
                            <Image src={accountInfo?.[0]?.background} alt='background' height="150px" />
                        </Flex>:<Flex w="60%" justifyContent="center"><Text>No Background image found</Text></Flex>}
                        <Box w="40%">
                            <ContentUpload uploadValue={'background'} account_Id={accountUuid} />
                        </Box>
                    </Flex>
                </Card>
            </Flex>)
    )
}

const mapStateToProps = (state) => ({
    accountUploadFlag: state.notificationHeader.accountUploadFlag,
});

export default connect(mapStateToProps)(AccountDesign)

//notificationHeader.accountUploadFlag
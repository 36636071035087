export const transcriptionLanguages = [
    { "code": "ab-GE", "name": "Abkhaz" },
    { "code": "af-ZA", "name": "Afrikaans" },
    { "code": "ar-AE", "name": "Arabic, Gulf" },
    { "code": "ar-SA", "name": "Arabic, Modern Standard" },
    { "code": "hy-AM", "name": "Armenian" },
    { "code": "ast-ES", "name": "Asturian" },
    { "code": "az-AZ", "name": "Azerbaijani" },
    { "code": "ba-RU", "name": "Bashkir" },
    { "code": "eu-ES", "name": "Basque" },
    { "code": "be-BY", "name": "Belarusian" },
    { "code": "bn-IN", "name": "Bengali" },
    { "code": "bs-BA", "name": "Bosnian" },
    { "code": "bg-BG", "name": "Bulgarian" },
    { "code": "ca-ES", "name": "Catalan" },
    { "code": "ckb-IR", "name": "Central Kurdish, Iran" },
    { "code": "ckb-IQ", "name": "Central Kurdish, Iraq" },
    { "code": "zh-CN", "name": "Chinese, Simplified" },
    { "code": "zh-TW", "name": "Chinese, Traditional" },
    { "code": "hr-HR", "name": "Croatian" },
    { "code": "cs-CZ", "name": "Czech" },
    { "code": "da-DK", "name": "Danish" },
    { "code": "nl-NL", "name": "Dutch" },
    { "code": "en-AU", "name": "English, Australian" },
    { "code": "en-GB", "name": "English, British" },
    { "code": "en-IN", "name": "English, Indian" },
    { "code": "en-IE", "name": "English, Irish" },
    { "code": "en-NZ", "name": "English, New Zealand" },
    { "code": "en-AB", "name": "English, Scottish" },
    { "code": "en-ZA", "name": "English, South African" },
    { "code": "en-US", "name": "English, US" },
    { "code": "en-WL", "name": "English, Welsh" },
    { "code": "et-ET", "name": "Estonian" },
    { "code": "fa-IR", "name": "Farsi" },
    { "code": "fi-FI", "name": "Finnish" },
    { "code": "fr-FR", "name": "French" },
    { "code": "fr-CA", "name": "French, Canadian" },
    { "code": "gl-ES", "name": "Galician" },
    { "code": "ka-GE", "name": "Georgian" },
    { "code": "de-DE", "name": "German" },
    { "code": "de-CH", "name": "German, Swiss" },
    { "code": "el-GR", "name": "Greek" },
    { "code": "gu-IN", "name": "Gujarati" },
    { "code": "ha-NG", "name": "Hausa" },
    { "code": "he-IL", "name": "Hebrew" },
    { "code": "hi-IN", "name": "Hindi, Indian" },
    { "code": "hu-HU", "name": "Hungarian" },
    { "code": "is-IS", "name": "Icelandic" },
    { "code": "id-ID", "name": "Indonesian" },
    { "code": "it-IT", "name": "Italian" },
    { "code": "ja-JP", "name": "Japanese" },
    { "code": "kab-DZ", "name": "Kabyle" },
    { "code": "kn-IN", "name": "Kannada" },
    { "code": "kk-KZ", "name": "Kazakh" },
    { "code": "rw-RW", "name": "Kinyarwanda" },
    { "code": "ko-KR", "name": "Korean" },
    { "code": "ky-KG", "name": "Kyrgyz" },
    { "code": "lv-LV", "name": "Latvian" },
    { "code": "lt-LT", "name": "Lithuanian" },
    { "code": "lg-IN", "name": "Luganda" },
    { "code": "mk-MK", "name": "Macedonian" },
    { "code": "ms-MY", "name": "Malay" },
    { "code": "ml-IN", "name": "Malayalam" },
    { "code": "mt-MT", "name": "Maltese" },
    { "code": "mr-IN", "name": "Marathi" },
    { "code": "mhr-RU", "name": "Meadow Mari" },
    { "code": "mn-MN", "name": "Mongolian" },
    { "code": "no-NO", "name": "Norwegian Bokmål" },
    { "code": "or-IN", "name": "Odia/Oriya" },
    { "code": "ps-AF", "name": "Pashto" },
    { "code": "pl-PL", "name": "Polish" },
    { "code": "pt-PT", "name": "Portuguese" },
    { "code": "pt-BR", "name": "Portuguese, Brazilian" },
    { "code": "pa-IN", "name": "Punjabi" },
    { "code": "ro-RO", "name": "Romanian" },
    { "code": "ru-RU", "name": "Russian" },
    { "code": "sr-RS", "name": "Serbian" },
    { "code": "si-LK", "name": "Sinhala" },
    { "code": "sk-SK", "name": "Slovak" },
    { "code": "sl-SI", "name": "Slovenian" },
    { "code": "so-SO", "name": "Somali" },
    { "code": "es-ES", "name": "Spanish" },
    { "code": "es-US", "name": "Spanish, US" },
    { "code": "su-ID", "name": "Sundanese" },
    { "code": "sw-KE", "name": "Swahili, Kenya" },
    { "code": "sw-BI", "name": "Swahili, Burundi" },
    { "code": "sw-RW", "name": "Swahili, Rwanda" },
    { "code": "sw-TZ", "name": "Swahili, Tanzania" },
    { "code": "sw-UG", "name": "Swahili, Uganda" },
    { "code": "sv-SE", "name": "Swedish" },
    { "code": "tl-PH", "name": "Tagalog/Filipino" },
    { "code": "ta-IN", "name": "Tamil" },
    { "code": "tt-RU", "name": "Tatar" },
    { "code": "te-IN", "name": "Telugu" },
    { "code": "th-TH", "name": "Thai" },
    { "code": "tr-TR", "name": "Turkish" },
    { "code": "uk-UA", "name": "Ukrainian" },
    { "code": "ug-CN", "name": "Uyghur" },
    { "code": "uz-UZ", "name": "Uzbek" },
    { "code": "vi-VN", "name": "Vietnamese" },
    { "code": "cy-WL", "name": "Welsh" },
    { "code": "wo-SN", "name": "Wolof" },
    { "code": "zu-ZA", "name": "Zulu" }
]

// FormComponent.jsx
import { Box, Button, Flex, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const AddUpdateRefrenceForm = ({ formData, onChange, error, setRefrenceForms, id, checkRefrenceFormUpdated }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const handleRemoveForm = (formId) => {
    setRefrenceForms(prevData => {
      let updatedData =  prevData.filter(item => item.id !== formId);
      checkRefrenceFormUpdated(updatedData)
      return updatedData
    });
  };
  
  return (
    <Flex gap={3} >
      <Box>
        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          Name <Text as="span" color={brandStars}>*</Text>
        </FormLabel>
        <Input
          type="text"
          name="full_name"
          value={formData.full_name}
          onChange={onChange}
          placeholder="Full Name"
        />
        <Text color="red" mb="2">
          {error?.full_name}
        </Text>
      </Box>

      <Box>
        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          Linked in <Text as="span" color={brandStars}>*</Text>
        </FormLabel>
        <Input
          type="text"
          name="linkedin_url"
          value={formData.linkedin_url}
          onChange={onChange}
          placeholder="LinkedIn"
        />
        <Text color="red" mb="2">
          {error?.linkedin_url}
        </Text>
      </Box>

      <Box>
        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          email <Text as="span" color={brandStars}>*</Text>
        </FormLabel>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={onChange}
          placeholder="Email"
        />
        <Text color="red" mb="2">
          {error?.email}
        </Text>
      </Box>
      <Button
        fontSize="sm"
        mt={6}
        colorScheme="blue"
        fontWeight="bold"
        h="50"
        onClick={() => {
          handleRemoveForm(id)
        }}>
        Remove
        </Button>
    </Flex>
  );
};

export default AddUpdateRefrenceForm;

import React, { useMemo, useEffect, useState } from "react";
// Chakra and Icon Imports
import {
  Text,
  Button,
  Flex,
  Icon,
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInput,
  Stack,
  Spinner,
  Switch,
  HStack,
} from "@chakra-ui/react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";

// React-Table Import
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Component Import
import Card from "../../../components/card/Card";
import { useCustomColorModeValues } from "../../../utils/ColorMode";
import { teamColumnData } from "./teamColumnData/TeamColumnData";

// API Services Import
import { fetchTeamListData } from "../../../services/adminServices";
import InviteTeamMemeber from "./InviteTeamMemeber";

function TeamListing() {
  const [loading, isLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState("view");
  const [teamListArr, setTeamListArr] = useState([]);
  const data = useMemo(() => teamListArr, [teamListArr]);
  const columns = useMemo(() => teamColumnData, [teamColumnData]);
  const { textColor, borderColor, brandColor } = useCustomColorModeValues();

  useEffect(() => {
    fetchListData();
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;

  // Todo:Data to fetch team list data
  const fetchListData = () => {
    isLoading(true);
    let obj = {
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchTeamListData(obj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.records) {
          setTeamListArr(res?.data[0]?.records);
        }
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px" p={5}>
        <HStack w={"100%"} justifyContent={"end"}>
          {currentPage === "view" ? (
            <Button onClick={() => setCurrentPage("invite")}>
              Invite Team Member
            </Button>
          ) : null}
          {currentPage === "invite" ? (
            <Button
              onClick={() => {
                setCurrentPage("view");
                fetchListData();
              }}
            >
              Back to Associate List
            </Button>
          ) : null}
        </HStack>
      </Card>
      {currentPage === "invite" ? (
        <Card px="0px" p={10} mt={2}>
          <InviteTeamMemeber
            // associateFlag={reRenderAssociateList}
            currentStatus={currentPage}
          />
        </Card>
      ) : null}
      {currentPage === "view" ? (
        <Card p={0} mt={2}>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" h="50vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card px="0px" p={10}>
              <Text fontSize={"20px"} fontWeight="bold" mb="3">
                Team Listing
              </Text>
              <Table
                {...getTableProps()}
                variant="simple"
                color="gray.500"
                mb="24px"
              >
                <Thead>
                  {headerGroups.map((headerGroup, index) => (
                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, index) => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          pe="10px"
                          key={index}
                          borderColor={borderColor}
                        >
                          <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                          >
                            {column.render("Header")}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          let data = "";
                          if (cell.column.Header === "EMAIL") {
                            data = (
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Text>
                            );
                          } else if (cell.column.Header === "FIRST NAME") {
                            data = (
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Text>
                            );
                          } else if (cell.column.Header === "LAST NAME") {
                            data = (
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Text>
                            );
                          } else if (cell.column.Header === "STATUS ORDER") {
                            data = (
                              <Badge
                                colorScheme={
                                  cell.value === "Completed" ? "green" : "red"
                                }
                                color={
                                  cell.value === "Completed"
                                    ? "green.500"
                                    : "red.500"
                                }
                                fontSize="md"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Badge>
                            );
                          } else if (cell.column.Header === "TYPE") {
                            data = (
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Text>
                            );
                          } else if (cell.column.Header === "STATUS") {
                            data = (
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="500"
                              >
                                <Switch
                                  isChecked={cell.value ? true : false}
                                  colorScheme="green"
                                />
                              </Text>
                            );
                          }
                          return (
                            <Td
                              {...cell.getCellProps()}
                              key={index}
                              fontSize={{ sm: "14px" }}
                              minW={{ sm: "150px", md: "200px", lg: "auto" }}
                              borderColor={borderColor}
                            >
                              {data}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <Flex
                direction={{ sm: "column", md: "row" }}
                justify="space-between"
                align="center"
                w="100%"
                px={{ md: "22px" }}
              >
                <Text
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="normal"
                  mb={{ sm: "24px", md: "0px" }}
                >
                  Showing {pageSize * pageIndex + 1} to{" "}
                  {pageSize * (pageIndex + 1) <= teamListArr.length
                    ? pageSize * (pageIndex + 1)
                    : teamListArr.length}{" "}
                  of {teamListArr.length} entries
                </Text>
                <Stack
                  direction="row"
                  alignSelf="flex-end"
                  spacing="4px"
                  ms="auto"
                >
                  <Button
                    variant="no-effects"
                    onClick={() => previousPage()}
                    transition="all .5s ease"
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg="transparent"
                    border="1px solid"
                    // borderColor={useColorModeValue("gray.200", "white")}
                    display={
                      pageSize === 5
                        ? "none"
                        : canPreviousPage
                        ? "flex"
                        : "none"
                    }
                    _hover={{
                      bg: "whiteAlpha.100",
                      opacity: "0.7",
                    }}
                  >
                    <Icon
                      as={MdChevronLeft}
                      w="16px"
                      h="16px"
                      color={textColor}
                    />
                  </Button>
                  {pageSize === 5 ? (
                    <NumberInput
                      max={pageCount - 1}
                      min={1}
                      w="75px"
                      mx="6px"
                      defaultValue="1"
                      onChange={(e) => gotoPage(e)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper onClick={() => nextPage()} />
                        <NumberDecrementStepper
                          onClick={() => previousPage()}
                        />
                      </NumberInputStepper>
                    </NumberInput>
                  ) : (
                    createPages(pageCount).map((pageNumber, index) => {
                      return (
                        <Button
                          variant="no-effects"
                          transition="all .5s ease"
                          onClick={() => gotoPage(pageNumber - 1)}
                          w="40px"
                          h="40px"
                          borderRadius="50%"
                          bg={
                            pageNumber === pageIndex + 1
                              ? brandColor
                              : "transparent"
                          }
                          border={
                            pageNumber === pageIndex + 1
                              ? "none"
                              : "1px solid lightgray"
                          }
                          _hover={
                            pageNumber === pageIndex + 1
                              ? {
                                  opacity: "0.7",
                                }
                              : {
                                  bg: "whiteAlpha.100",
                                }
                          }
                          key={index}
                        >
                          <Text
                            fontSize="sm"
                            color={
                              pageNumber === pageIndex + 1 ? "#fff" : textColor
                            }
                          >
                            {pageNumber}
                          </Text>
                        </Button>
                      );
                    })
                  )}
                  <Button
                    variant="no-effects"
                    onClick={() => nextPage()}
                    transition="all .5s ease"
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg="transparent"
                    border="1px solid"
                    // borderColor={useColorModeValue("gray.200", "white")}
                    display={
                      pageSize === 5 ? "none" : canNextPage ? "flex" : "none"
                    }
                    _hover={{
                      bg: "whiteAlpha.100",
                      opacity: "0.7",
                    }}
                  >
                    <Icon
                      as={MdChevronRight}
                      w="16px"
                      h="16px"
                      color={textColor}
                    />
                  </Button>
                </Stack>
              </Flex>
            </Card>
          )}
        </Card>
      ) : null}
    </Flex>
  );
}

export default TeamListing;

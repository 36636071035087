import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Textarea,
    Text,
    TagCloseButton,
    TagLabel,
    Select,
    Box,
    Tag,
    Flex,
} from '@chakra-ui/react'
import StarRating from './StarRating/StarRating';

const FeedbackModal = ({ isFeedBackOpen, onFeedBackClose, rating, setRating, comment, setComment, selectedComment, setSelectedComment, handleAddFeedBack, submitLoading }) => {
    
    const [addDisabled, setAddDisabled] = useState(true)

    const options = ["Good Call with no problem","Bad call with Audio issues","Good call but issues with interpretation quality"];

    const handleSelectOption = (e) => {
        const selectedOption = e.target.value;
        if (!selectedComment.includes(selectedOption) && selectedOption !== "") {
            setSelectedComment([...selectedComment, selectedOption]);
        }
        e.target.value = "";
    };

    const handleRemoveOption = (optionToRemove) => {
        setSelectedComment(selectedComment.filter((opt) => opt !== optionToRemove));
    };

    const handleTextChange = (e) => {
        setComment(e.target.value);
    };

    useEffect(()=>{
        if(selectedComment.length || comment.length || rating){
            setAddDisabled(false)
        }else{
            setAddDisabled(true)
        }
    }, [selectedComment, rating, comment])

    return (
        <Modal isOpen={isFeedBackOpen} onClose={onFeedBackClose}>
            <ModalOverlay />
            <ModalContent width={{sm:"87%", 'md':"initial"}}>
                <ModalHeader>LingoLink Call Feedback</ModalHeader>
                <ModalBody>
                </ModalBody>
                <Box p="2">
                    <Flex direction="column" border="1px" borderColor={'gray.500'} p={2} rounded="sm">
                        <Flex flexWrap="wrap">
                            
                                {selectedComment.map((option, index) => (
                                    <Tag
                                        key={index}
                                        size="md"
                                        borderRadius="full"
                                        variant="solid"
                                        colorScheme="blue"
                                        mr={2}
                                        mb={2}
                                    >
                                        <TagLabel>{option}</TagLabel>
                                        <TagCloseButton onClick={() => handleRemoveOption(option)} />
                                    </Tag>
                                ))}
                        </Flex>
                        <Textarea
                            value={comment}
                            onChange={handleTextChange}
                            placeholder="Write your text here..."
                            pl={0}
                            focusBorderColor="transparent"
                            _focus={{ boxShadow: "none" }}
                            border="none"
                            p={1}
                        />
                    </Flex>
                    <Box w="full" textAlign="right">
                        <Text fontSize="smaller">max 200 words ({comment.length}/200)</Text>
                    </Box>
                    <Select placeholder="Select an option" onChange={handleSelectOption} mb={3} mt={3}>
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                    <StarRating rating={rating} setRating={setRating} />
                </Box>
                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={onFeedBackClose} size={{sm:"sm", md:"md"}}>
                        Close
                    </Button>
                    <Button colorScheme='green' isDisabled={addDisabled} onClick={handleAddFeedBack} size={{sm:"sm", md:"md"}} isLoading={submitLoading}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default FeedbackModal
export const DRAWER_TYPES ={
    MANAGE_DEVICE:"manageDevice",
    ASSIGN_ACCOUNT:'assign_account',
    ACC_ADMIN_LIST:'account_admin_list',
    ADD_UPDATE_NOTE:"addUpdateNote"
}

export const LOGS_FILTER = {
    MAC:"mac",
    SERIAL:"serial",
    ALL:'all'
}
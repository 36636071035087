import {
  Box, Button, Flex, IconButton, Text, Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Card,
  Spacer,
  Spinner,
  Alert,
  AlertIcon,
  color,
} from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import { accLangPrefPaginated, applyAccLangPref, fetchLocationInfo } from '../../../../services/accountServices'
import { useGreenBg } from '../../../../theme/globalColorTheme'
import { toastFunctionToaster } from '../../../../utils/toastFunction'
import { BiDotsHorizontal } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const RenderLanguages = memo(({ langs, translationLanguages }) => {
  let langsArr = langs?.split(',')
  const filterData = translationLanguages?.filter(item => langsArr?.indexOf(item.code) > -1)
  return (
    filterData?.length ? (
      <Flex flexWrap="wrap" gap={2} >
        {filterData.map((item) => (<Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md" color="black">{item.name}</Text>))}
      </Flex>
    ) :
      (<Flex flexWrap="wrap" gap={2} >
        <Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md" color="black">All Languages</Text>
      </Flex>)
  )
})

const LinkPreferences = (props) => {
  const { linkItem, reListLink, availableLangs,langPrefList } = props;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const greenBg = useGreenBg()
  const [isApplyLoading, setIsApplyLoading] = useState(false)
  const [accountId, setAccountUuid] = useState('')
  const navigate = useNavigate()

  function handleApplyPrefrence(prefrenceid) {
    const paramObj = {
      "link_account_uuid": accountId,
      "link_location_uuid": "",
      "linkUuid": linkItem.uuid,
      "language_preference_uuid": prefrenceid
    }
    setIsApplyLoading(prefrenceid)
    applyAccLangPref(paramObj).then(resp => {
      if (resp.data[0]?.status) {
        reListLink()
        handleCloseDrawer()
        toast(toastFunctionToaster("Successfully added language preference", "success"))
      } else {
        toast(toastFunctionToaster("Failed to add language preference", "error"))
      }
      setIsApplyLoading(false)
    }).catch(err => {
      setIsApplyLoading(false)
      toast(toastFunctionToaster("Failed to add language preference", "error"))
    })
  }

  function fetchAccAndLocDetails() {
    const paramObj = { key: "location" };
    fetchLocationInfo(paramObj)
      .then(resp => {
        if (resp?.data[0]?.status) {
          setAccountUuid(resp?.data[0]?.uuid);
        } else {
          setAccountUuid("");
        }
      })
      .catch(err => {
        setAccountUuid("");
      });
  };

  function handleCloseDrawer() {
    onClose()
    setIsApplyLoading('')
  }

  function handleOpenDrawer() {
    onOpen()
    fetchAccAndLocDetails()
  }


  return (
    <Flex gap={2} mt={3}>
      <Text w={'200px'}><b>Language Preferences:</b></Text>
      <RenderLanguages langs={linkItem?.source_langs} translationLanguages={availableLangs} />

      <IconButton
        borderRadius={'5px'}
        size='sm'
        fontSize={'22px'}
        _hover={{
          color: "white",
          backgroundColor: "blue.300"
        }}
        ml='2'
        bg={'blue.100'}
        color="black"
        // colorScheme='blue'
        icon={<BiDotsHorizontal />}
        onClick={handleOpenDrawer}
      />


      <Drawer
        size="md"
        isOpen={isOpen}
        placement='right'
        onClose={handleCloseDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody>
            <Text>List of all enabled Languages preference</Text>
            <Flex flexDir="column" gap={2} p={1}>
              {<>
                {
                  langPrefList && langPrefList.length ? langPrefList.map(item => (
                    <Flex alignItems="center" justifyContent="center" gap="1">
                      <Card w="100%" borderRadius="md" mb={2} p={4}>
                        <Flex alignItems='center' >
                          <Flex flexDir="column" gap={2} flex="1">
                            <Flex>
                              <Box
                                p={2}
                                bg={greenBg}
                                borderRadius="md">
                                <Text
                                  fontWeight={"500"}
                                  fontSize={"18px"}
                                  _firstLetter={{ textTransform: "capitalize" }}>
                                  {item?.language_preference_name}
                                </Text>
                              </Box>
                              <Spacer />
                            </Flex>

                            <Flex alignContent="center" gap={2}>Languages:<RenderLanguages langs={item?.supported_language_list} translationLanguages={availableLangs} /></Flex>
                          </Flex>
                          <Button fontSize="sm" bg="blue.100" isLoading={isApplyLoading === item.language_preference_uuid} color="black" _hover={{
                            color: "white",
                            backgroundColor: "blue.300"
                          }} onClick={() => handleApplyPrefrence(item.language_preference_uuid)}>Apply</Button>
                        </Flex>
                      </Card>
                    </Flex>
                  ))
                    : <Alert status="warning" mt="4">
                      <AlertIcon />
                      <Text>You dont have any Language Preferences</Text>
                      <Text
                        ml={5}
                        _hover={{ cursor: "pointer", textDecoration: "underline" }}
                      // onClick={() => handleOpenDrawer(SIDEBAR_TYPES.CREATE)}
                      onClick={()=> navigate("/account/languagepreference")}
                      >
                        <b>CREATE NEW Language Preference!!</b>
                      </Text>
                    </Alert>
                }
              </>}

            </Flex>
          </DrawerBody>

          {/* <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter> */}
        </DrawerContent>
      </Drawer>

    </Flex>
  )
}

export default LinkPreferences
import { Flex, Heading, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'
import { useNoResultFoundText } from '../../../../theme/globalColorTheme'
import { memo } from 'react'
import { formatSeconds } from '../../../../utils/TimeFunctions'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'

const RenderSessions = memo(({ cardSessions }) => {
    const notFoundText = useNoResultFoundText()
    return cardSessions !== null && cardSessions?.length > 0
        ? cardSessions?.map((item, index) => (
            <Flex w={'100%'} key={index}
                direction={'column'} gap={2} mt={1}>

                <Flex w={'100%'} borderWidth={'0.5px'} borderColor={'gray.500'} borderRadius={'md'} p={3}>
                    <Flex w={'100%'} direction={'column'} align={'center'} justify={'space-between'}>
                        <Flex w={'100%'}>
                            <Text fontSize={'sm'}>
                                {item?.call_start_time ? moment(item?.call_start_time).format("MMM DD,YYYY,HH:mm:ss a") : "00:00:00"}
                                {" ... "}
                                {item?.call_end_time ? moment(item?.call_end_time).format("MMM DD,YYYY,HH:mm:ss a") : "00:00:00"}
                            </Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text fontSize={'sm'}>Caller Number:  {item?.caller_phone_number}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text fontSize={'sm'}>Target Number:  {item?.target_phone_number}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text fontSize={'sm'}>Language Name: {item?.language_name} (Code: {item?.language_code})</Text>
                        </Flex>
                    </Flex>
                    <Flex w={'100%'}>
                        <Text fontSize={'sm'}><Icon as={item?.pin_code_validation ? AiFillCheckCircle : AiFillCloseCircle} color={item?.pin_code_validation ? 'green.600' : 'red.600'} boxSize={5} /> </Text>
                    </Flex>
                    <Flex w={'100%'}>
                        <Text fontSize={'sm'}><Icon as={item?.lingo_card_call_status === "Completed" ? AiFillCheckCircle : AiFillCloseCircle} color={item?.lingo_card_call_status === "Completed" ? 'green.600' : 'red.600'} boxSize={5} /></Text>
                    </Flex>
                    <Flex w={'250px'}>
                        <Text>{formatSeconds(Math.round(item?.call_duration_minute, 2))}</Text>
                    </Flex>

                </Flex>
            </Flex>
        ))
        : <Flex w={'100%'} p={2}>
            <Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this LingoCard call</Text>
        </Flex>;
})

const LingoCardHistory = ({ selectedLingoCard, cardSessions, totalCalls, validationFailed, callsFailed, totalTime }) => {
    return (
        <>
            {!selectedLingoCard ? <Flex
                w={"100%"}
                p={15}
                bg={'red.100'}
                borderLeftWidth={"5px"}
                borderLeftColor={"red.500"}
            >
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                    Click the lingo cards on the right for call history.
                </Heading>
            </Flex> :
                <>
                    {cardSessions?.length ? <>
                        <Flex
                            h="100%"
                            w={"100%"}
                            direction={"column"}
                            mt={2}
                            borderBottomWidth={"0.05px"}
                            borderBottomColor={"gray.200"}
                            alignItems='center'
                            overflow="hidden"
                        >
                            <Flex w={"100%"} direction={"column"} h="full" p="2">
                                {cardSessions &&
                                    <Flex w={"100%"} direction={"row"} p={3} bg={'blue.100'} borderTopRightRadius="lg" borderTopLeftRadius="lg">
                                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                            Call Information
                                        </Heading>
                                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                            Validation
                                        </Heading>
                                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                            Status
                                        </Heading>
                                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                            Call Duration(m)
                                        </Heading>
                                    </Flex>}

                                <Flex w={"100%"} direction={"column"} h='90%' overflowY="auto" className="custom-scrollbar" pb={"20"}>
                                    <RenderSessions cardSessions={cardSessions} />
                                </Flex>

                                {cardSessions &&
                                    <Flex w={"100%"} direction={"row"} bg={'blue.100'}
                                        p={3} align={'center'}
                                        //position="absolute" bottom={0} right={0}
                                        borderTop={"1px"} borderTopColor={"gray.600"} >
                                        <Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
                                            Total Calls: {totalCalls}
                                        </Text>
                                        <Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
                                            Failed Validation: {validationFailed}
                                        </Text>
                                        <Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
                                            Failed Calls: {callsFailed}
                                        </Text>
                                        <Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
                                            Total Time: {totalTime}
                                        </Text>
                                    </Flex>}
                            </Flex>
                        </Flex>
                    </> : <Flex
                        w={"100%"}
                        p={5}
                        bg={'red.100'}
                        borderLeftWidth={"5px"}
                        borderLeftColor={"red.500"}
                    >
                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                            This Lingo Card does not have any usage.
                        </Heading>
                    </Flex>}
                </>
            }
        </>
    )
}

export default LingoCardHistory
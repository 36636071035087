// Chakra Imports
import React, { useState,useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { PiSmileyBlank, PiSmileySad, PiSmiley } from "react-icons/pi";
import { Flex, Box, Text, useColorModeValue, useToast, Icon } from "@chakra-ui/react";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Services Imports
import { storeFeedbackData } from "../../../services/businessServices";

const FeedbackComponent = (props) => {
	const toast = useToast();
	const title = props?.title;
	const [headerTitle,setHeaderTitle] = useState(`How do you feel about providing your feedback regarding ${title}?`)
	const type = props?.type;
	const subtype = props?.subtype;
	var pathName = window.location.pathname;
	const [showFeedBack,setShowFeedback] = useState(true)
	const [showTitleFlag,setShowTitleFlag] = useState(true)
	const [feedBackValue, selectedFeedbackValue] = useState(null);
	const gradientColor = useColorModeValue("linear(to-b, blue.100, blue.300)", "linear(to-b, gray.100, gray.600)");
	const textColor = useColorModeValue("secondaryGray.900", "gray.600");
	
	const [feedbackStatus,setFeedbackStatus] = useState(false);

	const handleEmojiSelect = value => {
		// setShowFeedback(false);
		// selectedFeedbackValue(value);
		// props?.onEmojiSelect(value);

		storeFeedBackDetails(value)
	};

	useEffect (() => {
		if (feedBackValue !== null) {
			storeFeedBackDetails();
		}
	},[feedBackValue])

	const storeFeedBackDetails = (value) => {
		let obj = {
			type : type,
			feedback : value,
			subtype : subtype,
			url : pathName,
		}
		storeFeedbackData(obj)
			.then(res => {
				if (res?.data[0]?.status) {
					if (res?.data[0]?.waiting){
						setHeaderTitle(res?.message)
						setShowTitleFlag(false)
					} else {
						setFeedbackStatus(res?.data[0]?.status);
						setHeaderTitle(res?.message)
						setShowTitleFlag(false)
					}
				} else {
					setHeaderTitle(res?.message)
				}
			})
			.catch(err => {
				setHeaderTitle('Unable to submit feedback due to some error..', "error")
			});
	}

	return (
		<>
			{showFeedBack ? (
				<Flex
					position="fixed"
					bottom="20px"
					w={"550px"}
					bgGradient={gradientColor}
					direction={"column"}
					p={2}
					rounded={"md"}
					borderWidth={"0.5px"}
					borderColor={"gray.200"}>
					<Flex justifyContent={'space-between'}>
						<Text fontWeight={"500"} fontSize={"16px"} color={textColor}>
							{headerTitle}
						</Text>
						<Icon  as={AiFillCloseCircle}  fontSize={'18px'} color="red.400" cursor={'pointer'} onClick={()=>{setShowFeedback(false)}}/>
					</Flex>
					{showTitleFlag ? (
						<Flex w={"100%"} alignItems="center" mt="3">
							<Box
								_hover={{color:'red.500'}}
								onClick={() => handleEmojiSelect(0)}
								cursor="pointer"
								marginRight="10px"
								color={feedBackValue === 0 ? "red.500" : textColor}>
								<PiSmileySad fontSize={"3rem"} />
							</Box>
							<Box
								_hover={{color:'orange.500'}}
								onClick={() => handleEmojiSelect(10)}
								cursor="pointer"
								marginRight="10px"
								color={feedBackValue === 10 ? "yellow.300" : textColor}>
								<PiSmileyBlank fontSize={"3rem"} />
							</Box>
							<Box
								_hover={{color:'green.500'}}
								onClick={() => handleEmojiSelect(20)}
								cursor="pointer"
								color={feedBackValue === 20 ? "green.500" : textColor}>
								<PiSmiley fontSize={"3rem"} />
							</Box>
						</Flex>
					): null }
				</Flex>
			) : null}
		</>
	);
};

export default FeedbackComponent;

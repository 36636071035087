import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Chakra Import
import {
	Box,
	Flex,
	Text,
	Badge,
	Icon,
	Avatar,
	Spacer,
	Heading,
	Spinner,
	useToast} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiOutlineMail,
} from "react-icons/ai";

// Api Call Imports
import {
	fetchAccountDetails,
	fetchAccountDescription,
} from "../../../../services/businessServices";

// Component Imports
import { useBrandBg, useCardTextHover, useGreenBg, useNoResultFoundText, useRedBg, usePillButtonBg, useTextColor, usePillButtonBgHover, usePillButtonTextColorSelected, usePillButtonTextColorHover } from "../../../../theme/globalColorTheme";

function AccountsLocations() {
	const redBg = useRedBg();
	const greenBg = useGreenBg();
	const notFoundTextColor = useNoResultFoundText()
	const toast = useToast();
	const brandBg = useBrandBg();
	const navigate = useNavigate();
	const cardHoverText = useCardTextHover()
	const [loading, isLoading] = useState(true);
	const [accountId, setAccountId] = useState('')
	const [accountList, setAccountList] = useState([]);
	const [adminList, setAdminList] = useState([]);
	const [accountDescription, setAccountDescription] = useState([]);
	const [currentSelection, setCurrentSelection] = useState('active');
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

	const pillButtonBg = usePillButtonBg()
	const textColor = useTextColor()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonTextColorHover = usePillButtonTextColorHover()

	//   Todo:Fetch all account details
	useEffect(() => {
		fetchAccountList(currentSelection);
	}, []);

	const fetchAccountList = (local_status) => {
		isLoading(true);
		let paramObj = {
			key: local_status,
			type: 'min',
			records_per_page: 0,
			page_id: 0,
		};
		fetchAccountDetails(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.total_records) {
					isLoading(false);
					setAccountList(res?.data[0]?.records);
				} else {
					isLoading(false);
					setAccountList([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountList([]);
			});
	};

	const collectAccountDescription = (account_uuid) => {
		isLoading(true);
		let paramObj = {
			link_account_uuid: account_uuid
		};
		fetchAccountDescription(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setAdminList(res?.data[0]?.admins)
					setAccountDescription(res?.data[0]?.locations);
				} else {
					isLoading(false);
					setAccountDescription([]);
					setAdminList([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountDescription([]);
				setAdminList([]);
			});
	};

	// Todo:Render Account Details
	const renderAccountDetails =
		accountList !== null && accountList?.length > 0 ? (
			accountList?.map((item, index) => (
				<Flex
					w={"100%"}
					key={index}
					rounded={"md"}
					align={"center"}
					p={1}
					mt={1}
					borderWidth={"0.05px"}
					borderColor={item?.is_active ? "green.300" : "red.200"}
					bg={accountId === item?.link_account_uuid ? 'blue.200' : null}
				>
					<Flex
						onClick={() => {
							collectAccountDescription(item?.link_account_uuid)
							setAccountId(item?.link_account_uuid)
						}}
						_hover={{ cursor: "pointer", bg: "blue.100", color: cardHoverText }}
						rounded={"md"}
						px={1}
						w={"100%"}
						align={"center"}>
						<Box minW={"60px"}>
							<Avatar src={item?.link_account_avatar?.length ? item?.link_account_avatar : null} />
						</Box>
						<Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
							<Text fontWeight={'bold'}>
								{item?.link_account_title}
							</Text>
							<Text w="100%">{item?.link_account_name}</Text>
							<Badge bg={'blue.400'}>{item?.link_account_type}</Badge>
						</Flex>
					</Flex>
				</Flex>
			))
		) : (
			<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
				<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={notFoundTextColor}>
					You do not have any account added.
				</Heading>
			</Flex>
		);


	// Todo:Render Admin List
	const renderAdminList = () => {
		console.log(adminList, "adminList")
		return (
			<Box>
				{
					adminList.map((subData, index) => (
						<Box
							position="relative"
							key={index}
							p="2" mb='2' border="1px"
							borderRadius={'5px'}
							borderColor={subData?.disabled ? 'red.400' : greenBg}
							cursor={'pointer'}
						>
							<Flex alignItems={'center'} justifyContent={'space-between'}>
								<Text fontWeight={'600'}>{subData?.first_name} {subData?.last_name}</Text>
								<Box h="5px" w="5px" borderRadius={'50px'} bg={subData?.disabled ? 'red.400' : 'green.400'}></Box>
							</Flex>
							<Flex w={'100%'} align={'center'}>
								<Icon as={AiOutlineMail} mr={1} />
								<Text>{subData?.email}</Text>
							</Flex>
						</Box>
					))
				}
			</Box>
		)
	}

	// Todo:Render Location and Subscriber List
	const renderLocationSubList = () => {
		console.log(accountDescription, "accountDescription")
		return (
			<Box>
				{accountDescription?.map((data, idx) => {
					return (
						<Box key={idx}
							ml="10px"
							position="relative"
							_before={
								data?.subscribers?.length ? {
									content: "''",
									width: '10px',
									height: "1px",
									bg: "#d7d7d7",
									left: '-10px',
									top: '36px',
									position: "absolute",
									transition: "all .3s ease",
								} : undefined
							}
							_after={
								data?.subscribers?.length ? {
									content: "''",
									width: '1px',
									height: "86%",
									bg: "#d7d7d7",
									left: '-10px',
									top: "37px",
									position: "absolute",
									transition: "all .3s ease",
								} : undefined
							}
							mb="20px">
							<Box p="2" mb='2' border="1px" borderColor={'#d7d7d7'} borderRadius={'5px'}>
								{data?.photo
									? <Text>{data?.photo}</Text> : null}
								<Text fontWeight={'600'}>{data?.location_name}</Text>
								<Text>{data?.location_title}</Text>
							</Box>
							<Box>
								{
									data?.subscribers?.length > 0 ? (
										data.subscribers.map((subData, index) => (
											<Box
												position="relative"
												_before={{
													content: "''",
													width: '55px',
													height: "1px",
													bg: "#d7d7d7",
													left: '-55px',
													top: "36px",
													position: "absolute",
													transition: "all .3s ease",
												}}
												key={index}
												ml="45px" p="2" mb='2' border="1px"
												borderRadius={'5px'}
												borderColor={subData?.disabled ? 'red.400' : 'green.400'}
												cursor={'pointer'}

											>
												<Flex alignItems={'center'} justifyContent={'space-between'}>
													<Text fontWeight={'600'}>{subData?.first_name} {subData?.last_name}</Text>
													<Box h="5px" w="5px" borderRadius={'50px'} bg={subData?.disabled ? 'red.400' : 'green.400'}></Box>
												</Flex>
												<Flex w={'100%'} align={'center'}>
													<Icon as={AiOutlineMail} mr={1} />
													<Text>{subData?.email}</Text>
												</Flex>
											</Box>
										))
									) : null
								}
							</Box>
						</Box>
					)
				})}
			</Box>
		)
	}

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				<Card p={1} mx={3} alignItems="center" rounded={'md'}>
					<Flex w={'100%'} direction={'row'} py={3}>
						<Text fontWeight={"500"} fontSize={"18px"}>
							Business Account Locations, Subscribers and LingoLink by Subscribers
						</Text>
						<Spacer />
					</Flex>
				</Card>
				<Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1}>
					<Flex h="80vh">
						<Box
							w={!configurationCollapseFlag ? "95%" : "75%"}
							bg={brandBg}
							p={1}
							borderRadius={"10px"}
							overflow="hidden">
							<Flex w={"100%"} direction={"column"}>
								{loading ? (
									<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
										<Spinner size={"xl"} />
									</Flex>
								) : (
									<Flex w={"100%"} direction={"column"}>
										<Flex
											w={"100%"}
											h="calc(100vh - 200px)"
											overflow="hidden"
											borderTopWidth="0px"
										>
											<Box w="50%" borderRightWidth={'1px'} h="100%"
												overflow="auto"
												css={{
													'&::-webkit-scrollbar': {
														width: '4px',
													},
													'&::-webkit-scrollbar-track': {
														width: '6px',
													},
													'&::-webkit-scrollbar-thumb': {
														background: { brandBg },
														borderRadius: '24px',
													},
												}}
											>
												<Text fontWeight={'bold'} mb="2" p="3" borderBottomWidth={'1px'}>Location and Subscriber List</Text>
												<Box p="3">
													{accountDescription?.length > 0
														? <Text>{renderLocationSubList()}</Text>
														: <Flex w={"100%"} p={5} bg={redBg} borderLeftWidth={"5px"} borderLeftColor={"red.500"}>
															<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={notFoundTextColor}>
																No Location And Subscriber found..
															</Heading>
														</Flex>
													}
												</Box>
											</Box>
											<Box w="50%" h="100%"
												overflow="auto"
												css={{
													'&::-webkit-scrollbar': {
														width: '4px',
													},
													'&::-webkit-scrollbar-track': {
														width: '6px',
													},
													'&::-webkit-scrollbar-thumb': {
														background: { brandBg },
														borderRadius: '24px',
													},
												}}>
												<Text fontWeight={'bold'} mb="2" p="3" borderBottomWidth={'1px'}>Admin List</Text>
												<Box p="3">
													{adminList?.length > 0
														? <Text>{renderAdminList()}</Text>
														: <Flex w={"100%"} p={5} bg={redBg} borderLeftWidth={"5px"} borderLeftColor={"red.500"}>
															<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={notFoundTextColor}>
																No Admins found..
															</Heading>
														</Flex>
													}
												</Box>
											</Box>

										</Flex>
									</Flex>
								)}
							</Flex>
						</Box>
						{configurationCollapseFlag ? (
							<Flex w="25%">
								<Box
									w="100%"
									position="relative"
									p="3"
									borderRadius="15px"
									bg={brandBg}
									ml="2">
									<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
										<AiFillRightCircle
											cursor="pointer"
											size="20px"
											onClick={() => {
												setConfiguationCollapseFlag(!configurationCollapseFlag);
											}}
										/>
									</Box>
									<Box w="100%" h="95%">
										<Flex minW={'170px'} maxW={'170px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
											<Text
												minW={'70px'} maxW={'70px'}
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													setCurrentSelection('active');
													fetchAccountList('active');
												}}
												p={2} roundedLeft={'md'}
												color={currentSelection === 'active' ? 'white' : textColor}
												bg={currentSelection === 'active' ? pillButtonBgSelected : pillButtonBg}>
												Active
											</Text>
											<Text
												minW={'80px'} maxW={'80px'}
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													setCurrentSelection('closed');
													fetchAccountList('closed');
												}}
												roundedRight={'md'}
												color={currentSelection === 'closed' ? 'white' : textColor}
												p={2}
												bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
												Closed
											</Text>
										</Flex>
										<Flex w={'100%'} direction={'column'}>
											{renderAccountDetails}
										</Flex>
									</Box>
								</Box>
							</Flex>
						) : (
							<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
								<Box w={"100%"} h="100%">
									<AiFillLeftCircle
										cursor="pointer"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
										size="20px"
									/>
									<Text
										fontSize="16px"
										mt="2"
										fontWeight='bold'
										css={{
											writingMode: "vertical-lr",
											textOrientation: "mixed",
										}}>
										Accounts List
									</Text>
								</Box>
							</Box>
						)}
					</Flex>
				</Card>
			</Box>
		</>
	);
}

export default AccountsLocations;

import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// --------------------------------------------------------------
// Settings Api
// --------------------------------------------------------------

export const fetchSettingsData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSettingsdata, data, config);
};
export const createNewSetting = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSetting, data, config);
};
export const updateSetting = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSetting, data, config);
};
export const manageSetting = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSetting, data, config);
};
export const getSettingById = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSetting, data, config);
};

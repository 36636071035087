import React, { useState, useEffect } from "react";

// Chakra Imports
import { useDispatch } from "react-redux";
import { MdUpload } from "react-icons/md";
import {
    Button,
    Text,
    Flex,
    Box,
    Input,
    Stack,
    Icon,
    FormControl,
    useColorModeValue,
    Card,
    Spinner,
} from "@chakra-ui/react";
import { getEmailId } from "../../../utils/localStorageIndex";
import axios from "../../../services/axios"
import { setAccountUploadFlag } from "../../../store/actions/topNotificationActions";
import { updateDesignForAccountAdmin } from "../../../services/accountServices";

// Component Import

export default function ContentUpload(props) {
    const emaiId = getEmailId();
    const dispatch = useDispatch();
    const accountId = props?.account_Id
    const { used, total, ...rest } = props;
    const [uploadStatus, setUploadStatus] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [uploadValue, setUploadValue] = useState(null)
    const [uploadFileUrl, setUploadFileUrl] = useState();
    const brandColor = useColorModeValue("brand.500", "white");
    const [uploadStatusLoading, setUploadStatusLoading] = useState(false);



    useEffect(() => {
        setUploadValue(props?.uploadValue)
    }, [props?.uploadValue])

    function postObjectData(s3Data, selectedFile) {
        dispatch(setAccountUploadFlag(false));
        const { url, fields } = s3Data;
        const payload = new FormData();

        // add all the other fields
        Object.entries(fields).forEach(([key, val]) => {
            payload.append(key, val);
        });
        payload.append("file", selectedFile);
        var ax_instance = axios.create();
        delete ax_instance.defaults.headers.common["Authorization"];
        const { data: result } = ax_instance
            .post(url, payload)
            .then((res) => {
                if (res?.data?.length > 0) {
                    setUploadStatus("File is uploaded successfully");
                    dispatch(setAccountUploadFlag(true));
                } else {
                    setUploadStatus("File is uploaded successfully");
                    dispatch(setAccountUploadFlag(true));

                }
            })
            .catch((err) => {
                setUploadStatus("Upload failed with Error :" + err);
            });
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            setUploadFile(e.target.files[0]);
            setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    };
    const uploadAccountData = async () => {
        setUploadStatusLoading(true);
        if (uploadFile) {
            let objData = {
                email: emaiId,
                link_account_uuid: accountId,
                content_choice: uploadValue,
                file_name: uploadFile.name,
                file_type: uploadFile.type,
            };
            setUploadStatus("Getting upload url for the selected file..");
            const response = await updateDesignForAccountAdmin(objData);
            setUploadStatusLoading(false);
            if (response && response.data && response.data.length > 0) {
                setUploadStatusLoading(false);
                const finalUrl = response.data[0].signedPackage.signedUrl;
                setUploadStatus("Trying to upload the selected file..");
                const s3UploadResult = await postObjectData(finalUrl, uploadFile);
            } else {
                setUploadStatusLoading(false);
                setUploadStatus("Unable to get the upload signed url..");
            }
        }
    };

    return (
        <Card {...rest} mb="20px" align="center" p="20px">
            <Box w={"100%"}>
                <FormControl cursor="pointer">
                    <Text fontSize={"12px"}>{uploadFileUrl}</Text>
                    <Text mb={4} fontSize={"12px"}>
                        {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
                    </Text>
                    <Box
                        position="relative"
                        height="200px"
                        width="100%"
                        borderWidth="2px"
                        borderColor={brandColor}
                        borderRadius={"10px"}
                        cursor="pointer"
                    >
                        {uploadStatusLoading ? <Flex w="full" h="full" alignItems="center" justifyContent="center" flexDir="column"><Spinner thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl' /><Text>Uploading...</Text></Flex> : (<><Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            cursor="pointer"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                spacing="4"
                                cursor="pointer"
                            >
                                <Box textAlign="center">
                                    <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                                    <Flex justify="center" mx="auto" mb="12px">
                                        <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                            Upload Files
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize="sm"
                                        fontWeight="500"
                                        color="secondaryGray.500"
                                    >
                                        PNG, JPG and GIF files are allowed
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>
                        <Input
                            type="file"
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            accept=".png, .jpg, .jpeg" // Include .pdf here to accept PDF files
                            cursor="pointer"
                            onChange={handleFileChange}
                            isDisabled={uploadStatusLoading}
                        /></>) }
                    </Box>
                </FormControl>
            </Box>
            {uploadFile ? (
                <Box
                    w="100%"
                    mb="30px"
                    mt={{ base: "20px", "2xl": "auto" }}
                    alignItems="center"
                >
                    <Button
                        me="auto"
                        mb="2"
                        variant="brand"
                        fontWeight="500"
                        onClick={uploadAccountData}
                        size="sm"
                        mt="2"
                        isDisabled={uploadStatusLoading ? true : false}
                        isLoading={uploadStatusLoading ? true : false}
                    >
                        Upload {uploadValue} Image
                    </Button>
                    <Box w={"100%"}>
                        <Text color="green">{uploadStatus}</Text>
                    </Box>
                </Box>
            ) : null}
        </Card>
    );
}

export const AssociatesDataHeader = [
    {
      Header: "USER NAME",
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "DISABLED STATUS",
      accessor: "status",
    },
    {
      Header: "GIVEN ROLE",
      accessor: "given_role",
    },
    {
      Header: "JOIN DATE",
      accessor: "date",
    },
    // {
    //   Header: "USER TYPE",
    //   accessor: "type",
    // },
      {
      Header: "ADMIN STATUS",
      accessor: "level",
    },
    // {
    //   Header: "ACTIONS",
    //   accessor: "actions",
    // },
  ];
  
  
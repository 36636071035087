import React, { useState, useEffect } from 'react'
import { Box, Text, Button, useToast } from "@chakra-ui/react"
import InputField from "../../../../components/fields/InputField";
import { inviteAccountAdmin, updateAccountAdmin } from '../../../../services/businessServices';


function InviteAccountAdmin({ accountUuid, accountAdminData, reListAccountAdmin }) {
    const toast = useToast();
    const [error, setError] = useState({});
    const [loading, isLoading] = useState(false);
    const [accountAdminUuid, setAccountAdminUuid] = useState(null);
    const [inviteAdmin, setInviteAdmin] = useState({
        link_account_uuid: accountUuid,
        first_name: "",
        last_name: "",
        admin_email: "",
    });

    useEffect(() => {
        if (accountAdminData !== null || accountAdminData !== undefined) {
            let obj = {
                link_account_uuid: accountUuid,
                first_name: accountAdminData?.first_name,
                last_name: accountAdminData?.last_name,
                admin_email: accountAdminData?.email,
            }
            setInviteAdmin(obj)
            setAccountAdminUuid(accountAdminData?.uuid)
        }
        else {
            setAccountAdminUuid(null)
        }
    }, [accountAdminData])

    // Todo:Update Object 
    const onUpdateField = (e) => {
        const nextFormState = {
            ...inviteAdmin,
            [e.target.name]: e.target.value,
        };
        setInviteAdmin(nextFormState);
    };

    // Todo:Validate Form Field
    const validateFieldForm = () => {
        let fields = inviteAdmin;
        let errors = {};
        let formIsValid = true;
        // Supports A-Z, a-Z, 0-9, ., -, _ (dot, hyphen, underscore)
        let emailPattern = new RegExp(
            /^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        );
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "*First Name is required.";
        }
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "*Last Name is required.";
        }
        if (!fields["admin_email"]) {
            formIsValid = false;
            errors["admin_email"] = "*Email is required.";
        } else if (!emailPattern.test(fields["admin_email"])) {
            formIsValid = false;
            errors["admin_email"] = "*Please enter valid email address.";
        }
        setError(errors);
        return formIsValid;
    };

    // TodoLSubmit Data
    const collectAccountAdminData = (event) => {
        event.preventDefault();
        if (validateFieldForm()) {
            isLoading(true);
            if (accountAdminUuid) {
                inviteAdmin.uuid = accountAdminUuid; //Todo:Change key uuid as added in backend to update the data
                updateAccountAdmin(inviteAdmin)
                    .then((res) => {
                        isLoading(false);
                        if (res.result) {
                            toast({
                                title: res.message,
                                status: "success",
                                isClosable: true,
                                duration: 3000,
                            });
                            reListAccountAdmin(true)
                        } else {
                            isLoading(false);
                            toast({
                                title: res.message,
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                            reListAccountAdmin(true)
                        }
                        resetData()
                    })
                    .catch((err) => {
                        isLoading(false);
                        if (err)
                            toast({
                                title:
                                    "Unable to invite account admin due to system error, please contact Lingolet support!!",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                        resetData()

                    });
            }
            else {
                inviteAccountAdmin(inviteAdmin)
                    .then((res) => {
                        isLoading(false);
                        if (res.result) {
                            if (res?.data[0]?.status){
                                toast({
                                    title: res.message,
                                    status: "success",
                                    isClosable: true,
                                    duration: 3000,
                                });
                                reListAccountAdmin(true)
                            } else {
                                toast({
                                    title: res.message,
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                });
                            }
                        } else {
                            isLoading(false);
                            toast({
                                title: res.message,
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                            reListAccountAdmin(true)
                        }
                        resetData()
                    })
                    .catch((err) => {
                        isLoading(false);
                        if (err)
                            toast({
                                title:
                                    "Unable to invite account admin due to system error, please contact Lingolet support!!",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                        resetData()

                    });
            }
        }
    };

    // Todo:Reset fields to invite account 
    const resetData = () => {
        let objData = {
            link_account_uuid: accountUuid,
            first_name: "",
            last_name: "",
            admin_email: "",
        }
        setInviteAdmin(objData)
        setAccountAdminUuid(null)
        setError({})
    }

    return (
        <>
            <Text fontWeight={'600'}>{accountAdminUuid ? 'Update' : 'Invite'} Account Admin</Text>
            <Box w="100%" p="10px" mt="3" bg="#fff" borderRadius={'10px'}>
                <Box w="100%">
                    <InputField
                        mb="0"
                        me="30px"
                        id="first_name"
                        label="First Name"
                        value={inviteAdmin?.first_name}
                        name="first_name"
                        onChange={onUpdateField}
                        placeholder="Enter First Name"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Text fontSize="sm" color={"red"} mb="5" mt="1">
                        {error?.first_name}
                    </Text>
                </Box>
                <Box w="100%">
                    <InputField
                        mb="0px"
                        id="last_name"
                        label="Last Name"
                        value={inviteAdmin.last_name}
                        name="last_name"
                        onChange={onUpdateField}
                        placeholder="Enter Last Name"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Text fontSize="sm" color="red" mb="5" mt="1">
                        {error?.last_name}
                    </Text>
                </Box>
                <Box w="100%">
                    <InputField
                        mb="0px"
                        id="admin_email"
                        label="Email"
                        value={inviteAdmin.admin_email}
                        name="admin_email"
                        onChange={onUpdateField}
                        placeholder="Enter Email"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                        isDisabled={accountAdminUuid ? true : false}
                    />
                    <Text fontSize="sm" color="red" mb="5" mt="1">
                        {error?.admin_email}
                    </Text>
                </Box>
                {accountAdminUuid !== null && accountAdminUuid !== undefined && accountAdminUuid?.length ? (
                    <>
                        <Button
                            colorScheme="green"
                            mt="3"
                            size="sm"
                            borderRadius={"10px"}
                            onClick={(e) => {
                                collectAccountAdminData(e);
                            }}
                            isDisabled={loading ? true : false}
                            isLoading={loading ? true : false}
                        >
                            Update Details
                        </Button>
                        <Button
                            colorScheme="red"
                            mt="3"
                            ml="3"
                            size="sm"
                            borderRadius={"10px"}
                            onClick={(e) => {
                                resetData(e);
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        colorScheme="green"
                        mt="3"
                        size="sm"
                        borderRadius={"10px"}
                        onClick={(e) => {
                            collectAccountAdminData(e);
                        }}
                        isDisabled={loading ? true : false}
                        isLoading={loading ? true : false}
                    >
                        Invite Admin
                    </Button>)}
            </Box>
        </>
    )
}

export default InviteAccountAdmin

import React, { useEffect, useState } from "react";

// Chakra Imports
import { Box, 
    Flex, 
    Avatar,
    Select,
    Spacer, 
    Button,
    Spinner,  
    FormLabel,
    Text,
    Textarea,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,  
    useToast,
    useDisclosure,
    useColorModeValue,
    useColorMode } from "@chakra-ui/react";

// Assets
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import InputField from "../../../components/fields/InputField";

// Component Import
import Card from "../../../components/card/Card";
import {  
    getAllLingoCalls, 
    getBillSettings, 
    getBillEntries, 
    createBillEntry, updateBillEntry, manageBillEntry, pricingBillEntry
 } from "../../../services/adminServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

function BillingManager() {
    const toast = useToast();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const btnPriceRef = React.useRef();
    const { isOpen: isPriceOpen, onOpen: onPriceOpen, onClose: onPriceClose } = useDisclosure();

    const { colorMode } = useColorMode();
    const [userId, setUserId] = useState("");
    const [loading, isLoading] = useState(true);

    const [serviceId, setServiceId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [billEntries, setBillEntries] = useState([]);
    const [billEntry, setBillEntry] = useState(null);

    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const [linkCallsList, setLinkCallsList] = useState([]);
    const [billingServices, setBillingServices] = useState(null);
    const [serviceProviders, setServiceProviders] = useState(null);
    const [billingUnits, setBillingUnits] = useState(null);

    const [error, setError] = useState({});
    const [entryDetails, setEntryDetails] = useState({
        serviceKey: null,
        serviceType: 'any',
        serviceGroup: 'any',
        serviceClass: 'any',
        serviceItem: 'any',
        providerKey: null,
        sourceLangs: 'any',
        targetLangs: 'any'
    });
    const [billEntryUuid, setBillEntryUuid] = useState(null);
    const [saveEntryLoader, setSaveEntryLoader] = useState(false);
        

    useEffect(() => {
        fetchBillingSettings();
    }, []);

    const fetchBillingSettings = () => {
        let obj = {
            info_id: ''
        };
        getBillSettings(obj)
        .then((res) => {
            isLoading(false);
            if (res?.data[0]?.status) {
                setBillingServices(res?.data[0]?.services);
                setServiceProviders(res?.data[0]?.providers);
                setBillingUnits(res?.data[0]?.units);
            } 
        })
        .catch((err) => {

        });
    };

    const fetchBillEntries = (pVal) => {
        if (serviceId != null){
            isLoading(true);
            let obj = {
                service_key: serviceId,
                provider_key: pVal,
                records_per_page: 0,
                page_id: 0,
                records_length: 0,
            };
            getBillEntries(obj)
            .then((res) => {
                isLoading(false);
                if (res?.data[0]?.total_records > 0) {
                    setBillEntries(res?.data[0]?.records);
                } else {
                    setBillEntries([]);
                    isLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                isLoading(false);
            });
        } else {
            toast(toastFunctionToaster('Please select a service first...', "warning"));
        }
    };

    const resetPricingDetails = () => {
        let priceObj = {
            'cost': 1,
            'unit_qty': 1,
            'unit': '',
            'currency': '$',
            'starts': 0,
            'fraction': 0,
            'base_cost': 0,
            'formula': 'none',
            'overhead_val': 10,
            'overhead_info': 'none'
        }
        setBillEntryUuid(null);
        setBillEntry(priceObj);
    }

    const createPricingObject = (fullObj) => {
        let priceObj = {
            'cost': fullObj.cost ? fullObj.cost : 1,
            'unit_qty': fullObj.unit_qty ? fullObj.unit_qty : 1,
            'unit': fullObj.unit,
            'currency': '$',
            'starts': fullObj.starts ? fullObj.starts : 0,
            'fraction': fullObj.fraction ? fullObj.fraction : 0,
            'base_cost': fullObj.base_cost ? fullObj.base_cost : 0.0,
            'formula': fullObj.formula ? fullObj.formula : 'none',
            'overhead_val': fullObj.overhead_val ? fullObj.overhead_val : 10,
            'overhead_info': fullObj.overhead_info ? fullObj.overhead_info : 'none'
        }
        setBillEntryUuid(fullObj?.billing_uuid);
        setBillEntry(priceObj);
    }

    const renderBillingEntries = billEntries !== null && billEntries?.length
    ? billEntries?.map((data, index) => (
        <Box
            w={'100%'}
            key={index}
            borderRadius={"10px"}
            p="2"
            mb="3"
            cursor={"pointer"}
            boxShadow={"md"}
            // onClick={() => {
            //     setBillEntryUuid(data?.uuid);
            // }}
        >
        <Flex w={"100%"}>
            <Box w="50px" minWidth={"50px"} px={1} pr={2}>
            <Avatar
                bg={'red'}
                h={{ base: "40px", "2xl": "50px" }}
                w={{ base: "40px", "2xl": "50px" }}
                src={avatar}
                me="16px"
            />
            </Box>
            <Flex w={'100%'} ml={2} direction={"column"} borderLeftWidth={"0.5px"}>
                <Box w={'100%'} pl={2} align={'center'} p={1}
                    borderBottomWidth={'0.5px'}
                    alignItems={'center'}>
                    <Text>{serviceId} service provided by {providerId} </Text>
                </Box>
                <Flex w={'100%'} pl={2}>
                    <Flex direction={"column"} w={"50%"}>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Service Type:</Text> 
                            <Text wordBreak={"break-all"}>
                                {data?.service_type}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Service Group:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.service_group}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Service Class:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.service_class}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Service Item:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.service_item}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Source Language:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.source_langs}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Target Language:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.target_langs}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex borderLeftWidth={"0.5px"}  direction={"column"} w={"50%"} pl={3}>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Cost:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.cost ? data?.cost : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Unit Qty:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.unit_qty ? data?.unit_qty : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Unit:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.unit ? data?.unit : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Currency:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.currency ? data?.currency : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Billing Starts after what usage (qty):</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.starts ? data?.starts : 0}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Fractional Billing (what % usage is billed?):</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.fraction ? data?.fraction : 0}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Base Cost to Add by default:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.base_cost? data?.base_cost : 0}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Billing Formula:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.formula? data?.formula : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Overhead %:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.overhead_val? data?.overhead_val : 'none'}
                            </Text>
                        </Flex>
                        <Flex w={'100%'} fontSize={'sm'} >
                            <Text color={'blue.300'} mr={2}>Overhead Info:</Text> 
                            <Text fontSize={'sm'} wordBreak={"break-all"}>
                                {data?.overhead_info ? data?.overhead_info : 'none'}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} borderTopWidth={'0.5px'} p={1}>
                    <Box w={'50%'} fontSize={'sm'} align={'center'}>
                        <Button size={'xs'} bg={'blue.100'} onClick={()=>{updateEntry(data, entryDetails)}}>Update Entry</Button>
                    </Box>
                    <Box w={'50%'} fontSize={'sm'} align={'center'}>
                        <Button 
                            onClick={() => {
                                createPricingObject(data);
                                onPriceOpen();
                            }}
                            size={'xs'} bg={'blue.100'}>Update Price</Button>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
        </Box>
    ))
    : <Flex w={'100%'} p={2} bg={'red.100'}>
        <Text>No Entries for {serviceId} by {providerId} Provider.</Text>
    </Flex>;

    const renderBillingServices = billingServices !== null && billingServices?.length
        ? billingServices?.map((data, index) => (
            <Box
                key={index}
                borderRadius={"10px"}
                p="2"
                mb="3"
                cursor={"pointer"}
                boxShadow={"md"}
                bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
                borderColor={serviceId === data?.key ? "grey" : SchemeBorderColor}
                borderWidth="3px"
                onClick={() => {
                    setServiceId(data?.key);
                }}
            >
            <Flex w={"100%"}>
                <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                <Avatar
                    h={{ base: "40px", "2xl": "50px" }}
                    w={{ base: "40px", "2xl": "50px" }}
                    src={avatar}
                    me="16px"
                />
                </Box>
                <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
                >
                <Text fontSize={'md'} fontWeight={"bold"} wordBreak={"break-all"}>
                    {data?.key}
                </Text>
                <Text fontSize={'xs'}>
                    {data?.info}
                </Text>
                </Flex>
            </Flex>
            </Box>
        ))
        : null;

    const renderServiceProviders = serviceProviders !== null && serviceProviders?.length
        ? serviceProviders?.map((data, index) => (
            <Box
                key={index}
                borderRadius={"10px"}
                p="2"
                mb="3"
                cursor={"pointer"}
                boxShadow={"md"}
                bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
                borderColor={providerId === data?.key ? "grey" : SchemeBorderColor}
                borderWidth="3px"
                onClick={() => {
                    setProviderId(data?.key);
                    fetchBillEntries(data?.key);
                }}
            >
            <Flex w={"100%"}>
                <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                <Avatar
                    h={{ base: "40px", "2xl": "50px" }}
                    w={{ base: "40px", "2xl": "50px" }}
                    src={avatar}
                    me="16px"
                />
                </Box>
                <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
                >
                <Text fontSize={'md'} fontWeight={"bold"} wordBreak={"break-all"}>
                    {data?.key}
                </Text>
                <Text fontSize={'xs'}>
                    {data?.name}
                </Text>
                </Flex>
            </Flex>
            </Box>
        ))
        : null;

    // Todo:Reset Device Details
    const resetEntryDetails = () => {
        const nextFormState = {
            ...entryDetails,
            ['serviceKey']: null,
            ['providerKey']: null,
            ['serviceType']: 'any',
            ['serviceGroup']: 'any',
            ['serviceClass']: 'any',
            ['serviceItem']: 'any',
            ['sourceLangs']: 'any',
            ['targetLangs']: 'any',
            };
            setEntryDetails(nextFormState);
    };

    // Todo:Reset details on drawer closed
    const closeDrawer = () => {
        onClose();
        setBillEntryUuid(null);
        resetEntryDetails();
    };

    const serviceOptions = billingServices !== null && billingServices?.length
    ? billingServices?.map((data, index) => (
        <option key={index} value={data?.key}>
            {data?.key}
        </option>
    )): null;

    const serviceProviderOptions = serviceProviders !== null && serviceProviders?.length
    ? serviceProviders?.map((data, index) => (
        <option key={index} value={data?.key}>
            {data?.name}
        </option>
    )): null;

    const priceUnitOptions = billingUnits !== null && billingUnits?.length
    ? billingUnits?.map((data, index) => (
        <option key={index} value={data?.key}>
            {data?.name}
        </option>
    )): null;

    //   Todo:Set Form Values
    const onUpdateField = e => {
        const nextFormState = {
            ...entryDetails,
            [e.target.name]: e.target.value,
        };
        setEntryDetails(nextFormState);
    };

    //   Todo:Set Form Values
    const onUpdatePricing = e => {
        const nextFormState = {
            ...billEntry,
            [e.target.name]: e.target.value,
        };
        setBillEntry(nextFormState);
    };

    const validateEntryValues = () => {
        let fields = entryDetails;
        let errors = {};
        let formIsValid = true;
        if (!fields['serviceKey']) {
          formIsValid = false;
          errors['serviceKey'] = '*Service is required.';
        }
        if (!fields['providerKey']) {
            formIsValid = false;
            errors['providerKey'] = '*A Servive Provider is required.';
        }
        if (!fields['serviceType']) {
            formIsValid = false;
            errors['serviceType'] = '*Service Type is required.';
        }
        if (!fields['serviceGroup']) {
            formIsValid = false;
            errors['serviceGroup'] = '*Service Group is required.';
        }
        if (!fields['serviceClass']) {
            formIsValid = false;
            errors['serviceClass'] = '*Service Class is required.';
        }
        if (!fields['serviceItem']) {
            formIsValid = false;
            errors['serviceItem'] = '*Service Item is required.';
        }
        setError(errors);
        return formIsValid;
    }

    const saveEntryDetails = () => {
        if (validateEntryValues){
            let objBody = {
                service_key: entryDetails.serviceKey,
                provider_key: entryDetails.providerKey,
                service_type: entryDetails.serviceType,
                service_group: entryDetails.serviceGroup,
                service_class: entryDetails.serviceClass,
                service_item: entryDetails.serviceItem,
                source_langs: entryDetails.sourceLangs,
                target_langs: entryDetails.targetLangs
            };
            createBillEntry(objBody)
            .then((res) => {
                if (res?.result) {
                    onClose();
                    resetEntryDetails();
                    //fetchAllEntries();
                    toast(toastFunctionToaster(res.message, "success"));
                } else {
                    onClose();
                    resetEntryDetails();
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                onClose();
                resetEntryDetails();
                toast(toastFunctionToaster(err.message, "error"));
            });
        }
    };


    const validatePricingValues = () => {
        return true;
    }
    
    const updateEntryPricing = () => {
        if (validatePricingValues && billEntryUuid != null && billEntry != null){
            let objBody = {
                billing_uuid: billEntryUuid,
                service_cost: billEntry.cost,
                unit_qty: billEntry.unit_qty,
                unit: billEntry.unit,
                currency: '$',
                starts: billEntry.starts,
                fraction: billEntry.fraction,
                base_cost: billEntry.base_cost,
                formula: billEntry.formula,
                overhead_percentage: billEntry.overhead_val,
                overhead_info: billEntry.overhead_info,
                
            };
            pricingBillEntry(objBody)
            .then((res) => {
                if (res?.result) {
                    resetPricingDetails();
                    fetchBillEntries(providerId);
                    toast(toastFunctionToaster(res.message, "success"));
                    onPriceClose();
                } else {
                    onPriceClose();
                    resetPricingDetails();
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                onPriceClose();
                resetPricingDetails();
                toast(toastFunctionToaster(err.message, "error"));
            });
        }
    };

    // Todo:Update Price Entry
    const updateEntry = (data, serviceProvide) => {
        let objBody = {
            serviceKey: serviceId,
            serviceType: data.service_type,
            serviceGroup: data.service_group,
            serviceClass: data.service_class,
            serviceItem: data.service_item,
            providerKey: providerId,
            sourceLangs: data.source_langs,
            targetLangs: data.target_langs
        };
        setEntryDetails(objBody);
        onOpen()
    }

    const closeDrawerPrice = () => {
        onPriceClose();
        setBillEntry(null);
    }

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card w={'100%'} p={1} rounded={'md'}>
                <Flex w={'100%'}>
                    <Spacer />
                    <Button size={'sm'} bg={'green.100'} onClick={onOpen}>
                        Add New Billing Entry
                    </Button>
                </Flex>
            </Card>
            <Card px="0px" p={1} mt={1}>
                <Flex justifyContent={"space-between"}>
                <Box
                    maxW="300px" minW={'300px'}
                    boxShadow={"md"}
                    borderWidth={"1px"}
                    borderStyle="dotted"
                    borderRadius={"10px"}
                    h="calc(100vh - 150px)"
                    borderBottomColor={"gray.400"}
                    bg={colorMode === "dark" ? "#111c44" : null}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Box w={'100%'} align={'center'} p={2}>
                            <Text fontWeight={"500"}>Services List</Text>
                        </Box>
                        {billingServices !== null && billingServices.length ? (
                            <Box p="2">{renderBillingServices}</Box>
                        ) : (
                            <Text p="2" fontWeight={"500"}>
                            No Services Available
                            </Text>
                        )}
                    </Flex>
                </Box>
                <Box
                    w="100%"
                    px={1}
                    boxShadow={"md"}
                    borderWidth={"1px"}
                    borderStyle="dotted"
                    borderRadius={"10px"}
                    h="calc(100vh - 150px)"
                    overflow="auto"
                    borderTopWidth="0px"
                    css={{
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '24px',
                    },
                    }}
                    borderBottomColor={"gray.400"}
                    bg={colorMode === "dark" ? "#111c44" : null}
                >
                    {renderBillingEntries}
                </Box>
                <Box
                    maxW="300px" minW={'300px'}
                    boxShadow={"md"}
                    borderWidth={"1px"}
                    borderStyle="dotted"
                    borderRadius={"10px"}
                    h="calc(100vh - 120px)"
                    borderBottomColor={"gray.400"}
                    bg={colorMode === "dark" ? "#111c44" : null}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Box w={'100%'} align={'center'} p={2}>
                            <Text fontWeight={"500"}>Provider List</Text>
                        </Box>
                        {serviceProviders !== null && serviceProviders.length ? (
                            <Box p="2">{renderServiceProviders}</Box>
                        ) : (
                            <Text p="2" fontWeight={"500"}>
                            No Providers Found
                            </Text>
                        )}
                    </Flex>
                </Box>
                </Flex>
            </Card>
            {/* // Add Project Drawer */}
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    closeDrawer();
                }}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={"1px"}>
                    {billEntryUuid ? "Update" : "Create New"} Billing Entry
                </DrawerHeader>
                <DrawerBody overflow="hidden" mt="5">
                    <Flex w={'100%'} direction={'column'}>
                        <Flex w={'100%'} direction={'column'} mb={5}>
                            <Flex w={'100%'}>
                                <Text fontSize={'sm'} mb={1} fontWeight={500} ml={4}><b>Select Service:</b></Text>
                                <Text color={'red'} ml={1}>*</Text>
                            </Flex>
                            <Select
                                value={entryDetails.serviceKey}
                                name="serviceKey"
                                id={'serviceKey'}
                                placeholder="Select Billing Service"
                                size="md"
                                color={textColor}
                                borderRadius="16px"
                                onChange={e => {
                                    onUpdateField(e);
                                }}
                            >
                                {serviceOptions}
                                {/* <option value="transcription">Transcription</option>
                                <option value="translation">Translation</option>
                                <option value="transcribing">Transcribing</option> */}
                            </Select>
                            {error?.serviceKey ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceKey}
                                </Text>
                            ) : null}
                        </Flex>
                        <Flex w={'100%'} direction={'column'} mb={5}>
                            <Flex w={'100%'}>
                            <Text fontSize={'sm'} mb={1} fontWeight={500} ml={4}><b>Service Provider:</b></Text>
                            <Text color={'red'} ml={1}>*</Text>
                            </Flex>
                            <Select
                                value={entryDetails.providerKey}
                                name="providerKey"
                                id={'providerKey'}
                                placeholder="Select Provider"
                                size="md"
                                color={textColor}
                                borderRadius="16px"
                                onChange={e => {
                                    onUpdateField(e);
                                }}
                            >
                                {serviceProviderOptions}
                            </Select>
                            {error?.serviceKey ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceKey}
                                </Text>
                            ) : null}
                        </Flex>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="serviceType"
                                id="serviceType"
                                value={entryDetails.serviceType ? entryDetails.serviceType : 'any'}
                                onChange={onUpdateField}
                                label="Service Type:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Service Type"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.serviceType ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceType}
                                </Text>
                            ) : null}
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="serviceGroup"
                                id="serviceGroup"
                                value={entryDetails.serviceGroup ? entryDetails.serviceGroup : 'any'}
                                onChange={onUpdateField}
                                label="Service Group:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Service Group"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.serviceGroup ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceGroup}
                                </Text>
                            ) : null}
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="serviceClass"
                                id="serviceClass"
                                value={entryDetails.serviceClass ? entryDetails.serviceClass : 'any'}
                                onChange={onUpdateField}
                                label="Service Class:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Service Class"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.serviceClass ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceClass}
                                </Text>
                            ) : null}
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="serviceItem"
                                id="serviceItem"
                                value={entryDetails.serviceItem ? entryDetails.serviceItem : 'any'}
                                onChange={onUpdateField}
                                label="Service Item:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Service Item"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.serviceItem ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.serviceItem}
                                </Text>
                            ) : null}
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="sourceLangs"
                                id="sourceLangs"
                                value={entryDetails.sourceLangs ? entryDetails.sourceLangs : 'any'}
                                onChange={onUpdateField}
                                label="Source Language (use any for no value):"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Source Language"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.sourceLangs ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.sourceLangs}
                                </Text>
                            ) : null}
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="targetLangs"
                                id="targetLangs"
                                value={entryDetails.targetLangs ? entryDetails.targetLangs : 'any'}
                                onChange={onUpdateField}
                                label="Target Language (use any for no value):"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Target Language"
                                w="100%"
                                maxlength={100}
                            />
                            {error?.targetLangs ? (
                                <Text fontSize="sm" color="red" mb="10px">
                                {error?.targetLangs}
                                </Text>
                            ) : null}
                        </FormLabel>
                    </Flex>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant="outline" mr={3} 
                        onClick={() => {
                            closeDrawer();
                        }}>
                        Cancel
                    </Button>
                    <Button
                    colorScheme="blue"
                    onClick={() => {
                        saveEntryDetails()
                    }}
                    isDisabled={saveEntryLoader ? true : false}
                    isLoading={saveEntryLoader ? true : false}
                    >
                    {billEntryUuid ? "Update" : "Create New"} Billing Entry
                    </Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
            {/* // Update Pricing Drawer */}
            <Drawer
                size="md"
                isOpen={isPriceOpen}
                placement="right"
                onClose={() => {
                    closeDrawerPrice();
                }}
                finalFocusRef={btnPriceRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={"1px"}>
                    Update Entry Pricing
                </DrawerHeader>
                <DrawerBody overflow="hidden" mt="5">
                    {billEntry != null
                    ?<Flex w={'100%'} direction={'column'}>
                        <Flex w={'100'}>
                            <FormLabel ml="2" required>
                                    <InputField
                                        mb={5}
                                        me="30px"
                                        type={'number'}
                                        name="cost"
                                        id="cost"
                                        value={billEntry.cost ? billEntry.cost : 0}
                                        onChange={onUpdatePricing}
                                        label="Service Cost (Only numbers with decimal):"
                                        required="true"
                                        borderRadius="5px"
                                        placeholder="Enter Cost"
                                        w="100%"
                                        maxlength={100}
                                    />
                            </FormLabel>
                            <Text mt={10} fontSize={'lg'}>$</Text>
                        </Flex>
                        <Flex w={'100%'} direction={'column'}>
                            <Flex w={'100%'}>
                                <Text fontSize={'sm'} mb={1} fontWeight={500} ml={4}><b>Unit Quantity ($0.20 for 10 Pages, 10 is unit quantity):</b></Text>
                                <Text color={'red'} ml={1}>*</Text>
                            </Flex>
                            <Flex w={'100%'}>
                                <FormLabel ml="2" required>
                                    <InputField
                                        mb={5}
                                        me="30px"
                                        type={'number'}
                                        name="unit_qty"
                                        id="unit_qty"
                                        value={billEntry.unit_qty ? billEntry.unit_qty : 1}
                                        onChange={onUpdatePricing}
                                        borderRadius="5px"
                                        placeholder="Enter Unit Quantity"
                                        w="100%"
                                        maxlength={100}
                                    />
                                </FormLabel>
                                <Flex w={'100%'} direction={'column'} mb={5}>
                                    <Select
                                        mt={2}
                                        value={billEntry.unit}
                                        name="unit"
                                        id={'unit'}
                                        placeholder="Select Unit"
                                        size="md"
                                        color={textColor}
                                        borderRadius="16px"
                                        onChange={e => {
                                            onUpdatePricing(e);
                                        }}
                                    >
                                        {priceUnitOptions}
                                    </Select>
                                </Flex>
                            </Flex>
                        </Flex>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                type={'number'}
                                name="starts"
                                id="starts"
                                value={billEntry.starts ? billEntry.starts : 0}
                                onChange={onUpdatePricing}
                                label="Billing started after following units usage:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Units after that billing starts (in same unit as above)"
                                w="100%"
                                maxlength={100}
                            />
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                type={'number'}
                                name="fraction"
                                id="fraction"
                                value={billEntry.fraction ? billEntry.fraction : 0}
                                onChange={onUpdatePricing}
                                label="Billing fraction (%) for partial billing (every 15 seconds):"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter fractional billing % in given unit"
                                w="100%"
                                maxlength={100}
                            />
                        </FormLabel>
                        <Flex w={'100'}>
                            <FormLabel ml="2" required>
                                    <InputField
                                        mb={5}
                                        me="30px"
                                        type={'number'}
                                        name="base_cost"
                                        id="base_cost"
                                        value={billEntry.base_cost ? billEntry.base_cost : 0}
                                        onChange={onUpdatePricing}
                                        label="Base Cost (Will be added to the bill from start):"
                                        required="true"
                                        borderRadius="5px"
                                        placeholder="Enter Base Cost to add in the billing to start with"
                                        w="100%"
                                        maxlength={100}
                                    />
                            </FormLabel>
                            <Text mt={10} fontSize={'lg'}>$</Text>
                        </Flex>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="formula"
                                id="formula"
                                value={billEntry.formula ? billEntry.formula : 'none'}
                                onChange={onUpdatePricing}
                                label="Billing Formula to be used to calculate billing"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter Billing formula"
                                w="100%"
                                maxlength={100}
                            />
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                type={'number'}
                                name="overhead_val"
                                id="overhead_val"
                                value={billEntry.overhead_val ? billEntry.overhead_val : 10}
                                onChange={onUpdatePricing}
                                label="Overhead % (Service overhead to charge with pricing):"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter overhead Percentage"
                                w="100%"
                                maxlength={100}
                            />
                        </FormLabel>
                        <FormLabel ml="2" required>
                            <InputField
                                mb={5}
                                me="30px"
                                name="overhead_info"
                                id="overhead_info"
                                value={billEntry.overhead_info ? billEntry.overhead_info : 'none'}
                                onChange={onUpdatePricing}
                                label="Overhead Details:"
                                required="true"
                                borderRadius="5px"
                                placeholder="Enter overhead details.."
                                w="100%"
                                maxlength={100}
                            />
                        </FormLabel>
                    </Flex>
                    :<Flex w={'100%'}>
                        <Text>Something is not right...</Text>
                    </Flex>}
                </DrawerBody>
                <DrawerFooter>
                    <Button variant="outline" mr={3} 
                        onClick={() => {
                            closeDrawerPrice();
                        }}>
                        Cancel
                    </Button>
                    <Button
                    colorScheme="blue"
                    onClick={() => {
                        updateEntryPricing()
                    }}
                    isDisabled={saveEntryLoader ? true : false}
                    isLoading={saveEntryLoader ? true : false}
                    >
                        Update Pricing
                    </Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

export default BillingManager;


// Chakra React Imports
import React, { useState } from "react";
import {
	Icon,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
	Flex,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";

import {
    AiOutlineCheckCircle,
	AiOutlineBulb,
	AiOutlineYoutube,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync,
    AiOutlineHolder,
    AiTwotoneAudio,
    AiOutlineVideoCamera,
    AiOutlineClockCircle
} from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";
import TabTwoHelper from "./TabComponent/TabTwoHelper";
import TabOneHelper from "./TabComponent/TabOneHelper";
import { defaultThemeColor } from "../../../constants";
import TabThreeHelper from "./TabComponent/TabThreeHelper";
import { getHelpFileContent } from "../../../services/sharedServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function Helper() {
	const [activeTab, setActiveTab] = useState(0);
	const brandBg = useColorModeValue("blue.50", "navy.900");
	const toast = useToast()
	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} mx={3}>
				<Tabs
					w="100%"
					borderBottom={"0px"}
					mx="auto"
					onChange={handleTabChange}
					index={activeTab}
				>
					<TabList border="0px" w="100%" h="5%">
						<Flex justifyContent={"space-between"} w="100%">
							<Flex>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineCheckCircle} mr={1} />
									Help Content
								</Tab>

								<>
									<Tab
										_selected={{
											color: defaultThemeColor,
											fontWeight: 600,
											borderBottomColor:
												defaultThemeColor,
										}}
										fontSize="16px"
										px="6"
										py="2"
									>
										<Icon as={AiOutlineBulb} mr={1} />
										How to? Section
									</Tab>
									<Tab
										_selected={{
											color: defaultThemeColor,
											fontWeight: 600,
											borderBottomColor:
												defaultThemeColor,
										}}
										fontSize="16px"
										px="6"
										py="2"
									>
										<Icon as={AiOutlineYoutube} mr={1} />
										Walkthrough Videos
									</Tab>
								</>
							</Flex>
							<Flex
								p="1"
								rounded={'md'}
								bg={'blue.100'}
								align={'center'}
								borderRadius={"10px"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Icon color={defaultThemeColor} as={AiOutlineMail} ml={1} />
								<Text
									px={4}
									p={1}
									as="span"
									ml="3"
									color={defaultThemeColor}
									fontWeight={"600"}
								>
									Email For Help
								</Text>
							</Flex>
							{}
						</Flex>
					</TabList>
					<TabPanels mt="1%" h="94%">
						<TabPanel p="0" h="100%">
							<TabOneHelper />
						</TabPanel>
						<TabPanel p="0">
							<TabTwoHelper />
						</TabPanel>
						<TabPanel p="0" h="100%">
							<TabThreeHelper />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default Helper;

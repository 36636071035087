import { Button } from '@chakra-ui/react';
import { Document, Page, PDFDownloadLink, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react'
import { memo } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import moment from 'moment'

const BillingPdf = memo(({ data, selectedRange }) => {
    function getFileName(selectedRange, prefix = "billing") {
        if (!selectedRange || selectedRange.length !== 2) {
          throw new Error("Invalid date range provided");
        }
      
        const [start, end] = selectedRange;
        const startDate = moment(start).format("MM-DD-YYYY");
        const endDate = moment(end).format("MM-DD-YYYY");
      
        const fileName = `${startDate} - ${endDate}_${prefix}.pdf`;
        return fileName;
      }


    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#f2f8f9',
            padding: 20,
            fontFamily: 'Helvetica',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        tableHeader: {
            backgroundColor: '#AEE1E8',
            padding: 8,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 10,
        },
        tableCell: {
            padding: 8,
            textAlign: 'center',
            borderBottom: '1px solid #ddd',
            fontSize: 7,
            width: "13.9%"
        },
        tableRow: {
            width: "100%",
            flexDirection: 'row',
        },
    });
    const fileName = getFileName(selectedRange);
    return (
        <PDFDownloadLink
            document={<Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        {/* Table Header */}
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.tableHeader]}>START TIME</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>END TIME</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>DURATION</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>PROVIDER</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>TARGET LANGUAGE</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>CALL TYPE</Text>
                            <Text style={[styles.tableCell, styles.tableHeader]}>EMAIL</Text>
                        </View>
                        {
                            data?.map(item => (
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCell}>{moment(item?.start_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                                    <Text style={styles.tableCell}> {moment(item?.end_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                                    <Text style={styles.tableCell}> {item?.duration}</Text>
                                    <Text style={styles.tableCell}>{item?.provider}</Text>
                                    <Text style={styles.tableCell}>{item?.target_language}</Text>
                                    <Text style={styles.tableCell}>{item?.call_type}</Text>
                                    <Text style={styles.tableCell}>{item?.subscriber || '...'}</Text>
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>}
            fileName={fileName}
        >
            {({ loading }) => (
                <Button rounded={'lg'} size="sm" leftIcon={<AiOutlineFilePdf />} colorScheme="red" isLoading={loading}>PDF</Button>
            )}
        </PDFDownloadLink>

    )
})

export default BillingPdf
export const INIT_CURRENT_SELECTION = 'active'
export const INIT_PAGE = 1
export const INIT_RECORDS_PER_PAGE = 10

export const LINGO_LINKS_FILTER = {
    ACTIVE:"active",
    CLOSED:"closed"
}

export const LINGO_CARD_FILTER = {
    ACTIVE:"active",
    CLOSED:"closed"
}

export const CALL_LIST_TYPE = {
    ONLY_CALLS:"callOnly",
    CALLS_WITH_SESSION:"callWithSession"
}
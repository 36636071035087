// Chakra React Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    AiOutlineBackward,
    AiOutlineUserAdd,
    AiOutlineBlock,
} from "react-icons/ai";
import {
    Spinner,
    Box,
    Avatar,
    Image,
    Heading,
    Icon,
    Spacer,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Flex,
} from "@chakra-ui/react";


// Common Components
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";
import { useBrandBg } from "../../../theme/globalColorTheme";

// Component Imports
import LocationDetails from "./LocationDetails";
import SubscriberManagement from "./SubscriberManagement";
import SubscriberLingoLinks from "./SubscriberLingoLinks";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import KioskAiBg from '../../../assets/communicator/kiosk-ai.png';
import LiveAiBg from '../../../assets/communicator/live-ai.png';

import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import TrustCenter from "../../common/trustCenter/TrustCenter";

// Api Services
import { getAccountQuotaList } from "../../../services/accountServices";


function LocationsSubscribers() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const brandBg = useBrandBg();
    
    const [activeTab, setActiveTab] = useState(0);
    const locationDetails = state?.locationDetails;
    const parentInfo = state.parentData ? state.parentData : null;
    const accountUuid = locationDetails?.link_account_uuid ? locationDetails?.link_account_uuid : null;
    const accountInfo =  state?.accountInfo ? state?.accountInfo : [];
    const [accountQuota, setAccountQuota] = useState([]);
    const [quotaData, setQuotaData] = useState([])
    const [loading, isLoading] = useState(true);

    // Todo:Get Quota Data
    useEffect(()=>{
        collectQuotaData();
    },[])
    
    const collectQuotaData = () => {
        if(accountUuid && locationDetails?.link_location_uuid) {
            isLoading(TrustCenter)
            let obj = {
                "account_uuid": accountUuid,
                "location_uuid": locationDetails?.link_location_uuid
            }
            getAccountQuotaList(obj).then((res)=>{
                isLoading(false)
                if(res?.data[0]) {
                    setQuotaData(res?.data[0])
                }
                if(res?.data[0]?.account?.length) {
                    setAccountQuota(res?.data[0]?.account)
                }
            }).catch((err)=>{
                isLoading(false)
            })
        }   
    }

    const renderAccountQuota =
    accountQuota?.map((quotaItem, idx) => {
        return (
           <Flex key={idx} pt={'3px'} px="5px">
                <Flex w={'60%'}>
                <Text>{quotaItem?.quota_name}</Text>
                </Flex>
                <Flex w={'60%'}>
                <Text><b>{quotaItem?.quota_value}</b>{' Minutes Monthlty'}</Text>
                </Flex>
            </Flex>
        );
    });

    // Todo:Tab Change
    const handleTabChange = index => {
        setActiveTab(index);
    };

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box mr="2" h="50px" w="50px" mb="3">
                <Image cursor={'pointer'} key={index} mr="1" borderRadius="50%" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  else if (name === "li") {
            return LiveAiBg;
        } else if (name === "ki") {
            return KioskAiBg;
        }
        else return;
	};

    const renderAccountInfo =
    accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex w={'100%'} bg={brandBg} rounded={'md'}  h={'100%'} align={'center'}>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                    {accountData?.link_account_logo
                        ? <Image src={accountData?.link_account_logo} w={100} h={100} /> : null}
                </Flex>
                <Flex w={"110px"}>
                    <Text bg='blue.100' p={1} rounded={'md'} as="span" ml="3">{accountData?.type}</Text>
                </Flex>
                <Flex w={'50%'} direction={'column'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={'50%'}>
                    {accountData?.apps != null ? (
                            <Text mb="2" fontWeight='600' textAlign={'center'}>Supported Apps:
                                {accountData?.apps != null
                                ? <Flex> 
                                    {renderAppData(accountData?.apps)} 
                                </Flex>
                                : <Text fontWeight={'bold'}>No apps</Text>}
                            </Text>
                            
                        ) : (
                            <Text>No Apps</Text>
                    )}
                </Flex>
                <Flex w={'50%'} mr="3" overflow="hidden">
                    {loading ? (
                        <Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="70vh">
                            <Spinner/>
                        </Flex>
                    ) : (
                        accountQuota && accountQuota.length > 0 ? (
                            <Card w={'100%'} rounded={'lg'} p={1}>
                                {renderAccountQuota}
                            </Card>
                        ) : (
                            <Card w={'100%'} rounded={'lg'} p={1}>
                                <Text>No Account quota info..</Text>
                            </Card>
                        )
                    )}
                </Flex>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                    {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>

            </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to collect Account Info
                </Heading>
            </Flex>
        );


    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt={2} >
            <Card w={"100%"} rounded={"lg"} mt={1} p={1} h={'100px'}>
                {renderAccountInfo}
            </Card>
            <Card w={"100%"} rounded={"lg"}  p={1} mt={1}>
                <Tabs w="100%" borderBottom={"0px"} mx="auto" onChange={handleTabChange} index={activeTab}>
                    <TabList border="0px" w="100%" h="40px">
                        <Flex w="100%">
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineBlock} mr={1} />
                                Account Location or Sub Location Details
                            </Tab>
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineUserAdd} mr={1} />
                                Subcriber Management
                            </Tab>
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineUserAdd} mr={1} />
                                Subcriber LingoLinks
                            </Tab>
                            <Spacer />
                            <Flex
                                p="1"
                                _hover={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate("/account/locations", {
                                    });
                                }}
                                rounded={"md"}
                                bg={"blue.100"}
                                align={"center"}
                                borderRadius={"10px"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Icon color={defaultThemeColor} as={AiOutlineBackward} ml={1} />
                                <Text px={4} p={1} as="span" ml="3" color={defaultThemeColor} fontWeight={"600"}>
                                    Back to Locations List
                                </Text>
                            </Flex>
                            { }
                        </Flex>
                    </TabList>
                    <TabPanels h="94%" p={2}>
                        <TabPanel p={0} h="100%">
                            <LocationDetails 
                                parentInfo={parentInfo}
                                locationDetails={locationDetails} 
                                apps={accountInfo[0]?.apps}
                                state= {state}
                                quotaDetails={quotaData}/>
                        </TabPanel>
                        <TabPanel p={0} h="100%">
                            <SubscriberManagement 
                                locationDetails={locationDetails} 
                                state= {state}
                                accountApp={accountInfo[0]?.apps?accountInfo[0]?.apps:[]}/>
                            {/* {accountUuid ? (
                                <SubscriberManagement 
                                    locationDetails={locationDetails} 
                                    accountApp={accountInfo[0]?.apps?accountInfo[0]?.apps:[]}/>
                            ) : <Text>XXX</Text>} */}
                        </TabPanel>
                        <TabPanel p={0} h="100%">
                            <SubscriberLingoLinks 
                                locationDetails={locationDetails} 
                                state= {state}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
        </Flex>
    );
}

export default LocationsSubscribers;

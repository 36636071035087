import { Alert, AlertIcon, Button, 
    Box, 
    Spacer,
    Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getAccAssignedDevices } from '../../../services/accountServices'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import Pagination from '../../../components/pagination/Pagination'
import DeviceData from './DeviceData'
import Card from '../../../components/card/Card'

import { useGreenBg, useLinkColor, useTextColor, 
    usePillButtonTextColorHover,usePillButtonHighLight,
    usePillButtonTextColorSelected, usePillButtonBg, usePillButtonBgHover } from "../../../theme/globalColorTheme";

const DeviceSupport = () => {
    const toast = useToast()
    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [devicesListLoading, setDevicesListLoading] = useState(false)
    const [devicesList, setDevicesList] = useState([])


    const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonBg = usePillButtonBg()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonTextColorHover = usePillButtonTextColorHover()
	const pillButtonText = useTextColor()
    const pillButtonBgHighlight = usePillButtonHighLight()



    function fetchDeviceList() {
        setDevicesListLoading(true)
        let paramObj = {
            page_id: currPage,
            records_per_page: recordsPerPage
        }
        getAccAssignedDevices(paramObj)
        .then(res => {
            if (res?.data[0]?.status) {
                setDevicesList(res?.data[0]?.data)
                setTotalPages(res?.data[0]?.total_pages)
            } else {
                toast(toastFunctionToaster("Failed to get device list", "error"))
                setDevicesList([])
                setTotalPages(0)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get device list", "error"))
            setDevicesList([])
            setTotalPages(0)
        }).finally(() => {
            setDevicesListLoading(false)
        })
    }

    useEffect(() => {
        fetchDeviceList()
    }, [recordsPerPage, currPage])

    useEffect(() => {
        setCurrPage(1)
    }, [recordsPerPage])

    const handleAction = () => {
        fetchDeviceList();
    }

    return (
        <Flex w={'100%'} pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Flex direction="column" w={'100%'}>
                <Card rounded={'lg'} w={'100%'} mt={2} p={1} h={'40px'}>
                    <Flex w={'100%'}>
                        <Spacer />
                        <Button 
                            _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                            color={pillButtonText}
                            bg={pillButtonBg}
                            onClick={() => fetchDeviceList()} 
                            rounded={'lg'} 
                            size={'xs'}>Reload
                        </Button>
                    </Flex>
                </Card>            
                <Card w={'100%'} rounded={'lg'} mt={1} p={1}>
                    <Flex h='calc(100vh - 200px)'>
                        {
                            devicesListLoading ?
                                (<Flex alignItems="center" justifyContent="center" h="full" w="full">
                                    <Spinner size="xl" />
                                </Flex>) : devicesList?.length ? (
                                    <Flex w={'100%'} direction={'column'}>
                                        <Flex w={'100%'} bg={'gray.200'} py={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'} color="black" borderTopRadius="md">
                                            <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Assignment Date</Text>
                                            </Box>
                                            <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Device</Text>
                                            </Box>
                                            <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Serial No.</Text>
                                            </Box>
                                            <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>MAC Address</Text>
                                            </Box>
                                            <Box minW={'60px'} fontSize={'sm'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Locked</Text>
                                            </Box>
                                            {/* <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Assigned User</Text>
                                            </Box> */}
                                            <Box w={'100%'} fontSize={'sm'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Assigned LingoLink</Text>
                                            </Box>
                                            {/* <Box minW={'155px'} fontSize={'sm'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Activation Status</Text>
                                            </Box> */}
                                            {/* <Box minW={'100px'} fontSize={'sm'} ml={1} pl={2}>
                                                <Text fontSize={'xs'}>Activation Code</Text>
                                            </Box> */}
                                        </Flex>          
                                        <Flex direction={'column'}  
                                            overflowX="auto"
                                            h={'calc(100vh - 250px'} 
                                            bg={'blue.100'}
                                            w={'100%'}
                                            overflow="auto"
                                            borderTopWidth="0px"
                                            className="custom-scrollbar"
                                        >                              
                                            <DeviceData 
                                                handleAction={handleAction}
                                                devicesList={devicesList} />
                                        </Flex>
                                    </Flex>
                                ) : (
                                    <Flex w="full" h="fit-content">
                                        <Alert status="warning" mt="4">
                                            <AlertIcon />
                                            No Devices Found
                                        </Alert>
                                    </Flex>
                                )
                        }
                    </Flex>
                </Card>
                {devicesList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
            </Flex>
            {/* <Flex minW={'300px'} maxW={'300px'} ml={1}>
                <Card w={'100%'} mt={2} p={2}>

                </Card>
            </Flex> */}
        </Flex>
    )
}

export default DeviceSupport
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl, InputGroup, InputRightElement,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
} from "@chakra-ui/react";

// Custom components
import { BiHide, BiShow } from 'react-icons/bi';

import CenteredAuth from "../../../layouts/auth/types/Centered";
import { updateDefaultPassword } from '../../../services/sharedServices';


function ChangeDummyPassword() {
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const btnTextColor = useColorModeValue("gray.200", "white");
    const errorColor = '#e6474a';
    const textColorSecondary = "gray.400";
    const brandStars = useColorModeValue("brand.500", "brand.400");
  
    // Declared variables
    const navigate = useNavigate();
    const { state } = useLocation();
    const toast = useToast();
    const [error, setError] = useState({});
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [loading, isLoading] = useState(false);
    const [changePasswordForm, setChangePassword] = useState({
      email: state.email,
      current_password: '',
      new_password: '',
    });
    const [retype_password, setRetypePassword] = useState('');
    const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState('');

    const onUpdateField = e => {
      const nextFormState = {
        ...changePasswordForm,
        [e.target.name]: e.target.value,
      };
      setChangePassword(nextFormState);
    };

    // password validation
    const validateFieldForm = () => {
      let fields = changePasswordForm;
      let errors = {};
      let formIsValid = true;
      const checkWhiteSpace = /^\S*$/;
      const checkLoerCase = /^(?=.*[a-z]).*$/;
      const checkUpperCase = /^(?=.*[A-Z]).*$/;
      const checkNumber = /.*[0-9].*/;
      const isValidLength = /^.{8,}$/;      

      if (!fields['current_password']) {
        formIsValid = false;
        errors['current_password'] = '*Current Password Is Reqired.';
      }
      if (!fields['new_password']) {
        formIsValid = false;
        errors['new_password'] = '*New Password Cannot Be Empty.';
      } else if (!checkWhiteSpace.test(fields['new_password'])) {
        formIsValid = false;
        errors['new_password'] = '*Password must not contain Whitespaces';
      } else if (!checkLoerCase.test(fields['new_password'])) {
        formIsValid = false;
        errors['new_password'] =
          '*Password must have at least one Lowercase Character';
      } else if (!checkUpperCase.test(fields['new_password'])) {
        formIsValid = false;
        errors['new_password'] =
          '*Password must have at least one Uppercase Character';
      } else if (!checkNumber.test(fields['new_password'])) {
        formIsValid = false;
        errors['new_password'] = '*Password must have at least one Number';
      } else if (!isValidLength.test(fields['new_password'])) {
        formIsValid = false;
        errors['new_password'] = '*Password must be minimum 8 characters long';
      }

      if (!retype_password) {
        formIsValid = false;
        setRetypePasswordErrMsg('*Confirm Password Cannot Be Empty.');
      } else if (fields['new_password'] !== retype_password) {
        formIsValid = false;
        setRetypePasswordErrMsg("*New Password didn't match.");
      }
      setError(errors);
      return formIsValid;
    };

    const handleSubmit = event => {
      event.preventDefault();
      if (validateFieldForm()) {
        isLoading(true);
        // TODO - Make sure changePasswordForm['email'] has the value;
        updateDefaultPassword(changePasswordForm)
          .then(res => {
            if (res.result) {
              isLoading(false);
              navigate('/login');
              toast({
                title: res?.message,
                status: 'success',
                isClosable: true,
                duration: 3000,
              });
            } else {
              isLoading(false);

              toast({
                title: res?.message,
                status: 'error',
                isClosable: true,
                duration: 3000,
              });
            }
          })
          .catch(err => {
            isLoading(false);

            if (err)
              toast({
                title: err?.message,
                status: 'error',
                isClosable: true,
                duration: 3000,
              });
          });
      }

      //
    };

    const handleClick1 = () => setShow1(!show1);
    const handleClick2 = () => setShow2(!show2);
    const handleClick3 = () => setShow3(!show3);

  return (
    <CenteredAuth
      image={"linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"}
      cardTop={{ base: "140px", md: "24vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize={{ base: "3xl", md: "36px" }}
            mb="16px"
          >
            Change Default Password
          </Heading>
          <Text>Email: {state.email}</Text>
          <Text
            color={textColorSecondary}
            fontSize="md"
            w={{ base: "100%", lg: "456px" }}
            maxW="100%"
          >
            Currently you have logged into using the default system
                  generated password. Please change your password to your own
                  here, before you can successfuly use the Talent Strap
                  Platform.
          </Text>
        </Box>
        <Box w={'100%'}>
        <form onSubmit={handleSubmit}>
              <FormControl>
                <small color="red">
                  
                  <br />
                  <br />
                </small>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <Input
                    type={show1 ? 'text' : 'password'}
                    value={changePasswordForm.current_password}
                    name="current_password"
                    onChange={onUpdateField}
                    placeholder="Enter Current Password"
                    borderRadius="0px"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick1}
                      bg="none"
                      _hover={{ bg: 'none' }}
                    >
                      {show1 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color={errorColor} mt="2">
                  {error?.current_password}
                </Text>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    type={show2 ? 'text' : 'password'}
                    value={changePasswordForm.new_password}
                    name="new_password"
                    onChange={onUpdateField}
                    placeholder="Enter New Password"
                    borderRadius="0px"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick2}
                      bg="none"
                      _hover={{ bg: 'none' }}
                    >
                      {show2 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Text color={errorColor} mt="2">
                  {error?.new_password}
                </Text>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    type={show3 ? 'text' : 'password'}
                    value={retype_password}
                    name="retype_password"
                    onChange={e => {
                      setRetypePassword(e.target.value);
                    }}
                    placeholder="Confirm New Password"
                    borderRadius="0px"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick3}
                      bg="none"
                      _hover={{ bg: 'none' }}
                    >
                      {show3 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text fontSize="sm" color={errorColor} mt="1">
                  {retypePasswordErrMsg}
                </Text>
              </FormControl>

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                color={btnTextColor}
                type="submit"
                // _hover={{ bg: borderBottomColor }}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Change Password
              </Button>
            </form>
        </Box>
      </Flex>
    </CenteredAuth>
  );
}

export default ChangeDummyPassword;

// React and Chakra Imports
import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Button,
    SimpleGrid,
    Spacer,
    Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';

import {
	MdPerson,
	MdPhone,
	MdMan,
	MdVideoCall,
	MdLocationOn,
} from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";

// Component Imports
import IconBox from "../../../../components/icons/IconBox";
import Card from "../../../../components/card/Card";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import { useTextColor } from '../../../../theme/globalColorTheme';

import { getAccountDbLocationCount} from "../../../../services/accountServices";
import AccountMetrics from "./AccountMetrics";


function LocationMetrics({locationEnable}) {
	const brandColor = useColorModeValue("brand.500", "white");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");

    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

	const [currentChoice, setCurrentChoice] = useState('account');
	const [monthSelect, setMonthSelect] = useState('current');
	const [loading, isLoading] = useState(true);
	const [locationStats, setLocationStats] = useState([]);

	useEffect(() => {
		collectAccountLocationCount(currentChoice);
	}, [currentChoice, monthSelect]);

	const collectAccountLocationCount = (choiceKey) => {
		isLoading(true);
		let paramObj = {
			key: choiceKey,
			month: monthSelect
		};
		getAccountDbLocationCount(paramObj)
        .then(res => {
            if (res?.data?.length > 0) {
                setLocationStats(res?.data[0]?.details);
                isLoading(false);
            } else {
                setLocationStats([]);
                isLoading(false);
            }
        })
        .catch(err => {
            isLoading(false);
        });
	};

	return (
		<Flex w={"100%"} direction={"column"}>
			<Card w={"100%"} mt={2}>
				<Flex w={'100%'}>
					{locationEnable
					?<Text ml={4}>Account and Location Metrics </Text>
					:<Text ml={4}>Account Metrics </Text>}
					<Spacer />
					<Menu>
						<MenuButton mr={2} as={Button} size={'sm'} rightIcon={<ChevronDownIcon />} >{monthSelect? monthSelect === 'current' ?'Current Month' :'Previous Month' : 'Account Usage'}</MenuButton>
						<MenuList>
							<MenuItem 
								onClick={()=> {
									setMonthSelect('current');
									setCurrentChoice('account');
								}}>Current Month</MenuItem>
							<MenuItem 
								onClick={()=> {
									setMonthSelect('previous')
									setCurrentChoice('account');
								}}>Previous Month</MenuItem>
						</MenuList>
					</Menu>
					{/* <Button size={'sm'}  
						onClick={() => {
							setCurrentChoice('account');
						}}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
						bg={pillButtonBg}>Account Usage</Button> */}
					{locationEnable
					?<Button size={'sm'} ml={1}  
						onClick={() => {
							setCurrentChoice('location');
						}}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
						bg={pillButtonBg}>Location Details</Button>
					:null}
				</Flex>
				{currentChoice === 'account'
				?<Flex w={'100%'}>
					<AccountMetrics 
						locationEnable={locationEnable}
						loading={loading}
						accountData={locationStats}
						/>
				</Flex>
				:<Flex w={'100%'}>
					<SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap="10px" mb="10px">
						{/* <MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdLocationOn} color={brandColor} />}
								/>
							}
							name="Total Locations"
							value={locationStats?.total_locations}
							loading={loading}
						/> */}
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdLocationOn} color={'green.500'} />}
								/>
							}
							name="Active Locations"
							value={locationStats?.total_active_locations}
							value2={locationStats?.total_locations}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="28px" h="28px" as={MdLocationOn} color="red.500" />}
								/>
							}
							name="Closed Locations"
							value={locationStats?.total_closed_locations}
							value2={locationStats?.total_locations}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="28px" h="28px" as={MdMan} color={brandColor} />}
								/>
							}
							name="Total Subscribers"
							value={locationStats?.total_subscribers}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="28px" h="28px" as={MdMan } color="green.500" />}
								/>
							}
							name="Active Subscribers"
							value={locationStats?.total_active_subscribers}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="28px" h="28px" as={MdMan} color="red.500" />}
								/>
							}
							name="Disabled Subscribers"
							value={locationStats?.total_disabled_subscribers}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl='/account/locations'
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="28px" h="28px" as={MdMan} color={brandColor} />}
								/>
							}
							name="Subscriber Locations"
							value={locationStats?.total_sub_locations}
							loading={loading}
						/>
					</SimpleGrid>
				</Flex>}
			</Card>
		</Flex>
	);
}

export default LocationMetrics;

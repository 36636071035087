import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, SimpleGrid, useColorModeValue, useToast } from "@chakra-ui/react";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text } from "@chakra-ui/react";
import { toastFunctionToaster } from "../../utils/toastFunction";
import moment from 'moment'
import { addCompany, getCompanyDetails, updateCompany } from "../services/profileServices";

function AddUpdateCompany({setActiveStep,
    setValidSteps,
    step, companyUuid}) {
        console.log(companyUuid)
    const toast = useToast();
    const navigate = useNavigate();
    const [currentDate] = useState(moment().format('YYYY-MM-DD'));

    const [error, setError] = useState({});
    const [submitType, setSubmitType] = useState("Add")
    const [updateDisabled, setUpdateDisabled] = useState(true)
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        company_name: "",
        date_founded: "",
        company_website: "",
        company_sector: "",
        company_theme: "",
        company_description: "",
        amount_raised: "",
        amount_raised_date: "",
        amount_raised_details: ""
    });
    const [intialValues, setInitialValues] = useState()

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    

    // Handle form field updates
    const onUpdateField = e => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
        if(submitType === "update"){
            if(JSON.stringify(intialValues) === JSON.stringify(nextFormState)){
                setUpdateDisabled(true)
            }else{
                setUpdateDisabled(false)
            }
        }
    };

    const validateFieldForm = () => {
        let errors = {};
        let formIsValid = true;
        const requiredFields = [
            "company_name",
            "date_founded",
            "company_website",
            "company_sector",
            "company_theme",
            "company_description",
            "amount_raised",
            "amount_raised_date",
            "amount_raised_details"
        ];

        requiredFields.forEach(field => {
            if (!form[field]) {
                formIsValid = false;
                errors[field] = `*Please enter ${field.replace(/_/g, ' ')}.`;
            }
        });

        setError(errors);
        return formIsValid;
    };

    const handleGetCompanyDetails = () => {
        if(companyUuid){
            const paramObj = {
                "ailab_company_uuid": companyUuid
              }
        getCompanyDetails(paramObj).then(resp=>{
            if(resp.result){
                setForm(resp?.data[0]?.status)
                setInitialValues(resp?.data[0]?.status)
                setSubmitType('update')
                setValidSteps(prevState=>{
                    prevState[step].isStepDone = true
                    return prevState
                })
            }else{
                setSubmitType('Add')
                setForm(null)
                setInitialValues(null)
                toast(toastFunctionToaster("failed to get company details", "error"))
            }
        }).catch(err=>{
            toast(toastFunctionToaster("failed to get company details", "error"))
        })
        }
        
    }

    const handleSubmit = async () => {
        if (validateFieldForm()) {
            if(submitType === "update"){
                const paramObj = {...form, "ailab_company_uuid":companyUuid}
                updateCompany(paramObj).then(resp=>{
                    if(resp.result){
                        setUpdateDisabled(true)
                        handleGetCompanyDetails()
                        toast(toastFunctionToaster("Company updated successfully", "success"))
                        setActiveStep(1)
                    }else{
                        toast(toastFunctionToaster("Failed to updated company", "error"))
                    }
                }).catch(err=>toast(toastFunctionToaster("Failed to updated company", "error")))
            }else{
                addCompany(form).then(resp=> {
                    if(resp.result){
                        toast(toastFunctionToaster("Company added Successfully", "success"))
                        setActiveStep(1)
                    }else{
                        toast(toastFunctionToaster("Failed to add company", "error"))
                    }
                }).catch(err=> toast(toastFunctionToaster("Failed to add company", "error")))
            }
        }
    };

    useEffect(()=>{
        handleGetCompanyDetails()
    }, [companyUuid])



    return (
        <Card p={6} bg="#F7F9FB" boxShadow="sm">
            <FormControl>
                <SimpleGrid columns={2} spacing={10}>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Company Name <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Company Name"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="company_name"
                            onChange={onUpdateField}
                            value={form.company_name}
                        />
                        <Text color="red" mb="2">
                            {error?.company_name}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Date Founded <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}
                            max={currentDate}
                            fontSize="sm"
                            type="date"
                            placeholder="Date Founded"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="date_founded"
                            onChange={onUpdateField}
                            value={form.date_founded}
                        />
                        <Text color="red" mb="2">
                            {error?.date_founded}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Company Website <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Company Website"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="company_website"
                            onChange={onUpdateField}
                            value={form.company_website}
                        />
                        <Text color="red" mb="2">
                            {error?.company_website}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Company Sector <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Company Sector"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="company_sector"
                            onChange={onUpdateField}
                            value={form.company_sector}
                        />
                        <Text color="red" mb="2">
                            {error?.company_sector}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Company Theme <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Company Theme"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="company_theme"
                            onChange={onUpdateField}
                            value={form.company_theme}
                        />
                        <Text color="red" mb="2">
                            {error?.company_theme}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Company Description <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Company Description"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="company_description"
                            onChange={onUpdateField}
                            value={form.company_description}
                        />
                        <Text color="red" mb="2">
                            {error?.company_description}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Amount Raised <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="number"
                            placeholder="Amount Raised"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="amount_raised"
                            onChange={onUpdateField}
                            value={form.amount_raised}
                        />
                        <Text color="red" mb="2">
                            {error?.amount_raised}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Amount Raised Date <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}
                            max={currentDate}
                            fontSize="sm"
                            type="date"
                            placeholder="Amount Raised Date"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="amount_raised_date"
                            onChange={onUpdateField}
                            value={form.amount_raised_date}
                        />
                        <Text color="red" mb="2">
                            {error?.amount_raised_date}
                        </Text></Box>
                    <Box><FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Amount Raised Details <Text as="span" color={brandStars}>*</Text>
                    </FormLabel>
                        <Input
                            isRequired={true}

                            fontSize="sm"
                            type="text"
                            placeholder="Amount Raised Details"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="amount_raised_details"
                            onChange={onUpdateField}
                            value={form.amount_raised_details}
                        />
                        <Text color="red" mb="2">
                            {error?.amount_raised_details}
                        </Text></Box>
                </SimpleGrid>
                <Button
                    fontSize="sm"
                    colorScheme="blue"
                    fontWeight="bold"
                    h="50"
                    onClick={() => {
                        handleSubmit();
                    }}
                    isDisabled={submitType === "update" ? updateDisabled || loading : loading}
                    isLoading={loading}>
                    {submitType === "update" ? 'Update Company' : 'Add Company'}
                </Button>
            </FormControl>
        </Card>

    );
}

export default AddUpdateCompany;

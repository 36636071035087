import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { Card, Heading, SimpleGrid, useColorModeValue, useToast } from "@chakra-ui/react";
import { Box, Button, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { toastFunctionToaster } from "../../utils/toastFunction";
import AddUpdateRefrenceForm from "./AddRefrenceForm";
import { addApplication, getApplication, UpdateApplication } from "../services/profileServices";

function AddUpdateApplication({ companyUuid, userUuid, application_id }) {
    const toast = useToast();
    const navigate = useNavigate();
    
    const [error, setError] = useState({});
    const [refrenceFormError, setRefrenceFormError] = useState({})
    const [submitType, setSubmitType] = useState("Add");
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        application_name: "",
        motivation: "",
        application_advantage: ""
    });
    const [initialValues, setInitialValues] = useState();
    const [refrenceForms, setRefrenceForms] = useState([]);
    const [intialRefrenceForm, setInitialrefrenceForm] = useState([])


    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");

    useEffect(()=>{
        setForm({
            application_name: "",
            motivation: "",
            application_advantage: ""
        })
        setRefrenceForms([])
        setError({})
        setRefrenceFormError({})
        setInitialValues([])
        setInitialrefrenceForm([])
        if(application_id === undefined){
            setSubmitType("Add")
        }else{
            setSubmitType("update")
            handleGetApplicationDetails()
            setUpdateDisabled(true)
        }
    },[application_id])

    // Handle form field updates
    const onUpdateField = (e) => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
        if (submitType === "update") {
            if (JSON.stringify(initialValues) === JSON.stringify(nextFormState)) {
                setUpdateDisabled(true);
            } else {
                setUpdateDisabled(false);
            }
        }
    };

    const checkRefrenceFormUpdated = (newFormData) => {
        console.log("called")
        console.log(JSON.stringify(intialRefrenceForm))
        console.log(JSON.stringify(newFormData))
        if ( JSON.stringify(intialRefrenceForm) === JSON.stringify(newFormData)) {
            setUpdateDisabled(true);
        } else {
            setUpdateDisabled(false);
        }
    }

    const handleAddRefrenceForm = () => {
        setRefrenceForms([...refrenceForms, { id: Date.now(), formData: { full_name: '', linkedin_url: '', email: '' } }]);
        checkRefrenceFormUpdated([...refrenceForms, { id: Date.now(), formData: { full_name: '', linkedin_url: '', email: '' } }])
      };

    const validateFieldForm = () => {
        let errors = {};
        let refrenceFormErrors = {}

        let formIsValid = true;
        const requiredFields = [
            "application_name",
            "motivation",
            "application_advantage"
        ];

        requiredFields.forEach((field) => {
            if (!form[field]) {
                formIsValid = false;
                errors[field] = `*Please enter ${field.replace(/_/g, ' ')}.`;
            }
        });

        refrenceForms.forEach((form) => {
            refrenceFormErrors[form.id] = {}
            Object.entries(form.formData).map(([key,value])=>{
                if(!value){
                    refrenceFormErrors[form.id][key] = `${key} is required`
                    formIsValid = false
                }
            })
          });
        setRefrenceFormError(refrenceFormErrors)
        setError(errors);
        return formIsValid;
    };

    const handleGetApplicationDetails = () => {
        if(application_id){
            let paramObj = 
                {
                    "ailab_company_uuid": companyUuid,
                    "application_uuid": application_id
                  }
            
            getApplication(paramObj).then(resp=>{
                if(resp?.data[0]?.status?.application_name){
                    let formObj = {
                        application_name: resp?.data[0]?.status?.application_name,
                        motivation: resp?.data[0]?.status?.motivation,
                        application_advantage: resp?.data[0]?.status?.application_advantage
                    }
                    setForm(formObj)
                    setInitialValues(formObj)
                    const refrencesFromResp = resp?.data[0]?.status?.reference_details?.replace(/'/g, '"');
                    const referenceDetails = JSON.parse(refrencesFromResp);
                    const refrenceDetailsArray =  referenceDetails.map((item, index)=>{
                        return {
                            formData:item,
                            id:Date.now()-index
                        }
                    })
                    
                    setRefrenceForms(refrenceDetailsArray)
                    setInitialrefrenceForm(refrenceDetailsArray)
                }else{

                }
            })
        }
    };

    const handleSubmit = async () => {
        if(validateFieldForm()){
            if(submitType === "update"){
                let refrenceDetails = refrenceForms.map(item=>{
                    return item.formData
                })
                let paramObj = {
                        "ailab_company_uuid": companyUuid,
                        "application_name": form.application_name,
                        "founder_uuid": userUuid,
                        "outlier_spike": "",
                        "motivation": form.motivation,
                        "application_advantage": form.application_advantage,
                        "application_progress": "Submitted",
                        "reference_details": refrenceDetails,
                        "application_uuid":application_id
                      }

                UpdateApplication(paramObj).then(resp=>{
                    if(resp?.data[0]?.status){
                        toast(toastFunctionToaster("Successfully updated application", "success"))
                        handleGetApplicationDetails()
                        setUpdateDisabled(true)
                        navigate('/ailab/applicant/proposal/list')
                    }else{
                        toast(toastFunctionToaster("failed to Update Application", "error"))
                    }
                }).catch(err=>toast(toastFunctionToaster("failed to Update Application", "error")))
            }else{
                let refrenceDetails = refrenceForms.map(item=>{
                    return item.formData
                })
                let paramObj = {
                        "ailab_company_uuid": companyUuid,
                        "application_name": form.application_name,
                        "founder_uuid": userUuid,
                        "outlier_spike": "",
                        "motivation": form.motivation,
                        "application_advantage": form.application_advantage,
                        "application_progress": "Submitted",
                        "reference_details": refrenceDetails
                      }
                addApplication(paramObj).then(resp=>{
                    if(resp?.data[0]?.status){
                        toast(toastFunctionToaster("Application sent Successfully", "success"))
                        navigate('/ailab/applicant/proposal/list')
                    }else{
                        toast(toastFunctionToaster("Failed to send Application", "error"))
                    }
                }).catch(err=>toast(toastFunctionToaster("Failed to send Application", "error")))
            }
            
        }
    };

    const handleRefrenceFormChange = (id, event) => {
        if(submitType === "update"){

            const newForms = refrenceForms.map(form => {
              if (form.id === id) {
                return { ...form, formData: { ...form.formData, [event.target.name]: event.target.value } };
              }
              return form;
            });
            setRefrenceForms(newForms);
            checkRefrenceFormUpdated(newForms)
        }
      };

    useEffect(()=>{
        handleGetApplicationDetails()
    },[])

    

    return (
        <Card p={6} bg="#F7F9FB" boxShadow="sm">
            <FormControl>
                <SimpleGrid columns={2} spacing={10}>
                    <Box>
                        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Application Name <Text as="span" color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            type="text"
                            placeholder="Application Name"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="application_name"
                            onChange={onUpdateField}
                            value={form.application_name}
                        />
                        <Text color="red" mb="2">
                            {error?.application_name}
                        </Text>
                    </Box>
                    <Box>
                        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Motivation <Text as="span" color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            type="text"
                            placeholder="Motivation"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="motivation"
                            onChange={onUpdateField}
                            value={form.motivation}
                        />
                        <Text color="red" mb="2">
                            {error?.motivation}
                        </Text>
                    </Box>
                    <Box>
                        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Application Advantage <Text as="span" color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            type="text"
                            placeholder="Application Advantage"
                            mb="14px"
                            fontWeight="500"
                            size="lg"
                            name="application_advantage"
                            onChange={onUpdateField}
                            value={form.application_advantage}
                        />
                        <Text color="red" mb="2">
                            {error?.application_advantage}
                        </Text>
                    </Box>
                </SimpleGrid>
                <Heading as="h3" fontSize="lg" fontWeight="bold" mb={6}>Add Refrences</Heading>
                    <Box pl={10}>
                    {refrenceForms.map((form) => (
                        <Box mb="4">
                        <AddUpdateRefrenceForm
                            key={form.id}
                            formData={form.formData}
                            onChange={(event) => handleRefrenceFormChange(form.id, event)}
                            error={refrenceFormError[form.id]}
                            setRefrenceForms={setRefrenceForms}
                            id={form.id}
                            checkRefrenceFormUpdated={checkRefrenceFormUpdated}
                        />
                        </Box>
                        
                    ))}
      <Button colorScheme="blue" onClick={handleAddRefrenceForm}>Add New Refrence</Button>
                    </Box>
                <Button
                    fontSize="sm"
                    mt={6}
                    colorScheme="blue"
                    fontWeight="bold"
                    h="50"
                    onClick={() => {
                        handleSubmit();
                    }}
                    isDisabled={submitType === "update" ? updateDisabled || loading : loading}
                    isLoading={loading}>
                    {submitType === "update" ? 'Update Application' : 'Submit Application'}
                </Button>
            </FormControl>
        </Card>
    );
}

export default AddUpdateApplication;

// Chakra Imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Avatar,
	Button,
	Flex,
	Box,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useToast,
	Spinner,
	useColorModeValue,
	useColorMode,
} from "@chakra-ui/react";
// Custom Components
import { MdNotificationsNone, MdInfoOutline, MdDarkMode, MdLightMode, MdZoomInMap, MdZoomOutMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import avatar4 from "../../assets/img/avatars/dummy-avatar.jpg";

import { ItemContent } from "../../components/menu/ItemContent";
import { SearchBar } from "../../components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "../../components/sidebar/Sidebar";
// Assets
import navImage from "../../assets/img/layout/Navbar.png";
import { FaEthereum } from "react-icons/fa";
import Configurator from "../../components/navbar/Configurator";
import routes from "../../sideBarRoutes/horizonRoutes";
import { clearLocalStorage, getEmailId } from "../../utils/localStorageIndex";
import { fetchPersonalInfo, logout } from "../../services/sharedServices";
import { setParentLogo } from "../../store/actions/topNotificationActions";

export default function HeaderLinks(props) {
	const { secondary, theme, setTheme } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue("gray.400", "white");
	let menuBg = useColorModeValue("white", "navy.800");
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const textColorBrand = useColorModeValue("brand.700", "brand.400");
	const ethColor = useColorModeValue("gray.700", "white");
	const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
	const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
	const ethBox = useColorModeValue("white", "navy.800");
	const shadow = useColorModeValue(
		"14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
		"14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
	);
	const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

	const { colorMode, toggleColorMode } = useColorMode();
	const toast = useToast();
	const dispatch = useDispatch();
	const emailId = getEmailId();
	const navigate = useNavigate();
	const emaiId = getEmailId();
	const [loading, isLoading] = useState(true);
	const [userDetails, setUserDetails] = useState({});
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { sideMenuFlag, profileLogoFlag } = sideMenuStatus;

	useEffect(() => {
		isLoading(true);
		let objData = {
			email: emaiId,
		};
		fetchPersonalInfo(objData)
			.then(res => {
				isLoading(false);
				if (res.result) {
					setUserDetails(res.data[0]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	}, [profileLogoFlag]);

	const systemLogout = () => {
		var apiData = { email: emailId };
		isLoading(true);
		logout(apiData)
			.then(res => {
				dispatch(setParentLogo(""));
				isLoading(false);
				if (res.result) {
					toast({
						title: res?.message,
						status: "success",
						isClosable: true,
						duration: 3000,
					});
					navigate("/login");
					clearLocalStorage();
				} else {
					isLoading(false);
				}
			})
			.catch(err => {
				dispatch(setParentLogo(""));
				isLoading(false);
				toast({
					title: "Error",
					status: "error",
					isClosable: true,
					duration: 3000,
				});
			});
	};

	return (
		<Flex
			w={{ sm: "100%", md: "auto" }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
			p="10px"
			borderRadius="999px"
			boxShadow={shadow}>
			<SearchBar
				mb={() => {
					if (secondary) {
						return { base: "10px", md: "unset" };
					}
					return "unset";
				}}
				me="10px"
				borderRadius="20px"
			/>
			{/* <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="20px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="20px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex> */}
			<SidebarResponsive routes={routes} />
			<Menu>
				<MenuButton p="0px">
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: "30px", md: "unset" }}
					minW={{ base: "unset", md: "400px", xl: "450px" }}
					maxW={{ base: "360px", md: "unset" }}>
					<Flex w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							Notifications
						</Text>
						<Text
							bg={"blue.100"}
							p={1}
							rounded={"md"}
							fontSize="sm"
							fontWeight="500"
							color={textColorBrand}
							ms="auto"
							cursor="pointer">
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<Text ml={5}>
							<ul>
								<li>Complete your personal profile..</li>
								<li>Complete your business profile..</li>
							</ul>
						</Text>
						{/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" />
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon Design System Free" />
            </MenuItem> */}
					</Flex>
				</MenuList>
			</Menu>

			{/* <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdInfoOutline}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          me={{ base: '30px', md: 'unset' }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{ base: 'unset' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Image src={navImage} borderRadius="16px" mb="28px" />
          <Flex flexDirection="column">
            <Link w="100%" href="https://horizon-ui.com/pro">
              <Button w="100%" h="44px" mb="10px" variant="brand">
                Buy Horizon UI PRO
              </Button>
            </Link>
            <Link
              w="100%"
              href="https://horizon-ui.com/documentation/docs/introduction"
            >
              <Button
                w="100%"
                h="44px"
                mb="10px"
                border="1px solid"
                bg="transparent"
                borderColor={borderButton}
              >
                See Documentation
              </Button>
            </Link>
            <Link
              w="100%"
              href="https://github.com/horizon-ui/horizon-ui-chakra-ts"
            >
              <Button
                w="100%"
                h="44px"
                variant="no-hover"
                color={textColor}
                bg="transparent"
              >
                Try Horizon Free
              </Button>
            </Link>
          </Flex>
        </MenuList>
      </Menu> */}
			{/* Setting Dark & Light Mode */}
			<Box p="0px">
				<Icon
					mt="6px"
					onClick={() => toggleColorMode()}
					as={colorMode === "dark" ? MdDarkMode : MdLightMode}
					color={navbarIcon}
					w="18px"
					h="18px"
					me="10px"
				/>
			</Box>

			{/* //Avkash Disabled
        <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      /> */}

			<Menu>
				<MenuButton p="0px">
					{loading ? (
						<Flex alignItems="center" justifyContent="center" h="50px" color="#fff">
							<Spinner size="sm" />
						</Flex>
					) : (
						<Image
							borderRadius="50px"
							me={"20px"}
							src={userDetails?.photo_url ? userDetails?.photo_url : avatar4}
							h="48px"
							w="48px"
							objectFit={"cover"}></Image>
					)}
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px" direction={"column"}>
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, {userDetails?.first_name}
							{/* {userDetails?.last_name} */}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						{/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Settings</Text>
            </MenuItem> */}
						<MenuItem
							_hover={{ bg: "none" }}
							_focus={{ bg: "none" }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Text
								fontSize="sm"
								onClick={() => {
									systemLogout();
								}}>
								Log out
							</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

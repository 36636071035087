import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const registerNewBusinessUser = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().registerBusinessUserApi,
    data,
    config
  );
};

export const inviteNewAssociate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().inviteAssociate, data, config);
};

export const fetchAllAssociatesPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().pageinatedAssociatesList,
    data,
    config
  );
};

export const changeAssociateStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceAPIs().associateStatus, data, config);
};

export const updateAssociateLevel = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceAPIs().associateLevel, data, config);
};
export const resetResentPwdCode = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().resetResendCode, data, config);
};

export const fetchBusinessInformation = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchBusinessDetails, data, config);
};

export const addBusinessData = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().addBusiness, data, config);
};

export const updateBusinessData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateBusiness, data, config);
};

export const uploadBusinessLogo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().uploadBusinessLogo, data, config);
};

// Configuration Services
// Goal Configuration
export const addBusinessGoal = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().addGoal, data, config);
};

export const updateBusinessGoal = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateGoal, data, config);
};

export const getBusinessGoals = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchBusinessGoals, data, config);
};

// Role Configuration
export const addBusinessRole = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().addRoles, data, config);
};

export const updateBusinessRole = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateRoles, data, config);
};

export const getBusinessRole = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().fetchRoleDetails, data, config);
};

// --------------------
// Project Services
// --------------------
export const addNewProject = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().createProject, data, config);
};

export const getAllProject = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().fetchProject, data, config);
};

export const updateProjectDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateProject, data, config);
};

export const updateProjectStatusDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateProjectStatus, data, config);
};
export const updateProjectAccessCode = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateProjectAccCode, data, config);
};

export const moduleAllotmentToProject = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().moduleProjectAllotment, data, config);
};

// --------------------
// LingoLinks For Customers
// --------------------
export const getAllCustLinks = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().fetchAllCustLinks, data, config);
};
export const addNewCustLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().createCustLingoLink, data, config);
};
export const resetCodeCustLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().resetCustLingoLink, data, config);
};
export const disableCustLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().disableCustLingoLink, data, config);
};
export const shareCustLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().shareCustLingoLink, data, config);
};

// --------------------
// LingoLinks Services
// --------------------
export const getAllLinks = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().fetchAllLinks, data, config);
};
export const getSingleLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().fetchOneLink, data, config);
};
export const addNewLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().createDynamicLink, data, config);
};
export const disableLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().disableDynamicLink, data, config);
};
export const shareLinkViaEmail = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().shareDynamicLinkViaEmail, data, config);
};
export const shareLinkViaSms = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().shareDynamicLinkViaSms, data, config);
};
export const refreshLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().refreshDynamicLink, data, config);
};
export const resetLeaderCode = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().refreshLeaderCodeLink, data, config);
};
export const mailLeaderCode = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().shareLeaderCode, data, config);
};
export const updateLinkFeature = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().updateDynamicFeatures, data, config);
};
export const updatelingoLink = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().updateLingoLink, data, config);
};
export const getAllLinkSessions = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchAllSessions, data, config);
};
export const getAllUserLinkCalls = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchAllUserCalls, data, config);
};
export const calculateUserLinkCalls = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().calculateUserLinks, data, config);
};
export const getAllLinkCustomers = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchLinkCustomers, data, config);
};
export const getSelectedLinkCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchLinkCustomer, data, config);
};

export const getCustomerAllLinks = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchCustomerLinks, data, config);
};
export const getLinkCustomerEnabled = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchEnabledCustomers, data, config);
};
export const addUpdateLinkCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpLinkCustomer, data, config);
};
export const updateCustomerInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().moreUpLinkCustomer, data, config);
};
export const manageLinkCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().mangLinkCustomer, data, config);
};
export const shareLinkWithCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().shareLnkwithCustomer, data, config);
};
export const getLinkFullDesign = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchLinkDesign, data, config);
};
export const adjustLinkDesign = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageLinkDesign, data, config);
};
export const uploadLinkDesignImages = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().uploadLinkDesign, data, config);
};

export const dynamicLinkTags = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addTagToDynamicLink, data, config);
};

export const getAccAdminCardCallList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().accAdminCallListing, data, config);
};

export const getBusinessAdminCardCallList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().businessCallListing, data, config);
};

export const getSubscriberCardCallList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().subLingoCardCallListing, data, config);
};
// --------------------
// Client Dashboard Services
// --------------------
export const getDbClientLinkCount = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().dbClientLinkCnt, data, config);
};
export const getDbClientProjectCount = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().dbClientProjCnt, data, config);
};

// --------------------
// Device Services
// --------------------
export const getDevBizList = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getBizDevicesApi, data, config);
};
export const getDevBizTeamList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getBizDevTeamApi, data, config);
};
export const assignBizDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assignBizDevApi, data, config);
};
export const fetchGenDevConnLogs = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().bizDevConnLogs, data, config);
};

export const assignDeviceToAcc = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assingDeviceToAccount, data, config);
};
export const assignNoteToAcc = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assingNotesToAccount, data, config);
};

export const assignDeviceToAccAdmin = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assignDeviceToAccAdmin, data, config);
};

export const assignLinkToDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assignLinkToDevice, data, config);
};
export const assignNotesToDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assignNotesToDevice, data, config);
};

export const adminAssignNotesToDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminAddNoteToDeviceAssign, data, config);
};





// export const getBizDeviceDetails = (data, config = null, axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().getDeviceApi, data, config);
// };
// export const businessDeviceManage = (data, config = null, axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().manageAdmDevApi, data, config);
// };

// --------------------
// Business Billing Services
// --------------------
export const getBusinessBillSettings = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().businessBillingSettings,
    data,
    config
  );
};

export const adjustBillingPricing = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adjustBillingPricing, data, config);
};

// export const createBusinessBillEntry = (data,config = null,axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().businessBillingAdd, data, config);
// };

// export const updateBusinessBillEntry = (data,config = null,axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().businessBillingUpdate, data, config);
// };

// export const manageBusinessBillEntry = (data,config = null,axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().businessBillingManage, data, config);
// };

// export const getBusinessBillServices = (data,config = null,axiosInstance = axios) => {
//   return axiosInstance.post(serviceAPIs().lingolinkCallsList, data, config);
// };

export const getBusinessBillEntries = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().businessBillingEntries, data, config);
};

// ------------------------------
// LingoCard Services
// -------------------------------
export const getlingoCardListing = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().lingoCardList, data, config);
};
export const getlingoCardDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().lingoCardDetails, data, config);
};
export const addUpdateLingoCardDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpdateLingoCard, data, config);
};

export const manageCardStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageCardStatus, data, config);
};

export const setupCardDisable = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().disableCardStatus, data, config);
};

export const shareCardWithCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().shareCrdwithCustomer, data, config);
}; //
export const emailCardWithCustomer = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().emailCrdwithCustomer, data, config);
};

// ------------------------------
// Sub-Domain Services
// -------------------------------
export const addSubdomainName = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addSubdomain, data, config);
};
export const delSubdomainName = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().delSubdomain, data, config);
};


// ------------------------
// Feedback Services
// ------------------------
export const storeFeedbackData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().feedback, data, config);
};


// ---------------------------
// Language Associate Services
// ---------------------------
export const fetchAllLanguageAssociatesPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().pageinatedLanguageAssociatesList,
    data,
    config
  );
};

export const inviteNewLanguageAssociate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().inviteLanguageAssociate, data, config);
};

export const changeLanguageAssociateStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().languageassociateStatus, data, config);
};

export const updateLanguageAssociateLevel = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceAPIs().languageassociateLevel, data, config);
};

// Link Design Customer
export const getLinkCustomerDesign = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().fetchCustomerLinkDesignData, data, config);
};


export const adjustCustomerLinkDesign = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageCustomerLinkDesign, data, config);
};

// Customer Card

export const addUpdateCustomerCardDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpdateCustomerCard, data, config);
};

export const getCustomerCardListing = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getCustomerCardList, data, config);
};

export const setupCustomerCardDisable = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().disableCustomerCardStatus, data, config);
};

export const emailCustomerCardDetail = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().emailCardDetails, data, config);
};

//-------- Account Service ------------//
export const fetchAccountDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getAccountList, data, config);
};
export const fetchAccountDescription = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getAccountDesc, data, config);
};

export const fetchAccountInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getAccountInfo, data, config);
};

export const fetchAccountQuota = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getAccountQuota, data, config);
};

export const setNewAccountQuota = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().createNewQuota, data, config);
};

export const manageAccountStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageAccountStatus, data, config);
};

export const addAccountDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().createNewAccount, data, config);
};

export const updateAccountDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateAccount, data, config);
};

export const uploadAccountDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().accountImageUpdate, data, config);
};

export const fetchAccountAdminList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminAccountList, data, config);
};



export const inviteAccountAdmin = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().inviteAccountAdmin, data, config);
};

export const inviteTeamMember = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().inviteTeamMember, data, config);
};

export const updateAccountAdmin = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateAccountAdmin, data, config);
};

export const getAssocTeamMembers = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getTeamMembers, data, config);
};

export const manageAccountAdminStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageAccountAdmStatus, data, config);
};

export const resetAccountAdminPwd = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().resetAccountAdmPwd, data, config);
};

export const fetchTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getTranscript, data, config);
};

export const fetchTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getTranscriptData, data, config);
};

export const saveAccountAppInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().storeAccountAppData, data, config);
};

export const upgradeAccountAdminApps = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccountAdminApps, data, config);
};

export const manageAccountLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageAccLingoCard, data, config);
};


export const manageAccountDocumnetAiSupport = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageDocumnetAi, data, config);
};

export const manageAccountDeviceSupport = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageDeviceSupport, data, config);
};

export const manageAccountScheduleSupport = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageScheduleSupport, data, config);
};


export const manageAccountAssociateSupport = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageAssociateSupport, data, config);
};

export const updateAccountType = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccountType, data, config);
};

export const updateAccountSecureCall = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccountSecureCall, data, config);
};

export const getCallTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getCallTranscript, data, config);
};

export const shareCallTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareCallTranscript, data, config);
};




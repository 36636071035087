import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { saveSessionId, saveEmailId, saveAuthToken, savegroupId } from "../../utils/localStorageIndex";
import { login, getToken, getPageConfig, googleSignOn } from "../../services/sharedServices";
import {ReactComponent as LoginIcon} from '../../assets/svg/googleIcon-48.svg'


// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	useToast,
	InputGroup,
	InputRightElement,
	useColorModeValue,
	Text,
	Image,
} from "@chakra-ui/react";
// Assets
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import CenteredAuth from "../../layouts/auth/types/Centered";
import { useGoogleLogin } from "@react-oauth/google";
import { addProfile } from "../../ailab/services/profileServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

function AddProfile() {
	// Defined variables
	const toast = useToast();
	const navigate = useNavigate();

	const [pageLogo, setPageLogo] = React.useState(null);
	const [pageBackgound, setPageBackground] = React.useState(null);

	const [error, setError] = React.useState({});
	const [loading, isLoading] = React.useState(false);
	const [loginForm, setLoginForm] = React.useState({
		user_email: "",
    first_name:"",
    last_name:""
	});

	const pageBackgroundImages = [
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
	]

	const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;
	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
	const buttonBg = useColorModeValue("blue.200", "gray.600");
	const googleText = useColorModeValue("navy.700", "white");
	const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.300" });
	const googleActive = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.200" });

	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);

	//   Todo:Fetch Business Details
	useEffect(() => {
		if (subdomain != null) {
			let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
			fetchConfDetails(subdomain_val);
		}
	}, []);

	const fetchConfDetails = (sub_domain) => {
		let paramObj = {
			subdomain: sub_domain, //'fostercity' //
		};
		getPageConfig(paramObj)
			.then((res) => {
				if (res?.data?.length > 0) {
					if (res?.data[0]?.logo) {
						setPageLogo(res?.data[0]?.logo);
					}
					if (res?.data[0]?.background) {
						setPageBackground(res?.data[0]?.background)
					}
				}
			})
			.catch((err) => {
				// Do Nothing
			});
	};

	// Todo:Set form value
	const onUpdateField = e => {
		const nextFormState = {
			...loginForm,
			[e.target.name]: e.target.value,
		};
		setLoginForm(nextFormState);
	};

	// Todo:Validation Function
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		let fields = loginForm;
		let emailPattern = new RegExp(/^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
		if (!fields["user_email"]) {
			formIsValid = false;
			errors["user_email"] = "*Please enter email.";
		} else if (!emailPattern.test(fields["user_email"])) {
			formIsValid = false;
			errors["user_email"] = "*Please enter valid email address.";
		}
		if (!fields["first_name"]) {
			formIsValid = false;
			errors["first_name"] = "*Please enter first name.";
		}
    if (!fields["last_name"]) {
			formIsValid = false;
			errors["last_name"] = "*Please enter last name.";
		}
		setError(errors);
		return formIsValid;
	};

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			handleSubmit();
		}
	};

	const handleSubmit = async () => {
		if (validateFieldForm()) {
			addProfile(loginForm).then(resp => {
        if(resp?.result === true){
          toast(toastFunctionToaster(resp?.message, "success"))
          toast({
            title: `Log in using the dummy password that was sent to ${loginForm.user_email}.`,
            status: "success",
            isClosable: true,
            duration: 5000,
          })
          navigate("/login")
        }else{
          toast(toastFunctionToaster("Failed to create profile", "error"))
        }
      }).catch(err=>{
        toast(toastFunctionToaster("Failed to create profile", "error"))
      })
		}
	};


	return (
		<CenteredAuth
			cardTop={{ base: "140px", md: "14vh" }}
			cardBottom={{ base: "50px", lg: "auto" }}
			pageLogo={pageLogo ? pageLogo : null}
			pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[3]}
		>
			<Box w="100%" className="lsp">
				<Flex
					maxW={{ base: "100%", md: "max-content" }}
					w="100%"
					mx={{ base: "auto", lg: "0px" }}
					me="auto"
					justifyContent="center"
					px={{ base: "20px", md: "0px" }}
					flexDirection="column">
					<Box me="auto">
						<Heading color={textColor} fontSize="36px" mb="10px">
							Create Profile
						</Heading>
						<Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
							Enter your Name and email to add profile!
						</Text>
					</Box>
					<Flex
						zIndex="2"
						direction="column"
						w={{ base: "100%", md: "420px" }}
						maxW="100%"
						background="transparent"
						borderRadius="15px"
						mx={{ base: "auto", lg: "unset" }}
						me="auto"
						mb={{ base: "20px", md: "auto" }}>

						<FormControl>
							<FormLabel
								display="flex"
								ms="4px"
								fontSize="sm"
								fontWeight="500"
								color={textColor}
								mb="8px">
								Email<Text color={brandStars}>*</Text>
							</FormLabel>
							<Input
								isRequired={true}
								variant="auth"
								fontSize="sm"
								ms={{ base: "0px", md: "0px" }}
								type="email"
								placeholder="email@businessdomain.com"
								mb="14px"
								fontWeight="500"
								size="lg"
								name="user_email"
								onChange={onUpdateField}
								value={loginForm.user_email}
							/>
							<Text color="red" mb="2">
								{error?.user_email}
							</Text>
							<FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
								First Name<Text color={brandStars}>*</Text>
							</FormLabel>
							<InputGroup size="md">
								<Input
									isRequired={true}
									fontSize="sm"
									ms={{ base: "0px", md: "4px" }}
									placeholder="Enter first name"
									mb="14px"
									size="lg"
									type={"text"}
									variant="auth"
									name="first_name"
									value={loginForm.first_name}
									onChange={onUpdateField}
								/>
							</InputGroup>
							<Text color="red" mb="2">
								{error?.first_name}
							</Text>

              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
								Last Name<Text color={brandStars}>*</Text>
							</FormLabel>
							<InputGroup size="md">
								<Input
									isRequired={true}
									fontSize="sm"
									ms={{ base: "0px", md: "4px" }}
									placeholder="Enter last name"
									mb="14px"
									size="lg"
									type={"text"}
									variant="auth"
									name="last_name"
									value={loginForm.last_name}
									onChange={onUpdateField}
									onKeyPress={handleKeyPress}
								/>
							</InputGroup>
							<Text color="red" mb="2">
								{error?.last_name}
							</Text>
							
							<Button
								fontSize="sm"
								// variant="brand.100"
								bg={buttonBg}
								color={googleText}
								fontWeight="500"
								w="100%"
								h="50"
								mb="24px"
								onClick={() => {
									handleSubmit();
								}}
								isDisabled={loading ? true : false}
								isLoading={loading ? true : false}>
								Create Profile
							</Button>
						</FormControl>
						<Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
							<Text color={textColorDetails} fontWeight="400" fontSize="14px">
								Already have profile?
								<NavLink to="/login">
									<Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
										Login
									</Text>
								</NavLink>
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Box>
		</CenteredAuth>
	);
}

export default AddProfile;
// Chakra Import
import React, { useState, useEffect } from "react";
import {
	Box,
	Flex,
	Text,
	Image,
	useColorModeValue,
} from "@chakra-ui/react";

// Component Import
import Upload from "./components/Upload";
import Card from "../../../components/card/Card";
import { useBrandBg } from "../../../theme/globalColorTheme";
import { getEmailId } from "../../../utils/localStorageIndex";
import lingo_logo from "../../../assets/landingPageImg/kb_logo.png";

// Services Import
import { fetchBusinessInformation } from "../../../services/businessServices";


function BusinessUpload(props) {
	const brandBg = useBrandBg();
	const [businessLogo, setBusinessLogo] = useState("")
	const [businessBg, setBusinessBg] = useState("")
	const borderColor = useColorModeValue("white !important", "#111C44 !important");


	useEffect(() => {
		fetchBusinessDetails();
	}, []);

	// Todo:Check Business logo and  background
	const fetchBusinessDetails = () => {
		var apiData = { email: getEmailId() };
		fetchBusinessInformation(apiData)
			.then(res => {
				if (res.result && res.data[0] !== null && res.data.length) {
					setBusinessLogo(res.data[0].business_logo_url)
					setBusinessBg(res.data[0].login_background)
				} else {
				}
			})
			.catch(err => {
			});
	};

	const reloadAfterUpload = () => {
		fetchBusinessDetails()
	}
	return (
		<Flex
			w="100%"
			h="70vh"
			position="relative"
			p="3"
			borderRadius="15px"
			bg={brandBg}
			ml="2"
			overflow={"hidden"}
			justifyContent={'space-between'}>
			<Card w="49%" p="10px" >
				<Box w="100%" mr="3">
					{businessLogo
						? <Image
							rounded={"lg"}
							mx="auto"
							src={businessLogo}
							h="100px"
							w="200px"
							border="1px solid"
							borderColor={borderColor}
						/>
						: <Image
							rounded={"lg"}
							mx="auto"
							src={lingo_logo}
							h="100px"
							w="300px"
							border="1px solid"
							borderColor={borderColor}
						/>}
					{businessBg ? (
						<Card w={"100%"} mt={5}>
							<Text my={5}>
								You are using the following
								background image as your branding
								image..
							</Text>
							<Image
								mt={5}
								src={
									businessBg
								}
								h={"500px"}
							/>
						</Card>
					) : (
						<Card w={"100%"} bg={"red.100"} mt={5}>
							<Text my={5} color={'#000'}>
								You haven't set any branding
								background image..
							</Text>
						</Card>
					)}
				</Box>
			</Card>
			<Upload
				uploadCompleted={reloadAfterUpload}
				gridArea={{
					base: "3 / 1 / 4 / 2",
					lg: "1 / 3 / 2 / 4",
				}}
				minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
				pe="20px"
				pb={{ base: "100px", lg: "20px" }}
				width="50%"
			/>
		</Flex>
	)
}

export default BusinessUpload
const BasicAiSourceLanguageList = [
    { name: "English", display: "English", code: ["en", 'en-US', 'en-AU', 'en-GB', 'en-NZ', 'en-IN']},
    { name: "Spanish", display: "Spanish", code: ['es', 'es-419'] },
    { name: "Hindi", display: "Hindi", code: ['hi', 'hi-Latn'] },
    { name: "Chinese (Mandarin, Simplified)", display: "Chinese (Mandarin, Simplified)", code: ['zh','zh-CN','zh-Hans'] },
    { name: "Chinese (Mandarin, Traditional)", display: "Chinese (Mandarin, Traditional)", code: ['zh-TW','zh-Hant'] },
    { name: "Czech", display: "Czech", code: ['cs']},
    { name: "Danish", display: "Danish", code: ['da', 'da-DK']},
    { name: "Dutch", display: "Dutch", code: ['nl']},
    { name: "Estonian", display: "Estonian", code: ['et']},
    { name: "Finnish", display: "Finnish", code: ['fi']},
    { name: "Flemish", display: "Flemish", code: ['nl-BE']},
    { name: "French", display: "French", code: ['fr', 'fr-CA']},
    { name: "German", display: "German", code: ['de']},
    { name: "German (Switzerland)", display: "German (Switzerland)", code: ['de-CH']},
    { name: "Greek", display: "Greek", code: ['el']},
    { name: "Hungarian", display: "Hungarian", code: ['hu']},
    { name: "Indonesian", display: "Indonesian", code: ['id']},
    { name: "Italian", display: "Italian", code: ['it']},
    { name: "Japanese", display: "Japanese", code: ['ja']},
    { name: "Korean", display: "Korean", code: ['ko', 'ko-KR']},
    { name: "Latvian", display: "Latvian", code: ['lv']},
    { name: "Lithuanian", display: "Lithuanian", code: ['lt']},
    { name: "Malay", display: "Malay", code: ['ms']},
    { name: "Norwegian", display: "Norwegian", code: ['no']},
    { name: "Polish", display: "Polish", code: ['po']},
    { name: "Portuguese", display: "Portuguese", code: ['pt', 'pt-BR']},
    { name: "Romanian", display: "Romanian", code: ['ro']},
    { name: "Russian", display: "Russian", code: ['ru']},
    { name: "Slovak", display: "Slovak", code: ['sk']},
    { name: "Swedish", display: "Swedish", code: ['sv', 'sv-SE']},
    { name: "Thai", display: "Thai", code: ['th', 'th-TH']},
    { name: "Turkish", display: "Turkish", code: ['tr']},
    { name: "Ukrainian", display: "Ukrainian", code: ['uk']},
    { name: "Vietnamese", display: "Vietnamese", code: ['vi']},
    { name: "Demo", display: "Demo", code: ['dm']}
];

export default BasicAiSourceLanguageList;
import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Button,
	Flex,
	HStack,
	VStack,
	Spinner,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	useToast,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

import Card from "../../../components/card/Card";
import { getEmailId } from "../../../utils/localStorageIndex";

import EditableAssociatesList from "./components/EditableAssociatesList";
import { AssociatesDataHeader } from "./components/AssociatesDataHeader";
import { fetchAllAssociatesPaginated } from "../../../services/businessServices";

import InviteAssociate from "./InviteAssociate";
import TeammemberRole from "./TeammemberRole";
import {useBrandBg} from "../../../theme/globalColorTheme";


function AssociateListing() {
	const toast = useToast();
	const email = getEmailId();
	const [currentPage, setCurrentPage] = useState("view");
	const [tableData, setTableData] = useState([]);
	const [noRecordFlag, setNoRecordFlag] = useState(true);
	const [showInvite, setShowInvite] = useState(false);
	const [loading, isLoading] = useState(true);

	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(false);

	useEffect(() => {
		fetchBusinessAssociatesDetails();
	}, []);

	const fetchBusinessAssociatesDetails = () => {
		isLoading(true);
		let objBody = {
			email: getEmailId(),
			team_only: true,
			records_per_page: 0,
			page_id: 0,
			records_length: 0,
		};
		fetchAllAssociatesPaginated(objBody)
			.then(res => {
				isLoading(false);
				if (res.data.length > 0 && res.result === true) {
					setNoRecordFlag(false);
					setTableData(res.data[0].records);
					setShowInvite(res.data[0].invite);
				} else if (res.result === false) {
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				} else {
					setNoRecordFlag(true);
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			})
			.catch(err => {
				isLoading(false);
				setNoRecordFlag(true);
				toast({
					title: err?.message,
					status: "error",
					isClosable: true,
					duration: 3000,
				});
			});
	};

	const reRenderTableData = data => {
		fetchBusinessAssociatesDetails();
	};

	const reRenderAssociateList = data => {
		if (data) {
			setCurrentPage("view");
			fetchBusinessAssociatesDetails();
		}
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
			<Card px="0px" p={5}>
				{showInvite
				?<HStack w={"100%"} justifyContent={"end"}>
					{currentPage === "view" ? (
						<Button onClick={() => setCurrentPage("invite")}>Invite Associates</Button>
					) : null}
					{currentPage === "invite" ? (
						<Button
							onClick={() => {
								setCurrentPage("view");
								fetchBusinessAssociatesDetails();
							}}>
							Back to Associate List
						</Button>
					) : null}
				</HStack>:null}
			</Card>
			{currentPage === "invite" ? (
				<Card px="0px" p={1} mt={2}>
					<Flex h="70vh">
						<Box
							w={!configurationCollapseFlag ? "95%" : "70%"}
							bg={brandBg}
							p="3"
							borderRadius={"10px"}
							overflow="hidden"
							overflowY="scroll"
						>
							<InviteAssociate 
								// showAddRolePanel={setConfiguationCollapseFlag(true)}
								associateFlag={reRenderAssociateList} 
								currentStatus={currentPage} />
						</Box>
						{configurationCollapseFlag ? (
							<Box
								w="30%"
								position="relative"
								p="3"
								borderRadius="15px"
								bg={brandBg}
								ml="2"
							>
								<Box
									position="absolute"
									top="0"
									right="0"
									mr="2"
									mt="3"
									zIndex="999"
								>
									<AiFillRightCircle
										cursor="pointer"
										size="20px"
										onClick={() => {
											setConfiguationCollapseFlag(
												!configurationCollapseFlag
											);
										}}
									/>
								</Box>
								<Box w="100%" h="95%" bg={brandBg}>
									<TeammemberRole />
								</Box>
							</Box>
						) : (
							<Box
								w="5%"
								p="3"
								borderRadius="10px"
								bg={brandBg}
								h="100%"
								ml="2"
								pl="5"
							>
								<Box w={"100%"} h="100%">
									<AiFillLeftCircle
										cursor="pointer"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
										size="20px"
									/>
									<Text
										fontSize="16px"
										mt="2"
										css={{
											writingMode: "vertical-lr",
											textOrientation: "mixed",
										}}
									>
										Add/Update Team Member Roles
									</Text>
								</Box>
							</Box>
						)}
					</Flex>
				</Card>
			) : null}
			{currentPage === "view" ? (
				<Card px="0px" p={10} mt={2}>
					<Box w={"100%"}>
						{loading ? (
							<Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
								<Spinner size="xl" />
							</Flex>
						) : (
							<>
								<VStack w={"100%"}>
									<Card px="0px">
										{loading ? (
											<Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
												<Spinner size="xl" />
											</Flex>
										) : tableData && tableData.length > 0 ? (
											<>
												<EditableAssociatesList
													emailId={email}
													tableData={tableData}
													columnsData={AssociatesDataHeader}
													onDataReceived={reRenderTableData}
													onDataUpdate={reRenderTableData}
												/>
											</>
										) : (
											<Box w={"100%"} p={5}>
												<Alert borderRadius="8px" status="error" variant="subtle">
													<Flex>
														<AlertIcon />
														<Flex direction="column">
															<AlertTitle mr="12px">Empty Associates List</AlertTitle>
															<AlertDescription>
																There are no Associates!!
															</AlertDescription>
														</Flex>
													</Flex>
												</Alert>
											</Box>
										)}
									</Card>
								</VStack>
							</>
						)}
					</Box>
				</Card>
			) : null}
		</Flex>
	);
}

export default AssociateListing;

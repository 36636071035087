import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Chakra Import
import {
	Box,
	Flex,
	Text,
	Select,
	Button,
	Alert,
	Drawer,
	Spinner,
	AlertIcon,
	FormLabel,
	SimpleGrid,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useColorModeValue,
	useDisclosure,
	Textarea,
	useToast,
	Heading,
	Tooltip,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../components/card/Card";
import Pagination from '../../../components/pagination/Pagination'


// Api Call Imports
import {
	getDevBizList,
	getDevBizTeamList,
	assignBizDevice,
	fetchAccountDetails,
	assignDeviceToAcc,
	assignNoteToAcc,
	fetchAccountAdminList,
	assignDeviceToAccAdmin,
	adminAssignNotesToDevice,
} from "../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { DRAWER_TYPES } from "./constants";

function DevicesList() {
	const toast = useToast();
	const state = useState();
	const navigate = useNavigate();
	const textColor = useColorModeValue("secondaryGray.900", "white");

	const [currPage, setCurrPage] = useState(1)
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [totalPages, setTotalPages] = useState(1)


	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [deviceList, setDeviceList] = useState([]);
	const [deviceUuid, setDeviceUuid] = useState(null);
	const [assignUuid, setAssignUuid] = useState(null);
	const [assignmentError, setAssignmentError] = useState(null);
	const [deviceReturnCustomer, setDeviceReturnCustomer] = useState(false);
	const [teamList, setTeamList] = useState([]);
	const [selectedMember, setSelectedMember] = useState(null);
	const [drawerType, setDrawerType] = useState(DRAWER_TYPES.MANAGE_DEVICE);
	const [accountList, setAccountList] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [accountAdminList, setAccountAdminList] = useState([]);
	const [accAdminListLoading, setAccAdminListLoading] = useState(false);
	const [selectedAccAdmin, setSelectedAccAdmin] = useState(null);
	const [selectedAssignment, setSelectedAssignment] = useState(null);
	const [btnLoading, setBtnLoading] = useState({
		assignDeviceToAccAdmin: false,
	});
	const [note, setNote] = useState(null)

	//   Todo:Fetch all projects
	useEffect(() => {
		fetchAllDevices();
		isLoading(false);
	}, [recordsPerPage, currPage]);

	const fetchAllDevices = () => {
		isLoading(true);
		let paramObj = {
			// filter_key: "string",
			// filter_value: "string",
			records_per_page: recordsPerPage,
			page_id: currPage,
		};
		getDevBizList(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data?.length > 0) {
					setDeviceList(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.total_pages);
				} else {
					setDeviceList([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	function handleNoteChange(e) {
		setNote(e?.target?.value)
	}

	function handleCloseDrawer() {
		setSelectedAssignment(null);
		setNote(null)
		setDrawerType(null)
		onClose();
	}

	function handleAddUpdateNote() {
		setBtnLoading(state => ({ ...state, addUpdateNote: true }))
		const paramObj = {
			device_uuid: selectedAssignment?.deviceObj?.uuid,
			assignment_uuid: selectedAssignment?.uuid,
			note: note
		}
		adminAssignNotesToDevice(paramObj).then(resp => {
			toast(toastFunctionToaster("Note added successfully.", "success"));
			handleCloseDrawer();
			fetchAllDevices();
			isLoading(false);
		})
			.catch(err => {
				toast(toastFunctionToaster("Failed to add note.", "error"));

			}).finally(() => {
				setBtnLoading(state => ({ ...state, addUpdateNote: false }))
			})
	}

	const fetchBusinessTeam = () => {
		let paramObj = {
			records_per_page: 0,
			page_id: 0,
		};
		getDevBizTeamList(paramObj)
			.then(res => {
				if (res?.data?.length > 0) {
					setTeamList(res?.data[0]);
				} else {
					setTeamList([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	function fetchAccountsList() {
		if (accountList.length < 1) {
			let paramObj = {
				key: "active",
				page_id: 1,
				records_per_page: 1000,
				type: "full",
			};
			fetchAccountDetails(paramObj)
				.then(res => {
					if (res?.data[0]?.active_account_count > 0) {
						setAccountList(res?.data[0]?.records);
					} else {
						toast(toastFunctionToaster("No linked Accounts found", "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster("Failed to get linked accounts", "error"));
				});
		}
	}

	function assignDeviceToAccount() {
		const paramObj = {
			device_uuid: deviceUuid,
			acc_uuid: selectedAccount,
			assign_uuid: assignUuid,
		};
		assignDeviceToAcc(paramObj)
			.then(res => {
				if (res?.data[0]?.status) {
					toast(toastFunctionToaster("Successfully assigned device", "success"));
					onClose();
					fetchAllDevices();
				} else {
					toast(toastFunctionToaster("Failed to assign device", "error"));
				}
			})
			.catch(err => {
				toast(toastFunctionToaster("Failed to assign device", "error"));
			});
	}

	const assignTeamMemberFunc = () => {
		let paramObj = {
			device_uuid: deviceUuid,
			user_uuid: selectedMember,
			assign_uuid: assignUuid,
		};
		setAssignmentError(null);
		assignBizDevice(paramObj)
			.then(res => {
				if (res?.data?.length > 0 && res?.data[0]?.status) {
					setAssignmentError(null);
					toast(toastFunctionToaster("Device assignment is completed successfully", "success"));
					fetchAllDevices();
					onClose();
				} else {
					setAssignmentError("Unable to assign the device to selected team member..");
				}
			})
			.catch(err => {
				setAssignmentError("Unable to assign the device to selected team member..");
			});
	};

	const assignTeamMember = () => {
		assignTeamMemberFunc();
	};

	function handleOpenAssignAccountDrawer(item) {
		setDrawerType(DRAWER_TYPES.ASSIGN_ACCOUNT);
		fetchAccountsList();
		setSelectedAccount(null);
		setDeviceUuid(item?.deviceObj?.uuid);
		setAssignUuid(item?.uuid);
		onOpen();
	}

	function getAccAdminsList(account_uuid) {
		setAccAdminListLoading(true);
		let paramObj = {
			link_account_uuid: account_uuid,
			records_per_page: 1000,
			page_id: 1,
		};
		fetchAccountAdminList(paramObj)
			.then(res => {
				if (res?.data[0]?.total_records) {
					setAccountAdminList(res?.data[0]?.records);
				} else {
					setAccountAdminList([]);
				}
			})
			.catch(err => {
				setAccAdminListLoading(false);
				setAccountAdminList([]);
			})
			.finally(() => {
				setAccAdminListLoading(false);
			});
	}

	function showAccAdminsList(item) {
		setDrawerType(DRAWER_TYPES.ACC_ADMIN_LIST);
		getAccAdminsList(item?.assigned_account_uuid);
		setDeviceUuid(item?.deviceObj?.uuid);
		setSelectedAssignment(item?.uuid);
		onOpen();
	}

	function handleAddNote(item) {
        if(item?.business_admin_notes){
            setNote(item?.business_admin_notes)
        }
        setSelectedAssignment(item);
        setDrawerType(DRAWER_TYPES.ADD_UPDATE_NOTE)
        onOpen();
    }

	const renderDevicesList =
		deviceList !== null && deviceList?.length > 0
			? deviceList?.map((item, index) => (
				<Card w={'100%'} p={1} mt={2}>
					<Flex key={index} w={'100%'} direction={'column'} mt={2}>
						<Flex w={"100%"} borderBottomWidth={"0.05px"} borderBottomColor={"gray.200"}>
							<Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
								<Text rounded={"md"} bg={item?.deviceObj?.assigned ? "green.200" : null} pl={2}>
									{moment(item?.assigned_date).format("MMM DD,YYYY,HH:mm a")}
								</Text>
							</Box>
							<Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
								{item?.deviceObj?.type} ({item?.deviceObj?.class})
								<Text fontSize={"xs"}>Serial: {item?.deviceObj?.serial}</Text>
							</Box>
							<Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
								<Text fontSize={"xs"}> {item?.deviceObj?.mac}</Text>
							</Box>
							<Box minW={"50px"} fontSize={"sm"} ml={1} pl={2}>
								{item?.is_locked ? (
									<Text p={1} bg={"green.100"} rounded={"md"}>
										Yes
									</Text>
								) : (
									<Text p={1} bg={"red.100"} rounded={"md"}>
										No
									</Text>
								)}
							</Box>
							<Box minW={"50px"} fontSize={"sm"} ml={1} pl={2}>
								{item?.is_activated ? (
									<Flex w={"100%"} p={1} direction={"column"} bg={"green.100"} rounded={"md"}>
										<Text>Yes</Text>
										<Text fontSize={"xs"}>On ({moment(new Date()).format("MMM DD,YYYY,HH:mm a")})</Text>
									</Flex>
								) : (
									<Text p={1} bg={"red.100"} rounded={"md"}>
										No
									</Text>
								)}
							</Box>
							<Box minW={"100px"} fontSize={"sm"} ml={1} pl={2}>
								{item?.activation_code}
							</Box>
							<Flex
								minW={"200px"}
								fontSize={"sm"}
								pr={2}
								ml={2}
								justifyContent="center"
								alignItems="center"
								gap={1}>
								<Button
									size={"sm"}
									w="100px"
									whiteSpace="normal"
									height="auto"
									blockSize="auto"
									p={2}
									colorScheme="blue"
									onClick={() => handleOpenAssignAccountDrawer(item)}>
									<Text fontSize="x-small">Assign Device to Account</Text>
								</Button>
								<Button
									onClick={() => {
										setDrawerType(DRAWER_TYPES.MANAGE_DEVICE);
										setSelectedMember(null);
										setDeviceUuid(item?.deviceObj?.uuid);
										setAssignUuid(item?.uuid);
										setDeviceReturnCustomer(item?.customer_send);
										fetchBusinessTeam();
										onOpen();
									}}
									size="xs">
									Manage Device
								</Button>
								<Button size="xs">Info</Button>
								{item?.assigned_account_uuid && (
									<Button size="xs" onClick={() => showAccAdminsList(item)}>
										Assign Device
									</Button>
								)}
							</Flex>
						</Flex>
						<Flex w={"100%"} align={'center'}>
							<Flex w={'50%'}>
								<Text fontSize={"xs"} mr={2}>Notes: </Text>
								{item?.business_admin_notes ? <Text fontSize={"sm"} color={'blue.400'}>{item?.business_admin_notes}</Text> : <Text fontSize={"sm"}  >No Notes</Text>}
								{item?.business_admin_notes
									? <Button size={'xs'}
										_hover={{ cursor: "pointer", borderColor: "blue.600", borderWidth: "1px" }}
										p={1}
										borderColor={"gray.400"}
										borderWidth={"1px"}
										rounded={"md"}
										ml={1}
										onClick={()=>handleAddNote(item)}>Update Notes</Button>
									: <Button size={'xs'}
										_hover={{ cursor: "pointer", borderColor: "blue.600", borderWidth: "1px" }}
										p={1}
										borderColor={"gray.400"}
										borderWidth={"1px"}
										rounded={"md"}
										ml={1}
										onClick={()=>handleAddNote(item)}
										>Add Notes</Button>}
							</Flex>
							<Flex w={'25%'}>
								<Flex w={"100%"} fontSize={"sm"} ml={1} pl={2}>
									<Text fontSize="smaller">
										Assigned Account:
										{item?.email_assigned && (item?.email_assigned || "No Info")}
										{item?.assigned_account_name && (
											<Tooltip label={`Assigned account - ${item?.assigned_account_name}`}>
												<Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
													{item?.assigned_account_name}
												</Text>
											</Tooltip>
										)}
									</Text>
								</Flex>
							</Flex>
							<Flex w={'25%'}>
								<Box w={"100%"} fontSize={"sm"} ml={1} pl={2}>
									<Text fontSize="smaller">
										Assigned Account Admin:
										{item?.assigned_acc_admin_email && (
											<Tooltip label={`Account admin - ${item?.assigned_acc_admin_email}`}>
												<Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
													{item?.assigned_acc_admin_email}
												</Text>
											</Tooltip>
										)}
									</Text>
								</Box>
							</Flex>
						</Flex>
					</Flex>
				</Card>
			))
			: null;

	const renderTeamData = teamList?.map((team, index) => (
		<option key={index} value={team.uuid} style={{ color: "black" }}>
			{team.email}
		</option>
	));

	const closeDrawer = () => {
		onClose();
		setDeviceUuid(null);
		setSelectedAccAdmin(null);
		setAccountAdminList([]);
		setAssignmentError(null);
		setSelectedAssignment(null);
	};

	const handleAssignDeviceToAccAdmin = () => {
		const paramObj = {
			device_uuid: deviceUuid,
			user_uuid: selectedAccAdmin,
			assignment_uuid: selectedAssignment,
		};
		setBtnLoading(state => ({ ...state, assignDeviceToAccAdmin: true }));
		assignDeviceToAccAdmin(paramObj)
			.then(resp => {
				if (resp?.data[0]?.status) {
					toast(toastFunctionToaster(resp?.message, "success"));
					fetchAllDevices();
					closeDrawer();
				} else {
					toast(toastFunctionToaster(resp?.message, "error"));
					setAssignmentError();
				}
			})
			.catch(err => {
				toast(toastFunctionToaster(err.message, "error"));
			})
			.finally(() => {
				setBtnLoading(state => ({ ...state, assignDeviceToAccAdmin: false }));
			});
	};

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				<Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
					<Text fontWeight={"500"} fontSize={"18px"}>
						Assigned Devices to your organization
					</Text>
					<Button colorScheme="blue" size="sm" onClick={() => fetchAllDevices()}>
						Refresh
					</Button>
				</Flex>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="xl" />
					</Flex>
				) : deviceList?.length ? (
					<Card w={"100%"} rounded={"lg"} p={0} >
						<Flex h="calc(100vh - 250px)" overflowX="auto">
							<Flex w={"100%"} h={'100%'} direction={"column"}>
								<Flex
									h={'40px'}
									w={"100%"}
									bg={"gray.200"}
									py={2}
									borderBottomWidth={"0.05px"}
									borderBottomColor={"gray.200"}>
									<Box minW={"150px"} fontSize={"xs"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Assignment Date</Text>
									</Box>
									<Box minW={"150px"} fontSize={"xs"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Device</Text>
									</Box>
									<Box minW={"150px"} fontSize={"xs"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Mac Address</Text>
									</Box>
									<Box minW={"50px"} fontSize={"sm"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Locked</Text>
									</Box>
									<Box minW={"50px"} fontSize={"sm"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Activated</Text>
									</Box>
									<Box minW={"100px"} fontSize={"sm"} ml={1} pl={2}>
										<Text fontSize={"xs"}>Activation Code</Text>
									</Box>
									{/* <Box maxW={"120px"} ml={1} pl={2}>
                                        <Text fontSize={"xs"}>Internal Assignment</Text>
                                    </Box> */}
                                    <Box minW={"200px"} fontSize={"sm"} pr={5} align={"end"}>
                                        <Text fontSize={"xs"}>Action</Text>
                                    </Box>
                                </Flex>
                                <Flex direction={'column'}  
                                    overflowX="auto"
                                    h={'calc(100vh - 200px'} 
                                    bg={'blue.100'}
                                    w={'100%'}
                                    overflow="auto"
                                    borderTopWidth="0px"
                                    className="custom-scrollbar"
                                    >
                                    {renderDevicesList}
                                </Flex>
                            </Flex>
                        </Flex>
                        {deviceList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
                    </Card>
				) : (
					<Alert status="warning" mt="4">
						<AlertIcon />
						No Devices Found
					</Alert>
				)}
			</Box>
			{/* // Add Device Drawer */}
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />

				{drawerType === DRAWER_TYPES.MANAGE_DEVICE && (
					<DrawerContent>
						<DrawerCloseButton bg={"gray.200"} />
						<DrawerHeader borderBottomWidth={"1px"}>
							{deviceUuid ? "Manage" : "Create New"} Device
						</DrawerHeader>
						<DrawerBody mt="5">
							{deviceUuid != null && assignUuid != null ? (
								<Flex w={"100%"} direction={"column"} mb={5}>
									<Card w={"100%"} h={"300px"} p={5} borderWidth={"0.5px"} borderColor={"gray.300"}>
										<Heading size={"md"} mb={5}>
											Assign Device to Team member
										</Heading>
										<Text>Here you can assign this device to your team members:</Text>
										{teamList?.length ? (
											<Flex
												w={"100%"}
												direction={"column"}
												p={2}
												rounded={"md"}
												mb={5}
												mt={2}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Select
													value={selectedMember}
													name="selectedMember"
													id={"selectedMember"}
													placeholder="Select Team member from the list"
													size="md"
													color={textColor}
													borderRadius="16px"
													onChange={e => {
														setSelectedMember(e.target.value);
													}}>
													{renderTeamData}
												</Select>
												{assignmentError != null ? (
													<Text mt={2} mb={2}>
														{assignmentError}
													</Text>
												) : null}
												{selectedMember != null ? (
													<Button
														mt={2}
														onClick={() => assignTeamMember()}
														size={"sm"}
														bg={"blue.100"}>
														Assign Device to Selected Teammate
													</Button>
												) : null}
											</Flex>
										) : null}
									</Card>
									{deviceReturnCustomer === false ? (
										<Card
											w={"100%"}
											mt={1}
											h={"300px"}
											p={5}
											borderWidth={"0.5px"}
											borderColor={"gray.300"}>
											<Heading size={"md"} mb={5}>
												Device Return Process
											</Heading>
											<Text>
												If you have returned this device to Lingolet Team, please make sure you
												completed the return process by clicking the{" "}
												<b>"Complete Return Process"</b>:
											</Text>
											<Button
												mt={5}
												onClick={() => assignTeamMember()}
												size={"sm"}
												bg={"blue.100"}>
												Complete Device Return Process
											</Button>
											<Text mt={2} fontSize={"xs"}>
												Note: Please do not select above process if you haven't sent the device
												back to Lingolet Team
											</Text>
										</Card>
									) : (
										<Card
											w={"100%"}
											mt={1}
											h={"300px"}
											p={5}
											borderWidth={"0.5px"}
											borderColor={"gray.300"}>
											<Heading size={"md"} mb={5}>
												Device Return Process
											</Heading>
											<Text>
												You have already completed the device return process. As soon as
												Lingolet get their device back, this device will be removed from your
												list.
											</Text>
										</Card>
									)}
								</Flex>
							) : (
								<Flex w={"100%"}>
									<Text>Error with the device management</Text>
								</Flex>
							)}
						</DrawerBody>
						<DrawerFooter>
							<Button mr={3} bg={"red.100"} onClick={onClose}>
								Cancel
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}

				{drawerType === DRAWER_TYPES.ASSIGN_ACCOUNT && (
					<DrawerContent>
						<DrawerCloseButton bg={"gray.200"} />
						<DrawerHeader borderBottomWidth={"1px"}>Assign Device to Account</DrawerHeader>
						<DrawerBody mt="5">
							{deviceUuid != null && assignUuid != null ? (
								<Flex w={"100%"} direction={"column"} mb={5}>
									<Card w={"100%"} h={"300px"} p={5} borderWidth={"0.5px"} borderColor={"gray.300"}>
										<Heading size={"md"} mb={5}>
											Assign Device to Account
										</Heading>
										<Text>Here you can assign this device to linked Accounts:</Text>
										{accountList?.length ? (
											<Flex
												w={"100%"}
												direction={"column"}
												p={2}
												rounded={"md"}
												mb={5}
												mt={2}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Select
													value={selectedAccount}
													name="selectedAccount"
													id={"selectedAccount"}
													placeholder="Select Account from the list"
													size="md"
													color={textColor}
													borderRadius="16px"
													onChange={e => {
														setSelectedAccount(e.target.value);
													}}>
													{accountList.map(item => (
														<option
															key={item?.link_account_uuid}
															value={item?.link_account_uuid}>
															<Text color="black">{item?.link_account_title}</Text>
														</option>
													))}
												</Select>
												{assignmentError != null ? (
													<Text mt={2} mb={2}>
														{assignmentError}
													</Text>
												) : null}
												{selectedAccount ? (
													<Button
														mt={2}
														onClick={() => assignDeviceToAccount()}
														size={"sm"}
														bg={"blue.100"}>
														Assign Device to Selected Account
													</Button>
												) : null}
											</Flex>
										) : null}
									</Card>
								</Flex>
							) : (
								<Flex w={"100%"}>
									<Text>Error with the device management</Text>
								</Flex>
							)}
						</DrawerBody>
						<DrawerFooter>
							<Button mr={3} bg={"red.100"} onClick={onClose}>
								Cancel
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}

				{drawerType === DRAWER_TYPES.ACC_ADMIN_LIST && (
					<DrawerContent>
						<DrawerCloseButton bg={"gray.200"} />
						<DrawerHeader borderBottomWidth={"1px"}>Assign Device to Account Admin</DrawerHeader>
						<DrawerBody mt="5">
							{deviceUuid != null ? (
								<Flex w={"100%"} direction={"column"} mb={5}>
									<Card w={"100%"} h={"300px"} p={5} borderWidth={"0.5px"} borderColor={"gray.300"}>
										<Heading size={"md"} mb={5}>
											Assign Device to Account Admin
										</Heading>
										<Text>Here you can assign this device to Account Admin:</Text>
										{accountAdminList?.length ? (
											<Flex
												w={"100%"}
												direction={"column"}
												p={2}
												rounded={"md"}
												mb={5}
												mt={2}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Select
													value={selectedAccAdmin}
													name="selectedAccAdmin"
													id={"selectedAccAdmin"}
													placeholder="Select Account Admin from the list"
													size="md"
													color={textColor}
													borderRadius="16px"
													onChange={e => {
														setSelectedAccAdmin(e.target.value);
													}}>
													{accAdminListLoading ? (
														<Spinner size="lg" />
													) : (
														accountAdminList.map(item => (
															<option key={item?.link_account_uuid} value={item?.uuid}>
																<Text color="black">{item?.email}</Text>
															</option>
														))
													)}
												</Select>
												{assignmentError != null ? (
													<Text mt={2} mb={2}>
														{assignmentError}
													</Text>
												) : null}
												{selectedAccAdmin ? (
													<Button
														mt={2}
														onClick={handleAssignDeviceToAccAdmin}
														size={"sm"}
														bg={"blue.100"}
														isLoading={btnLoading?.assignDeviceToAccAdmin}>
														Assign Device to Selected Account Admin
													</Button>
												) : null}
											</Flex>
										) : null}
									</Card>
								</Flex>
							) : (
								<Flex w={"100%"}>
									<Text>Error with the device management</Text>
								</Flex>
							)}
						</DrawerBody>
						<DrawerFooter>
							<Button mr={3} bg={"red.100"} onClick={onClose}>
								Cancel
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}

				{drawerType === DRAWER_TYPES.ADD_UPDATE_NOTE && <DrawerContent>
					<DrawerHeader>{selectedAssignment?.account_admin_notes ? 'Update Note' : 'Add Note'}</DrawerHeader>

					<DrawerBody>
						<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>Note:</Text>
						<Textarea
							value={note}
							onChange={handleNoteChange}
							placeholder='Enter note...'
							size='sm'
							maxLength={200}
						/>
						<Text
							textAlign={"right"}
							fontWeight={"500"}
							fontSize={"sm"}
							mt="2"
						>
							{note?.length || 0}/100
						</Text>
					</DrawerBody>

					<DrawerFooter>
						<Button variant='outline' mr={3} onClick={handleCloseDrawer}>
							Cancel
						</Button>
						<Button colorScheme='blue' onClick={handleAddUpdateNote} isDisabled={!note?.length} isLoading={btnLoading?.addUpdateNote}>{selectedAssignment?.account_admin_notes ? 'Update Note' : 'Add Note'}</Button>
					</DrawerFooter>
				</DrawerContent>}

			</Drawer>
		</>
	);
}

export default DevicesList;

// React and Chakra Imports
import React , { useEffect, useState } from "react";
import {
    Box,
    Text,
    Button,
    SimpleGrid,
    Spacer,
    Flex,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    MdVideocam,
    MdEvent,
    MdLink,
    MdLinkOff,
    MdPhone,
    MdSensors,
} from "react-icons/md";
import { useNavigate, } from "react-router-dom";

// Component Imports
import Card from '../../../../components/card/Card';
import IconBox from "../../../../components/icons/IconBox";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import { useTextColor } from '../../../../theme/globalColorTheme';

import {getAccountDbEventCount} from "../../../../services/accountServices";

function LingoLinkMetrics({ locationEnable}) {
    const navigate = useNavigate();

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");
    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

    const [currentChoice, setCurrentChoice] = useState('account');
    const [eventStats, setEventStats] = useState(null);
    const [eventLoader, setEventLoader] = useState(null);


	useEffect(() => {
		collectAccountEventsCount(currentChoice);
	}, [currentChoice]);


    const collectAccountEventsCount = (choiceKey) => {
        setEventLoader(true);
        let paramObj = {
            key: choiceKey,
            duration: "all"
        };
        getAccountDbEventCount(paramObj)
        .then(res => {
            if (res?.data?.length > 0) {
                setEventStats(res?.data[0]);
                setEventLoader(false);
            } else {
                setEventStats([]);
                setEventLoader(false);
            }
        })
        .catch(err => {
            setEventLoader(false);
        });
    };

    return (
        <Card w={'100%'} mt={5}>
            <Flex w={'100%'}>
                <Text ml={4}>LingoLink Usage</Text>
                <Spacer />
                <Button size={'sm'}  
                    onClick={() => {
                        setCurrentChoice('account');
                    }}
                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                    bg={pillButtonBg}>Account Metrics</Button>
                {locationEnable
                ?<Button size={'sm'} ml={1}  
                    onClick={() => {
                        setCurrentChoice('location');
                    }}
                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                    bg={pillButtonBg}>Location Metrics</Button>
                :null}

                {/* <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                    bg={pillButtonBg}>Overall</Button>
                <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} ml={1} color={blackWhiteColor}
                    bg={pillButtonBg}>Today</Button>
                <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} ml={1} color={blackWhiteColor}
                    bg={pillButtonBg}>This Week</Button>
                <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} ml={1} color={blackWhiteColor}
                    bg={pillButtonBg}>This Month</Button>
                <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} ml={1} color={blackWhiteColor}
                    bg={pillButtonBg}>This Year</Button>
                <Button size={'sm'}  _hover={{ bg: pillButtonBgHighlight, color: "black" }} ml={1} color={blackWhiteColor}
                    bg={pillButtonBg}>Custom Range</Button> */}
            </Flex>
            <Box w={'100%'} mt={2} borderTopColor={'gray.300'} borderTopWidth={'0.5px'}>
                <SimpleGrid
                    mt={5}
                    columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
                    gap='10px'
                    mb='10px'>
                    <MiniStatistics
                        launchUrl='/account/linklisting'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdLink} color={brandColor} />
                                }
                            />
                        }
                        name='Total Links'
                        value={eventStats?.links}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/linklisting'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdLink} color={'green.500'} />
                                }
                            />
                        }
                        name='Active Links'
                        value={eventStats?.active_links}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/linklisting'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdLinkOff} color={'red.300'} />
                                }
                            />
                        }
                        name='Closed Links'
                        value={eventStats?.closed_links}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/account_callhistory'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                                icon={<Icon w='28px' h='28px' as={MdPhone} color='white' />}
                            />
                        }
                        name='Total Calls'
                        value={eventStats?.calls}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/account_callhistory'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={<Icon w='28px' h='28px' as={MdPhone} color={brandColor} />}
                            />
                        }
                        name='Audio Calls'
                        value={eventStats?.audio_calls}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/account_callhistory'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdVideocam} color={brandColor} />
                                }
                            />
                        }

                        name='Video Calls'
                        value={eventStats?.video_calls}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/events'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdEvent} color={brandColor} />
                                }
                            />
                        }

                        name='Total Events'
                        value={eventStats?.total_events}
                        loading={eventLoader}
                    />
                    <MiniStatistics
                        launchUrl='/account/events'
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdSensors} color={brandColor} />
                                }
                            />
                        }

                        name='Total Sessions'
                        value={eventStats?.sessions}
                        loading={eventLoader}
                    />
                </SimpleGrid>
            </Box>
        </Card>
    )
}

export default LingoLinkMetrics;
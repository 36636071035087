import React, { useEffect, useState } from 'react';
import {
  Box,
  Text, Center,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import Card from '../../components/card/Card';
import Footer from '../../komponents/uilayout/Footer';

function EULA() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  

    const [loading, isLoading] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Center>
                <Text fontWeight={'bold'} fontSize={'24px'}>
                    Lingolet End User License Agreement (EULA)
                </Text>
            </Center>
            <Card w={'96%'} px={20} mt={10}>

                <Text>
                IMPORTANT-READ CAREFULLY: THIS END USER LICENSE AGREEMENT (“EULA”) IS A LEGAL AGREEMENT BETWEEN YOU (EITHER AN INDIVIDUAL OR A LEGAL ENTITY) AND LINGOLET, INC. (“LINGOLET”) FOR THE LINGOLET SOFTWARE INSTALLED ONTO THE LINGOLET PRODUCT PURCHASED BY YOU (THE “PRODUCT”), OR LEGALLY DOWNLOADED FROM LINGOLET.COM OR ANY OTHER CHANNEL PROVIDED BY LINGOLET ( “SOFTWARE”).
                </Text>

                <Text mt={5}>
                YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA BY USING THE PRODUCTS CONTAINING THE SOFTWARE, INSTALLING THE SOFTWARE ONTO THE PRODUCTS OR DEVICE CONNECTED TO THE PRODUCTS. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA, DO NOT USE THE PRODUCTS CONTAINING THE SOFTWARE OR DOWNLOAD THE SOFTWARE FROM LINGOLET OR ANY OTHER CHANNEL PROVIDED BY LINGOLET. INSTEAD, YOU MAY RETURN THE PRODUCT TO THE RESELLER WHERE YOU PURCHASED IT FOR A REFUND IN ACCORDANCE WITH THE RESELLER’S APPLICABLE RETURN POLICY.
                </Text>

                <Text mt={5}>
                Section 1. Limited Software License. Subject to the terms and conditions of this EULA, Lingolet grants you a limited, non-exclusive, non-transferable, personal license to install, run and use one copy of the Software loaded on the Product or on your device connected to the Product solely relating to your authorized use of the Product.
                </Text>


                <Text mt={5}>
                Section 2. Documentation. You may make and use a reasonable number of copies of any documentation provided with the Software; provided that such copies will only be used for internal business purposes and are not to be republished or redistributed (either in hard copy or electronic form) to any third party.


                </Text>


                <Text mt={5}>
                Section 3. Backup. You may make a reasonable number of copies of the Software for backup and archival purposes only.


                </Text>


                <Text mt={5}>
                Section 4. Updates. Any software provided to you by Lingolet or made available on the Lingolet website at www.Lingolet.com (“Website”) or any other channel provided by Lingolet that updates or supplements the original Software is governed by this EULA unless separate license terms are provided with such updates or supplements, in which case, such separate terms will govern.


                </Text>


                <Text mt={5}>
                Section 5. License Limitations. The license set forth in Sections 1, 2, and 3 applies only to the extent that you have ordered and paid for the Product and states the entirety of your rights with respect to the Software. Lingolet reserves all rights not expressly granted to you in this EULA. Without limiting the foregoing, you shall not authorize or permit any third party to (a) use the Software for any purpose other than that in connection with the Product; (b) license, distribute, lease, rent, lend, transfer, assign or otherwise dispose of the Software; (c) reverse engineer, decompile, disassemble or attempt to discover the source code of or any trade secrets related to the Software, except and only to the extent that such conduct is expressly permitted by applicable law notwithstanding this limitation; (d) adapt, modify, alter, translate or create any derivative works of the Software; (e) remove, alter or obscure any copyright notice or other proprietary rights notice on the Software or Product; or (f) circumvent or attempt to circumvent any methods employed by Lingolet to control access to the components, features or functions of the Product or Software. Subject to the limitations specified in this Section 5, you are not prohibited from providing any services hosted by Lingolet CacheDrive to any third party for commercial purposes.

                </Text>


                <Text mt={5}>
                Section 6. Open Source. The Software may contain components licensed to Lingolet under the GNU General Public License (“GPL Components”), currently available at https://www.gnu.org/licenses/gpl.html. The terms of the GPL will control solely with respect to the GPL Components to the extent that this EULA conflicts with the requirements of the GPL with respect to your use of the GPL Components, and, in such event, you agree to be bound by the GPL with respect to your use of such components.

                </Text>


                <Text mt={5}>
                Section 7. Audit. Lingolet will have the right to audit your compliance with the terms of this EULA. You agree to grant Lingolet a right to access your facilities, equipment, books, records, and documents and to otherwise reasonably cooperate with Lingolet in order to facilitate any such audit by Lingolet or its agent authorized by Lingolet.

                </Text>


                <Text mt={5}>
                Section 8. Ownership. The Software is a valuable property of Lingolet and its licensors, protected by copyright and other intellectual property laws and treaties. Lingolet or its licensors own all rights, titles, and interests in and to the Software, including but not limited to copyright and any other intellectual property rights.

                </Text>


                <Text mt={5}>
                Section 9. Limited Warranty. Lingolet provides a limited warrant that the Software will substantially conform to Lingolet’s published specifications for the Software if any, or otherwise set forth on the Website, for a period required by your local law. Lingolet will use commercially reasonable efforts to, in Lingolet’s sole discretion, either correct any such nonconformity in the Software or replace any Software that fails to comply with the foregoing warranty, provided that you give Lingolet written notice of such noncompliance within the warranty period. The foregoing warranty does not apply to any non-compliance resulting from any: (w) use, reproduction, distribution, or disclosure not in accordance with this EULA; (x) any customization, modification, or other alteration of the Software by anyone other than Lingolet; (y) combination of the Software with any product, services or other items provided by anyone other than Lingolet; or (z) your failure to comply with this EULA.

​
                </Text>


                <Text mt={5}>
                Section 10. Support. During the period specified in Section 9, Lingolet will make support services available to you. Following the expiration of the applicable period, support for Software may be available from Lingolet upon written request.


                </Text>


                <Text mt={5}>
                Section 11. Disclaimer of Warranties. EXCEPT AS EXPRESSLY SET FORTH ABOVE, THE SOFTWARE IS PROVIDED “AS IS” AND WITH ALL FAULTS. LINGOLET AND ITS SUPPLIERS HEREBY DISCLAIM ALL OTHER WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, ARISING BY LAW OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, TITLE, AND NON-INFRINGEMENT, WITH REGARD TO THE SOFTWARE. WITHOUT LIMITING THE FOREGOING, LINGOLET DOES NOT WARRANT THAT THE SOFTWARE WILL BE FREE OF BUGS, ERRORS, VIRUSES, OR OTHER DEFECTS.


                </Text>


                <Text mt={5}>
                Section 12. Disclaimer of Certain Damages. IN NO EVENT WILL LINGOLET OR ITS LICENSORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO LOSS OF DATA, INFORMATION, REVENUE, PROFIT, OR BUSINESS) ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SOFTWARE OR OTHERWISE UNDER OR IN CONNECTION WITH THIS EULA OR THE SOFTWARE, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY EVEN IF LINGOLET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.


                </Text>


                <Text mt={5}>
                Section 13. Limitation of Liability. LINGOLET’S AND ITS SUPPLIERS’ LIABILITY ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SOFTWARE OR OTHERWISE UNDER OR IN CONNECTION WITH THIS EULA OR THE SOFTWARE IS LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU FOR THE PRODUCT REGARDLESS OF THE AMOUNT OF DAMAGES YOU MAY INCUR AND WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY. The foregoing disclaimer of warranties, disclaimer of certain damages, and limitation of liability will apply to the maximum extent permitted by applicable law. The laws of some states/jurisdictions do not allow the exclusion of implied warranties or the exclusion or limitation of certain damages. To the extent that those laws apply to this EULA, the exclusions and limitations set forth above may not apply to you.

                </Text>


                <Text mt={5}>
                Section 14. Export Restrictions. You acknowledge that the Software is subject to U.S. export restrictions. You agree to comply with all applicable laws and regulations that apply to the Software, including without limitation the U.S. Export Administration Regulations.

                </Text>


                <Text mt={5}>
                Section 15. Termination. Without prejudice to any other rights, Lingolet may terminate this EULA if you do not abide by the terms and conditions contained herein. In such an event, you must cease use of the Software and destroy all copies of the Software and all of its component parts.

                </Text>


                <Text mt={5}>
Section 16. Assignment. You may not transfer or assign your rights under this EULA to any third party, except for that pre-installed in the Products. Any such transfer or assignment in violation of the foregoing restriction will be void.

                </Text>


                <Text mt={5}>
                Section 17. Applicable Law. Unless expressly prohibited by local law, this EULA is governed by and construed in accordance with the laws of the State of California without regard to any conflict of law principles to the contrary.

                </Text>


                <Text mt={5}>
                Section 18. Dispute Resolution. Any dispute, controversy, or claim arising out of or relating to this EULA will be resolved exclusively and finally by arbitration conducted by three neutral arbitrators in accordance with the procedures of the California Arbitration Law and related enforcement rules. In such cases, the arbitration will be limited solely to the dispute between you and Lingolet. The arbitration, or any portion of it, will not be consolidated with any other arbitration and will not be conducted on a class-wide or class action basis.

                </Text>


                <Text mt={5}>
                Section 19. Attorneys’ Fees. In any arbitration, mediation, or other legal action or proceeding to enforce rights or remedies under this EULA, the prevailing party will be entitled to recover, in addition to any other relief to which it may be entitled, costs and reasonable attorneys’ fees.
                </Text>


                <Text mt={5}>
                Section 20. Severability. If any provision of this EULA is held by a court of competent jurisdiction to be invalid, illegal, or unenforceable, the remainder of this EULA will remain in full force and effect.

                </Text>


                <Text mt={5}>
                Section 21. Entire Agreement. This EULA sets forth the entire agreement of Lingolet and you with respect to the Software and the subject matter hereof and supersedes all prior and contemporaneous understandings and agreements whether written or oral. No amendment, modification, or waiver of any of the provisions of this EULA will be valid unless set forth in a written instrument signed by the party to be bound thereby.
                </Text>
 
                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                If you have questions, contact us:
                </Text>

                    <Text>
                    Lingolet Inc. <br/>
                    Attn: Legal,  EULA <br/>
                    4633 Old Ironsides Drive, Ste. 230 <br/>
                    Santa Clara, CA  95054 <br/>
                    Email info@lingolet.com <br/>

                    </Text>


                <Center mt={10}>
                Last Updated: <Text pl={1}><b>December 21th, 2023</b></Text>
                </Center>
            </Card>
            <Box w="100%">
                <Footer />
            </Box>
        </Flex>
    )
}

export default EULA;

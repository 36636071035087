import React, { useState, useEffect } from "react";

// Chakra Import
import {
    Button,
    Box,
    Flex,
    Text,
    Icon,
    Image,
    Avatar,
    Switch,
    Spacer,
    Heading,
    Spinner,
    useToast,
    Checkbox,
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import {
    AiFillEdit,
    AiFillLeftCircle,
    AiFillRightCircle,
    AiOutlineCheckSquare,
    AiOutlineLock,
} from "react-icons/ai";

// Api Call Imports
import { enableDiableSubLingoCard, fetchSubscriberList, manageSubscriberStatus, resetSubscriberPassword } from "../../../services/accountServices";

// Component Imports
// import InviteAccountAdmin from "./InviteAccountAdmin";
import AddUpdateSubcriber from "./AddUpdateSubcriber";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useBrandBg, useGreenBg } from "../../../theme/globalColorTheme";
import UpdateAccountAppFeature from "./UpdateAccountAppFeature";


function SubscriberManagement({ locationDetails, state,accountApp }) {
    const linkAccountUuid = locationDetails?.link_account_uuid ? locationDetails?.link_account_uuid : null;
    const link_location_uuid = locationDetails?.link_location_uuid;
    const toast = useToast();
    const brandBg = useBrandBg();
    const [loading, isLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [subscriberList, setSubscriberList] = useState([]);
    const [selectedSubsData, setSelectedSubsData] = useState({})
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const sourceFeatures = accountApp?.length?accountApp?.split(','):[];
    const [resetLoader,setResetLoader] = useState(false)

    useEffect(() => {
        collectSubscriberList();
    }, []);
    
    // Todo:Fetch Subscriber List Details
    const collectSubscriberList = () => {
        isLoading(true);
        let paramObj = {
            link_account_uuid: linkAccountUuid,
            link_location_uuid: link_location_uuid,
            records_per_page: 10,
            page_id: currentPage
        };
        fetchSubscriberList(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.total_records) {
                    isLoading(false);
                    setSubscriberList(res?.data[0]?.records);
                    setTotalPages(res?.data[0]?.totalPages)
                } else {
                    isLoading(false);
                    setSubscriberList([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setSubscriberList([]);
            });
    };

    // Todo:Update Subscriber Status
    const updateSubscriber = (user_uuid) => {
        if (user_uuid != null && linkAccountUuid !== null) {
            let objBody = {
                link_location_uuid: link_location_uuid,
                link_account_uuid: linkAccountUuid,
                user_uuid: user_uuid
            };
            manageSubscriberStatus(objBody)
                .then(res => {
                    if (res?.result) {
                        collectSubscriberList();
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {

                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch(err => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
        }
    };

    const handleFeatureUpdate = (data) => {
        if(data?.length) {
            collectSubscriberList()
        }
    }

    // Todo:Reset Subscriber Password
    const resetSubscriberPasswd = (data) => {
        setResetLoader(true);
        let obj = {
            user_uuid: data?.user_uuid
        }
        resetSubscriberPassword(obj).then((res)=> {
            setResetLoader(false);
            if(res) {
                toast(toastFunctionToaster(res.message, "success"));
            }
            else {
                toast(toastFunctionToaster(res.message, "error"));
            }

        }).catch((err)=>{
            setResetLoader(false);
            if(err) {
                toast(toastFunctionToaster('Something went wrong!!!', "error"));
            }
        })
    }

    const manageSubLingoCard = (userUuid) =>{
        const reqBody = {
            link_account_uuid: linkAccountUuid,
            link_location_uuid: link_location_uuid,
            user_uuid: userUuid
        }
        enableDiableSubLingoCard(reqBody).then(resp=>{
            collectSubscriberList()
            if(resp.result){
                toast(toastFunctionToaster(resp.message, "success"));
            }else{
                toast(toastFunctionToaster(resp.message, "error"));
            }

        }).catch(err=>{
            toast(toastFunctionToaster("Something went wrong", "error"));
        })
    }

    // Todo:Add / Update Selected Apps
    const renderSelectedAccountApps = (data,itemData) => (
        <UpdateAccountAppFeature 
            selectedData={data} 
            sourceFeatures={sourceFeatures} 
            link_account_uuid={linkAccountUuid} 
            link_location_uuid={link_location_uuid} 
            itemData={itemData} 
            reRenderFeautureApp={handleFeatureUpdate}/> 
    )

    const renderSubscriberList =
        subscriberList !== null && subscriberList?.length > 0 ? (
            subscriberList?.map((item, index) => (
                <Flex
                    w={"100%"}
                    key={index}
                    rounded={"md"}
                    align={"center"}
                    p={1}
                    mt={1}
                    direction={'column'}
                    borderWidth={"0.05px"}
                    borderLeftWidth={'10px'}
                    borderLeftColor={item?.is_disable ? "red.300" : "green.300"}
                    borderColor={item?.is_disable ? "red.200" : "green.300"}>
                    <Flex w={'100%'}>
                        <Flex
                            rounded={"md"}
                            px={1}
                            w={"100px"}
                            align={"center"}>
                            <Box minW={"60px"}>
                                <Avatar />
                            </Box>
                        </Flex>
                        <Flex w={'100%'} direction={'column'}>
                            <Flex minW={"100%"} p={1} direction={"column"} flexWrap={'wrap'}>
                                <Text fontWeight={'bold'}>
                                    {item?.first_name} <Text as="span" fontWeight={'bold'}>{item?.last_name}</Text>
                                </Text>
                                <Text>
                                    {item?.account_subscriber_email}
                                </Text>
                                <Flex maxW={'100%'}>
                                    <Text px={2} color={'gray.700'} rounded={'md'} bg={item?.logged ? 'red.200' : 'green.400'}>{item?.logged ? 'Not Logged' : 'Logged'}</Text>
                                    {item?.is_disable
                                        ? <Text ml={1} w={'100px'} color={'gray.700'} bg={'red.100'} p={1} rounded={'md'}>
                                            Disabled
                                        </Text>
                                        : <Text ml={1} w={'100px'} color={'gray.700'} bg={'green.200'} p={1} rounded={'md'}>
                                            Enabled
                                        </Text>}
                                        {item?.lingocard_enable
                                        ? <Text ml={1} w={'100px'} color={'gray.700'} bg={'green.200'} p={1} rounded={'md'}>
                                            LingoCard enabled
                                        </Text>
                                        : <Text ml={1} w={'100px'} color={'gray.700'} bg={'red.200'} p={1} rounded={'md'}>
                                            LingoCard disabled
                                        </Text>}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} align={'top'} direction={'column'}>
                            <Flex w={'100%'} flexWrap="wrap" alignItems="center" justifyContent="flex-start" rowGap={2} p={1}>
                                {resetLoader ? (
                                    <Box p='1'>
                                        <Spinner size='md'/>
                                    </Box>
                                    ):(
                                    <Flex
                                        rounded={'md'}
                                        px={2} py={1}
                                        alignItems={"center"}
                                        borderWidth={"1px"}
                                        borderColor={"gray.400"}
                                        align={"end"}
                                        cursor={'pointer'}
                                        onClick={() => {
                                            resetSubscriberPasswd(item)
                                        }}
                                    >
                                        <Icon
                                            as={AiOutlineLock}
                                            ml={1}
                                            p={1}
                                            rounded={"md"}
                                            fontSize={"24px"}
                                            cursor={"pointer"}
                                            mr="1"
                                        />
                                        Reset Password
                                    </Flex>
                                )}

                                <Flex
                                    align={'center'}
                                    rounded={'md'}
                                    px={2} py={1}
                                    alignItems={"center"}
                                    borderWidth={"1px"}
                                    borderColor={"gray.400"}
                                    minW={"160px"} maxW={"160px"}
                                    mx='2'
                                >
                                    <Switch
                                        mr={2}
                                        onChange={() => {
                                            updateSubscriber(item?.user_uuid);
                                        }}
                                        isChecked={item?.is_disable ? true : false}
                                        colorScheme="green"
                                    />
                                    {item?.is_disable
                                        ? <Text>Enable Login</Text>
                                        : <Text>Disable Login</Text>}

                                    {/* <Icon
                                        as={AiOutlineHolder}
                                        ml={1}
                                        p={1}
                                        bg={"gray.200"}
                                        rounded={"md"}
                                        fontSize={"24px"}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            // onOpen();
                                            // setSelectedAccountData(item)
                                        }}
                                    /> */}
                                </Flex>
                                {!item?.is_disable ? (
                                    <Flex
                                        rounded={'md'}
                                        px={2} py={1}
                                        alignItems={"center"}
                                        borderWidth={"1px"}
                                        borderColor={"gray.400"}
                                        align={"end"}
                                        mr="2"
                                        cursor={'pointer'}
                                        onClick={() => {
                                            setSelectedSubsData(item)
                                            setConfiguationCollapseFlag(true);
                                        }}
                                    >
                                        <Icon
                                            as={AiFillEdit}
                                            ml={1}
                                            p={1}
                                            rounded={"md"}
                                            fontSize={"24px"}
                                            cursor={"pointer"}
                                            mr="1"

                                        />
                                        Edit
                                    </Flex>) : null}

                                    <Flex
                                    align={'center'}
                                    rounded={'md'}
                                    px={2} py={1}
                                    alignItems={"center"}
                                    borderWidth={"1px"}
                                    borderColor={"gray.400"}
                                    minW={"160px"}
                                    mx='2'
                                >
                                <Switch
                                        mr={2}
                                        onChange={() => {
                                            manageSubLingoCard(item?.user_uuid)
                                        }}
                                        colorScheme="green"
                                        isChecked={item?.lingocard_enable}
                                    />
                                    <Text>{item?.lingocard_enable ? 'Disable' : 'Enable' } LingoCard</Text>
                                    </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={3} p={1} direction={'column'} borderWidth={'1px'} borderColor={'gray.200'} rounded={'lg'}>
                        <Flex
                            rounded={"md"}
                            px={1}
                            align={"center"}>
                                <Text>Enabled lingoLink Apps</Text>
                        </Flex>
                        <Flex w={'100%'} alignItems={'center'}>
                            {renderSelectedAccountApps(item?.link_apps, item)}
                        </Flex>
                    </Flex>
                </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                    You do not have any subscriber added.
                </Heading>
            </Flex>
        );

    const handleSubscriberList = (data) => {
        if (data) {
            collectSubscriberList();
        }
    }

    return (
        <>
            <Flex w={'100%'}>
                <Card w={'100%'} h={'calc(100vh - 200px)'} p={1} alignItems="center" rounded={'md'}>
                    <Flex w={'100%'} h="100%">
                        <Box
                            w={!configurationCollapseFlag ? "96%" : "85%"}
                            bg={brandBg}
                            p={1}
                            borderRadius={"10px"}
                            overflow="hidden" h="100%">
                            <Flex w={"100%"} h="100%" direction={"column"}>
                                {loading ? (
                                    <Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
                                        <Spinner size={"xl"} />
                                    </Flex>
                                ) : (
                                    <Flex w={"100%"} h="100%" direction={"column"}>
                                        <Flex
                                            direction={"column"}
                                            w={"100%"}
                                            h="100%"
                                            overflow="auto"
                                            borderTopWidth="0px"
                                            className="custom-scrollbar">
                                            {renderSubscriberList}
                                            {currentPage?.length > 1 ? (
                                                <Box mt="4" position={'absolute'} bottom="15px" width={"100%"}>
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={pageNumber => {
                                                            setCurrentPage(pageNumber);
                                                        }}
                                                    />
                                                </Box>) : null}
                                        </Flex>

                                    </Flex>
                                )}
                            </Flex>
                        </Box>
                        {configurationCollapseFlag ? (
                            <Flex w="35%">
                                <Box
                                    w="100%"
                                    position="relative"
                                    p="3"
                                    borderRadius="15px"
                                    bg={brandBg}
                                    ml="2">
                                    <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                        <AiFillRightCircle
                                            cursor="pointer"
                                            size="20px"
                                            onClick={() => {
                                                setConfiguationCollapseFlag(!configurationCollapseFlag);
                                            }}
                                        />
                                    </Box>
                                    <Box w="100%" h="95%">
                                        {linkAccountUuid != null
                                        ?<AddUpdateSubcriber 
                                            link_account_uuid={linkAccountUuid}
                                            link_location_uuid={link_location_uuid} 
                                            reListSubscriber={handleSubscriberList}
                                            subscriberData={selectedSubsData}
                                        />:null}
                                    </Box>
                                </Box>
                            </Flex>
                        ) : (
                            <Box w="4%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                                <Box w={"100%"} h="100%">
                                    <AiFillLeftCircle
                                        cursor="pointer"
                                        onClick={() => {
                                            setConfiguationCollapseFlag(!configurationCollapseFlag);
                                        }}
                                        size="20px"
                                    />
                                    <Text
                                        fontSize="16px"
                                        mt="2"
                                        fontWeight='bold'
                                        css={{
                                            writingMode: "vertical-lr",
                                            textOrientation: "mixed",
                                        }}>
                                        Invite Subscriber
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Card>
            </Flex>
        </>
    )
}

export default SubscriberManagement;
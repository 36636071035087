import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Link,
    Image,
    Button,
    Select,
    Icon,
    Spacer,
    Alert,
    Drawer,
    Spinner,
    Tooltip,
    RadioGroup,
    Stack,
    Radio,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Slider,
    FormControl,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    AlertIcon,
    FormLabel,
    SimpleGrid,
    PinInput,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    PinInputField,
    useDisclosure,
    Textarea,
    useToast,
    Heading,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync,
    AiTwotoneAudio,
    AiOutlineVideoCamera,
    AiOutlineClockCircle,
    AiOutlineAntDesign
} from "react-icons/ai";
import moment from "moment";

// Component Imports
import { formatTime, formatSeconds } from '../../../utils/TimeFunctions';
import { toastFunctionToaster } from "../../../utils/toastFunction";
import InputField from "../../../components/fields/InputField";
import Card from "../../../components/card/Card";

// Api Call Imports
import { manageLinkStartEx, manageLinkVerifyEx } from "../../../services/dynamicPageSvc"


function LinkManagVerify() {
    const btnRef = React.useRef()

    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const linkKey = state?.linkKey ? state?.linkKey : null;
    const linkCode = state?.linkCode ? state?.linkCode : null;
    const linkSession = state?.linkSession ? state?.linkSession : null;
    const [launchModal, setLaunchModal] = useState(false)


    const [loading, isLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [accessCode, setAccessCode] = useState(0)
    const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100")

    // Todo:Code Value
    const [valueOne, setValueOne] = useState("")
    const [valueTwo, setValueTwo] = useState("")
    const [valueThree, setValueThree] = useState("")
    const [valueFour, setValueFour] = useState("")
    const [valueFive, setValueFive] = useState("")
    const [valueSix, setValueSix] = useState("")
    let launchKey = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
    const textColor = useColorModeValue("secondaryGray.900", "white")
    useEffect(() => {
        requestLinkMgmtCode();
    }, []);

    const requestLinkMgmtCode = () => {
        if (linkKey != null && linkCode != null && linkSession != null) {
            isLoading(true);
            let paramObj = {
                link_key: linkKey,
                link_code: linkCode,
                link_session: linkSession
            };
            manageLinkStartEx(paramObj)
                .then((res) => {
                    console.log(res, "resresres")
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        // access Code is emailed
                        // Temporarily it is added in the API response 
                        toast(toastFunctionToaster(res?.message, "success"));

                        setAccessCode(res?.data[0]?.code6);
                        setLaunchModal(true)

                    } else if (res?.data[0]?.waiting) {
                        toast(toastFunctionToaster(res?.message, "error"));

                        // show Message res?message (Wait for 10 minutes to get code)
                    }

                })
                .catch((err) => {
                    toast(toastFunctionToaster('Something went wrong', "success"));

                    isLoading(false);
                });
        }
    };

    const verifyLinkMgmtCode = () => {
        let launchCode = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
        if (linkKey != null && linkCode != null && linkSession != null && launchCode?.length === 6) {
            isLoading(true);
            let paramObj = {
                link_key: linkKey,
                link_code: linkCode,
                link_session: linkSession,
                access_code: launchCode
            };
            manageLinkVerifyEx(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.status && res?.data[0]?.request == false) {
                        // code verification success
                        // Lanuch > LinkManageHome
                    } else if (res?.data[0]?.status == false && res?.data[0]?.request) {
                        // code is expired, regenerate.
                    } else {
                        // Access failure
                    }
                })
                .catch((err) => {
                    isLoading(false);
                });
        }
    };

    const verifyCodeStr = () => {
        let launchCode = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
        console.log(launchCode?.length)
        if (launchCode != null && launchCode.length === 6) {
            return true
        } else {
            return false
        }

    }

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Text>Link Verify { }</Text>
            </Box>

            <Modal
                finalFocusRef={btnRef}
                isOpen={launchModal}
                onClose={() => {
                    setLaunchModal(false)
                    onClose()
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Enter your access code</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Heading fontSize={"20px"} fontWeight={600} my="2">
                            Enter your access code
                        </Heading>
                        <Flex
                            zIndex="2"
                            direction="column"
                            w={{ base: "100%", md: "425px" }}
                            maxW="100%"
                            background="transparent"
                            borderRadius="15px"
                            mx={{ base: "auto", lg: "unset" }}
                            me="auto"
                            mb={{ base: "20px", md: "auto" }}
                        >
                            <FormControl>
                                <Flex justify="center">
                                    <PinInput mx="auto" otp>
                                        <PinInputField
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            me="10px"
                                            value={valueOne}
                                            onChange={e => {
                                                setValueOne(e.target.value)
                                            }}
                                        />
                                        <PinInputField
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            me="10px"
                                            value={valueTwo}
                                            onChange={e => {
                                                setValueTwo(e.target.value)
                                            }}
                                        />
                                        <PinInputField
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            me="10px"
                                            value={valueThree}
                                            onChange={e => {
                                                setValueThree(
                                                    e.target.value
                                                )
                                            }}
                                        />
                                        <PinInputField
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            value={valueFour}
                                            onChange={e => {
                                                setValueFour(e.target.value)
                                            }}
                                        />
                                        <PinInputField
                                            ml={2}
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            value={valueFive}
                                            onChange={e => {
                                                setValueFive(e.target.value)
                                            }}
                                        />
                                        <PinInputField
                                            ml={2}
                                            fontSize="30px"
                                            color={textColor}
                                            borderRadius="16px"
                                            borderColor={borderColor}
                                            h={{ base: "55px", md: "80px" }}
                                            w={{ base: "63px", md: "95px" }}
                                            value={valueSix}
                                            onChange={e => {
                                                setValueSix(e.target.value)
                                            }}
                                        />
                                    </PinInput>
                                </Flex>
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isDisabled={verifyCodeStr() ? false : true}
                            isLoading={loading ? true : false}
                            onClick={() => verifyLinkMgmtCode()}
                            colorScheme={verifyCodeStr() ? "green" : "red"}
                            mr={3}
                        >
                            Verify
                        </Button>
                        <Button
                            onClick={() => {
                                setLaunchModal(false)
                                onClose()
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default LinkManagVerify;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text, Center,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import Card from '../../components/card/Card';
import Footer from '../../komponents/uilayout/Footer';

function GPLCompliance() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  

    const [loading, isLoading] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Center>
                <Text fontWeight={'bold'} fontSize={'24px'}>
                    Lingolet GPL Compliance
                </Text>
            </Center>
            <Card w={'96%'} px={20} mt={10}>

                <Text>
                The software included in the Lingolet product contains copyrighted software subject to the GNU General Public License (“GPL”) or GNU Lesser General Public License (“LGPL”). Please see the GPL and LGPL to Web sites to view the terms of each license.
                </Text>

                <Text mt={5}>
                To have access to the GPL Code and LGPL Code used in Lingolet products, send a money order or check for US$5 to Lingolet Inc., ATTN: GNU/GPL, 4633 Old Ironsides Drive, Ste. 230, Santa Clara, CA 95054 US. Please write “Source for Lingolet product” in the memo line of your payment.

                </Text>
                <Text>
                The GPL Code and LGPL Code used in Lingolet products are distributed WITHOUT ANY WARRANTY and are subject to the copyrights of one or more authors.
                </Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                If you have questions, contact us:
                </Text>

                    <Text>
                    Lingolet Inc. <br/>
                    Attn: Legal,  GNU/GPL Code <br/>
                    4633 Old Ironsides Drive, Ste. 230 <br/>
                    Santa Clara, CA  95054 <br/>
                    Email info@lingolet.com <br/>

                    </Text>


                <Center mt={10}>
                Last Updated: <Text pl={1}><b>December 21th, 2023</b></Text>
                </Center>
            </Card>
            <Box w="100%">
                <Footer />
            </Box>
        </Flex>
    )
}

export default GPLCompliance;

// React Chakra Import
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/fields/InputField";
import {
	Box,
	Flex,
	Text,
	Heading,
	Button,
	Tabs, TabList, TabPanels, Tab, TabPanel,
	Select,
	Icon,
	Spacer,
	Image,
	Spinner,
	Tooltip,
	Card,
	FormLabel,
	Textarea,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";

import { transcriptionLanguages } from "../../../../utils/TranscriptionLanguage.js";
// import { translationLanguages } from "../../../../utils/TranslationLanguages";

import moment from "moment";
import { AiOutlineCheckCircle, AiOutlineMail, AiOutlineStop, AiOutlineSync } from "react-icons/ai";
// Api Call Imports
import {
	getlingoCardDetails,
	getLinkCustomerEnabled,
	shareCardWithCustomer,
	emailCardWithCustomer,
	manageCardStatus,
	getLinkFullDesign,
	setupCardDisable,
} from "../../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import FeedbackComponent from "../../../common/Feedback/FeedbackComponent.js";
import Multiselect from "multiselect-react-dropdown";
import lsplogo from "../../../../assets/landingPageImg/lspcloud_logo-wide.png";

function CardManager() {
	const toast = useToast();
	const navigate = useNavigate();
    const { state } = useLocation();

	const cardUuid = state?.cardUuid ? state?.cardUuid : null;

    const [linkInfo, setLinkInfo] = useState([]);
	const [loading, isLoading] = useState(true);
    const [showTooltip, setShowTooltip] = React.useState(false);

	const btnRef = React.useRef();
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const [cardData, setCardData] = useState([]);
    const [linkCustomer, setLinkCustomer] = useState([]);
    const [linkCustomers, setLinkCustomers] = useState([]);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [toEmails, setToEmails] = useState(null);

	useEffect(() => {
		fetchCardDetails();
		collectLinkCustomers();
	}, []);

	// Todo: Fetch Card Details
	const fetchCardDetails = () => {
		isLoading(true);
		let obj = {
			card_uuid: cardUuid,
		};
		getlingoCardDetails(obj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					setCardData(res?.data[0]?.details);
					setLinkCustomer(res?.data[0]?.customer);
				}
			})
			.catch(err => {
				isLoading(false);

				console.log(err);
			});
	};

	const collectLinkCustomers = () => {
        let paramObj = {
            records_per_page: 0,
            page_id: 0,
        };
        getLinkCustomerEnabled(paramObj)
        .then((res) => {
            if (res?.data[0]?.count) {
                isLoading(false);
                setLinkCustomers(res?.data[0]?.records);
            } else {
                isLoading(false);
                setLinkCustomers([]);
            }
        })
        .catch((err) => {
            setLinkCustomers([]);
        });
    };

    const shareCardWithSelectedCustomer = (crdCustomerUuid) => {
        let paramObj = {
            customerUuid: crdCustomerUuid,
            cardUuid: cardUuid,
        };
        shareCardWithCustomer(paramObj)
        .then((res) => {
            if (res?.data[0]?.status) {
                fetchCardDetails();
                toast(toastFunctionToaster(res.message, "success"));
            } else {
              toast(toastFunctionToaster(res.message, "error"));
            }
        })
        .catch((err) => {
          toast(toastFunctionToaster('Unable to share LingoLink with selected customer..', "error"));
        });
    };
    

	// Todo:Disable Data
	const disableSelectedLink = data => {
		let obj = {
			lingo_card_uuid: cardUuid,
			card_dead: !true,
		};
		setupCardDisable(obj)
			.then(res => {
				fetchCardDetails();
			})
			.catch(err => {
				console.log(err);
			});
	};

	const sentCardInfoEmail = () => {
		let obj = {
			cardUuid: cardUuid,
			customerEmails: toEmails,
			customerMsg: toEmails,
		};
		emailCardWithCustomer(obj)
			.then(res => {
				if (res?.data[0]?.status) {
					fetchCardDetails();
					toast(toastFunctionToaster('Card information is shared with selected customers..', "success"));
				} else {
				  toast(toastFunctionToaster('Unable to send email to selected customers.', "error"));
				}
			})
			.catch(err => {
				console.log(err);
			});

	}

	const renderLanguageListDetails = (langs) => {
        let langData = langs.split(",");
		if (langData.length > 0) {
		  return langData?.map((item, index) => {
			return (
			  <Text m={1} p={1} fontSize={"xs"} bg={"blue.100"} rounded={"md"}>
				{item}
			  </Text>
			);
		  });
		}
	};

	const renderAllCustomers = linkCustomers?.map((linkCust, index) => (
        <option
          key={linkCust.uuid}
          value={linkCust.uuid}
          style={{ color: "black" }}
        >
          {linkCust.info}
        </option>
    ));

	const renderCardData =
		cardData !== null && cardData?.length > 0
			? cardData?.map((item, index) => (
					<Card
						h="calc(100vh - 180px)"
						key={index}
						p={1}
						w={"100%"}
						rounded={"md"}
						borderWidth={"0.05px"}
						borderColor={item?.card_active ? "green.200" : "red.200"}>
						<Flex w={"100%"} direction={"column"}>
								<Flex w={'100%'} align={'center'} p={2}>
									<Flex minW={"150px"} maxW={"150px"}>
										<Text rounded={"md"} bg={item?.card_active ? "green.200" : "red.200"} p={2}>
											{item?.card_dead ? (
												<Icon as={AiOutlineStop} color={"red.600"} ml={1} mr={1} mt={1} />
											) : null}
											{item?.card_title}
										</Text>
									</Flex>
									<Spacer />
									<Flex w={"200px"}>
										<Tooltip
											hasArrow
											placement={"top"}
											bg="teal.500"
											onMouseEnter={() => setShowTooltip(true)}
											onMouseLeave={() => setShowTooltip(false)}
											color="white"
											label={"Dead Card (Instantly becomes unusable)"}>
											<Button
												ml={1}
												bg={item?.card_active ? "red.100" : "green.100"}
												onClick={() => {
													disableSelectedLink(item);
												}}
												size={"sm"}>
												Disable Card Completely
											</Button>
										</Tooltip>
									</Flex>
								</Flex>
								<Flex w={"100%"} mt={2} p={2} direction={"column"} rounded={'md'} 
									border={'1px'} borderColor={'gray.200'} >
									<Flex w={"100%"}>
										<Text>
											Start Time (UTC):{" "}
											<Text as="spam" fontWeight={"600"}>
												{moment(item?.card_start_time).format("MMM DD,YYYY,HH:mm a")}
											</Text>
										</Text>
										<Text ml={2}>
											End Time (UTC): {moment(item?.card_end_time).format("MMM DD,YYYY,HH:mm a")}
										</Text>
									</Flex>
									<Flex w={"100%"}>
										<Text>
											Call Time: <b>{item?.card_minutes} Minutes</b>
										</Text>
									</Flex>
									<Flex w={"100%"}  align={'center'}>
										<Text>
											Source Language:{" "}
										</Text>
										{renderLanguageListDetails(item?.card_source_langs)}
									</Flex>
									<Flex w={"100%"} align={'center'}>
										<Text>
											Target Language:{" "}
										</Text>
										{renderLanguageListDetails(item?.card_target_langs)}
									</Flex>
								</Flex>
						</Flex>
					</Card>
			  ))
			: null;

	return (
		<Box direction="column" pt={{ sm: "125px", lg: "80px" }}>
			<Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
				<Text fontWeight={"500"} fontSize={"18px"}>
					LingoCard Details
				</Text>
				<Spacer />
				<Flex w={'200px'}>
					<Button ml={1} bg={'blue.100'} size="sm" 
						onClick={() => {
							navigate("/business/cardlisting", {
							});
						}}>
						Back to Card Listing
					</Button>
				</Flex>
			</Flex>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" />
				</Flex>
			) : cardData?.length ? (
				<Flex w={"100%"}>
					<Flex w={'100%'} direction={"column"}>
						{renderCardData}
					</Flex>
					<Flex ml={1} minW={'400px'} maxW={'400px'} p={1} rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}>
                        <Tabs>
                            <TabList>
                                <Tab>Assign Customer</Tab>
                                <Tab>Assign Link</Tab>
                                <Tab>Email Customer</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
									{linkCustomer?.length > 0
                                    ?<Flex w={'100%'} direction ={'column'} bg={'green.100'} rounded={'md'} p={2}>
                                        <Text w={'100%'}>LingoCard is already shared with </Text>
										<Text bg={'green.200'} py={2} pl={2}
											rounded={'md'} fontSize={'md'} fontWeight={500}>{linkCustomer?.length > 0 ? linkCustomer[0]?.email : 'xxx'}</Text>
                                    </Flex>
                                    :<Flex w={'100%'} direction ={'column'}>
                                        <Flex w={'100%'} direction={'column'}>
                                            <Text>{"Assign Card with Customer"}</Text>
                                        </Flex>
                                        <Flex w={'100%'} direction={'column'}>
                                            {linkCustomers?.length > 0
                                            ?<Flex w={'100%'} p={2}
                                                rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}
                                                direction={'column'}>
                                                <Text mb={4} py={2}>Select customer from list below to share Lingolink:</Text>
                                                <Select
                                                    value={customerInfo}
                                                    name="customerInfo"
                                                    id={'customerInfo'}
                                                    placeholder="Select Customer"
                                                    size="md"
                                                    // color={textColor}
                                                    borderRadius="16px"
                                                    onChange={e => {
                                                    	setCustomerInfo(e.target.value);
                                                    }}
                                                >
                                                {renderAllCustomers}
                                                </Select>
                                                {customerInfo
                                                ?<Button mt={4}  my={4} bg={'blue.100'} 
													onClick={() => shareCardWithSelectedCustomer(customerInfo)}
													rounded={'md'} size={'sm'}>Share Card with above customer
												</Button>:null}
                                            </Flex>
                                            :<Text color={'red.300'}><Icon as={AiOutlineStop} mt={1} mr={1} /> Please add customers first to share Card</Text>}
                                        </Flex>
                                    </Flex>}
								</TabPanel>
								<TabPanel>

								</TabPanel>
                                <TabPanel>
									{linkCustomer?.length > 0
									?<Flex w={'100%'} direction={'column'}>
										<Flex w={'100%'} direction={'column'}>
											<Text ml={2}>{"Email Card Details to customers"}</Text>
										</Flex>
										<Card 
											borderWidth={'0.5px'} borderColor={'gray.300'}
											bg={'gray.200'} w={"100%"} h={'200px'} mt={5} mb="2" borderRadius='10px' fontSize={"sm"} p="10px">
												<Flex alignItems={'center'} justifyContent={'space-between'}>
													<Image src={lsplogo} w="30%"></Image>
													<Text color="red.500" fontWeight={'600'}> Exp Date:- 25-04-2024</Text>
												</Flex>
												<Flex alignItems={'center'} justifyContent={'center'} my="2">
													<Text border={'1px'} borderStyle={'dotted'} borderRadius={'5px'} fontSize={'24px'} py="3" px="5">123456</Text>
												</Flex>
												<Text  fontWeight={'600'}> LingoLink URl:- https://lingolet.ai/lingolink?key=vA6XGAf</Text>
												<Text  fontWeight={'600'}> Phone Number:- <Text as="span">4444-3333-3333</Text></Text>

										</Card>
										<Text mb="2" fontSize={"md"} color={"blue.500"}>
										
										</Text>
										<FormLabel mt="3" ml="2" required>
											<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											To Email Address(es):
											</Text>
											<Textarea
											mb="0"
											me="30px"
											rows="5"
											id="toEmails"
											value={toEmails}
											name="toEmails"
											onChange={(e) => {
												setToEmails(e.target.value);
											}}
											placeholder="Enter emails (comma separated)"
											w="100%"
											maxlength={200}
											/>
											<Text
											textAlign={"right"}
											fontWeight={"500"}
											fontSize={"sm"}
											mt="2"
											>
											{toEmails?.length}/200
											</Text>
											<Text>
											Note: An email will be sent to all above emails address with the
											card details.
											</Text>
										</FormLabel>
										<Flex w={'100%'}>
											<Button
												colorScheme="blue"
												onClick={() => {
													sentCardInfoEmail();
												}}
												isDisabled={
													toEmails === null || toEmails?.length === 0 ? true : false
												}
												// isLoading={saveDetailsLoader ? true : false}
											>
												Send Email
											</Button>
										</Flex>
									</Flex>
									:<Flex w={'100%'} direction ={'column'} bg={'red.100'} rounded={'md'} p={2}>
										<Text w={'100%'}>Card is not shared with any customer.</Text>
										<Text w={'100%'}>Please share the card first and then you can send email.</Text>
									</Flex>}
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>								
				</Flex>
			) : (
				<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
					<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
						Unable to collect LingoCard Details!!
					</Heading>
				</Flex>
			)}
		</Box>
	);
}

export default CardManager;

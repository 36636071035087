import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Spacer,
    Flex,
    Progress,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Icon,
    Text,
    Alert,
    Button,
    Spinner,
    AlertIcon,
    Avatar,
    useToast,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import Card from "../../../components/card/Card";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";

import { translationLanguages } from '../../../utils/TranslationLanguages';
import { transcriptionLanguages } from '../../../utils/TranscriptionLanguage';


import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from 'react-audio-player';

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { adminWorkflowData, adminWorkflowPerform,  adminWorkflowCollect} from "../../../services/adminServices";

function PerformJob() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { colorMode } = useColorMode();
    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

    const workflowUuid = state?.workflowUuid ? state?.workflowUuid : null;
    const projectUuid = state?.projectUuid ? state?.projectUuid : null;
    const contentUuid = state?.contentUuid ? state?.contentUuid : null;

    const [loading, isLoading] = useState(true);
    const [workflowData, setWorkflowData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [transcriptionData, setTranscriptionData] = useState(null);

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        fetchWorkflowDetails();
    }, []);

    const fetchWorkflowDetails = () => {
        isLoading(true);
        let objData = {
            workflow_uuid: workflowUuid,
            project_uuid: projectUuid,
            content_uuid: contentUuid
        };
        adminWorkflowData(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setWorkflowData(res?.data[0]?.workflow);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const PerformWorkflowJob = () => {
        isLoading(true);
        let objData = {
            workflow_uuid: workflowUuid,
            project_uuid: projectUuid,
            content_uuid: contentUuid
        };
        adminWorkflowPerform(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setWorkflowData(res?.data[0]?.workflow);
                setContentData(res?.data[0]?.workflow[0]?.content);
                setResultData(res?.data[0]?.workflow[0]?.results);
                if (res?.data[0]?.workflow[0]?.status){
                    toast(toastFunctionToaster('Job completed successfully!!', "success"));
                } else {
                    toast(toastFunctionToaster('Unable to complete the given job', "error"));
                }
            } else {
                toast(toastFunctionToaster('Unable to complete the given job', "error"));
                isLoading(false);
            }
          })
          .catch((err) => {
            toast(toastFunctionToaster('Unable to complete the given job', "error"));
            isLoading(false);
          });
    };

    const getTranscriptionData = async (url) => {
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    }


    const PerformWorkflowCollect = () => {
        isLoading(true);
        let objData = {
            workflow_uuid: workflowUuid,
            project_uuid: projectUuid,
            content_uuid: contentUuid
        };
        adminWorkflowCollect(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setWorkflowData(res?.data[0]?.workflow);
                setContentData(res?.data[0]?.workflow[0]?.content);
                setResultData(res?.data[0]?.workflow[0]?.results);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const getTranslationLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = translationLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const getTranscriptionLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = transcriptionLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const renderLanguageListDetails = (langData) => {
        if (langData.length > 0){
            return(
                langData?.map((item, index) => {
                    return(
                        <Text m={1} p={1} fontSize={'xs'} bg={'blue.100'} rounded={'md'}>{item}</Text>
                    )
                })
            )
        }
    }


    const renderWorkflowDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} 
            direction={'column'}
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex w={'100%'} ml={1} p={2}>
                <Text align={'center'} fontSize={'lg'}><b>{item?.code}</b></Text>
                <Spacer />
                {item?.started === false
                ?<Button 
                    onClick={() => PerformWorkflowJob()}
                    size={'sm'} bg={'blue.200'}>
                    Perform Job
                </Button>:null}
                {item?.started
                ?<Button 
                    onClick={() => PerformWorkflowCollect()}
                    size={'sm'} bg={'blue.200'}>
                    Refresh Results
                </Button>
                :<Button size={'sm'} disabled={true} ml={1} bg={'red.200'}>Refresh Results</Button>}
            </Flex>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {moment(item?.created).format("MMM DD,YYYY,HH:mm a")}
            </Box>
            <Box w={'100%'} ml={1} pl={2}>
                <Text color={'blue.400'} fontSize={'xs'}>Submitted By: {item?.email}</Text>
            </Box>
            <Box w={'100%'} ml={1} pl={2}>
                {item?.code === 'Translation'
                ?<Flex w={'100%'}>
                    <Text mr={1}>Source Language</Text>
                    {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.source))}
                    <Text ml={5}  mr={1}>Target Language(s)</Text>
                    {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.languages))}
                </Flex>:null}
                {item?.code === 'Transcription'
                ?<Flex w={'100%'}>
                    <Text mr={1}>Source Language</Text>
                    {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.source))}
                    <Text ml={5}  mr={1}>Target Language(s)</Text>
                    {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.languages))}
                </Flex>:null}
            </Box>
            <Box w={'100%'} ml={1} pl={2}>
                <Text color={'blue.400'} fontSize={'md'}>Workflow Progress: {item?.status}</Text>
            </Box>
            <Box minW={'250px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                {item?.priority 
                ?<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Top Priority
                </Flex>:null}
            </Box>
            <Flex w={'100%'} borderWidth={'0.5px'} borderColor={'gray.200'} rounded={'md'} mt={4} p={2}>
                {item?.started
                ?<Box w={'250px'}>
                    <Text color={'green.500'}>Job Started</Text>
                    <Text ml={1}>{moment(item?.start_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                </Box>
                :<Box w={'250px'}>
                    <Text color={'red.500'}>Not Started</Text>
                </Box>}
                <Box  w={'100%'} ml={1} pl={2} mt={2} mb={5}>
                    <Progress w={'100%'} bg={'gray'}
                        colorScheme={'green'} size='sm' hasStripe 
                        value={item?.completed ? 100 : 2} />
                </Box>
                {item?.completed
                ?<Box w={'250px'} pl={5}>
                    <Text color={'green.500'}>Job Completed</Text>
                    <Text ml={1}>{moment(item?.complete_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                </Box>
                :<Box w={'250px'} pl={5}>
                    <Text color={'red.500'}>Not Completed</Text>
                </Box>}
            </Flex>
        </Flex>
        ))
    : null;

    // const readTextFile = (file) => {
	// 	var rawFile = new XMLHttpRequest();
	// 	rawFile.open("GET", file, false);
	// 	rawFile.onreadystatechange = () => {
	// 		if (rawFile.readyState === 4) {
	// 			if (rawFile.status === 200 || rawFile.status == 0) {
	// 				var allText = rawFile.responseText;
    //                 return(allText);
	// 			}
	// 		}
	// 	};
	// 	rawFile.send(null);
	// };

    const collectTextResult = (result_text) => {
        var correctjson = result_text.replace((/'/g), "\"");
        let resultObj = JSON.parse(correctjson);
        let resultText = resultObj.result_text;
        return resultText
    }

    const renderWorkflowResult = resultData !== null && resultData?.length > 0
    ? resultData?.map((item, index) => (
        <Card direction={'column'} key={index} h={'1000px'}
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            {/* <Text>{JSON.stringify(item)}</Text> */}
            {item?.type === 'pdf'
                ?<Flex w={'100%'} h={'800px'}>
                    <DocViewer 
                    pluginRenderers={DocViewerRenderers} 
                    documents={[{uri: item?.url, fileName:'documentTitle', fileType: 'pdf'}]}
                    config={{
                        theme:{
                        'pdf-download':true
                        },
                        header: {
                        disableHeader: true,
                        disableFileName: false,
                        retainURLParams: true,
                        },
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                        defaultZoom: 1.1, // 1 as default,
                        zoomJump: 0.2, // 0.1 as default,
                        },
                        pdfDownload: true,
                        pdfVerticalScrollByDefault: false, // false as default
                    }}
                /></Flex>:null}
            {item?.type === 'doc'
                ?<Flex w={'100%'} h={'800px'}
                    ><DocViewer 
                        style={{width: 1200, height: 800}}
                        pluginRenderers={DocViewerRenderers} 
                        documents={[{uri: item?.url, fileName:'documentTitle', fileType: 'doc'}]}
                        config={{
                            theme:{
                            height:"100%"
                            },
                            header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: true,
                            },
                        }}
                    /></Flex>:null}
                {item?.type === 'txt'
                    ?<Flex w={'100%'} h={'800px'}><DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={[{uri: item?.url, fileName:'', fileType: 'txt'}]}
                        config={{
                            theme:{
                            'pdf-download':true
                            },
                            header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: true,
                            },
                            csvDelimiter: ",", // "," as default,
                            pdfZoom: {
                            defaultZoom: 1.1, // 1 as default,
                            zoomJump: 0.2, // 0.1 as default,
                            },
                        }}
                    /></Flex>:null}
            {item?.type === 'text'
                ?<Flex w={'100%'} h={'800px'}>
                    <Text>{item?.result_text}</Text>
                </Flex>:null}
        </Card>
    ))
    : null;


    const renderWorkflowContent = contentData !== null && contentData?.length > 0
    ? contentData?.map((item, index) => (
        <Flex key={index} 
            direction={'column'}
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            {/* <Text>{JSON.stringify(item)}</Text> */}
            {item.contentType === 'pdf'
            ?<Flex w={'100%'} direction={'column'}>
                <DocViewer 
                    pluginRenderers={DocViewerRenderers} 
                    documents={[{uri: item?.s3_url, fileName:item?.title, fileType: 'pdf'}]}
                    config={{
                        theme:{
                        'pdf-download':true
                        },
                        header: {
                        disableHeader: true,
                        disableFileName: false,
                        retainURLParams: true,
                        },
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                        defaultZoom: 1.1, // 1 as default,
                        zoomJump: 0.2, // 0.1 as default,
                        },
                        pdfDownload: true,
                        pdfVerticalScrollByDefault: false, // false as default
                    }}
                />
                <Box w={'100%'}>
                    Page {pageNumber} of {numPages}
                </Box>
            </Flex>:null}
            {item.contentType === 'doc'
            ?<Flex w={'100%'} direction={'column'}>
                <DocViewer 
                    style={{width: 1200, height: 800}}
                    pluginRenderers={DocViewerRenderers} 
                    documents={[{uri:  item?.s3_url, fileName: item?.title, fileType: 'doc'}]}
                    config={{
                        theme:{
                        height:"100%"
                        },
                        header: {
                            disableHeader: true,
                            disableFileName: false,
                            retainURLParams: true,
                        },
                    }}
                />
                <Box w={'100%'}>
                    Page {pageNumber} of {numPages}
                </Box>
            </Flex>:null}
            {item.contentType === 'audio'
            ?<Flex w={'100%'} direction={'column'}>
                <Text fontSize={'lg'}>Play your Source Content (audio)</Text>
                <ReactAudioPlayer
                    src={item.s3_url}
                    autoPlay={false}
                    controls
                />
            </Flex>:null}
            {item.contentType === 'text'
            ?<Flex w={'100%'} direction={'column'}>
                <Text fontSize={'lg'}>Source Text</Text>
                <Text>{item?.source_text}</Text>
            </Flex>:null}
        </Flex>
        ))
    : null;

    const renderBillingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} 
            direction={'column'}
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Box w={'100%'} ml={1} pl={2}>
                {item?.billed 
                ?<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Billed
                </Flex>
                :<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Not Billed
                </Flex>}
                {item?.billed === false ? ( null ) :
                    item?.paid
                    ?(<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Paid
                    </Flex>) : (
                    <Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'red.300'} mr={1}/> Not Paid
                    </Flex> )
                }
            </Box>
        </Flex>
        ))
    : null;
    
    const renderProofreadingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} 
            direction={'column'}
            w={'100%'} mt={2}>
            <Box w={'100%'} ml={1} pl={2}>
                {item?.proofreading 
                ?<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Proofreading Added
                </Flex>
                :<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'gray.300'} mr={1}/> Proofreading Not Added
                </Flex>}
            </Box>
        </Flex>
        ))
    : null;

    const renderReportingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} direction={'column'} w={'100%'} mt={2}>
            <Box w={'100%'} ml={1} pl={2}>
                {item?.email_response 
                ?<Flex w={'100%'}>
                    {item?.email_sent
                    ?<Flex w={'100%'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/>The results are sent in the email, please check  your email. 
                    </Flex>
                    :<Flex w={'100%'}>
                        <Icon mt={1} as={BiCheckCircle} color={'gray.300'} mr={1}/> We could not sent an email with result, only status update.
                    </Flex>}
                </Flex>
                :<Flex w={'100%'}>
                    <Icon mt={1} as={BiCheckCircle} color={'red.200'} mr={1}/> You did not select to get response in your email update.
                </Flex>}
            </Box>
        </Flex>
        ))
    : null;


    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card direction="column" mt="5" borderWidth="0.5px">
                <Flex w={'100%'}>
                <Text>{' '}Workflow Jobs Perform</Text>
                <Spacer />
                {workflowUuid != null
                ?<Button 
                    onClick={() => {
                        navigate("/admin/workflowjobs", {
                        state: {
                            // workflowUuid: item?.wuuid,
                            // projectUuid: item?.puuid,
                            // contentUuid: item?.cuuid
                        },
                        })
                    }}
                    size={'sm'}>Back to Workflow Jobs</Button>:null}
                </Flex>
            </Card>
            {loading ? (
                <Flex alignItems="center" justifyContent="center" h="60vh">
                <Spinner size="lg" />
                </Flex>
            ) : (
                <>
                {workflowData !== null && workflowData?.length ? (
                    <Flex w={'100%'} direction={'column'}>
                        <Card w={'100%'}>
                            {renderWorkflowDetails}
                        </Card>
                        <Card w={'100%'} mt={1}>
                            <Tabs>
                            <TabList>
                                <Tab>Original Source Content</Tab>
                                <Tab>Results Content</Tab>
                                <Tab>Proofreading Results</Tab>
                                <Tab>Progress Reporting</Tab>
                                <Tab>Billing Details</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderWorkflowContent}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderWorkflowResult}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderProofreadingDetails}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderReportingDetails}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderBillingDetails}
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                            </Tabs>
                        </Card>
                        {/* {renderWorkflowResult}
                        <Card w={'100%'} mt={1}>
                        </Card> */}
                    </Flex>
                ) : (
                    <Card w={'100%'}>
                        <Text p="2" fontWeight={"500"}>
                            No Record Found
                        </Text>
                    </Card>
                )}
                </>
            )}
        </Box>
        </>
    );
}

export default PerformJob;
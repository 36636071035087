import React, { useState, useEffect } from "react";

// Chakra Import
import {
	Box,
	Flex,
	Text,
	Button,
	Alert,
	Drawer,
	Spinner,
	AlertIcon,
	FormLabel,
	SimpleGrid,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import InputField from "../../components/fields/InputField";

// Api Call Imports
import { addNewProject, getAllProject, updateProjectDetails } from "../../services/businessServices";

// Component Imports
import ProjectCard from "./ProjectCard";
import { toastFunctionToaster } from "../../utils/toastFunction";

function ProjectListing() {
	const toast = useToast();
	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [projectTitle, setProjectTile] = useState("");
	const [projectInfo, setProjectInfo] = useState("");
	const [projectList, setProjectList] = useState([]);
	const [project_uuId, setProject_uuId] = useState("");
	const [projectTitleFlag, setProjectTileFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);

	//   Todo:Fetch all projects
	useEffect(() => {
		fetchProjectDetails();
	}, []);

	const fetchProjectDetails = () => {
		isLoading(true);
		let paramObj = {
			filter_key: "string",
			filter_value: "string",
			records_per_page: 0,
			page_id: 0,
		};
		getAllProject(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.records) {
					setProjectList(res?.data[0]?.records);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	//   Todo:API to create new project
	const createUpdateProjectDetails = () => {
		if (projectTitle?.length) {
			setProjectTileFLag(false); //Project title validation flag
			let objBody = {
				project_title: projectTitle,
				project_info: projectInfo,
				project_icon: "",
			};
			setSaveDetailsLoader(true);
			if (project_uuId) {
				editProjectDetails();
			} else {
				addNewProject(objBody)
					.then(res => {
						setSaveDetailsLoader(false);
						if (res?.result) {
							onClose();
							resetProjectDetails();
							fetchProjectDetails();
							toast(toastFunctionToaster(res.message, "success"));
						} else {
							onClose();
							resetProjectDetails();
							toast(toastFunctionToaster(res.message, "error"));
						}
					})
					.catch(err => {
						onClose();
						resetProjectDetails();
						setSaveDetailsLoader(false);
						toast(toastFunctionToaster(err.message, "error"));
					});
			}
		} else {
			setProjectTileFLag(true);
		}
	};

	// Todo:Edit Project Api call
	const editProjectDetails = () => {
		let objBody = {
			project_uuid: project_uuId,
			project_title: projectTitle,
			project_info: projectInfo,
			project_icon: "",
		};
		updateProjectDetails(objBody)
			.then(res => {
				setSaveDetailsLoader(false);
				if (res?.result) {
					onClose();
					resetProjectDetails();
					fetchProjectDetails();
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					onClose();
					resetProjectDetails();
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				onClose();
				resetProjectDetails();
				setSaveDetailsLoader(false);
				toast(toastFunctionToaster(err.message, "error"));
			});
	};

	// Todo:Fetch Data From Project Card Component
	const handleProjectData = data => {
		onOpen();
		setProject_uuId(data?.project_uuid);
		setProjectInfo(data?.project_info);
		setProjectTile(data?.project_title);
	};

	const renderProjectDetails = () => {
		fetchProjectDetails();
	};

	//   Todo:Render Project Details
	const renderProjectCard = projectList?.map((data, index) => (
		<ProjectCard
			projectData={data}
			returnProjectDetailsData={handleProjectData}
			reloadProjectDetails={renderProjectDetails}
		/>
	));

	// Todo:Reset Project Details
	const resetProjectDetails = () => {
		setProjectInfo("");
		setProjectTile("");
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		setProject_uuId("");
		resetProjectDetails();
	};

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				<Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
					<Text fontWeight={"500"} fontSize={"18px"}>
						Translation and Transcription Projects
					</Text>
					<Button colorScheme="blue" size="sm" onClick={onOpen}>
						Create New Project
					</Button>
				</Flex>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="xl" />
					</Flex>
				) : projectList?.length ? (
					<SimpleGrid columns={3} spacing={10} mt={5}>
						{renderProjectCard}
					</SimpleGrid>
				) : (
					<Alert status="warning" mt="4">
						<AlertIcon />
						No Project Found
					</Alert>
				)}
			</Box>
			{/* // Add Project Drawer */}
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{" "}
						{project_uuId ? "Update" : "Create New"} Project
					</DrawerHeader>
					<DrawerBody overflow="hidden" mt="5">
						<InputField
							mb="0"
							me="30px"
							id="project_title"
							label="Project Title"
							value={projectTitle}
							name="project_title"
							required="true"
							borderRadius="5px"
							onChange={e => {
								setProjectTile(e.target.value);
							}}
							placeholder="Enter Project Title"
							w="100%"
							maxlength={100}
						/>
						<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
							{projectTitle?.length}/100
						</Text>
						{projectTitleFlag ? (
							<Text fontSize="sm" color={"red"} mb="5" mt="1">
								Project Title is Required
							</Text>
						) : null}
						<FormLabel mt="3" ml="2" required>
							<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
								Project Info
							</Text>
							<Textarea
								mb="0"
								me="30px"
								rows="10"
								id="projectInfo"
								value={projectInfo}
								name="projectInfo"
								onChange={e => {
									setProjectInfo(e.target.value);
								}}
								placeholder="Enter Project Info"
								w="100%"
								maxlength={500}
							/>
							<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
								{projectInfo?.length}/500
							</Text>
						</FormLabel>
					</DrawerBody>

					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							onClick={() => {
								setProject_uuId("");
								resetProjectDetails();
								createUpdateProjectDetails();
							}}
							isDisabled={saveDetailsLoader ? true : false}
							isLoading={saveDetailsLoader ? true : false}>
							{project_uuId ? "Update" : "Create New"} Project
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default ProjectListing;

import React, { useState, useEffect, useRef  } from "react";

// Chakra Import
import {
    Button,
    Box,
    Flex,
    Text,
    Icon,
    Image,
    Avatar,
    Switch,
    Spacer,
    Heading,
    Spinner,
    useToast,
    Checkbox,
    useDisclosure,
    AlertDialog,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,

} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import {
    AiFillEdit,
    AiOutlineStop,
    AiFillLeftCircle,
    AiFillRightCircle,
    AiOutlineCheckCircle,
    AiOutlineCheckSquare,
    AiOutlineLock,
} from "react-icons/ai";
import moment from "moment";
import QRCode from "react-qr-code";

// Api Call Imports
import { 
    fetchSubscriberLingoLinks,
    fetchSubscriberManageLingoLink,
    fetchSubscriberList } from "../../../services/accountServices";

// Component Imports
// import InviteAccountAdmin from "./InviteAccountAdmin";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useBrandBg, 
    usePillButtonBg,
    useGray500TextColor, usePillButtonBgHover,
    useGreenBg, useBoxHoverHighlight } from "../../../theme/globalColorTheme";
import LinkApplication from "../../subscriber/links/LinkApplication";


function SubscriberLingoLinks({ locationDetails, state }) {
    const linkAccountUuid = locationDetails?.link_account_uuid ? locationDetails?.link_account_uuid : null;
    const link_location_uuid = locationDetails?.link_location_uuid;

    const port_value = window.location.port ? ":" + window.location.port : '';
	const currentHost = window.location.hostname.includes("localhost")
		? "https://lingolet.ai/"
		: window.location.protocol + "//" + window.location.hostname + port_value + "/";

    const btnRef = React.useRef();
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure()
    const cancelRef = useRef()

    const toast = useToast();
    const brandBg = useBrandBg();
    const [loading, isLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [subscriberList, setSubscriberList] = useState([]);
    const [subscriberLingoLinks, setSubscriberLingoLinks] = useState([]);
    const [activeLink, setActiveLink] = useState(null);
    const [selectedSubsData, setSelectedSubsData] = useState({})
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [resetLoader,setResetLoader] = useState(false)


    useEffect(() => {
        collectSubscriberList();
    }, []);
    
    // Todo:Fetch Subscriber List Details
    const collectSubscriberList = () => {
        isLoading(true);
        let paramObj = {
            link_account_uuid: linkAccountUuid,
            link_location_uuid: link_location_uuid,
            records_per_page: 10,
            page_id: currentPage
        };
        fetchSubscriberList(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.total_records) {
                    isLoading(false);
                    setSubscriberList(res?.data[0]?.records);
                    setTotalPages(res?.data[0]?.totalPages)
                } else {
                    isLoading(false);
                    setSubscriberList([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setSubscriberList([]);
            });
    };

    // Todo:Fetch Subscriber LingoLink List Details
    const collectSubscriberLingoLinks = (subscriber_uuid) => {
        isLoading(true);
        let paramObj = {
            user_uuid: subscriber_uuid,
            link_account_uuid: linkAccountUuid,
            link_location_uuid: link_location_uuid,
            records_per_page: 10,
            page_id: currentPage
        };
        fetchSubscriberLingoLinks(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.records?.length > 0) {
                    isLoading(false);
                    setSubscriberLingoLinks(res?.data[0]?.records);
                } else {
                    isLoading(false);
                    setSubscriberLingoLinks([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setSubscriberLingoLinks([]);
            });
    };

    // Todo:Fetch Subscriber LingoLink List Details
    const manageSubscriberLingoLink = () => {
        if (selectedSubscriber != null){
            isLoading(true);
            let paramObj = {
                user_uuid: selectedSubscriber,
                lingolink_uuid: activeLink, // lingolink_uuid,
                link_account_uuid: linkAccountUuid,
                link_location_uuid: link_location_uuid,
            };
            fetchSubscriberManageLingoLink(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        isLoading(false);
                        toast(toastFunctionToaster("Successfully disabled the selected LingoLink", "success"));
                        collectSubscriberLingoLinks(selectedSubscriber);
                        onAlertClose();
                    } else {
                        isLoading(false);
                        toast(toastFunctionToaster("Failed to disable to selected LingoLink", "error"));
                   }
                })
                .catch(err => {
                    isLoading(false);
                    toast(toastFunctionToaster("Failed to disable to selected LingoLink", "error"));
                });
        }
    };

    const renderSubscriberList =
    subscriberList !== null && subscriberList?.length > 0 ? (
        subscriberList?.map((item, index) => (
            <Flex
                onClick={() => {
                    setSelectedSubscriber(item?.user_uuid);
                    collectSubscriberLingoLinks(item?.user_uuid);
                }}
                w={"100%"}
                key={index}
                _hover={{cursor: 'pointer'}}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}
                direction={'column'}
                borderWidth={"0.05px"}
                borderLeftWidth={'10px'}
                borderLeftColor={item?.is_disable ? "red.300" : "green.300"}
                borderColor={item?.is_disable ? "red.200" : "green.300"}>
                <Flex w={'100%'}>
                    <Flex
                        rounded={"md"}
                        px={1}
                        w={"100px"}
                        align={"center"}>
                        <Box minW={"60px"}>
                            <Avatar />
                        </Box>
                    </Flex>
                    <Flex w={'100%'} direction={'column'}>
                        <Flex minW={"100%"} p={1} direction={"column"} flexWrap={'wrap'}>
                            <Text fontWeight={'bold'}>
                                {item?.first_name} <Text as="span" fontWeight={'bold'}>{item?.last_name}</Text>
                            </Text>
                            <Text>
                                {item?.account_subscriber_email}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                This location does not have any subscribers..
            </Heading>
        </Flex>
    );

    function handleOpenAlert(uuid) {
        setActiveLink(uuid);
        onAlertOpen();
    }

    // function handleAlertClose() {
    //     onAlertClose();
    //     setActiveLink(null);
    // }

    const renderSubscriberLingoLinkList =
    subscriberLingoLinks !== null && subscriberLingoLinks?.length > 0 ? (
        subscriberLingoLinks?.map((item, index) => (
            <Flex
                //onClick={() => collectSubscriberLingoLinks(item?.user_uuid)}
                w={"100%"}
                key={index}
                // _hover={{cursor: 'pointer'}}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}
                direction={'column'}
                borderWidth={"0.05px"}
                borderLeftWidth={'10px'}
                borderLeftColor={item?.is_disable ? "red.300" : "green.300"}
                borderColor={item?.is_disable ? "red.200" : "green.300"}>
                <Flex w={'100%'}>
                    {/* <Flex
                        rounded={"md"}
                        px={1}
                        w={"100px"}
                        align={"center"}>
                        <Box minW={"60px"}>
                            <Avatar />
                        </Box>
                    </Flex> */}
                    <Flex maxW={"150px"}>
                        <Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
                            <Box
                                w={"100%"}
                                onClick={() => {
                                    // setModalQrCode(item?.fullurl);
                                    // onOpenQr();
                                }}
                                _hover={{ cursor: "pointer", boxShadow:useBoxHoverHighlight, borderColor: "blue.600", borderWidth: "1px" }}
                                p={1}
                                borderColor={"gray.400"}
                                borderWidth={"1px"}
                                rounded={"md"}>
                                <QRCode
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100", width: "100" }}
                                    value={item?.fullurl}
                                    viewBox={`0 0 90 90`}
                                />
                            </Box>
                            {/* <Text>QR(Url+Code)</Text> */}
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} direction={'column'}>
                        <Flex minW={"100%"} p={1} direction={"column"} flexWrap={'wrap'}>
                            <Box w={'150px'} color="black" align='center' rounded={'md'} borderWidth={'0.05px'} borderColor={'green.200'}>
                                {item?.class === 1 ?<Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} /> Unlimited Link</Text>:null}
                                {item?.class === 2 ?<Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1}  />Trial Link</Text>:null}
                                {item?.class === 3 ?<Text><Icon as={AiOutlineCheckCircle} color={"gray.600"}  mt={1} mr={1} />Fixed Link</Text>:null}
                                {item?.multichannel?<Text><Icon as={AiOutlineCheckCircle} color={"gray.600"}  mt={1} mr={1} />Multichannel</Text>:null}
                            </Box>
                            <Text fontWeight={'bold'}>
                                {item?.fullurl ? currentHost + item?.fullurl : "No Url"}
                            </Text>
                            <Text>
                                {item?.title}
                            </Text>
                            {/* <Text>
                                Start Time: {moment.utc(item?.starttime).local().format("MMM DD,YYYY,HH:mm a")}
                                {"  End Time"}
                                {moment.utc(item?.endtime).local().format("MMM DD,YYYY,HH:mm a")}
                            </Text> */}
                            {item?.tags && <Text as="span" fontWeight={'bold'}>Tags: {item?.tags}</Text>}
                            {item?.class === 3 && <Flex w={"100%"} direction="column">
                                <Flex>
                                    <Text color={useGray500TextColor}>
                                        Link Start Time (UTC):
                                    </Text>
                                    <Text ml={2}>
                                        {moment.utc(item?.starttime).local().format("MMM DD,YYYY,HH:mm a")}
                                    </Text>
                                </Flex>

                                <Flex>
                                    <Text  color={useGray500TextColor}>
                                        Link End Time (UTC):
                                    </Text>
                                    <Text ml={2}>
                                        {moment.utc(item?.endtime).local().format("MMM DD,YYYY,HH:mm a")}
                                    </Text>
                                </Flex>
								</Flex>}

                                <LinkApplication linkItem={item} subAppDetails={item?.subAppDetails}/>
                        </Flex>
                    </Flex>
                    <Spacer />
                    <Flex>
                        {selectedSubscriber != null
                        ?<Button 
                            onClick={() => {
                                handleOpenAlert(item?.uuid);
                                //manageSubscriberLingoLink(item?.uuid)
                            }}
                            size={'sm'} bg={usePillButtonBg} rounded={'lg'}>Disable Link</Button>
                        :null}
                    </Flex>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                This subscriber does not have any LingoLinks.
            </Heading>
        </Flex>
    );

    return (
        <>
            <Flex w={'100%'}>
                <Card w={'100%'} h={'calc(100vh - 200px)'} p={1} alignItems="center" rounded={'md'}>
                    <Flex w={'100%'} h="100%">
                        <Box
                            w={!configurationCollapseFlag ? "96%" : "85%"}
                            bg={brandBg}
                            p={1}
                            borderRadius={"10px"}
                            overflow="hidden" h="100%">
                            <Flex w={"100%"} h="100%" direction={"column"}>
                                {loading ? (
                                    <Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
                                        <Spinner size={"xl"} />
                                    </Flex>
                                ) : (
                                    <Flex w={"100%"} h="100%" direction={"column"}>
                                        <Flex
                                            direction={"column"}
                                            w={"100%"}
                                            h="100%"
                                            overflow="auto"
                                            borderTopWidth="0px"
                                            className="custom-scrollbar">
                                            {renderSubscriberLingoLinkList}
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        </Box>
                        {configurationCollapseFlag ? (
                            <Flex w="35%">
                                <Box
                                    w="100%"
                                    position="relative"
                                    p="3"
                                    borderRadius="15px"
                                    bg={brandBg}
                                    ml="2">
                                    <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                        <AiFillRightCircle
                                            cursor="pointer"
                                            size="20px"
                                            onClick={() => {
                                                setConfiguationCollapseFlag(!configurationCollapseFlag);
                                            }}
                                        />
                                    </Box>
                                    <Box w="100%" h="95%">
                                        <Text
                                            fontSize="16px"
                                            mt="2"
                                            fontWeight='bold'
                                            >
                                            Subscribers List
                                        </Text>
                                        {renderSubscriberList}

                                    </Box>
                                </Box>
                            </Flex>
                        ) : (
                            <Box w="4%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                                <Box w={"100%"} h="100%">
                                    <AiFillLeftCircle
                                        cursor="pointer"
                                        onClick={() => {
                                            setConfiguationCollapseFlag(!configurationCollapseFlag);
                                        }}
                                        size="20px"
                                    />
                                    <Text
                                        fontSize="16px"
                                        mt="2"
                                        fontWeight='bold'
                                        css={{
                                            writingMode: "vertical-lr",
                                            textOrientation: "mixed",
                                        }}>
                                        Subscribers List
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Card>
                <AlertDialog
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onAlertClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Disable Link
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure? Disabled link instantly becomes unusable..
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onAlertClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme='red' 
                                    onClick={() => manageSubscriberLingoLink()} ml={3}>
                                    Disable Link
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Flex>
        </>
    )
}

export default SubscriberLingoLinks;
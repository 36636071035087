export const DRAWER = {
    ADDUPDATECARD: 'addUpdateCard',
    SHARECARD: 'shareCard',
    CARD_DETAILS: 'cardDetails',
    SHARESMS:'sharesms'
}

export const lingoCardFilters = {
    ACTIVE: 'active',
    DISABLE: 'disable'
}
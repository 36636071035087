import React from 'react'
import { Route } from 'react-router-dom'
import LandingPage from '../ailab/landinghome/LandingPage'
import InvestorTeam from '../ailab/investment/investors/InvestorTeam'
import CompaniesInvested from '../ailab/investment/portfolio/CompaniesInvested'
import ApplicantLayout from '../sideBarRoutes/ApplicantLayouts'
import AddProfile from '../ailab/applicant/AddProfile'

const useAiLabRoutes = () => {
  return (
    <Route path="/ailab">
        <Route path='/ailab/' element={<LandingPage/>}/>
        <Route path='/ailab/investors' element={<InvestorTeam/>}/>
        <Route path="/ailab/companies" element={<CompaniesInvested/>} />
        <Route path="/ailab/add-profile" element={<AddProfile />} />
        <Route path="/ailab/applicant/*" element={<ApplicantLayout />} />
    </Route>
  )
}

export default useAiLabRoutes
import React, { useRef } from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import ReactDOM from "react-dom";
import { useLocation, useNavigate, location } from 'react-router-dom';
import {
  Button, Icon,
  Box,
  Flex,
  Image,
  Heading,
  Link,
  Text,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Center,
} from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import { FaPlus, FaArrowRightArrowLeft, FaBilibili, FaRocketchat, 
    FaArrowsDownToPeople, FaArrowsTurnToDots, FaOptinMonster,
    FaAlignJustify } from "react-icons/fa6";

import WaitListingComp from "../../komponents/landinghome/WaitListMe";
import LingoletFooter from "../landinghome/LingoletFooter";

import logo from "../../assets/landingPageImg/kb_logo.png";
import ceslogo from "../../assets/marketing/ces-logo.png";
import ceshero from "../../assets/marketing/ces-hero.png";
import banner from "../../assets/landingPageImg/lingo-hero.png";

function CESMediaKit() {
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const announcementDiv = useRef(null);

        // Todo:Scroll to targeted div
    const scrollToDiv = (divType) => {
        if (divType === "announcements") {
            if (announcementDiv.current) {
                ReactDOM.findDOMNode(announcementDiv.current).scrollIntoView({
                behavior: "smooth",
                block: "start",
                });
            }
        }
    };


    const launchPage = (url) => {
        navigate(url, {
            state: {
                sourcePage:'cesmediakit',
            },
        });
    };

    return (
        <>
            <Box bg="#fff" color="#000">
                <Flex
                    w={{
                        base: "50%",
                        md: "90%",
                        xl: "50%",
                        sm: "100%",
                    }}
                    mx="auto"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    h="12vh"
                >
                    <Box w={'80%'}>
                        <Image
                            onClick={() => launchPage('/')}
                            _hover={{cursor:'pointer'}}
                            w={{
                            base: "50%",
                            md: "50%",
                            xl: "50%",
                            sm: "50%",  
                            }}
                            src={logo}
                        ></Image>
                    </Box>
                    <Box w={'20%'}>
                        <Icon as={FaTimes} h={'12'} w={'12'} />
                    </Box>
                    <Box w={'80%'}>
                        <Image
                            w={{
                            base: "100%",
                            md: "100%",
                            xl: "100%",
                            sm: "100%",  
                            }}
                            src={ceslogo}
                        ></Image>
                    </Box>
                    <Box
                        w={{
                            base: "50%",
                            md: "90%",
                            xl: "50%",
                            sm: "90%",
                        }}
                        mx="auto"
                        textAlign={"center"}
                        my="16"
                        bg="#fff"
                        >
                            <Flex
                            alignItems={"center"}
                            fontSize={{
                                base: "14px",
                                md: "14px",
                                xl: "14px",
                                sm: "10px",
                            }}
                            bg="white"
                            >
                                <Text
                                    m="0"
                                    fontWeight={"bold"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                    scrollToDiv("announcements");
                                    }}
                                    color="#000"
                                >
                                    Announcements
                                </Text>
                            </Flex>
                    </Box>
                </Flex>
                <Box position="relative">
                    <Box>
                        <Image w={'100%'} src={ceshero} />
                    </Box>
                </Box>
                <Flex 
                    ref={announcementDiv}
                    align={'center'} position="relative" direction={'column'}>
                    <Text mt={5} p={1} rounded={'md'} bg={'yellow.200'} fontSize={'3xl'}>Announcements</Text>
                    <Text mt={5} fontSize={'4xl'}>Lingolet Announces Strategic Partnership with Onemeta for Global Language Solutions</Text>
                    
                    <Flex w={'80%'} direction={'column'}>
                        <Text>
                        We are excited to announce a significant milestone for <a href={'https://lingolet.ai/'} color={'blue'} target={'_blank'}><b>Lingolet</b></a>, a leading provider of language solutions through its innovative IoT device and AI platform. Lingolet has entered into a strategic agreement with <a href={'https://www.onemeta.ai/'} color={'blue'} target={'_blank'}><b>Onemeta</b></a>, combining technologies to offer unparalleled language services on a global scale.
                        <br/><br/>
                        Through our dedicated IoT device and AI platform, Lingolet empowers users to access live interpreters on demand in over 100 languages. The partnership with Onemeta will leverage the strengths of both organizations, enabling joint go-to-market sales activities and expanding the reach of our enterprise worldwide sales teams.
                        <br/><br/>                        
                        This collaboration introduces a groundbreaking option for interpreting requestors: the choice between a <b>live interpreter</b> and the <b>Secure AI Interpreting platform ™</b>. By merging these two cutting-edge technologies, users can seamlessly switch between human and AI interpretation, providing flexibility and efficiency in communication.
                        <br/><br/>
                        Jerry Song, CEO of Lingolet, expresses enthusiasm about the partnership's potential impact. <i>"This alliance allows for high-quality, low-cost interpreting at the point of service (POS), revolutionizing communication experiences globally. Whether at a hotel counter or a bank teller's fingertips, language will no longer be a barrier. From consumers to enterprises, we are breaking down linguistic barriers and enhancing communication possibilities."</i>                        
                        <br/><br/>
                        To learn more about Lingolet and our transformative language solutions, please visit our website: <a href={'https://lingolet.ai/'} color={'blue'} target={'_blank'}><b>https://lingolet.ai</b></a>.
                        <br/><br/>
                        </Text>
                        <Text mt={2} fontSize={'lg'}>
                        Communicate better with Lingolet - bridging gaps, fostering understanding.
                        </Text>
                    </Flex>
                </Flex>
                <LingoletFooter />
                <Box bg="#fff">
                    <Text color="#000" textAlign={"center"} p="8">
                        &copy; Copyright 2024 Lingolet AI, Inc. All rights reserved.
                    </Text>
                </Box>
            </Box>
        </>
    )
}


export default CESMediaKit;



import React, { useEffect, useState } from "react";

// Chakra Imports
import { Box, 
    Flex, 
    Avatar,
    Button,
    Icon,
    Spinner,  
    Spacer,
    Badge,
    Text,
    useColorModeValue,
    useColorMode, 
    HStack} from "@chakra-ui/react";

import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync, AiTwotoneAudio,
    AiOutlineVideoCamera
    } from "react-icons/ai";
import moment from "moment";    
import { formatTime, formatSeconds} from '../../../utils/TimeFunctions';
import {countUniqueValues} from '../../../utils/JsonObjectFunctions';

// Assets
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";

// Component Import
import Card from "../../../components/card/Card";
import { fetchBusinessList, getAllLingoCalls } from "../../../services/adminServices";

function LingolinkListing() {
    const { colorMode } = useColorMode();
    const [userId, setUserId] = useState("");

    const [loading, isLoading] = useState(true);
    const [businessId, setBusinessId] = useState(null);
    const [businessList, setBusinessList] = useState([]);

    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

    const [linkCallsList, setLinkCallsList] = useState([]);
    const [linkCallsSummary, setLinkCallsSummary] = useState(null);
    const [recordsPerPage, setRecordsPerPage] = useState(25);

    useEffect(() => {
        fetchBusienessDetails();
    }, []);

    const fetchBusienessDetails = () => {
        isLoading(true);
        let obj = {
        records_per_page: 0,
        page_id: 0,
        records_length: 0,
        };
        fetchBusinessList(obj)
        .then((res) => {
            isLoading(false);
            if (res?.data[0]?.records?.length) {
                setBusinessList(res?.data[0]?.records);
            } else {
                setBusinessList([]);
                setLinkCallsSummary(null);
                isLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
            isLoading(false);
        });
    };

    const fetchLinkCallDetails = (businessUuid, pageRecord) => {
        isLoading(true);
        let obj = {
            filter_key: 'business_uuid',
            filter_value: businessUuid,
            records_per_page: pageRecord,
            page_id: 0,
            records_length: 0,
        };
        getAllLingoCalls(obj)
        .then((res) => {
            isLoading(false);
            if (res?.data[0]?.records?.length) {
                setLinkCallsList(res?.data[0]?.records);
                setLinkCallsSummary(res?.data[0]?.summary);
            } else {
                setLinkCallsList([]);
                isLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
            isLoading(false);
        });
    };

    //   // Todo:Pass Business Id To Business User Component
    //   const handleBusinessId = (data) => {
    //     setBusinessId(data?.business_uuid);
    //   };

    //   // Todo:Pass Business Details To Module List Component
    //   const handleBusinessUserDetails = (data) => {
    //     setUserId(data?.user_uuid);
    //   };

    const renderBusinessList =
    businessList !== null && businessList?.length
        ? businessList?.map((data, index) => (
            <Box
            key={index}
            borderRadius={"10px"}
            p="2"
            mb="3"
            cursor={"pointer"}
            boxShadow={"md"}
            bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
            borderColor={
                businessId === data?.business_uuid ? "grey" : SchemeBorderColor
            }
            borderWidth="3px"
            onClick={() => {
                setBusinessId(data?.business_uuid);
                fetchLinkCallDetails(data?.business_uuid, 25);
            }}
            >
            <Flex w={"100%"}>
                <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                <Avatar
                    h={{ base: "40px", "2xl": "50px" }}
                    w={{ base: "40px", "2xl": "50px" }}
                    src={avatar}
                    me="16px"
                />
                </Box>
                <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
                >
                <Text fontSize={'md'} fontWeight={"bold"} wordBreak={"break-all"}>
                    {data?.business_name}
                </Text>
                <Text fontSize={'sm'}>
                    {data?.city}
                    {data?.state} {data?.zip_code} {data?.country}
                </Text>
                </Flex>
            </Flex>
            </Box>
        ))
        : null;

    const renderSessionCalls = linkCallsList !== null && linkCallsList?.length > 0
    ? linkCallsList?.map((item, index) => (
        <Flex w={'100%'} key={index} 
            direction={'column'}
            p={2} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex w={'100%'}>
                <Flex w={'50px'}>
                    <Text >{index+1}</Text>
                </Flex>
                <Flex maxW={'180px'} minW={'180px'} direction={'column'} p={1}>
                    <Text fontSize={'xs'}>{moment(item?.start_time).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    <Text p={1} bg={'green.100'} rounded={'md'}>{item?.key}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'} p={1}>                    
                    <Text fontWeight={500} color={'gray.400'}>{item?.mask}</Text>
                    <Text>{item?.provider}({item?.source_language}{'->'}{item?.target_language})</Text>
                </Flex>
                <Flex w={'150px'} align={'center'} fontSize={'sm'}>
                    <Text w={'30px'} roundedLeft={'lg'} p={1} 
                        bg={item?.type === 'audio' ? 'green.300' : 'blue.300'} align={'center'} pt={1}>
                        {item?.type === 'audio' ? <Icon h={4} w={4} as={AiTwotoneAudio} /> : <Icon h={4} w={4} as={AiOutlineVideoCamera} />}
                    </Text>
                    <Text w={'80px'} p={1} bg={'blue.100'}>{formatSeconds(item?.duration_seconds)}</Text>
                    <Text w={'40px'} roundedRight={'lg'} p={1} bg={'green.100'}>{item?.amount.toFixed(2)}</Text>
                </Flex> 
                <Flex minW={'200px'} direction={'column'} fontSize={'xs'} p={1}>
                    <Text>Start {moment(item?.start_time).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    <Text>End {moment(item?.end_time).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                </Flex>            
            </Flex>
            {item?.count > 0
            ?<Flex w={'100%'} direction={'column'} >
                {renderSessionCalls(item?.calls)}
            </Flex>:null}
        </Flex>
    ))
    :<Flex w={'100%'} p={2}>
        <Text bg={'red.100'} rounded={'md'} p={3}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this LingoLink</Text>
    </Flex>

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card px="0px" p={1}>
                <Flex justifyContent={"space-between"}>
                <Box
                    maxW="300px" minW={'300px'}
                    boxShadow={"md"}
                    borderWidth={"1px"}
                    borderStyle="dotted"
                    borderRadius={"10px"}
                    h="calc(100vh - 120px)"
                    borderBottomColor={"gray.400"}
                    bg={colorMode === "dark" ? "#111c44" : null}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Box w={'100%'} align={'center'} p={2}>
                            <Text fontWeight={"500"}>Business List</Text>
                        </Box>
                        {businessList !== null && businessList.length ? (
                            <Box p="2">{renderBusinessList}</Box>
                        ) : (
                            <Text p="2" fontWeight={"500"}>
                            No Business Found
                            </Text>
                        )}
                    </Flex>
                </Box>
                <Box
                    w="100%"
                    boxShadow={"md"}
                    borderWidth={"1px"}
                    borderStyle="dotted"
                    borderRadius={"10px"}
                    h="calc(100vh - 120px)"
                    borderBottomColor={"gray.400"}
                    bg={colorMode === "dark" ? "#111c44" : null}
                >
                    <Flex w={'100%'} 
                        h='calc(100vh - 160px)' 
                        overflow="auto"
                        borderTopWidth="0px"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '24px',
                            },
                        }}
                        direction={'column'}>
                    {renderSessionCalls}
                    </Flex>
                    <Flex w={'100%'} bg={'gray.200'}>
                        {linkCallsList !== null && linkCallsList?.length > 0 && linkCallsSummary != null && linkCallsSummary?.length == 1
                        ?<Flex w={'100%'} p={2} h={'40px'} bg={'gray.300'} align={'center'}>
                            <Box align={'start'}>
                                <Text ml={1}>Keys: <Badge>{countUniqueValues(linkCallsList, 'key')}</Badge></Text>
                            </Box>
                            <Box align={'start'} ml={2}>
                                <Text ml={1}>Sessions: <Badge>{countUniqueValues(linkCallsList, 'mask')}</Badge></Text>
                            </Box>
                            <Box align={'start'} ml={2}>
                                <Text ml={1}>Calls: <Badge>{linkCallsList?.length}</Badge></Text>
                            </Box>
                            <Box align={'start'} ml={2}>
                                <Text ml={1}>Total Time: <Badge>{formatSeconds(Math.round(linkCallsSummary[0].duration,2))}</Badge></Text>
                            </Box>
                            <Box align={'end'} ml={2}>
                                <Text ml={1}>Total Charged $ Amount: <Badge bg={'green.200'}>${linkCallsSummary[0].amount.toFixed(2)}</Badge></Text>
                            </Box>                        
                            <Spacer />
                            <HStack w={'300px'} align={'center'}>
                                <Text>Records/Page</Text>
                                <Button     
                                    _hover={{bg:'blue.200'}}
                                    bg={recordsPerPage == 10 ? 'blue.100' : 'gray.100'}
                                    onClick={() => {setRecordsPerPage(10); fetchLinkCallDetails(businessId, 10)}} size={'xs'}>10</Button>
                                <Button 
                                    _hover={{bg:'blue.200'}}
                                    bg={recordsPerPage == 25 ? 'blue.100' : 'gray.100'}
                                    onClick={() => {setRecordsPerPage(25); fetchLinkCallDetails(businessId, 25)}} size={'xs'}>25</Button>
                                <Button 
                                    _hover={{bg:'blue.200'}}
                                    bg={recordsPerPage == 50 ? 'blue.100' : 'gray.100'}
                                    onClick={() => {setRecordsPerPage(50); fetchLinkCallDetails(businessId, 50)}} size={'xs'}>50</Button>
                                <Button 
                                    _hover={{bg:'blue.200'}}
                                    bg={recordsPerPage == 100 ? 'blue.100' : 'gray.100'}
                                    onClick={() => {setRecordsPerPage(100); fetchLinkCallDetails(businessId, 100)}} size={'xs'}>100</Button>
                                <Button 
                                    _hover={{bg:'blue.200'}}
                                    bg={recordsPerPage == 500 ? 'blue.100' : 'gray.100'}
                                    onClick={() => {setRecordsPerPage(500); fetchLinkCallDetails(businessId, 500)}} size={'xs'}>500</Button>

                            </HStack>
                            {/* <Box 
                                onClick={() => calculateBilling()}
                                align={'end'} ml={2} mr={'1'}>
                                <Button size={'xs'}>Calculate Amount</Button>
                            </Box> */}
                        </Flex>
                        :<Flex p={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
                            <Text ml={1}>No Report...</Text>
                        </Flex>}
                    </Flex>
                </Box>
                </Flex>
            </Card>
        </Flex>
    );
}

export default LingolinkListing;


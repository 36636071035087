import React, { useState, useEffect } from 'react'
// Chakra Import
import {
    Flex,
    Text,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useToast,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";


import { addNewLocation, updateExistingLocation } from '../../../services/accountServices';

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { countryList } from '../../../constants/Country';
import { ChevronDownIcon } from '@chakra-ui/icons';

function AddUpdateLocation(props) {
    const toast = useToast();

    const accountUuid = props.accountUuid ? props.accountUuid : null;

    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(null);
    const [locationName, setLocationName] = useState(null);
    const [locationTitle, setLocationTitle] = useState(null);
    const [locationAdd1, setLocationAdd1] = useState(null);
    const [locationAdd2, setLocationAdd2] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zipCode, setZipcode] = useState(null);
    const [country, setCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [locationNameFlag, setLocationNameFlag] = useState(null)
    const [subLocationId, setSubLocationId] = useState(null)

    useEffect(()=> {
        if (props?.subDetailsModalFlag?.length) {
            setSubLocationId(props?.subDetailsModalFlag);
        } else {
            setSubLocationId('');
        }
    },[props?.subDetailsModalFlag])

    // Todo:Update data when edit is clicked from LocationLisitng Component
    useEffect(() => {
        if (Object.keys(props?.locationData)?.length) {
            const locationData = props?.locationData
            setLocationId(locationData?.link_location_uuid ? locationData?.link_location_uuid : null)
            setLocationName(locationData?.link_location_name ? locationData?.link_location_name : null)
            setLocationTitle(locationData?.link_location_title ? locationData?.link_location_title : '')
            setLocationAdd1(locationData?.link_location_address1 ? locationData?.link_location_address1 : null)
            setLocationAdd2(locationData?.link_location_address2 ? locationData?.link_location_address2 : null)
            setCity(locationData?.link_location_city ? locationData?.link_location_city : null)
            setState(locationData?.link_location_state ? locationData?.link_location_state : null)
            setZipcode(locationData?.link_location_zip ? locationData?.link_location_zip : null)
            setCountry(locationData?.link_location_country ? locationData?.link_location_country : null)
            setPhoneNumber(locationData?.link_location_phone ? locationData?.link_location_phone : null)
        }
    }, [props?.locationData])

    // Todo:Reset details on drawer closed
    const closeDrawer = () => {
        props?.close()
        resetLocationDetails();
    };

    // Todo:Validate Name And Email
    const validateFieldForm = () => {
        let formIsValid = true;
        if (!locationName) {
            formIsValid = false;
            setLocationNameFlag("*Name Cannot Be Empty");
        } else {
            setLocationNameFlag("");
        }
        return formIsValid;
    };

    //   Todo:Add/Update New Location
    const createLocation = () => {
        setLoading(true)
        if (validateFieldForm() && accountUuid != null) {
            let objBody = {
                //"parent_location_uuid": optional_value for sub location
                "link_account_uuid": accountUuid,
                "link_location_title": locationTitle,
                "link_location_name": locationName,
                "link_location_address1": locationAdd1,
                "link_location_address2": locationAdd2,
                "link_location_city": city,
                "link_location_state": state,
                "link_location_zip": zipCode,
                "link_location_country": country, // Corrected this to use a country variable
                "link_location_phone": phoneNumber,
            };
            if (subLocationId?.length) {
                objBody.parent_location_uuid = subLocationId;
            } else {
                objBody.parent_location_uuid = null
            }
            addNewLocation(objBody)
                .then(res => {
                    setLoading(false)
                    if (res?.result) {
                        props?.callList('reset')
                        resetLocationDetails();
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {
                        setLoading(false)
                        props?.callList('reset')
                        resetLocationDetails();
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                    resetLocationDetails();
                })
                .catch(err => {
                    setLoading(false)
                    props?.callList('reset')
                    resetLocationDetails();
                    toast(toastFunctionToaster(err.message, "error"));
                });
        }
        else {
            setLoading(false)

        }
    };

    // Todo:Update Location Details
    const updatelocationData = () => {
        setLoading(true);
        if (validateFieldForm() && accountUuid != null) {
            if (locationId?.length) {
                let objBody = {
                    //"parent_location_uuid": optional_value for sub location
                    "link_account_uuid": accountUuid,
                    "link_location_uuid": locationId,
                    "link_location_title": locationTitle,
                    "link_location_name": locationName,
                    "link_location_address1": locationAdd1,
                    "link_location_address2": locationAdd2,
                    "link_location_city": city,
                    "link_location_state": state,
                    "link_location_zip": zipCode,
                    "link_location_country": country,
                    "link_location_phone": phoneNumber,
                    "parent_location_uuid":null
                };
                if (subLocationId?.length) {
                    objBody.parent_location_uuid = subLocationId;
                } else {
                    objBody.parent_location_uuid = null
                }
                updateExistingLocation(objBody)
                    .then(res => {
                        setLoading(false);
                        if (res?.result) {
                            props?.callList('reset')
                            resetLocationDetails();
                            toast(toastFunctionToaster(res.message, "success"));
                        } else {
                            props?.callList('reset')
                            resetLocationDetails();
                            toast(toastFunctionToaster(res.message, "error"));
                        }
                        resetLocationDetails();
                    })
                    .catch(err => {
                        setLoading(false);
                        props?.callList('reset')
                        resetLocationDetails();
                        toast(toastFunctionToaster(err.message, "error"));
                    });
            }
        }
        else {
            setLoading(false)
        }
    };

    // Todo:Reset Location Details
    const resetLocationDetails = () => {
        setLocationId(null);
        setLocationName(null)
        setLocationTitle(null)
        setLocationAdd1(null)
        setLocationAdd2(null)
        setCity(null)
        setState(null)
        setZipcode(null)
        setCountry(null)
        setPhoneNumber(null)
        setLocationNameFlag(null)
        setSubLocationId('')
    };

    return (
        <Drawer
            size="md"
            isOpen={props?.isOpen}
            placement="right"
            onClose={() => {
                closeDrawer();
            }}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={"1px"}>
                    {locationId ? "Update" : "Add New"} {subLocationId ? 'Sub' : null} Location
                </DrawerHeader>
                <DrawerBody overflow={'hidden'} overflowY={'auto'}>
                    <Flex w={'100%'} p={2} rounded={'md'}
                        direction={'column'} borderWidth={"0.05px"} borderColor={'gray.200'}>
                        <Text color={'red.300'} fontSize={'xs'} w={'100%'} align={'end'}>Must Have field..</Text>
                        {/* Todo:Location Name */}
                        <Flex w={"100%"} direction={"column"}>
                            <InputField
                                mb="0"
                                me="30px"
                                id="locationName"
                                label={`${subLocationId ? 'Sub ' : ''}Location Name`}
                                value={locationName}
                                name="locationName"
                                required="true"
                                borderRadius="5px"
                                onChange={e => {
                                    setLocationName(e.target.value);
                                    setLocationTitle(e.target.value)
                                }}
                                placeholder="Enter Location Name"
                                w="100%"
                                maxlength={100}
                            />
                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                <Text fontSize="sm" color={"red"} mt="1" mb="5">
                                    {locationNameFlag}
                                </Text>
                            </Flex>
                        </Flex>
                        {/* Todo:Location Title */}
                        {/* <Flex w={"100%"} direction={"column"}>
                            <InputField
                                mb="0"
                                me="30px"
                                required="true"
                                id="locationTitle"
                                label={`${subLocationId ? 'Sub ' : ''}Location Title`}
                                value={locationTitle}
                                name="locationTitle"
                                borderRadius="5px"
                                onChange={e => {
                                    setLocationTitle(e.target.value);
                                }}
                                placeholder="Enter Location Title"
                                w="100%"
                                maxlength={100}
                            />
                        </Flex> */}
                    </Flex>
                    <Flex w={'100%'} p={2} rounded={'md'} mt={1}
                        direction={'column'} borderWidth={"0.05px"} borderColor={'gray.200'}>
                        <Flex justifyContent={'space-between'}>
                            {subLocationId ? 
                            <Text fontSize={'sm'} w={'100%'} fontWeight={'600'}>
                                Sub Location Address
                            </Text> : null}
                            <Text color={'red.300'} fontSize={'xs'} w={'100%'} align={'end'}>Optional fields..</Text>
                        </Flex>
                        {/* Todo:Location Address 1 */}
                        <Flex w={"100%"} direction={"column"} mt="3">
                            <InputField
                                mb="0"
                                me="30px"
                                id="locationAdd1"
                                label="Address Line 1"
                                value={locationAdd1}
                                name="locationAdd1"
                                borderRadius="5px"
                                onChange={e => {
                                    setLocationAdd1(e.target.value);
                                }}
                                placeholder="Enter Location Address 1"
                                w="100%"
                                maxlength={100}
                            />
                        </Flex>

                        {/* Todo:Location Address 2 */}
                        <Flex w={"100%"} direction={"column"} mt="3">
                            <InputField
                                mb="0"
                                me="30px"
                                id="locationAdd2"
                                label="Address Line 2"
                                value={locationAdd2}
                                name="locationAdd2"
                                borderRadius="5px"
                                onChange={e => {
                                    setLocationAdd2(e.target.value);
                                }}
                                placeholder="Enter Location Address 2"
                                w="100%"
                                maxlength={100}
                            />
                        </Flex>

                        <Flex w={"100%"} justifyContent={'space-between'} mt="3">
                        {/* Todo:City */}
                            <Flex w={"33%"}>
                                <InputField
                                    mb="0"
                                    me="30px"
                                    id="city"
                                    label="City"
                                    value={city}
                                    name="city"
                                    borderRadius="5px"
                                    onChange={e => {
                                        setCity(e.target.value);
                                    }}
                                    placeholder="City Name"
                                    w="100%"
                                    maxlength={100}
                                />
                            </Flex>
                            {/* Todo:State */}
                            <Flex w={"33%"}>
                                <InputField
                                    mb="0"
                                    me="30px"
                                    id="state"
                                    label="State"
                                    value={state}
                                    name="state"
                                    borderRadius="5px"
                                    onChange={e => {
                                        setState(e.target.value);
                                    }}
                                    placeholder="State Name"
                                    w="100%"
                                    maxlength={100}
                                />
                            </Flex>
                            {/* Todo:Zip Code */}
                            <Flex w={"33%"}>
                                <InputField
                                    mb="0"
                                    me="30px"
                                    id="zipCode"
                                    label="Zip Code"
                                    value={zipCode}
                                    name="zipCode"
                                    borderRadius="5px"
                                    onChange={e => {
                                        setZipcode(e.target.value);
                                    }}
                                    placeholder="ZipCode"
                                    w="100%"
                                    type="number"
                                    maxlength={100}
                                />
                            </Flex>
                        </Flex>

                        {/* Todo:Country */}
                        <Flex w={"100%"} direction={"column"} mt="3">
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>{country ? country : `Select Country Name`}</MenuButton>
                            <MenuList onClick={e=>setCountry(e.target.value)} h={"200px"} overflowY="auto">
                                {countryList.map(item=><MenuItem value={item.name}>{item.name}</MenuItem>)}
                            </MenuList>
                            
                        </Menu>
                        </Flex>

                        {/* Todo:Phone Number */}
                        <Flex w={"100%"} direction={"column"} mt="3">
                            <InputField
                                mb="0"
                                me="30px"
                                id="phoneNumber"
                                label="Phone Number"
                                value={phoneNumber}
                                name="phoneNumber"
                                borderRadius="5px"
                                onChange={e => {
                                    setPhoneNumber(e.target.value);
                                }}
                                placeholder="Enter Phone Number"
                                w="100%"
                                maxlength={100}
                            />
                        </Flex>
                    </Flex>
                </DrawerBody>
                <DrawerFooter>
                    <Flex w={"100%"} direction={"row"}>
                        <Button
                            variant="outline"
                            mr={3}
                            size="sm"
                            borderRadius={'10px'}
                            onClick={() => {
                                closeDrawer()
                            }}>
                            Cancel
                        </Button>
                        {locationId ? (
                            <Button
                                size="sm"
                                borderRadius={'10px'}
                                colorScheme="green"
                                isDisabled={loading ? true : false}
                                isLoading={loading ? true : false}
                                onClick={() => {
                                    updatelocationData();
                                }}>
                                Update Location {subLocationId ? 'Sub ' : null} Details
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                borderRadius={'10px'}
                                colorScheme="green"
                                isDisabled={loading ? true : false}
                                isLoading={loading ? true : false}
                                onClick={() => {
                                    createLocation();
                                }}>
                                Create New {subLocationId ? 'Sub ' : null} Location
                            </Button>
                        )}
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer >
    )
}

export default AddUpdateLocation
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  HStack, Center,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import Card from '../../components/card/Card';
import Footer from '../../komponents/uilayout/Footer';

function TermsOfUse() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  

    const [loading, isLoading] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Center>
                <Text fontWeight={'bold'} fontSize={'24px'}>Lingolet Terms of Use</Text>
            </Center>
            <Card w={'96%'} px={20} mt={10}>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                    Important – Read Carefully
                </Text>
                
                <Text>
                PLEASE READ THIS AGREEMENT CAREFULLY. BY INSTALLING AND USING OR ACCESSING THE LINGOLET HARDWARE DEVICE AND/OR SOFTWARE AND BY CLICKING “I ACCEPT” BELOW, YOU INDICATE ACCEPTANCE OF THE TERMS, CONDITIONS, AND LIMITATIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THE TERMS, CONDITIONS, AND LIMITATIONS OF THIS AGREEMENT, LINGOLET WILL IMMEDIATELY DISABLE YOUR ACCESS TO THE SOFTWARE AND DEACTIVATE YOUR ACCOUNT.
                </Text>

                <Text>
                THIS AGREEMENT APPLIES TO THE LINGOLET HARDWARE DEVICE AND THE SOFTWARE THAT LINGOLET HAS LICENSED. YOU MUST USE IT STRICTLY IN ACCORDANCE WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                </Text>

                <Text>
                LINGOLET RESERVES THE RIGHT, IN ITS SOLE DISCRETION, TO MODIFY THE TERMS AND CONDITIONS OF THIS AGREEMENT, ANY TIME, AND SUCH MODIFIED TERMS AND CONDITIONS SHALL HAVE IMMEDIATE EFFECT ONCE THEY ARE MADE AVAILABLE THROUGH SOFTWARE UPDATES OR OTHERWISE THROUGH USER’S USE OF THE SOFTWARE AT ANY TIME AFTER SUCH MODIFICATIONS ARE MADE.
                </Text>

                <Text>
                </Text>


                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                1. DEFINITION
                </Text>

                <Text>
                1.1 Device shall mean Lingolet hardware devices, such as Lingolet One, Lingolet X, and similar.
                </Text>
                <Text>
                1.2 Software shall mean the Lingolet apps, the Lingolet website, and software included with the Device.
                </Text>
                <Text>
                1.3 Services shall mean Voice memos, AI Transcription, AI Translation, AI Interpreter, and Human Interpreter services provided through the Lingolet Device, Software, apps, and website.
                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                2. HUMAN INTERPRETERS
                </Text>

                <Text>
                Lingolet Devices and Software provide fee-based access to on-demand professional Human Interpreting services through Boostlingo Professional Interpreter Network (BLPIN), a third-party platform. Lingolet does not provide interpretation services as an interpretation service provider and does not contract with, employ, hire, vet, qualify, manage, or provide interpreters and is not responsible or liable for the conduct of interpreters who are answering calls or providing interpretation services. For terms and conditions of using BLPIN, see boostlingo.com/legal-lsp/
                For a list of current BLPIN supported languages, see boostlingo.com/boostlingo-languages-supported-doc/
                </Text>



                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                3. DEVICE SALES AND REFUND
                </Text>

                <Text>
                With the purchase of the Device, you agree to the Terms and Conditions included in the Device package. A copy of SALES & REFUND TERMS AND CONDITIONS (“TERMS”) of the Device can be found at lingolet.com/company/salesandrefunds/.
                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                4. USING OUR SERVICES
                </Text>

                <Text>
                You must follow all policies within our Services.
                </Text>

                <Text>
                Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using a method other than the interface and instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we suspect misconduct.
                </Text>

                <Text>
                Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.
                </Text>

                <Text>
                Our Services may display some content that is not the property of Lingolet. That content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do.
                </Text>

                <Text>
                Our Services are available on mobile devices. Do not use such Services in a way that distracts or prevents you from obeying traffic or safety laws.
                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                5. PRIVACY                
                </Text>

                <Text>
                Lingolet’s privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that Lingolet can use such data in accordance with our privacy policies.

                </Text>


                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                6. ABOUT THE SOFTWARE IN OUR SERVICES
                </Text>

                <Text>
                When Lingolet Service requires or includes downloadable software, this software may update automatically on your device when a new version or feature is available. Some Services may let you adjust your automatic update settings.
                </Text>
                <Text>
                Lingolet gives you a personal, worldwide, royalty-free, non-assignable, and non-exclusive license to use the software provided to you by Lingolet as part of the Services. This license’s sole purpose is to enable you to use and enjoy the benefit of the Services as provided by Lingolet, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.

                </Text>
                <Text>
                Some of the software in our Services may be offered under an open-source license that we will make available to you. There may be provisions in the open-source license that expressly override some of these terms.

                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                7. SERVICE FEES
                </Text>

                <Text>
                You must pay the fees specified in the applicable schedule or subscription plan to use certain features of the software. Such fees may include ongoing monthly fees or other periodic fees. Failure to pay such fees on time may result in termination of your accounts and your use of the Software.

                </Text>
                <Text>
                Usage Pricing Changes: If necessary, Lingolet reserves the right to adjust rates with a 30-day notice. The rates outlined on the order form or website are the initial rates being offered as part of this month-to-month agreement.

                </Text>
                <Text>
                Service Shut Down: Lingolet reserves the right to temporarily or permanently shut down your access to the Software if you have unpaid invoices that are past due or you fail to renew your subscription.

                </Text>
                <Text>
                Service Termination and Cancellation: This subscription agreement is month-to-month. Lingolet reserves the right to terminate the subscription for any reason with a 30-day notice.

                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                8. THIRD-PARTY SOFTWARE
                </Text>

                <Text>
                The Software may utilize or include third-party software that may be subject to open source or third-party license terms (“Third-Party Software”). You acknowledge and agree that its right to use such Third-Party Software as part of the Software is subject to and governed by the terms and conditions of the open-source or third-party license(s) applicable to such Third Party Software, including without limitation any applicable acknowledgments, license terms, and disclaimers contained therein. In the event of a conflict between the terms of this Agreement and the terms of such open source or third-party licenses, the terms of the open-source or third-party licenses shall control only with regard to your use of such Third-Party Software. In no event shall the Software or components thereof be deemed to be “open source” or “publicly available” software.

                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                9. WARRANTY DISCLAIMER
                </Text>

                <Text>
                THE SOFTWARE, THE LINGOLET WEBSITE, AND ALL OF THE CONTENTS THEREOF ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESSED, IMPLIED, OR STATUTORY, AND LINGOLET HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS, WITH RESPECT TO THE SOFTWARE, THE LINGOLET WEBSITE AND ALL CONTENTS THEREOF, THE ACCOMPANYING DOCUMENTATION, AND ANY ACCOMPANYING SUPPORTING MATERIALS OR OTHER MATERIALS RECEIVED FROM OR ON BEHALF OF LINGOLET AND/OR ANY AFFILIATE, AGENT, RESELLER, DEALER, DISTRIBUTOR, EMPLOYEE, CONTRACTOR OR REPRESENTATIVE OF LINGOLET.

                </Text>
                <Text>
                YOU EXPRESSLY ACKNOWLEDGE THAT THE WEBSITE AND/OR THE SOFTWARE MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. NO AFFILIATE, CONTRACTOR, EMPLOYEE, AGENT, RESELLER, DEALER, DISTRIBUTOR, OR REPRESENTATIVE OF LINGOLET IS AUTHORIZED TO MODIFY THIS LIMITED WARRANTY OR TO MAKE ANY ADDITIONAL WARRANTIES. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE OR JURISDICTION TO JURISDICTION.

                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                10. LIABILITY DISCLAIMER
                </Text>

                <Text>
                    WHEN PERMITTED BY LAW, LINGOLET, AND LINGOLET’S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES, OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
                    TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF LINGOLET AND ITS SUPPLIERS AND DISTRIBUTORS FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING THE SERVICES TO YOU AGAIN).

                </Text>
                <Text>
                IN ALL CASES, LINGOLET, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.

                </Text>
                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                11. ABOUT THESE TERMS
                </Text>

                <Text>
                We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. We’ll post notice of modified additional terms in the applicable Service. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.
                </Text>

                <Text>
                    If there is a conflict between these terms and the additional terms, the additional terms will apply for that conflict.
                    These terms control the relationship between Lingolet and you. They do not create any third-party beneficiary rights.
                    If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).
                </Text>
                <Text>
                If it turns out that a particular term is not enforceable, this will not affect any other terms.
                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                12. TRADEMARKS
                </Text>

                <Text>
                All trademarks, services marks, designs, and logos used by Lingolet in connection with the Software, Lingolet’s services, and/or Lingolet website are the trademarks or registered trademarks of Lingolet. All other trademarks and/or service marks are the property of their respective owners.

                </Text>

                <Text>
                BY ACCESSING AND USING THE LINGOLET WEBSITE AND BY INSTALLING, ACCESSING, AND USING THE LINGOLET PLATFORM AND SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE UNCONDITIONALLY THAT CHANNEL PARTNER AND CLIENTS SHALL BE BOUND BY ITS TERMS AND CONDITIONS. YOU FURTHER REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO EXECUTE THIS BINDING AGREEMENT ON BEHALF OF THE CHANNEL PARTNER.

                </Text>

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>
                If you have questions, contact us:
                </Text>

                <Text>
                    Lingolet Inc. <br/>
                    Attn: Legal Terms of Service <br/>
                    4633 Old Ironsides Drive, Ste. 230 <br/>
                    Santa Clara, CA 95054 <br/>
                    Email info@lingolet.com <br/>
                </Text>



            </Card>
            <Box w="100%">
                <Footer />
            </Box>
        </Flex>
    )
}

export default TermsOfUse;

import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { useLocation, useNavigate, location } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, FormControl, FormLabel, InputGroup, InputLeftElement, LinkProps, Menu, MenuButton, MenuItem, MenuList, Select, SimpleGrid, useToast } from '@chakra-ui/react'
import {
    extendTheme,
    Container,
    Heading,
    Icon,
    Button,
    Box,
    Flex,
    Image,
    Link,
    Text,
    Input,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerContent,
    useDisclosure,
    DrawerCloseButton,
    useColorModeValue,
	useColorMode,
    Center,
} from "@chakra-ui/react";
// import theme from "./theme";
// import HeroCarousel from "./HeroCarausel";
import {
    FaPlus, FaArrowRightArrowLeft, FaBilibili, FaRocketchat,
    FaArrowsDownToPeople, FaArrowsTurnToDots, FaCircleDot,
    FaAlignJustify, FaCircleCheck, FaCircleExclamation,
} from "react-icons/fa6";

import Card from '../../../components/card/Card';
import LingoletFooter from "../../landinghome/LingoletFooter";

import HybridScreen from "../../../assets/landingPageImg/HybridScreen.png";
import logo from "../../../assets/landingPageImg/kb_logo.png";

import { CountryPhoneCodes } from '../../../utils/CountryPhoneCodes';
import { countryCodes } from '../../../utils/countryCodes';
import { ChevronDownIcon, PhoneIcon } from '@chakra-ui/icons';
import { addPhoneToException, manageAddedPhoneException, verifyPhone } from '../../../services/sharedServices';
import { toastFunctionToaster } from '../../../utils/toastFunction';

function HybridAppPage() {
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCountryCode, setSelectedCountryCode] = useState(null)
    const [number, setNumber] = useState(null)
    const [btnLoading, setBtnLoading] = useState({})
    const [status, setStatus] = useState({})
    const toast = useToast()
    const [showRemoveBtn, setShowRemoveBtn] = useState(false)
    
    const textColor = useColorModeValue("secondaryGray.900", "white");


    async function detectCountryCode() {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            setSelectedCountryCode(data.country_calling_code)
        } catch (error) {
            setSelectedCountryCode()
        }
    }

    function handleVerifyPhone() {
        setShowRemoveBtn(false)
        setBtnLoading(prevState => ({
            ...prevState,
            verify: true,
            enabled: false,
        }))
        setStatus({})
        const paramObj = {
            "lingo_exception_type": "phone",
            "lingo_exception_value": selectedCountryCode + number,
        }
        verifyPhone(paramObj).then(resp => {
            if (resp?.data[0]?.exist) {
                setStatus(prevStatus => ({ ...prevStatus, verify: true, exist: true, enabled: resp?.data[0]?.enable}))
                setShowRemoveBtn(true)
            } else {
                setStatus(prevStatus => ({ ...prevStatus, verify: true,  exist: false, enabled: false }))
                setShowRemoveBtn(false)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to check for given phone number", "error"))
        }).finally(() => {
            setBtnLoading({
                verify: false
            })
        })
    }

    function handleAddPhoneToException() {
        setShowRemoveBtn(false)
        setStatus({})
        setBtnLoading(prevState => ({ ...prevState }))
        const paramObj = {
            "lingo_exception_type": "phone",
            "lingo_exception_value": selectedCountryCode + number,
            "email": "",
            "email_confirmation": false
        }
        setStatus()
        addPhoneToException(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                handleVerifyPhone();
                // setStatus(prevStatus => ({ ...prevStatus, verify: true, enabled: resp?.data[0]?.enable}))
                // // setShowRemoveBtn(true)

                // // if (resp?.data[0]?.duplicate) {
                // //     setStatus({ verify: true })
                // //     setStatus({ enabled: resp?.data[0]?.enabled })
                // // } else {
                // //     setStatus({ add: "added" })
                // //     setStatus({ verify: true })
                // //     setStatus({ enabled: resp?.data[0]?.enabled })
                // // }
                // setShowRemoveBtn(true)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to add phone number to exception list", "error"))
        }).finally(() => {
            setBtnLoading(prevState => ({ ...prevState, add: false }))
        })
    }

    function handleManagePhoneException() {
        setBtnLoading(prevState => ({
            ...prevState,
            manage: true
        }))
        setStatus({})
        const paramObj = {
            "lingo_exception_type": "phone",
            "lingo_exception_value": selectedCountryCode + number,
            "email": "",
            "email_confirmation": false
        }
        manageAddedPhoneException(paramObj).then(resp => {
            if (resp?.data[0].status) {
                handleVerifyPhone();
                // setShowRemoveBtn(false)
                // setStatus({
                //     manage: "removed"
                // })
            } else {
                toast(toastFunctionToaster("Failed to remove phone from exception"))
                setStatus({
                    manage: "failed"
                })
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to remove phone from exception"))
        }).finally(() => {
            setBtnLoading(prevState => ({
                ...prevState,
                manage: false
            }))
        })
    }

    useEffect(() => {
        detectCountryCode()
    }, [])

    const countryCodeOptions =
    CountryPhoneCodes !== null && CountryPhoneCodes?.length
        ? CountryPhoneCodes?.map((data, index) => (
                <option key={index} value={data?.code}>
                    {data?.code} - {data?.country}
                </option>
          ))
        : null;
    return (
        <>
            <Box bg="#fff" color="#000" height="100vh">
                <Box bg="#fff">
                    <Flex
                        w={{
                            base: "50%",
                            md: "90%",
                            xl: "50%",
                            sm: "100%",
                        }}
                        mx="auto"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Flex
                            w={'100%'}
                            //   alignItems={"center"}
                            fontSize={{
                                base: "18px",
                                md: "18px",
                                xl: "18px",
                                sm: "10px",
                            }}
                            direction={'column'}
                            bg="white"
                        >
                            <Text
                                mt="10"
                                fontSize={'2xl'}
                                fontWeight={"bold"}
                                cursor={"pointer"}
                                color="#000"
                            >
                                Phone Setup to reveive SMS
                            </Text>
                            <Text mt={15}>
                                Here you can manage your phone number to receive SMS from so Lingolet,
                                LSPCloud, our partners and affiliates can choose if your
                                phone number  any kind of communication. </Text>
                            <Text fontSize={'sm'}>You can verify and then enable and disable settings to receive SMS from LingoLet, LSPCloud and its partners.</Text>
                            <Flex mt="4" alignItems="center" p={1} bg={'green.100'} rounded={'lg'}>
                                <FormControl w={!selectedCountryCode ? '350px' : '350px'}>
                                    <FormLabel fontSize="sm" for="countryCode">Country Code</FormLabel>
                                    <Select
                                        w={'350px'}
                                        value={selectedCountryCode}
                                        name="countryCode"
                                        id={"countryCode"}
                                        placeholder="Select Country Code"
                                        size="md"
                                        color={textColor}
                                        borderRadius="16px"
                                        onChange={e => {
                                            setStatus(prevStatus => ({ ...prevStatus, verify: false, exist: false, enabled: false}))
                                            setShowRemoveBtn(false)
                                            setSelectedCountryCode(e.target.value);
                                        }}>
                                        {countryCodeOptions}
                                    </Select>
                                    {/* <Menu id='countryCode'>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme='teal'>
                                            {selectedCountryCode ? selectedCountryCode : "Select Country Code"}
                                        </MenuButton>
                                        <MenuList onClick={e => setSelectedCountryCode(e.target.value)} h="400px" overflowY="auto">
                                            {countryCodes.map(option => (
                                                <MenuItem value={option.code}>{option.code}</MenuItem>
                                            ))}

                                        </MenuList>
                                    </Menu> */}
                                </FormControl>
                                <FormControl w="200px" ml={1}>
                                    <FormLabel fontSize="sm" for="number">Phone Number</FormLabel>
                                    <Input type='number' inputMode='numeric' w={"200px"} 
                                        onChange={e => {
                                            setStatus(prevStatus => ({ ...prevStatus, verify: false, exist: false, enabled: false}))
                                            setShowRemoveBtn(false)
                                            setNumber(e.target.value)}} 
                                            value={number} />
                                </FormControl>
                                <Button variant="solid" w="fit-content" colorScheme='facebook' mt={7} ml={2} isDisabled={!number || !selectedCountryCode} onClick={handleAddPhoneToException} isLoading={btnLoading?.add}>Add Phone</Button>
                                <Button mt={7} colorScheme='green' ml={2} isDisabled={!number || !selectedCountryCode} onClick={handleVerifyPhone} isLoading={btnLoading?.verify}>Verify</Button>
                            </Flex>
                            <Flex mt={10} w="full">
                                {status?.verify ? (
                                        status?.exist === true
                                        ? ( 
                                            status?.enabled === true 
                                            ?<Flex w={'100%'} direction={'column'}>
                                                <Text>Your Number (<b>{selectedCountryCode + number}</b>) exists in our database.</Text>
                                                <Flex w={'100%'}>
                                                    <Icon color={'green'} as={FaCircleCheck}></Icon>
                                                    <Text ml={3}>Your phone number is set to receive SMS messages from Lingolet.</Text> 
                                                    </Flex>
                                                </Flex>
                                            :<Flex w={'100%'} direction={'column'}>
                                                <Text>Your Number (<b>{selectedCountryCode + number}</b>) exists in our database.</Text>
                                                <Flex w={'100%'}>
                                                    <Icon color={'red'} as={FaCircleExclamation}></Icon>
                                                    <Text ml={3}>Your phone number is set to receive <b>NO</b> SMS messages from Lingolet.</Text>
                                                </Flex>
                                            </Flex>
                                         )
                                        :<Text>Your phone number (<b>{selectedCountryCode + number}</b>) does not exist in our database.</Text>
                                ) : (
                                    null
                                    // status?.exist === false
                                    // ?<Text>Your Number (<b>{selectedCountryCode + number}</b>) does not exist in our database. 2</Text>
                                    // :null
                                )}

                                {/* {status?.verify 
                                ?<Text fontSize="md"> 
                                    (status?.exist === true 
                                     (status?.enabled === true 
                                        ?<Flex w={'100%'}>
                                            <Text>Your Number (<b>{selectedCountryCode + number}</b>) exists in our database.</Text>
                                            <Icon color={'green'} as={FaCircleCheck}></Icon>
                                            <Text ml={3}>Your phone number is set to receive SMS messages from Lingolet.</Text> 
                                        </Flex>
                                        :<Flex w={'100%'}>
                                            <Text>Your Number (<b>{selectedCountryCode + number}</b>) exists in our database.</Text>
                                            <Icon color={'red'} as={FaCircleExclamation}></Icon>
                                            <Text ml={3}>Your phone number is set to receive <b>NO</b> SMS messages from Lingolet.</Text>
                                        </Flex>)
                                    :<Text>Your Number (<b>{selectedCountryCode + number}</b>) does not exist in our database.</Text>)
                                    :null)
                                </Text>
                                :null} */}
                                {/* //<Text fontSize="md">Your Number (<b>{selectedCountryCode + number}</b>) in not in our database </Text>} */}
                                {/* {status?.add ? <Text fontSize="md">Your Number (<b>{selectedCountryCode + number}</b>) added into exception list</Text> : null} */}
                                {/* {status?.manage ? <Text fontSize="md">{status?.manage === "removed" ? 'Successfully removed' : 'Failed to remove'} your number (<b>{selectedCountryCode + number}</b>) from exception list</Text> : null} */}
                            </Flex>
                            {showRemoveBtn &&
                                <>
                                    <Text mt={7} fontSize="sm">To set your phone number (<b>{selectedCountryCode + number}</b>) NOT to receive SMS from Lingolet click below button</Text>
                                    <Button w="300px" colorScheme={status?.enabled === true ? 'red': 'green'} 
                                        onClick={handleManagePhoneException} isLoading={btnLoading?.manage}>
                                            {status?.enabled === true ? 'Disable Phone to Receive SMS' : 'Enable Phone to Receive SMS'}
                                    </Button>
                                </>
                            }
                        </Flex>
                        <Flex>

                        </Flex>
                    </Flex>

                </Box>
            </Box>
        </>
    )
}


export default HybridAppPage;

import React, { useState } from 'react'
// Chakra Import
import {
    Box,
    Flex,
    Text,
    Button,
    Drawer,
    FormLabel,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    useToast,
} from "@chakra-ui/react";

// Component Imports
import InputField from "../../../../components/fields/InputField";
import { toastFunctionToaster } from '../../../../utils/toastFunction';
import { useBlueButton, useGreenBg } from '../../../../theme/globalColorTheme';

// Api Services
import { mailLeaderCode } from '../../../../services/businessServices';

function LinkEmailLeaderCode(props) {
    const toast = useToast()
    const btnRef = React.useRef()
    const linkInfo = props?.linkItem
    const greenButtonBg = useGreenBg();
    const blueButtonBg = useBlueButton();
    const message = "Leader Access Code : ";
    const [subject, setSubject] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();
    const userEmail = props?.userEmail ? props?.userEmail : null;
    const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
    const [toEmails, setToEmails] = useState(userEmail ? userEmail : '');
    const [leaderCode, setLeaderCode] = useState(props?.linkItem?.leader_code ? message + props?.linkItem?.leader_code : message);
    const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";


    // Todo:Send link to the customer
    const shareLeaderCodeByMail = () => {
        setSaveDetailsLoader(true)
        if (toEmails?.length > 0 && linkInfo?.uuid != null) {
                let objBody = {
                    link_uuid: linkInfo?.uuid,
                    host_name: currentHost,
                    target_emails: toEmails,
                    subject: subject,
                    body: leaderCode,
                };
                mailLeaderCode(objBody)
                    .then((res) => {
                        setSaveDetailsLoader(false)
                        if (res?.result) {
                            toast(toastFunctionToaster(res.message, "success"));
                            resetFields();
                        } else {
                            toast(toastFunctionToaster(res.message, "error"));
                        }
                    })
                    .catch((err) => {
                        setSaveDetailsLoader(false)
                        toast(toastFunctionToaster(err.message, "error"));
                    });
            } 
        else {
            setSaveDetailsLoader(false)
            toast(
                toastFunctionToaster(
                    "You must provide email addresse and select which url to share..",
                    "error"
                )
            );
        }
    };

    // Todo:Reset data after code is mailed
    const resetFields = () => {
        onClose();
        setSubject();
        setToEmails();
    }

    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} ml={1}  size={"xs"} bg={greenButtonBg} onClick={onOpen}>Email Leader Code</Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent overflow={'auto'}>
                    <DrawerBody p="0px">
                        {Object.keys(linkInfo)?.length
                            ? <Flex w={"100%"} mt={2} h="98%">
                                <Flex w="100%"
                                    direction={'column'}>
                                    <Flex w={'100%'} direction={'column'} borderBottomWidth={'1px'} borderColor="grey.200" p="3">
                                        <Text fontSize={'lg'} fontWeight={'600'}>{"Send Multichannel Link Leader Code"}</Text>
                                        <DrawerCloseButton />
                                    </Flex>
                                    <Box p="3" h="95%" overflow={'hidden'} overflowY={'auto'}>
                                        <InputField
                                            mb="0"
                                            me="30px"
                                            id="subject"
                                            label="Enter Subject"
                                            value={subject}
                                            name="subject"
                                            borderRadius="5px"
                                            onChange={e => {
                                                setSubject(e.target.value);
                                            }}
                                            placeholder="Enter Subject"
                                            w="100%"
                                            maxlength={100}
                                        />
                                        <FormLabel mt="3" ml="2" required>
                                            <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                To Email Address(es):
                                            </Text>
                                            <Textarea
                                                mb="0"
                                                me="30px"
                                                rows="10"
                                                id="toEmails"
                                                value={toEmails}
                                                name="toEmails"
                                                onChange={(e) => {
                                                    setToEmails(e.target.value);
                                                }}
                                                placeholder="Enter emails (comma separated)"
                                                w="100%"
                                                maxlength={200}
                                            />
                                            <Text
                                                textAlign={"right"}
                                                fontWeight={"500"}
                                                fontSize={"sm"}
                                                mt="2"
                                            >
                                                {toEmails?.length}/200
                                            </Text>
                                            <Text>
                                                Note: An email will be sent to all above emails address with the
                                                link
                                            </Text>
                                        </FormLabel>
                                        <FormLabel mt="3" ml="2" required>
                                        <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                Message:
                                            </Text>
                                            <Textarea
                                                mb="0"
                                                me="30px"
                                                rows="10"
                                                fontSize={'20px'}
                                                fontWeight={'bold'}
                                                id="message"
                                                value={leaderCode}
                                                name="leaderCode"
                                                onChange={(e) => {
                                                    setLeaderCode(e.target.value);
                                                }}
                                                placeholder="Enter Message"
                                                w="100%"
                                                maxlength={500}
                                            /> 
                                        </FormLabel>
                                    </Box>
                                    <Flex w={'100%'} mt="4" alignItems={'center'} p="3" borderTopWidth={'1px'} borderColor="grey.200">
                                        <Button
                                            bg={blueButtonBg}
                                            borderRadius={'10px'}
                                            size="sm"
                                            mr="2"
                                            onClick={() => {
                                                shareLeaderCodeByMail();
                                            }}
                                            isDisabled={
                                                toEmails === null || toEmails?.length === 0 ? true : false
                                            }
                                            isLoading={saveDetailsLoader ? true : false}
                                        >
                                            Send Email
                                        </Button>

                                        <Button variant='outline' borderRadius={'10px'} colorScheme='red' size="sm" onClick={onClose}>
                                            Close
                                        </Button>
                                    </Flex>

                                </Flex>
                            </Flex>
                            : <Flex w={'100%'} p={2} bg={'red.100'}>
                                <Text>Error collecting Selected Link Details</Text>
                            </Flex>}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LinkEmailLeaderCode
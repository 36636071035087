import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Spacer,
    Tab,
    Flex,
    Tabs,
    Icon,
    Text,
    Alert,
    Button,
    Spinner,
    TabList,
    AlertIcon,
    Avatar,
    useToast,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import Card from "../../../components/card/Card";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import { adminWorkflowJobs } from "../../../services/adminServices";
import { translationLanguages } from '../../../utils/TranslationLanguages';
import { transcriptionLanguages } from '../../../utils/TranscriptionLanguage';

function AdminJobs() {
    const toast = useToast();
    const navigate = useNavigate();
    const { colorMode } = useColorMode();
    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

    const [loading, isLoading] = useState(true);
    const [jobsList, setJobsList] = useState([]);
    const [jobsType, setJobsType] = useState('Active');

    useEffect(() => {
        fetchContentWorkflows();
    }, [jobsType]);

    const fetchContentWorkflows = (jobTypeLocal) => {
        isLoading(true);
        let objData = {
            'job_type': jobTypeLocal != null ? jobTypeLocal : jobsType,
            'max': 10
        };
        adminWorkflowJobs(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setJobsList(res?.data[0]?.workflows);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
                isLoading(false);
          });
    };

    const getTranslationLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = translationLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const getTranscriptionLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = transcriptionLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const renderLanguageListDetails = (langData) => {
        if (langData.length > 0){
            return(
                langData?.map((item, index) => {
                    return(
                        <Text m={1} p={1} fontSize={'xs'} bg={'blue.100'} rounded={'md'}>{item}</Text>
                    )
                })
            )
        }
    }

    const getJobExType = (jobName) => {
        if (jobName != null){
            let doc = ['docx', 'doc', 'pdf', 'txt'].map((item, index) => { if (jobName.indexOf(item) >= 0) return true; else return false;})
            if (jobName.indexOf('mp3') >= 0){
                return '(Audio File)';
            } else if (jobName.indexOf('docx') >= 0 || jobName.indexOf('pdf') >= 0 
                || jobName.indexOf('txt') >= 0 || jobName.indexOf('doc') >= 0){
                return '(Document)';
            } else if ('realtime'.indexOf(jobName) >= 0){
                return '(Realtime Text)';
            } 
        }    
    }

    const renderJobsList = jobsList !== null && jobsList?.length
        ? jobsList?.map((item, index) => (
            <Flex key={index} w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                <Box minW={'150px'} fontSize={'sm'} align="center">
                    <Text>
                    {moment(item?.created).format("MMM DD,YYYY,HH:mm a")}
                    </Text>
                    <Button size='xs' bg={'green.100'} mt={1}>
                        Workflow Info
                    </Button>
                </Box>
                <Box w={'100%'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                    <Flex w={'100%'} align={'center'}  borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                        <Text><b>{item?.code}</b></Text>
                        <Text fontSize={'xs'} ml={2}>{getJobExType(item?.job_name)}</Text>
                    </Flex>
                    {item?.code === 'Translation'
                    ?<Flex w={'100%'} align={'center'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                        <Text mr={1}>Source Language</Text>
                        {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.source))}
                        <Text ml={5}  mr={1}>Target Language(s)</Text>
                        {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.languages))}
                    </Flex>:null}
                    {item?.code === 'Transcription'
                    ?<Flex w={'100%'}  align={'center'}  borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                        <Text mr={1}>Source Language</Text>
                        {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.source))}
                        <Text ml={5}  mr={1}>Target Language(s)</Text>
                        {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.languages))}
                    </Flex>:null}
                    <Flex w={'100%'} mt={1} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                        <Text mt={2} color={'blue.400'} fontSize={'xs'}>Submitted By: {item?.email}</Text>
                    </Flex>
                </Box>
                <Box minW={'100px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                    {item?.completed ? (
                        <Text color={'blue.200'}>Job Completed</Text>
                    ) : item?.started && item?.completed === false ? (
                        <Text color={'green.300'}>Job Started</Text>
                    ) : ( 
                        <Text color={'red.300'}>Not Started</Text>
                    )}
                </Box>
                <Box minW={'250px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                    {item?.priority 
                    ?<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Top Priority
                    </Flex>:null}
                    {item?.proofreading 
                    ?<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Proofreading Added
                    </Flex>:null}
                    {item?.email_response 
                    ?<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Result in Email
                    </Flex>:null}
                </Box>
                <Box minW={'150px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                    {item?.billed 
                    ?<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Billed
                    </Flex>
                    :<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Not Billed
                    </Flex>}
                    {item?.billed === false ? ( null ) :
                        item?.paid
                        ?(<Flex w={'200px'}>
                            <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Paid
                        </Flex>) : (
                        <Flex w={'200px'}>
                            <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Paid
                        </Flex> )
                    }
                </Box>
                <Box minW={'150px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                    {item?.completed ?
                    ( <Button 
                        onClick={() => {
                            navigate("/admin/workflowjobs/perform", {
                            state: {
                                workflowUuid: item?.wuuid,
                                projectUuid: item?.puuid,
                                contentUuid: item?.cuuid
                            },
                            })
                        }}
                        size={'sm'} bg={'green.100'}>
                        View Results
                    </Button> ) : (
                        <>
                        {item?.started
                        ?<Button 
                            onClick={() => {
                                navigate("/admin/workflowjobs/perform", {
                                state: {
                                    workflowUuid: item?.wuuid,
                                    projectUuid: item?.puuid,
                                    contentUuid: item?.cuuid
                                },
                                })
                            }}
                            size={'sm'} color={'gray.600'} bg={'blue.200'}>
                            Check Job Status
                        </Button>
                        :<Button 
                            onClick={() => {
                                navigate("/admin/workflowjobs/perform", {
                                state: {
                                    workflowUuid: item?.wuuid,
                                    projectUuid: item?.puuid,
                                    contentUuid: item?.cuuid
                                },
                                })
                            }}
                            size={'sm'} color={'gray.600'} bg={'blue.200'}>
                            Start Job
                        </Button>}
                        </>
                    )}
                </Box>
            </Flex>
            ))
      : null;

    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card direction="column" mt="5" borderWidth="0.5px">
                <Flex w={'100%'}>
                    <Text>{jobsType}{' '}Workflow Jobs</Text>
                    <Spacer />
                    <Button 
                        bg={jobsType === 'Active' ? 'blue.200' : 'gray.200'}
                        onClick={() => {
                            setJobsType('Active');
                            fetchContentWorkflows('Active');
                        }}
                        size={'sm'}>Active & Ready Jobs</Button>
                    <Button 
                        bg={jobsType === 'Completed' ? 'blue.200' : 'gray.200'}
                        onClick={() => {
                            setJobsType('Completed');
                            fetchContentWorkflows('Completed');
                        }}
                        size={'sm'} ml={1}>Completed Jobs</Button>
                </Flex>
            </Card>
            {loading ? (
                <Flex alignItems="center" justifyContent="center" h="60vh">
                <Spinner size="lg" />
                </Flex>
            ) : (
                <>
                {jobsList !== null && jobsList?.length ? (
                    <Card w={'100%'}>
                        {renderJobsList}
                    </Card>
                ) : (
                    <Card w={'100%'}>
                        <Text p="2" fontWeight={"500"}>
                        There are no jobs with selected category!!
                        </Text>
                    </Card>
                )}
                </>
            )}
        </Box>
        </>
    );
}

export default AdminJobs;

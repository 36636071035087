import React, { useEffect, useRef, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Box,
    Button,
    Flex,
    Spinner,
    Tab,
    Spacer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Input,
    Checkbox,
} from "@chakra-ui/react";
import {
    AiFillLeftCircle,
    AiFillRightCircle,
    AiFillCaretRight,
    AiFillCaretLeft,
    AiOutlineFilePdf,
    AiOutlineFileExcel,
    AiOutlineFileText,
} from "react-icons/ai";

// import { Bar } from "react-chartjs-2";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Label, ResponsiveContainer } from 'recharts';

import moment from 'moment'
import Card from "../../../../components/card/Card";
import { useBrandBg } from "../../../../theme/globalColorTheme";

// API Service
import { getAccountBillingList } from "../../../../services/accountServices";
import TableComponent from "./TableComponent";
import BillingPdf from "./BillingPdf";
import BillingCSV from "./BillingCSV";
import { useCallback } from "react";

const GraphComponent = React.memo(({ data }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={'100%'}
                height={'100%'}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 150,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tick={{ angle: 90, textAnchor: 'start', 'dominantBaseline': 'ideographic' }}
                    dataKey="date" type="category">
                    <Label
                        position='insideBottom'
                        offset={-160}
                        value="Date of Call"
                        style={{ fontSize: '80%', fill: 'black', padding: '100px' }}
                    />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#ffc658" />
            </BarChart>
        </ResponsiveContainer>
    );
});

const AccountBilling = props => {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true)
    const [months, setMonths] = useState(null)
    const [hoveredDate, setHoveredDate] = useState(null);
    const calanderContainer = useRef()
    const activeCalanderRef = useRef()
    const [excludeValue, setExcludeValue] = useState(null)
    const [filterGraphData, setFilterGraphdata] = useState(false)
    
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [year, setYear] = useState(new Date().getFullYear())
    useEffect(() => {
        const monthsArr = Array.from({ length: 12 }, (v, i) => new Date(year, i));
        setMonths(monthsArr)
        const today = new Date()
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setSelectedRange([firstDayOfCurrentMonth, lastDayOfCurrentMonth])

        setTimeout(() => {
            const monthsSelectButton = document.querySelectorAll(".react-calendar__navigation__label");
            monthsSelectButton.forEach((element, index) => {
                element.addEventListener("click", () => {
                    const { firstDate, lastDate } = getFirstAndLastDatesForYearAndMonth(year, index)
                    setSelectedRange([firstDate, lastDate])

                });
            });
        }, 1)

    

        return () => {
            const monthsSelectButton = document.querySelectorAll(".react-calendar__navigation__label");
            monthsSelectButton.forEach((element) => {
                element.removeEventListener("click", () => { });
            });
        };

    }, [year])

    useEffect(() => {
        if (calanderContainer.current && activeCalanderRef.current && selectedRange[1]) {
            const positionOfActiveCalanderRespToParent = activeCalanderRef.current.offsetTop

            calanderContainer.current.scroll({
                top: positionOfActiveCalanderRespToParent - 15,
                behavior: "smooth"
            })
        }
    }, [selectedRange])

    const handleDateChange = (date) => {
        if (!selectedRange[0]) {
            setSelectedRange([date, null]);
        } else if (selectedRange[0] && !selectedRange[1]) {
            setSelectedRange([selectedRange[0], date]);
        } else {
            setSelectedRange([date, null]);
        }
        setHoveredDate(null);
    };

    const handleCloseRightTab = () => {
        setConfiguationCollapseFlag(!configurationCollapseFlag);
    };

    const disableOtherMonthDates = ({ date, activeStartDate }) => {
        return date.getMonth() !== activeStartDate.getMonth();
    };

    const changeYear = (increment) => {
        setYear((prevYear) => prevYear + increment);
    };

    function handleMouseHover(e) {
        const target = e.target;
        if (target.tagName.toLowerCase() === 'button') {
            const hasReactCalendarTileClass = target.classList.contains('react-calendar__tile');
            const hasReactCalendarMonthViewClass = target.classList.contains('react-calendar__month-view__days__day');

            if (hasReactCalendarTileClass && hasReactCalendarMonthViewClass) {
                let date = e.target?.firstChild?.ariaLabel
                setHoveredDate(new Date(date))
            }
        }
    }

    function getFirstAndLastDatesForYearAndMonth(year, month) {
        const firstDate = new Date(year, month, 1);
        const lastDate = new Date(year, month + 1, 0);

        return {
            firstDate,
            lastDate,
        };
    }

    const isDateInHoveredRange = (date) => {
        if (!selectedRange[0] || selectedRange[1] || !hoveredDate) return false;
        return date >= selectedRange[0] && date <= hoveredDate;
    };

    const isDateInRange = (date) => {
        if (!selectedRange[0] || !selectedRange[1]) return false;
        return date >= selectedRange[0] && date <= selectedRange[1];
    };

    function handleMouseLeave() {
        setHoveredDate(null)
    }

    function handleStoreActiveCalander(node, month) {
        if (node && selectedRange[0]) {
            const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
            const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
            if (selectedRange[0] >= monthStart && selectedRange[0] <= monthEnd) {
                activeCalanderRef.current = node;
            }
        }
    };

    const [billingLoading, isBillingLoading] = useState(false)
    const [billingData, setBillingData] = useState([])
    const [billingDataFilter, setBillingDataFilter] = useState([])
    const [graphData, setGraphData] = useState([])
    const [graphFilteredData, setGraphFilteredData] = useState([])
    useEffect(() => {
        if (selectedRange[1] > selectedRange[0]) {
            collectFullBillingData()
        }
    }, [selectedRange])

    useEffect(() => {
        if (filterGraphData && excludeValue !== null) {
            const data = graphData?.filter(item => parseFloat(item.value) <= parseFloat(excludeValue))
            setGraphFilteredData(data)
        } else {
            setGraphFilteredData(graphData)
        }
    }, [filterGraphData, excludeValue])

    const collectFullBillingData = () => {
        isBillingLoading(true);
        let paramObj = {
            start_date: selectedRange[0],
            end_date: selectedRange[1],
            records_per_page: 0,
            page_id: 0,
        };
        getAccountBillingList(paramObj)
            .then(res => {
                isBillingLoading(false);
                if (res?.data[0]?.status) {
                    setBillingData(res?.data[0]?.billingData);
                    setBillingDataFilter(res?.data[0]?.billingData);
                    setGraphData(res?.data[0]?.graphData)
                    setGraphFilteredData(res?.data[0]?.graphData)
                } else {
                    setBillingData([]);
                    setBillingDataFilter([]);
                    setGraphFilteredData([])
                }
            })
            .catch(err => {
                isBillingLoading(false);
                setBillingData([]);
                setBillingDataFilter([]);
                setGraphFilteredData([])
            });
    };

    const columns = [
        {
            Header: 'Start Time',
            accessor: (row) =>row?.start_time ? new Date(row.start_time) : null,
            Cell: ({ value }) =>value ?  moment(value).format("MMM DD, YYYY, HH:mm a") : null,
            sortType: 'datetime',
            id: 'start_time',
            allowColorGrouping:false
        },
        {
            Header: 'End Time',
            accessor: (row) => row.end_time ? new Date(row.end_time) : null,
            Cell: ({ value }) => value ? moment(value).format("MMM DD, YYYY, HH:mm a") : null,
            // sortType: 'datetime',
            id: 'end_time',
            allowColorGrouping:false
        },
        {
            Header: 'Duration',
            accessor: 'duration',
            id: 'duration',
            allowColorGrouping:true
        },
        {
            Header: 'Provider',
            accessor: 'provider',
            id: 'provider',
            allowColorGrouping:true
        },
        {
            Header: 'Target Language',
            accessor: 'target_language',
            id: 'target_language',
            allowColorGrouping:true
        },
        {
            Header: 'Call Type',
            accessor: 'call_type',
            id: 'call_type',
            allowColorGrouping:true
        },
        {
            Header: 'Email',
            accessor: (row) => row?.subscriber,
            id: 'subscriber',
            allowColorGrouping:true
        },

    ];

    const defaultSort = [
        { id: 'start_time', desc: true },
    ];

    return (
        <Flex direction="column" >
            <Card w={"100%"} rounded={"md"} p="1px">
                <Flex h='calc(100vh - 160px)' w="100%">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : { xl: "69.1%", "2xl": "78.5%" }}
                        id="calander-container"
                        bg={brandBg}
                        p="1"
                        borderRadius={"10px"}
                        overflow="hidden">
                        <Card w={"100%"} p={2} rounded={"md"} h="full">
                            <Flex w="full" justifyContent="space-between" alignItems="center" h="full">

                                <Tabs variant='enclosed' w="full" h="full">
                                    <Flex w={'100%'}>
                                        <TabList w={'100%'}>
                                            <Flex w={'100%'}>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Billing Details</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Consumption Graph</Tab>
                                            </Flex>
                                        </TabList>
                                        <Spacer />
                                        <Card w={'600px'} p={1} ml="auto" align={'end'}>
                                            <Flex gap={1} alignItems="center" >
                                                <Text fontWeight="bold" fontSize="small">
                                                    Export Report
                                                </Text>
                                                <BillingPdf data={billingData} selectedRange={selectedRange} />
                                                <BillingCSV data={billingData} selectedRange={selectedRange} />
                                                <Button rounded={'lg'} size="sm" leftIcon={<AiOutlineFileExcel />} colorScheme="green">EXCEL</Button>
                                                <Button
                                                    rounded={'lg'}
                                                    w={'100px'}
                                                    colorScheme="green"
                                                    onClick={() => collectFullBillingData()}
                                                    size={'sm'}>
                                                    Reload Data
                                                </Button>
                                            </Flex>
                                        </Card>
                                    </Flex>
                                    <TabPanels h="90%">
                                        <TabPanel bg={brandBg} h="full" rounded="lg" p={2}>
                                            <Flex direction="column" gap="2" h="full" overflow="hidden" position="relative">
                                                <Flex w="full" p="1">
                                                    <Flex w={'100%'} alignItems="center">
                                                        <Text fontWeight="bold">
                                                            Call Records From
                                                        </Text>
                                                        <Text ml={1} fontWeight="bold" color={'blue.500'}>
                                                            {moment(selectedRange[0]).format("MMM Do YY")} to {selectedRange[1] ? moment(selectedRange[1]).format("MMM Do YY") : "select end date"}
                                                        </Text>
                                                        <Text fontWeight="bold" ml={2}>
                                                            (Total Records:  {billingDataFilter?.length ? billingDataFilter?.length : 0})
                                                        </Text>
                                                        <Spacer />
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="column" gap={2} h="94%" p="1">
                                                    {billingLoading
                                                        ? (<Flex alignItems="center" justifyContent="center" h="full" w="full">
                                                            <Spinner size="xl" />
                                                        </Flex>)
                                                        :
                                                        <Flex flex="1" overflow="hidden">
                                                            <TableComponent data={billingDataFilter} columns={columns} defaultSort={defaultSort} />
                                                        </Flex>
                                                    }
                                                </Flex>
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel bg={brandBg} h="full" rounded="lg" overflow="auto" className="custom-scrollbar" p={2}>
                                            <Flex w="full" gap="2" alignItems="center" pb={2}>
                                                <Text>Analytics</Text>
                                                <Flex flex="1" gap="2" ml="2">
                                                    <Checkbox value={filterGraphData} onChange={() => setFilterGraphdata(filterGraphData => !filterGraphData)}>Exclude Values above:</Checkbox>
                                                    <Input w="20%" placeholder="enter value" value={excludeValue} onChange={(e) => { setExcludeValue(e.target.value) }} type="number" ></Input>
                                                </Flex>
                                            </Flex>
                                            {billingLoading
                                                ? (<Flex alignItems="center" justifyContent="center" h="full" w="full">
                                                    <Spinner size="xl" />
                                                </Flex>)
                                                : <Box w={'100%'} h="400px" rounded={"lg"} borderWidth={"0.5px"} borderColor={"gray.300"} mt="auto">
                                                    {/* {graphData?.length > 0 
                                                ? <Bar data={graphData[0]} /> 
                                                : null} */}
                                                    {/* {graphData?.length > 0
                                                ?<LineChart width={'900px'} height={300} data={graphData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="date" type="category" tickFormatter={(date) => new Date(date).toLocaleDateString()} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey="value" stroke="#8884d8" />
                                                </LineChart>:null} */}
                                                    {graphFilteredData?.length > 0
                                                        ?
                                                        <GraphComponent data={graphFilteredData} />
                                                        : <Text>Unable to create the graph..</Text>}
                                                </Box>}
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Flex>
                        </Card>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Flex overflow={"hidden"} flex={1}>
                            <Box minW="97%" position="relative" p="1" rounded={'lg'} bg={brandBg} ml="2" pb={7}>
                                <Flex direction="column" gap="2">
                                    <Flex alignItems="center" w="80%" justifyContent="space-between">
                                        <Text fontWeight={"500"}>Select Date</Text>
                                        <Flex gap={2} alignItems="center">
                                            <AiFillCaretLeft onClick={() => changeYear(-1)} />
                                            <Button colorScheme="blue">{year}</Button>
                                            <AiFillCaretRight onClick={() => changeYear(+1)} />
                                        </Flex>
                                        <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                            <AiFillRightCircle
                                                cursor="pointer"
                                                size="20px"
                                                onClick={() => {
                                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                                }}
                                            />
                                        </Box>
                                    </Flex>
                                </Flex>
                                <Card
                                    w="100%"
                                    h="95%"
                                    rounded={'lg'}
                                    mt="1"
                                    p={1}
                                >
                                    <Flex flexDirection="column" alignItems="center" ref={calanderContainer} h="100%" overflowY="auto"
                                        pb="-1"
                                        overflowX="hidden"
                                        gap={2}
                                        className="custom-scrollbar">
                                        {months && months.map((month, index) => (
                                            <Box mt={index === 0 ? "-10" : "-20"} key={index} transform={{ lg: "scale(0.6)", "2xl": "scale(0.6)" }} className="calendar-container" onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} ref={(node) => new Date(selectedRange[0]).getMonth() === new Date(month).getMonth() ? handleStoreActiveCalander(node, month) : null}
                                            >
                                                <Calendar
                                                    value={selectedRange}
                                                    onChange={handleDateChange}
                                                    view="month"
                                                    activeStartDate={month}
                                                    selectRange={false}
                                                    tileDisabled={disableOtherMonthDates}
                                                    tileClassName={({ date }) => {
                                                        const isDisabled = disableOtherMonthDates({ date, activeStartDate: month });
                                                        if (isDisabled) {
                                                            return "react-calander_disabled-dates"
                                                        }
                                                        if (isDateInRange(date) && !isDisabled) {
                                                            return "react-calander_selected-range";
                                                        }
                                                        if (isDateInHoveredRange(date) && !isDisabled) {
                                                            return "react-calander_hovered-range";
                                                        }
                                                        return null;
                                                    }}
                                                />

                                            </Box>
                                        ))}
                                    </Flex>
                                </Card>
                            </Box>
                        </Flex>
                    ) : (
                        <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        handleCloseRightTab();
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}>
                                    Select Date
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>

    );
};

export default AccountBilling;

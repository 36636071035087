import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import {
	Box,
	Link,
	Button,
	FormControl,
	FormLabel,
	PinInput,
	PinInputField,
	Input,
	VStack,
	Heading,
	Flex,
	Image,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useToast,
	useDisclosure,
	useColorModeValue,
} from "@chakra-ui/react"
// Api Call Imports

import { launchPageEx } from "../../services/dynamicPageSvc"
// Mixpanel - Page Load Event
// https://mixpanel.com/report/3254045/view/3762305/setup/
import mixpanel from "mixpanel-browser"

// Component Imports
import { toastFunctionToaster } from "../../utils/toastFunction"

const DynamicLauncher = () => {
	const toast = useToast()
	const navigate = useNavigate()
	const btnRef = React.useRef()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [loading, isLoading] = useState(false)

	// // Mixpanel integration
	// const mixpanelJsProductKey = process.env.REACT_APP_MIXPANEL_PRODUCT_KEY
	// mixpanel.init(mixpanelJsProductKey, {
	// 	debug: true,
	// 	track_pageview: "https://lingolet.ai/lingolink?key",
	// 	persistence: "localStorage",
	// })
	// // Set this to a unique identifier for the user performing the event.
	// mixpanel.identify("LingoletLink_Launcher")
	// // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
	// mixpanel.track("LingolinkLauncher", { LinkType: "Launcher" })

	const textColor = useColorModeValue("secondaryGray.900", "white")
	const textColorSecondary = "gray.400"
	const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100")
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
	const textColorBrand = useColorModeValue("brand.500", "white")

	const { search } = window.location
	const launchUrl = new URLSearchParams(search).get("key")
	const launchCodeAuto = new URLSearchParams(search).get("code")
	const [launchModal, setLaunchModal] = useState(true)

	const [showNextPage, setShowNextPage] = useState(false)

	const [pageData, setPageData] = useState(null)
	// Todo:Key Value
	const [keyValueOne, setKeyValueOne] = useState("")
	const [keyValueTwo, setKeyValueTwo] = useState("")
	const [keyValueThree, setKeyValueThree] = useState("")
	const [keyValueFour, setKeyValueFour] = useState("")
	const [keyValueFive, setKeyValueFive] = useState("")
	const [keyValueSix, setKeyValueSix] = useState("")
	const [keyValueSeven, setKeyValueSeven] = useState("")

	// Todo:Code Value
	const [valueOne, setValueOne] = useState("")
	const [valueTwo, setValueTwo] = useState("")
	const [valueThree, setValueThree] = useState("")
	const [valueFour, setValueFour] = useState("")
	const [valueFive, setValueFive] = useState("")
	const [valueSix, setValueSix] = useState("")

	useEffect(() => {
		if (launchCodeAuto != null && launchUrl != null) {
			verifyAccessCodeAuto()
		}
	}, [launchUrl, launchCodeAuto])

	const verifyCodeStr = () => {
		let launchCode = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
		let launchKey = `${keyValueOne}${keyValueTwo}${keyValueThree}${keyValueFour}${keyValueFive}${keyValueSix}${keyValueSeven}`
		console.log("launchCode", launchCode)
		console.log("launchKey", launchKey)

		if (launchCode != null && launchCode.length === 6) {
			if (launchUrl === undefined || launchUrl === null) {
				console.log(launchKey?.length)
				if (launchKey?.length === 7) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		}
	}

	const verifyAccessCodeAuto = () => {
		if (
			launchUrl != null &&
			launchCodeAuto != null &&
			launchCodeAuto.length === 6
		) {
			isLoading(true)
			let paramObj = {
				launchKey: launchUrl,
				launchCode: launchCodeAuto,
			}
			launchPageEx(paramObj)
				.then(res => {
					isLoading(false)
					if (res?.result) {
						if (res?.data[0]?.status) {
							toast(
								toastFunctionToaster(
									"Access code verification is successful, redirecting!!!",
									"success"
								)
							)
							setShowNextPage(res?.data[0]?.status)
							navigate("/lingolink/apps", {
								state: {
									launchKey: launchUrl,
									launchCode: launchCodeAuto,
									timeLeft: res?.data[0]?.time_left,
									sessionKey: res?.data[0]?.sessionId,
								},
							})
						}
					} else {
						toast(
							toastFunctionToaster(
								"Unable to validate access code..",
								"error"
							)
						)
					}
				})
				.catch(err => {
					isLoading(false)
				})
		}
	}

	const verifyAccessCode = () => {
		let launchCode = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
		let launchKey = `${keyValueOne}${keyValueTwo}${keyValueThree}${keyValueFour}${keyValueFive}${keyValueSix}${keyValueSeven}`
		console.log(launchUrl != null || launchKey?.length)
		if (
			(launchUrl != null || launchKey?.length) &&
			launchCode != null &&
			launchCode.length === 6
		) {
			isLoading(true)
			let paramObj = {
				launchKey: launchUrl ? launchUrl : launchKey,
				launchCode: launchCode,
			}
			launchPageEx(paramObj)
				.then(res => {
					isLoading(false)
					if (res?.result) {
						if (res?.data[0]?.status) {
							toast(
								toastFunctionToaster(
									"Access code verification is successful, redirecting!!!",
									"success"
								)
							)
							setShowNextPage(res?.data[0]?.status)
							navigate("/lingolink/apps", {
								state: {
									launchKey: launchUrl
										? launchUrl
										: launchKey,
									launchCode: launchCode,
									timeLeft: res?.data[0]?.time_left,
									sessionKey: res?.data[0]?.sessionId,
								},
							})
						}
					} else {
						toast(
							toastFunctionToaster(
								"Unable to validate access code..",
								"error"
							)
						)
					}
				})
				.catch(err => {
					isLoading(false)
				})
		}
	}

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				{launchUrl === null || launchUrl === undefined ? (
					<Flex w={"100%"} direction={"column"} align={"center"}>
						<Heading
							color="#gray.800"
							fontSize={"26px"}
							fontWeight={"normal"}
						>
							Hmm....{" "}
							<Text as="span" fontWeight={"600"} mr="1">
								Something is not right!!
							</Text>
						</Heading>
						<Text mt="3" fontSize={"17px"} color="gray.700">
							Looks like you don't have enough credentials to
							access this page..
						</Text>
						<Link
							_hover={{ cursor: "pointer" }}
							p={1}
							bg={"gray.200"}
							rounded={"md"}
							mt={2}
							href={"https://lingolet.ai"}
							w={{ base: "100%", "2xl": "unset" }}
						>
							<Text
								mt="1"
								fontSize={"17px"}
								color="blue.600"
								px={2}
							>
								Back to Lingolet Home Page
							</Text>
						</Link>
					</Flex>
				) : (
					<Flex w={"100%"} direction={"column"} align={"center"}>
						<Heading
							color="#gray.800"
							fontSize={"26px"}
							fontWeight={"normal"}
						>
							Please Use your{" "}
							<Text as="span" fontWeight={"600"} mr="1">
								Access Code
							</Text>{" "}
							to access this page..
						</Heading>
					</Flex>
				)}
				<Modal
					finalFocusRef={btnRef}
					isOpen={launchModal}
					onClose={() => {
						setLaunchModal(false)
						onClose()
					}}
				>
					<ModalOverlay />
					<ModalContent>
						{/* <ModalHeader>Enter your access code</ModalHeader> */}
						<ModalCloseButton />
						<ModalBody pb={6}>
							{launchUrl === null || launchUrl === undefined ? (
								<>
									<Heading
										fontSize={"20px"}
										fontWeight={600}
										my="2"
									>
										Enter your key
									</Heading>
									<Flex
										zIndex="2"
										direction="column"
										w={{ base: "100%", md: "425px" }}
										maxW="100%"
										background="transparent"
										borderRadius="15px"
										mx={{ base: "auto", lg: "unset" }}
										me="auto"
										mb={{ base: "20px", md: "auto" }}
									>
										<FormControl>
											<Flex justify="center">
												<PinInput
													mx="auto"
													type="alphanumeric"
												>
													<PinInputField
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														me="10px"
														value={keyValueOne}
														onChange={e => {
															setKeyValueOne(
																e.target.value
															)
														}}
													/>
													<PinInputField
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														me="10px"
														value={keyValueTwo}
														onChange={e => {
															setKeyValueTwo(
																e.target.value
															)
														}}
													/>
													<PinInputField
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														me="10px"
														value={keyValueThree}
														onChange={e => {
															setKeyValueThree(
																e.target.value
															)
														}}
													/>
													<PinInputField
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														value={keyValueFour}
														onChange={e => {
															setKeyValueFour(
																e.target.value
															)
														}}
													/>
													<PinInputField
														ml={2}
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														value={keyValueFive}
														onChange={e => {
															setKeyValueFive(
																e.target.value
															)
														}}
													/>
													<PinInputField
														ml={2}
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														value={keyValueSix}
														onChange={e => {
															setKeyValueSix(
																e.target.value
															)
														}}
													/>
													<PinInputField
														ml={2}
														fontSize="30px"
														color={textColor}
														borderRadius="16px"
														borderColor={
															borderColor
														}
														h={{
															base: "55px",
															md: "80px",
														}}
														w={{
															base: "63px",
															md: "95px",
														}}
														value={keyValueSeven}
														onChange={e => {
															setKeyValueSeven(
																e.target.value
															)
														}}
													/>
												</PinInput>
											</Flex>
										</FormControl>
									</Flex>
								</>
							) : null}
							<Heading fontSize={"20px"} fontWeight={600} my="2">
								Enter your access code
							</Heading>
							<Flex
								zIndex="2"
								direction="column"
								w={{ base: "100%", md: "425px" }}
								maxW="100%"
								background="transparent"
								borderRadius="15px"
								mx={{ base: "auto", lg: "unset" }}
								me="auto"
								mb={{ base: "20px", md: "auto" }}
							>
								<FormControl>
									<Flex justify="center">
										<PinInput mx="auto" otp>
											<PinInputField
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												me="10px"
												value={valueOne}
												onChange={e => {
													setValueOne(e.target.value)
												}}
											/>
											<PinInputField
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												me="10px"
												value={valueTwo}
												onChange={e => {
													setValueTwo(e.target.value)
												}}
											/>
											<PinInputField
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												me="10px"
												value={valueThree}
												onChange={e => {
													setValueThree(
														e.target.value
													)
												}}
											/>
											<PinInputField
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												value={valueFour}
												onChange={e => {
													setValueFour(e.target.value)
												}}
											/>
											<PinInputField
												ml={2}
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												value={valueFive}
												onChange={e => {
													setValueFive(e.target.value)
												}}
											/>
											<PinInputField
												ml={2}
												fontSize="30px"
												color={textColor}
												borderRadius="16px"
												borderColor={borderColor}
												h={{ base: "55px", md: "80px" }}
												w={{ base: "63px", md: "95px" }}
												value={valueSix}
												onChange={e => {
													setValueSix(e.target.value)
												}}
											/>
										</PinInput>
									</Flex>
								</FormControl>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Button
								isDisabled={verifyCodeStr() ? false : true}
								isLoading={loading ? true : false}
								onClick={() => verifyAccessCode()}
								colorScheme={verifyCodeStr() ? "green" : "red"}
								mr={3}
							>
								Verify
							</Button>
							<Button
								onClick={() => {
									setLaunchModal(false)
									onClose()
								}}
							>
								Cancel
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Box>
		</>
	)
}

export default DynamicLauncher

// Chakra Import
import { Box, Text, Button, SimpleGrid, Spacer, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { MdAttachMoney, MdPerson, MdLink, MdLinkOff, MdPhone, MdSensors } from "react-icons/md";

// Component Import
import Card from "../../../../components/card/Card";
import IconBox from "../../../../components/icons/IconBox";
import MiniStatistics from "../../../../components/card/MiniStatistics";

function SubscriberEventMetrics(props) {
	const brandColor = useColorModeValue("brand.500", "white");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

	const projectStats = props.projectStats ? props.projectStats : [];

	return (
		<Card w={"100%"} h={"280px"} mt={5}>
			<Flex w={"100%"}>
				<Text ml={4}>Document AI</Text>
				<Spacer />
				<Button size={"sm"} bg={"blue.100"}>
					Overall
				</Button>
				<Button size={"sm"} bg={"blue.100"} ml={1}>
					Today
				</Button>
				<Button size={"sm"} bg={"blue.100"} ml={1}>
					This Week
				</Button>
				<Button size={"sm"} bg={"blue.100"} ml={1}>
					This Month
				</Button>
				<Button size={"sm"} bg={"blue.100"} ml={1}>
					This Year
				</Button>
				<Button size={"sm"} bg={"blue.100"} ml={1}>
					Custom Range
				</Button>
			</Flex>
			<Box w={"100%"} mt={2} borderTopColor={"gray.300"} borderTopWidth={"0.5px"}>
				<SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }} gap="10px" mb="10px">
					<MiniStatistics
						startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdLink} color={brandColor} />} />}
						name="Projects"
						value={projectStats?.length > 0 ? projectStats[0]?.projects : 0}
					/>
					<MiniStatistics
						startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdLinkOff} color={"red.300"} />} />}
						name="Contents"
						value={projectStats?.length > 0 ? projectStats[0]?.contents : 0}
					/>
					<MiniStatistics
						startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdSensors} color={brandColor} />} />}
						name="Workflows (all)"
						value={projectStats?.length > 0 ? projectStats[0]?.workflows : 0}
					/>
					<MiniStatistics
						startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdPerson} color={brandColor} />} />}
						name="Translation Workflows"
						value={projectStats?.length > 0 ? projectStats[0]?.workflows_translation : 0}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
								icon={<Icon w="28px" h="28px" as={MdPhone} color="white" />}
							/>
						}
						name="Transcription Workflows"
						value={projectStats?.length > 0 ? projectStats[0]?.workflows_transcription : 0}
					/>
					<MiniStatistics
						startContent={
							<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />} />
						}
						name="Transcribe Workflows"
						value={projectStats?.length > 0 ? projectStats[0]?.workflows_transcribe : 0.0}
					/>
				</SimpleGrid>
			</Box>
		</Card>
	);
}

export default SubscriberEventMetrics;

import { Icon } from "@chakra-ui/react";
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import Home from "../ailab/applicant/home/Home";
import AddProposal from "../ailab/applicant/proposal/AddProposal";
import ApplicationsList from "../ailab/applicant/proposal/ApplicationsList";


const ApplicantRoutes = [
  {
    name: "Home",
    layout: "/ailab/applicant",
    path: "/home",
    component: <Home/>,
    secondary: true,
    showToMenu: true,
    icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
  },
  {
    name: "Applications",
    path: "/application",
    icon: (
      <Icon as={AiOutlineSetting} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "Create Proposal",
        layout: "/ailab/applicant",
        path: "/proposal/create",
        component: <AddProposal />,
        secondary: true,
        showToMenu: true,
      },
      {
        name: "Applications",
        layout: "/ailab/applicant",
        path: "/proposal/list",
        component: <ApplicationsList />,
        secondary: true,
        showToMenu: true,
      },
      {
        name: "Update Application",
        layout: "/ailab/applicant",
        path: "/proposal/update/:application_id",
        component: <AddProposal />,
        secondary: true,
        showToMenu: false,
      },
    ],
  },
  {
    name: "Settings",
    layout: "/ailab/applicant",
    path: "/settings",
    component: <>Settings</>,
    secondary: false,
    showToMenu: true,
    icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
  },
  {
    name: "Profile",
    layout: "/ailab/applicant",
    path: "/profile",
    component: <>Profile</>,
    secondary: false,
    showToMenu: true,
    icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
  }

]

export default ApplicantRoutes
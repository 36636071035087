import React from "react";
import Card from "../../../../components/card/Card";

function Active() {
	return (
		<Card w={"100%"} h={"100%"} rounded={"md"} p={5} mx={3} mt="3">
			Active
		</Card>
	);
}

export default Active;

import React, { useState } from 'react'
// Chakra Import
import {
    Box,
    Flex,
    Text,
    Button,
    Drawer,
    FormLabel,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    useToast,
    Checkbox,
} from "@chakra-ui/react";

// Component Imports
import InputField from "../../../components/fields/InputField";
import { toastFunctionToaster } from '../../../utils/toastFunction';
import { useBlueButton, useYellowButton } from '../../../theme/globalColorTheme';

// Api Services
import { shareLeaderCode } from '../../../services/accountServices';

function LinkSmsLeaderCode(props) {
    const toast = useToast()
    const btnRef = React.useRef()
    const accountUuid = props?.accountId ? props?.accountId : null
    const locationUuid = props?.locationId ? props?.locationId : null
    const [mobileNumber, setMobileNumber] = useState(null)
    const linkInfo = props?.linkItem
    const blueButtonBg = useBlueButton();
    const yellowButtonBg = useYellowButton();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
    const message = "Leader Access Code : ";
    const [leaderCode, setLeaderCode] = useState(props?.linkItem?.leader_code ? message + props?.linkItem?.leader_code : message);
    const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";


    // Todo:Send link to the customer
    const shareLeaderCodeBySMS = () => {
        setSaveDetailsLoader(true)
        if (mobileNumber?.length > 0 && linkInfo?.uuid != null) {
            if (accountUuid != null && locationUuid != null) {
                let objBody = {
                    link_account_uuid: accountUuid,
                    link_location_uuid: locationUuid,
                    link_uuid: linkInfo?.uuid,
                    host_name: currentHost,
                    mobileNumber: mobileNumber,
                    body: leaderCode,
                };
                // Todo:Enable Once Api is fetched..
                // shareLeaderCodeBySmS(objBody)
                //     .then((res) => {
                //         setSaveDetailsLoader(false)
                //         if (res?.result) {
                //             toast(toastFunctionToaster(res.message, "success"));
                //         } else {
                //             toast(toastFunctionToaster(res.message, "error"));
                //         }
                //     })
                //     .catch((err) => {
                //         setSaveDetailsLoader(false)
                //         toast(toastFunctionToaster(err.message, "error"));
                //     });
            } else {
                setSaveDetailsLoader(false)
                toast(
                    toastFunctionToaster(
                        "You must provide email addresse and select which url to share..",
                        "error"
                    )
                );
            }
        }
        else {
            setSaveDetailsLoader(false)
        }
    };

    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} w='100%' size="sm" bg={yellowButtonBg} borderRadius="0" onClick={onOpen}>SMS Code</Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody p="0px">
                        {Object.keys(linkInfo)?.length
                            ? <Flex w={"100%"} mt={2} h="98%">
                                <Flex w="100%"
                                    direction={'column'} overflow={'hidden'}>
                                    <Flex w={'100%'} direction={'column'} borderBottomWidth={'1px'} borderColor="grey.200" p="3">
                                        <Text fontSize={'lg'} fontWeight={'600'}>{"Send Multichannel Link Leader Code"}</Text>
                                        <DrawerCloseButton />
                                    </Flex>
                                    <Box p="3" h="95%">
                                        <InputField
                                            mb="0"
                                            me="30px"
                                            type='number'
                                            label="Enter Mobile Number"
                                            value={mobileNumber}
                                            name="mobileNumber"
                                            borderRadius="5px"
                                            onChange={e => {
                                                setMobileNumber(e.target.val0);
                                            }}
                                            placeholder="Enter Mobile Number"
                                            w="100%"
                                            maxlength={15}
                                        />
                                        <FormLabel mt="3" ml="2" required>
                                        <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                Message:
                                            </Text>
                                            <Textarea
                                                mb="0"
                                                me="30px"
                                                rows="10"
                                                fontSize={'20px'}
                                                fontWeight={'bold'}
                                                id="message"
                                                value={leaderCode}
                                                name="leaderCode"
                                                onChange={(e) => {
                                                    setLeaderCode(e.target.value);
                                                }}
                                                placeholder="Enter Message"
                                                w="100%"
                                                maxlength={500}
                                            />
                                        </FormLabel>
                                    </Box>
                                    <Flex w={'100%'} mt="4" alignItems={'center'} p="3" borderTopWidth={'1px'} borderColor="grey.200">
                                        <Button
                                            bg={blueButtonBg}
                                            borderRadius={'10px'}
                                            size="sm"
                                            mr="2"
                                            onClick={() => {
                                                shareLeaderCodeBySMS();
                                            }}
                                            isDisabled={
                                                mobileNumber === null || mobileNumber?.length === 0 ? true : false
                                            }
                                            isLoading={saveDetailsLoader ? true : false}
                                        >
                                            Send SMS
                                        </Button>

                                        <Button variant='outline' borderRadius={'10px'} colorScheme='red' size="sm" onClick={onClose}>
                                            Close
                                        </Button>
                                    </Flex>

                                </Flex>
                            </Flex>
                            : <Flex w={'100%'} p={2} bg={'red.100'}>
                                <Text>Error collecting Selected Link Details</Text>
                            </Flex>}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LinkSmsLeaderCode
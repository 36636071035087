import React, { useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Spinner,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tooltip,
  Badge,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Switch,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  Textarea,
} from "@chakra-ui/react";
import { BsFiletypePdf, BsFiletypeAi,  BsFiletypeMp3, BsFiletypeDocx, BsFiletypeTxt, BsFiletypeMp4} from "react-icons/bs";
import { BiCommentEdit, BiBookReader } from "react-icons/bi";
import { CgAsterisk } from "react-icons/cg";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import InputField from "../../../components/fields/InputField.js";
import { SearchBar } from "../../../components/navbar/searchBar/SearchBar.js";
import {
  addUpdateProjectContent,
  getContentDetails,
} from "../../../services/moduleServices.js";
import { useCustomColorModeValues } from "../../../utils/ColorMode.js";
import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";
import { toastFunctionToaster } from "../../../utils/toastFunction.js";
import Card from "../../../components/card/Card.js";

import {
    getContentForView
  } from "../../../services/moduleServices.js";
  
function DisplayContent(props) {
    const toast = useToast();
    const { columnsData, tableData } = props;
    const pageContent = props?.pageContent ? props?.pageContent : null;
    const projectUuid = props?.projectUuid ? props?.projectUuid : null;
    

    const [contentType, setContentType] = useState(null);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [documentText, setDocumentText] = useState(null);
    const [documentTitle, setDocumentTitle] = useState(null);
    const [documentFormat, setDocumentFormat] = useState(null);

    const [loading, isLoading] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        if (pageContent != null){
            checkContentType();
            let contentUuid = pageContent?.uuid ? pageContent?.uuid : null;
            if (projectUuid != null && contentUuid != null){
                fetchProjectContent(contentUuid);
            }
        }
    }, [pageContent]);

    const checkContentType = () => {
        let contentType = pageContent?.type ? pageContent?.type : null;
        if (contentType != null){
            if (contentType.includes('pdf') || contentType.includes('doc')){
                setContentType('doc');
            } else if (contentType.includes('txt') || contentType.includes('text')){
              setContentType('text');
            } else if (contentType.includes('audio')){
              setContentType('audio');
            } 
        } 
    }

    const fetchProjectContent = (contentUuid) => {
        isLoading(true);
        let objData = {
          project_uuid: projectUuid,
          content_uuid: contentUuid,
        };
        getContentForView(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setDocumentText(res?.data[0]?.content);
                setDocumentUrl(res?.data[0]?.url);
                setDocumentTitle(res?.data[0]?.title);
                setDocumentFormat(res?.data[0]?.format);
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    return (
        <>
            {/* <Text>
                {pageContent != null ?JSON.stringify(pageContent):<Text>Error</Text>}
            </Text> */}
            {/* <Text>{contentType}</Text> */}
            {loading ? (
                <Flex h="70vh" alignItems="center" justifyContent="center">
                    <Spinner size="xl" />
                </Flex>
            ) : (
                <Box w={'100%'} h={'100%'}>
                    {contentType === 'text' 
                    ?<Flex w={'100%'} direction={'column'}>
                        <Card w={'100%'} mt={1}>
                            <Text>{documentTitle}</Text>
                        </Card>
                        <Card w={'100%'} 
                             h="calc(100vh - 300px)"
                            overflow="auto"
                            borderTopWidth="0px"
                            css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '24px',
                            },
                            }}     
                            mt={1}>
                        <pre style={{whiteSpace: 'pre-wrap', fontFamily: 'inherit'}}>{documentText}</pre>
                        </Card>
                    </Flex>:null}
                    {contentType === 'doc' && documentUrl != null
                    ?<Flex w={'100%'} direction={'column'}>
                        {documentFormat === 'pdf'
                        ?<DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{uri: documentUrl, fileName:documentTitle, fileType: 'pdf'}]}
                            config={{
                                theme:{
                                'pdf-download':true
                                },
                                header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: true,
                                },
                                csvDelimiter: ",", // "," as default,
                                pdfZoom: {
                                defaultZoom: 1.1, // 1 as default,
                                zoomJump: 0.2, // 0.1 as default,
                                },
                                pdfDownload: true,
                                pdfVerticalScrollByDefault: false, // false as default
                            }}
                        />:null}
                        {documentFormat === 'doc'
                        ?<DocViewer 
                            style={{width: 600, height: 1200}}
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{uri: documentUrl, fileName:documentTitle, fileType: 'doc'}]}
                            config={{
                                theme:{
                                // 'pdf-download':true
                                height:"100%"
                                },
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: true,
                                },
                                // csvDelimiter: ",", // "," as default,
                                // pdfZoom: {
                                //     defaultZoom: 1.1, // 1 as default,
                                //     zoomJump: 0.2, // 0.1 as default,
                                // },
                                // pdfDownload: true,
                                // pdfVerticalScrollByDefault: false, // false as default
                            }}
                        />:null}
                        <Box w={'100%'}>
                            Page {pageNumber} of {numPages}
                        </Box>
                    </Flex>:null}
                    {contentType === 'audio' && documentUrl != null
                    ?<Flex w={'100%'} direction={'column'}>
                        <Text fontSize={'lg'}>Play your Audio Content</Text>
                        <ReactAudioPlayer
                            src={documentUrl}
                            autoPlay={false}
                            controls
                        />
                    </Flex>:null}
                </Box>
            )}
        </>
    )
}

export default DisplayContent;
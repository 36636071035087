import Card from "../../../../components/card/Card";
import { Box, Button, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useBrandBg, useGreenBg, useRedBg } from "../../../../theme/globalColorTheme";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { useEffect } from "react";
import { useState } from "react";

const LocationAndSubscribers = ({ locationList, loading, onClick, subsId, setLocationList }) => {
	const [sort, setSort] = useState(null);
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

	function sortLocations() {
		if (locationList && locationList.length > 1 && sort !== "") {
			const tempLocations = [...locationList].sort((a, b) => {
				if (sort === "asc") {
					if (a.is_sub_location !== b.is_sub_location) {
						return a.is_sub_location - b.is_sub_location;
					}
					return a.link_location_title.localeCompare(b.link_location_title);
				} else if (sort === "desc") {
					if (a.is_sub_location !== b.is_sub_location) {
						return a.is_sub_location - b.is_sub_location;
					}
					return b.link_location_title.localeCompare(a.link_location_title);
				}
				return 0;
			});

			setLocationList(tempLocations);
		}
	}

	useEffect(() => {
		sortLocations();
	}, [sort]);

	const renderLocationList = locationList?.map((data, idx) => {
		return (
			<Box
				p={0}
				key={idx}
				ml="10px"
				position="relative"
				_before={
					data?.subs?.length
						? {
								content: "''",
								width: "10px",
								height: "1px",
								bg: "#d7d7d7",
								left: "-10px",
								top: data?.subs?.length ? "30px" : null,
								position: "absolute",
								transition: "all .3s ease",
						  }
						: undefined
				}
				mb="1px">
				<Box p="1" mb="1" borderWidth="1px" borderColor={"#d7d7d7"} rounded={'lg'}>
					<Text fontWeight={"600"}>{data?.link_location_title}</Text>
					<Text>{data?.link_location_name}</Text>
				</Box>
				<Box>
					{data?.subs?.length > 0
						? data.subs.map((subData, index) => (
								<Box
									position="relative"
									_before={{
										content: "''",
										width: "55px",
										height: "1px",
										bg: "#d7d7d7",
										left: "-30px",
										top: "36px",
										position: "absolute",
										transition: "all .3s ease",
									}}
									_after={{
										content: "''",
										width: "1px",
										height: "117%",
										bg: "#d7d7d7",
										left: "-31px",
										top: "-36px",
										position: "absolute",
										transition: "all .3s ease",
									}}
									key={index}
									_hover={{ bg: "blue.100", color: "black" }}
									ml="20px"
									p="2"
									mb="2"
									border="1px"
									rounded={'lg'}
									// borderColor={subData?.disabled ? 'red.400' : greenBg}
									borderColor={"#d7d7d7"}
									cursor={"pointer"}
									bg={subData?.user_uuid === subsId ? useGreenBg : 'blue.50'}
									onClick={() => onClick(data, subData)}>
									<Flex alignItems={"center"} justifyContent={"space-between"}>
										<Text fontWeight={"300"}>
											{subData?.first_name} {subData?.last_name}
										</Text>
										<Box
											h="5px"
											w="5px"
											rounded={'lg'}
											bg={subData?.disabled ? "red.400" : "green.400"}></Box>
									</Flex>
									<Text fontSize={"sm"}>{subData?.email}</Text>
								</Box>
						  ))
						: null}
				</Box>
			</Box>
		);
	});

	return (
		<>
			{configurationCollapseFlag ? (
				<Box w="20%" position="relative" p="1" rounded={'lg'} bg={useBrandBg} ml="2">
					<Box position="absolute" top="0" right="0" mr="2" mt="2" zIndex="999">
						<AiFillRightCircle
							cursor="pointer"
							size="20px"
							onClick={() => {
								setConfiguationCollapseFlag(!configurationCollapseFlag);
							}}
						/>
					</Box>
					<Box w="100%" h="97%" bg={useBrandBg}>
						<Text fontWeight="bold">Location And Subscribers</Text>
						<Card p="2" h="98%" overflow="hidden" mt="4" rounded={'lg'}>
							<Flex pb={2} px={4} justifyContent="center">
								<Flex justifyContent="center">
									<Menu>
										<MenuButton as={Button} rightIcon={<ChevronDownIcon />} w="96%">
											{sort
												? sort === "asc"
													? "By Name (Ascending)"
													: "By Name (Descending)"
												: "Sort Locations"}
										</MenuButton>
										<MenuList onClick={e => setSort(e.target.value)}>
											<MenuItem value="asc">By Name (Ascending)</MenuItem>
											<MenuItem value="desc">By Name (Descending)</MenuItem>
										</MenuList>
									</Menu>
								</Flex>
							</Flex>
							{loading ? (
								<Flex alignItems="center" justifyContent="center" h="90%">
									<Spinner size="lg" />
								</Flex>
							) : locationList?.length ? (
								<Box p="1" overflow="auto" className="custom-scrollbar">
									{renderLocationList}
								</Box>
							) : (
								<Flex
									w={"100%"}
									p={5}
									bg={useRedBg}
									borderLeftWidth={"5px"}
									borderLeftColor={"red.500"}>
									<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
										No Locations and Subscriber found..
									</Heading>
								</Flex>
							)}
						</Card>
					</Box>
				</Box>
			) : (
				<Box w="5%" p="3" borderRadius="10px" bg={useBrandBg} h="100%" ml="2" pl="5">
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							onClick={() => {
								setConfiguationCollapseFlag(!configurationCollapseFlag);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}>
							Location And Subscribers
						</Text>
					</Box>
				</Box>
			)}
		</>
	);
};

export default LocationAndSubscribers;

// React + Chakra Imports
import React, { useState } from "react";
import { AiOutlineBulb } from "react-icons/ai";
import { Flex, Icon, Tab, Tabs, TabList, TabPanel, TabPanels } from "@chakra-ui/react";

// Component Imports
import Paid from "./Paid";
import Active from "./Active";
import Pending from "./Pending";
import Completed from "./Completed";
import Card from "../../../../components/card/Card";
import { defaultThemeColor } from "../../../../constants";

function BookingHome() {
	const [activeTab, setActiveTab] = useState(0);
	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Tabs w="100%" borderBottom={"0px"} mx="auto" onChange={handleTabChange} index={activeTab}>
				<Card w={"100%"} h={"100%"} rounded={"md"} p={1} mx={3}>
					<TabList border="0px" w="100%">
						<Flex justifyContent={"end"} w="100%">
							<Flex>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Active
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Completed
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Pending
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Closed Paid
								</Tab>
							</Flex>
						</Flex>
					</TabList>
				</Card>
				<TabPanels mt="1" h="100%">
					<TabPanel p="0">
						<Active />
					</TabPanel>
					<TabPanel p="0" h="100%">
						<Completed />
					</TabPanel>
					<TabPanel p="0" h="100%">
						<Pending />
					</TabPanel>
					<TabPanel p="0" h="100%">
						<Paid />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	);
}

export default BookingHome;

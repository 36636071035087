import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiUpload } from 'react-icons/bi';
import { useDispatch } from 'react-redux';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Stack,
  Input,
  Icon,
  Text,
  FormControl,
  VStack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from '../../../../services/axios';
// Custom components
import Card from '../../../../components/card/Card.js';
// Assets
import { MdUpload } from 'react-icons/md';
import 'react-dropzone-uploader/dist/styles.css';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { uploadBusinessLogo } from '../../../../services/businessServices';
import { setBannerFlag } from '../../../../store/actions/topNotificationActions';
// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";

export default function Upload(props) {
  const toast = useToast();
  const { used, total, ...rest } = props;

  // Chakra Color Mode
  const pillButtonBg = useColorModeValue("blue.100", "gray.600");
  const pillButtonBgSelected = useColorModeValue("blue.400", "red.500");

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';

  // Todo:Declared Use-State
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

  const [currentSelection, setCurrentSelection] = useState('logo');

  function postObjectData(s3Data, selectedFile) {
    const { url, fields } = s3Data;
    const payload = new FormData();
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append('file', selectedFile);
    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common['Authorization'];
    const { data: result } = ax_instance
      .post(url, payload)
      .then(res => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setUploadStatus('File is uploaded successfully');
          dispatch(setBannerFlag(true));
          setUploadFile(null);
          props.uploadCompleted();
        } else {
          setUploadStatus('File is uploaded successfully');
          dispatch(setBannerFlag(true));
          setUploadFile();
          setUploadFileUrl(null);
          props.uploadCompleted();
        }
      })
      .catch(err => {
        isLoading(false);
        setUploadFile();
        setUploadFileUrl(null);
        setUploadStatus('Upload failed with Error :' + err);
      });
  }

  const handleFileChange = e => {
    if (e.target.files) {
      if (e.target.files[0].size <= 2048000) {
        setUploadFile(e.target.files[0]);
        setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        toast(toastFunctionToaster("The maximum supported file size is 2048KB or 2MB", "error"));
      }
    }
  };

  const handleSubmitNew = async () => {
    setUploadStatusLoading(true);
    if (uploadFile) {
      let objData = {
        email: emaiId,
        content_choice: currentSelection,
        file_name: uploadFile.name,
        file_type: uploadFile.type,
      };
      setUploadStatus('Getting upload url for the selected file..');
      const response = await uploadBusinessLogo(objData);
      setUploadStatusLoading(false);
      if (response && response.data && response.data.length > 0) {
        setUploadStatusLoading(false);
        const finalUrl = response.data[0].signedPackage.signedUrl;
        setUploadStatus('Trying to upload the selected file..');
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        setUploadStatusLoading(false);
        setUploadStatus('Unable to get the upload signed url..');
      }
    }
  };

  return (
    <Card {...rest} mb="20px" align="center" p="20px" w={props?.width ? props?.width : null} h="100%">
      <Box w={'100%'}>
        <Card w={'100%'}>
          <FormControl cursor="pointer">
            <Text fontSize={'12px'}>{uploadFileUrl}</Text>
            <Text mb={4} fontSize={'12px'}>
              {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
            </Text>
            <Text fontSize={'20px'} mb="10px" fontWeight={'bold'}>
              Upload Business or Company Logo, Login Screen Background
            </Text>
            <Flex minW={'340px'} maxW={'340px'} bg={'gray.100'} rounded={'md'} p={1}>
              <Text
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentSelection('logo');
                }}
                p={2} roundedLeft={'md'} bg={currentSelection === 'logo' ? pillButtonBgSelected : pillButtonBg}>Business Logo</Text>
              <Text
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentSelection('loginscreen');
                }}
                p={2} roundedRight={'md'} bg={currentSelection === 'loginscreen' ? pillButtonBgSelected : pillButtonBg} pl={5} pr={5}>Login Screen Background</Text>
            </Flex>
            <Box
              mt={5}
              position="relative"
              height="200px"
              width="100%"
              borderWidth="2px"
              borderColor={brandColor}
              borderRadius={'10px'}
              cursor="pointer"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                cursor="pointer"
              >
                <Stack
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justify="center"
                  spacing="4"
                  cursor="pointer"
                >
                  <Box textAlign="center">
                    <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                    <Flex justify="center" mx="auto" mb="12px">
                      <Text fontSize="xl" fontWeight="700" color={brandColor}>
                        {currentSelection == 'logo'
                          ? 'Upload Logo Image File'
                          : 'Upload Login Background Screen'}
                      </Text>
                    </Flex>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                    >
                      PNG, JPG and JPEG files are allowed (maximum 2MB)
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.700"
                    >
                      The suggestive business logo size is <b>180x60</b> or multiple of this size.
                    </Text>
                  </Box>
                </Stack>
              </Box>
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept=".png, .jpg, .jpeg" //Include .pdf here to accept PDF files
                cursor="pointer"
                onChange={handleFileChange}
              />
            </Box>
          </FormControl>
        </Card>
      </Box>
      {uploadFile ? (
        <Button
          mt={5}
          size="sm"
          bg={'blue.100'}
          onClick={handleSubmitNew}
          isDisabled={uploadStatusLoading ? true : false}
          isLoading={uploadStatusLoading ? true : false}
        >
          {currentSelection == 'logo'
            ? 'Upload Logo Image File'
            : 'Upload Login Background Screen'}
        </Button>
      ) : null}
      <Box w={'100%'}>
        <Text textAlign="center" mt="3" color="green.200">
          {uploadStatus}
        </Text>
      </Box>
    </Card>
  );
}

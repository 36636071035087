
export function countUniqueValues(obj, key) {
    // Create an empty object to store the count of each value
    const valueCount = {};
  
    // Loop through the object and count the occurrences of each value
    for (const item of Object.values(obj)) {
      const value = item[key];
      if (valueCount[value]) {
        valueCount[value]++;
      } else {
        valueCount[value] = 1;
      }
    }
  
    // Return the number of unique values
    return Object.keys(valueCount).length;
}
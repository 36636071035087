export const teamColumnData = [
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "FIRST NAME",
    accessor: "first_name",
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  // {
  //   Header: "UUID",
  //   accessor: "uuid",
  // },
];

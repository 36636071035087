import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// ------------------------
// Project Content 
// ------------------------
export const addUpdateProjectContent = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpdateContent, data, config);
};
export const getAudioContentUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getAudioFileUrl, data, config);
};
export const getTextFromUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getProcessContentUrl, data, config);
};

export const getContentDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getContentData, data, config);
};
export const uploadContentDocument = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().uploadContentData, data, config);
};
export const getContentForView = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().contentViewData, data, config);
};

// ------------------------
// Project Content Workflow
// ------------------------
export const addNewContentWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().createContentWorkflow, data, config);
};
export const listingContentWorkflowUser = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().listingContentWorkflow, data, config);
};
export const getContentWorkflowResult = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().contentWorkflowResultApi, data, config);
};
export const sendEmailWithResult = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().contentResultMailApi, data, config);
};


// ------------------------
// Resource Management
// ------------------------
 
export const addUpdateResourceDocument = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpdateResource, data, config);
};
export const uploadResourceDocument = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().uploadResource, data, config);
};
export const getAllResources = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().allResources, data, config);
};

export const getProjectContents = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getAllContent, data, config);
};
export const getProjectActiveJobs = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getAllActiveApi, data, config);
};


export const getResourceDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getResourceData, data, config);
};
export const addUpdateResourceTraining = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addUpdateTrainingContent, data, config);
};
export const getResourceTraining = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getTrainingContent, data, config);
};
export const prepareResourceTraining = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().prepareTrainingContent, data, config);
};


// ------------------------------------
//
//
//
//
// ------------------------------------

export const listAllModules = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().moduleListingAll, data, config);
};


// Todo:Create New Module
export const createNewModule = (
  data,
  userCode,
  config = null,
  axiosInstance = axios
) => {
  if (userCode === "a") {
    return axiosInstance.post(serviceAPIs().createModuleAdmin, data, config);
  } else if (userCode === "b") {
    return axiosInstance.post(serviceAPIs().createModuleBusiness, data, config);
  }
};

// Get Module for Training
export const getModuleForTraining = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().wsModuleForTraining, data, config);
};

// Get Specific Module
export const getSpecificModule = (
  data,
  userCode,
  config = null,
  axiosInstance = axios
) => {
  if (userCode == "a") {
    return axiosInstance.post(serviceAPIs().specificAdminModule, data, config);
  } else if (userCode == "b") {
    return axiosInstance.post(
      serviceAPIs().specificBusinessModule,
      data,
      config
    );
  }
};
export const getSpecificAdminModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().specificAdminModule, data, config);
};
export const getSpecificBusinessModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().specificBusinessModule, data, config);
};
export const getSpecificAssociateModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().associateSpecificModule,
    data,
    config
  );
};

// Todo:Fetch Modules
export const fetchModules = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().moduleList, data, config);
};
export const fetchModulesAdmin = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getModuleListAdmin, data, config);
};
export const fetchModulesBusiness = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().getModuleListBusiness, data, config);
};

// Todo:Update Module Details
export const updateModule = (
  data,
  userCode,
  config = null,
  axiosInstance = axios
) => {
  if (userCode === "a") {
    return axiosInstance.post(
      serviceAPIs().updateAdminModuleDetails,
      data,
      config
    );
  } else if (userCode === "b") {
    return axiosInstance.post(
      serviceAPIs().updateBusinessModuleDetails,
      data,
      config
    );
  }
  // return axiosInstance.post(serviceAPIs().updateModuleDetails, data, config);
};

// Specific Topic to Work in Workshop
export const getSpecificModuleTopicWorkshop = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().wsSpecificModuleTopic, data, config);
};

// Todo:Manage Module Status
export const manageModuleStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().managePublishedStatus, data, config);
};

// Todo:Manage Module Status
export const fetchTopicList = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().topicList, data, config);
};

// Get Specific Module Progress
export const getModuleProgress = (
  data,
  userCode,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().wsModuleProgressData, data, config);
};

// Topic Services
export const createNewTopic = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().createTopicDetails, data, config);
};

export const updateTopicDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().editTopicDetails, data, config);
};

export const updateTopicStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().manageTopicReadnessStatus,
    data,
    config
  );
};

// Assessment Services

export const createNewAssesment = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().createAssesmentDetails, data, config);
};

export const fetchAssessmentList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().assessmentList, data, config);
};

export const updateAssessmentData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().editAssesmentDetails, data, config);
};

export const manageAssesmentStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().manageAssesmentStatus, data, config);
};

export const fetchAssesmentQuestionList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().listAssesmentQuestion, data, config);
};

export const addAssesmentQuestionList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addAssesmentQuestion, data, config);
};

export const fetchModuleAssessmentData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().wsModuleAssessmentFullData,
    data,
    config
  );
};

export const updateAssesmentQuestion = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().updateAssesmentQuestion,
    data,
    config
  );
};

export const fetchAssociateBasedModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().associateAssignedModule,
    data,
    config
  );
};

export const fetchAssociateSelfModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().associateSelfModule, data, config);
};

// Assign module to Associate
export const storeAssociateBasedModule = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceAPIs().storeAssoiateModuleData,
    data,
    config
  );
};
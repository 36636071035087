// Chakra React Imports
import React, { useState } from "react";
import { Text, useToast, 
	Box, Flex, Spinner, useColorModeValue, Tabs, TabList, Tab, TabPanels, TabPanel, Menu, MenuButton, Button, MenuList, MenuItem, Heading, Badge, Spacer, Icon, filter, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineAudio, AiOutlineCheckCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg, useGreenBg, useListItemBgColor, useListItemTextColor, useRedBg, useTextColor } from "../../../../../theme/globalColorTheme";
import Card from "../../../../../components/card/Card";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { fetchLocationsAndSubs, fetchSubscriberLinks, fetchSubscriberLinksCallHistory } from "../../../../../services/accountServices";
import { formatSeconds } from "../../../../../utils/TimeFunctions";
import Pagination from "../../../../../components/pagination/Pagination";
import { MdOutlinePhoneForwarded, MdOutlineVideoCall } from "react-icons/md";
import { FaMicrophoneAlt } from "react-icons/fa";
import moment from 'moment'
import LocationAndSubscribers from "../../components/LocationAndSubscribers";
import { LOC_CALL_HISTORY } from "../constants";
import LingoLinkHistory from "../../components/LingoLinkHistory";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

function LocationCallHistoryTab({ locationList, setLocationList, locationLoading }) {
	const toast = useToast()
	const cardBg = useColorModeValue("white", "#151117")
	const redBg = useRedBg();
	const greenBg = useGreenBg();
	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
	const [subsId, setSubsId] = useState(null);
	const [locationData, setLocationData] = useState({});
	const [linkLoader, setLinkLoader] = useState(false);
	const [linkData, setLinkData] = useState([]);
	const [linkUuid, setLinkUuId] = useState("");
	const [linkKey, setLinkKey] = useState(null)
	const [callHistoryList, setCallHistoryList] = useState([]);
	const [callHistoryLoader, setCallHistoryLoader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [linkreport, setLinkReport] = useState([]);
	const [billAmount, setBillAmount] = useState(0.0);
	const blackWhiteText = useTextColor()
	const [tabIndex, setTabIndex] = useState(0)
	const [linkFilter, setLinkFilter] = useState(LOC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE)
	const [listTypeFilter, setListTypeFilter] = useState(LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS)
	const [selectedFilter, setSelectedFilter] = useState(LOC_CALL_HISTORY.FILTERS.ALL)
	const [duration, setDuration] = useState(LOC_CALL_HISTORY.DURATION.TODAY)
	const [splitTabs, setSplitTabs] = useState(true)


	const blackWhiteColor = useTextColor()
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");


	useEffect(() => {
		const data = linkData.filter(item => item.uuid === linkUuid)
		if (data.length) {
			collectCallHistory(data[0])
		}
	}, [currentPage, recordsPerPage, linkData]);

	useEffect(() => {
		setCurrentPage(1)
	}, [recordsPerPage])



	const handleTabsChange = (index) => {
		setTabIndex(index)
	}

	const handleSelectedFilter = (filter) => {
		if (selectedFilter === filter) {
			setSelectedFilter(LOC_CALL_HISTORY.FILTERS.ALL)
		} else {
			setSelectedFilter(filter)
		}
	}

	// Todo:Fetch Lingo Link Details
	const collectLinkData = (locationData, subscriberData) => {
		setLinkLoader(true);
		let obj = {
			account_uuid: locationData?.link_account_uuid,
			location_uuid: locationData?.link_location_uuid,
			subscriber_uuid: subscriberData?.sub_uuid,
			link_status: linkFilter,
			duration: "all",
		};
		fetchSubscriberLinks(obj)
			.then(res => {
				setLinkLoader(false);
				if (res?.data?.[0]?.records?.length) {
					setLinkData(res?.data?.[0]?.records);
					setCallHistoryList([]);
					setLinkUuId("");
					setLinkKey(null)
				} else {
					setLinkData([]);
					setCallHistoryList([]);
				}
			})
			.catch(err => {
				if (err) {
					setLinkLoader(false);
					setLinkData([]);
					setCallHistoryList([]);
					setLocationData({});
					setLinkUuId("");
					setLinkKey(null)
				}
			});
	};

	// Todo : Collect Call History Based on Link Id
	const collectCallHistory = data => {
		if (!data?.key || !data?.uuid) {
			toast(toastFunctionToaster("Please select a Subscriber and LingoLink to get call history..", "warning"))
		} else {
			setCallHistoryLoader(true);
			let obj = {
				account_uuid: locationData?.link_account_uuid,
				location_uuid: locationData?.link_location_uuid,
				linkUuid: data?.uuid,
				linkKey: data?.key,
				page_id: currentPage,
				duration: duration,
				filter: selectedFilter,
				limit: recordsPerPage
			};

			fetchSubscriberLinksCallHistory(obj)
				.then(res => {
					setCallHistoryLoader(false);
					if (res?.data?.[0]?.sessions?.length) {
						setCallHistoryList(res?.data?.[0]?.sessions);
						setTotalPages(res?.data[0]?.total_pages)
						setLinkReport(res?.data[0]?.report)
					} else {
						setCallHistoryList([]);
						setTotalPages(0)
					}
				})
				.catch(err => {
					setCallHistoryLoader(false);
				});
		}
	};



	function onSubscriberClick(data, subData) {
		collectLinkData(data, subData);
		setSubsId(subData?.user_uuid);
		setLocationData(data);
	}

	// Todo:Render Lingo Link List
	const renderLinkData =
		linkData && linkData.length > 0 ? (
			linkData.map((data, idx) => (
				<Box
					key={idx}
					position="relative"
					onClick={() => {
						setLinkUuId(data?.uuid);
						setLinkKey(data?.key)
						collectCallHistory(data);
					}}
				>
					<Box
						p="1"
						bg={data?.uuid === linkUuid ? greenBg : null}
						cursor="pointer"
						_hover={{ bg: "blue.100", color: "black" }}
						mb="1"
						border="1px"
						borderColor="#d7d7d7"
						rounded={'lg'}
					>
						<Flex
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Text fontWeight="200">{data?.title}</Text>
							<Box
								h="5px"
								w="5px"
								rounded={'lg'}
								bg={data?.active ? "green.400" : "red.400"}
							></Box>
						</Flex>
						<Text fontSize={"xs"}>{data?.full_url}</Text>
					</Box>
				</Box>
			))
		) : (
			<Flex
				w={"100%"}
				p={5}
				bg={redBg}
				borderLeftWidth={"5px"}
				borderLeftColor={"red.500"}
			>
				<Heading w={"100%"} fontSize={"12px"} fontWeight={"normal"} color="black">
					This Subscriber does not have any LingoLinks.
				</Heading>
			</Flex>
		);


	return (
		<Flex h='calc(100vh - 180px)' p={0}>
			<Flex
				flex="1"
				bg={brandBg}
				rounded={'lg'}
				h="full"
				p={1}
				w="80%"
			>
				<Box w={!configurationCollapseFlag ? "95%" : "80%"}>
					<Card rounded={'lg'} p={0} h='calc(100vh - 193px)' w="full">
						{
							(callHistoryLoader) ? <Flex w="100%" justifyContent="center">
								<Spinner
									thickness='2px'
									speed='0.65s'
									size='xl'
								/>
							</Flex> :
								<LingoLinkHistory 
									selectedLingoLink={{ key: linkFilter, uuid: linkUuid }} 
									linkSessions={callHistoryList} listTypeFilter={listTypeFilter} 
									linkreport={linkreport} billAmount={billAmount} calculateBilling={() => { }} />
						}

					</Card>
				</Box>

				{configurationCollapseFlag ? (
					<Box
						w="20%"
						position="relative"
						rounded={'lg'}
						bg={brandBg}
						ml="2"
						h="full"
					>
						<Flex w={'100%'}
							p={1}
							h={'40px'}
							//bg={'gray.100'}
							borderBottomWidth={'0.5px'}
							borderBottomColor={'gray.300'}
						>
							{/* <Box w={'80%'}>
								<FormControl display='flex' alignItems='center' mb={1} mt="1">
									<FormLabel htmlFor='splitTabs' mb='0'>
										Split Tabs
									</FormLabel>
									<Switch id='splitTabs' value={splitTabs} onChange={() => setSplitTabs(splitTab => !splitTab)} />
								</FormControl>
							</Box> */}
							<Flex align={"center"} p={1} w="100%" justifyContent="center">
								<Box
									p={1}
									align={"center"}
									w={"45%"}
									_hover={{ cursor: "pointer" }}
									roundedLeft={"lg"}
									bg={linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "green.600" : greenBg}
									color={linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "white" : "inherit"}
									size="sm"
									onClick={() => {
										setLinkFilter(LOC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE);
									}}>
									{linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
									Active
								</Box>
								<Box
									p={1}
									align={"center"}
									w={"45%"}
									_hover={{ cursor: "pointer" }}
									roundedRight={"lg"}
									bg={linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "red.400" : redBg}
									color={linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "white" : "inherit"}
									size="sm"
									onClick={() => {
										setLinkFilter(LOC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED);
									}}>
									{linkFilter === LOC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
									Closed
								</Box>
							</Flex>
							<Box
								align={'end'}
								w={'10px'}
								position="absolute"
								top="0"
								right="0"
								mr="2"
								mt="3"
								zIndex="999"
							>
								<AiFillRightCircle
									cursor="pointer"
									size="20px"
									onClick={() => {
										setConfiguationCollapseFlag(
											!configurationCollapseFlag
										);
									}}
								/>
							</Box>
						</Flex>
						{splitTabs ?
							<Flex direction="column" p={0}>
								<Card w="full" p={1} mt="1" rounded={'lg'} h='calc(100vh - 510px)'>
									<Flex flexDir="column" w="full" alignItems="center" h="100%" overflow="hidden">
										<Flex 
											flexDir="column" overflow="auto" w="100%" h="100%" p='1' mt={2} className="custom-scrollbar"
										>
											<Flex flexDir="column" minH="100%">
												{linkLoader ? (
													<Flex
														h="50vh"
														alignItems={"center"}
														justifyContent={"center"}
													>
														<Spinner size="xl" />
													</Flex>
												) : (
													<>
														{renderLinkData}
													</>
												)}
											</Flex>
										</Flex>
									</Flex>
								</Card>
								<Card w="full" p={1} mt="1" h={'270px'} rounded={'lg'}>
									<Flex flexDir="column" w="full" alignItems="center" h="full" overflow="hidden">
										<Flex flexDir="column" w="full" alignItems="center" h="100%" overflowY="auto" className="custom-scrollbar">
											<Flex alignItems="center" w={'100%'} p={1}>
												<Box
													h="full"
													p={1}
													display="flex"
													alignItems="center"
													justifyContent="center"
													w={"50%"}
													_hover={{ cursor: "pointer" }}
													roundedLeft={"lg"}
													bg={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "green.600" : useGreenBg}
													color={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "white" : "inherit"}
													size="sm"
													fontSize="sm"
													onClick={() => {
														setListTypeFilter(LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION);
													}}>
													{listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
													Calls with Session
												</Box>
												<Box
													p={1}
													h="full"
													display="flex"
													alignItems="center"
													justifyContent="center"
													w={"50%"}
													_hover={{ cursor: "pointer" }}
													roundedRight={"lg"}
													fontSize="sm"
													bg={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "green.600" : useGreenBg}
													color={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "white" : "inherit"}
													size="sm"
													onClick={() => {
														setListTypeFilter(LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS);
													}}>
													{listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
													Calls only
												</Box>
											</Flex>
											<Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
												<Text fontWeight="bold" mb="0">Call History</Text>
												<Flex alignItems="center" p={2}>
													<Box
														h="full"
														p={1}
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														roundedLeft={"lg"}
														bg={selectedFilter === LOC_CALL_HISTORY.FILTERS.ALL ? "green.600" : useGreenBg}
														color={selectedFilter === LOC_CALL_HISTORY.FILTERS.ALL ? "white" : "inherit"}
														size="sm"
														borderLeft="1px"
														borderColor="white"
														fontSize="sm"
														onClick={() => {
															handleSelectedFilter(LOC_CALL_HISTORY.FILTERS.ALL);
														}}>
														{selectedFilter === LOC_CALL_HISTORY.FILTERS.ALL ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														All
													</Box>
													<Box
														h="full"
														p={1}
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														bg={selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? "green.600" : useGreenBg}
														color={selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? "white" : "inherit"}
														size="sm"
														borderRight="1px"
														borderColor="white"
														fontSize="sm"
														onClick={() => {
															handleSelectedFilter(LOC_CALL_HISTORY.FILTERS.FEEDBACK);
														}}>
														{selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														Feedbacks
													</Box>
													<Box
														p={1}
														h="full"
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														roundedRight={"lg"}
														fontSize="sm"
														bg={selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "green.600" : useGreenBg}
														color={selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "white" : "inherit"}
														size="sm"
														onClick={() => {
															handleSelectedFilter(LOC_CALL_HISTORY.FILTERS.TRANSCRIPT);
														}}>
														{selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														Transcripts
													</Box>
												</Flex>
											</Flex>
											<Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
												<Text fontWeight="bold" mb="0">Duration</Text>
												<Flex alignItems="center" w="100%">
													<Box
														h="full"
														p={1}
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														roundedLeft={"lg"}
														bg={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
														color={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
														size="sm"
														fontSize="sm"
														onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.TODAY) }}
													>
														{duration === LOC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

														Today
													</Box>
													<Box
														h="full"
														p={1}
														borderRight="1px"
														borderLeft="1px"
														borderColor="white"
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														bg={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
														color={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
														size="sm"
														fontSize="sm"
														onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.WEEK) }}
													>
														{duration === LOC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														This Week
													</Box>
													<Box
														p={1}
														h="full"
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"100px"}
														_hover={{ cursor: "pointer" }}
														roundedRight={"lg"}
														fontSize="sm"
														bg={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
														color={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
														size="sm"
														onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.MONTH) }}
													>
														{duration === LOC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														This Month
													</Box>
												</Flex>
											</Flex>
										</Flex>
										<Button size={'sm'} ml={1}
												onClick={() => collectCallHistory({ uuid: linkUuid, key: linkKey })}
												_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
												bg={pillButtonBg}>Get History</Button>
									</Flex>
								</Card>
							</Flex>
						 : <Box w="100%" h="90%" bg={brandBg}>
							<Tabs variant="enclosed-colored" colorScheme="green" mt="1" h="full" index={tabIndex} onChange={handleTabsChange} isFitted>
								<TabList>
									<Tab fontSize="xs" fontWeight="bold">lingolinks</Tab>
									<Tab fontSize="xs" fontWeight="bold">Filters</Tab>
								</TabList>
								<TabPanels h="full">
									<TabPanel p="0" h="full" mt="2">
										<Card
											w="full"
											h="92%"
											p="2"
											overflowY="auto"
											pt="3"

										>
											{linkLoader ? (
												<Flex
													h="50vh"
													alignItems={"center"}
													justifyContent={"center"}
												>
													<Spinner size="xl" />
												</Flex>
											) : (
												<>
													{renderLinkData}
												</>
											)}{" "}
										</Card>
									</TabPanel>
									<TabPanel p="0" h="full" mt="2">
										<Card h="92%" w="full" p={1} rounded={'lg'}>
											<Flex flexDir="column" w="full" alignItems="center" h="100%">
												<Flex alignItems="center" w={'100%'} p={1}>
													<Box
														h="full"
														p={1}
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"50%"}
														_hover={{ cursor: "pointer" }}
														roundedLeft={"lg"}
														bg={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "green.600" : useGreenBg}
														color={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "white" : "inherit"}
														size="sm"
														fontSize="sm"
														onClick={() => {
															setListTypeFilter(LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION);
														}}>
														{listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														Calls with Session
													</Box>
													<Box
														p={1}
														h="full"
														display="flex"
														alignItems="center"
														justifyContent="center"
														w={"50%"}
														_hover={{ cursor: "pointer" }}
														roundedRight={"lg"}
														fontSize="sm"
														bg={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "green.600" : useGreenBg}
														color={listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "white" : "inherit"}
														size="sm"
														onClick={() => {
															setListTypeFilter(LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS);
														}}>
														{listTypeFilter === LOC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
														Calls only
													</Box>
												</Flex>
												<Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
													<Text fontWeight="bold" mb="3">Call History</Text>
													<Flex alignItems="center" p={2}>
														<Box
															h="full"
															p={1}
															display="flex"
															alignItems="center"
															justifyContent="center"
															w={"100px"}
															_hover={{ cursor: "pointer" }}
															roundedLeft={"lg"}
															bg={selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? "green.600" : useGreenBg}
															color={selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? "white" : "inherit"}
															size="sm"
															borderRight="1px"
															borderColor="white"
															fontSize="sm"
															onClick={() => {
																handleSelectedFilter(LOC_CALL_HISTORY.FILTERS.FEEDBACK);
															}}>
															{selectedFilter === LOC_CALL_HISTORY.FILTERS.FEEDBACK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
															Feedbacks
														</Box>
														<Box
															p={1}
															h="full"
															display="flex"
															alignItems="center"
															justifyContent="center"
															w={"100px"}
															_hover={{ cursor: "pointer" }}
															roundedRight={"lg"}
															fontSize="sm"
															bg={selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "green.600" : useGreenBg}
															color={selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "white" : "inherit"}
															size="sm"
															onClick={() => {
																handleSelectedFilter(LOC_CALL_HISTORY.FILTERS.TRANSCRIPT);
															}}>
															{selectedFilter === LOC_CALL_HISTORY.FILTERS.TRANSCRIPT ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
															Transcripts
														</Box>
													</Flex>
												</Flex>
												<Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
													<Text fontWeight="bold" mb="3">Duration</Text>
													<Flex alignItems="center" w="100%">
														<Box
															h="full"
															p={1}
															display="flex"
															alignItems="center"
															justifyContent="center"
															w={"100px"}
															_hover={{ cursor: "pointer" }}
															roundedLeft={"lg"}
															bg={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
															color={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
															size="sm"
															fontSize="sm"
															onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.TODAY) }}
														>
															{duration === LOC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

															Today
														</Box>
														<Box
															h="full"
															p={1}
															borderRight="1px"
															borderLeft="1px"
															borderColor="white"
															display="flex"
															alignItems="center"
															justifyContent="center"
															w={"100px"}
															_hover={{ cursor: "pointer" }}
															bg={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
															color={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
															size="sm"
															fontSize="sm"
															onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.WEEK) }}
														>
															{duration === LOC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
															This Week
														</Box>
														<Box
															p={1}
															h="full"
															display="flex"
															alignItems="center"
															justifyContent="center"
															w={"100px"}
															_hover={{ cursor: "pointer" }}
															roundedRight={"lg"}
															fontSize="sm"
															bg={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
															color={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
															size="sm"
															onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.MONTH) }}
														>
															{duration === LOC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
															This Month
														</Box>
													</Flex>
												</Flex>
											</Flex>
											<Button size={'sm'} ml={1}
												onClick={() => collectCallHistory({ uuid: linkUuid, key: linkKey })}
												_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
												bg={pillButtonBg}>Get History</Button>
										</Card>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Box>}

					</Box>
				) : (
					<Card
						w="5%"
						p="3"
						rounded={'lg'}
						h="100%"
						ml="2"
						pl="5"
					>
						<Flex w={"100%"} h="100%" direction="column" alignItems="center">
							<AiFillLeftCircle
								cursor="pointer"
								onClick={() => {
									setConfiguationCollapseFlag(
										!configurationCollapseFlag
									);
								}}
								size="20px"
							/>
							<Text
								fontSize="16px"
								mt="2"
								css={{
									writingMode: "vertical-lr",
									textOrientation: "mixed",
								}}
							>
								Expand Report Settings
							</Text>
						</Flex>
					</Card>
				)}
			</Flex>
			<LocationAndSubscribers 
				loading={locationLoading} 
				locationList={locationList} 
				setLocationList={setLocationList} 
				subsId={subsId} 
				onClick={onSubscriberClick} />
		</Flex>
	);
}

export default LocationCallHistoryTab;

// Chakra React Imports
import React, { useState, useEffect } from "react";
import { Icon, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Spinner, Box } from "@chakra-ui/react";

// Api Call Imports
import {
	AiOutlineYoutube,
} from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";

// Components 
import LinkCustomerLinksListing from './LinkSubscriberLinksListing';
import { fetchAccLocInfo, fetchSubPersonalInfo } from "../../../services/accountServices";
import Pagination from "../../../components/pagination/Pagination";
import { INIT_PAGE, INIT_RECORDS_PER_PAGE } from "./constants";

function LinkSubscriberManage() {
	const [loading, isLoading] = useState(false)
	const [activeTab, setActiveTab] = useState(0);
	const [accountId, setAccountId] = useState("");
	const [locationId, setLocationId] = useState("");
	const [subscriberId, setSubsriberId] = useState("");
	const [subAppDetails,setSubAppDetails] = useState([]);
	const [subscriberDetails, setSubscriberDetails] = useState({})


	//Fetch Parent Account and Location details
	useEffect(() => {
		collectAccountLocationInfo();
		collectSubscriberInfo();
	}, []);

	const collectAccountLocationInfo = () => {
		isLoading(true);
		let paramObj = {
			key: 'subscriber',
		};
		fetchAccLocInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setAccountId(res?.data[0]?.accUuid)
					setLocationId(res?.data[0]?.locUuid)
					setSubsriberId(res?.data[0]?.subUuid)
					setSubscriberDetails(res?.data[0]?.subInfo[0])

				} else {
					isLoading(false);
					setAccountId("")
					setLocationId("")
					setSubsriberId("")
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountId("")
				setLocationId("")
				setSubsriberId("")
			});
	};

	const collectSubscriberInfo = () => {
		isLoading(true);
		let paramObj = {
			key: "subscriber",
		};
		fetchSubPersonalInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					if (res?.data[0]?.subInfo[0]?.apps != null){
						setSubAppDetails(res?.data[0]?.subInfo[0]?.apps);
					}
				} else {
					isLoading(false);
					setSubAppDetails([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setSubAppDetails([]);
			});
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4" >
			{loading ? (
				<Flex
					alignItems="center"
					justifyContent="center"
					h="50vh"
				>
					<Spinner size="xl" />
				</Flex>
			) : (
				<Box w={"100%"}>
					<LinkCustomerLinksListing
						accountId={accountId} 
						locationId={locationId} 
						subscriberId={subscriberId} 
						subscriberDetails={subscriberDetails} 
						subAppDetails={subAppDetails}/>
				</Box>)}
		</Flex>
	);
}

export default LinkSubscriberManage;
import React from 'react'
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

// Component Upload
import LinkUploadDetails from './LinkUploadDetails'

function LinkCustomerUpload() {
    return (
        <Accordion defaultIndex={[0]} allowToggle >
            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Subscriber Avatar
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    {/* Todo:Needs to be changes */}
                    <LinkUploadDetails />
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem >
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Subscriber Logo
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    {/* Todo:Needs to be changes */}
                    <LinkUploadDetails />
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Background Image
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    {/* Todo:Needs to be changes */}
                    <LinkUploadDetails />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default LinkCustomerUpload
import React from "react";
import {
	Button,
	Modal,
	Image,
	ModalBody,
	ModalFooter,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	ModalCloseButton,
} from "@chakra-ui/react";

function FullScreenImage({ imageValue, imageSize, rounded }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const imageUrl = imageValue;
	return (
		<>
			<Image
                borderRadius={'lg'}
				onClick={() => onOpen()}
				m={4}
                p={2}
                borderColor={'gray'} borderWidth={'2px'}
                rounded={rounded}
				src={imageValue}
				_hover={{cursor:'pointer', borderWidth:'2px', borderColor:'red', boxShadow:'green' }}>
			</Image>

			<Modal onClose={onClose} size={"full"} isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<Image 
                            onClick={onClose}
                            src={imageUrl} />
					</ModalBody>
					<ModalFooter>
						<Button size="sm" colorScheme="red" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default FullScreenImage;

import React, { useEffect, useState } from 'react'
import { applicationsList } from '../../services/profileServices'
import { Box, Button, Card, Flex, Text, useToast } from '@chakra-ui/react'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const ApplicationsList = () => {
    const [applicationList, setApplicationList] = useState([])
    const toast = useToast()
    const navigate = useNavigate()
    const handleGetApplications = () => {
        const paramObj = {
            key:"all"
        }
        applicationsList(paramObj).then(resp=>{
            if(resp?.data){
                setApplicationList(resp?.data)
            }else{
                toast(toastFunctionToaster("failed to get Application list", "error"))
            }
        }).catch(err=>{toast(toastFunctionToaster("failed to get Application list", "error"))})
    }

    useEffect(()=>{
        handleGetApplications()
    }, [])

  return (
    <Flex pt={{ sm: '125px', lg: '90px' }} pl={3} alignItems="flex-start" bg="#fff" flexDir="column">
        {applicationList ? applicationList.map((item, index)=>(
            <Card
					key={index}
					p={4}
					w={"100%"}
					mt={2}
					rounded={"md"}
					borderWidth={"0.05px"}>
                <Flex w="full" justifyContent="space-between" alignItems="center">
                    <Flex flexDir="column">
                    <Box>
                     <Text fontWeight="bold" as="span">Application Name:</Text> {item?.status?.application_name}
                    </Box>
                    <Box>
                    <Text fontWeight="bold" as="span">Application status:</Text> {item?.status?.application_progress}
                    </Box>
                    <Box>
                    <Text fontWeight="bold" as="span">Created On:</Text> {moment(item?.status?.created_at).format("MMM DD,YYYY,HH:mm a")}
                    </Box>
                    </Flex>
                    <Flex>
                        <Button colorScheme='blue' p="2" size="md" onClick={()=>
                            navigate(`/ailab/applicant/proposal/update/${item?.status?.application_id}`)
                        }>
                            Show details
                        </Button>
                    </Flex>
                    
                </Flex>
            </Card>
        )) : null}
    </Flex>
  )
}

export default ApplicationsList
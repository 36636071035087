import React, { useState } from 'react';
import { Box, Input, Text, Button, VStack } from '@chakra-ui/react';
import { INFO_TYPES } from './constants';

const ContactInfo = ({ contactList, handleEdit }) => {
  const [editableContacts, setEditableContacts] = useState(contactList);

  const handleInputChange = (e, index, field) => {
    const updatedContacts = [...editableContacts];
    updatedContacts[index][field] = e.target.value;
    setEditableContacts(updatedContacts);
  };

  return (
    <>
      {editableContacts.length > 0 ? (
        editableContacts.map((contact, index) => (
          <Box
            key={contact.id_contact_uuid}
            id={`${contact.identity_uuid}-${contact.id_contact_uuid}`}
            mt={4}
            p={4}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
          >
            <Text fontWeight="bold" mb={2}>
              Contact Information {index + 1}
            </Text>

            <VStack align="start" spacing={2}>
              <Text><strong>Home Phone:</strong></Text>
              <Input
                value={contact.id_contact_home_phone || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_home_phone')}
              />

              <Text><strong>Mobile Phone:</strong></Text>
              <Input
                value={contact.id_contact_mobile_phone || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_mobile_phone')}
              />

              <Text><strong>Business Phone:</strong></Text>
              <Input
                value={contact.id_contact_business_phone || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_business_phone')}
              />

              <Text><strong>Other Phone(s):</strong></Text>
              <Input
                value={contact.id_contact_other_phone || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_other_phone')}
              />

              <Text><strong>Primary Email:</strong></Text>
              <Input
                value={contact.id_contact_primary_email || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_primary_email')}
              />

              <Text><strong>Secondary Email(s):</strong></Text>
              <Input
                value={contact.id_contact_secondary_email || ''}
                onChange={(e) => handleInputChange(e, index, 'id_contact_secondary_email')}
              />
            </VStack>

            <Button mt={4} colorScheme="blue" onClick={() => handleEdit(INFO_TYPES.CONTACT, editableContacts[index])}>
              Save
            </Button>
          </Box>
        ))
      ) : (
        <Text mt={2}>No contact information available</Text>
      )}
    </>
  );
};

export default ContactInfo;

import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Button,
	Flex,
	HStack,
	Stack,
	Select,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useToast,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import InputField from "../../../components/fields/InputField";
import { useDispatch } from "react-redux";
import { getEmailId } from "../../../utils/localStorageIndex";
import { fetchBusinessInformation } from "../../../services/businessServices";
import { addBusinessData, updateBusinessData } from "../../../services/businessServices";
import {
	setTopNotificationMessage,
	setTopNotoficationStatusColor,
	setBusinessInfoStatus,
} from "../../../store/actions/topNotificationActions";
import { stateList } from "../../../constants/State";
import { countryList } from "../../../constants/Country";
import { useBrandBg, useGradientColor, usePillButtonBg, usePillButtonBgSelected, useTextColor } from "../../../theme/globalColorTheme";

function BusinessSettings(props) {
	const brandBg = useBrandBg();
	const gradientColor = useGradientColor();
	const pillButtonBg = usePillButtonBg();
	const pillButtonBgSelected = usePillButtonBgSelected();

	const textColor = useTextColor;
	const [activeBullets, setActiveBullets] = useState({
		user: true,
		address: false,
	});


	const textColorSecondary = "gray.400";
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

	const [currentPage, setCurrentPage] = useState("view");
	const [loading, isLoading] = useState(true);

	const userTab = React.useRef();
	const addressTab = React.useRef();

	// business logic
	const toast = useToast();
	const dispatch = useDispatch();
	const [error, setError] = useState({});
	const defaultStateChangeValue = "United States";
	const [stateValue, setStateValue] = useState(false);
	const [updateAction, setUpdateAction] = useState(false);
	const [businessInfo, setBusinessInfo] = useState({
		business_name: "",
		business_url: "",
		address_line1: "",
		address_line2: "",
		city: "",
		state: "",
		country: "",
		zip_code: 0,
		phone: "",
		business_email: "",
		business_logo_url: "",
		email: getEmailId(),
	});

	//   Todo:Fetch Business Details
	useEffect(() => {
		fetchBusinessDetails();
	}, []);

	const
		fetchBusinessDetails = () => {
			var apiData = { email: getEmailId() };
			fetchBusinessInformation(apiData)
				.then(res => {
					if (res.result && res.data[0] !== null && res.data.length) {
						let responseData = {
							business_uuid: res.data[0].business_uuid ? res.data[0].business_uuid : null,
							business_name: res.data[0].business_name,
							business_url: res.data[0].business_url,
							address_line1: res.data[0].address_line1,
							address_line2: res.data[0].address_line2,
							city: res.data[0].city,
							state: res.data[0].state,
							country: res.data[0].country,
							zip_code: res.data[0].zip_code,
							phone: res.data[0].phone,
							business_email: res.data[0].business_email,
							business_logo_url: res.data[0].business_logo_url,
							subdomain: res.data[0].subdomain,
							login_background: res.data[0].login_background,
							// email: getEmailId(),
						};
						if (
							res.data[0].country !== undefined &&
							res.data[0].country &&
							res.data[0].country === defaultStateChangeValue
						) {
							setStateValue(true);
						}
						res.data[0].business_uuid ? setUpdateAction(true) : setUpdateAction(false);
						isLoading(false);
						dispatch(setTopNotificationMessage(res?.message));
						dispatch(setTopNotoficationStatusColor("green"));
						setBusinessInfo(responseData);
						props?.businessDetails(responseData)
						// setBusinessInfo(res.data[0]);
					} else {
						isLoading(false);
						dispatch(setTopNotificationMessage(res?.message));
						dispatch(setBusinessInfoStatus(false));
						dispatch(setTopNotoficationStatusColor("red"));
					}
				})
				.catch(err => {
					isLoading(false);
					if (err) {
						dispatch(setTopNotificationMessage(err?.message));
						dispatch(setTopNotoficationStatusColor("red"));
					}
				});
		};

	const reloadAfterUpload = () => {
		fetchBusinessDetails();
		setCurrentPage("view");
	};

	//   Todo:Set Form Values
	const onUpdateField = e => {
		const nextFormState = {
			...businessInfo,
			[e.target.name]: e.target.value,
		};

		setBusinessInfo(nextFormState);
	};

	const validateFieldForm = () => {
		let fields = businessInfo;
		let errors = {};
		let formIsValid = true;
		if (!fields["business_name"]) {
			formIsValid = false;
			errors["business_name"] = "*Business Name is required.";
			userTab.current.click();
		}
		setError(errors);
		return formIsValid;
	};

	//   Todo:Render Country list Data
	const renderCountryListData = countryList?.map((countryData, index) => (
		<option key={countryData.code} value={countryData.name} style={{ color: "black" }}>
			{countryData.name}
		</option>
	));

	//   Todo:Render State list Data
	const renderStateListData = stateList?.map((state, index) => (
		<option key={state.code} value={state.name} style={{ color: "black" }}>
			{state.name}
		</option>
	));

	// Todo:receive selected data from google location
	const handleSelectedLocation = data => {
		setBusinessInfo(prevBusinessInfo => ({
			...prevBusinessInfo,
			address_line1: data,
		}));
	};

	const resetStateValue = e => {
		if (e.target.value === defaultStateChangeValue) {
			setStateValue(true);
		} else {
			setStateValue(false);
		}
	};

	const submitBusinessData = event => {
		event.preventDefault();
		if (validateFieldForm()) {
			isLoading(true);
			if (businessInfo && businessInfo.business_uuid !== null && updateAction) {
				updateBusinessData(businessInfo)
					.then(res => {
						isLoading(false);
						if (res.result) {
							dispatch(setTopNotificationMessage(res?.message));
							dispatch(setTopNotoficationStatusColor("green"));
							toast({
								title: res.message,
								status: "success",
								isClosable: true,
								duration: 3000,
							});
							fetchBusinessDetails();
							// props.onDataUpdate();
							// navigate('/business/information/BusinessDetails');
						} else {
							dispatch(setTopNotificationMessage(res?.message));
							dispatch(setTopNotoficationStatusColor("red"));
							toast({
								title: res.message,
								status: "error",
								isClosable: true,
								duration: 3000,
							});
						}
					})
					.catch(err => {
						isLoading(false);
						if (err) dispatch(setTopNotificationMessage(err?.response?.data?.detail));
						dispatch(setTopNotoficationStatusColor("red"));
					});
			} else {
				addBusinessData(businessInfo)
					.then(res => {
						isLoading(false);
						if (res.result) {
							toast({
								title: res.message,
								status: "success",
								isClosable: true,
								duration: 3000,
							});
							dispatch(setTopNotificationMessage(res?.message));
							dispatch(setTopNotoficationStatusColor("green"));
							// navigate('/business/information/BusinessDetails');
							// > '/business/dashboard/'
						} else {
							dispatch(setTopNotificationMessage(res?.message));
							dispatch(setTopNotoficationStatusColor("red"));
							toast({
								title: res.message,
								status: "error",
								isClosable: true,
								duration: 3000,
							});
						}
					})
					.catch(err => {
						isLoading(false);
						if (err) dispatch(setTopNotificationMessage(err?.response?.data?.detail));
						dispatch(setTopNotoficationStatusColor("red"));
					});
			}
		}
	};

	return (
		<Box w="100%" h="70vh" position="relative" p="3" borderRadius="15px" bg={brandBg} ml="2" overflow={'hidden'} overflowY={'scroll'}>
			<Card w={"100%"} py={1} rounded={"lg"}>
				<Flex w={"100%"} justifyContent={"end"} my={1}>
					<HStack
						w={"100px"}
						borderTopLeftRadius={"10px"}
						borderBottomLeftRadius={"10px"}
						_hover={{ cursor: "pointer" }}
						onClick={() => setCurrentPage("view")}
						bg={currentPage === "view" ? pillButtonBgSelected : pillButtonBg}
						boxShadow={currentPage === "view" ? "md" : ""}
						h={"40px"}
						m={0}
						px={2}
						fontSize={"18px"}>
						<Text w="100%" textAlign="center">
							View
						</Text>
					</HStack>
					<HStack
						w={"100px"}
						bg={currentPage === "edit" ? pillButtonBgSelected : pillButtonBg}
						borderTopRightRadius={"10px"}
						borderBottomRightRadius={"10px"}
						_hover={{ cursor: "pointer" }}
						onClick={() => setCurrentPage("edit")}
						boxShadow={currentPage === "edit" ? "md" : ""}
						h={"40px"}
						px={2}
						fontSize={"18px"}>
						<Text w="100%" textAlign="center">
							Edit
						</Text>
					</HStack>
				</Flex>
			</Card>
			{currentPage === "view" ? (
				<Box w={"100%"} mt={1}>
					<Card w={"100%"}>
						<Flex direction="column" minH="100vh" align="center" position="relative" overflow="hidden">
							<Card mb={{ base: "0px", lg: "20px" }} alignItems="center">
								<Box
									bgGradient={gradientColor}
									//bgGradient="linear(to-b, blue.100, blue.300)"
									bgSize="cover"
									borderRadius="16px"
									h="131px"
									w="100%"
								/>
								<Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
									{businessInfo.business_name}
								</Text>
								<Text color={textColorSecondary} fontSize="sm">
									{businessInfo.business_url}
								</Text>
								<Text color={textColorSecondary} fontSize="md">
									{businessInfo.business_email}
								</Text>
								<Text color={textColorSecondary} fontSize="sm">
									{businessInfo.phone}
								</Text>
								<Text color={textColorSecondary} fontSize="sm">
									{/* Date Joined: <b>{moment(join_date).format("MMM DD, YYYY HH:mm")}</b> */}
								</Text>
								{businessInfo.country != null ? (
									<Box m={5} border={"gray.100"}>
										<Text fontSize={"sm"}>{businessInfo.address_line1}</Text>
										<Text fontSize={"sm"}>{businessInfo.address_line2}</Text>
										<Text fontSize={"sm"}>
											{businessInfo?.city}, {businessInfo?.state}, {businessInfo?.zip_code}
										</Text>
									</Box>
								) : null}
								<Flex w="max-content" mx="auto" mt="26px"></Flex>
							</Card>
						</Flex>
					</Card>
				</Box>
			) : null}
			{currentPage === "edit" ? (
				<Box w={"100%"} p={3}>
					<Card w={'100%'}>
						<Flex direction="column" minH="100vh" align="center" position="relative" overflow="hidden">
							<Box
								h="40vh"
								bgGradient={gradientColor}
								position="absolute"
								w="90%"
								borderRadius="20px"
							/>
							<Tabs
								w={"100%"}
								variant="unstyled"
								zIndex="0"
								mt={{ base: "60px", md: "65px" }}
								display="flex"
								flexDirection="column">
								<TabList display="flex" alignItems="center" alignSelf="center" justifySelf="center">
									<Tab
										_focus={{ border: "0px", boxShadow: "unset" }}
										ref={userTab}
										w={{ sm: "120px", md: "250px", lg: "800px" }}
										onClick={() =>
											setActiveBullets({
												user: true,
												address: false,
												// profile: false,
											})
										}>
										<Flex
											direction="column"
											justify="center"
											align="center"
											position="relative"
											_before={{
												content: "''",
												width: { sm: "120px", md: "250px", lg: "800px" },
												height: "3px",
												bg: activeBullets.address ? "white" : "brand.400",
												left: { sm: "12px", md: "30px" },
												top: {
													sm: activeBullets.user ? "6px" : "4px",
													md: null,
												},
												position: "absolute",
												bottom: activeBullets.user ? "40px" : "38px",

												transition: "all .3s ease",
											}}>
											<Box
												zIndex="1"
												border="2px solid"
												borderColor={activeBullets.user ? "white" : "brand.400"}
												bgGradient="linear(to-b, brand.400, brand.600)"
												w="16px"
												h="16px"
												mb="8px"
												borderRadius="50%"
											/>
											<Text
												color={activeBullets.user ? "white" : "gray.300"}
												fontWeight={activeBullets.user ? "bold" : "normal"}
												display={{ sm: "none", md: "block" }}>
												User Info
											</Text>
										</Flex>
									</Tab>
									<Tab
										_focus={{ border: "0px", boxShadow: "unset" }}
										ref={addressTab}
										w={{ sm: "120px", md: "250px", lg: "800px" }}
										onClick={() =>
											setActiveBullets({
												user: true,
												address: true,
												// profile: false,
											})
										}>
										<Flex
											direction="column"
											justify="center"
											align="center"
											position="relative"
											_before={{
												content: "''",
												// width: { sm: '120px', md: '250px', lg: '300px' },
												height: "3px",
												bg: activeBullets.profile ? "white" : "brand.400",
												left: { sm: "12px", md: "32px" },
												top: "6px",
												position: "absolute",
												bottom: activeBullets.address ? "40px" : "38px",

												transition: "all .3s ease",
											}}>
											<Box
												zIndex="1"
												border="2px solid"
												borderColor={activeBullets.address ? "white" : "brand.400"}
												bgGradient="linear(to-b, brand.400, brand.600)"
												w="16px"
												h="16px"
												mb="8px"
												borderRadius="50%"
											/>
											<Text
												color={activeBullets.address ? "white" : "gray.300"}
												fontWeight={activeBullets.address ? "bold" : "normal"}
												display={{ sm: "none", md: "block" }}>
												Address
											</Text>
										</Flex>
									</Tab>
								</TabList>
								<TabPanels
									mt="24px"
									maxW={{ md: "90%", lg: "100%" }}
									mx="auto"
									mb="25px"
									borderRadius="20px">
									<TabPanel w={{ sm: "330px", md: "400px", lg: "700px" }} p="0px" mx="auto">
										<Card p="30px">
											<Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
												Business Details
											</Text>
											<Flex direction="column" w="100%">
												<Stack direction="column" spacing="20px">
													{/* <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px"> */}
													<InputField
														w="100%"
														mb="0px"
														id="business_name"
														placeholder="Enter Business Name"
														label="Business Name"
														value={businessInfo.business_name}
														name="business_name"
														onChange={onUpdateField}
													/>
													{error?.business_name ? (
														<Text fontSize="sm" color="red" mb="10px">
															{error?.business_name}
														</Text>
													) : null}
													{/* <InputField
													mb="0px"
													id="business_url"
													placeholder="Enter Business Url"
													label="Business Url"
													value={businessInfo.business_url}
													name="business_url"
													onChange={onUpdateField}
												/> */}
													<InputField
														mb="0px"
														id="business_email_id"
														placeholder="Enter Business Email Id"
														label="Business Email Id"
														value={businessInfo.business_email}
														name="business_email"
														onChange={onUpdateField}
													/>
													<InputField
														mb="0px"
														id="phone_number"
														placeholder="Enter Phone Number"
														label="Phone Number:"
														value={businessInfo.phone}
														name="phone"
														onChange={onUpdateField}
													/>
													{/* </SimpleGrid> */}
												</Stack>
												<Flex justify="space-between" mt="24px">
													<Button
														//variant="darkBrand"
														bg={pillButtonBg}
														fontSize="sm"
														borderRadius="16px"
														w={{ base: "128px", md: "148px" }}
														h="46px"
														ms="auto"
														onClick={() => addressTab.current.click()}>
														Next
													</Button>
												</Flex>
											</Flex>
										</Card>
									</TabPanel>
									<TabPanel w={{ sm: "330px", md: "400px", lg: "700px" }} p="0px" mx="auto">
										<Card p="30px">
											<Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
												Address
											</Text>
											<Flex direction="column" w="100%">
												<Stack direction="column" spacing="20px" mb="20px">
													<InputField
														mb="0px"
														id="add1"
														placeholder="eg. 1245 First Street,"
														label="Address Line 1"
														value={businessInfo.address_line1}
														name="address_line1"
														onChange={onUpdateField}
													/>
													{/* <GoogleLocation
									sendSelectedLocation={handleSelectedLocation}
									locationValue={businessInfo.address_line1}
									width={'100%'}
									borderRadius={'16px'}
									placeholder={'Enter Address Line 1'}
									border={'1px solid #e0e5f2'}
									bg="none"
								></GoogleLocation> */}

													<InputField
														mb="0px"
														id="add2"
														placeholder="eg. Apartment, Floor"
														label="Address Line 2"
														value={businessInfo.address_line2}
														name="address_line2"
														onChange={onUpdateField}
													/>
													<Text
														fontSize="sm"
														color={textColor}
														fontWeight="bold"
														_hover={{ cursor: "pointer" }}
														mb="2">
														Country
													</Text>

													<Select
														value={businessInfo.country}
														name="country"
														placeholder="Select Country"
														size="md"
														color={textColor}
														borderRadius="16px"
														onChange={e => {
															onUpdateField(e);
															resetStateValue(e);
														}}>
														{renderCountryListData}
													</Select>
													<Text
														fontSize="sm"
														color={textColor}
														fontWeight="bold"
														_hover={{ cursor: "pointer" }}
														mb="2">
														State
													</Text>

													{stateValue ? (
														<Select
															value={businessInfo.state}
															name="state"
															placeholder="Select State"
															size="md"
															color={textColor}
															borderRadius="16px"
															onChange={onUpdateField}>
															{renderStateListData}
														</Select>
													) : (
														<InputField
															placeholder="Enter State"
															value={businessInfo.state}
															name="state"
															onChange={onUpdateField}
															_placeholder={{ color: "white" }}
														/>
													)}

													<InputField
														mb="0px"
														id="city"
														placeholder="City"
														label="City"
														value={businessInfo.city}
														name="city"
														onChange={onUpdateField}
													/>
													<InputField
														mb="0px"
														id="zip_code"
														placeholder="Zipcode"
														label="Zipcode"
														value={businessInfo.zip_code}
														name="zip_code"
														onChange={onUpdateField}
													/>
												</Stack>
												<Flex justify="space-between">
													<Button
														variant="light"
														fontSize="sm"
														borderRadius="16px"
														w={{ base: "128px", md: "148px" }}
														h="46px"
														onClick={() => userTab.current.click()}>
														Prev
													</Button>
													<Button
														variant="darkBrand"
														fontSize="sm"
														borderRadius="16px"
														w={{ base: "128px", md: "148px" }}
														h="46px"
														ms="auto"
														onClick={e => {
															submitBusinessData(e);
														}}
														isDisabled={loading ? true : false}
														isLoading={loading ? true : false}
													// onClick={() => userTab.current.click()}
													>
														Update Business
													</Button>
												</Flex>
											</Flex>
										</Card>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Flex>
					</Card>
					{/* Custom Subdomain */}
					{/* <CustomBusinessSubdomain 
						subdomain={businessInfo?.subdomain}
						uploadCompleted={reloadAfterUpload}
					/> */}
					{/* {businessInfo?.subdomain ? (
						<Card w={"100%"} mt={5}>
							<Flex w={'100%'} direction={'column'}>
								<Text my={5}>Your have already setup ypur subdomain as below:</Text>
								<Flex w={'100%'} align={'center'}>
									<Text my={5} ml={2} fontWeight={500} rounded={'md'}
										p={2} bg={'blue.100'}>{businessInfo?.subdomain}</Text>
									<Spacer />
									<Button 
										onClick={() => removeSubdomain(businessInfo?.subdomain)}
										size={'md'} bg={'red.100'}>
										Delete Subdomain
									</Button>
								</Flex>
								<Text mt={5} fontSize={'xs'}>To Change your subdomain again, please contact support.</Text>
							</Flex>
						</Card>
					) : <Card w={"100%"}  mt={5}>
						<CustomBusinessSubdomain 
							subdoman={businessInfo?.subdomain}
							uploadCompleted={reloadAfterUpload}
						/>
					</Card>} */}
					{/* </SimpleGrid> */}
				</Box>
			) : null}
		</Box>
	);
}

export default BusinessSettings;

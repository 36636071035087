import React from "react";
import { Flex } from "@chakra-ui/react";
import PersonalInfo from "./PersonalInfo";

function BusinessProfile() {
  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
      <PersonalInfo />
    </Flex>
  );
}

export default BusinessProfile;

import { useColorModeValue } from "@chakra-ui/react";

export const usePillButtonBg = () => {
	return useColorModeValue("blue.100", "gray.600");
};

export const usePillButtonBgSelected = () => {
	return useColorModeValue("blue.400", "red.500");
};

export const useTextColor = () => {
	return useColorModeValue("gray.800", "white");
};

export const useBlueBorder = () => {
	return useColorModeValue("blue.200", "blue.600");
};

export const useStatisticsPillBorderColor = () => {
	return useColorModeValue("blue.100", "gray.600");
};

export const usePillHoverBgColor = () => {
	return useColorModeValue("blue.100", "gray.600");
};

export const useGrayTextColor = () => {
	return useColorModeValue("gray.500", "gray.700");
};

export const useGray500TextColor = () => {
	return useColorModeValue("gray.500", "gray.500");
};

export const useGradientColor = () => {
	return useColorModeValue("linear(to-b, blue.100, blue.300)", "linear(to-b, gray.100, gray.600)");
};

export const useBrandBg = () => {
	return useColorModeValue("blue.50", "navy.900");
};

export const useBlackWhiteBg = () => {
	return useColorModeValue("white", "black");
};

export const useGrayShadeCardBg = () => {
	return useColorModeValue("gray.100", "gray.800");
};
export const useGrayShadeCardBorderBg = () => {
	return useColorModeValue("blue.100", "gray.600");
};

export const useCardGradientTheme = () => {
	return useColorModeValue("linear(to-b, brand.400, brand.600)");
};

export const useBlueButton = () => {
	return useColorModeValue("blue.100", "blue.600");
};

export const useRedButton = () => {
	return useColorModeValue("red.200", "red.300");
};

export const useGreenButton = () => {
	return useColorModeValue("green.300", "green.600");
};

export const useYellowButton = () => {
	return useColorModeValue("yellow.300", "yellow.600");
};

export const useWhiteAlphaBg = () => {
	return useColorModeValue("white", "whiteAlpha.400");
};

export const useBlueBg = () => {
	return useColorModeValue("blue.100", "blue.100");
};

export const useRedBg = () => {
	return useColorModeValue("red.100", "red.100");
};

export const useGreenBg = () => {
	return useColorModeValue("green.200", "green.500");
};

export const useHOrange = () => {
	return useColorModeValue("horizonOrange.200", "horizonOrange.500");
};

export const useListItemHoverBg = () => {
	return useColorModeValue("blue.50", "gray.600");
};

export const useNoDataBandBg = () => {
	return useColorModeValue("blue.100", "gray.700");
};

export const useNoResultFoundText = () => {
	return useColorModeValue("black", "black");
};

export const useListItemPillHoverBg = () => {
	return useColorModeValue("blue.200", "gray.800");
};

export const useCardHoverBg = () => {
	return useColorModeValue("blue.100", "gray.700");
};

export const useCardTextHover = () => {
	return useColorModeValue("grey.500", "gray.300");
};

export const useBoxHoverHighlight = () => {
	return useColorModeValue("dark-lg", "outline");
};

export const usePillButtonTextColor = () => {
	return useColorModeValue("secondaryGray.700", "white");
};

export const useDummyBlueButtonTextColor = () => {
	return useColorModeValue("gray.700", "black");
};

export const usePillButtonBgHover = () => {
	return useColorModeValue("blue.400", "red.200");
};

export const useListItemBgColor = () => {
	return useColorModeValue("#f2f2f2", "#120303");
};

export const useListItemTextColor = () => {
	return useColorModeValue("black", "white");
};

export const usePillButtonTextColorSelected = () => {
	return useColorModeValue("blue.300", "#2d0707");
};

export const usePillButtonTextColorHover = () => {
	return useColorModeValue("secondaryGray.200", "gray.700");
};

//used in business-AccountsListing
export const useBusinessAccountTypeText = () => {
	return useColorModeValue("gray.500", "white");
};

export const useBusinessAccountTypeSwitch = () => {
	return useColorModeValue("green", "blue");
};

export const useBusinessAccountTypeBg = () => {
	return useColorModeValue("blue.100", "whiteAlpha.300");
};
export const useBusinessAccountTypeCardIcon = () => {
	return useColorModeValue("grey.600", "black");
};

export const usePillButtonHighLight = () => {
	return "blue.300", "gray.100";
};

//used in BusinessDashbord
export const useMetricsIconBgColor = () => {
	return useColorModeValue("secondaryGray.300", "whiteAlpha.100");
};

export const useMetricsIconColorAndPillBg = () => {
	return useColorModeValue("brand.500", "white");
};

export const useCardHeaderNonActiveBg = () => {
	return useColorModeValue("gray.200", "#444040");
};

export const useCardHeaderActiveBg = () => {
	return useColorModeValue("gray.200", "#37413c");
};

export const useWhiteDarkBg = () => {
	return useColorModeValue("white", "#2d3748");
};

export const useWhiteLightdarkBg = () => {
	return useColorModeValue("white", "black");
};

export const useLinkColor = () => {
	return useColorModeValue("blue.600", "blue.300");
};

export const useGreyBorderColor = () => {
	return useColorModeValue("#e2e8f0", "#48494b");
};
//unused

//LinkSessions and LinkCalls
// const textColor = useColorModeValue("secondaryGray.900", "white");
// const textColorSecondary = "gray.400";
// const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
// const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
// const textColorBrand = useColorModeValue("brand.500", "white");

// React Chakra Imports
import React, { useRef } from "react";
import { AiOutlineSearch, AiFillCheckCircle } from "react-icons/ai";
import {
	Box,
	Flex,
	Text,
	Icon,
	Stack,
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";

function TrustControl() {
	const targetRefs = useRef([]);
	const trustControlData = [
		{
			label: "Infrastructure security",
			title: [
				{
					subTitle: "Encryption key access restricted",
					subContent:
						"The company restricts privileged access to encryption keys to authorized users with a business need.",
				},
				{
					subTitle: "Unique account authentication enforced",
					subContent:
						"The company requires authentication to systems and applications to use unique username and password or authorized Secure Socket Shell (SSH) keys.",
				},
				{
					subTitle: "Production application access restricted",
					subContent:
						"System access restricted to authorized access only",
				},
			],
		},
		{
			label: "Organizational security",
			title: [
				{
					subTitle: "Asset disposal procedures utilized",
					subContent:
						"The company has electronic media containing confidential information purged or destroyed in accordance with best practices, and certificates of destruction are issued for each device destroyed.",
				},
				{
					subTitle: "Production inventory maintained",
					subContent:
						"The company maintains a formal inventory of production system assets.",
				},
				{
					subTitle: "Portable media encrypted",
					subContent:
						"The company encrypts portable and removable media devices when used.",
				},
			],
		},
		{
			label: "Product security",
			title: [
				{
					subTitle: "Data encryption utilized",
					subContent:
						"The company's datastores housing sensitive customer data are encrypted at rest.",
				},
				{
					subTitle: "Control self-assessments conducted",
					subContent:
						"The company performs control self-assessments at least annually to gain assurance that controls are in place and operating effectively. Corrective actions are taken based on relevant findings. If the company has committed to an SLA for a finding, the corrective action is completed within that SLA.",
				},
				{
					subTitle: "Penetration testing performed",
					subContent:
						"The company's penetration testing is performed at least annually. A remediation plan is developed and changes are implemented to remediate vulnerabilities in accordance with SLAs.",
				},
			],
		},
		{
			label: "Internal security procedures",
			title: [
				{
					subTitle:
						"Continuity and Disaster Recovery plans established",
					subContent:
						"The company has Business Continuity and Disaster Recovery Plans in place that outline communication plans in order to maintain information security continuity in the event of the unavailability of key personnel.",
				},
				{
					subTitle: "Continuity and disaster recovery plans tested",
					subContent:
						"The company has a documented business continuity/disaster recovery (BC/DR) plan and tests it at least annually.",
				},
				{
					subTitle: "Cybersecurity insurance maintained",
					subContent:
						"The company maintains cybersecurity insurance to mitigate the financial impact of business disruptions.",
				},
			],
		},
		{
			label: "Data and privacy",
			title: [
				{
					subTitle: "Data retention procedures established",
					subContent:
						"The company has formal retention and disposal procedures in place to guide the secure retention and disposal of company and customer data.",
				},
				{
					subTitle: "Customer data deleted upon leaving",
					subContent:
						"The company purges or removes customer data containing confidential information from the application environment, in accordance with best practices, when customers leave the service.",
				},
				{
					subTitle: "Data classification policy established",
					subContent:
						"The company has a data classification policy in place to help ensure that confidential data is properly secured and restricted to authorized personnel.",
				},
			],
		},
	];
	const securityTopics = [
		"Infrastructure security",
		"Organizational security",
		"Product security",
		"Internal security procedures",
		"Data and privacy",
	];

	const scrollToTarget = index => {
		targetRefs.current[index].scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	const renderTrustControlData = () => {
		return (
			<>
				{trustControlData?.map((data, index) => (
					<Box
						mt={index === 0 ? "0" : "3"}
						key={data?.label}
						id={data?.label}
						ref={ref => (targetRefs.current[index] = ref)}
					>
						<Text mr="3" fontSize={"18px"} fontWeight="500">
							{data?.label}
						</Text>
						<Box borderRadius={"5px"} borderWidth={"1px"} mt="3">
							<Flex justifyContent={"space-between"} p="10px">
								<Text>Control</Text>
								<Text>Status</Text>
							</Flex>
							{data?.title?.map((subData, subIndex) => (
								<Flex
									justifyContent={"space-between"}
									alignItems={"center"}
									p="10px"
									borderTop={"1px"}
									borderColor={"#d7d7d7"}
									key={subIndex}
								>
									<Box>
										<Text
											fontSize={"18px"}
											fontWeight={"500"}
										>
											{subData?.subTitle}
										</Text>
										<Text>{subData?.subContent}</Text>
									</Box>
									<Icon
										as={AiFillCheckCircle}
										color={"green.500"}
									/>
								</Flex>
							))}
						</Box>
					</Box>
				))}
			</>
		);
	};

	return (
		<Box>
			<Flex alignItems={"center"} justifyContent={"space-between"} my="6">
				<Text mr="3" fontSize={"18px"} fontWeight="500">
					Controls
				</Text>
				<Stack spacing={4} w="30%" alignItems={"center"}>
					<InputGroup>
						<InputLeftElement pointerEvents="none">
							<Icon
								mt="-1"
								as={AiOutlineSearch}
								color="gray.300"
							/>
						</InputLeftElement>
						<Input
							size="sm"
							type="text"
							placeholder="Search Controls"
						/>
					</InputGroup>
				</Stack>
			</Flex>
			<Flex justifyContent="space-between">
				<Box w="20%">
					{securityTopics.map((text, index) => (
						<Text
							key={index}
							mb="3"
							cursor="pointer"
							onClick={() => scrollToTarget(index)}
							fontWeight="500"
							fontSize="18px"
						>
							{text}
						</Text>
					))}
				</Box>
				<Box w="75%">{renderTrustControlData()}</Box>
			</Flex>
		</Box>
	);
}

export default TrustControl;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Spacer,
    Flex,
    Progress,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Icon,
    Text,
    Alert,
    Button,
    Spinner,
    AlertIcon,
    Avatar,
    useToast,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import Card from "../../../components/card/Card";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";

import { translationLanguages } from '../../../utils/TranslationLanguages';
import { transcriptionLanguages } from '../../../utils/TranscriptionLanguage';


import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from 'react-audio-player';

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { adminAutoJobsProgress } from "../../../services/adminServices";

function AdminJobProgress() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { colorMode } = useColorMode();
    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

    const [loading, isLoading] = useState(true);
    const [jobsList, setJobsList] = useState([]);

    useEffect(() => {
        fetchAllJobs();
    }, []);

    const fetchAllJobs = () => {
        isLoading(true);
        let objData = {
            'job_status': '',
            'filter_key': '',
            'filter_value': '',
            'page_id': 0,
            'records_per_page': 10
        };
        adminAutoJobsProgress(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setJobsList(res?.data[0]?.jobs);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
                isLoading(false);
          });
    };


    const renderJobProgressList = jobsList !== null && jobsList?.length
    ? jobsList?.map((item, index) => (
        <Flex key={index} w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Box minW={'150px'} fontSize={'sm'}>
                <Text><b>{item?.code}</b></Text>
                <Text>{moment(item?.created).format("MMM DD,YYYY,HH:mm a")}</Text>
            </Box>
            <Box minW={'150px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2} fontSize={'sm'}>
                {item?.status === 'failed'
                ?<Text mt={2} color={'red.500'} fontSize={'xs'}>{item?.status}</Text>
                :(item?.status === 'completed' 
                    ?<Text mt={2} color={'green.600'} fontSize={'xs'}>{item?.status}</Text>
                    :<Text mt={2} color={'blue.400'} fontSize={'xs'}>{item?.status}</Text>
                ) }
            </Box>
            <Box minW={'50px'}borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2} fontSize={'sm'}>
                <Text mt={2} color={'blue.400'} fontSize={'xs'}>{item?.counter}</Text>
            </Box>
            <Box w={'100%'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                {/* {item?.code === 'Translation'
                ?<Flex w={'100%'}>
                    {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.languages))}
                </Flex>:null}
                {item?.code === 'Transcription'
                ?<Flex w={'100%'}>
                    {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.languages))}
                </Flex>:null} */}
                <Text mt={2} color={'blue.400'} fontSize={'xs'}>{item?.email}</Text>
                <Text mt={2} color={'blue.400'} fontSize={'xs'}>{item?.key}</Text>
            </Box>
            <Box minW={'100px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                {item?.completed ? (
                    <Text color={'blue.200'}>Job Completed</Text>
                ) : item?.started && item?.completed === false ? (
                    <Text color={'green.300'}>Job Started</Text>
                ) : ( 
                    <Text color={'red.300'}>Not Started</Text>
                )}
            </Box>
            {/* <Box minW={'150px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                {item?.completed ?
                ( <Button 
                    onClick={() => {
                        navigate("/admin/workflowjobs/perform", {
                        state: {
                            workflowUuid: item?.wuuid,
                            projectUuid: item?.puuid,
                            contentUuid: item?.cuuid
                        },
                        })
                    }}
                    size={'sm'} bg={'green.100'}>
                    View Results
                </Button> ) : (
                    <>
                    {item?.started
                    ?<Button 
                        onClick={() => {
                            navigate("/admin/workflowjobs/perform", {
                            state: {
                                workflowUuid: item?.wuuid,
                                projectUuid: item?.puuid,
                                contentUuid: item?.cuuid
                            },
                            })
                        }}
                        size={'sm'} color={'gray.600'} bg={'blue.200'}>
                        Check Job Status
                    </Button>
                    :<Button 
                        onClick={() => {
                            navigate("/admin/workflowjobs/perform", {
                            state: {
                                workflowUuid: item?.wuuid,
                                projectUuid: item?.puuid,
                                contentUuid: item?.cuuid
                            },
                            })
                        }}
                        size={'sm'} color={'gray.600'} bg={'blue.200'}>
                        Start Job
                    </Button>}
                    </>
                )}
            </Box> */}
        </Flex>
        ))
    : null;

    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card direction="column" mt="5" borderWidth="0.5px">
                <Flex w={'100%'}>
                    <Text>Workflow Jobs Progress</Text>
                    <Spacer />
                    <Button 
                        bg={'blue.200'}
                        onClick={() => {
                            fetchAllJobs();
                        }}
                        size={'sm'}>Refresh</Button>

                    {/* <Button 
                        bg={jobsType === 'Active' ? 'blue.200' : 'gray.200'}
                        onClick={() => {
                            setJobsType('Active');
                            fetchContentWorkflows('Active');
                        }}
                        size={'sm'}>Active & Ready Jobs</Button>
                    <Button 
                        bg={jobsType === 'Completed' ? 'blue.200' : 'gray.200'}
                        onClick={() => {
                            setJobsType('Completed');
                            fetchContentWorkflows('Completed');
                        }}
                        size={'sm'} ml={1}>Completed Jobs</Button> */}
                </Flex>
            </Card>
            {loading ? (
                <Flex alignItems="center" justifyContent="center" h="60vh">
                <Spinner size="lg" />
                </Flex>
            ) : (
                <>
                {jobsList !== null && jobsList?.length ? (
                    <Card w={'100%'}>
                        {renderJobProgressList}
                    </Card>
                ) : (
                    <Card w={'100%'}>
                        <Text p="2" fontWeight={"500"}>
                        No Record Found
                        </Text>
                    </Card>
                )}
                </>
            )}
        </Box>
        </>
    );
}

export default AdminJobProgress;


import React, { useState } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useBlueButton, useListItemHoverBg } from '../../../theme/globalColorTheme';

function CardAccessCode({code}) {
  const [isBlurred, setIsBlurred] = useState(true);
  const blueColor = useBlueButton()
  const blueGrayColor = useListItemHoverBg()
  const toggleBlur = () => {
    setIsBlurred(!isBlurred);
  };

  return (
    <Flex alignItems="center" gap="2"  p={3} border="1px" borderColor="green.200" borderRadius="lg" bg="green.100">
      <Button onClick={toggleBlur} bg={blueColor} size={"sm"} _hover={{bg:blueGrayColor}}>
        {isBlurred ? 'Show Code' : 'Hide Code'}
      </Button>
      <Text
        fontSize="lg"
        fontWeight="bold"
        transition="filter 0.3s ease"
        color="black"
        filter={isBlurred ? 'blur(5px)' : 'none'}
      >
        {code}
      </Text>
    </Flex>
  );
}

export default CardAccessCode;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { saveSessionId, saveEmailId, saveAuthToken, savegroupId } from "../../../utils/localStorageIndex";
import { login, getToken, getPageConfig, googleSignOn } from "../../../services/sharedServices";
import {ReactComponent as LoginIcon} from '../../../assets/svg/googleIcon-48.svg'


// Chakra imports
import {
	Box,
	Spacer,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	useToast,
	InputGroup,
	InputRightElement,
	useColorModeValue,
	Text,
	Image,
} from "@chakra-ui/react";
// Assets
import { FcGoogle } from "react-icons/fc";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import CenteredAuth from "../../../layouts/auth/types/Centered";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function LspLogin() {
	// Defined variables
	const toast = useToast();
	const navigate = useNavigate();

	const [pageLogo, setPageLogo] = React.useState(null);
	const [pageBackgound, setPageBackground] = React.useState(null);

	const [error, setError] = React.useState({});
	const [loading, isLoading] = React.useState(false);
	const [loginForm, setLoginForm] = React.useState({
		email: "",
		password: "",
	});

	const [name, setName] = useState(null)
	const [version, setVersion] = useState(null)

	const pageBackgroundImages = [
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
	]

	const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;
	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
	const buttonBg = useColorModeValue("blue.200", "gray.600");
	const googleText = useColorModeValue("navy.700", "white");
	const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.300" });
	const googleActive = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.200" });

	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);

	//   Todo:Fetch Business Details
	useEffect(() => {
		if (subdomain != null) {
			let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
			fetchConfDetails(subdomain_val);
		}
	}, []);

	const fetchConfDetails = (sub_domain) => {
		let paramObj = {
			subdomain: sub_domain, //'fostercity' //
		};
		getPageConfig(paramObj)
			.then((res) => {
				if (res?.data?.length > 0) {
					if (res?.data[0]?.logo) {
						setPageLogo(res?.data[0]?.logo);
					}
					if (res?.data[0]?.background) {
						setPageBackground(res?.data[0]?.background)
					}
					if(res?.data[0]?.info?.name) setName(res?.data[0]?.info?.name)
					if(res?.data[0]?.info?.version) setVersion(res?.data[0]?.info?.version)

				}
			})
			.catch((err) => {
				// Do Nothing
			});
	};

	// Todo:Set form value
	const onUpdateField = e => {
		const nextFormState = {
			...loginForm,
			[e.target.name]: e.target.value,
		};
		setLoginForm(nextFormState);
	};

	// Todo:Validation Function
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		let fields = loginForm;
		let emailPattern = new RegExp(/^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter email.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid email address.";
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter password.";
		}
		setError(errors);
		return formIsValid;
	};

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			handleSubmit();
		}
	};

	const handleOnLoginSuccess = async(data) =>{
		
		if (data.email_verified === false) {
			const emailId = data.email;
			toast({
				title: "Sorry, the email verification is not completed.. Please check your email for the email verification code or you can resend the email verification code also.",
				status: "success",
				isClosable: true,
				duration: 3000,
			});
			navigate("/verifyEmail", {
				state: {
					user_emailId: emailId,
				},
			});
		} else if (data.is_dummy_password === false) {
			const emailId = data.email;
			saveEmailId(emailId);

			// Move the token retrieval logic here and await the token
			const formData = new FormData();
			formData.append("grant_type", "");
			formData.append("username", loginForm.email);
			formData.append("password", loginForm.password);
			formData.append("scope", "");
			formData.append("client_id", "");
			formData.append("client_secret", "");
			if (data?.token) {
				saveAuthToken(data.token)
			} else {
				const tokenRes = await getToken(formData);
				if (tokenRes.access_token != null && tokenRes.access_token.length > 0) {
					saveAuthToken(tokenRes.access_token);
				}
			}
			savegroupId(data.group);
			saveSessionId(data.user_uuid);
			toast({
				title: "Logged In Successfully",
				status: "success",
				isClosable: true,
				duration: 3000,
			});
			if (data.group === "100") {
				navigate("/admin/dashboard");
			}
			if (data.group === "200" || data.group === "300") {
				navigate("/business/stats");
			}
			if (data.group === "500") {
				navigate("/languageassociate/stats");
			}
			if (data.group === "600") {
				navigate("/account/dashboard");
			}
			if (data.group === "700") {
				navigate("/subscriber/dashboard");
			}
			if(data.group === "5100"){
				navigate("/ailab/applicant/home")
			}

		} else if (data.is_dummy_password === true) {
			const emailId = data.email;
			toast({
				title: "You have logged in successfully using default password. Please change default password with your own password",
				status: "success",
				isClosable: true,
				duration: 3000,
			});
			navigate("/changeDefaultPassword", {
				state: {
					email: emailId,
				},
			});
		}
	
	}

	const handleSubmit = async () => {
		if (validateFieldForm()) {
			isLoading(true);
			try {
				const res = await login(loginForm);
				isLoading(false);
				if (res?.result) {
					handleOnLoginSuccess(res?.data[0])
				} else {
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			} catch (err) {
				isLoading(false);
				if (err?.response?.data?.detail?.message) {
					toast({
						title: err?.response?.data?.detail?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				} else {
					toast({
						title: "An error occurred",
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			}
		}
	};

	const onGoogleLogin = useGoogleLogin({
		onSuccess:async (tokenResponse) => {
		isLoading(true)
		googleSignOn(tokenResponse.access_token).then(res=>{
			isLoading(false);
				if (res?.result) {
					handleOnLoginSuccess(res.data)
				} else {
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
		}).catch(err=>{
			isLoading(false);
				if (err?.response?.data?.detail?.message) {
					toast({
						title: err?.response?.data?.detail?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				} else {
					toast({
						title: "An error occurred",
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
		})
			
		},

		onError: error=> {
			toast({
			title: "An error occurred",
			status: "error",
			isClosable: true,
			duration: 3000,
		})
	}
	})
	return (
		<CenteredAuth
			cardTop={{ base: "140px", md: "14vh" }}
			cardBottom={{ base: "50px", lg: "auto" }}
			pageLogo={pageLogo ? pageLogo : null}
			// pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[Math.floor(Math.random() * 4)]}
			pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[3]}
			confName={name}
			confVersion={version}
		// mx="0px"
		>
			<Box w="100%" className="lsp">
				<Flex
					maxW={{ base: "100%", md: "max-content" }}
					w="100%"
					mx={{ base: "auto", lg: "0px" }}
					me="auto"
					justifyContent="center"
					px={{ base: "20px", md: "0px" }}
					flexDirection="column">
					<Box me="auto">
						<Heading color={textColor} fontSize="36px" mb="10px">
							Sign In
						</Heading>
						<Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
							Enter your email and password to sign in!
						</Text>
					</Box>
					<Flex
						zIndex="2"
						direction="column"
						w={{ base: "100%", md: "420px" }}
						maxW="100%"
						background="transparent"
						borderRadius="15px"
						mx={{ base: "auto", lg: "unset" }}
						me="auto"
						mb={{ base: "20px", md: "auto" }}>

						{/* <Button
							fontSize="sm"
							me="0px"
							mb="26px"
							py="15px"
							h="50px"
							borderRadius="16px"
							bg={googleBg}
							color={googleText}
							fontWeight="500"
							_hover={googleHover}
							_active={googleActive}
							_focus={googleActive}>
							<Icon as={FcGoogle} w="20px" h="20px" me="10px" />
							Sign in with Google
						</Button>
						<Flex align="center" mb="25px">
							<HSeparator />
							<Text color="gray.400" mx="14px">
								or
							</Text>
							<HSeparator />
						</Flex> */}

						<FormControl>
							<FormLabel
								display="flex"
								ms="4px"
								fontSize="sm"
								fontWeight="500"
								color={textColor}
								mb="8px">
								Email<Text color={brandStars}>*</Text>
							</FormLabel>
							<Input
								isRequired={true}
								variant="auth"
								fontSize="sm"
								ms={{ base: "0px", md: "0px" }}
								type="email"
								placeholder="email@businessdomain.com"
								mb="14px"
								fontWeight="500"
								size="lg"
								name="email"
								onChange={onUpdateField}
								value={loginForm.email}
							/>
							<Text color="red" mb="2">
								{error?.email}
							</Text>
							<FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
								Password<Text color={brandStars}>*</Text>
							</FormLabel>
							<InputGroup size="md">
								<Input
									isRequired={true}
									fontSize="sm"
									ms={{ base: "0px", md: "4px" }}
									placeholder="Min. 8 characters"
									mb="14px"
									size="lg"
									type={show ? "text" : "password"}
									variant="auth"
									name="password"
									value={loginForm.password}
									onChange={onUpdateField}
									onKeyPress={handleKeyPress}
								/>
								<InputRightElement display="flex" alignItems="center" mt="4px">
									<Icon
										color={textColorSecondary}
										_hover={{ cursor: "pointer" }}
										as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
										onClick={handleClick}
									/>
								</InputRightElement>
							</InputGroup>
							<Text color="red" mb="2">
								{error?.password}
							</Text>
							<Flex justifyContent="space-between" align="center" mb="24px">
								<Spacer />
								{/* <FormControl display="flex" alignItems="center">
									<Checkbox id="remember-login" colorScheme="brand" me="10px" />
									<FormLabel
										htmlFor="remember-login"
										mb="0"
										fontWeight="normal"
										color={textColor}
										fontSize="sm">
										Keep me logged in
									</FormLabel>
								</FormControl> */}
								<NavLink to="/forgot-password">
									<Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
										Forgot password?
									</Text>
								</NavLink>
							</Flex>
							<Button
								fontSize="sm"
								// variant="brand.100"
								bg={buttonBg}
								color={googleText}
								fontWeight="500"
								w="100%"
								h="50"
								mb="24px"
								onClick={() => {
									handleSubmit();
								}}
								isDisabled={loading ? true : false}
								isLoading={loading ? true : false}>
								Sign In
							</Button>
						</FormControl>
						<Button
								fontSize="sm"
								// variant="brand.100"
								color={googleText}
								fontWeight="500"
								w="100%"
								h="50"
								mb="24px"
								onClick={onGoogleLogin}
							leftIcon={<Box>
								<LoginIcon height="20px" width="20px"/>
							</Box>}
							isDisabled={loading ? true : false}
								isLoading={loading ? true : false}>
								Continue with Google
							</Button>
						{/* <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
							<Text color={textColorDetails} fontWeight="400" fontSize="14px">
								Not registered yet?
								<NavLink to="/register">
									<Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
										Create an Account
									</Text>
								</NavLink>
							</Text>
						</Flex> */}
					</Flex>
				</Flex>
			</Box>
		</CenteredAuth>
	);
}

export default LspLogin;
import adminRoutes from "./AdminRoutes.js";
import businessRoutes from "./BusinessRoutes.js";
import { getGroupId } from "../utils/localStorageIndex.js";

const groupId = getGroupId();

// TODO: Suraj Fix Route
const horizonRoutes = groupId === "300" ? businessRoutes : adminRoutes;

// const getFinalRoute = (groupId) => {
//     debugger;
//     if (groupId === 100){
//         return adminRoutes;
//     } else if (groupId === 200 || groupId === 300) {
//         return businessRoutes;
//     } else if (groupId === 500) {
//         return languageAssociateRoutes;
//     }
// }
// const horizonRoutes = getFinalRoute(groupId);


export default horizonRoutes;

// Chakra React Imports
import React, { useState } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Image,
	Text,
	Flex,
    useToast,
	useColorModeValue,
} from "@chakra-ui/react";

// Component Imports
import Card from "../../../components/card/Card";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import TrustCenter from "../trustCenter/TrustCenter";
import { useBlackWhiteBg, useBrandBg } from "../../../theme/globalColorTheme";

function PlatformTrust() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const  customerUuid = state?. customerUuid ? state?. customerUuid : null;

	const [activeTab, setActiveTab] = useState(0);
	const brandBg = useBrandBg();
    const blackWhiteBg = useBlackWhiteBg();

	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(false);

	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} mx={3}>
                <Flex h="75vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "80%"}
                        bg={brandBg}
                        p="3"
                        borderRadius={"10px"}
                        overflow="hidden"
				        overflowY="scroll"
                    >
                        <TrustCenter />
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            minW="300px" maxW={'300px'}
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%">
                                <Text>Certifications</Text>
                                <Flex w={'100%'} direction={'column'} align={'center'}>
                                    <Image mt={1} src='https://lingoletpub.s3.us-west-1.amazonaws.com/open/soc_2.svg' w={100} h={100}/>
                                    <Image mt={1} src='https://lingoletpub.s3.us-west-1.amazonaws.com/open/hipaa.svg' w={100} h={100}/>
                                    <Image mt={1} src='https://lingoletpub.s3.us-west-1.amazonaws.com/open/iso27001.svg' w={100} h={100}/>
                                    <Image mt={1} src='https://lingoletpub.s3.us-west-1.amazonaws.com/open/gdpr.svg' w={100} h={100}/>
                                    <Image mt={1} src='https://lingoletpub.s3.us-west-1.amazonaws.com/open/pci_dss.svg' w={100} h={100}/>
                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Certifications
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
			</Card>
		</Flex>
	);
}

export default PlatformTrust;

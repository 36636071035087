import { Button } from '@chakra-ui/react';
import { PDFDownloadLink, Document, Page, View, Image, Text, PDFViewer } from '@react-pdf/renderer';
import lingoLogo from "../../../media/images/Lingolet-2C-large.png";


const MyDocument = ({ subscriberLogo, pageHeader, pageSubHeader, qrSrc }) => {
    return (
    <Document>
    <Page size="A4" style={{ padding: 20 }}>
      <View style={{ textAlign: 'center', marginBottom: 20 }}>
          <Image
            src={{ uri: subscriberLogo|| lingoLogo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
            style={{ height: 100, marginBottom: 20, objectFit: 'contain' }}
          />
        <Text style={{ fontSize: 24, fontWeight: 600 }}>{pageHeader}</Text>
        <Text style={{ fontSize: 20, fontWeight: 500, marginTop: 10 }}>
          {pageSubHeader}
        </Text>
        {
            qrSrc.map(qr=><Image src={qr} style={{ height: 200, marginBottom: 20, objectFit: 'contain', marginTop:"24"}} />)
        }
          <View style={{ flexDirection: 'row', display:'flex', justifyContent:'center', marginTop:"10" }}>
            {subscriberLogo && (
              <Image src={subscriberLogo} style={{ marginRight: 10, height: 60 }} />
            )}
            <View>
              <Text>Powered By</Text>
              <Image src={lingoLogo} style={{ height: 60, width: '60%', objectFit: 'contain', marginTop:"-10" }} />
            </View>
          </View>
      </View>
    </Page>
  </Document>
)};

const PdfComponent = ({ subscriberLogo, pageHeader, pageSubHeader, linkInfo,qrSrc }) => {
  return (
    <>
      <PDFDownloadLink
        document={<MyDocument subscriberLogo={subscriberLogo} pageHeader={pageHeader} pageSubHeader={pageSubHeader} linkInfo={linkInfo} qrSrc={qrSrc}/>}
        fileName="myDocument.pdf"
      >
        {({ loading }) => (
          <Button mt={4} colorScheme="teal" isLoading={loading}>
            Download PDF
          </Button>
        )}
      </PDFDownloadLink>
    </>

  );
};

export default PdfComponent;

import React, { useState, useEffect } from 'react'
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { Box, Avatar, Text, Flex, Heading, Image,Tabs, TabList, TabPanels, Tab, TabPanel, } from "@chakra-ui/react";

// Component Import
import LocationUpload from './LocationUpload';
import Card from "../../../components/card/Card";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import KioskAiBg from '../../../assets/communicator/kiosk-ai.png';
import LiveAiBg from '../../../assets/communicator/live-ai.png';
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import { useBrandBg, useBlackWhiteBg } from '../../../theme/globalColorTheme';
import CreateQuotaLocation from './CreateQuotaLocation';
import LanguageSelection from './LanguageSelection';
import { useSelector } from 'react-redux';


function LocationDetails(props) {
    const brandBg = useBrandBg();
    const quotaData = props?.quotaDetails;
    const blackWhiteBg = useBlackWhiteBg();
    const parentInfo = props.parentInfo ? props.parentInfo : null;
    const locationInfo = props.locationDetails ? props.locationDetails : null;
    const [appData,setAppData] = useState(null)
    const [locationAvtar, setLocationAvtar] = useState(locationInfo?.link_location_avatar);
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const accountUuid = props.state.accountUuid
    const locationUuid = props.locationDetails.link_location_uuid
    const uploadAccountAvatar = useSelector((store) => store.notificationHeader);

    useEffect(()=> {
        if(uploadAccountAvatar?.locationAvtarFlag) {
            setLocationAvtar(uploadAccountAvatar?.locationAvtarFlag)
        }
        else {
            setLocationAvtar(locationInfo?.link_location_avatar)
        }

    },[uploadAccountAvatar])

    useEffect(() => {
        if(props?.apps) {
            setAppData(props?.apps)
        }
        else {
            setAppData(null)
        }

    },[props?.apps])

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="150px" w="150px" mb="3" mr="1">
                <Image cursor={'pointer'} key={index} mr="1" borderRadius="10px" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  else if (name === "li") {
            return LiveAiBg;
        } else if (name === "ki") {
            return KioskAiBg;
        }
        else return;
	};

    const renderSelectedLocationInfo =
        Object.keys(locationInfo)?.length > 0 ? (
            <Flex w={"100%"} rounded={"md"} direction={"column"} >
                <Box minW={"100px"}>
                    <Avatar h={100} w={100} src={locationAvtar} />
                </Box>
                <Text mt={5} fontWeight={600} fontSize={"xl"}>
                    {locationInfo?.subLoc ? 'Sub' : ''} Location Details
                </Text>
                <Flex w={'100%'}>
                    <Text w={'200px'}>Location Name: </Text>
                    <Text as="span" fontWeight={'600'} ml="3">{locationInfo?.link_location_name}</Text>
                </Flex>
                <Flex w={'100%'}>
                    <Text  w={'200px'}>Phone Number:</Text>
                    {locationInfo?.link_location_phone
                    ?<Text as="span" fontWeight={'600'} ml="3">{locationInfo?.link_location_phone?locationInfo?.link_location_phone:"Not Available"}</Text>
                    :<Text as="span" color={'red.200'} fontWeight={'100'} ml="3">{locationInfo?.link_location_phone?locationInfo?.link_location_phone:"Not Available"}</Text>}
                </Flex>
                <Flex w={'100%'}>
                    <Text w={'200px'}> Address Line 1:</Text>
                    <Text as="span" ml="3" fontWeight={'600'}>
                        {locationInfo?.link_location_address1?locationInfo?.link_location_address1:"Not Available"}</Text>
                </Flex>
                <Flex w={'100%'}>
                        <Text w={'200px'}> Address Line 2: </Text>
                        <Text as="span" ml="3" fontWeight={'600'}>
                            {locationInfo?.link_location_address2?locationInfo?.link_location_address2:"Not Available"}
                        </Text>
                   
                </Flex>
                <Flex w={'100%'}>
                    <Text w={'200px'}> City:</Text>
                    <Text as="span" ml="3" fontWeight={'600'}>
                        {locationInfo?.link_location_city?locationInfo?.link_location_city:"Not Available"}
                    </Text>
                </Flex>
                <Flex w={'100%'}>
                    <Text w={'200px'}> State:</Text>
                    <Text as="span" ml="3" fontWeight={'600'}>
                        {locationInfo?.link_location_state?locationInfo?.link_location_state:"Not Available"}
                    </Text>
                </Flex>
                <Flex w={'100%'}>
                    <Text w={'200px'}> Zipcode:</Text>
                    <Text as="span" ml="3" fontWeight={'600'}>
                        {locationInfo?.link_location_zip?locationInfo?.link_location_zip:"Not Available"}
                    </Text>
                </Flex>
                <Flex w={'100%'}>
                    <Text w={'200px'}> Country: </Text>
                    <Text as="span" ml="3" fontWeight={'600'}>
                        {locationInfo?.link_location_country?locationInfo?.link_location_country:"Not Available"}
                    </Text>
                </Flex>
            </Flex>

        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to get info...
                </Heading>
            </Flex>
        );

    return (
        <Card w={"100%"} rounded={"md"} p={1}>
            <Flex h="75vh">
                <Box
                    w={!configurationCollapseFlag ? "95%" : "80%"}
                    bg={brandBg}
                    rounded={'lg'}
                    p="1"
                    borderRadius={"10px"}
                    overflow="hidden">
                        <Card w={'100%'} rounded={'lg'} p={2} mt="4" h="80%" overflow="hidden">
                            <Tabs variant='unstyled' bg={brandBg} orientation="vertical" h="100%">
                                <TabList p='10px' pb='0px'>
                                    <Tab mt={1} rounded={'lg'} w='200px' bg={blackWhiteBg} _selected={{ color: '#fff', bg: 'blue.500', borderRadius:'5px' }}>Location Details</Tab>
                                    <Tab mt={1} rounded={'lg'} w='200px' bg={blackWhiteBg} _selected={{ color: '#fff', bg: 'blue.500', borderRadius:'5px' }}>Supported Apps</Tab>
                                    <Tab mt={1} rounded={'lg'} w='200px' bg={blackWhiteBg} _selected={{ color: '#fff', bg: 'blue.500', borderRadius:'5px' }}>Location Quota</Tab>
                                    <Tab mt={1} rounded={'lg'} w='200px' bg={blackWhiteBg} _selected={{ color: '#fff', bg: 'blue.500', borderRadius:'5px' }}>Language Selection</Tab>
                                </TabList>
                                <TabPanels pt="10px">
                                    <TabPanel pt="0" h={'100%'}>
                                    <Card w={'100%'} h={'100%'} rounded={'lg'} p={2}>
                                        {Object?.keys(locationInfo)?.length > 0
                                            ? <Flex w={'100%'} direction={'column'}>{renderSelectedLocationInfo}</Flex>
                                            : <Text>Collecting...</Text>}
                                    </Card>
                                    </TabPanel>
                                    <TabPanel pt="0" h={'100%'}>
                                    <Card w={'100%'} h={'100%'} rounded={'lg'} p={2} mt={1} >
                                        <Text fontWeight={'600'} mb='3' >Supported LingoLink Apps</Text>
                                        {appData != null ? (
                                            <Text fontWeight='600' textAlign={'center'}>
                                                {appData != null
                                                ? <Flex  flexWrap={'wrap'}> 
                                                    {renderAppData(appData)} 
                                                </Flex>
                                                : <Text>No apps</Text>}
                                            </Text>
                                            ) : (
                                            <Text>No Apps</Text>
                                        )}
                                    </Card>
                                    </TabPanel>
                                    <TabPanel h={'100%'} pt="0" height="95%" overflowY="scroll">
                                        <CreateQuotaLocation propsData={props}/>
                                    </TabPanel>
                                    <TabPanel overflow="hidden" h={'100%'}>
                                        <LanguageSelection  accountUuid={accountUuid} locationUuid={locationUuid}/>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs> 
                        </Card>
                    </Box>
                        
              
                {configurationCollapseFlag ? (
                    <Flex minW={"305px"} maxW={"605px"}>
                        <Box
                            minW="300px"
                            maxW={"300px"}
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2">
                            <Text fontWeight={'500'}>Upload Location Details</Text>
                            <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%" bg={blackWhiteBg} borderRadius={'10px'} mt="3" >
                                {Object.keys(locationInfo)?.length > 0
                                    ? <LocationUpload 
                                        locationInfo={locationInfo}
                                        parentInfo={parentInfo}
                                         />
                                    : null}
                            </Box>
                        </Box>
                    </Flex>
                ) : (
                    <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                        <Box w={"100%"} h="100%">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                fontWeight={'bold'}
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}>
                                Upload Location Details
                            </Text>
                        </Box>
                    </Box>
                )}
            </Flex>
        </Card>
    )
}

export default LocationDetails
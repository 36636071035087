// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg } from "../../../../theme/globalColorTheme";

function ReportingTabOne() {
	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(false);

	return (
		<Flex h="70vh" overflow="hidden">
			<Box
				w={!configurationCollapseFlag ? "95%" : "80%"}
				bg={brandBg}
				p="3"
				borderRadius={"10px"}
				h="full"
				className="custom-scrollbar"
				overflowY="auto"
			></Box>
			{configurationCollapseFlag ? (
				<Box
					w="20%"
					position="relative"
					p="3"
					borderRadius="15px"
					bg={brandBg}
					ml="2"
				>
					<Box
						position="absolute"
						top="0"
						right="0"
						mr="2"
						mt="3"
						zIndex="999"
					>
						<AiFillRightCircle
							cursor="pointer"
							size="20px"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
						/>
					</Box>
					<Box w="100%" h="95%" bg={brandBg}></Box>
				</Box>
			) : (
				<Box
					w="5%"
					p="3"
					borderRadius="10px"
					bg={brandBg}
					h="100%"
					ml="2"
					pl="5"
				>
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}
						>
							Expand Report Settings
						</Text>
					</Box>
				</Box>
			)}
		</Flex>
	);
}

export default ReportingTabOne;

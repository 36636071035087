import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex, Select, Image, Spacer, 
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
  Switch,
} from '@chakra-ui/react';
import { MdOutlineCircle, MdOutlinePeople, MdOutlinePerson, MdOutlinePersonOutline, MdOutlinePersonPinCircle, MdOutlinePersonalVideo, MdPlusOne, MdVerifiedUser } from 'react-icons/md';
import { AiOutlineDisconnect } from "react-icons/ai";
import { GoArrowSwitch } from "react-icons/go";
import { RiRobotLine } from "react-icons/ri";
import { FaPlus, FaArrowRightArrowLeft, FaBilibili, FaRocketchat, 
    FaArrowsDownToPeople, FaArrowsTurnToDots,
    FaAlignJustify } from "react-icons/fa6";
import { ColorModeSwitcher } from '../../../ColorModeSwitcher';

import Card from '../../../components/card/Card';
import { languageList } from '../../../utils/languageList';
import lingo_logo from "../../../assets/landingPageImg/kb_logo.png";
import onemeta_logo from '../../../assets/landingPageImg/OM logo-blue-logo.png';

function LingoletOneMetaDemo() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
    
    const bannerBg = useColorModeValue(null, 'white');
    const skinTextColor = useColorModeValue('gray.800', 'gray.800');
    const skinTextColorCh1 = useColorModeValue('gray.800', '#B75CFF');
    const skinTextColorCh2 = useColorModeValue('gray.800', 'blue.300');
  
    const [loading, isLoading] = useState(true);
    const [languageOne, setLanguageOne] = useState('English');
    const [languageTwo, setLanguageTwo] = useState('Spanish');
    const [translationStarted, setTranslationStarted] = useState(false);

    const [optionalLangs, setOptionalLangs] = useState(['Japanese', 'Chinese', 'Korean', 'Hindi', 'Spanish', 'English']);

    const jsonData = [
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'son las 2 de la tarde.', translatedText:'It is 2 PM.'},
            
            {id: 'one', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'two', sourceText: 'Estoy muy bien y saludable.', translatedText:'I am doing great and healthy.'},

            {id: 'one', sourceText: 'It is great to see you', translatedText:'Es genial verte'},
            {id: 'two', sourceText: 'Totalmente, estoy feliz de verte.', translatedText:'Totally, I am happy to see you. '},

            {id: 'one', sourceText: 'Thanks, its nice talking with you', translatedText:'Gracias, es un placer hablar contigo.'},
            {id: 'two', sourceText: 'Claro, disfruté la charla.', translatedText:'Sure, I enjoyed the talk'},
            
        ]
    const [audioTextData, setAudioTextData] = useState(jsonData);
    const [translationLayout, setTranslationLayout] = useState('mix');

    const renderLanguageListData = languageList?.map((countryData, index) => (
        <option
        key={countryData.code}
        value={countryData.name}
        style={{ color: 'black' }}
        >
        {countryData.name}
        </option>
    ));

    const onUpdateField = (id, e) => {
        if (id === 'one'){
            setLanguageOne(e.target.value);
        } else if (id === 'two'){
            setLanguageTwo(e.target.value);
        } 
    };
    
    const renderContent = (side) => {
        if (side === 'mix'){
            if (audioTextData?.length > 0){
                return(
                    audioTextData.map( (item, index) => {
                        return(
                            <Flex key={index} w={'100%'} direction={'column'}>
                                {item.id === 'one'
                                ?<Flex w={'100%'}>
                                    <Flex align={'start'} w={'55%'} direction={'column'}>
                                        <Text align={'start'} color={'#CB8AFF'}>{item.sourceText}</Text>
                                        <Flex w={'100%'} mt={3}>
                                            <Spacer />
                                            <Card align={'start'} w={'60%'} bg={side === 'one' ? 'blue.600' : '#B75CFF'}>
                                                <Text color={'gray.200'}>{item.translatedText}</Text>
                                            </Card>
                                        </Flex>
                                    </Flex>
                                    <Spacer/>
                                </Flex>:null}
                                {item.id  === 'two'
                                ?<Flex w={'100%'}>
                                    <Spacer />
                                    <Flex align={'end'} w={'55%'} direction={'column'}>
                                        <Text color={'blue.200'}>{item.sourceText}</Text>
                                        <Flex w={'100%'} mt={3}>
                                            <Card align={'start'} w={'60%'} bg={side === 'two' ? '#B75CFF': 'blue.600'}>
                                                <Text color={'gray.200'}>{item.translatedText}</Text>
                                            </Card>
                                            <Spacer />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                :null}
                            </Flex>
                        )
                    })
                )
            }
        } else {
            let keyValue = null;
            if (side == 'left'){
                keyValue = 'one';
            } else if (side == 'right'){
                keyValue = 'two';
            }
            if (audioTextData?.length > 0 && keyValue != null){
                let filteredData = audioTextData?.filter(data => {
                    return data?.id === keyValue;
                });
                if (filteredData.length > 0){
                    filteredData = filteredData.reverse();
                    return(
                        filteredData.map( (item, index) => {
                            return(
                                <Flex key={index} w={'100%'} direction={'column'}>
                                    <Text color={'gray.600'} align={'start'}>{item.sourceText}</Text>
                                    <Text color={side === 'one' ? 'blue.600' : 'green.600'} align={'end'}>{item.translatedText}</Text>
                                </Flex>
                            )
                        })
                    )
                }
            }
        }
    }

    return (
        <Flex w={'100%'} direction="column">
            <Flex w={'100%'}    
                bg={bannerBg}
                p={1} borderWidth={'0.1px'} borderColor={'gray.200'}>
                <Spacer />
                <Image src={lingo_logo} w={'200px'} h={'50px'}/>
                <Icon color={'gray.700'} as={FaPlus} ml={5} mr={5} mt={5} />
                <Image src={onemeta_logo} w={'200px'} h={'40px'} mt={2} />
                <Spacer />
                <ColorModeSwitcher align={'top'} color={'gray.800'} justifySelf="flex-end" />
            </Flex>
            <Flex w={'100%'} hidden={translationStarted ? true : false}>
                <Spacer />
                <Flex w={'60%'} mt={1} bg={'blue.100'} rounded={'lg'} align={'center'} border={'1px'} borderColor={'gray.200'}>
                    <Flex w={'45%'} direction={'column'} align={'start'} pl={20}>
                        <Text color={skinTextColor} fontSize={'3xl'}>
                            {languageOne}
                        </Text>
                        {/* <Text fontSize={'xs'} color={'gray.600'} >Selected <b>First</b> Lanugage</Text> */}
                    </Flex>
                    <Box w={'10%'}>
                        <Icon color={skinTextColor}  as={FaArrowRightArrowLeft} fontSize={20} />
                    </Box>
                    <Flex w={'45%'} direction={'column'}  align={'end'} pr={20}>
                        <Text color={skinTextColor} fontSize={'3xl'}>
                            {languageTwo}
                        </Text>
                        {/* <Text fontSize={'xs'} color={'gray.600'} >Selected <b>Second</b> Lanugage</Text> */}
                    </Flex>
                </Flex>
                <Spacer />
            </Flex>
            <Flex w={'100%'} h="calc(100vh - 130px)">
                    <Box w={'100%'} align={'center'} h={'100%'} hidden={translationStarted ? false : true}>
                        <Flex w={'100%'} align={'center'} bg="blue.600" >
                            <Flex w={'25%'} bg={'#B75CFF'} align={'center'}>
                                <Text w={'100%'} align={'center'} color={'gray.200'}>Speaker One</Text>
                            </Flex>
                            <Flex w={'50%'} align={'center'} bg="blue.700">
                                <Text w={'100%'} align={'center'} color={'gray.200'}>Translation</Text>
                            </Flex>
                            <Flex w={'25%'}  align={'center'}>
                                <Text w={'100%'} align={'center'} color={'gray.200'}>Speaker Two</Text>
                            </Flex>
                        </Flex>
                        {translationLayout === 'box'
                        ?<Flex w={'100%'} h="calc(100vh - 190px)" align={'center'} p={2}>
                            <Flex w={'50%'} h={'100%'} direction={'column'} align={'center'}>
                                <Flex 
                                    bg={'blue.100'} rounded={'lg'}
                                    p={2}
                                    direction={'column'}
                                    w={'100%'}
                                    overflow="auto"
                                    borderTopWidth="0px"
                                    css={{
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: '24px',
                                    },
                                    }}     
                                    h={'100%'}>
                                        {renderContent('left')}
                                </Flex>
                            </Flex>
                            <Flex w={'50%'} ml={'5px'} h={'100%'} direction={'column'}>
                                <Flex 
                                    bg={'green.100'} rounded={'lg'}
                                    p={2}
                                    direction={'column'}
                                    overflow="auto"
                                    borderTopWidth="0px"
                                    css={{
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: '24px',
                                    },
                                    }}     
                                    w={'100%'} h={'100%'}>
                                        {renderContent('right')}
                                </Flex>
                            </Flex>
                        </Flex>
                        :<Flex w={'96%'} h="calc(100vh - 190px)" align={'center'} p={2}>
                            <Flex w={'100%'} ml={'5px'} mr={'10px'} h={'100%'} direction={'column'}>
                                <Flex 
                                    rounded={'lg'}
                                    p={2}
                                    direction={'column'}
                                    overflow="auto"
                                    borderTopWidth="0px"
                                    css={{
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: '24px',
                                    },
                                    }}     
                                    w={'100%'} h={'100%'}>
                                        {renderContent('mix')}
                                </Flex>
                            </Flex>
                        </Flex>}
                        <Flex w={'100%'} bg={'blue.800'} align={'center'} p={1}>
                            <Flex w={'20%'} align={'center'} pl={5}>
                                <Text color={'gray.100'} mr={2}>Enable Audio</Text>
                                <Switch colorScheme='blue' size={'lg'}>

                                </Switch>
                            </Flex>
                            <Box w={'60%'} align={'center'}>
                                {/* <Button 
                                    bg={translationLayout ==='mix' ? 'blue.200' : 'gray.200'}
                                    mr={1} onClick={() => setTranslationLayout('mix')}>
                                    <Icon color={skinTextColor} as={FaArrowsTurnToDots} />
                                </Button> */}
                                {translationStarted
                                ?<Button  align={'center'}
                                    onClick={() => {
                                        setTranslationStarted(false);
                                    }} 
                                    color={skinTextColor}
                                    cursor={'pointer'}
                                    _hover={{bg:'red.400'}}
                                    bg={'red.200'}>
                                        Disconnect ({languageOne} <Icon color={skinTextColor} ml={1} mr={1}  as={FaArrowRightArrowLeft} fontSize={12} /> {languageTwo})
                                </Button>:null}
                                {/* <Button 
                                    bg={translationLayout ==='box' ? 'blue.200' : 'gray.200'}
                                    ml={1} onClick={() => setTranslationLayout('box')}>
                                    <Icon color={skinTextColor} as={FaArrowsDownToPeople} />
                                </Button> */}
                            </Box>
                            <Flex w={'20%'}  pr={5} align={'center'}>
                                <Text color={'gray.100'} mr={2}>Enable Audio</Text>
                                <Switch colorScheme='green' size={'lg'}>

                                </Switch>
                            </Flex>
                        </Flex>      
                    </Box>
                    <Box w={'100%'} h={'100%'}  align={'center'} hidden={translationStarted ? true : false}>
                        <Flex w={'100%'}>
                            <Flex w={'50%'} rounded={'lg'} m={1} borderWidth={'0.5px'} borderColor={'gray.300'}
                                h={'100%'} align={'center'} direction={'column'} p={1}>
                                <Text mt={4} color={'gray.400'} fontSize={'md'}>Speaker One Language Options</Text>
                                <SimpleGrid w={'100%'} p={2} columns={3} columnGap={'20px'}>
                                    {optionalLangs.map( (item, index) => {
                                        return(
                                            <Box 
                                                cursor={'pointer'}
                                                _hover={{bg:'blue.100'}}
                                                onClick={() => setLanguageOne(item)}
                                                key={index} w={'100%'} mt={1} h={'50px'} 
                                                rounded={'lg'}
                                                border={'1px'} borderColor={'gray.200'}>
                                                    <Text mt={2} 
                                                        align={'center'}
                                                        color={skinTextColorCh1}  
                                                        fontSize={'20px'}>{item}</Text>
                                            </Box>
                                        )
                                    })}
                                </SimpleGrid>
                                <Flex w={'100%'} p={5}>
                                    <Text mt={1} color={'gray.400'} fontSize={'md'}>Select 1st language:</Text>
                                    <Flex w={'60%'} align={'center'} ml={2}>
                                        <Select
                                            value={languageOne}
                                            name="language_one"
                                            placeholder="Select Language"
                                            size="sm"
                                            borderRadius="3px"
                                            borderColor="gray.200"
                                            fontSize={'14px'}
                                            mr="10px"
                                            onChange={e => {
                                                onUpdateField('one', e);
                                            }}
                                        >
                                            {renderLanguageListData}
                                        </Select>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex w={'50%'} rounded={'lg'} m={1} borderWidth={'0.5px'} borderColor={'gray.300'}
                                pr={3}
                                h={'100%'} direction={'column'} align={'center'} p={1}>
                                <Text mt={4} color={'gray.400'} fontSize={'md'}>Speaker Two Language Options</Text>
                                <SimpleGrid w={'100%'} p={2} columns={3} columnGap={'20px'}>
                                    {optionalLangs.map( (item, index) => {
                                        return(
                                            <Box 
                                                cursor={'pointer'}
                                                _hover={{bg:'blue.100'}}
                                                onClick={() => setLanguageTwo(item)}
                                                key={index} w={'100%'} mt={1} h={'50px'} 
                                                rounded={'lg'}
                                                border={'1px'} borderColor={'gray.200'}>
                                                    <Text mt={2} 
                                                        align={'center'}
                                                        color={skinTextColorCh2} fontSize={'20px'}>{item}</Text>
                                            </Box>
                                        )
                                    })}
                                </SimpleGrid>
                                <Flex w={'100%'} p={5}>
                                    <Text mt={1} color={'gray.400'} fontSize={'md'}>Select 2nd language:</Text>
                                    <Flex w={'60%'}  ml={2}>
                                        <Select
                                            value={languageTwo}
                                            name="language_two"
                                            placeholder="Select Language"
                                            size="sm"
                                            borderRadius="3px"
                                            borderColor="gray.200"
                                            fontSize={'14px'}
                                            mr="10px"
                                            onChange={e => {
                                                onUpdateField('two', e);
                                            }}
                                        >
                                            {renderLanguageListData}
                                        </Select>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} maxHeight={'20%'} mt={5}>
                            <Flex w={'100%'} align={'center'}>
                                <Spacer />
                                {/* <Card cursor={'pointer'} p={1} _hover={{bg:'blue.200'}} bg={'blue.100'} w={'100%'}>
                                    <Flex
                                        onClick={() => {
                                            setTranslationStarted(true);
                                        }} 
                                        w={'100%'} color={'gray.600'} p={1} rounded={'lg'}>
                                        <Icon as={FaRocketchat} h={20} w={20} pl={2} />
                                        <Flex w={'100%'} direction={'column'} pl={5}>
                                            <Text fontSize={'2xl'}>
                                                Start Human Interpretation
                                            </Text>
                                            <Text fontSize={'sm'} align='end'>
                                                {languageOne}
                                                {' '}
                                                <Icon as={FaArrowRightArrowLeft} />
                                                {' '}
                                                {languageTwo}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Card> */}
                                <Card cursor={'pointer'} p={1} _hover={{bg:'blue.200'}} bg={'blue.100'} w={'60%'}>
                                    <Flex mr={3}
                                        onClick={() => {setTranslationStarted(true);}} 
                                        w={'100%'} p={1} rounded={'lg'}>
                                        <Icon color={skinTextColor}  as={FaBilibili} h={20} w={20} pl={2} />
                                        <Flex w={'100%'} direction={'column'} pl={5}>
                                            <Text color={skinTextColor}  fontSize={'2xl'}>
                                                Start Secure AI Translation
                                            </Text>
                                            {/* <Text fontSize={'sm'} align='end'>
                                                {languageTwo}
                                                {' '}
                                                <Icon as={FaArrowRightArrowLeft} />
                                                {' '}
                                                {languageOne}
                                            </Text> */}
                                        </Flex>
                                    </Flex>
                                </Card>
                                <Spacer />
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
        </Flex>
    )
}

export default LingoletOneMetaDemo;

import { Provider } from "react-redux";
import { Routes } from "react-router-dom";
import store from "./store/index";
// import { Elements } from '@stripe/react-stripe-js';
import { ChakraProvider } from "@chakra-ui/react";
import initialTheme from "./theme/theme"; //  { themeGreen }
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./assets/css/App.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import useAiLabRoutes from "./routes/AiLabRoutes";
import useLingoletRoutes from "./routes/LingoletRoutes";


export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
  const googleClientId = process.env.REACT_APP_GOOGLE_SIGN_ON;
  const aiLab = (process.env.REACT_APP_AILAB) === 'true'? true: false
  const stripePromise = loadStripe(publishKey);
  const AiLabRoutes = useAiLabRoutes()
  const LingoletRoutes = useLingoletRoutes(currentTheme, setCurrentTheme)
  return (
    <Elements stripe={stripePromise}>
      <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <ChakraProvider theme={currentTheme}>
          {/* <MainRouteComponent /> */}
          {/* { HorizonMenuComp } */} 
          <Routes>
            {aiLab &&  AiLabRoutes}
            {LingoletRoutes}
          </Routes>
        </ChakraProvider>
      </Provider>
      </GoogleOAuthProvider>
    </Elements>
  );
}
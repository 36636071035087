import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Chakra Import
import {
  Box,
  Flex,
  Select,
  Text,
  Button,
  Icon,
  Spacer,
  Alert,
  Drawer,
  Spinner,
  AlertIcon,
  FormLabel,
  SimpleGrid,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  useDisclosure,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import moment from "moment";
import Pagination from '../../../components/pagination/Pagination'
import Card from "../../../components/card/Card";

// Api Call Imports
import {
    addNewDevice, addBulkDevices, updateSelectedDevice, getAllDevices,
} from "../../../services/adminServices";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

function DevicesListing() {
    const toast = useToast();
    const navigate = useNavigate();
    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(1)

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const btnRef = React.useRef();
    const [loading, isLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deviceList, setDeviceList] = useState([]);
    const [error, setError] = useState({});

    const [deviceUuid, setDeviceUuid] = useState(null);
    const [deviceDetails, setDeviceDetails] = useState({
        deviceType: '',
        deviceClass: '',
        deviceSerial: '',
        deviceConfig: '',
        macAddress: ''
    });

    //   Todo:Set Form Values
    const onUpdateField = e => {
        const nextFormState = {
          ...deviceDetails,
          [e.target.name]: e.target.value,
        };
        setDeviceDetails(nextFormState);
    };
    
    //   Todo:Fetch all devices
    useEffect(() => {
        fetchAllDevices();
    }, [recordsPerPage, currPage]);
    
    const fetchAllDevices = () => {
        isLoading(true);
        let paramObj = {
          filter_key: "string",
          filter_value: "string",
          records_per_page: recordsPerPage,
          page_id: currPage,
        };
        getAllDevices(paramObj)
          .then((res) => {
            isLoading(false);
            if (res?.data?.length > 0) {
                setDeviceList(res?.data[0]?.records);
                setTotalPages(res?.data[0]?.total_pages);
            } else {
                toast(toastFunctionToaster("Failed to get device list", "error"))
                setDeviceList([])
                setTotalPages(0)
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const validateDeviceValues = () => {
        let fields = deviceDetails;
        let errors = {};
        let formIsValid = true;
        if (!fields['deviceType']) {
          formIsValid = false;
          errors['deviceType'] = '*Device Type is required.';
        }
        if (!fields['deviceClass']) {
            formIsValid = false;
            errors['deviceClass'] = '*Device Class is required.';
        }
        if (!fields['deviceSerial']) {
            formIsValid = false;
            errors['deviceSerial'] = '*Device Serial is required.';
        }
        // if (!fields['deviceConfig']) {
        //     formIsValid = false;
        //     errors['deviceConfig'] = '*Device Info is required.';
        // }
        setError(errors);
        return formIsValid;
    }

    //   Todo:API to create new device
    const createUpdateDeviceDetails = () => {
        if (validateDeviceValues()) {
            let objBody = {
                device_type: deviceDetails.deviceType,
                device_class: deviceDetails.deviceClass,
                device_serial: deviceDetails.deviceSerial,
                device_config: deviceDetails.deviceConfig ? deviceDetails.deviceConfig : '',
                mac_address: deviceDetails.macAddress ? deviceDetails.macAddress : ''
            };
            addNewDevice(objBody)
            .then((res) => {
                if (res?.result) {
                onClose();
                    resetDeviceDetails();
                    fetchAllDevices();
                toast(toastFunctionToaster(res.message, "success"));
                } else {
                    onClose();
                    resetDeviceDetails();
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                onClose();
                resetDeviceDetails();
                toast(toastFunctionToaster(err.message, "error"));
            });
        }
    };

    // Todo:Reset Device Details
    const resetDeviceDetails = () => {
        const nextFormState = {
            ...deviceDetails,
            ['deviceSerial']: null,
            ['deviceClass']: null,
            ['deviceType']: null,
            ['deviceConfig']: null,
            ['macAddress']: null
          };
          setDeviceDetails(nextFormState);
    };

    // Todo:Reset details on drawer closed
    const closeDrawer = () => {
        onClose();
        setDeviceUuid(null);
        resetDeviceDetails();
    };

    const renderDevicesList = deviceList !== null && deviceList?.length > 0
    ? deviceList?.map((item, index) => (
        <Flex key={index} 
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                <Text rounded={'md'} bg={item?.assigned ? 'green.200' : null} pl={2}>
                {item?.assigned ? 'Assigned' : 'Not Assigned'}
                </Text>
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.class}
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.type}
            </Box>
            <Box direction='column' minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                <Text>{item?.serial}</Text>
                <Text>{item?.mac}</Text>                
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.lost? 'Lost' : 'Not Lost'}
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.lost? 'Archived' : 'Not Archived'}
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {moment(item?.added_date).format("MMM DD,YYYY,HH:mm a")}
            </Box>
            <Box w={'100%'} fontSize={'sm'} ml={1} pl={2}>
                {item?.config}
            </Box>
            <Box minW={'150px'} fontSize={'sm'} pr={2}>
                <Button 
                    onClick={() => {
                        navigate("/admin/devices/deviceinfo", {
                        state: {
                            deviceUuid: item?.uuid,
                            deviceSerial: item?.serial,
                            },
                        })
                    }}
                    size={'sm'}>Manage Device</Button>
            </Box>
        </Flex>
        ))
    : null;

    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex
            p="3"
            alignItems="center"
            borderBottomWidth="1px"
            justifyContent={"space-between"}
            >
            <Text fontWeight={"500"} fontSize={"18px"}>
                Lingolet Devices
            </Text>
            <Spacer />
            <Button ml={1} colorScheme="blue" size="sm" onClick={() => fetchAllDevices()}>
                Refresh
            </Button>
            <Button ml={1} colorScheme="blue" size="sm" 
                onClick={() => {
                    resetDeviceDetails();
                    onOpen()
                }}>
                Add Single Device
            </Button>
            <Button  ml={1} colorScheme="blue" size="sm" onClick={onOpen}>
                Bulk Upload devices
            </Button>
            </Flex>
            {loading ? (
            <Flex alignItems="center" justifyContent="center" h="50vh">
                <Spinner size="xl" />
            </Flex>
            ) : deviceList?.length ? (
                <Card w={"100%"} rounded={"lg"} p={0} >
                    <Flex h="calc(100vh - 250px)" overflowX="auto">
                        <Flex w={'100%'} h={'100%'} direction={'column'}>
                            <Flex w={'100%'} h={'40px'} bg={'gray.200'} py={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                                <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Assignment Status</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Device class</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Device Type</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Serial/MAC</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Lost Device</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Archival Status</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Added On</Text>
                                </Box>
                                <Box w={'100%'} ml={1} pl={2}>
                                    <Text fontSize={'xs'}>Configuration</Text>
                                </Box>
                                <Box minW={'150px'} fontSize={'sm'} pr={2}>
                                    <Text fontSize={'xs'}>Action</Text>
                                </Box>
                            </Flex>
                            <Flex w={'100%'} 
                                h={'calc(100vh - 200px'}                        
                                overflow="auto"
                                borderTopWidth="0px"
                                className="custom-scrollbar"
                                direction={'column'}>
                                {renderDevicesList}
                            </Flex>
                        </Flex>
                    </Flex>
                    {deviceList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
                </Card>
            ) : (
            <Alert status="warning" mt="4">
                <AlertIcon />
                No Device Found
            </Alert>
            )}
        </Box>
            {/* // Add Device Drawer */}
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    closeDrawer();
                }}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={"1px"}>
                    {" "}
                    {deviceUuid ? "Update" : "Create New"} Device
                </DrawerHeader>
                <DrawerBody overflow="hidden" mt="5">
                    <Flex w={'100%'} direction={'column'} mb={5}>
                        <Select
                            value={deviceDetails.deviceClass}
                            name="deviceClass"
                            id={'deviceClass'}
                            placeholder="Select Device Class"
                            size="md"
                            color={textColor}
                            borderRadius="16px"
                            onChange={e => {
                                onUpdateField(e);
                            }}
                        >
                            <option value="Electronics">Electronics</option>
                            <option value="Enclosure">Enclosure</option>
                        </Select>
                        {error?.deviceClass ? (
                            <Text fontSize="sm" color="red" mb="10px">
                            {error?.deviceClass}
                            </Text>
                        ) : null}
                    </Flex>
                    <Flex w={'100%'} direction={'column'} mb={5}>
                        <Select
                            value={deviceDetails.deviceType}
                            name="deviceType"
                            id={'deviceType'}
                            placeholder="Select Device Type"
                            size="md"
                            color={textColor}
                            borderRadius="16px"
                            onChange={e => {
                                onUpdateField(e);
                            }}
                        >
                            <option value="Tablet">Tablet</option>
                            <option value="Lingolet BT">Lingolet BT</option>
                            <option value="TableTop">TableTop</option>
                            <option value="KickStand">KickStand</option>
                        </Select>
                        {error?.deviceType ? (
                            <Text fontSize="sm" color="red" mb="10px">
                            {error?.deviceType}
                            </Text>
                        ) : null}
                    </Flex>
                    {/* <InputField
                        mb={5}
                        me="30px"
                        label="Device Class"
                        required="true"
                        borderRadius="5px"
                        name="deviceClass"
                        id="deviceClass"
                        value={deviceDetails.deviceClass}
                        onChange={onUpdateField}
                        placeholder="Enter Device Class"
                        w="100%"
                        maxlength={100}
                    /> */}
                    <Flex w={'100%'} direction={'column'} mb={5}>
                        <InputField
                            mt={0}
                            name="deviceSerial"
                            id="deviceSerial"
                            value={deviceDetails.deviceSerial}
                            onChange={onUpdateField}
                            label="Device Serial Number"
                            required="true"
                            borderRadius="5px"
                            placeholder="Enter Device Serial Number"
                            w="100%"
                            maxlength={100}
                        />
                        {error?.deviceSerial ? (
                            <Text fontSize="sm" color="red" mb="10px">
                            {error?.deviceSerial}
                            </Text>
                        ) : null}
                    </Flex>
                    <Flex w={'100%'} direction={'column'} mb={5}>
                        <InputField
                            me="30px"
                            label="Device Mac Address (Optional)"
                            name="macAddress"
                            id="macAddress"
                            value={deviceDetails.macAddress}
                            onChange={onUpdateField}
                            borderRadius="5px"
                            placeholder="Enter Device Mac Address (Optional)"
                            w="100%"
                            maxlength={100}
                        />
                    </Flex>
                    <FormLabel mt="3" ml="2" required>
                    <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                        Device Info
                    </Text>
                    <Textarea
                        mb={5}
                        me="30px"
                        rows="10"
                        id="deviceConfig"
                        name="deviceConfig"
                        value={deviceDetails.deviceConfig}
                        onChange={onUpdateField}
                        placeholder="Enter Device Info"
                        w="100%"
                        maxlength={500}
                    />
                    <Text
                        textAlign={"right"}
                        fontWeight={"500"}
                        fontSize={"sm"}
                        mt="2"
                    >
                        {deviceDetails?.deviceConfig?.length}/500
                    </Text>
                    {/* {error?.deviceConfig ? (
                        <Text fontSize="sm" color="red" mb="10px">
                        {error?.deviceConfig}
                        </Text>
                    ) : null} */}
                    </FormLabel>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            // setDeviceUuid(null);
                            // resetDeviceDetails();
                            setError({});
                            createUpdateDeviceDetails();
                        }}
                        // isDisabled={saveDetailsLoader ? true : false}
                        // isLoading={saveDetailsLoader ? true : false}
                        >
                        {deviceUuid ? "Update" : "Add New"} Device
                    </Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default DevicesListing;

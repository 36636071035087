import React from "react";
import Info from "./components/Info";
import Upload from "./components/Upload";
import Socials from "./components/Socials";
import Password from "./components/Password";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";

function ProfileSettings() {
  return (
    <Box pt="20px">
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Column Left */}
        <Flex direction="column">
          <Upload
            gridArea={{
              base: "3 / 1 / 4 / 2",
              lg: "1 / 3 / 2 / 4",
            }}
            minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
            pe="20px"
            pb={{ base: "100px", lg: "20px" }}
          />
          <Info />
        </Flex>
        {/* Column Right */}
        <Flex direction="column">
          <Socials />
          <Password />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}

export default ProfileSettings;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
	Box,
    Icon,
	Button,
	Flex,
	Text,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import Card from "../../../components/card/Card";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import Pagination from '../../../components/pagination/Pagination'
import {
	AiOutlineCopy,
    AiFillCaretRight,
    AiOutlineLink,
} from "react-icons/ai";

import { getAccAssignedDeviceLogs } from '../../../services/accountServices'

import { useGreenBg, useLinkColor, useTextColor, 
    usePillButtonTextColorHover,usePillButtonHighLight,
    usePillButtonTextColorSelected, usePillButtonBg, usePillButtonBgHover } from "../../../theme/globalColorTheme";


function DevicesConnectLogs() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    
    const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonBg = usePillButtonBg()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonTextColorHover = usePillButtonTextColorHover()
	const pillButtonText = useTextColor()
    const pillButtonBgHighlight = usePillButtonHighLight()

    const lingoLinkUrl = state?.lingoLink ? state?.lingoLink : null;
    const deviceSerial = state?.deviceSerial ? state?.deviceSerial : null;
    const deviceMac = state?.deviceMac ? state?.deviceMac : null;

    const [devicesListLoading, setDevicesListLoading] = useState(false)
    const [devicesConnLogs, setDevicesConnLogs] = useState([])

    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(1)


    useEffect(() => {
        fetchDeviceLogs()
    }, [recordsPerPage, currPage])

    useEffect(() => {
        setCurrPage(1)
    }, [recordsPerPage])

    function fetchDeviceLogs() {
        if (deviceSerial != null && deviceMac != null){
            setDevicesListLoading(true)
            let paramObj = {
                dev_serial: deviceSerial,
                dev_mac: deviceMac,
                records_per_page:recordsPerPage,
                page_id:currPage
            }
            getAccAssignedDeviceLogs(paramObj)
            .then(res => {
                if (res?.data[0]?.status) {
                    setDevicesConnLogs(res?.data[0]?.data)
                    setTotalPages(res?.data[0]?.total_pages)
                } else {
                    toast(toastFunctionToaster("Failed to get device connect logs due to no serial or mac info", "error"))
                    setDevicesConnLogs([])
                    setTotalPages(0)
                }
            }).catch(err => {
                toast(toastFunctionToaster("Failed to get device connect logs due to no serial or mac info", "error"))
                setDevicesConnLogs([])
                setTotalPages(0)
            }).finally(() => {
                setDevicesListLoading(false)
            })
        } else{
            toast(toastFunctionToaster("Failed to get device connect logs due to no serial or mac info", "error"))
        }
    }

    const deviceLogListing = devicesConnLogs?.length > 0 
    ? devicesConnLogs?.map((item, index) => (
        <Flex w={'100%'} 
            fontSize={'sm'}
            key={index}  mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex minW={'200px'} align={'center'}>
                <Icon as={AiFillCaretRight} color={item?.is_connected ? 'green'  : 'red' } mr={1} />
                {moment(item?.datetime).format("MMM DD,YYYY,HH:mm a")}
            </Flex>
            <Flex minW={'100px'}>
                {item?.type}
            </Flex>
            <Flex minW={'50px'}>
                {item?.stage}
            </Flex>
            <Flex minW={'100%'}>
                {item?.response}
            </Flex>
        </Flex>
        ))
    : <Text bg={'red.100'} px={2} rounded={'lg'}>This device does not have any connection logs...</Text>;

    return (
        <Flex w={'100%'} direction={'column'} pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'} mt={2} p={2} h={'40px'}>
                <Flex w={'100%'}>
                    <Flex w={'100%'}>
                        Device Serial: <Text px={1} rounded={'lg'} bg={pillButtonBg} mr={2}>{deviceSerial}</Text>
                        Device Mac:  <Text px={1} rounded={'lg'} bg={pillButtonBg} mr={2}>{deviceMac}</Text>
                        LingoLink : <Text px={1} rounded={'lg'} bg={pillButtonBg}>{lingoLinkUrl}</Text>
                    </Flex>
                    <Flex w={'150px'}>
                        <Button 
                            _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                            color={pillButtonText}
                            bg={pillButtonBg}
                            onClick={() => fetchDeviceLogs()} 
                            rounded={'lg'} 
                            size={'xs'}>Reload</Button>
                        <Button 
                            ml={1}
                            _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                            color={pillButtonText}
                            bg={pillButtonBg}
                            onClick={() => {
                                navigate('/account/assigned_devices', {
                                    state: {
                                    },
                                });
                            }} 
                            rounded={'lg'} 
                            size={'xs'}>Back to Devices</Button>
                    </Flex>
                </Flex>
            </Card>
            <Flex w={'100%'} direction="column" >
                <Card w={'100%'} mt={2} p={2}>
                    <Flex w={'100%'} h='calc(100vh - 250px)' overflow="auto"  direction={'column'}>
                        {deviceLogListing}
                    </Flex>
                    {devicesConnLogs.length 
                        ?<Pagination currentPage={currPage} 
                                    recordsPerPage={recordsPerPage} 
                                    setCurrentPage={setCurrPage} 
                                    setRecordsPerPage={setRecordsPerPage} 
                                    totalPages={totalPages} /> 
                        : null}
                </Card>
            </Flex>
        </Flex>
    )

}


export default DevicesConnectLogs
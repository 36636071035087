import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Spacer,
    Badge,
    Flex,
    Progress,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Icon,
    Text,
    Alert,
    Button,
    Spinner,
    AlertIcon,
    Avatar,
    useToast,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import Card from "../../../components/card/Card";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";

import { translationLanguages } from '../../../utils/TranslationLanguages';
import { transcriptionLanguages } from '../../../utils/TranscriptionLanguage';


import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from 'react-audio-player';

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { adminAutomationReport } from "../../../services/adminServices";

function AutomationReport() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { colorMode } = useColorMode();
    const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
    const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

    const [loading, isLoading] = useState(true);
    const [eventsList, setEventsList] = useState([]);
    const [runTime, setRunTime] = useState(null);
    

    useEffect(() => {
        fetchAllReports();
    }, []);

    const fetchAllReports = () => {
        isLoading(true);
        let objData = {
            'job_status': '',
            'filter_key': '',
            'filter_value': '',
            'page_id': 0,
            'records_per_page': 10
        };
        adminAutomationReport(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setEventsList(res?.data[0]?.events);
                setRunTime(res?.data[0]?.runtime);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
                isLoading(false);
          });
    };


    const renderEventsList = eventsList !== null && eventsList?.length
    ? eventsList?.map((item, index) => (
        <Flex key={index} w={'100%'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Box minW={'150px'} fontSize={'sm'}>
                <Text><b>{item?.counter}</b></Text>
            </Box>
            <Box minW={'100px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2} fontSize={'sm'}>
                <Text mt={2} color={'blue.400'} fontSize={'sm'}>{item?.status ? 'True': 'False'}</Text>
            </Box>
            <Box minW={'200px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                <Text color={'blue.400'} fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm a")}</Text>
            </Box>
            <Box minW={'200px'} borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2}>
                <Text color={'blue.400'} fontSize={'sm'}>{moment(item?.updated).format("MMM DD,YYYY,HH:mm a")}</Text>
            </Box>
            <Box minW={'100%'}borderLeftWidth={'0.05px'} borderLeftColor={'gray.200'} ml={1} pl={2} fontSize={'sm'}>
                {item?.data != null
                ?<Text mt={2} color={'blue.400'} fontSize={'xs'}>{item?.data}</Text>
                :<Text mt={2} color={'red.400'} fontSize={'xs'}>
                    No Data
                </Text>}
            </Box>

        </Flex>
        ))
    : null;

    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card direction="column" mt="5" borderWidth="0.5px">
                <Flex w={'100%'} align={'center'}>
                    <Text>Automation Report</Text>
                    <Spacer />
                    <Badge mr={1} bg={'yellow.100'}>{runTime != null ? runTime : ''}</Badge>
                    <Button 
                        bg={'blue.200'}
                        onClick={() => {
                            fetchAllReports();
                        }}
                        size={'sm'}>Refresh</Button>
                    {/* <Button 
                        bg={jobsType === 'Completed' ? 'blue.200' : 'gray.200'}
                        onClick={() => {
                            setJobsType('Completed');
                            fetchContentWorkflows('Completed');
                        }}
                        size={'sm'} ml={1}>Completed Jobs</Button> */}
                </Flex>
            </Card>
            {loading ? (
                <Flex alignItems="center" justifyContent="center" h="60vh">
                <Spinner size="lg" />
                </Flex>
            ) : (
                <>
                {eventsList !== null && eventsList?.length ? (
                    <Card w={'100%'} 
                        h="calc(100vh - 300px)"
                        overflow="auto"
                            css={{
                                '&::-webkit-scrollbar': {
                                width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                background: '#fff',
                                borderRadius: '24px',
                                },
                            }}
                        >
                        {renderEventsList}
                    </Card>
                ) : (
                    <Card w={'100%'}>
                        <Text p="2" fontWeight={"500"}>
                        No Record Found
                        </Text>
                    </Card>
                )}
                </>
            )}
        </Box>
        </>
    );
}

export default AutomationReport;


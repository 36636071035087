import React from "react";
import Card from "../../../../components/card/Card";

function Saved() {
	return (
		<Card w={"100%"} h={"100%"} rounded={"md"} p={5} mx={3} mt="3">
			Saved
		</Card>
	);
}

export default Saved;

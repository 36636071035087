import React, { useState, useEffect } from "react";
import { Flex, Text, Box, Button, Icon, useToast, Spinner } from "@chakra-ui/react";
import { BsPlus, BsCheck, BsXCircleFill } from "react-icons/bs";

// Component Call
import InputField from "../../../components/fields/InputField";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Services
import { getBusinessRole, addBusinessRole, updateBusinessRole } from "../../../services/businessServices";

function TeammemberRole() {
	useEffect(() => {
		isLoading(true);
		fetchRoleDetails();
	}, []);

	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [roleArr, setRoleArr] = useState([
		{ role: "Engineering", selected: false },
		{ role: "Support", selected: false },
		{ role: "Marketing", selected: false },
		{ role: "Leadership", selected: false },
		{ role: "Management", selected: false },
	]);
	const [roleValue, setRoleValue] = useState("");
	const [spinnerLoading, setSpinnerLoading] = useState(false);
	const [customRoleArr, setCustomRoleArr] = useState([]);
	const [updateButtonFlag, setUpdateButtonFlag] = useState(false);
	const [customRoleArrErrFlag, setCustomRoleArrErrFlag] = useState(false);

	const fetchRoleDetails = () => {
		setSpinnerLoading(true);
		getBusinessRole()
			.then(res => {
				setSpinnerLoading(false);
				isLoading(false);
				if (res?.data[0]?.associate_roles.length) {
					let parseData = JSON.parse(res?.data[0]?.associate_roles);

					setRoleArr(parseData?.default_roles);
					setCustomRoleArr(parseData?.new_roles);
				} else {
					setRoleArr([]);
				}
			})
			.catch(err => {
				setSpinnerLoading(false);

				isLoading(false);
			});
	};

	// Todo:Fuction to reset check buttons
	const setCheckedValue = (data, arrValue, setterFunction) => {
		let cloneData = [...arrValue];
		cloneData?.map((subValue, index) => {
			if (subValue?.role === data) {
				subValue.selected = !subValue.selected;
			}
		});
		setterFunction(cloneData);
	};

	// Todo:Render Switches
	const renderRoleArrData = roleArr?.map((data, index) => (
		<Button
			key={index}
			cursor="pointer"
			bg={data?.selected ? "blue.300" : null}
			borderWidth={"1px"}
			borderRadius={"20px"}
			size="sm"
			mr="2"
			mb="2"
			onClick={() => {
				setCheckedValue(data.role, roleArr, setRoleArr);
				setUpdateButtonFlag(true);
				setRoleValue("");
			}}>
			<Icon h="24px" w="24px" as={data?.selected ? BsCheck : BsPlus}></Icon> {data?.role}
		</Button>
	));

	// Todo:Render Custom Role Data
	const renderCustomRoleArrData = customRoleArr?.map((data, index) => (
		<Button
			key={index}
			cursor="pointer"
			bg={data?.selected ? "green.300" : null}
			borderWidth={"1px"}
			borderRadius={"20px"}
			size="sm"
			mr="2"
			mb="2"
			onClick={e => {
				setCheckedValue(data.role, customRoleArr, setCustomRoleArr);
				setUpdateButtonFlag(true);
				setRoleValue("");
			}}>
			<Icon h="24px" w="24px" as={data?.selected ? BsCheck : BsPlus}></Icon> {data?.role}
			<Icon
				h="18px"
				w="18px"
				cursor="pointer"
				as={BsXCircleFill}
				onClick={e => {
					e.stopPropagation();
					removeSelectedRole(index);
				}}
				ml="3"></Icon>{" "}
		</Button>
	));

	// Todo:- Remove Selected value from custom array
	const removeSelectedRole = indexValue => {
		let cloneArr = [...customRoleArr];
		if (indexValue >= 0 && indexValue < customRoleArr.length) {
			cloneArr.splice(indexValue, 1);
			setCustomRoleArr(cloneArr);
			setUpdateButtonFlag(true);
		}
	};

	const addUpdateRole = value => {
		if (customRoleArr.length > 25) {
			setCustomRoleArrErrFlag(true);
		} else {
			isLoading(true);
			setUpdateButtonFlag(false);
			let clonedData = [...customRoleArr];
			if (roleValue?.length > 0 && updateButtonFlag === false) {
				let cloneArr = clonedData;
				let objValue = { role: roleValue, selected: true };
				cloneArr.push(objValue);
				clonedData = cloneArr;
				setCustomRoleArr(cloneArr);
			}

			let roles = {
				new_roles: clonedData,
				default_roles: roleArr,
			};
			const jsonObj = { associate_roles: JSON.stringify(roles) };
			if (value === "addRole") {
				addAssociateRole(jsonObj);
			} else {
				updateRoleDetails(jsonObj);
			}
		}
	};

	// Todo:Add Role Details
	const addAssociateRole = obj => {
		addBusinessRole(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setRoleValue("");
					fetchRoleDetails();
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					setRoleValue("");
					setCustomRoleArr([]);
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				setRoleValue("");
				isLoading(false);
				toast(toastFunctionToaster(err.message, "error"));
			});
	};

	// Todo:Update Details
	const updateRoleDetails = obj => {
		updateBusinessRole(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setRoleValue("");
					fetchRoleDetails();
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				setRoleValue("");
				isLoading(false);
				toast(toastFunctionToaster(err.message, "error"));
			});
	};

	// Todo:Update Existing Role
	useEffect(() => {
		if (updateButtonFlag === true) {
			addUpdateRole();
		}
	}, [updateButtonFlag]);

	return (
		<>
			{spinnerLoading ? (
				<Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
					<Spinner size="xl" />
				</Flex>
			) : (
				<Box>
					<Box p="4" mb="4" borderWidth="1px" borderRadius={"20px"} borderStyle={"dotted"}>
						<>
							<Flex alignItems={"center"} justifyContent={"space-between"}>
								<Text fontWeight={"500"} mb="2">
									Existing Roles
								</Text>
							</Flex>
							{renderRoleArrData}
						</>
						<Box mt="3">
							{customRoleArr?.length ? (
								<>
									<Text fontWeight={"500"} mb="2">
										Custom Roles
									</Text>
									{renderCustomRoleArrData}
								</>
							) : (
								<Text fontWeight={"500"} mt="2">
									No Custom Role Added
								</Text>
							)}
						</Box>
					</Box>

					<Flex
						w="100%"
						bg="unset"
						p="1"
                        direction={'column'}
						borderWidth={"1px"}
						// alignItems={"center"}
						borderRadius={"20px"}
						justifyContent={"space-between"}>
						<Box mr="4" w="100%">
							<InputField
								mb="0"
								title={"Add New Role"}
								id="role_value"
								value={roleValue}
								name="role_value"
								placeholder="Enter New Role"
								onChange={e => {
									setRoleValue(e.target.value);
									setUpdateButtonFlag(false);
								}}
								w="100%"
							/>
							{customRoleArrErrFlag ? (
								<Text color="red" fontWeight={"500"}>
									Maximum 25 roles can be added
								</Text>
							) : null}
						</Box>
						<Button
                            w={'100px'}
                            mt={1}
							size="sm"
							borderRadius={"10px"}
							fontWeight={"500"}
							colorScheme="green"
							onClick={() => {
								addUpdateRole("addRole");
								setUpdateButtonFlag(false);
							}}
							isDisabled={loading || roleValue?.length < 1 ? true : false}
							isLoading={loading ? true : false}>
							Add
						</Button>
					</Flex>
				</Box>
			)}
		</>
	);
}

export default TeammemberRole;

const BasicAiLanguageList = [
    {name: "Bulgarian", display: "Bulgarian"},
    {name: "Catalan", display: "Catalan"},
    {name: "Czech", display: "Czech"},
    {name: "Danish", display: "Danish"},
    {name: "Dutch", display: "Dutch"},
    {name: "English", display: "English"},
    {name: "Estonian", display: "Estonian"},
    {name: "Finnish", display: "Finnish"},
    {name: "Flemish", display: "Flemish"},
    {name: "French", display: "French"},
    {name: "German", display: "German"},
    {name: "Greek", display: "Greek"},
    {name: "Hindi", display: "Hindi"},
    {name: "Hungarian", display: "Hungarian"},
    {name: "Indonesian", display: "Indonesian"},
    {name: "Italian", display: "Italian"},
    {name: "Japanese", display: "Japanese"},
    {name: "Korean", display: "Korean"},
    {name: "Latvian", display: "Latvian"},
    {name: "Lithuanian", display: "Lithuanian"},
    {name: "Malay", display: "Malay"},
    {name: "Norwegian", display: "Norwegian"},
    {name: "Polish", display: "Polish"},
    {name: "Portuguese", display: "Portuguese"},
    {name: "Romanian", display: "Romanian"},
    {name: "Russian", display: "Russian"},
    {name: "Slovak", display: "Slovak"},
    {name: "Spanish", display: "Spanish"},
    {name: "Swedish", display: "Swedish"},
    {name: "Thai", display: "Thai"},
    {name: "Turkish", display: "Turkish"},
    {name: "Ukrainian", display: "Ukrainian"},
    {name: "Vietnamese", display: "Vietnamese"},
    {name: "Demo", display: "Demo"},
  ];
  
  export default BasicAiLanguageList;
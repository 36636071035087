import { Button, Flex, FormControl, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, PinInput, PinInputField, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import { useEffect } from 'react'
import { useState } from 'react'
import { controlSpaceContentList, verifySpaceAccess } from '../../../services/sharedServices'
import { useTextColor } from '../../../theme/globalColorTheme'
import { useRef } from 'react'
import ControlSpaceAccessContents from '../components/ControlSpaceAccessContents'

const VerifyControlAccess = ({ space_name }) => {
    const [loading, setLoading] = useState(false)
    const [accessCode, setAccessCode] = useState(null)
    const [valueOne, setValueOne] = useState("")
    const [valueTwo, setValueTwo] = useState("")
    const [valueThree, setValueThree] = useState("")
    const [valueFour, setValueFour] = useState("")
    const [valueFive, setValueFive] = useState("")
    const [valueSix, setValueSix] = useState("")
    const [jwtToken, setJwtToken] = useState(null)
    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [Listloading, setListLoading] = useState(false)
    const [data, setData] = useState([])

    const firstPinRef = useRef(null);
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isCodeValid, setIsCodeValid] = useState(false);
    const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100")

    function handleSendOrVerifyAccessCode() {
        setLoading(true)
        const paramObj = {
            id: space_name,
            access_code: accessCode ? accessCode : ''
        }

        verifySpaceAccess(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                if (resp?.data[0]?.email_sent) {
                    toast(toastFunctionToaster(resp?.message, "success"))
                    onOpen()
                } else if (resp?.data[0]?.access_verified) {
                    toast(toastFunctionToaster(resp?.message, "success"))
                    setJwtToken(resp?.data[0]?.token)
                }
            }
            else {
                toast(toastFunctionToaster(resp?.message || resp?.error, "error"))
            }
        }).catch(e => toast(toastFunctionToaster("Failed to verify", "error")))
        .finally(()=>setLoading(false))
    }

    function handleGetContentList() {
        setListLoading(true)
        const paramObj = {
            token:jwtToken,
            page_id: pageId,
            records_per_page: recordsPerPage
        }
        controlSpaceContentList(paramObj).then(resp => {
            if (resp?.data[0]?.data?.data) {
                setData(resp?.data[0]?.data?.data)
                setTotalPages(resp?.data[0]?.data?.total_pages)
            } else {
                setData([])
                setTotalPages(0)
            }
        }).catch(err => {
            setData([])
            setTotalPages(0)
        }).finally(() => {
            setListLoading(false)
        })
    }


    function handleCloseModal() {
        onClose()
        setAccessCode(null)
    }

    useEffect(() => {
        if (!space_name) {
            toast(toastFunctionToaster("Invalid Space Name", "error"))
        }
        space_name && handleSendOrVerifyAccessCode()
    }, [space_name])

    useEffect(() => {
        const code = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`;
        const flag = code && code.length === 6
        setIsCodeValid(flag)
        flag && setAccessCode(code)
    }, [valueOne, valueTwo, valueThree, valueFour, valueFive, valueSix]);

    useEffect(() => {
        if (jwtToken) {
            handleGetContentList()
        }
    }, [jwtToken])

    useEffect(() => {
        handleGetContentList()
    }, [pageId])

    useEffect(() => {
        setPageId(1)
    }, [recordsPerPage])

    return (
        <>
            {jwtToken ?
            <Flex  p="4" h="100vh" pb={8} pt="0">
                <ControlSpaceAccessContents data={data} pageId={pageId} recordsPerPage={recordsPerPage} setPageId={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} loading={Listloading} />
            </Flex>
                : <>
                    {
                        space_name ?
                            <>
                                <Modal
                                    isOpen={isOpen}
                                    onClose={() => {
                                        handleCloseModal()
                                    }}
                                >
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalCloseButton />
                                        <ModalBody pb={6}>

                                            <Heading fontSize={"20px"} fontWeight={600} my="2">
                                                Enter your access code
                                            </Heading>
                                            <Flex
                                                zIndex="2"
                                                direction="column"
                                                w={{ base: "100%", md: "425px" }}
                                                maxW="100%"
                                                background="transparent"
                                                borderRadius="15px"
                                                mx={{ base: "auto", lg: "unset" }}
                                                me="auto"
                                                mb={{ base: "20px", md: "auto" }}
                                            >
                                                <FormControl>
                                                    <Flex justify="center">
                                                        <PinInput mx="auto" otp>
                                                            <PinInputField
                                                                fontSize="30px"
                                                                ref={firstPinRef}
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                type='number'
                                                                min={0}
                                                                max={9}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                me="10px"
                                                                value={valueOne}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueOne(e.target.value)
                                                                }}
                                                            />
                                                            <PinInputField
                                                                fontSize="30px"
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                me="10px"
                                                                value={valueTwo}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueTwo(e.target.value)
                                                                }}
                                                            />
                                                            <PinInputField
                                                                fontSize="30px"
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                me="10px"
                                                                value={valueThree}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueThree(
                                                                        e.target.value
                                                                    )
                                                                }}
                                                            />
                                                            <PinInputField
                                                                fontSize="30px"
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                value={valueFour}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueFour(e.target.value)
                                                                }}
                                                            />
                                                            <PinInputField
                                                                ml={2}
                                                                fontSize="30px"
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                value={valueFive}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueFive(e.target.value)
                                                                }}
                                                            />
                                                            <PinInputField
                                                                ml={2}
                                                                fontSize="30px"
                                                                color={useTextColor}
                                                                borderRadius="16px"
                                                                borderColor={borderColor}
                                                                h={{ base: "55px", md: "80px" }}
                                                                w={{ base: "63px", md: "95px" }}
                                                                value={valueSix}
                                                                onChange={e => {
                                                                    (e.target.value >= 1 && e.target.value <= 9) && setValueSix(e.target.value)
                                                                }}
                                                            />
                                                        </PinInput>
                                                    </Flex>
                                                </FormControl>
                                            </Flex>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                isDisabled={isCodeValid ? false : true}
                                                isLoading={loading ? true : false}
                                                onClick={handleSendOrVerifyAccessCode}
                                                colorScheme={isCodeValid ? "green" : "red"}
                                                mr={3}
                                            >
                                                Verify
                                            </Button>
                                            <Button
                                                onClick={handleCloseModal}
                                            >
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </>
                            : <Flex justifyContent="center"><Text fontSize="xl" p="2">Invalid Space Name</Text></Flex>
                    }
                </>
            }
        </>

    )
}

export default VerifyControlAccess
import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Button,
	SimpleGrid,
	Spacer,
	Flex,
	HStack,
	Icon,
	Avatar,
	Select,
	FormLabel,
	Spinner,
	Badge,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	CloseButton,
	IconButton,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Grid,
	useColorModeValue,
} from "@chakra-ui/react";

import {
	MdAddTask,
	MdAttachMoney,
	MdBarChart,
	MdPerson,
	MdLink,
	MdLinkOff,
	MdPhone,
	MdSensors,
	MdFileCopy,
	MdCamera,
	MdVideoCall,
} from "react-icons/md";
import {
	AiOutlineCheckCircle,
	AiOutlineCopy,
	AiOutlineLink,
	AiOutlineMail,
	AiOutlineStop,
	AiOutlineSync,
	AiOutlineHolder,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
} from "react-icons/ai";

import { getEmailId, getGroupId } from "../../../../utils/localStorageIndex";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import StatisticsPill from "../../../common/widgets/StatisticsPill";
import IconBox from "../../../../components/icons/IconBox";
import Usa from "../../../../assets/img/dashboards/usa.png";
import Card from "../../../../components/card/Card";
import LinkRevenue from "./LinkRevenue";
import LinkTraffic from "./LinkTraffic";

// Api Call Imports
import { getDbClientLinkCount, getDbClientProjectCount } from "../../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useMetricsIconBgColor, useMetricsIconColorAndPillBg, usePillButtonBg, usePillButtonTextColorHover, useTextColor } from "../../../../theme/globalColorTheme";

function LinkMetrics(props) {
	const brandColor = useMetricsIconColorAndPillBg()
	const boxBg = useMetricsIconBgColor()
	const pillButtonBg = usePillButtonBg()
	const pillButtonText = useTextColor()
	const pillButtonTextHover = usePillButtonTextColorHover()
	const pillButtonBgHighlight = useMetricsIconColorAndPillBg()

	const bgBadge = useColorModeValue("brand.50", "whiteAlpha.100");
	const bgContrast = useColorModeValue("secondaryGray.300", "navy.900");
	const textColor = useColorModeValue("secondaryGray.200", "white");

	const [loading, isLoading] = useState(true);
	const [currentSelection, setCurrentSelection] = useState("links");

	const linkStats = props.linkStats ? props.linkStats : [];

	// const [linkStats, setLinkStats] = useState([]);
	// const [projectStats, setProjectStats] = useState([]);

	return (
		<Flex w={"100%"} direction={"column"}>
			<Card w={"100%"} h={"200px"} mt={5}>
				<Flex w={"100%"}>
					<Text ml={4}>LingoLinks</Text>
					<Spacer />
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText}
						bg={pillButtonBg}>
						Overall
					</Button>
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText} bg={pillButtonBg} ml={1}>
						Today
					</Button>
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText}
						bg={pillButtonBg}
						ml={1}>
						This Week
					</Button>
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText}
						bg={pillButtonBg}
						ml={1}>
						This Month
					</Button>
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText}
						bg={pillButtonBg}
						ml={1}>
						This Year
					</Button>
					<Button size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: pillButtonTextHover }}
						color={pillButtonText}
						bg={pillButtonBg}
						ml={1}>
						Custom Range
					</Button>
				</Flex>
				<Box w={"100%"} mt={2} borderTopColor={"gray.300"} borderTopWidth={"0.5px"}>
					<SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap="10px" mb="10px">
						<StatisticsPill
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdLink} color={brandColor} />}
								/>
							}
							name="Active Links"
							value={linkStats?.length > 0 ? linkStats[0]?.active : 0}
						/>
						<StatisticsPill
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdLinkOff} color={"red.300"} />}
								/>
							}
							name="Closed Links"
							value={linkStats?.length > 0 ? linkStats[0]?.closed : 0}
						/>
						<MiniStatistics
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdSensors} color={brandColor} />}
								/>
							}
							name="Sessions"
							value={linkStats?.length > 0 ? linkStats[0]?.sessions : 0}
						/>
						<MiniStatistics
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg={boxBg}
									icon={<Icon w="32px" h="32px" as={MdPerson} color={brandColor} />}
								/>
							}
							// endContent={
							//     <Flex me='-16px' mt='10px'>
							//     <FormLabel htmlFor='balance'>
							//         <Avatar src={Usa} />
							//     </FormLabel>
							//     <Select
							//         id='balance'
							//         variant='mini'
							//         mt='5px'
							//         me='0px'
							//         defaultValue='usd'>
							//         <option value='usd'>USD</option>
							//         <option value='eur'>EUR</option>
							//         <option value='gba'>GBA</option>
							//     </Select>
							//     </Flex>
							// }
							name="Customers"
							value={linkStats?.length > 0 ? linkStats[0]?.calls : 0}
						/>
						<MiniStatistics
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdPhone} color="white" />}
								/>
							}
							name="Audio Calls"
							value={linkStats?.length > 0 ? linkStats[0]?.audio_calls : 0}
						/>
						<MiniStatistics
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdVideoCall} color="white" />}
								/>
							}
							name="Video Calls"
							value={linkStats?.length > 0 ? linkStats[0]?.video_calls : 0}
						/>
						{/* <MiniStatistics 
                                startContent={
                                    <IconBox
                                    w='56px'
                                    h='56px'
                                    bg={boxBg}
                                    icon={
                                        <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                                    }
                                    />
                                }
                                //growth='+5%' 
                                name='Call Amount' 
                                value={linkStats?.length > 0 ?  linkStats[0]?.amount : 0.0}
                            /> */}
						{/* <MiniStatistics
                            startContent={
                                <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                    <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
                                }
                                />
                            }
                            name='Total Projects'
                            value='2935'
                            /> */}
					</SimpleGrid>
				</Box>
			</Card>
			<Flex w={"100%"} mt={2}>
				<Flex w={"50%"}>
					<LinkRevenue />
				</Flex>
				<Flex w={"50%"} ml={1}>
					<LinkTraffic />
				</Flex>
			</Flex>
		</Flex>
	);
}

export default LinkMetrics;

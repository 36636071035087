import React, { useState } from 'react';
import { Box, Input, Text, Button, Switch, VStack, useToast } from '@chakra-ui/react';
import { toastFunctionToaster } from '../../../../utils/toastFunction';
import { manageLingoletIdAddress } from '../../../../services/accountServices';
import { INFO_TYPES } from './constants';

const AddressInfo = ({ addressList, handleEdit }) => {
  const [editableAddresses, setEditableAddresses] = useState(addressList);
  const toast = useToast();

  const handleInputChange = (e, index, field) => {
    const updatedAddresses = [...editableAddresses];
    updatedAddresses[index][field] = e.target.value;
    setEditableAddresses(updatedAddresses);
  };

  const handleToggleChange = async (e, index, field) => {
    try {
      const updatedAddresses = [...editableAddresses];
      updatedAddresses[index][field] = e.target.checked;

      const params = {
        identity_uuid: updatedAddresses[index].identity_uuid,
        id_address_uuid: updatedAddresses[index].id_address_uuid,
      };
      const response = await manageLingoletIdAddress(params);
      if (response?.data[0]?.status) {
        toast(toastFunctionToaster("Updated successfully", "success"));
        setEditableAddresses(updatedAddresses);
      } else {
        toast(toastFunctionToaster("Failed to update", "error"));
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to update", "error"));
    }
  };

  return (
    <>
      {editableAddresses.length > 0 ? (
        editableAddresses.map((address, index) => (
          <Box
            key={address.id_address_uuid}
            id={`${address.identity_uuid}-${address.id_address_uuid}`}
            mt={4}
            p={4}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
          >
            <Text fontWeight="bold" mb={2}>
              Address Information {index + 1}
            </Text>

            <VStack align="start" spacing={2}>
              <Text><strong>Address Type:</strong></Text>
              <Input
                value={address.id_address_type}
                onChange={(e) => handleInputChange(e, index, 'id_address_type')}
              />

              <Text><strong>Address Line 1:</strong></Text>
              <Input
                value={address.id_address_line1}
                onChange={(e) => handleInputChange(e, index, 'id_address_line1')}
              />

              <Text><strong>Address Line 2:</strong></Text>
              <Input
                value={address.id_address_line2 || ''}
                onChange={(e) => handleInputChange(e, index, 'id_address_line2')}
              />

              <Text><strong>City:</strong></Text>
              <Input
                value={address.id_address_city}
                onChange={(e) => handleInputChange(e, index, 'id_address_city')}
              />

              <Text><strong>State:</strong></Text>
              <Input
                value={address.id_address_state || ''}
                onChange={(e) => handleInputChange(e, index, 'id_address_state')}
              />

              <Text><strong>Zip:</strong></Text>
              <Input
                value={address.id_address_zip || ''}
                onChange={(e) => handleInputChange(e, index, 'id_address_zip')}
              />

              <Text><strong>Country:</strong></Text>
              <Input
                value={address.id_address_country}
                onChange={(e) => handleInputChange(e, index, 'id_address_country')}
              />

              <Text><strong>Region:</strong></Text>
              <Input
                value={address.id_address_region || ''}
                onChange={(e) => handleInputChange(e, index, 'id_address_region')}
              />

              <Text><strong>Active:</strong></Text>
              <Switch
                isChecked={address.id_address_active}
                onChange={(e) => handleToggleChange(e, index, 'id_address_active')}
              />
            </VStack>

            <Button mt={4} colorScheme="blue" onClick={() => handleEdit(INFO_TYPES.ADDRESS, editableAddresses[index])}>
              Save
            </Button>
          </Box>
        ))
      ) : (
        <Text mt={2}>No address information available</Text>
      )}
    </>
  );
};

export default AddressInfo;

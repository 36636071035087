// Chakra React Imports
import React, { useEffect, useState } from "react";
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
	useToast,
	Spinner,
	Flex,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../../../constants";
import { getHelpFileContent, getHelpToc } from "../../../../services/sharedServices";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

function TabOneSubHelper(props) {
	const [toc, setToc] = useState(null)
	const [isTocLoading, setIsTocLoading] = useState(false)
	const toast = useToast()

	function getToc() {
		setIsTocLoading(true)
		getHelpToc().then(resp => {
			if (resp.data[0]?.TOC) {
				let jsonData = JSON.parse(resp.data[0]?.TOC)
				setToc(jsonData)
			} else {
				setToc([])
				toast(toastFunctionToaster("Something wen wrong", "error"))
			}
		}).catch(err => {
			toast(toastFunctionToaster("Something wen wrong", "error"))
			setToc([])

		}).finally(() => {
			setIsTocLoading(false)
		})
	}

	
	useEffect(() => {
		getToc()
	}, [])


	return (
		<Tabs w="100%" h="100%" borderBottom={"0px"} mx="auto">
			<TabList border="0px" w="100%" h="8%">
				<Tab
					_selected={{
						color: defaultThemeColor,
						fontWeight: 600,
						borderBottomColor: defaultThemeColor,
					}}
					fontSize="16px"
					px="6"
					py="2"
				>
					Admin Content
				</Tab>
				<Tab
					_selected={{
						color: defaultThemeColor,
						fontWeight: 600,
						borderBottomColor: defaultThemeColor,
					}}
					fontSize="16px"
					px="6"
					py="2"
				>
					Team Content
				</Tab>
			</TabList>
			<TabPanels mt="4%" h="91%">
				<TabPanel p="0" h="100%">
					<Text ml={5}>
						{
							isTocLoading ?<Flex w="full" justifyContent="center"> 
							<Spinner
								thickness='2px'
								speed='0.65s'
								emptyColor='gray.200'
								color='gray.500'
								size='xl'
							/>
							</Flex>  : <>
								{
									toc ? <>
										{toc.chapters?.map(chapter=>(
											<ul key={chapter?.chapter}>
												{chapter?.topics && chapter?.topics?.length ? <>
													{
														chapter?.topics?.map(topic=>(
															<li key={topic?.heading}><Text cursor="pointer" onClick={()=>props?.handleGetFileContent(topic?.file_name)}>{topic?.heading}</Text></li>
														))
													}
												</>: null}
											</ul>
										))}
									</> : null
								}
							</>
						}
						{/* <ul>
							<li>Update Personal Profile</li>
							<li>Update Business Profile</li>
							<li>Team Roles and Configuration</li>
							<li>Add & Manage Team Members</li>
							<li>Add Your Business Customers</li>
							<li>Add LingoCard and Assign to Customers</li>
							<li>Add LingoLink and Assign to Customers</li>
							<li>Using LingoLink</li>
							<li>Using DocumentAI</li>
						</ul>
						<ul>
							<li>1. Difference between Premium and Secure AI </li>
						</ul> */}
					</Text>
				</TabPanel>
				<TabPanel p="0" h="100%">
					<Text ml={5}>
						<ul>
							<li>Contact Team Members</li>
							<li>Edit Business Customers</li>
							<li>Assign Card to Customers</li>
							<li>Assign LingoLink to Customers</li>
							<li>LingoLink Management</li>
							<li>Using DocumentAI</li>
						</ul>
					</Text>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}

export default TabOneSubHelper;

// Chakra React Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    AiOutlineCheckCircle,
    AiOutlineBulb,
    AiOutlineYoutube,
    AiOutlineBackward,
    AiOutlinePhone,
} from "react-icons/ai";
import {
    Icon,
    Spacer,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Flex,
} from "@chakra-ui/react";

// Api Call Imports
import {
    fetchAccountInfo,
} from "../../../../services/businessServices";

// Common Components
import Card from "../../../../components/card/Card";
import { defaultThemeColor } from "../../../../constants";

// Component Imports
import AccountDetails from "./AccountDetails";
import AccountAdmin from "./AccountAdmin";


function AccountManage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, isLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [accountDetails, setAccountDetails] = useState([]);
    const accountUuid = state?.accountUuid ? state?.accountUuid : null;

    //   Todo:Fetch all account details
    useEffect(() => {
        collectAccountInfo();
    }, []);

    const collectAccountInfo = () => {
        if (accountUuid != null) {
            isLoading(true);
            let paramObj = {
                link_account_uuid: accountUuid,
            };
            fetchAccountInfo(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        isLoading(false);
                        setAccountDetails(res?.data[0]?.info);
                    } else {
                        isLoading(false);
                        setAccountDetails([]);
                    }
                })
                .catch(err => {
                    isLoading(false);
                    setAccountDetails([]);
                });
        }
    };

    // Todo:Tab Change
    const handleTabChange = index => {
        setActiveTab(index);
    };

    const handleUpdatedApp = (data) => {
        if(data) {
            collectAccountInfo()
        }
    }

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt={2} >
            <Card w={"100%"} p={1}>
                <Tabs w="100%" borderBottom={"0px"} mx="auto" onChange={handleTabChange} index={activeTab}>
                    <TabList border="0px" w="100%" h="40px">
                        <Flex w="100%">
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineCheckCircle} mr={1} />
                                Account Details
                            </Tab>
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineBulb} mr={1} />
                                Account Admins
                            </Tab>
                            {/* <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineYoutube} mr={1} />
                                Locations & Subscribers
                            </Tab>
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlinePhone} mr={1} />
                                LingoLinks
                            </Tab> */}
                            <Spacer />
                            <Flex
                                p="1"
                                _hover={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate("/business/accountmanagement", {
                                    });
                                }}
                                rounded={"md"}
                                bg={"blue.100"}
                                align={"center"}
                                borderRadius={"10px"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Icon color={defaultThemeColor} as={AiOutlineBackward} ml={1} />
                                <Text px={4} p={1} as="span" ml="3" color={defaultThemeColor} fontWeight={"600"}>
                                    Back to Account List
                                </Text>
                            </Flex>
                            { }
                        </Flex>
                    </TabList>
                    <TabPanels h="100%" p={2}>
                        <TabPanel p={0} h="100%">
                            <AccountDetails 
                                accountDetails={accountDetails} 
                                renderFeatureApp={handleUpdatedApp}
                                refetchInfo={collectAccountInfo}
                                />
                        </TabPanel>
                        <TabPanel p={0} h="100%">
                            {accountUuid != null
                                ? <AccountAdmin accountUuid={accountUuid} accountDetails={accountDetails} /> : null}
                        </TabPanel>
                        {/* <TabPanel p="0" h="100%" >
                            <Text>Locations & Subscribers</Text>
                        </TabPanel>
                        <TabPanel p="0" h="100%">
                            <Text>LingoLinks</Text>
                        </TabPanel> */}
                    </TabPanels>
                </Tabs>
            </Card>
        </Flex>
    );
}

export default AccountManage;

import React from "react";
import Card from "../../../../components/card/Card";

function Paid() {
	return (
		<Card w={"100%"} h={"100%"} rounded={"md"} p={5} mx={3} mt="3">
			Closed Paid
		</Card>
	);
}

export default Paid;

import React, { useState } from 'react';
import { Box, Input, Text, Button, Switch, VStack, useToast } from '@chakra-ui/react';
import { INFO_TYPES } from './constants';
import { toastFunctionToaster } from '../../../../utils/toastFunction';
import { manageLingoletIdTax } from '../../../../services/accountServices';

const TaxInfo = ({ taxList, handleEdit }) => {
  const [editableTaxes, setEditableTaxes] = useState(taxList);
  const toast = useToast();

  const handleInputChange = (e, index, field) => {
    const updatedTaxes = [...editableTaxes];
    updatedTaxes[index][field] = e.target.value;
    setEditableTaxes(updatedTaxes);
  };

  const handleToggleChange = async(e, index, field) => {
    try {
      const manageTaxes = [...editableTaxes];
      manageTaxes[index][field] = e.target.checked;
      const params = {
        identity_uuid: manageTaxes[index].identity_uuid,
        identity_tax_uuid: manageTaxes[index].identity_tax_uuid
      }
      const response = await manageLingoletIdTax(params);
      if(response?.data[0]?.status) {
        setEditableTaxes(manageTaxes);
        toast(toastFunctionToaster("Updated successfully", "success"));
      } else {
        toast(toastFunctionToaster("Failed to update", "error"));
      }
    } catch (error) { 
        toast(toastFunctionToaster("Failed to update", "error"));
    }
  };

  return (
    <>
      {editableTaxes.length > 0 ? (
        editableTaxes.map((tax, index) => (
          <Box
            key={tax.identity_tax_uuid}
            id={`${tax.identity_uuid}-${tax.identity_tax_uuid}`}
            mt={4}
            p={4}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
          >
            <Text fontWeight="bold" mb={2}>
              Tax Information {index + 1}
            </Text>

            <VStack align="start" spacing={2}>
              <Text><strong>Tax Identifier:</strong></Text>
              <Input
                value={tax.identity_tax_identifier}
                onChange={(e) => handleInputChange(e, index, 'identity_tax_identifier')}
              />

              <Text><strong>Additional Info:</strong></Text>
              <Input
                value={tax.identity_tax_identifier_info || ''}
                onChange={(e) => handleInputChange(e, index, 'identity_tax_identifier_info')}
              />

              <Text><strong>Tax Rate (%):</strong></Text>
              <Input
                type="number"
                value={tax.identity_tax_identifier_rate !== null ? tax.identity_tax_identifier_rate : ''}
                onChange={(e) => handleInputChange(e, index, 'identity_tax_identifier_rate')}
              />

              <Text><strong>Country:</strong></Text>
              <Input
                value={tax.identity_tax_identifier_country}
                onChange={(e) => handleInputChange(e, index, 'identity_tax_identifier_country')}
              />

              <Text><strong>State:</strong></Text>
              <Input
                value={tax.identity_tax_identifier_state || ''}
                onChange={(e) => handleInputChange(e, index, 'identity_tax_identifier_state')}
              />

              <Text><strong>Active:</strong></Text>
              <Switch
                isChecked={tax.identity_tax_active}
                onChange={(e) => handleToggleChange(e, index, 'identity_tax_active')}
              />
            </VStack>

            <Button mt={4} colorScheme="blue" onClick={(e) => handleEdit(INFO_TYPES.TAX, editableTaxes[index])}>
              Save
            </Button>
          </Box>
        ))
      ) : (
        <Text mt={2}>No tax information available</Text>
      )}
    </>
  );
};

export default TaxInfo;

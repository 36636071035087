import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack, Heading, Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import HubspotForm from "react-hubspot-form";

const WorkflowError = () => {
  const [showHeader, setShowHeader] = useState(true);

  const [workflowType, setWorkflowType] = useState('Translation');

  return (
    <Box w={'100%'} p={5} align={'center'} mt={10}>
        {showHeader
        ?<Flex w={'80%'} direction={'column'} bg={'red.100'} rounded="lg" p={5}>
            <Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
              Error with{" "}
              <Text as="span" fontWeight={"600"} mr="1">
                {workflowType}
              </Text>
              {" "}Workflow
            </Heading>
            <Text mt="3" fontSize={"15px"} color="gray.700">
              We would like to inform you that your specific {workflowType} workflow due to some error.
            </Text>
            <Text mt="3" fontSize={"12px"} color="gray.500">
              Please fill out the following form if you want to request for support for this issue. .
            </Text>
        </Flex>
        :<Box w={"100%"} p={5} mt={10}>
            <Text>Unable to show what you are looking for....</Text>
        </Box>}
    </Box>
  );
};

export default WorkflowError;

import React from 'react'
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

// Component Upload
import AccountContentUpload from './AccountContentUpload'

function AccountUpload({ accountDetails }) {
    const accountId = accountDetails?.link_account_uuid
    return (
        <Accordion defaultIndex={[0]} allowToggle >
            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Upload Avatar
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <AccountContentUpload uploadValue={'avatar'} account_Id={accountId} />
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem >
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Upload Logo
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <AccountContentUpload uploadValue={'logo'} account_Id={accountId} />
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Upload Background Image
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <AccountContentUpload uploadValue={'background'} account_Id={accountId} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default AccountUpload
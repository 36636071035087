export const themeColor = '#3780c2';
export const errorColor = '#e6474a';
export const borderBottomColor = '#494552';
export const defaultThemeColor = '#3780c2'; 
export const themeFontSizeXlHeading = '16px';
export const themeFontSizeLargeHeading = '14px';
export const themeFontSizeMediumHeading = '12px';
export const themeFontSizeOddHeading = '11px';
export const themeFontSizeSmallHeading = '10px';
export const themeFontSizeXSHeading = '8px';

// Dark and Light Colors for Theme
// Dark and Light Theme > white + Orange
export const dcthemeDarkColor = 'navy.700';
export const dcthemeLightColor = 'white';


export const roleAccess = [
  { roleUser: 'Admin', groupId: '100' },
  { roleUser: 'Business', groupId: '200' },
  { roleUser: 'Associate', groupId: '300' },
];

export const userRole = [
  {
    roleName: 'Home',
    group: ['100', '200', '300'],
  },
  {
    roleName: 'Business',
    group: ['200', '300'],
  },
  {
    roleName: 'Associate',
    group: ['300'],
  },
  {
    roleName: 'Admin',
    group: ['100'],
  },
];

// React Imports
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Button, Flex, Box, Text, Menu, MenuButton, MenuList, MenuItem, useColorModeValue } from "@chakra-ui/react";

// Component Imports
import ClientTable from "./ClientTable";
import Card from "../../../../../components/card/Card";
import { useGreenButton, useTextColor, useGreyBorderColor } from "../../../../../theme/globalColorTheme";

function ClientData({ viewType }) {
	const blackWhiteColor = useTextColor();
	const [width, setWidth] = useState("100%");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

	useEffect(() => {
		if (viewType === "card") {
			setWidth("49%");
		} else {
			setWidth("100%");
		}
	}, [viewType]);

	return (
		<>
			{viewType === "table" ? (
				<ClientTable />
			) : (
				<Flex mt="4" flexWrap={"wrap"} justifyContent={"space-between"}>
					<Card w={width} rounded="md" mb="3" borderWidth={"1px"} borderColor={useGreyBorderColor}>
						<Flex mt="4" alignItems={"center"}>
							<Flex w="70%" alignItems={"center"}>
								<Flex h="90px" w="90px" alignItems={"center"} justifyContent={"center"} borderRadius={"10px"} border="1px" borderStyle={"dotted"}>
									Logo
								</Flex>
								<Box ml="4">
									<Text fontSize={"18"} fontWeight={"600"}>
										ABC CORPORATION
									</Text>
									<Text fontSize={"15"}>1234, Street Name, Suite X, City, State, Zip, Country </Text>
									<Flex mt="3" flexWrap={"wrap"}>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											All
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											General
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Contact
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Company
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Banking
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Tax
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Relationships
										</Text>
									</Flex>
								</Box>
							</Flex>
							<Box w="30%" textAlign={"right"}>
								<Box mb="3">
									<Menu>
										<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
											Actions
										</MenuButton>
										<MenuList>
											<MenuItem>Edit Details</MenuItem>
											<MenuItem>Contact</MenuItem>
											<MenuItem>Email / SMS</MenuItem>
											<MenuItem>Disable</MenuItem>
											<MenuItem>Activate</MenuItem>
											<MenuItem>Promote</MenuItem>
										</MenuList>
									</Menu>
								</Box>
								<Menu>
									<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
										Review
									</MenuButton>
									<MenuList>
										<MenuItem>Jobs</MenuItem>
										<MenuItem>Banking</MenuItem>
										<MenuItem>Payments</MenuItem>
										<MenuItem>Billings</MenuItem>
										<MenuItem>Activities</MenuItem>
										<MenuItem>Reports</MenuItem>
										<MenuItem>Analysis</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Flex>
					</Card>
					<Card w={width} rounded="md" mb="3" borderWidth={"1px"} borderColor={useGreyBorderColor}>
						<Flex mt="4" alignItems={"center"}>
							<Flex w="70%" alignItems={"center"}>
								<Flex h="90px" w="90px" alignItems={"center"} justifyContent={"center"} borderRadius={"10px"} border="1px" borderStyle={"dotted"}>
									Logo
								</Flex>
								<Box ml="4">
									<Text fontSize={"18"} fontWeight={"600"}>
										ABC CORPORATION
									</Text>
									<Text fontSize={"15"}>1234, Street Name, Suite X, City, State, Zip, Country </Text>
									<Flex mt="3" flexWrap={"wrap"}>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											All
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											General
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Contact
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Company
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Banking
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Tax
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Relationships
										</Text>
									</Flex>
								</Box>
							</Flex>
							<Box w="30%" textAlign={"right"}>
								<Box mb="3">
									<Menu>
										<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
											Actions
										</MenuButton>
										<MenuList>
											<MenuItem>Edit Details</MenuItem>
											<MenuItem>Contact</MenuItem>
											<MenuItem>Email / SMS</MenuItem>
											<MenuItem>Disable</MenuItem>
											<MenuItem>Activate</MenuItem>
											<MenuItem>Promote</MenuItem>
										</MenuList>
									</Menu>
								</Box>
								<Menu>
									<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
										Review
									</MenuButton>
									<MenuList>
										<MenuItem>Jobs</MenuItem>
										<MenuItem>Banking</MenuItem>
										<MenuItem>Payments</MenuItem>
										<MenuItem>Billings</MenuItem>
										<MenuItem>Activities</MenuItem>
										<MenuItem>Reports</MenuItem>
										<MenuItem>Analysis</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Flex>
					</Card>
					<Card w={width} rounded="md" mb="3" borderWidth={"1px"} borderColor={useGreyBorderColor}>
						<Flex mt="4" alignItems={"center"}>
							<Flex w="70%" alignItems={"center"}>
								<Flex h="90px" w="90px" alignItems={"center"} justifyContent={"center"} borderRadius={"10px"} border="1px" borderStyle={"dotted"}>
									Logo
								</Flex>
								<Box ml="4">
									<Text fontSize={"18"} fontWeight={"600"}>
										ABC CORPORATION
									</Text>
									<Text fontSize={"15"}>1234, Street Name, Suite X, City, State, Zip, Country </Text>
									<Flex mt="3" flexWrap={"wrap"}>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											All
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											General
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Contact
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Company
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Banking
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Tax
										</Text>
										<Text mb="1" bg={useGreenButton} px="3" py="1" borderRadius="10px" borderWidth="1px" borderStyle="dotted" mr="1" fontSize={viewType === "card" ? "12px" : "14px"}>
											Relationships
										</Text>
									</Flex>
								</Box>
							</Flex>
							<Box w="30%" textAlign={"right"}>
								<Box mb="3">
									<Menu>
										<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
											Actions
										</MenuButton>
										<MenuList>
											<MenuItem>Edit Details</MenuItem>
											<MenuItem>Contact</MenuItem>
											<MenuItem>Email / SMS</MenuItem>
											<MenuItem>Disable</MenuItem>
											<MenuItem>Activate</MenuItem>
											<MenuItem>Promote</MenuItem>
										</MenuList>
									</Menu>
								</Box>
								<Menu>
									<MenuButton fontWeight={"600"} borderRadius="10px" size="sm" as={Button} rightIcon={<ChevronDownIcon />} _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor} bg={pillButtonBg}>
										Review
									</MenuButton>
									<MenuList>
										<MenuItem>Jobs</MenuItem>
										<MenuItem>Banking</MenuItem>
										<MenuItem>Payments</MenuItem>
										<MenuItem>Billings</MenuItem>
										<MenuItem>Activities</MenuItem>
										<MenuItem>Reports</MenuItem>
										<MenuItem>Analysis</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Flex>
					</Card>
				</Flex>
			)}
		</>
	);
}

export default ClientData;

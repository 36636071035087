import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import {
	Box,
	Button,
	Heading,
	Spinner,
	Flex,
	Text,
	Icon,
	useToast,
	Spacer,
	Badge,
} from "@chakra-ui/react";
import {
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineCheckCircle,
	AiFillCheckCircle,
	AiFillCloseCircle,
	AiTwotonePhone,
} from "react-icons/ai";

// Component Imports
import { formatSeconds } from "../../../utils/TimeFunctions";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
	useGreenButton,
	useNoResultFoundText,
	useRedButton,
	useTextColor,
	useListItemBgColor,
	useListItemTextColor,
} from "../../../theme/globalColorTheme";

import { CALL_LIST_TYPE, LINGO_CARDS_FILTER } from "./constants";
import { getSubscriberCardCallList } from "../../../services/businessServices";
import { listSubLingoCard, fetchAccLocInfo } from "../../../services/accountServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";


const RenderSessions = memo(({ linkSessions: cardSessions }) => {
	const notFoundText = useNoResultFoundText()
	return cardSessions !== null && cardSessions?.length > 0
		? cardSessions?.map((item, index) => (
			<Flex w={'100%'} key={index}
				direction={'column'} gap={2} mt={1}>

				<Flex w={'100%'} borderWidth={'0.5px'} borderColor={'gray.500'} borderRadius={'md'} p={3}>
					<Flex w={'100%'} direction={'column'} align={'center'} justify={'space-between'}>
						<Flex w={'100%'}>
							<Text fontSize={'sm'}>
							{item?.call_start_time ? moment(item?.call_start_time).format("MMM DD,YYYY,HH:mm:ss a") : "00:00:00"}
							{" ... "}
							{item?.call_end_time ? moment(item?.call_end_time).format("MMM DD,YYYY,HH:mm:ss a") : "00:00:00"}
							</Text>
						</Flex>
						<Flex w={'100%'}>
							<Text fontSize={'sm'}>Caller Number:  {item?.caller_phone_number}</Text>
						</Flex>
						<Flex w={'100%'}>
							<Text fontSize={'sm'}>Target Number:  {item?.target_phone_number}</Text>
						</Flex>
						<Flex w={'100%'}>
							<Text fontSize={'sm'}>Language Name: {item?.language_name} (Code: {item?.language_code})</Text>
						</Flex>
					</Flex>
					<Flex w={'100%'}>
						<Text fontSize={'sm'}><Icon as={item?.pin_code_validation ? AiFillCheckCircle : AiFillCloseCircle} color={item?.pin_code_validation ? 'green.600' : 'red.600'} boxSize={5} /> </Text>
					</Flex>
					<Flex w={'100%'}>
						<Text fontSize={'sm'}><Icon as={item?.lingo_card_call_status === "Completed" ? AiFillCheckCircle : AiFillCloseCircle} color={item?.lingo_card_call_status === "Completed" ? 'green.600' : 'red.600'} boxSize={5} /></Text>
					</Flex>
					<Flex w={'250px'}>
						<Text>{formatSeconds(Math.round(item?.call_duration_minute, 2))}</Text>
					</Flex>
					{/* <Flex w={'150px'} direction={'column'}>
						<Text>Total Calls: {item?.iteration}</Text>
					</Flex> */}


				</Flex>
				{/* {item?.count > 0
					? <Flex w={'100%'} direction={'column'} gap={1}>
						<RenderCallHistory callSessions={item?.calls} />
					</Flex> : null} */}
			</Flex>
		))
		: <Flex w={'100%'} p={2}>
			<Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this LingoCard call</Text>
		</Flex>;
})

// const RenderCallHistory = memo(({ callSessions,rendering_child_call = false }) => {
// 	if (callSessions.length > 0) {
// 		return callSessions?.map((item, index) => {
// 			return (
// 				<Flex direction="column">
// 				<Flex
// 					w={"100%"}
// 					key={index} // #120303  f2f2f2
// 					//bg={item?.type === "audio" ? "#120303" : "blue.50"}					
// 					p={2}
// 					rounded={"md"}
// 					borderWidth={"0.05px"}
// 					borderColor={"gray.200"}
// 					bg={useListItemBgColor}
// 					color={useListItemTextColor}
// 				>
// 					<Flex minW={"30px"} alignItems="center">
// 						<Text>{index + 1}</Text>
// 					</Flex>
// 					<Flex minW={"50px"} alignItems="center">
// 						<Box
// 							w={"30px"}
// 							rounded={"md"}
// 							bg={
// 								item?.type === "audio"
// 									? "green.300"
// 									: "blue.300"
// 							}
// 							align={"center"}
// 							pt={1}
// 						>
// 							{ rendering_child_call 
// 								? <Icon h={5} w={5} as={AiTwotonePhone} /> : item?.type === "audio" ? (
// 								<Icon h={5} w={5} as={AiTwotoneAudio} />
// 							) : (
// 								<Icon as={AiOutlineVideoCamera} />
// 							)}
// 						</Box>
// 					</Flex>
// 					<Flex w={"100%"} direction={"column"}>
// 						<Text fontSize={"sm"}>
// 							{moment(item?.started).format(
// 								"MMM DD,YYYY,HH:mm:ss a"
// 							)}
// 							{" - "}
// 							{moment(item?.ended).format(
// 								"MMM DD,YYYY,HH:mm:ss a"
// 							)}
// 						</Text>
// 						<Text>
// 							<b>{item?.source_language}</b>{", "}
// 							<b>{item?.target_language} </b>
// 							{item?.agent_info
// 							? item?.type === "audio" ? <b> Agent: {JSON.parse(item?.agent_info?.replace(/'/g, '"'))?.agent}</b>
// 							: <Flex flexDir="column" fontSize="small">
// 								<Text>Agent Company: <b>{JSON.parse(item?.agent_info)?.company || "N/A"}</b></Text>
// 								<Text>Agent name: <b>{JSON.parse(item?.agent_info)?.name || "N/A"}</b></Text>
// 							</Flex>
// 							: null}
// 						</Text>
// 					</Flex>
// 					<Flex maxW={"80px"} minW={"80px"}>
// 						<Text>
// 							{formatSeconds(
// 								Math.round(item?.duration_seconds, 0)
// 							)}
// 						</Text>
// 					</Flex>
// 				</Flex>
// 				{item?.child_calls && item?.child_calls.length > 0 && (
//                         <Flex w={'100%'} direction={'column'} pl={8} pt={2}>
// 							<RenderCallHistory callSessions={item?.child_calls} rendering_child_call/>
//                                 </Flex>
//                             )}
// 				</Flex>
// 			);
// 		});
// 	}
// })


const LinkSubscriberLingoCardCallsHistory = props => {
	const redBg = useRedButton();
	const greenBg = useGreenButton();
	const [cardFilter, setCardFilter] = useState(LINGO_CARDS_FILTER.ACTIVE)
	const [cardsPageId, setCardsPageId] = useState(1)
	const [cards, setCards] = useState(null)
	const toast = useToast()
	// const [linkreport, setLinkReport] = useState([]);
	const [cardsLoading, setCardsLoading] = useState(true)
	const [isLoadMoreDisabled, setLoadMoreDisabled] = useState(false)
	const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false)
	const [cardSessions, setCardSessions] = useState(null)
	const [selectedLingoCard, setSelectedLingoCard] = useState('')
	const [cardHistoryLoader, setCardHistoryLoader] = useState(false)
	// const [billAmount, setBillAmount] = useState(0.0);
	// const [listTypeFilter, setListTypeFilter] = useState(CALL_LIST_TYPE.ONLY_CALLS)	
	const [accountUuid, setAccountUuid] = useState(null);
	const [locationUuid, setLocationUuid] = useState(null);
	const [accInfoLoading, setAccInfoLoading] = useState(false);
	const [totalCalls, setTotalCalls] = useState(0);
	const [callsFailed, setCallsFailed] = useState(0);
	const [validationFailed, setValidationFailed] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	// const blackWhiteText = useTextColor()

	// const calculateBilling = (link) => {
	// 	let linkUuid = link?.uuid
	// 	let linkCode = link?.code
	// 	let linkKey = link?.key
	// 	if (linkUuid != null && linkCode != null && linkKey != null) {
	// 		// isLoading(true);
	// 		let paramObj = {
	// 			linkUuid: linkUuid,
	// 			linkKey: linkKey
	// 		};
	// 		calculateUserLinkCalls(paramObj)
	// 			.then((res) => {
	// 				// isLoading(false);
	// 				if (res?.data[0]?.status) {
	// 					// isLoading(false);
	// 					setBillAmount(res?.data[0]?.amount);
	// 					// mergeBillingData(res?.data[0]?.sessions);
	// 				} else {
	// 					// isLoading(false);
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				// isLoading(false);
	// 			});
	// 	}
	// };


	const collectAccountLocationInfo = () => {
		setAccInfoLoading(true)
		let paramObj = {
			key: 'subscriber',
		};
		fetchAccLocInfo(paramObj)
			.then(res => {
				if (res?.data[0]?.status) {
					setAccountUuid(res?.data[0]?.accUuid || "")
					setLocationUuid(res?.data[0]?.locUuid || "")
				} else {
					setAccInfoLoading(false)
					setAccountUuid("")
					setLocationUuid("")
				}
			})
			.catch(err => {
				setAccInfoLoading(false)
				setAccountUuid("")
				setLocationUuid("")
			});
	};

	async function getAllLingoCardCalls() {
		try {
			if(accountUuid) {
				setAccInfoLoading(true);

				const paramObj = {
					"link_account_uuid": accountUuid,
					"link_location_uuid": locationUuid,
					"key": cardFilter,
					page_id: cardsPageId,
					records_per_page: 10,
				};
				
				cards?.length ? setShowLoadMoreLoading(true) : setCardsLoading(true);
				//getSubscriberCardCallList
				const resp = await listSubLingoCard(paramObj);
				
				const records = resp.data[0]?.records; 
				
				if (!records?.length) {
					setLoadMoreDisabled(true);
					setCardsLoading(false);
					setShowLoadMoreLoading(false);
					setAccInfoLoading(false);
					return;
				}
				setAccInfoLoading(false);				
				setLoadMoreDisabled(false);
				setCards(prevLinks => cards?.length ? [...prevLinks, ...records] : records);
			}

		} catch (err) {
			toast(toastFunctionToaster("Failed to get lingoCards", "error"));
			setCards([]);
			setAccInfoLoading(false);
		} finally {
			setCardsLoading(false);
			setShowLoadMoreLoading(false);
		}
	}

	function getCardCallHistory(uuid, key) {
		const paramObj = {
			account_uuid: null,
			page_id: 1,
			records_per_page: 10,
		}
		setCardHistoryLoader(true)
		getSubscriberCardCallList(paramObj).then(res => {
			if (res?.data?.[0]?.records?.length) {
				const filteredCardCalls = res?.data?.[0]?.records?.filter(item => item?.lingo_card_uuid === uuid);
				setCardSessions(filteredCardCalls);
				setCardSessions(filteredCardCalls);
				setTotalCalls(filteredCardCalls.length);
				setCallsFailed(filteredCardCalls?.filter(item => item?.lingo_card_call_status !== "Completed").length);
				setValidationFailed(filteredCardCalls?.filter(item => !item?.pin_code_validation).length);
				
				const totalTimeInMinutes = filteredCardCalls?.reduce((acc,t) => acc += t?.call_duration_minute, 0);
				setTotalTime(formatMinutesToHoursAndMinutes(totalTimeInMinutes));
				// setLinkReport(res?.data[0]?.report);
			} else {
				setCardSessions([])
			}
		}).catch(err => {
			toast(toastFunctionToaster("Failed to get Call history", "error"))
			setCardSessions([])

		}).finally(() => {
			setCardHistoryLoader(false)
		})
	}

	function handleLoadMoreLinks() {
		setCardsPageId(cardsPageId => cardsPageId + 1)
	}

	function handleShowCardCalls(item) {
		setSelectedLingoCard(item?.lingo_card_uuid)
		getCardCallHistory(item?.lingo_card_uuid, item?.lingo_card_key)
	}

	function formatMinutesToHoursAndMinutes(totalMinutes) {
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;

		let result = '';
		if (hours > 0) {
			result += `${hours} hour${hours > 1 ? 's' : ''}`;
		}
		if (minutes > 0) {
			result += (result ? ', ' : '') + `${minutes} minute${minutes > 1 ? 's' : ''}`;
		}

		return result || '0 minutes';
	}

	useEffect(() => {
		collectAccountLocationInfo();
	},[])

	useEffect(() => {
		getAllLingoCardCalls()
	}, [accountUuid, locationUuid, cardFilter, cardsPageId])

	useEffect(() => {
		setCardsPageId(1)
		setCards([])
		setSelectedLingoCard(null)
		setCardSessions(null)
	}, [cardFilter])


	return (
		<Flex pt={{ sm: "125px", lg: "75px" }} pl={3} h={'94vh'}>
			
			<Card w={"80%"} mt={2} mr={2} h="100%">
				{
					cardHistoryLoader ? <Flex w="100%" justifyContent="center">
						<Spinner
							thickness='2px'
							speed='0.65s'
							size='xl'
						/>
					</Flex> : <>
						{!selectedLingoCard ? <Flex
							w={"100%"}
							p={5}
							bg={'red.100'}
							borderLeftWidth={"5px"}
							borderLeftColor={"red.500"}
						>
							<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
								Click the lingo cards on the right for call history.
							</Heading>
						</Flex> :
							<>
								{cardSessions?.length ? <>
									<Flex
										h="100%"
										w={"100%"}
										direction={"column"}
										p={2}
										mt={2}
										borderBottomWidth={"0.05px"}
										borderBottomColor={"gray.200"}
										alignItems='center'
										overflow="hidden"
									>
										{/* <Flex w={"100%"}>
						<Flex w={"50px"}>
							<Text>{index + 1}</Text>
						</Flex>
						<Flex w={"100%"} direction={"column"}>
							<Text fontSize={"sm"}>
								{moment(item?.created).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
							</Text>
							<Text fontWeight={500} color={"gray.400"}>
								{item?.session}
							</Text>
						</Flex>
						<Flex w={"150px"} direction={"column"}>
							<Text>Total Calls: {item?.count}</Text>
						</Flex>
						<Flex w={"250px"} direction={"column"}>
							<Text>
								Call Duration:{" "}
								{formatSeconds(Math.round(item?.total, 2))}
							</Text>
						</Flex>
					</Flex> */}
										<Flex w={"100%"} direction={"column"} position="relative" h="100%" overflowY="hidden">
										<Flex w={"100%"} direction={"column"} h="100%" overflowY="auto" className="custom-scrollbar" pb={"20"}>
											{ cardSessions && 
												<Flex w={"100%"} direction={"row"} p={3}>
													<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
														Call Information
													</Heading>
													<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
														Validation
													</Heading>
													<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
														Status
													</Heading>
													<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
														Call Duration(m)
													</Heading>
												</Flex>}
											<RenderSessions linkSessions={cardSessions}/>
											{ cardSessions && 
												<Flex w={"100%"} direction={"row"} p={3} align={'center'} position="absolute" borderTop={"1px"} borderTopColor={"gray.600"} bottom={0} right={0}>
													<Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
														Total Calls: {totalCalls}
													</Text>
													<Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
														Failed Validation: {validationFailed}
													</Text>
													<Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
														Failed Calls: {callsFailed}
													</Text>
													<Text w={"100%"} fontSize={"sm"} fontWeight={"normal"} color="black">
														Total Time: {totalTime}
													</Text>
												</Flex>}
										</Flex>
											{/* {linkreport?.length > 0
												? <Flex w={'100%'} h={'content'} bg={'gray.300'} align={'center'} fontSize="smaller" position="absolute" bottom={0} right={0} p={2} borderRadius="md" color="black">
													<Box align={'start'}>
														<Text ml={1}>Total Sessions: <Badge color={blackWhiteText} bg={greenBg}>{linkreport[0]}</Badge></Text>
													</Box>
													<Box align={'start'} ml={2}>
														<Text ml={1}>Total Calls (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>{linkreport[1]}</Badge></Text>
													</Box>
													<Box align={'start'} ml={2}>
														<Text ml={1}>Total Time used (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>{formatSeconds(Math.round(linkreport[2], 2))}</Badge></Text>
													</Box>
													<Spacer />
													{billAmount >= 0.0
														? <Box align={'end'} ml={2}>
															<Text ml={1}>Total Charged $ Amount (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>${billAmount.toFixed(2)}</Badge></Text>
														</Box> : null}
														<Box
                                                            onClick={() => calculateBilling()}
                                                            align={'end'} ml={2} mr={'1'}>
                                                            <Button size={'xs'} colorScheme="green">Calculate Amount</Button>
                                                        </Box>
												</Flex>
												: <Flex py={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
													<Text ml={1}>No Report...</Text>
												</Flex>} */}
										</Flex>
									</Flex>
								</> : <Flex
									w={"100%"}
									p={5}
									bg={'red.100'}
									borderLeftWidth={"5px"}
									borderLeftColor={"red.500"}
								>
									<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
										This Lingo card does not have any usage.
									</Heading>
								</Flex>}
							</>
						}
					</>
				}

			</Card>
			<Card w={"20%"} mt={2} p={2}>
				<Flex flexDir="column" w="full" alignItems="center" h="100%">
					<Text as="h3" fontWeight="bold" fontSize="large">LingoCards</Text>
					<Flex align={"center"} p={2} w="full">
						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedLeft={"lg"}
							bg={cardFilter === LINGO_CARDS_FILTER.ACTIVE ? "green.600" : greenBg}
							color={cardFilter === LINGO_CARDS_FILTER.ACTIVE ? "white" : "inherit"}
							size="sm"
							onClick={() => {
								setCardFilter(LINGO_CARDS_FILTER.ACTIVE);
							}}>
							{cardFilter === LINGO_CARDS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Active
						</Box>

						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedRight={"lg"}
							bg={cardFilter === LINGO_CARDS_FILTER.CLOSED ? "red.400" : redBg}
							color={cardFilter === LINGO_CARDS_FILTER.CLOSED ? "white" : "inherit"}
							size="sm"
							onClick={() => {
								setCardFilter(LINGO_CARDS_FILTER.CLOSED);
							}}>
							{cardFilter === LINGO_CARDS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Closed
						</Box>
					</Flex>
					<Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
					>
						<Flex flexDir="column" minH="100%">
							{
								cardsLoading ? <Flex w="100%" justifyContent="center">
									<Spinner
										thickness='2px'
										speed='0.65s'
										size='xl'
									/>
								</Flex> :
									<Flex flexDir="column">
										{
											(cards && cards?.length) ? cards.map((card) => (
												<Flex flexDir="column" gap={1} p="2"
													cursor="pointer"
													_hover={{ bg: "blue.100", color:'black' }}
													mb="2"
													borderWidth="1px"
													borderColor="#d7d7d7"
													borderRadius="5px"
													bg={selectedLingoCard === card.lingo_card_uuid ? greenBg : null}
													onClick={() => handleShowCardCalls(card)}
												>
													<Text fontWeight="bold">{card?.lingo_card_title}</Text>
													{/* <Text fontSize="smaller">{link?.url}</Text> */}
												</Flex>
											)) : null
										}
										{(cards && cards?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
									</Flex>
							}
						</Flex>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	);
};

export default LinkSubscriberLingoCardCallsHistory;

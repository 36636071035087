// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "../../../../components/charts/BarChart";

// Custom components
import Card from "../../../../components/card/Card";

export default function LinkTraffic(props) {
	const eventStatsData = props?.eventStats[0]?.events_detail;
	const { ...rest } = props;

	const barChartDataDailyTraffic = [
		{
			name: "Subscriber Link",
			data: Object.values(eventStatsData),
		},
	];

	const barChartOptionsDailyTraffic = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		tooltip: {
			style: {
				fontSize: "12px",
				fontFamily: undefined,
			},
			onDatasetHover: {
				style: {
					fontSize: "12px",
					fontFamily: undefined,
				},
			},
			theme: "dark",
		},
		xaxis: {
			categories: ["Created", "Disabled", "Shared", "Reset", "Updated", "Keywords", "Design", "App Update", "Manage Design"],
			show: false,
			labels: {
				show: true,
				style: {
					colors: "#A3AED0",
					fontSize: "14px",
					fontWeight: "500",
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			show: false,
			color: "black",
			labels: {
				show: true,
				style: {
					colors: "#CBD5E0",
					fontSize: "14px",
				},
			},
		},
		grid: {
			show: false,
			strokeDashArray: 5,
			yaxis: {
				lines: {
					show: true,
				},
			},
			xaxis: {
				lines: {
					show: false,
				},
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				type: "vertical",
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				colorStops: [
					[
						{
							offset: 0,
							color: "#4318FF",
							opacity: 1,
						},
						{
							offset: 100,
							color: "rgba(67, 24, 255, 1)",
							opacity: 0.28,
						},
					],
				],
			},
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				columnWidth: "40px",
			},
		},
	};

	const newOptions = {
		...barChartOptionsDailyTraffic,
		fill: {
			...barChartOptionsDailyTraffic.fill,
			gradient: {
				type: "vertical",
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				colorStops: [
					[
						{
							offset: 0,
							color: "var(--chakra-colors-brand-500)",
							opacity: 1,
						},
						{
							offset: 100,
							color: "white",
							opacity: 0.28,
						},
					],
				],
			},
		},
	};

	// Chakra Color Mode
	return (
		<Card alignItems="center" flexDirection="column" w="100%" {...rest}>
			<Box h="240px" mt="auto" w="100%">
				<BarChart chartData={barChartDataDailyTraffic} chartOptions={newOptions} />
			</Box>
		</Card>
	);
}

import React from 'react'
import { Box, color, Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import logo from "../../..//media/images/Lingolet-200x200.png"

const CompaniesInvested = () => {
  return (<>
    <Box mb={10} bg="teal.900">
      <Heading as="h2" size="4xl" p={6} color="white">
        Companies
      </Heading>
    </Box>
    <Flex w="full" flexDir="column" alignItems="center">
      <Flex color="gray.500" justifyContent="space-around" w="50%" mb={6}>
        <Text fontWeight="semibold" _hover={
          { color: "black" }
        }>Consumer</Text>
        <Text fontWeight="semibold" _hover={
          { color: "black" }
        }>Crypto</Text>
        <Text fontWeight="semibold" _hover={
          { color: "black" }
        }>Enterprise</Text>
        <Text fontWeight="semibold" _hover={
          { color: "black" }
        }>Fintech</Text>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={14} p={5}>
        {Array.from({ length: 6 }, (_, index) => (
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="250px"
            height="200px"
            overflow="hidden"
            bg="#f7f9fa"
            css={css`
        &:hover .overlay {
          opacity: 1;
          transform: scale(1);
        }
      `}
          >
            <Flex alignItems="center" justifyContent="center">
              <Image src={logo} alt="Placeholder Image" width="50%" height="50%" />
            </Flex>

            <Box
              className="overlay"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="teal.800"
              color="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity="0"
              transition="opacity 0.6s ease"
            >
              <Flex flexDir="column" alignItems="center">
                <Text fontSize="4xl" as="h1" fontWeight="extrabold">Lingolet</Text>
                <Text fontSize="xl" as="h4" color="blue.200" fontWeight="bold">AI</Text>
                <Text fontSize="medium" as="h4" mt="2" fontWeight='semibold'>READ MORE</Text>
              </Flex>
            </Box>
          </Box>
        ))}

      </SimpleGrid>
    </Flex>
  </>

  );
}

export default CompaniesInvested


// React and Chakra Imports
import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Button,
    SimpleGrid,
    Spacer,
    Flex,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';

import {
	MdPerson,
	MdPhone,
	MdMan,
    MdMicExternalOn,
	MdVideoCall,
	MdLocationOn,
} from "react-icons/md";

// Component Imports
import IconBox from "../../../../components/icons/IconBox";
import Card from "../../../../components/card/Card";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import { useTextColor } from '../../../../theme/globalColorTheme';

import { getAccountDbLocationCount} from "../../../../services/accountServices";

function AccountMetrics({locationEnable, loading, accountData}) {
	const brandColor = useColorModeValue("brand.500", "white");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");

    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

	const [currentChoice, setCurrentChoice] = useState('account');

    return (
		<Flex w={"100%"} direction={"column"}>
            <SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }} gap="10px" mb="10px">
                <MiniStatistics
                    launchUrl='/account/billing'
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="28px" h="28px" as={MdMan } color="green.500" />}
                        />
                    }
                    name="Total Human Interpretation Minutes Used"
                    comment="(This Month)"
                    value={accountData?.hi_minutes?.toFixed(2)}
                    loading={loading}
                />
                <MiniStatistics
                    launchUrl='/account/billing'
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="28px" h="28px" as={MdMan} color="red.500" />}
                        />
                    }
                    name="Total Secure AI Minutes Used"
                    comment="(This Month)"
                    value={accountData?.ai_minutes?.toFixed(2)}
                    loading={loading}
                />
                {/* <MiniStatistics
                    launchUrl='/account/locations'
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="28px" h="28px" as={MdMan} color={brandColor} />}
                        />
                    }
                    name="Subscriber Locations"
                    value={accountData?.total_sub_locations}
                    loading={loading}
                /> */}
            </SimpleGrid>
            <SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap="10px" mb="10px">
                <MiniStatistics
                    launchUrl='/account/billing'
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                            icon={<Icon w='28px' h='28px' as={MdVideoCall} color='white' />}
                        />
                    }
                    name="Total Video Calls"
                    comment="(Human Interpretation)"
                    value={accountData?.video_calls}
                    loading={loading}
                />
                <MiniStatistics
                    launchUrl='/account/billing'
                    startContent={
                        <IconBox
                        w='56px'
                        h='56px'
                        bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                        icon={<Icon w='28px' h='28px' as={MdPhone} color='white' />}
                        />
                    }
                    name="Total Audio Calls"
                    comment="(Human Interpretation)"
                    value={accountData?.audio_calls}
                    loading={loading}
                />
                <MiniStatistics
                    launchUrl='/account/billing'
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                            icon={<Icon w='28px' h='28px' as={MdMicExternalOn} color='white' />}
                        />
                    }
                    name="Total Audio Calls"
                    comment="(Secure AI)"
                    value={accountData?.ai_calls}
                    loading={loading}
                />
            </SimpleGrid>
		</Flex>
	);
}

export default AccountMetrics;

// React Imports
import React, { useState } from "react";
import { Table, Thead, Tbody, Box, Tr, Th, Td, TableContainer, Avatar } from "@chakra-ui/react";

// Component Imports
import Pagination from "../../../../../components/pagination/Pagination";
import { useGreyBorderColor } from "../../../../../theme/globalColorTheme";

function ClientTable() {
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	return (
		<Box mt="5">
			<TableContainer>
				<Table variant="simple" borderWidth={"1px"} borderColor={useGreyBorderColor}>
					<Thead>
						<Tr>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								Name
							</Th>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								Logo
							</Th>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								Street Name
							</Th>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								State
							</Th>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								Country
							</Th>
							<Th borderRightWidth={"1px"} borderBottomWidth={"1px"} borderColor={useGreyBorderColor}>
								Zip
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								ABC CORPORATION
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								<Avatar size="sm" name="ABC CORPORATION"></Avatar>
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								1234, Street Name, Suite X
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								Maharashtra
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								India
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								400706
							</Td>
						</Tr>
						<Tr>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								ABC CORPORATION
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								<Avatar size="sm" name="ABC CORPORATION"></Avatar>
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								1234, Street Name, Suite X
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								Maharashtra
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								India
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								400706
							</Td>
						</Tr>
						<Tr>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								ABC CORPORATION
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								<Avatar size="sm" name="ABC CORPORATION"></Avatar>
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								1234, Street Name, Suite X
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								Maharashtra
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								India
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								400706
							</Td>
						</Tr>
						<Tr>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								ABC CORPORATION
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								<Avatar size="sm" name="ABC CORPORATION"></Avatar>
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								1234, Street Name, Suite X
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								Maharashtra
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								India
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								400706
							</Td>
						</Tr>
						<Tr>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								ABC CORPORATION
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								<Avatar size="sm" name="ABC CORPORATION"></Avatar>
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								1234, Street Name, Suite X
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								Maharashtra
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								India
							</Td>
							<Td borderBottomWidth={"1px"} borderBottomColor={useGreyBorderColor}>
								400706
							</Td>
						</Tr>
					</Tbody>

					{/* <Tfoot>
						<Tr>
							<Th>To convert</Th>
							<Th>into</Th>
							<Th isNumeric>multiply by</Th>
						</Tr>
					</Tfoot> */}
				</Table>
			</TableContainer>
			<Pagination currentPage={currPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} recordsPerPage={recordsPerPage} />
		</Box>
	);
}

export default ClientTable;

import { Flex, Text } from '@chakra-ui/react';
import React from 'react'
import { useState } from 'react';
import {  useSearchParams } from 'react-router-dom';
import { getPageConfig } from '../../../services/sharedServices';
import { useEffect } from 'react';
import VerifyControlAccess from '../../documentai/shareControl/VerifyControlAccess';

const DocumentControlAccess = () => {
    const [supportEmail, setSupportEmail] = useState(null)
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type')
    const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;


    useEffect(() => {
        if (subdomain != null) {
            let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
            fetchConfDetails(subdomain_val);
        }
    }, []);

    const fetchConfDetails = (sub_domain) => {
        let paramObj = {
            subdomain: sub_domain,
        };
        getPageConfig(paramObj)
            .then((res) => {
                if (res?.data?.length > 0) {
                    if (res?.data[0]?.info?.support) setSupportEmail(res?.data[0]?.info?.support)

                }
            })
            .catch(() => {
            });
    };


    if (!type) {
        return <Flex justifyContent="center" gap="1"><Text fontSize="xx-large">Link is not valid</Text> {supportEmail && <Text fontSize="xx-large">please contact {supportEmail}</Text>}</Flex>
    }



    return (
        <>
        {
            type === "listing" ? <VerifyControlAccess space_name={searchParams.get('space')}/> : null
        }
        </>
    )
}

export default DocumentControlAccess
import { Badge, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Heading, Icon, ListItem, Spacer, Spinner, Text, UnorderedList, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'
import { memo } from 'react'
import { MdOutlinePhoneForwarded, MdOutlineVideoCall } from 'react-icons/md'
import { FaMicrophoneAlt } from 'react-icons/fa'
import { AiFillCheckCircle, AiFillExclamationCircle, AiOutlineAudio, AiOutlineCaretRight } from 'react-icons/ai'
import { FaStar } from 'react-icons/fa6'
import { useState } from 'react'
import { formatSeconds } from '../../../../utils/TimeFunctions'
import { useGreenButton, useListItemBgColor, useListItemTextColor, useNoResultFoundText, useTextColor } from '../../../../theme/globalColorTheme'
import { ACC_CALL_HISTORY } from '../reportingTab/constants'
import { getCallTranscript, shareCallTranscript } from '../../../../services/businessServices'
import { toastFunctionToaster } from '../../../../utils/toastFunction'
import TagsInput from 'react-tagsinput'
import Card from '../../../../components/card/Card'

const RenderSessions = memo(({ linkSessions, listTypeFilter, linkreport, selectedLingoLink, handleShareTranscript }) => {
    const notFoundText = useNoResultFoundText()
    if (linkreport[1] === 0 && listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS) {
        return (
            <Flex w={'100%'} p={2}>
                <Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>calls</b> in this LingoLink</Text>
            </Flex>
        )
    }
    return linkSessions !== null && linkSessions?.length > 0
        ? linkSessions?.map((item, index) => (
            <Flex w={'100%'} key={index}
                direction={'column'} gap={2} mt={1}>
                {listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION &&

                    <Flex w={'100%'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'} p={2} >
                        <Flex w={'50px'}>
                            <Text >{index + 1}</Text>
                        </Flex>
                        <Flex w={'250px'}>
                            <Text fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text fontWeight={500} color={'gray.400'}>{item?.session}</Text>
                        </Flex>
                        <Flex w={'150px'} direction={'column'}>
                            <Text>Total Calls: {item?.count}</Text>
                        </Flex>
                        <Flex w={'250px'} direction={'column'}>
                            <Text>Call Duration: {formatSeconds(Math.round(item?.total, 2))}</Text>
                        </Flex>

                    </Flex>}
                {item?.count > 0
                    ? <Flex w={'100%'} direction={'column'} gap={1}>
                        <RenderCallHistory callSessions={item?.calls} selectedLingoLink={selectedLingoLink} handleShareTranscript={handleShareTranscript} />
                    </Flex> : null}
            </Flex>
        ))
        : <Flex w={'100%'} p={2}>
            <Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this LingoLink</Text>
        </Flex>;
})

const RenderAgentInfo = ({ agent_info }) => {
    if (agent_info) {
        let agent_json = JSON.parse(agent_info?.replace(/'/g, '"'))
        if (agent_json?.agent) {
            return <b>{agent_json?.agent}</b>
        }
        if (agent_json?.company) {
            return (
                <Flex flexDir="column" fontSize="small">
                    <b>Human Interpretation</b>
                    {/* <Text>Agent Company: <b>{agent_json?.company || "N/A"}</b></Text>
                    <Text>Agent name: <b>{agent_json?.name || "N/A"}</b></Text> */}
                </Flex>
            )
        }
    }
}

const RenderCallIconBox = ({ callProvider, callType, isSecureAiWithPhoneCallOut }) => {
    let bgColor = "blue.300";
    let icon = MdOutlineVideoCall;
    if (callProvider === "secureai" || callProvider === "basicai") {
        bgColor = "green.300";

        if (callType === "audio") {
            icon = isSecureAiWithPhoneCallOut ? MdOutlinePhoneForwarded : FaMicrophoneAlt;
        } else {
            icon = MdOutlinePhoneForwarded;
        }
    } else {
        if (callType === "audio") {
            icon = AiOutlineAudio;
        }
    }

    return (
        <Box
            w="30px"
            rounded="md"
            bg={bgColor}
            align="center"
            pt={1}
        >
            <Icon as={icon} />
        </Box>
    );
};

const RenderCallHistory = memo(({ callSessions, selectedLingoLink, handleShareTranscript }) => {
    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
    const toast = useToast()
    const [transcript, setTranscript] = useState({})

    function handleGetTranscript(call_uuid) {
        if (transcript[call_uuid]) {
            transcript[call_uuid]?.show ?
                setTranscript((transcript) => {
                    transcript[call_uuid].show = false;
                    return { ...transcript };
                }) : setTranscript((transcript) => {
                    transcript[call_uuid].show = true;
                    return { ...transcript };
                })
        } else {
            const paramObj = {
                call_uuid,
                link_key: selectedLingoLink?.key,
                link_uuid: selectedLingoLink?.uuid,
            };

            setTranscript((prevTranscript) => ({
                ...prevTranscript,
                [call_uuid]: { show: true, isLoading: true },
            }));

            getCallTranscript(paramObj)
                .then((resp) => {
                    try {
                        const transcriptData = JSON.parse(resp?.data?.[0]?.data?.transcript_text || "null");
                        setTranscript((prevTranscript) => ({
                            ...prevTranscript,
                            [call_uuid]: { transcript: transcriptData, show: true, isLoading: false },
                        }));
                    } catch (err) {
                        setTranscript((prevTranscript) => ({
                            ...prevTranscript,
                            [call_uuid]: { show: true, isLoading: false, error: "Failed to load transcript." },
                        }));
                        toast(toastFunctionToaster("Failed to parse transcript data", "error"));
                    }
                })
        }
    }


    if (callSessions.length > 0) {
        return callSessions?.map((item, index) => {
            return (
                <Flex direction="column">
                    <Flex
                        w={"100%"}
                        key={index} // #120303  f2f2f2
                        //bg={item?.type === "audio" ? "#120303" : "blue.50"}					
                        p={2}
                        gap={2}
                        rounded={"md"}
                        borderWidth={"0.05px"}
                        borderColor={"gray.200"}
                        bg={useListItemBgColor}
                        direction="column"
                        color={useListItemTextColor}
                    >
                        <Flex w="full">
                            <Flex minW={"30px"} maxW={"100px"} mr="2" alignItems="center">
                                <RenderAgentInfo agent_info={item?.agent_info} />
                            </Flex>
                            <Flex minW={"50px"} alignItems="center">
                                <RenderCallIconBox callProvider={item?.provider} callType={item?.type} isSecureAiWithPhoneCallOut={item?.parent_call_uuid} />
                            </Flex>
                            <Flex flex="1" direction={"column"}>
                                <Text fontSize={"sm"}>
                                    {moment(item?.started).format(
                                        "MMM DD,YYYY,HH:mm:ss a"
                                    )}
                                    {" - "}
                                    {moment(item?.ended).format(
                                        "MMM DD,YYYY,HH:mm:ss a"
                                    )}
                                </Text>
                                <Text>
                                    <b>{item?.source_language}</b>{", "}
                                    <b>{item?.target_language} </b>
                                </Text>
                            </Flex>
                            {item?.feedback && <Flex alignItems="center" flex="1">
                                {
                                    Array(item.feedback?.star_rating).fill(" ").map((_, index) => (<Icon as={FaStar} boxSize={8} color={"yellow.400"} height="8" />))
                                }
                            </Flex>}
                            {item?.transcript && <Button
                                size="sm"
                                _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                                color={blackWhiteColor}
                                bg={pillButtonBg}
                                mr="6"
                                onClick={() => handleGetTranscript(item?.uuid)}
                            >
                                {transcript[item?.uuid]?.show ? "Hide" : "Show"} Transcript
                            </Button>}
                            <Flex maxW={"50px"} minW={"50px"}>
                                {item?.status
                                    ? <Icon as={AiFillCheckCircle} mt={1} color={'green'} />
                                    : <Icon as={AiFillExclamationCircle} mt={1} color={'red.500'} />}
                            </Flex>
                            <Flex maxW={"80px"} minW={"80px"}>
                                <Text>
                                    {formatSeconds(
                                        Math.round(item?.duration_seconds, 0)
                                    )}
                                </Text>
                            </Flex>
                        </Flex>
                        {item.feedback?.comments?.length && <Card w="full" p="2">
                            <Text fontWeight="bold" fontSize="large">Call feedback</Text>
                            <Flex direction="column" gap="2">
                                <UnorderedList>
                                    {
                                        item?.feedback?.comments?.map(comment => (
                                            <ListItem>{comment}</ListItem>
                                        ))
                                    }
                                </UnorderedList>
                            </Flex>
                        </Card>}
                        {transcript[item?.uuid] && transcript[item?.uuid]?.show && (
                            <>
                                {transcript[item?.uuid]?.isLoading ? (
                                    <Flex justifyContent="center" alignItems="center" mt={4}>
                                        <Spinner size="lg" color="black" speed='0.65s' emptyColor='gray.200' />
                                    </Flex>
                                ) : (
                                    <Card w="full" p="2" mt={4} overflow="hidden">
                                        <Flex alignItems="center" justifyContent="space-between" p='1.5' borderBottom={'1px'} borderColor={useTextColor} mb="1">
                                            <Text fontWeight="bold" fontSize="large">Call Transcript</Text>
                                            <Button
                                                size="sm"
                                                _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                                                color={blackWhiteColor}
                                                bg={pillButtonBg}
                                                mr="6"
                                                onClick={() => handleShareTranscript(item?.uuid)}
                                            >
                                                Email Transcript
                                            </Button>
                                        </Flex>
                                        <Flex direction="column" gap="2" maxH="350px" overflow="auto" className='custom-scrollbar'>
                                            {transcript[item?.uuid]?.transcript?.map((data, index) => {
                                                const polyglot = data?.communication_polyglots?.[0];
                                                return (
                                                    <Flex w="full" gap={2} key={index}>
                                                        <Flex flex="1" gap="2">
                                                            <Flex>
                                                                <Icon pt={2} as={AiOutlineCaretRight} fontSize="lg" />
                                                                <Text>{data?.language || "Unknown Language"}:</Text>
                                                            </Flex>
                                                            <Text> {data?.text || "No text available"}</Text>
                                                        </Flex>

                                                        {polyglot && (
                                                            <Flex flex="1" gap="2">
                                                                <Flex>
                                                                    <Icon pt={2} as={AiOutlineCaretRight} fontSize="lg" />
                                                                    <Text>{polyglot?.language || "Unknown Polyglot"}:</Text>
                                                                </Flex>
                                                                <Text> {polyglot?.text || "No text available"}</Text>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                );
                                            })}
                                        </Flex>
                                    </Card>
                                )}
                            </>
                        )}



                    </Flex>
                    {item?.child_calls && item?.child_calls.length > 0 && (
                        <Flex w={'100%'} direction={'column'} pl={8} pt={2}>
                            <RenderCallHistory callSessions={item?.child_calls} selectedLingoLink={selectedLingoLink} handleShareTranscript={handleShareTranscript} />
                        </Flex>
                    )}

                </Flex>
            );
        });
    }
})

const LingoLinkHistory = ({ selectedLingoLink, linkSessions, listTypeFilter, linkreport, billAmount, calculateBilling }) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const [shareTranscriptCallUuid, setShareTranscriptUuid] = useState(null)
    const [toEmails, setToEmails] = useState([])
    const [btnLoading, setBtnLoading] = useState({})
    const toast = useToast()

    function handleShareTranscript(call_uuid) {
        setShareTranscriptUuid(call_uuid)
        onOpen()
    }

    function onDrawerClose() {
        setShareTranscriptUuid(null)
        setToEmails([])
        onClose()
    }

    function handleShareTranscriptApi(){
        setBtnLoading(btnLoading=>({...btnLoading, send_transcript:true}))
        const paramObj = { 
                "target_emails": toEmails.join(","),
                "call_uuid": shareTranscriptCallUuid,
                "link_uuid": selectedLingoLink?.uuid,
                "link_key": selectedLingoLink?.key
              }
        
        shareCallTranscript(paramObj).then(resp=>{
            if(resp?.data?.status){
                toast(toastFunctionToaster(resp?.message, "success"))
                onDrawerClose()
            }else{
                toast(toastFunctionToaster(resp?.message, "error"))
                }
        }).catch(err=>{
            toast(toastFunctionToaster("Failed to send Email", "success"))
        }).finally(()=>{
            setBtnLoading(btnLoading=>({...btnLoading, send_transcript:false}))
        })
    }
    return (
        <>
            {!selectedLingoLink ? <Flex
                w={"100%"}
                p={1}
                bg={'red.100'}
                borderLeftWidth={"5px"}
                borderLeftColor={"red.500"}
            >
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                    Click the lingo link on the right for link usage history.
                </Heading>
            </Flex> :
                <>
                    {linkSessions?.length ? <>
                        <Flex
                            h="100%"
                            w={"100%"}
                            direction={"column"}
                            p={1}
                            mt={0}
                            borderBottomWidth={"0.05px"}
                            borderBottomColor={"gray.200"}
                            alignItems='center'
                            overflow="hidden"
                        >
                            <Flex w={"100%"} direction={"column"} position="relative" h="100%" overflowY="hidden">
                                <Flex w={"100%"} direction={"column"} h="100%" overflowY="auto" className="custom-scrollbar" pb={"20"}>
                                    <RenderSessions linkSessions={linkSessions} listTypeFilter={listTypeFilter} linkreport={linkreport} selectedLingoLink={selectedLingoLink} handleShareTranscript={handleShareTranscript} />
                                </Flex>
                                {linkreport?.length > 0
                                    ? <Flex w={'100%'} h={'content'} bg={'gray.300'} align={'center'} fontSize="smaller" position="absolute" bottom={0} right={0} p={2} borderRadius="md" color="black">
                                        <Box align={'start'}>
                                            <Text ml={1}>Total Sessions: <Badge color={useTextColor} bg={useGreenButton}>{linkreport[0]}</Badge></Text>
                                        </Box>
                                        <Box align={'start'} ml={2}>
                                            <Text ml={1}>Total Calls (in all sessions): <Badge color={useTextColor} bg={useGreenButton}>{linkreport[1]}</Badge></Text>
                                        </Box>
                                        <Box align={'start'} ml={2}>
                                            <Text ml={1}>Total Time used (in all sessions): <Badge color={useTextColor} bg={useGreenButton}>{formatSeconds(Math.round(linkreport[2], 2))}</Badge></Text>
                                        </Box>
                                        <Spacer />
                                        {billAmount >= 0.0
                                            ? <Box align={'end'} ml={2}>
                                                <Text ml={1}>Total Charged $ Amount (in all sessions): <Badge color={useTextColor} bg={useGreenButton}>${billAmount.toFixed(2)}</Badge></Text>
                                            </Box> : null}
                                        <Box
                                            onClick={() => calculateBilling()}
                                            align={'end'} ml={2} mr={'1'}>
                                            <Button size={'xs'} colorScheme="green">Calculate Amount</Button>
                                        </Box>
                                    </Flex>
                                    : <Flex py={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
                                        <Text ml={1}>No Report...</Text>
                                    </Flex>}
                            </Flex>
                        </Flex>
                    </> : <Flex
                        w={"100%"}
                        p={5}
                        bg={'red.100'}
                        borderLeftWidth={"5px"}
                        borderLeftColor={"red.500"}
                    >
                        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                            This Lingo link does not have any usage.
                        </Heading>
                    </Flex>}
                </>
            }
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onDrawerClose}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Share Transcript</DrawerHeader>

                    <DrawerBody>
                        <FormLabel mt="3" ml="2" required>
                            <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                To Email Address(es) (Maxium 5 Emails):
                            </Text>
                            <TagsInput
                                style={{ width: '100%' }}
                                maxTags={5}
                                value={toEmails}
                                onChange={tags => setToEmails(tags)}
                                inputProps={{ placeholder: 'Enter Email' }}
                            />
                            <Flex w={'100%'}>
                                <Text
                                    textAlign={"right"}
                                    fontWeight={"500"}
                                    fontSize={"smaller"}
                                    mt="2"
                                >
                                    Please click ENTER at the end of email to add.
                                </Text>
                                <Spacer />
                                <Text
                                    textAlign={"right"}
                                    fontWeight={"500"}
                                    fontSize={"smaller"}
                                    mt="2"
                                >
                                    {toEmails?.length}/5
                                </Text>
                            </Flex>
                            <Text mt={1}>
                                Note: An email will be sent to all above emails address with the call
                                Transcript
                            </Text>
                        </FormLabel>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={onDrawerClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={handleShareTranscriptApi} isDisabled={!toEmails.length} isLoading={btnLoading?.send_transcript}>Send</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LingoLinkHistory
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text, Center,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import Card from '../../components/card/Card';
import Footer from '../../komponents/uilayout/Footer';

function PrivacyPolicy() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  

    const [loading, isLoading] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Center>
                <Text fontWeight={'bold'} fontSize={'24px'}>
                    Lingolet Privacy Policy
                </Text>
            </Center>
            <Card w={'96%'} px={20} mt={10}>

                <Text>
                There are many different ways for you to use our service. For instance, you can log into the Lingolet website to purchase our products and use our products and services. When you share information with us (for example, by creating a Lingolet Account), we can improve our service to you by showing relevant product updates, help you to connect with people, or share with others quicker and easier. Therefore, we want to be clear about the way we use your information and how you can protect your privacy.
                </Text>


                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                Our Privacy Policy states:
                </Text>
                <ul>
                    <li>The information we collect</li>
                    <li>How we collect and use your information</li>
                    <li>The choices we offer, including how to access and update your information</li>
                    <li>Information security and sharing, the application, and changes to this Privacy Policy</li>
                </ul>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                The Information We Collect
                </Text>
    


                <Text>
                We collect two types of information: Personally Identifiable Information (PII) (PII is data that can be used to uniquely identify someone) and Non-Personally Identifiable Information (NPII) (data that is not connected to a person). If we combine PII with NPII during the combination period, such information will be deemed PII.
                </Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                How We Collect and Use Information
                </Text>
    
<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Collection and Use of Personal Information
                </Text>

<Text>
When you interact with Lingolet Inc. (hereinafter ”Lingolet” or “us”) and its affiliates, you may be required to provide your personal information to use its products and services. Such personal information may be gathered with other information to promote our products or services. The following are types of information that we may collect and examples of how we use such information.
</Text>
​

​

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
The Collection of Personal Information
                </Text>

<ul>
    <li>
    When you create a Lingolet account, download or update the Lingolet software, register in the Lingolet online store, or take part in surveys or other activities by Lingolet Inc., we’ll ask for your personal information, including but not limited to your name, telephone number, e-mail address, postal mail address, and similar.

    </li>
    <li>
    When you purchase Lingolet products or services, we’ll collect relevant personal information, including (but not limited to) delivery information, bank account, credit card information, billing address, credit check, other financial information, contact information, or communication records.

    </li>
    <li>
    When you use Lingolet products to share information with your family, friends, and co-workers to send messages or invite another person through Lingolet social media accounts, we will collect information for such people, such as name, mail address, telephone number, and so on.

    </li>
</ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
The Use of Personal Information
                </Text>

 <ul>
    <li>
    Lingolet will strictly comply with this Privacy Policy and its updates when using your PII. Your PII will be solely used for the purposes for which you consent. If there are additional purposes, we will ask for your consent before we use your information for those purposes.

    </li>
    <li>
    Personal Information will be used for Lingolet products and services, including delivery of your orders, performing our service to you, verify your identity, and ensure the safety and functionality of our products and services, such as prevention of fraud or any other kind of improper use.
        
    </li>
    <li>
    Personal Information will be used to develop our products and services, however, in most cases, we will use aggregated (non-personally identifiable) information and statistics for such purposes.

    </li>
    <li>
    Personal Information will be used for communication, for example, to notify you of updates and releases of Lingolet products and services.

    </li>
    <li>
    Personal Information will be used to improve personalized services. For example, we may personalize content and advertising or use such information for research purposes.
        
    </li>
    <li>
    If you participate in lottery drawings, competitions, or similar Lingolet activities, we may use your PII to manage such activities.
        
    </li>
    <li>
    Your mobile user identification information, mobile device unique identification, and location information may be used to activate your service and software licenses. We use such information to promote our products or analyze the efficiency of our service. But we won’t track your location.

    </li>
 </ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
The Collection and Use of Non-Personal Information
                </Text>

<Text>
We may collect and use non-personal information to improve our service and provide a better user experience. Here are examples where we may collect and use your non-personal information.
    
</Text>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
The Collection of Non-Personal Information
                </Text>

 
<ul>
    <li>
    When you create a Lingolet account, download Lingolet software and updates, register in the Lingolet Online Store, take part in online surveys, or engage in other Lingolet activities, we may collect information such as profession, language, postcode, area code, and the time zone.

    </li>
    <li>
    Information relevant to usage habits and problem discovery—If you participate in the Lingolet User Experience Improvement Plan, we may anonymously collect your data usage.

    </li>
    <li>
    Location Information—When you use location-based programs provided by Lingolet, we may collect your location information.

    </li>
    <li>
    Cookies and other technical information—Lingolet websites, online services, interactive applications, e-mail messages, and ads may use tracking technologies (such as cookies, pixels, website beacon, analytics tags, and remarketing tags) to collect and store non-personal information.

    </li>
    <li>
    Log Information—when you visit Lingolet server, we automatically store server log information. Such server logs may include IP address, browser type, browser language, referral URL, operating system, date/time stamp, and click-stream data.

    </li>
</ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
The Use of Non-Personal Information
                </Text>

<ul>
    <li>
    Non-Personal Information, such as profession, language, postcode, area code, and time zone where Lingolet products are used, will be helpful for us to understand our users’ behavior so we can improve our products, services, and advertising.

    </li>
    <li>
    We will analyze the data from our Lingolet “User Experience Improvement Plan” to continuously improve the experience and performance and to identify and fix problems and develop the functionality and design of our products and services.

    </li>
    <li>
    We will use data tracking information (such as cookies and pixels) to provide you with a better user experience and to promote our overall service. For example, by storing your language preference settings, we will show our service to you in your preferred language. We may send emails to you that include tracking so we can see if the email was opened or links were clicked. This lets us improve our emails to you.

    </li>
</ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
How Users Choose to Provide Information
                </Text>

<ul>
    <li>
    People have different privacy concerns, so we will clearly explain the ways by which we collect information so you can choose how to allow us to collect information.
    </li>
    <li>
    You can change your location settings in your device settings, for example, change or disable the location method or location server, or modify the accuracy of your location to change the location information which you provide to Lingolet.
    </li>
    <li>
    You can set your browser to block or accept cookies. You can delete cookies anytime. You can also set your browser to notify you if a site will set cookies on your device.
    </li>
</ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Accessing and Updating your Personal Information
                </Text>

<Text>
Go to account.lingolet.com to log in to your account. You can access and update your information settings. You can also delete part or all of your personal information or ask us to do it unless we must keep that information for legal purposes.
<br/>
Whenever you use our services, we give you access to your personal information. If that information is wrong, we give you ways to update or delete it (unless we must keep that information for legitimate business or legal purposes). When updating your personal information, we may ask you to verify your identity before we can act on your request.
</Text>

<Text>
Where we can provide information access and correction, we will do so for free, except where it would require a disproportionate effort. We aim to maintain our services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our services, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems.
<br/>
We may reject requests that are unreasonably repetitive, require disproportionate technical effort, risk the privacy of others, or would be extremely impractical.
</Text>


<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Sharing Information with a Third Party
                </Text>

<Text>
Except for explicitly noted limited sharing, we will strictly ensure the confidentiality of your personal information and we won’t share such information with a third party.
<br/>
By using our services, you authorize us to share your personal information with a third party in the following cases:

</Text>

<ul>
    <li>
    To meet legal investigations, lawsuits, law enforcement, or other mandatory requests, we will share your personal information with a third party.

    </li>
    <li>
    We may provide personal information to our subsidiaries, affiliates, or other trusted businesses or persons to process it for us in compliance with our Privacy Policy and appropriate confidentiality and security measures.

    </li>
    <li>
    If Lingolet is involved in a merger, acquisition, or asset sale, we will continue to ensure the confidentiality of any personal information and give affected users notice before personal information is transferred.

    </li>
    <li>
    For business purposes or to protect users, we may share non-personally information publicly and with our partners to show trends about the general use of our services.

    </li>
</ul>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Security of Your Information
                </Text>

<Text>
We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information. Therefore, we have taken the following measures:

</Text>
<Text>
Your information security is so important to us. Therefore, we will continuously protect your PII security and will take security measures such as encryption of storage and transmission, in order to protect your information from unauthorized access or unauthorized alteration, disclosure, or destruction.

</Text>
<Text>
We restrict access to personal information to Lingolet employees and agents on a need-to-know basis and they are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.

</Text>
<Text>
We use SSL to encrypt many of our services.

</Text>
<Text>
We review our information collection, storage, and processing practices, including physical security measures, to guard against unauthorized access to systems.

</Text>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Application of Our Privacy Policy
                </Text>

​<Text>
Lingolet products and services may include third-party products or services. However, our Privacy Policy does not apply to third-party products and services. When you use such products and services, they may collect your information. We advise you to read their privacy policy. Our Privacy Policy does not apply to information collected by other websites.

</Text>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Collection and Use of Children’s Personal Information
                </Text>

<Text>
Lingolet requires that children under thirteen years of age get prior approval from their legal guardians before using Lingolet products and services. Lingolet will not collect children’s Personal Information if we know the user is a child nor will we disclose such information to any third party. The Guardian shall have the right to reject the collection of his/her children’s PII or request the deletion of such PII. If we collect the PII of children under the age of 13, we will delete it as soon as we know.

</Text>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
Changes in Our Privacy Policy
                </Text>

<Text>
Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent unless we provide you with an email notice or website notice.
</Text>

<Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
If you have questions, contact us:
                </Text>
<Text>
Lingolet Inc. <br/>
Attn: Legal, Privacy Policy <br/>
4633 Old Ironsides Drive, Ste. 230 <br/>
Santa Clara, CA  95054 <br/>
Email info@lingolet.com <br/>

</Text>


                <Center mt={10}>
                Last Updated: <Text pl={1}><b>December 21th, 2023</b></Text>
                </Center>
            </Card>
            <Box w="100%">
                <Footer />
            </Box>
        </Flex>
    )
}

export default PrivacyPolicy;

import React, { useMemo, useState } from 'react';
import {
  Flex,
  Thead,
  Table,
  Tr,
  Tbody,
  Td,
  Th,
  useColorModeValue,
  Icon,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { TbCaretDown, TbCaretUp, TbFilter, TbFilterFilled } from 'react-icons/tb';
import { HiOutlineSearch } from "react-icons/hi";
import { useTextColor } from '../../../../theme/globalColorTheme';
import { useCustomColorModeValues } from '../../../../utils/ColorMode';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { PAGINATION_OPTIONS } from '../../../../components/pagination/constants';
import { IoSettingsOutline } from 'react-icons/io5';
import { useCallback } from 'react';
import { useEffect } from 'react';

function TableComponent({ data, columns, defaultSort = [] }) {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const [filters, setFilters] = useState({});
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [columnColors, setColumnColors] = useState({});
  const [searchValue, setSearchValue] = useState(null)
  const [filterData, setFilterData] = useState(data)

  const filteredData = useMemo(() => {
    return filterData.filter((row) => {
      return Object.keys(filters).every((colId) => {
        if (!filters[colId] || filters[colId].length === 0) return true;
        return filters[colId].includes(row[colId]);
      });
    });
  }, [filterData, filters]);

  const assignColors = (columnId) => {
    const uniqueValues = [...new Set(filterData?.map((row) => row[columnId] ?? 'Unknown'))];
    const colors = uniqueValues.reduce((acc, value, index) => {
      acc[value] = `hsl(${(index * 360) / uniqueValues.length}, 70%, 70%)`;
      return acc;
    }, {});
    setColumnColors((prev) => ({ ...prev, [columnId]: colors }));
  };


  const toggleColumnHighlight = (columnId) => {
    if (highlightedColumn === columnId) {
      setHighlightedColumn(null);
    } else {
      assignColors(columnId);
      setHighlightedColumn(columnId);
    }
  };

  const memoizedData = useMemo(() => filteredData, [filteredData]);
  const tableBg = useColorModeValue('white', 'gray.700');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    setPageSize,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: 5, sortBy: defaultSort },
    },
    useSortBy,
    usePagination
  );

  const { borderColor } = useCustomColorModeValues();

  const handleFilterChange = useCallback((colId, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (!newFilters[colId]) {
        newFilters[colId] = [];
      }
      if (newFilters[colId].includes(value)) {
        newFilters[colId] = newFilters[colId].filter((val) => val !== value);
      } else {
        newFilters[colId].push(value);
      }
      return newFilters;
    });
  }, []);

  function handleClearAllFilters(colId) {
    setFilters(filters => ({ ...filters, [colId]: [] }))
  }

  const isColumnFiltered = (colId, columnValues) => {
    const selectedValues = filters[colId] || [];
    return selectedValues.length !== 0 && selectedValues.length !== columnValues.length;
  };
  function handleFilterData(searchValue){
    const filteredData = data.filter((obj) =>
      Object.entries(obj).some(([key, value]) =>
        String(value).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilterData(filteredData)
  }
  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(searchValue){
        handleFilterData(searchValue)
      }
    },300)
    return ()=>{
      clearTimeout(timer)
    }
  }, [searchValue])

  return (
    <Flex overflow="hidden" w="full" direction="column" bg={tableBg} rounded="lg" gap={2}>
      <Flex p="3" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <Icon as={HiOutlineSearch} color="gray.400" />
            </InputLeftElement>
            <Input type='search' placeholder='Search..' borderRadius="lg" value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}/>
          </InputGroup>
        </Flex>


        <Flex alignItems="center">
          <Flex mr={3}>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} px={2} py={0} colorScheme='blue' h="30px" pl={3}>
                {pageSize}
              </MenuButton>
              <MenuList zIndex={1000}>
                {PAGINATION_OPTIONS.map(item => (<MenuItem value={String(item)} color={useTextColor} onClick={() => setPageSize(item)}>{item}</MenuItem>))}
              </MenuList>
            </Menu>
          </Flex>
          <Flex alignItems="center" gap="2">
            <Icon as={FaAngleLeft} cursor={canPreviousPage ? "pointer" : "not-allowed"} onClick={() => canPreviousPage && previousPage()} />
            {pageIndex + 1}
            <Icon as={FaAngleRight} cursor={canNextPage ? "pointer" : "not-allowed"} onClick={() => canNextPage && nextPage()} />
          </Flex>
          <Flex borderLeft="1px" alignItems="center" ml="3" pl="2">
            <Popover>
              <PopoverTrigger>
                <Button
                  size="xs"
                  variant="ghost"
                  p={0}
                  _hover={{ bg: 'transparent' }}
                  _focus={{ boxShadow: 'none' }}
                ><Icon as={IoSettingsOutline} boxSize={5} color={'gray.900'} /></Button>
              </PopoverTrigger>
              <PopoverContent overflow="hidden">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody height="200px" overflow="auto" className='custom-scrollbar'>
                  <Flex direction="column" gap="2">
                    {columns.map((column, idx) => (
                      column?.allowColorGrouping &&
                      <Checkbox
                        key={idx}
                        isChecked={highlightedColumn === column.accessor}
                        onChange={() => toggleColumnHighlight(column.accessor)}
                        mb={2}
                      >
                        {column.Header}

                      </Checkbox>
                    ))}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>

      </Flex>
      <Flex direction="column" overflow="auto" w="full" className="custom-scrollbar" maxH="full" minH="400px">
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" colorScheme="teal">
          <Thead position="sticky" top="0" bg={tableBg} zIndex={1}>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, idx) => {
                  const columnValues = [...new Set(filterData.map((row) => {
                    return row[column.id]
                  }))];
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      pe="10px"
                      key={idx}
                      borderColor={borderColor}
                    >
                      <Flex gap={2} alignItems="center" justifyContent="space-between">
                        <Box {...column.getSortByToggleProps()} cursor="pointer" h="full">{column.render('Header')}</Box>
                        <Flex alignItems="center">
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon as={TbCaretDown} boxSize={5} color="black" onClick={() => previousPage()} />
                              ) : (
                                <Icon as={TbCaretUp} boxSize={5} color="black" onClick={() => nextPage()} />
                              )
                            ) : (
                              ''
                            )}
                          </Box>
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                size="xs"
                                variant="ghost"
                                p={0}
                                _hover={{ bg: 'transparent' }}
                                _focus={{ boxShadow: 'none' }}
                              >
                                {
                                  isColumnFiltered(column.id, columnValues) ?
                                    <Icon
                                      as={TbFilterFilled}
                                      boxSize={4}
                                      color={'gray.400'}
                                    /> :
                                    <Icon
                                      as={TbFilter}
                                      boxSize={4}
                                      color={'gray.400'}
                                    />
                                }
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent overflow="hidden">
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <Box p="2">
                                <Button w="40%" onClick={() => handleClearAllFilters(column.id)}>Clear All</Button>
                              </Box>
                              <PopoverBody height="200px" overflow="auto" className='custom-scrollbar'>
                                <Flex direction="column" gap="2">
                                  {columnValues.every(value => value == null) ? (
                                    <Text>No filters available</Text>
                                  ) : (
                                    columnValues.map((value, valueIdx) => (
                                      value && <Checkbox
                                        key={valueIdx}
                                        isChecked={filters[column.id]?.includes(value)}
                                        onChange={() => handleFilterChange(column.id, value)}
                                        textTransform="none"
                                      >
                                        {value}
                                      </Checkbox>
                                    ))
                                  )}
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index} h="auto">
                  {row.cells.map((cell, idx) => {
                    const bgColor =
                      highlightedColumn === cell.column.id
                        ? columnColors[cell.column.id]?.[cell.value] || 'transparent'
                        : 'transparent';

                    return (<Td
                      key={idx}
                      {...cell.getCellProps()}
                      color={useTextColor}
                      fontSize="md"
                      fontWeight="500"
                    >
                      <Flex justifyItems="flex-start">
                        <Box borderRadius="full" bg={bgColor} textAlign="center" p="1.5" w={bgColor !== "transparent" ? "full" : 'auto'}>
                          {cell?.value ? cell.render('Cell') : "..."}
                        </Box>
                      </Flex>
                    </Td>)
                  }
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
}

export default TableComponent;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Flex, Text, Button, Box, Spinner, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, } from "@chakra-ui/react";
import { useNoResultFoundText, useYellowButton } from "../../../../theme/globalColorTheme";
import { fetchTranscript, fetchTranscriptData, lingoEmailTranscript } from "../../../../services/businessServices";

function LinkTranscripts() {
	const { state } = useLocation();
	const linkDetails = state?.linkDetails[0];
	const linkId = linkDetails?.uuid;
	const accountId = state?.accountId;
	const locationId = state?.locationId;
	const yellowBgButton = useYellowButton();
	const [invalidEmail, setInvalidEmail] = useState([]);
	const [emailLoader, setEmailLoader] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [resultLoader, setResultLoader] = useState(false);
	const [transcriptData, setTranscriptData] = useState([]);
	const [skillsKeyword, setSkillsKeyword] = useState([]);
	const [resultTransctipt, setResultTranscript] = useState([]);
	const [transcriptLoader, setTranscriptLoader] = useState(false);
	const [selectedTranscript, setSelectedTranscript] = useState(null);

	const notFoundText = useNoResultFoundText()

	// Todo:Api need's to be changed
	useEffect(() => {
		collectTranscription()
	}, [])

	const collectTranscription = () => {
		setTranscriptLoader(true);
		let obj = {
			"linkUuid": linkId,
			"link_account_uuid": accountId ? accountId : '',
			"link_location_uuid": locationId ? locationId : '',
		}
		fetchTranscript(obj).then((res) => {
			setTranscriptLoader(false);
			if (res?.result) {
				if (res?.data?.length) {
					setTranscriptData(res?.data)
				}
			}
			else {
				setTranscriptData([])
			}
		}).catch((err) => {
			setTranscriptLoader(false);
		})
	}

	const getTransctiptData = (transcriptId) => {
		setResultLoader(true)
		let obj = {
			"linkUuid": linkId,
			"link_account_uuid": accountId,
			"link_location_uuid": locationId,
			"transcript_uuid": transcriptId
		}
		fetchTranscriptData(obj).then((res) => {
			setResultLoader(false)
			setResultTranscript(res?.data[0]?.transcript_text)
		}).catch((err) => {
			setResultLoader(false)
		})
	}

	const renderTranscriptList = () => {
		if (transcriptData?.length) {
			return transcriptData.map((data, index) => (
				<Box mb="4" key={index}>
					<Flex key={index} p="2" borderRadius={'5px'} borderWidth={'1px'} alignItems={'center'} justifyContent={'space-between'}>
						<Flex>
							<Text>
								{data?.datetime_created}
							</Text>
							<Text ml="4" fontWeight={'bold'}>
								{data?.transcript_languages}
							</Text>
						</Flex>
						<Button size="sm" bg={yellowBgButton} borderRadius={'5px'} onClick={() => {
							getTransctiptData(data?.transcript)
							setSelectedTranscript(index)
						}} isDisabled={resultLoader ? true : false}> Get Transcript</Button>
					</Flex>
					{resultTransctipt?.length && selectedTranscript == index ? (
						renderTranscriptData()
					) : null}
				</Box>
			));
		} else {
			return <Text w={'100%'} p={2} bg={'yellow.100'} color={notFoundText} fontSize={"16px"} >There is no transcripts data for select LingoLink!!</Text>;
		}
	};

	const renderTranscriptData = () => {
		return (
			<>
				{resultLoader ? (
					<Flex alignItems="center" justifyContent="center" h="20vh">
						<Spinner size="lg" />
					</Flex>
				) : (
					<Box mb="4" p="2" borderRadius={'5px'} borderWidth={'1px'} borderTop={'0px'}>
						<Flex justifyContent={'space-between'}>
							<Text>{renderTranscriptJson()}</Text>
							{resultTransctipt?.length ? (
								<Button size="sm" colorScheme="yellow" borderRadius={'5px'} onClick={() => { onOpen() }}>Email</Button>
							) : null}
						</Flex>
					</Box>)}
			</>
		)
	}

	const renderTranscriptJson = () => {
		if (JSON.parse(resultTransctipt)) {
			const transcriptJson = JSON.parse(resultTransctipt)
			return transcriptJson?.map((transcript, index) => {
				return (
					<Flex key={index} alignItems={'center'}>
						<Icon as={AiFillCaretRight} />
						<Text>{transcript?.language} :</Text>
						<Text ml="2">{transcript?.text}</Text>
					</Flex>
				)
			})

		} else {
			<Text>No Data Available|</Text>
		}
	}

	const onTagChange = tags => {
		setSkillsKeyword(tags);
	};

	const validateEmails = emails => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emails.filter(email => !emailRegex.test(email));
	};

	useEffect(() => {
		setInvalidEmail(validateEmails(skillsKeyword));
	}, [skillsKeyword]);

	// Todo:Send Transcript Data By Mail
	const mailTranscriptData = () => {
		const transcriptJson = JSON.parse(resultTransctipt);
		const transctiptArr = []
		transcriptJson?.map((data) => {
			let obj = {
				language: data?.language,
				text: data?.text
			}
			transctiptArr.push(obj)
		})
		setEmailLoader(true);
		const letObj = {
			linkKey: linkDetails?.key,
			languages: `${transcriptData[0]?.transcript_languages}`,
			transcript_text: JSON.stringify(transctiptArr),
			emails: skillsKeyword.join(", "),
		};
		// Api need to be added
	}

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			{transcriptLoader ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="lg" />
				</Flex>
			) : renderTranscriptList()
			}
			{/* Popup Modal */}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Send Email</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Please use enter to multiple emails in the textbox.</Text>
						<TagsInput value={skillsKeyword} onChange={onTagChange} inputProps={{ placeholder: "Enter Email" }} />
						<Flex flexWrap="wrap" alignItems={"center"} mt="2">
							{invalidEmail.map((email, index) => (
								<Text p="1" mr="1" bg="red.100" color="#000" size="sm" key={index}>
									{email}
								</Text>
							))}
							{invalidEmail?.length ? <Text>Not Valid Email </Text> : null}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="green"
							mr={3}
							onClick={() => {
								mailTranscriptData();
							}}
							isLoading={emailLoader ? true : false}
							isDisabled={invalidEmail?.length > 0 || !skillsKeyword?.length ? true : false}
						>
							Send Mail
						</Button>
						<Button colorScheme="blue" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}

export default LinkTranscripts;
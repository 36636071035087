/**
 * Converts seconds to a formatted string in the format "HH:MM:SS"
 * @param {number} seconds - The number of seconds to convert
 * @returns {string} The formatted time string
 */
export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}
  
  /**
   * Calculates the number of 15-second increments for a given number of seconds
   * @param {number} seconds - The number of seconds to calculate
   * @returns {number} The number of 15-second increments
   */
export function calculateBillingIncrements(seconds) {
    const incrementSeconds = 15;
    const incrementCount = Math.ceil(seconds / incrementSeconds);
    return incrementCount;
}


/**
 * Formats a number of seconds into a string in the format "HH:MM:SS".
 * 
 * @param {number} totalSeconds - The number of seconds to format.
 * @returns {string} The formatted time string.
 */
export function formatSeconds(totalSeconds) {
    // Calculate hours, minutes, and seconds from the total seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
  
    // Pad the values with leading zeros if necessary
    const hoursStr = String(hours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(seconds).padStart(2, '0');
  
    // Return the formatted time string
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}
import Information from "./Information";
import React, { useEffect, useState } from "react";
import Card from "../../../../components/card/Card.js";
import { getEmailId } from "../../../../utils/localStorageIndex";
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { fetchPersonalInfo } from "../../../../services/sharedServices";

export default function GeneralInformation(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [updateInfoObj, setUpdateInfoObj] = useState({});

  // Todo:Fetch User Details
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    let objData = {
      email: getEmailId(),
    };
    fetchPersonalInfo(objData)
      .then((res) => {
        if (res.result) {
          setUpdateInfoObj(res.data[0]);
        }
      })
      .catch((err) => {});
  };

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Social Information
      </Text>
      <SimpleGrid columns="1" gap="20px">
        <Information
          title="Twitter"
          value={
            updateInfoObj?.social_twitter
              ? updateInfoObj?.social_twitter
              : "Twitter ....."
          }
        />
        <Information
          title="LinkedIn"
          value={
            updateInfoObj?.social_linkedin
              ? updateInfoObj?.social_linkedin
              : "LinkedIn ....."
          }
        />
        <Information
          title="GitHub"
          value={
            updateInfoObj?.social_github
              ? updateInfoObj?.social_github
              : "GitHub ....."
          }
        />
      </SimpleGrid>
    </Card>
  );
}

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { subLingoCardUsage } from '../../../services/accountServices';
import { Box, Button, Card, Flex, Icon, Spacer, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import { AiOutlineStop } from 'react-icons/ai';
import moment from "moment";
import { transcriptionLanguages } from '../../../utils/TranscriptionLanguage';


const CardUsage = (props) => {
    const { state } = useLocation();
    const toast = useToast()
    const [data, setData] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [, setShowTooltip] = useState(false);


    useEffect(()=>{
      setLoading(true)
      subLingoCardUsage(state).then(resp=>{
        if(resp?.result){
          toast(toastFunctionToaster(resp?.message, "success"))
          setData(resp?.data[0])
        }else{
          toast(toastFunctionToaster(resp?.message, "error"))
          setData(null)
        }
      }).catch(err=>{
        toast(toastFunctionToaster("Failed to get card usages", "error"))
        setData(null)
      })
      setLoading(false)
    }, [])

    const renderLanguageListDetails = langs => {
      let langData = langs.split(",");
      const filteredData = transcriptionLanguages.filter(item =>
        langData.includes(item.code)
      );
      if (filteredData.length > 0) {
        return filteredData?.map((item, index) => {
          return (
            <Text
              m={1}
              p={1}
              fontSize={"xs"}
              bg={"blue.100"}
              rounded={"md"}
            >
              {item?.name}
            </Text>
          );
        });
      }
    };

    const CardData = ({data}) => {
      return(
					<Card
						h="calc(100vh - 180px)"
						key={data?.ingo_card_uuid}
						p={1}
						w={"100%"}
						rounded={"md"}
						borderWidth={"0.05px"}
						borderColor={data?.card_active ? "green.200" : "red.200"}>
						<Flex w={"100%"} direction={"column"}>
								<Flex w={'100%'} align={'center'} p={2}>
									<Flex minW={"150px"} maxW={"150px"}>
										<Text rounded={"md"} bg={data?.card_active ? "green.200" : "red.200"} p={2}>
											{data?.card_dead ? (
												<Icon as={AiOutlineStop} color={"red.600"} ml={1} mr={1} mt={1} />
											) : null}
											{data?.lingo_card_title}
										</Text>
									</Flex>
									
								</Flex>
								<Flex w={"100%"} mt={2} p={2} direction={"column"} rounded={'md'} 
									border={'1px'} borderColor={'gray.200'} >
									<Flex w={"100%"}>
										<Text>
											Start Time (UTC):{" "}
											<Text as="spam" fontWeight={"600"}>
												{moment(data?.card_start_time).format("MMM DD,YYYY,HH:mm a")}
											</Text>
										</Text>
										<Text ml={2}>
											End Time (UTC): {moment(data?.card_end_time).format("MMM DD,YYYY,HH:mm a")}
										</Text>
									</Flex>
									<Flex w={"100%"}>
										<Text>
											Call Time: <b>{data?.card_minutes} Minutes</b>
										</Text>
									</Flex>
									<Flex w={"100%"}  align={'center'}>
										<Text>
											Source Language:{" "}
										</Text>
										{renderLanguageListDetails(data?.card_source_langs)}
									</Flex>
									<Flex w={"100%"} align={'center'}>
										<Text>
											Target Language:{" "}
										</Text>
										{renderLanguageListDetails(data?.card_target_langs)}
									</Flex>
									<Flex w={"100%"} align={'center'}>
										<Text>
											Voice calls: <b>{data?.card_voice_calls}</b>
										</Text>
									</Flex>
									<Flex w={"100%"} align={'center'}>
										<Text>
											Video calls: <b>{data?.card_video_calls}</b>
										</Text>
									</Flex>
									<Flex w={"100%"} align={'center'}>
										<Text>
											Active Days: <b>{data?.active_days}</b>
										</Text>
									</Flex>
								</Flex>
						</Flex>
					</Card>
      )
    }


  return (
    <Box direction="column" pt={{ sm: "125px", lg: "80px" }} minH="30vh">
      <Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
				<Text fontWeight={"500"} fontSize={"18px"}>
					LingoCard Details
				</Text>
				<Spacer />
				<Flex w={'200px'}>
					<Button ml={1} bg={'blue.100'} size="sm" 
						onClick={() => {
							navigate("/subscriber/lingocardlisting", {
							});
						}}>
						Back to Card Listing
					</Button>
				</Flex>
			</Flex>
      
      {loading ? <Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" />
				</Flex> : (data !== null ?
          <Flex w={"100%"}>
					<Flex w={'100%'} direction={"column"}>
						<CardData data={data} />
					</Flex>
          </Flex> : <>no data</>)}
      
    </Box>
  )
}

export default CardUsage
import React, { useEffect, useState } from "react";
import { useNavigate, } from "react-router-dom";
import {
    Box,
    Icon,
    Button,
    Link,
    Tooltip,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
    useDisclosure,
    Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import Card from "../../../components/card/Card";
import { toastFunctionToaster } from "../../../utils/toastFunction";

import {
    AiOutlineCopy,
    AiOutlineLink,
} from "react-icons/ai";

import { assignLinkToDevice, assignNotesToDevice, getAllLinks } from "../../../services/businessServices";
import {
    useGreenBg, useLinkColor, useTextColor,
    usePillButtonTextColorHover, usePillButtonHighLight,
    usePillButtonTextColorSelected, usePillButtonBg, usePillButtonBgHover
} from "../../../theme/globalColorTheme";
import QRCode from "react-qr-code";
import { DEVICE_DATA } from "./constants";

const DeviceData = ({ handleAction, devicesList }) => {
    const toast = useToast();
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [dynamicLinks, setDynamicLinks] = useState([]);
    const [loading, isLoading] = useState(false);
    const [pageId, setPageId] = useState(1);
    const [btnLoading, setBtnLoading] = useState({
        linkLoading: false,
        addUpdateNote:false
    });
    const [modalQrCode, setModalQrCode] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [drawerType, setDrawerType] = useState(null)
    const [note, setNote] = useState(null)
    const pillButtonBgSelected = usePillButtonTextColorSelected()
    const pillButtonBg = usePillButtonBg()
    const pillButtonBgHover = usePillButtonBgHover()
    const pillButtonTextColorHover = usePillButtonTextColorHover()
    const pillButtonText = useTextColor()
    const pillButtonBgHighlight = usePillButtonHighLight()

    const [isLoadMoreDisabled, setLoadMoreDisabled] = useState(false);
    const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();
    const qrRef = React.useRef();
    const port_value = window.location.port ? ":" + window.location.port : "";
    const currentHost = window.location.hostname.includes("localhost")
        ? "https://lingolet.ai/"
        : window.location.protocol + "//" + window.location.hostname + port_value + "/";
    function handleAssignLink(item) {
        setSelectedAssignment(item);
        setDrawerType(DEVICE_DATA.DRAWER_TYPE.ASSING_LINK)
        onOpen();
    }

    function handleNoteChange(e){
        setNote(e?.target?.value)
    }

    function handleAddNote(item) {
        if(item?.account_admin_notes){
            setNote(item?.account_admin_notes)
        }
        setSelectedAssignment(item);
        setDrawerType(DEVICE_DATA.DRAWER_TYPE.ADD_UPDATE_NOTE)
        onOpen();
    }

    function handleCloseDrawer() {
        setSelectedAssignment(null);
        setSelectedLink(null);
        setNote(null)
        setDrawerType(null)
        onClose();
    }

    useEffect(() => {
        getAllDynamicLinks();
    }, [pageId]);

    const getAllDynamicLinks = () => {
        setDynamicLinks([]);
        setBtnLoading(state => ({ ...state, linkLoading: true }));
        let paramObj = {
            filter_key: "string",
            filter_value: "active",
            records_per_page: 10,
            page_id: pageId,
        };
        getAllLinks(paramObj)
            .then(res => {
                if (res?.data[0]?.records.length) {
                    setDynamicLinks(prevLinks =>
                        prevLinks?.length ? [...prevLinks, ...res?.data[0]?.records] : res?.data[0]?.records
                    );
                } else {
                    setLoadMoreDisabled(true);
                }
            })
            .catch(err => { })
            .finally(() => {
                setBtnLoading(state => ({ ...state, linkLoading: false }));
            });
    };

    function handleAddUpdateNote(){
        setBtnLoading(state=>({...state, addUpdateNote: true}))
        const paramObj = {
            device_uuid: selectedAssignment?.deviceObj?.uuid,
            assignment_uuid: selectedAssignment?.uuid,
            note:note
        }
        assignNotesToDevice(paramObj).then(resp => {
            toast(toastFunctionToaster("Note added successfully.", "success"));
            handleCloseDrawer();
            handleAction();
        })
        .catch(err => {
            toast(toastFunctionToaster("Failed to add note.", "error"));

        }).finally(()=>{
            setBtnLoading(state=>({...state, addUpdateNote: false}))    
        })
    }

    function assignLingoLink() {
        const paramObj = {
            device_uuid: selectedAssignment?.deviceObj?.uuid,
            assignment_uuid: selectedAssignment?.uuid,
            lingolink_uuid: selectedLink,
        };
        assignLinkToDevice(paramObj)
            .then(resp => {
                toast(toastFunctionToaster("Selected LingoLink is assigned successfully to selected device.", "success"));
                handleCloseDrawer();
                handleAction();
            })
            .catch(err => {
                toast(toastFunctionToaster("Failed to assign the selected LingoLink to selected device.", "error"));
            });
    }


    function handleLoadMoreLinks() {
        setPageId(linksPageId => linksPageId + 1);
    }

    if (!devicesList || !devicesList?.length) return;


    return (
        <>
            {devicesList?.map((item, index) => (
                <Card w={'100%'} rounded={'lg'} p={0} mb={2} boxShadow="lg" pt={1} pb={1}>
                    <Flex
                        key={index}
                        w={"100%"}
                        mt={1}
                        direction="column"
                        gap={2}>
                        <Flex>
                            <Flex w={'100%'} direction="column">
                                <Flex w={'100%'}>
                                    <Box minW={"170px"} fontSize={"sm"} ml={1} pl={2}>
                                        <Text
                                            rounded={"md"}
                                            bg={item?.deviceObj?.assigned ? "green.200" : null}
                                            pl={2}
                                            color="black">
                                            {moment(item?.assigned_date).format("MMM DD,YYYY,HH:mm a")}
                                        </Text>
                                    </Box>
                                    <Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
                                        {item?.deviceObj?.type} ({item?.deviceObj?.class})
                                    </Box>
                                    <Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
                                        <Text fontSize={"sm"}>{item?.deviceObj?.serial}</Text>
                                    </Box>
                                    <Box minW={"150px"} fontSize={"sm"} ml={1} pl={2}>
                                        <Text fontSize={"sm"}>{item?.deviceObj?.mac}</Text>
                                    </Box>
                                    <Box minW={"60px"} fontSize={"xs"} ml={1} pl={2} color="black" alignItems={"center"}>
                                        {item?.is_locked ? (
                                            <Text p={1} bg={"green.100"} rounded={"md"}>
                                                Yes
                                            </Text>
                                        ) : (
                                            <Text p={1} bg={"red.100"} rounded={"md"}>
                                                No
                                            </Text>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex w={'100%'} px={2} align={'center'} direction={'column'}>
                                    <Flex w={'100%'} align={'center'} >
                                        <Text fontSize={"xs"} mr={2}>Assigned to: </Text>
                                        {item?.account_admin_email ? <Text fontSize={"sm"} color={'blue.400'}>{item?.account_admin_email}</Text> : <Text fontSize={"sm"}  >None</Text>}
                                    </Flex>
                                    <Flex w={'100%'} align={'center'} >
                                        <Text fontSize={"xs"} mr={2}>Notes: </Text>
                                        {item?.account_admin_notes ? <Text fontSize={"sm"} color={'blue.400'}>{item?.account_admin_notes}</Text> : <Text fontSize={"sm"}  >No Notes</Text>}
                                        {item?.account_admin_notes
                                            ? <Button size={'xs'}
                                                _hover={{ cursor: "pointer", borderColor: "blue.600", borderWidth: "1px" }}
                                                p={1}
                                                borderColor={"gray.400"}
                                                borderWidth={"1px"}
                                                rounded={"md"}
                                                onClick={()=>handleAddNote(item)}
                                                ml={1}>Update Notes</Button>
                                            : <Button size={'xs'}
                                                _hover={{ cursor: "pointer", borderColor: "blue.600", borderWidth: "1px" }}
                                                p={1}
                                                borderColor={"gray.400"}
                                                borderWidth={"1px"}
                                                rounded={"md"}
                                                onClick={()=>handleAddNote(item)}
                                                ml={1}>Add Notes</Button>}
                                    </Flex>
                                </Flex>
                            </Flex>
                            {/* <Box w={"150px"} fontSize="smaller" ml={1} pl={2}>
                                <Tooltip label={`${item?.account_admin_email}`}>
                                    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                        {item?.account_admin_email}
                                    </Text>
                                </Tooltip>
                            </Box> */}

                            {/* Avkash - blocked this section */}
                            {/* {item?.is_assigned_to_user == false
                            ?<Box w={"150px"} fontSize="smaller" ml={1} pl={2}>
                                <Button size={"xs"} onClick={() => handleAssignLink(item)}>
                                        Assign a User
                                </Button>
                            </Box>
                            :<Box w={"150px"} fontSize="smaller" ml={1} pl={2}>
                                <Text>Assigned User Info</Text>
                            </Box>} */}

                            {/* <Box minW={'155px'} fontSize={'sm'} ml={1} pl={2} color="black">
                            {item?.is_activated 
                                ? <Flex w={'100%'} p={1} direction={'column'} bg={'green.100'} rounded={'md'}>
                                <Text>Yes</Text>
                                <Text fontSize={'xs'}>On ({moment(new Date()).format("MMM DD,YYYY,HH:mm a")})</Text>
                                </Flex>
                                : <Text p={1} bg={'red.100'} rounded={'md'}>No</Text>}
                            </Box> */}
                            {/* <Box minW={'100px'} fontSize={'sm'} ml={1} pl={2}>
                            {item?.activation_code}
                            </Box> */}
                            {item?.lingolink
                                ? <Box w={"100%"} fontSize="smaller" ml={1} pl={2}>
                                    {item?.lingolink && (
                                        <Flex w={'100%'} p={2}>
                                            <Box
                                                w={'80px'}
                                                onClick={() => {
                                                    setModalQrCode(item?.lingolink?.fullurl);
                                                    onOpenQr();
                                                }}
                                                _hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
                                                p={1}
                                                borderColor={"gray.400"}
                                                borderWidth={"1px"}
                                                rounded={"md"}>
                                                <QRCode
                                                    size={60}
                                                    style={{ height: "auto", maxWidth: "60", width: "60" }}
                                                    value={item?.lingolink?.fullurl}
                                                    viewBox={`0 0 90 90`}
                                                />
                                            </Box>
                                            <Box w={'100%'}>
                                                <Text fontSize={'md'} ml={1} color={useLinkColor}>{item?.lingolink?.title}</Text>
                                                <Text ml={1} color={useLinkColor}>{currentHost + item?.lingolink?.fullurl}</Text>
                                                <Flex w={'100%'}>
                                                    <Button
                                                        _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                                                        color={pillButtonText}
                                                        bg={pillButtonBg}
                                                        rounded={'lg'} mr={1}
                                                        ml={1} mt={1} size={"sm"} onClick={() => handleAssignLink(item)}>
                                                        Change Assigned LingoLink
                                                    </Button>
                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Open Url in new Tab">
                                                        <Link
                                                            p={1}
                                                            w={"35px"}
                                                            rounded={"md"}
                                                            mr={2}
                                                            align={"center"}
                                                            href={currentHost + item?.lingolink?.fullurl}
                                                            target="_blank"
                                                            _hover={{
                                                                bg: pillButtonBgHighlight,
                                                                cursor: "pointer",
                                                                color: "black"
                                                            }}
                                                            color={pillButtonText}
                                                            bg={pillButtonBg}>
                                                            <Icon
                                                                ml={2}
                                                                mt={1}
                                                                h={5}
                                                                _hover={{ cursor: "pointer" }}
                                                                w={5}
                                                                as={AiOutlineLink}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Copy Url">
                                                        <Box
                                                            mt={1}
                                                            w={"35px"}
                                                            ml={1}
                                                            pr={2}
                                                            rounded={"md"}
                                                            mr={2}
                                                            _hover={{
                                                                bg: pillButtonBgHighlight,
                                                                cursor: "pointer",
                                                                color: 'black'
                                                            }}
                                                            color={pillButtonText}
                                                            bg={pillButtonBg}>
                                                            <Icon
                                                                onClick={() =>
                                                                    navigator.clipboard.writeText(currentHost + item?.lingolink?.fullurl)
                                                                }
                                                                ml={2}
                                                                mt={1}
                                                                h={5}
                                                                _hover={{ cursor: "pointer" }}
                                                                w={5}
                                                                as={AiOutlineCopy}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    )}
                                </Box>
                                : <Box w={"100%"} fontSize="smaller" ml={1} pl={2}>
                                    <Button
                                        _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                                        color={pillButtonText}
                                        bg={pillButtonBg}
                                        rounded={'lg'} size={"sm"} onClick={() => handleAssignLink(item)}>
                                        Assign LingoLink
                                    </Button>
                                </Box>}
                            <Box W={'50px'} align={'center'} mr={1}>
                                <Button
                                    _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                                    color={pillButtonText}
                                    bg={pillButtonBg}
                                    onClick={() => {
                                        navigate('/account/assigned_device/logs', {
                                            state: {
                                                assignUuid: item?.uuid,
                                                deviceUuid: item?.deviceObj?.uuid,
                                                deviceSerial: item?.deviceObj?.serial,
                                                deviceMac: item?.deviceObj?.mac,
                                                lingoLink: currentHost + item?.lingolink?.fullurl
                                            },
                                        });
                                    }}
                                    rounded={'lg'} size={'xs'}>Logs</Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Card>
            ))}
            <Drawer isOpen={isOpen} placement="right" onClose={handleCloseDrawer} size="md">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    {drawerType === DEVICE_DATA.DRAWER_TYPE.ASSING_LINK && <>
                        <DrawerHeader>Assign LingoLink To Device</DrawerHeader>
                        <DrawerBody>
                            <Text fontSize="md" mb={2}>
                                Select LingoLink
                            </Text>
                            <Card p={2} h="90%" rounded={"md"} overflow="hidden">
                                <Flex h="full" className="custom-scrollbar" overflowY="auto" direction="column" gap={2}>
                                    {dynamicLinks.map(link => (
                                        <Flex
                                            cursor="pointer"
                                            direction="column"
                                            _hover={{ bg: "blue.100", color: "black" }}
                                            borderWidth="1px"
                                            borderColor="#d7d7d7"
                                            borderRadius="5px"
                                            bg={selectedLink === link?.uuid ? useGreenBg : "inherit"}
                                            p={2}
                                            rounded="md"
                                            color={useTextColor}
                                            border="1px"
                                            onClick={() => setSelectedLink(link?.uuid)}>
                                            <Text fontWeight="bold">{link?.title}</Text>
                                            <Text fontSize="smaller">{link?.url}</Text>
                                            {link?.tags
                                                ? <Flex w={'100%'}>
                                                    <Text fontSize="smaller" mr={1}>{'Tags: '}</Text>
                                                    <Text fontSize="smaller">{link?.tags}</Text>
                                                </Flex>
                                                : <Text fontSize="smaller">No Tags</Text>
                                            }

                                        </Flex>
                                    ))}
                                    {/* {dynamicLinks && dynamicLinks?.length ? (
									<Button
										colorScheme="green"
										p={3}
										rounded="md"
										isLoading={btnLoading.linkLoading}
										onClick={handleLoadMoreLinks}
										isDisabled={isLoadMoreDisabled}>
										Load more
									</Button>
								) : null} */}
                                </Flex>
                            </Card>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={handleCloseDrawer}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={assignLingoLink}>
                                Assign LingoLink
                            </Button>
                        </DrawerFooter>
                    </>}

                    {drawerType === DEVICE_DATA.DRAWER_TYPE.ADD_UPDATE_NOTE && <>
                        <DrawerHeader>{selectedAssignment?.account_admin_notes ? 'Update Note' : 'Add Note'}</DrawerHeader>

                        <DrawerBody>
                            <Text  mb="2" fontWeight={"bold"} fontSize={"sm"}>Note:</Text>
                            <Textarea
                                value={note}
                                onChange={handleNoteChange}
                                placeholder='Enter note...'
                                size='sm'
                                maxLength={200}
                            />
                            <Text
									textAlign={"right"}
									fontWeight={"500"}
									fontSize={"sm"}
									mt="2"
								>
									{note?.length || 0}/100
								</Text>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button variant='outline' mr={3} onClick={handleCloseDrawer}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={handleAddUpdateNote} isDisabled={!note?.length} isLoading={btnLoading?.addUpdateNote}>{selectedAssignment?.account_admin_notes ? 'Update Note' : 'Add Note'}</Button>
                        </DrawerFooter>
                    </>}

                </DrawerContent>
            </Drawer>

            <Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>QR Code for the LingoLink</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {modalQrCode != null ? (
                            <Flex w={"100%"} direction={"column"}>
                                <QRCode
                                    title={currentHost + modalQrCode}
                                    size={60}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={currentHost + modalQrCode}
                                    viewBox={`0 0 90 90`}
                                />
                                <Text p={1} bg={"blue.100"}>
                                    {currentHost + modalQrCode}
                                </Text>
                            </Flex>
                        ) : (
                            <Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            size={"sm"}
                            colorScheme="blue"
                            onClick={() => {
                                setModalQrCode(null);
                                onCloseQr();
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeviceData;

import React, { useState } from "react";

// Chakra Imports
import { Box, Flex, useColorMode } from "@chakra-ui/react";

// Component Import
import BusinessList from "./BusinessList";
import Card from "../../../components/card/Card";
import BusinessUserList from "./BusinessUserList";
import ModuleBasedList from "./ModuleBasedList";

function BusinessListing() {
  const { colorMode } = useColorMode();
  const [businessId, setBusinessId] = useState("");
  const [userId, setUserId] = useState("");

  // Todo:Pass Business Id To Business User Component
  const handleBusinessId = (data) => {
    setBusinessId(data?.business_uuid);
  };

  // Todo:Pass Business Details To Module List Component
  const handleBusinessUserDetails = (data) => {
    setUserId(data?.user_uuid);
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px" p={1}>
        <Flex justifyContent={"space-between"}>
          <Box
            w="33%"
            boxShadow={"md"}
            borderWidth={"1px"}
            borderStyle="dotted"
            borderRadius={"10px"}
            h="calc(100vh - 200px)"
            borderBottomColor={"gray.400"}
            bg={colorMode === "dark" ? "#111c44" : null}
          >
            <BusinessList returnAssociateDetails={handleBusinessId} />
          </Box>
          <Box
            w="33%"
            boxShadow={"md"}
            borderWidth={"1px"}
            borderStyle="dotted"
            borderRadius={"10px"}
            h="calc(100vh - 200px)"
            borderBottomColor={"gray.400"}
            bg={colorMode === "dark" ? "#111c44" : null}
          >
            <BusinessUserList
              businessId={businessId}
              returnBusinessUserDetails={handleBusinessUserDetails}
            />
          </Box>
          <Box
            w="33%"
            boxShadow={"md"}
            borderWidth={"1px"}
            borderStyle="dotted"
            borderRadius={"10px"}
            h="calc(100vh - 200px)"
            borderBottomColor={"gray.400"}
            bg={colorMode === "dark" ? "#111c44" : null}
          >
            <ModuleBasedList userId={userId} />
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}

export default BusinessListing;

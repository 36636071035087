// Chakra React Imports
import React, { useState, useEffect } from "react";
import { AiOutlineBulb, AiOutlineCloudUpload, AiFillBuild } from "react-icons/ai";
import {
	Box,
	Icon,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Flex,
} from "@chakra-ui/react";

// Component Imports
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";
import BusinessSettings from "./BusinessSettings";
import CustomBusinessSubdomain from "./CustomBusinessSubdomain";
import BusinessUpload from "./BusinessUpload";

function BusinessInfo() {
	const [activeTab, setActiveTab] = useState(0);

	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} mx={3}>
				<Tabs
					w="100%"
					borderBottom={"0px"}
					mx="auto"
					onChange={handleTabChange}
					index={activeTab}>
					<TabList border="0px" w="100%" h="5%">
						<Flex justifyContent={"space-between"} w="100%">
							<Flex>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2">
									<Icon as={AiOutlineBulb} mr={1} />
									Bussiness Update
								</Tab>

								<>
									<Tab
										_selected={{
											color: defaultThemeColor,
											fontWeight: 600,
											borderBottomColor:
												defaultThemeColor,
										}}
										fontSize="16px"
										px="6"
										py="2">
										<Icon
											as={AiOutlineCloudUpload}
											mr={1}
										/>
										Upload Business or Company Logo
									</Tab>
									<Tab
										_selected={{
											color: defaultThemeColor,
											fontWeight: 600,
											borderBottomColor:
												defaultThemeColor,
										}}
										fontSize="16px"
										px="6"
										py="2">
										<Icon as={AiFillBuild} mr={1} />
										Custom Subdomain
									</Tab>
								</>
							</Flex>
						</Flex>
					</TabList>
					<TabPanels mt="1%" h="94%">
						<TabPanel p="0" h="100%">
							<BusinessSettings />
						</TabPanel>
						<TabPanel p="0">
							<BusinessUpload />
						</TabPanel>
						<TabPanel p="0" h="100%">
							<CustomBusinessSubdomain />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default BusinessInfo;

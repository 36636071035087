export const serviceAPIs = (urlVar = null) => {
	return {
        add_profile:"/v1/api/ailab/add_profile",
        add_company:"/v1/api/ailab/add_company",
        update_company:"/v1/api/ailab/update_company",
        add_application:"/v1/api/ailab/add_application",
        update_application:"/v1/api/ailab/update_application",
        get_application:"/v1/api/ailab/get_application",
        get_company_details: "/v1/api/ailab/get_company",
        get_ailab_user_profile:"/v1/api/ailab/get_profile",
        get_application_list:"/v1/api/ailab/list_application"
    }
};

import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { inviteTeamMember } from "../../../services/businessServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function InviteAccountAdmin() {
  const toast = useToast();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const buttonBg = useColorModeValue("blue.200", "gray.600");
  const buttonText = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    retypeEmail: "",
  });

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };

  const validateFieldForm = () => {
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(/^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    if (!form.firstname) {
      formIsValid = false;
      errors["firstname"] = "*Please enter your first name.";
    }

    if (!form.lastname) {
      formIsValid = false;
      errors["lastname"] = "*Please enter your last name.";
    }

    if (!form.email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email.";
    } else if (!emailPattern.test(form.email)) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }

    if (!form.retypeEmail) {
      formIsValid = false;
      errors["retypeEmail"] = "*Please retype your email.";
    } else if (form.email !== form.retypeEmail) {
      formIsValid = false;
      errors["retypeEmail"] = "*Emails do not match.";
    }

    setError(errors);
    return formIsValid;
  };

  const showModal = () => {
    if (validateFieldForm()) {
      onOpen()
    }
  }

  const handleSubmit = () => {
    onClose()
    if (validateFieldForm()) {
      const ObjParams = {
        first_name: form.firstname,
        last_name: form.lastname,
        admin_email: form.email
      }
      setLoading(true);
      inviteTeamMember(ObjParams).then(resp=>{
        if(resp?.data[0].status != false){
          toast(toastFunctionToaster("Successfully invited team member", "success"))
        }else{
          toast(toastFunctionToaster("Failed to invite team member", "error"))
        }
      }).catch(err=>toast(toastFunctionToaster("Failed to invite team member", "error")))
      setLoading(false)
    }
  };

  return (
    <>
      <Heading color={textColor} fontSize="lg" mb="10px">
        Team Member Information
      </Heading>
      <Text fontSize="md" color={textColorSecondary} mb={2}>
        Please provide Team Member information to join Lingolet platform.
      </Text>
      <FormControl>
        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          First Name<Text color="red" as="span">*</Text>
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          placeholder="First Name"
          mb="14px"
          size="lg"
          name="firstname"
          onChange={onUpdateField}
          value={form.firstname}
        />
        <Text color="red" mb="4" mt="-3">
          {error.firstname}
        </Text>

        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          Last Name<Text color="red" as="span">*</Text>
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          placeholder="Last Name"
          mb="14px"
          size="lg"
          name="lastname"
          onChange={onUpdateField}
          value={form.lastname}
        />
        <Text color="red" mb="4" mt="-3">
          {error.lastname}
        </Text>

        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          Email<Text color="red" as="span">*</Text>
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          placeholder="mail@example.com"
          mb="14px"
          size="lg"
          name="email"
          onChange={onUpdateField}
          value={form.email}
        />
        <Text color="red" mb="4" mt="-3">
          {error.email}
        </Text>

        <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
          Retype Email<Text color="red" as="span">*</Text>
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          placeholder="Retype Email"
          mb="14px"
          size="lg"
          name="retypeEmail"
          onChange={onUpdateField}
          value={form.retypeEmail}
        />
        <Text color="red" mb="4" mt="-3">
          {error.retypeEmail}
        </Text>

        <Button
          fontSize="sm"
          bg={buttonBg}
          color={buttonText}
          fontWeight="500"
          w="100%"
          h="50"
          mb="24px"
          onClick={showModal}
          isDisabled={loading}
          isLoading={loading}
        >
          Submit
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite Team Member</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Please confirm the following details:</Text>
              <Text><strong>First Name:</strong> {form.firstname}</Text>
              <Text><strong>Last Name:</strong> {form.lastname}</Text>
              <Text><strong>Email:</strong> {form.email}</Text>
            </ModalBody>

            <ModalFooter>
              <Button
                fontSize="sm"
                bg={buttonBg}
                color={buttonText}
                fontWeight="500"
                onClick={handleSubmit}
                isDisabled={loading}
                isLoading={loading}
                mr={2}
              >
                confirm
              </Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


      </FormControl>
    </>
  );
}

export default InviteAccountAdmin;

import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Box, Button, Input, FormControl, Textarea,
  FormLabel, Accordion, AccordionItem, AccordionButton, AccordionPanel,
  AccordionIcon, VStack, Text, Icon, Switch, HStack, Drawer, 
  DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton,
  DrawerFooter, DrawerHeader,
  useDisclosure, useToast
} from "@chakra-ui/react";
import { RiEditLine } from '@remixicon/react';

import BankInfo from "./BankInfo";
import TaxInfo from "./TaxInfo";
import AddressInfo from "./AddressInfo";
import ContactInfo from "./ContactInfo";

import { INFO_TYPES, TABS } from "./constants";

import { getLingoletIdentityList, createLingoletIdentity, 
  updateLingoletIdentity, manageLingoletIdentity, createLingoletIdContact,
  createLingoletIdAddress, createLingoletIdBank, createLingoletIdTax, 
  updateLingoletIdContact, updateLingoletIdAddress,
  updateLingoletIdBank, updateLingoletIdTax,
  getLingoletIdTaxList, getLingoletIdBankList,
  getLingoletIdAddressList, getLingoletIdContactList 
} from "../../../../services/accountServices";

import { toastFunctionToaster } from "../../../../utils/toastFunction";

// const CustomSwitch = ({checked}) => {
//     return (
//         <Switch 
//             checked={checked}
//             colorScheme="teal"  // A softer color for a modern look
//             size="lg"           // Keeping the large size for better visibility
//             sx={{
//                 // Styling the switch track (the background part)
//                 '.chakra-switch__track': {
//                     backgroundColor: 'gray.300',       // Background color when inactive
//                     borderRadius: '9999px',            // Making it more rounded
//                     transition: 'background-color 0.3s ease', // Smooth transition when switching
//                 },
//                 // Styling the track when active
//                 '.chakra-switch__track[data-checked]': {
//                     backgroundColor: 'teal.400',       // Color when active
//                 },
//                 // Styling the thumb (the toggle button)
//                 '.chakra-switch__thumb': {
//                     backgroundColor: 'white',          // Thumb color
//                     boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for a sleek look
//                     transition: 'transform 0.3s ease', // Smooth toggle animation
//                 },
//                 // Slight scale animation on interaction
//                 '&:hover .chakra-switch__thumb': {
//                     transform: 'scale(1.1)',           // Enlarge on hover
//                 },
//             }}
//         />
//     )
//   } 

  

const CustomerList = memo(({customers, handleInfoClick, handleBaseToggleChange, handleEdit}) => {
  const [editableCustomers, setEditableCustomer] = useState(customers);
  const [index, setIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e, field, index) => {
    const updatedCustomers = [...editableCustomers];
    updatedCustomers[index][field] = e.target.value;

    if(field === 'id_employed' || field === 'id_retired') {
      updatedCustomers[index][field] = e.target.checked
    }
    setEditableCustomer(updatedCustomers);
  }

  const handleClick = (index) => {
    setIndex(index);
    onOpen();
  }

    return (<>
              <Box mt={10}>
                  <VStack spacing={4} align="stretch">
                      {editableCustomers.length > 0 ? (
                          editableCustomers.map((customer, index) => (
                          <Box id={customer.identity_uuid} key={index} pb={12} boxShadow={"md"} border={"1px"} borderColor="gray.200" p={4} borderRadius="md" position="relative">
                              {/* Toggle and Edit Buttons at Top Right */}
                              <HStack position={"absolute"} top={2} right={2}>
                                  <Switch isChecked={customer.id_enabled}
                                  onChange={(e) => handleBaseToggleChange(customer.identity_uuid)}
                                  />
                                  <Button leftIcon={<Icon as={RiEditLine} />} size="sm" colorScheme="yellow" onClick={() => handleClick(index)} >Edit</Button>
                              </HStack>

                              {/* Base Info */}
                              <Text fontWeight="bold">
                                  {customer.id_salutation} {customer.id_first_name} {customer.id_middle_name} {customer.id_last_name} {customer.id_suffix}
                              </Text>
                              <Text>Nickname: {customer.id_nick_name}</Text>
                              <Text>Gender: {customer.id_gender}</Text>
                              <Text>Sex: {customer.id_sex}</Text>
                              <Text>Age: {customer.id_dob ? new Date().getFullYear() - new Date(customer.id_dob).getFullYear() : 'N/A'}</Text>
                              <Text>Nationality: {customer.id_nationality}</Text>
                              <Text>Ethnicity: {customer.id_ethnicity}</Text>
                              <Text>Birth Country: {customer.id_birth_country}</Text>
                              <Text>Residential Country: {customer.id_residential_country}</Text>
                              <Text>Time Zone: {customer.id_time_zone}</Text>
                              <Text>Marital Status: {customer.id_marital_status}</Text>
                              <Text>Employment Status: {customer.id_employment_status}</Text>
                              <Text>Employed: {customer.id_employed ? 'Yes' : 'No'}</Text>
                              <Text>Retired: {customer.id_retired ? 'Yes' : 'No'}</Text>

                              {/* Additional Info Buttons at Bottom Left */}
                              <HStack bottom={2} left={2} spacing={2} mt={2}>
                                  <Button size="sm" colorScheme="blue" onClick={() => handleInfoClick(INFO_TYPES.CONTACT, {identity_uuid: customer.identity_uuid})}>Contact Info</Button>
                                  <Button size="sm" colorScheme="blue" onClick={() => handleInfoClick(INFO_TYPES.ADDRESS, {identity_uuid: customer.identity_uuid})}>Address</Button>
                                  <Button size="sm" colorScheme="blue" onClick={() => handleInfoClick(INFO_TYPES.BANK, {identity_uuid: customer.identity_uuid})}>Bank Info</Button>
                                  <Button size="sm" colorScheme="blue" onClick={() => handleInfoClick(INFO_TYPES.TAX, {identity_uuid: customer.identity_uuid})}>Tax Info</Button>
                              </HStack>
                          </Box>
                          ))
                      ) : (
                          <Text>No customers added yet.</Text>
                      )}
                  </VStack>
              </Box>

              <Drawer isOpen={isOpen} onClose={onClose} size="md">
              <DrawerOverlay />
              <DrawerContent>
                  <DrawerHeader>Edit Customer Details</DrawerHeader>
                  <DrawerBody>
                      <FormControl>
                          <FormLabel>Salutation</FormLabel>
                          <Input value={editableCustomers[index]?.id_salutation || ''} onChange={(e) => handleChange(e, 'id_salutation', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>First Name</FormLabel>
                          <Input value={editableCustomers[index]?.id_first_name || ''} onChange={(e) => handleChange(e, 'id_first_name', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Middle Name</FormLabel>
                          <Input value={editableCustomers[index]?.id_middle_name || ''} onChange={(e) => handleChange(e, 'id_middle_name', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Last Name</FormLabel>
                          <Input value={editableCustomers[index]?.id_last_name || ''} onChange={(e) => handleChange(e, 'id_last_name', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Nickname</FormLabel>
                          <Input value={editableCustomers[index]?.id_nick_name || ''} onChange={(e) => handleChange(e,'id_nick_name', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Suffix</FormLabel>
                          <Input value={editableCustomers[index]?.id_suffix || ''} onChange={(e) => handleChange(e,'id_suffix', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Time Zone</FormLabel>
                          <Input value={editableCustomers[index]?.id_time_zone || ''} onChange={(e) => handleChange(e,'id_time_zone', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Gender</FormLabel>
                          <Input value={editableCustomers[index]?.id_gender || ''} onChange={(e) => handleChange(e,'id_gender', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Sex</FormLabel>
                          <Input value={editableCustomers[index]?.id_sex || ''} onChange={(e) => handleChange(e,'id_sex', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Date of Birth (YYYY-MM-DD)</FormLabel>
                          <Input value={editableCustomers[index]?.id_dob || ''} onChange={(e) => handleChange(e,'id_dob', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Ethnicity</FormLabel>
                          <Input value={editableCustomers[index]?.id_ethnicity || ''} onChange={(e) => handleChange(e,'id_ethnicity', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Birth Country</FormLabel>
                          <Input value={editableCustomers[index]?.id_birth_country || ''} onChange={(e) => handleChange(e,'id_birth_country', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Nationality</FormLabel>
                          <Input value={editableCustomers[index]?.id_nationality || ''} onChange={(e) => handleChange(e,'id_nationality', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Employment Status</FormLabel>
                          <Input value={editableCustomers[index]?.id_employment_status || ''} onChange={(e) => handleChange(e,'id_employment_status', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Employed</FormLabel>
                          <Switch isChecked={editableCustomers[index]?.id_employed || false} onChange={(e) => handleChange(e,'id_employed', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Retired</FormLabel>
                          <Switch isChecked={editableCustomers[index]?.id_retired || false} onChange={(e) => handleChange(e,'id_retired', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Residential Country</FormLabel>
                          <Input value={editableCustomers[index]?.id_residential_country || ''} onChange={(e) => handleChange(e,'id_residential_country', index)} />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Marital Status</FormLabel>
                          <Input value={editableCustomers[index]?.id_marital_status || ''} onChange={(e) => handleChange(e,'id_marital_status', index)} />
                      </FormControl>
                  </DrawerBody>
                  <DrawerFooter>
                      <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                      <Button colorScheme="blue" onClick={(e) => handleEdit( INFO_TYPES.BASEINFO, editableCustomers[index])}>Save</Button>
                  </DrawerFooter>
              </DrawerContent>
              </Drawer>
            </>
        )  
    });

const AdditionalInfo = ({infoType, isOpen, onClose, contactList, addressList, bankList, taxList, handleEdit}) => {
    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay  />
            <DrawerContent maxWidth={"25vw"}>
            <DrawerCloseButton />
            <DrawerBody>
                <Text fontSize="lg" fontWeight="bold" mt={4}>
                {infoType}
                </Text>
                <Text mt={2}>
                {/* Dynamic content based on the selected type */}
                {infoType === INFO_TYPES.CONTACT && <ContactInfo contactList={contactList} handleEdit={handleEdit}/>}
                {infoType === INFO_TYPES.ADDRESS && <AddressInfo addressList={addressList} handleEdit={handleEdit}/>}
                {infoType === INFO_TYPES.BANK && <BankInfo bankList={bankList} handleEdit={handleEdit}/>}
                {infoType === INFO_TYPES.TAX && <TaxInfo taxList={taxList} handleEdit={handleEdit}/>}
                </Text>
            </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}


const CustomerManagement = () => {
  const [activeTab, setActiveTab] = useState(TABS.ADDCUSTOMER);
  const [customers, setCustomers] = useState([]);
  const [isBaseInfoAdded, setIsBaseInfoAdded] = useState(false);

  const [customerData, setCustomerData] = useState({
    id_salutation: "",
    id_first_name: "",
    id_middle_name: "",
    id_last_name: "",
    id_nick_name: "",
    id_suffix: "",
    id_time_zone: "",
    id_gender: "",
    id_dob: null,
    id_ethnicity: "",
    id_birth_country: "",
    id_nationality: "",
    id_employment_status: "",
    id_employed: false,
    id_retired: false,
    id_residential_country: "",
    id_marital_status: "",
    id_sex: "",
    id_enabled: true
  });

  const [addressData, setAddressData] = useState({
    id_address_type: "",
    id_address_company_uuid: "",
    id_address_line1: "",
    id_address_line2: "",
    id_address_city: "",
    id_address_state: "",
    id_address_zip: "",
    id_address_country: "",
    id_address_region: "",
    id_address_active: true,
    identity_uuid: ""
  });

  const [contactData, setContactData] = useState({
    identity_uuid: "", 
    id_contact_home_phone: "",
    id_contact_mobile_phone: "",
    id_contact_business_phone: "",
    id_contact_other_phone: [],
    id_contact_primary_email: "",
    id_contact_secondary_email: []
  });

  const [bankData, setBankData] = useState({
    identity_bank_name: "",
    identity_bank_location: "",
    identity_bank_branch: "",
    identity_bank_address: "",
    identity_bank_account_type: "", 
    identity_bank_account_description: "",
    identity_bank_account_reference: "",
    identity_bank_account_number: "",
    identity_bank_sft_routing_transit: "",
    identity_bank_account_swift: "",
    identity_bank_account_iban: "",
    identity_bank_currency_code: "",
    identity_bank_currency_symbol: "",
    identity_bank_active: true,
    identity_uuid: "",
});

const [taxData, setTaxData] = useState({
  identity_tax_identifier: "",
  identity_tax_identifier_info: "",
  identity_tax_identifier_rate: null,
  identity_tax_identifier_country: "",
  identity_tax_identifier_state: "",
  identity_tax_active: true,
  identity_uuid: "",
});

  const [contactList, setContactList] = useState('');
  const [addressList, setAddressList] = useState('');
  const [bankList, setBankList] = useState('');
  const [taxList, setTaxList] = useState('');
  const [infoType, setInfoType] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idUuid, setIdUuid] = useState("");
	const toast = useToast();

  

  // const handleInfoClick = useCallback(async(type, identityObject) => {
  //   setInfoType(type);
  //   await fetchAdditionalInfo(type, identityObject);
  //   onOpen();
  // }, [onOpen, type]);

    const handleInfoClick = useCallback(async(type, identityObject) => {
      setInfoType(type);
      await fetchAdditionalInfo(type, identityObject);
      onOpen();
    }, [onOpen]);

  const handleBaseToggleChange = async(identity_uuid) => {
    try {
      const params = {
        identity_uuid: identity_uuid,
      }
      const response = await manageLingoletIdentity(params);
      
      if(response?.data[0]?.status) {
        toast(toastFunctionToaster("Updated successfully", "success"));
        await fetchCustomers();
      } else {
        toast(toastFunctionToaster("Failed to update", "error"));
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to update", "error"));
      console.error(error);
    }
  }

  const fetchCustomers = async () => {
    try {
      const filter = {
        filter_key:"",
        filter_value:""
      }
      const customerList = await getLingoletIdentityList(filter);
      const records = customerList?.data[0];
  
      if(records?.status) {
        setCustomers(records?.data);
      } else {
        toast(toastFunctionToaster("Failed to get the list", "error"));
        setCustomers([]);
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to get the list", "error"));
      setCustomers([]);
      console.error(error);
    }
  }

  const fetchAdditionalInfo = async (type, identityObject) => {
    try {
      if(type === INFO_TYPES.CONTACT) {
        const contactList = await getLingoletIdContactList(identityObject);
        const records = contactList?.data[0];
        if(records?.status) {
          setContactList(records?.data);
        } else {
          toast(toastFunctionToaster("Failed to get the list", "error"));
          setContactList([]);
        }
      } else if(type === INFO_TYPES.ADDRESS) {
        const addressList = await getLingoletIdAddressList(identityObject);
        const records = addressList?.data[0];
        if(records?.status) {
          setAddressList(records?.data);
        } else {
          toast(toastFunctionToaster("Failed to get the list", "error"));
          setAddressList([]);
        }
      } else if(type === INFO_TYPES.BANK) {
        const bankList = await getLingoletIdBankList(identityObject);
        const records = bankList?.data[0];
        if(records?.status) {
          setBankList(records?.data);
        } else {
          toast(toastFunctionToaster("Failed to get the list", "error"));
          setBankList([]);
        }
      } else if(type === INFO_TYPES.TAX) {
        const taxList = await getLingoletIdTaxList(identityObject);
        const records = taxList?.data[0];
        if(records?.status) {
          setTaxList(records?.data);
        } else {
          toast(toastFunctionToaster("Failed to get the list", "error"));
          setTaxList([]);
        }
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to get the list", "error"));
      console.error(error);
    }
  }


  // Handle changes in the form inputs
  const handleInputChange = (e, type) => {
    switch (type) {
      case INFO_TYPES.BASEINFO:
        setCustomerData({ ...customerData, [e.target.name]: e.target.value });
        break;
      case INFO_TYPES.ADDRESS:
        setAddressData({ ...addressData, [e.target.name]: e.target.value });
        break;
      case INFO_TYPES.CONTACT:
        setContactData({ ...contactData, [e.target.name]: e.target.value });
        break;
      case INFO_TYPES.BANK:
        setBankData({ ...bankData, [e.target.name]: e.target.value });
        break;
      case INFO_TYPES.TAX:
        setTaxData({ ...taxData, [e.target.name]: e.target.value });
        break;
      default:
        console.error("Invalid info type");
        break;
    }
  };

  const handleEdit = async(type, data) => {
    try {
      switch (type) {
        case INFO_TYPES.BASEINFO:
          const res = await updateLingoletIdentity(data);
          if(res?.data[0]?.status) {
            toast(toastFunctionToaster("Identity updated successfully", "success"));
            await fetchCustomers();
          } else {
            toast(toastFunctionToaster("Failed to update identity", "error"));
            setCustomers([]);
          }
          break;
        case INFO_TYPES.ADDRESS:
          const addressResp = await updateLingoletIdAddress(data);
          if(addressResp?.data[0]?.status) {
            toast(toastFunctionToaster("Address updated successfully", "success"));
            await fetchAdditionalInfo(INFO_TYPES.ADDRESS, {identity_uuid: data.identity_uuid});
          } else {
            toast(toastFunctionToaster("Failed to update address", "error"));
            setAddressList([]);
          }
          break;
        case INFO_TYPES.CONTACT:
          const contactData = Object.assign({}, data);
          if(contactData?.id_contact_secondary_email) {
            const list = contactData?.id_contact_secondary_email.split(",");
            contactData.id_contact_secondary_email = list;
          }
          if(contactData?.id_contact_other_phone) {
            const list = contactData?.id_contact_other_phone.split(",");
            contactData.id_contact_other_phone = list;
          }
          console.log(JSON.stringify(contactData))

          const contactResp = await updateLingoletIdContact(contactData);
          if(contactResp?.data[0]?.status) {
            toast(toastFunctionToaster("Contact updated successfully", "success"));
            await fetchAdditionalInfo(INFO_TYPES.CONTACT, {identity_uuid: data.identity_uuid});
          } else {
            toast(toastFunctionToaster("Failed to update contact", "error"));
            setContactList([]);
          }
          break;
        case INFO_TYPES.BANK:
          const bankResp = await updateLingoletIdBank(data);
          if(bankResp?.data[0]?.status) {
            toast(toastFunctionToaster("Bank updated successfully", "success"));
            await fetchAdditionalInfo(INFO_TYPES.BANK, {identity_uuid: data.identity_uuid});
          } else {
            toast(toastFunctionToaster("Failed to update bank", "error"));
            setBankList([]);
          }
          break;
        case INFO_TYPES.TAX:
          const taxResp = await updateLingoletIdTax(data);
          if(taxResp?.data[0]?.status) {
            toast(toastFunctionToaster("Tax updated successfully", "success"));
            await fetchAdditionalInfo(INFO_TYPES.TAX, {identity_uuid: data.identity_uuid});
          } else {
            toast(toastFunctionToaster("Failed to update tax", "error"));
            setTaxList([]);
          }
          break;
        default:
          console.error("Invalid info type");
          break;
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to update", "error"));
      console.error(error);
    }
  };

  const handleReset = (type) => {
    try {
      switch (type) {
        case INFO_TYPES.BASEINFO:          
          setCustomerData({
            id_salutation: "",
            id_first_name: "",
            id_middle_name: "",
            id_last_name: "",
            id_nick_name: "",
            id_suffix: "",
            id_time_zone: "",
            id_gender: "",
            id_dob: "",
            id_ethnicity: "",
            id_birth_country: "",
            id_nationality: "",
            id_employment_status: "",
            id_employed: false,
            id_retired: false,
            id_residential_country: "",
            id_marital_status: "",
            id_sex: "",
            id_enabled: true
          });
          break;
        case INFO_TYPES.ADDRESS:
          setAddressData({
            id_address_type: "",
            id_address_company_uuid: "",
            id_address_line1: "",
            id_address_line2: "",
            id_address_city: "",
            id_address_state: "",
            id_address_zip: "",
            id_address_country: "",
            id_address_region: "",
            id_address_active: true,
            identity_uuid: ""
          });
          break;
        case INFO_TYPES.CONTACT:
          setContactData({
            identity_uuid: "", 
            id_contact_home_phone: "",
            id_contact_mobile_phone: "",
            id_contact_business_phone: "",
            id_contact_other_phone: [],
            id_contact_primary_email: "",
            id_contact_secondary_email: []
          });
          break;
        case INFO_TYPES.BANK:
          setBankData({
            identity_bank_name: "",
            identity_bank_location: "",
            identity_bank_branch: "",
            identity_bank_address: "",
            identity_bank_account_type: "", 
            identity_bank_account_description: "",
            identity_bank_account_reference: "",
            identity_bank_account_number: "",
            identity_bank_sft_routing_transit: "",
            identity_bank_account_swift: "",
            identity_bank_account_iban: "",
            identity_bank_currency_code: "",
            identity_bank_currency_symbol: "",
            identity_bank_active: true,
            identity_uuid: ""
          });
          break;
        case INFO_TYPES.TAX:
          setTaxData({
            identity_tax_identifier: "",
            identity_tax_identifier_info: "",
            identity_tax_identifier_rate: null,
            identity_tax_identifier_country: "",
            identity_tax_identifier_state: "",
            identity_tax_active: true,
            identity_uuid: ""
          });
          break;
        default:
          console.error("Invalid info type");
          break;
      };
    } catch (error) {
      toast(toastFunctionToaster("Failed to reset", "error"));
      console.error(error);
    }
  };

  const handleSaveBaseInfo = async() => {
    try {
      const response = await createLingoletIdentity(customerData);
      if(response?.data[0]?.status) {
        toast(toastFunctionToaster("Customer added successfully", "success"));
        setIdUuid(response?.data[0]?.identity_uuid);
        setIsBaseInfoAdded(true);
        fetchCustomers();
        handleReset(INFO_TYPES.BASEINFO);
      } else {
        toast(toastFunctionToaster("Failed to add the customer", "error"));
        setIsBaseInfoAdded(false);
        setIdUuid("");
      }
    } catch (error) {
      toast(toastFunctionToaster("Failed to add the customer", "error"));
      setIdUuid("");
      setIsBaseInfoAdded(false);
      handleReset(INFO_TYPES.BASEINFO);
    }
  };

  const handleSave = async(type, data, _idUuid) => {
    try {
      switch (type) {
        case INFO_TYPES.CONTACT:

          const contactData = Object.assign({}, data);
          contactData.identity_uuid = _idUuid;
          const respContact = await createLingoletIdContact(contactData);

          if(respContact?.data[0]?.status) {
            toast(toastFunctionToaster("Contact added successfully", "success"));
            handleReset(INFO_TYPES.CONTACT);
          } else {
            toast(toastFunctionToaster("Failed to add contact", "error"));
            handleReset(INFO_TYPES.CONTACT);           
          }
          break;
        case INFO_TYPES.ADDRESS:

          const addressData = Object.assign({}, data);
          addressData.identity_uuid = _idUuid;
          const respAddress = await createLingoletIdAddress(addressData);

          if(respAddress?.data[0]?.status) {
            toast(toastFunctionToaster("Address added sucessfully", "success"))
            handleReset(INFO_TYPES.ADDRESS);
          } else {
            toast(toastFunctionToaster("Failed to add address", "error"));
            handleReset(INFO_TYPES.ADDRESS);
          }
          break;
        case INFO_TYPES.BANK:

          const bankData = Object.assign({}, data);
          bankData.identity_uuid = _idUuid;
          const respBank = await createLingoletIdBank(bankData);

          if(respBank?.data[0]?.status) {
            toast(toastFunctionToaster("Bank added successfully", "success"));
            handleReset(INFO_TYPES.BANK);
          } else {
            toast(toastFunctionToaster("Failed to add bank", "error"));
            handleReset(INFO_TYPES.BANK);
          }
          break;
        case INFO_TYPES.TAX:

          const taxData = Object.assign({}, data);
          taxData.identity_uuid = _idUuid;
          const respTax = await createLingoletIdTax(taxData);

          if(respTax?.data[0]?.status) {
            toast(toastFunctionToaster("Tax added successfully", "success"));
            handleReset(INFO_TYPES.TAX);
          } else {
            toast(toastFunctionToaster("Failed to add tax", "error"));
            handleReset(INFO_TYPES.TAX);
          }
          break;
        default:
          console.error("Invalid info type");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }


  const handleSaveAdditionalInfo = async (type) => {
    try {

      switch (type) {
        case INFO_TYPES.CONTACT:
          await handleSave(INFO_TYPES.CONTACT, contactData, idUuid);
          break;
        case INFO_TYPES.ADDRESS:
          await handleSave(INFO_TYPES.ADDRESS, addressData, idUuid);
          break;
        case INFO_TYPES.BANK:
          await handleSave(INFO_TYPES.BANK, bankData, idUuid);
          break;
        case INFO_TYPES.TAX:
          await handleSave(INFO_TYPES.TAX, taxData, idUuid);
          break;
        default:
           console.error("Invalid info type");
          break;
        };
    } catch (error) {
        toast(toastFunctionToaster("Something went wrong", "error"));
        console.error(error);
    };
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <Box pt={{ sm: "125px", lg: "75px" }} pl={3} h={'94vh'} overflowX={"auto"} scrollBehavior={"smooth"}>
      {/* Buttons */}
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2} mb={2} pt={4} borderTop={'1px'} borderColor={'gray.200'}>
        <Button
          id="customers"
          colorScheme={activeTab === TABS.CUSTOMER ? "blue" : "gray"}
          onClick={() => setActiveTab(TABS.CUSTOMER)}
        >
          Customers
        </Button>
        <Button
          id="add-customer"
          colorScheme={activeTab === TABS.ADDCUSTOMER ? "green" : "gray"}
          onClick={() => setActiveTab(TABS.ADDCUSTOMER)}
        >
          Add Customer
        </Button>
      </Box>

      {/* Add/Edit Customer Section */}
      {activeTab === TABS.ADDCUSTOMER && (
        <Box mt={10}>
          <VStack spacing={4} align="stretch">
          <Box border="1px" borderColor="gray.200" p={4} borderRadius="md">
            <FormControl>
              <FormLabel>Base Information</FormLabel>
              
              {/* Salutation */}
              <Input 
                name="id_salutation" 
                placeholder="Enter salutation" 
                value={customerData.id_salutation} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
              />

              {/* First Name */}
              <Input 
                name="id_first_name" 
                placeholder="Enter first name" 
                value={customerData.id_first_name} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
                isRequired={true}
              />

              {/* Middle Name */}
              <Input 
                name="id_middle_name" 
                placeholder="Enter middle name" 
                value={customerData.id_middle_name} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Last Name */}
              <Input 
                name="id_last_name" 
                placeholder="Enter last name" 
                value={customerData.id_last_name} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
                isRequired={true}
              />

              {/* Nick Name */}
              <Input 
                name="id_nick_name" 
                placeholder="Enter nick name" 
                value={customerData.id_nick_name} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Suffix */}
              <Input 
                name="id_suffix" 
                placeholder="Enter suffix" 
                value={customerData.id_suffix} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Time Zone */}
              <Input 
                name="id_time_zone" 
                placeholder="Enter time zone" 
                value={customerData.id_time_zone} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Gender */}
              <Input 
                name="id_gender" 
                placeholder="Enter gender" 
                value={customerData.id_gender} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Date of Birth */}
              <Input 
                name="id_dob" 
                placeholder="Enter date of birth" 
                value={customerData.id_dob} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Ethnicity */}
              <Input 
                name="id_ethnicity" 
                placeholder="Enter ethnicity" 
                value={customerData.id_ethnicity} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Birth Country */}
              <Input 
                name="id_birth_country" 
                placeholder="Enter birth country (YYYY-MM-DD)" 
                value={customerData.id_birth_country} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Nationality */}
              <Input 
                name="id_nationality" 
                placeholder="Enter nationality" 
                value={customerData.id_nationality} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Employment Status */}
              <Input 
                name="id_employment_status" 
                placeholder="Enter employment status" 
                value={customerData.id_employment_status} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Residential Country */}
              <Input 
                name="id_residential_country" 
                placeholder="Enter residential country" 
                value={customerData.id_residential_country} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Marital Status */}
              <Input 
                name="id_marital_status" 
                placeholder="Enter marital status" 
                value={customerData.id_marital_status} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />

              {/* Sex */}
              <Input 
                name="id_sex" 
                placeholder="Enter sex" 
                value={customerData.id_sex} 
                onChange={(e) => handleInputChange(e, INFO_TYPES.BASEINFO)} 
                mt={2}
              />
            </FormControl>
            <Button colorScheme="blue" mt={4} onClick={handleSaveBaseInfo}>Save</Button>
          </Box>

            {/* Accordion for Additional Information */}
            {isBaseInfoAdded && (
              <Accordion allowToggle mt={4}>
                {/* Address Information */}
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Address Information</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <FormControl>
                      <FormLabel>Address Type</FormLabel>
                      <Input
                        name="id_address_type"
                        placeholder="Enter address type (e.g., residential, business)"
                        value={addressData.id_address_type}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Company UUID</FormLabel>
                      <Input
                        name="id_address_company_uuid"
                        placeholder="Enter company UUID (if applicable)"
                        value={addressData.id_address_company_uuid}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Street Address Line 1</FormLabel>
                      <Input
                        name="id_address_line1"
                        placeholder="Enter street address line 1"
                        value={addressData.id_address_line1}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Street Address Line 2</FormLabel>
                      <Input
                        name="id_address_line2"
                        placeholder="Enter street address line 2"
                        value={addressData.id_address_line2}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="id_address_city"
                        placeholder="Enter city"
                        value={addressData.id_address_city}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>State</FormLabel>
                      <Input
                        name="id_address_state"
                        placeholder="Enter state"
                        value={addressData.id_address_state}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>ZIP Code</FormLabel>
                      <Input
                        name="id_address_zip"
                        placeholder="Enter ZIP code"
                        value={addressData.id_address_zip}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="id_address_country"
                        placeholder="Enter country"
                        value={addressData.id_address_country}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Region</FormLabel>
                      <Input
                        name="id_address_region"
                        placeholder="Enter region"
                        value={addressData.id_address_region}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.ADDRESS)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Active</FormLabel>
                      <Switch
                        isChecked={addressData.id_address_active}
                        onChange={(e) => handleInputChange({ target: { name: "id_address_active", value: e.target.checked }}, INFO_TYPES.ADDRESS)}
                      />
                    </FormControl>

                    <Button colorScheme="blue" mt={4} onClick={() => handleSaveAdditionalInfo(INFO_TYPES.ADDRESS)}>
                      Save
                    </Button>
                  </AccordionPanel>
                </AccordionItem>

                {/* Contact Information */}
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Contact Information</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <FormControl>
                      <FormLabel>Home Phone</FormLabel>
                      <Input
                        name="id_contact_home_phone"
                        placeholder="Enter home phone number"
                        value={contactData.id_contact_home_phone}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.CONTACT)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Mobile Phone</FormLabel>
                      <Input
                        name="id_contact_mobile_phone"
                        placeholder="Enter mobile phone number"
                        value={contactData.id_contact_mobile_phone}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.CONTACT)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Business Phone</FormLabel>
                      <Input
                        name="id_contact_business_phone"
                        placeholder="Enter business phone number"
                        value={contactData.id_contact_business_phone}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.CONTACT)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Other Phone Numbers</FormLabel>
                      <Textarea
                        name="id_contact_other_phone"
                        placeholder="Enter other phone numbers, separated by commas"
                        value={contactData.id_contact_other_phone.join(", ")}
                        onChange={(e) => handleInputChange(
                          { target: { name: "id_contact_other_phone", value: e.target.value.split(", ") } },
                          INFO_TYPES.CONTACT
                        )}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Primary Email</FormLabel>
                      <Input
                        name="id_contact_primary_email"
                        placeholder="Enter primary email address"
                        value={contactData.id_contact_primary_email}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.CONTACT)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Secondary Email Addresses</FormLabel>
                      <Textarea
                        name="id_contact_secondary_email"
                        placeholder="Enter secondary email addresses, separated by commas"
                        value={contactData.id_contact_secondary_email.join(", ")}
                        onChange={(e) => handleInputChange(
                          { target: { name: "id_contact_secondary_email", value: e.target.value.split(", ") } },
                          INFO_TYPES.CONTACT
                        )}
                      />
                    </FormControl>

                    <Button colorScheme="blue" mt={4} onClick={() => handleSaveAdditionalInfo(INFO_TYPES.CONTACT)}>
                      Save
                    </Button>
                  </AccordionPanel>
                </AccordionItem>

                {/* Banking Information */}
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Banking Information</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <FormControl>
                      <FormLabel>Bank Name</FormLabel>
                      <Input
                        name="identity_bank_name"
                        placeholder="Enter bank name"
                        value={bankData.identity_bank_name}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Location</FormLabel>
                      <Input
                        name="identity_bank_location"
                        placeholder="Enter bank location"
                        value={bankData.identity_bank_location}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Branch</FormLabel>
                      <Input
                        name="identity_bank_branch"
                        placeholder="Enter branch name"
                        value={bankData.identity_bank_branch}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Address</FormLabel>
                      <Input
                        name="identity_bank_address"
                        placeholder="Enter bank address"
                        value={bankData.identity_bank_address}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Account Type</FormLabel>
                      <Input
                        name="identity_bank_account_type"
                        placeholder="Enter account type (e.g., Savings, Checking)"
                        value={bankData.identity_bank_account_type}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Account Description</FormLabel>
                      <Input
                        name="identity_bank_account_description"
                        placeholder="Enter account description"
                        value={bankData.identity_bank_account_description}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Account Reference</FormLabel>
                      <Input
                        name="identity_bank_account_reference"
                        placeholder="Enter account reference"
                        value={bankData.identity_bank_account_reference}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Account Number</FormLabel>
                      <Input
                        name="identity_bank_account_number"
                        placeholder="Enter account number"
                        value={bankData.identity_bank_account_number}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Routing/Transit Number</FormLabel>
                      <Input
                        name="identity_bank_sft_routing_transit"
                        placeholder="Enter SFT or Routing/Transit number"
                        value={bankData.identity_bank_sft_routing_transit}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>SWIFT Code</FormLabel>
                      <Input
                        name="identity_bank_account_swift"
                        placeholder="Enter SWIFT code"
                        value={bankData.identity_bank_account_swift}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>IBAN</FormLabel>
                      <Input
                        name="identity_bank_account_iban"
                        placeholder="Enter IBAN"
                        value={bankData.identity_bank_account_iban}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Currency Code</FormLabel>
                      <Input
                        name="identity_bank_currency_code"
                        placeholder="Enter currency code (e.g., USD, EUR)"
                        value={bankData.identity_bank_currency_code}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Currency Symbol</FormLabel>
                      <Input
                        name="identity_bank_currency_symbol"
                        placeholder="Enter currency symbol (e.g., $, €)"
                        value={bankData.identity_bank_currency_symbol}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.BANK)}
                      />
                    </FormControl>

                    <Button colorScheme="blue" mt={4} onClick={() => handleSaveAdditionalInfo(INFO_TYPES.BANK)}>
                      Save
                    </Button>
                  </AccordionPanel>
                </AccordionItem>

                {/* Tax Information */}
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Tax Information</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <FormControl>
                      <FormLabel>Tax Identifier</FormLabel>
                      <Input
                        name="identity_tax_identifier"
                        placeholder="Enter tax identifier"
                        value={taxData.identity_tax_identifier}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.TAX)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Tax Identifier Info</FormLabel>
                      <Input
                        name="identity_tax_identifier_info"
                        placeholder="Enter additional tax identifier info"
                        value={taxData.identity_tax_identifier_info}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.TAX)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Tax Rate</FormLabel>
                      <Input
                        name="identity_tax_identifier_rate"
                        type="number"
                        placeholder="Enter tax rate (e.g., 5.5)"
                        value={taxData.identity_tax_identifier_rate}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.TAX)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="identity_tax_identifier_country"
                        placeholder="Enter country for tax"
                        value={taxData.identity_tax_identifier_country}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.TAX)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>State</FormLabel>
                      <Input
                        name="identity_tax_identifier_state"
                        placeholder="Enter state for tax"
                        value={taxData.identity_tax_identifier_state}
                        onChange={(e) => handleInputChange(e, INFO_TYPES.TAX)}
                        isRequired={true}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Active</FormLabel>
                      <Switch
                        isChecked={taxData.identity_tax_active}
                        onChange={(e) => handleInputChange({ target: { name: "identity_tax_active", value: e.target.checked }}, INFO_TYPES.TAX)}
                      />
                    </FormControl>

                    <Button colorScheme="blue" mt={4} onClick={() => handleSaveAdditionalInfo(INFO_TYPES.TAX)}>
                      Save
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </VStack>
        </Box>
      )}

      {/* Customers List Section */}
      {activeTab === TABS.CUSTOMER && (
        <CustomerList customers={customers} handleInfoClick={handleInfoClick} handleBaseToggleChange={handleBaseToggleChange} handleEdit={handleEdit}/>
      )}

        {/* Side Drawer for Info Display */}
        <AdditionalInfo 
            infoType={infoType} 
            isOpen={isOpen} 
            onClose={onClose}
            contactList={contactList}
            addressList={addressList}
            bankList={bankList}
            taxList={taxList}
            handleEdit={handleEdit}
        />

    </Box>
  );
};

export default CustomerManagement;

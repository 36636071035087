import { Alert, AlertDescription, AlertIcon, AlertTitle, Badge, Box, Button, Card, Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react'
import { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import { LuDownload } from "react-icons/lu";
import { useEffect } from 'react';
import Pagination from '../../../components/pagination/Pagination';
import { HiOutlineSearch } from 'react-icons/hi';
import { FaFileAlt } from 'react-icons/fa';

const ControlSpaceAccessContents = ({ data, pageId, setRecordsPerPage, setPageId, recordsPerPage, totalPages, loading, handleModalOpen }) => {
    const [filterData, setFilterData] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    

    function handleFilterData(searchValue) {
        if(!searchValue){
            setFilterData(data)
            return
        }
        const newFilteredData = data?.filter(item => item?.content_title?.indexOf(searchValue) > -1)
        setFilterData(newFilteredData)
    }

    function handleShowFile(data){
        handleModalOpen(data)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
                handleFilterData(searchValue)
        }, 200)
        return () => {
            clearTimeout(timer)
        }
    }, [searchValue, data])

    useEffect(() => {
        if (data) {
            setFilterData(data)
        }
    }, [data])
    return (

        <Card direction="column" p="0" mt="5" h="full" w="full">
            <Box w={"100%"} h="full">
                <Card px="0" h="full">
                    {data && data?.length > 0 &&
                        <Flex alignItems="center" p="2">
                            <InputGroup w="30%">
                                <InputLeftElement pointerEvents='none'>
                                    <Icon as={HiOutlineSearch} color="gray.400" />
                                </InputLeftElement>
                                <Input type='search' placeholder='Search..' borderRadius="lg" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                            </InputGroup>
                        </Flex>
                    }

                    {loading ? (
                        <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
                            <Spinner size="xl" />
                        </Flex>
                    ) : filterData && filterData.length > 0 ? (
                        <Box overflow="hidden" p={4} position="relative" h="full">
                            <Box
                                overflowY="auto"
                                maxH="calc(100% - 50px)"
                                className="custom-scrollbar"
                            >
                                <Table variant="simple">
                                    <Thead position="sticky" top="0" bg="white" zIndex="1">
                                        <Tr borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                            <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                TITLE
                                            </Th>
                                            <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                OVERWRITTEN
                                            </Th>
                                            <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                STORED
                                            </Th>
                                            <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                ACTION
                                            </Th>
                                            <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                TRANSLATED CONTENT
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {filterData.map((item) => (
                                            <Tr
                                                key={item?.uuid}
                                                borderBottomWidth="1px"
                                                borderBottomColor="#d7d7d7"
                                            >
                                                <Td display="flex" alignItems="center">
                                                    <Icon
                                                        as={
                                                            item.type === "application/pdf"
                                                                ? FaFilePdf
                                                                : FaFileAlt
                                                        }
                                                        color={
                                                            item.type === "application/pdf"
                                                                ? "blue.500"
                                                                : "green.500"
                                                        }
                                                        mr={2}
                                                    />
                                                    {item?.title}
                                                </Td>
                                                <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    <Badge
                                                        colorScheme={!item.overwritten ? "red" : "green"}
                                                    >
                                                        {item?.overwritten ? "YES" : "NO"}
                                                    </Badge>
                                                </Td>
                                                <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    <Badge colorScheme={!item.stored ? "red" : "green"}>
                                                        {item?.stored ? "YES" : "NO"}
                                                    </Badge>
                                                </Td>
                                                <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    <Flex gap="1" alignItems="center">
                                                        <Button size="sm" colorScheme="green" onClick={()=>handleShowFile(item)}>
                                                            View Original
                                                        </Button>
                                                        <IconButton
                                                            borderRadius="xl"
                                                            size="sm"
                                                            colorScheme='yellow'
                                                            icon={<LuDownload />}
                                                        />
                                                    </Flex>
                                                </Td>
                                                <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    <Button size="sm" colorScheme="green">
                                                        View Translation
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                            <Flex position="absolute" bottom="0" right="0" w="full">
                                <Pagination
                                    currentPage={pageId}
                                    setCurrentPage={setPageId}
                                    setRecordsPerPage={setRecordsPerPage}
                                    totalPages={totalPages}
                                    recordsPerPage={recordsPerPage}
                                />
                            </Flex>
                            
                        </Box>
                    ) : (
                        <Box w={"100%"} p={5}>
                            <Alert borderRadius="8px" status="error" variant="subtle">
                                <Flex>
                                    <AlertIcon />
                                    <Flex direction="column">
                                        <AlertTitle mr="12px">Empty Resources List</AlertTitle>
                                        <AlertDescription>
                                            No Data Found!!
                                        </AlertDescription>
                                    </Flex>
                                </Flex>
                            </Alert>
                        </Box>
                    )}
                </Card>
            </Box>
        </Card>

    )
}

export default ControlSpaceAccessContents
import { useEffect } from "react";
import QRCode from "react-qr-code";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Chakra Import
import {
	Box,
	Button,
	Flex,
	Text,
	Link,
	Icon,
	Tooltip,
	useDisclosure,
	useColorModeValue
} from "@chakra-ui/react";
import moment from "moment";
import {
	AiOutlineCopy,
	AiOutlineLink,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
} from "react-icons/ai";

// Component Imports
import LinkTags from "./LinkTags";
import LinkDesignWidget from "./LinkDesignWidget";
import LinkQRCodeWidget from "./LinkQRCodeWidget";
import { formatSeconds } from "../../../utils/TimeFunctions";
import { useBlackWhiteBg, useGradientColor, useGray500TextColor,
	useBrandBg, useGreenButton, useYellowButton, useTextColor, useLinkColor } from "../../../theme/globalColorTheme";
import LinkApplication from "./LinkApplication";
import LinkPreferences from "./LinkPreferences";

function LinkItemWidget(props) {
	const navigate = useNavigate()
	const greenButtonBg = useGreenButton();
	const yellowButtonBg = useYellowButton();
	const [linkItem, setLinkItem] = useState(null)
	const port_value = window.location.port ? ":" + window.location.port : '';
	const currentHost = window.location.hostname.includes("localhost")
		? "https://lingolet.ai/"
		: window.location.protocol + "//" + window.location.hostname + port_value + "/";
	// const currentHost = window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();

	const [showTooltip, setShowTooltip] = React.useState(false);
	const [modalQrCode, setModalQrCode] = useState(null);
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const pillButtonText = useColorModeValue("white.100", "gray.800");
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blackWhiteBg = useBlackWhiteBg()
	const blackWhiteText = useTextColor()
	const linkColor = useLinkColor()
	const localGray500TextColor = useGray500TextColor();

	useEffect(() => {
		setLinkItem(props?.linkItem ? props?.linkItem : null)
	}, [props?.linkItem])

	const handleQrCode = () => {
		setModalQrCode('')
	}

	const handleLink = () => {
		props?.reRenderLingoList('render')
	}



	return (
		<>
			{linkItem != null
				? <Flex w={'100%'} alignItems={'start'}>
					<Flex w={"100%"} direction={"column"} p={'10px'}>
						{((linkItem?.class === 2 || linkItem?.class === 3) && linkItem?.trial)
							? <Flex w={'100%'} direction={'column'}>
								<Flex w={"100%"} >
									<Text w={'200px'}  color={localGray500TextColor}>
										Link Start Time (UTC):
									</Text>
									<Text w={'300px'}>
										{moment.utc(linkItem?.starttime).local().format("MMM DD,YYYY,HH:mm a")}
									</Text>
									<Text w={'200px'} ml={2}  color={localGray500TextColor}>
										Link End Time (UTC):
									</Text>
									<Text w={'300px'}>
										{moment.utc(linkItem?.endtime).local().format("MMM DD,YYYY,HH:mm a")}
									</Text>
								</Flex>
								<Flex w={"100%"} mb="10px">
									<Text w={'200px'}  color={localGray500TextColor}>
										Link Assigned Time: 
									</Text>
									<Text w={'300px'}>
										{linkItem?.hours} Hours
									</Text>
									<Text w={'200px'} color={localGray500TextColor}>
										Time Left: 
									</Text>
									<Text w={'300px'}>
										{linkItem?.time_left}
									</Text>
								</Flex>
							</Flex> : null}
						<Flex
							w={"100%"}
							p={1}
							direction={"column"}
							bg={blackWhiteBg}
							rounded={"md"}>
							<Flex w={"100%"} direction={"column"}>
								<Flex w={"100%"} mt={1} mb={1}>
									<Flex w={"100%"}>
										<Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
											{linkItem?.url ? currentHost + linkItem?.url : "No Url"}
										</Text>
									</Flex>
									<Tooltip
										hasArrow
										placement={"top"}
										bg="teal.500"
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}
										color="white"
										label="Copy Url">
										<Box w={"35px"} ml={1}
											_hover={{
												bg: pillButtonBgHighlight,
												cursor: "pointer",
												color:"black"
											}}
											color={blackWhiteText}
											bg={pillButtonBg}
											pr={2} rounded={"md"}
											mr={2}>
											<Icon
												onClick={() =>
													navigator.clipboard.writeText(currentHost + linkItem?.url)
												}
												
												ml={2}
												mt={1}
												h={5}
												w={5}
												as={AiOutlineCopy}
											/>
										</Box>
									</Tooltip>
									<Tooltip
										hasArrow
										placement={"top"}
										bg="teal.500"
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}
										color="white"
										label="Open Url in new Tab">
										<Link
											p={1}
											w={"35px"}
											rounded={"md"}
											mr={2}
											align={"center"}
											href={currentHost + linkItem?.url}
											target="_blank"
											bg={pillButtonBg}
											_hover={{
												bg: pillButtonBgHighlight,
												cursor: "pointer",
												color:"black"
											}}
											color={blackWhiteText}
											>
											<Icon
												ml={2}
												mt={1}
												h={5}
												_hover={{ cursor: "pointer" }}
												w={5}
												as={AiOutlineLink}
											/>
										</Link>
									</Tooltip>
								</Flex>
								<Flex
									w={"100%"}
									mt={1}
									mb={1}
									borderTopColor={gradientColor}
									borderTopWidth={"0.5px"}>
									<Flex w={"100%"} mt={1}>
										<Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
											{linkItem?.fullurl ? currentHost + linkItem?.fullurl : "No Url"}
										</Text>
									</Flex>
									<Tooltip
										hasArrow
										placement={"top"}
										bg="teal.500"
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}
										color="white"
										label="Copy Url">
										<Box
											mt={1}
											w={"35px"}
											ml={1}
											pr={2}
											rounded={"md"}
											mr={2}
											_hover={{
												bg: pillButtonBgHighlight,
												cursor: "pointer",
												color:"black"
											}}
											color={blackWhiteText}
											bg={pillButtonBg}>
											<Icon
												onClick={() =>
													navigator.clipboard.writeText(currentHost + linkItem?.fullurl)
												}
												ml={2}
												mt={1}
												h={5}
												_hover={{ cursor: "pointer" }}
												w={5}
												as={AiOutlineCopy}
											/>
										</Box>
									</Tooltip>
									<Tooltip
										hasArrow
										placement={"top"}
										bg="teal.500"
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}
										color="white"
										label="Open Url in new Tab">
										<Link
											mt={1}
											p={1}
											w={"35px"}
											rounded={"md"}
											mr={2}
											align={"center"}
											href={currentHost + linkItem?.fullurl}
											target="_blank"
											_hover={{
												bg: pillButtonBgHighlight,
												cursor: "pointer",
												color:"black"
											}}
											color={blackWhiteText}
											bg={pillButtonBg}>
											<Icon
												ml={2}
												mt={1}
												h={5}
												_hover={{ cursor: "pointer" }}
												w={5}
												as={AiOutlineLink}
											/>
										</Link>
									</Tooltip>
								</Flex>
								<Flex w={"100%"}>
									{((linkItem?.class === 2 || linkItem?.class === 3) && linkItem?.trial) ? (
										linkItem?.active === false || linkItem?.calls?.expired || linkItem?.dead ? (
											<Flex
												w={"100%"}
												// bg={"red.100"}
												bg={brandBg}
												align={"center"}
												rounded={"md"}
												p={1}
												borderWidth={"0.05px"}
												borderColor={"gray.400"}>
												<Box w={"250px"}>
													{linkItem?.class === 2
													?<Text color={"red.500"}>Expired Trial Link</Text>:null}
													{linkItem?.class === 3
													?<Text color={"red.500"}>Expired Fixed Link</Text>:null}													
												</Box>
												<Flex w={"100%"} direction={"column"}>
													<Box w={"100%"}>
														<Icon
															as={AiOutlineClockCircle}
															mr={1}
															mt={1}
															h={5}
															w={5}
														/>{" "}
														Time:{" "}
														<b>{formatSeconds(linkItem?.calls?.calls[6].toFixed(2))}</b>{" "}
														| Used:{" "}
														<b>{formatSeconds(linkItem?.calls?.calls[7].toFixed(2))}</b>{" "}
														| Remaining:{" "}
														<b>
															{linkItem?.calls?.calls[8] > 0
																? formatSeconds(
																	linkItem?.calls?.calls[8].toFixed(2)
																)
																: "00:00:00"}
														</b>
													</Box>
													{/* <Flex w={"100%"}>
														<Box w={"100%"}>
															<Icon
																as={AiTwotoneAudio}
																mr={1}
																mt={1}
																h={5}
																w={5}
															/>{" "}
															Calls:{" "}
															<b>
																{linkItem?.calls?.calls[0]} | Made:{" "}
																{linkItem?.calls?.calls[1]} | Remaining:{" "}
																{linkItem?.calls?.calls[2]}
															</b>
														</Box>
														<Box w={"100%"}>
															<Icon
																as={AiOutlineVideoCamera}
																mr={1}
																mt={1}
																h={5}
																w={5}
															/>{" "}
															Calls:{" "}
															<b>
																{linkItem?.calls?.calls[3]} | Made:{" "}
																{linkItem?.calls?.calls[4]} | Remaining:{" "}
																{linkItem?.calls?.calls[5]}
															</b>
															)
														</Box>
													</Flex> */}
												</Flex>
											</Flex>
										) : (
											<Flex
												w={"100%"}
												bg={brandBg}
												align={"center"}
												rounded={"md"}
												p={1}
											>
												<Box w={"250px"}>
													{linkItem?.class === 2 ?<Text color={"green.700"}>Active Trial Link</Text>:null}
													{linkItem?.class === 3 ?<Text color={"green.700"}>Active Fixed Link</Text>:null}
												</Box>
												<Flex w={"100%"} direction={"column"} alignItems={'center'}>
													<Flex w={"100%"} alignItems={'center'}>
														<Icon
															as={AiOutlineClockCircle}
															mr={1}
															mt={1}
															h={5}
															w={5}
														/>{" "}
														Time:{" "}
														<b>{formatSeconds(linkItem?.calls?.calls[6].toFixed(2))}</b>{" "}
														| Used:{" "}
														<b>{formatSeconds(linkItem?.calls?.calls[7].toFixed(2))}</b>{" "}
														| Remaining:{" "}
														<b>
															{linkItem?.calls?.calls[8] > 0
																? formatSeconds(
																	linkItem?.calls?.calls[8].toFixed(2)
																)
																: "00:00:00"}{" "}
														</b>
													</Flex>
													{/* <Flex w={"100%"} alignItems={'center'}>
														<Flex w={"100%"} alignItems={'center'}>
															<Icon
																as={AiTwotoneAudio}
																mr={1}
																h={5}
																w={5}
															/>{" "}
															Calls:{" "}
															<b>
																{linkItem?.calls?.calls[0]} | Made:{" "}
																{linkItem?.calls?.calls[1]} | Remaining:{" "}
																{linkItem?.calls?.calls[2]}
															</b>
														</Flex>
														<Flex w={"100%"} alignItems={'center'}>
															<Icon
																as={AiOutlineVideoCamera}
																mr={1}
																h={5}
																w={5}
															/>{" "}
															Calls:{" "}
															<b>
																{linkItem?.calls?.calls[3]} | Made:{" "}
																{linkItem?.calls?.calls[4]} | Remaining:{" "}
																{linkItem?.calls?.calls[5]}
															</b>
														</Flex>
													</Flex> */}
												</Flex>
											</Flex>
										)
									) : (
										<Flex
											w={"100%"}
											bg={brandBg}
											align={"center"}
											rounded={"md"}
											px={2}
										>
											{/* {linkItem?.multichannel === false
											?<Text fontSize={"md"} color={"green.500"} fontWeight={'500'}>
												Not a trial/demo link.
											</Text>:null} */}
										</Flex>
									)}
								</Flex>
							</Flex>
						</Flex>
						<LinkTags linkItem={linkItem} accountId={props?.accountId} locationId={props?.locationId} reListLink={handleLink} />
						<LinkPreferences  linkItem={linkItem} accountId={props?.accountId} locationId={props?.locationId} reListLink={handleLink} langPrefList={props?.langPrefList}/>
						<LinkApplication linkItem={linkItem} accountId={props?.accountId} locationId={props?.locationId} reListLink={handleLink} subAppDetails={props?.subAppDetails}/>
					</Flex>
					<Flex minW={'300px'} maxW={'300px'} flexWrap={'wrap'}>
						<Flex maxW={"150px"}>
							<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
								<Box
									w={"100%"}
									onClick={() => {
										setModalQrCode(linkItem?.url);
										onOpenQr();
									}}
									_hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
									p={1}
									borderColor={"gray.400"}
									borderWidth={"1px"}
									rounded={"md"}>
									<QRCode
										size={90}
										style={{ height: "auto", maxWidth: "100", width: "100" }}
										value={linkItem?.url}
										viewBox={`0 0 90 90`}
									/>
								</Box>
								<Text>QR(Url)</Text>
							</Flex>
						</Flex>
						<Flex maxW={"150px"}>
							<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
								<Box
									w={"100%"}
									onClick={() => {
										setModalQrCode(linkItem?.fullurl);
										onOpenQr();
									}}
									_hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
									p={1}
									borderColor={"gray.400"}
									borderWidth={"1px"}
									rounded={"md"}>
									<QRCode
										size={90}
										style={{ height: "auto", maxWidth: "100", width: "100" }}
										value={linkItem?.fullurl}
										viewBox={`0 0 90 90`}
									/>
								</Box>
								<Text>QR(Url+Code)</Text>
							</Flex>
						</Flex>
						<LinkQRCodeWidget modalQrCode={modalQrCode} isOpenQr={isOpenQr} onCloseQr={onCloseQr} currentHost={currentHost} handleQrCode={handleQrCode} />
					</Flex>
				</Flex> : null}
		</>
	)
}

export default LinkItemWidget;

// Chakra Imports
import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Flex,
    Button,
    Progress,
    Heading,
    Menu,
    MenuButton,
    MenuList,
    MenuItemOption,
    Icon,
    Textarea,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Image,
    useMediaQuery,
    useToast
} from "@chakra-ui/react";
import { AiFillPlayCircle } from 'react-icons/ai'
import { BsPauseCircleFill } from 'react-icons/bs'
import { BiSolidDownload } from 'react-icons/bi'

const AudioPlayer = ({ playNow, audioFile }) => {

    const [isPlaying, setIsPlaying] = useState(playNow);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef()
    const progressBarRef = useRef()

    useEffect(() => {
        const audio = audioRef.current;

        if (audioFile) {

            const audioArrayBuffer = audioFile.AudioStream.buffer;
            const audioURL = URL.createObjectURL(new Blob([audioArrayBuffer], { type: "audio/mpeg" }));

            audio.src = audioURL;

            audio.addEventListener('loadeddata', () => {
                setDuration(audio.duration);
            });

            audio.addEventListener('timeupdate', updateProgressBar);
            audio.addEventListener('ended', () => {
                setIsPlaying(false); // Set isPlaying to false when audio has ended
            });
            setIsPlaying(true);
            audio.play();
            return () => {
                URL.revokeObjectURL(audioURL);
                audio.removeEventListener('timeupdate', updateProgressBar);
                audio.removeEventListener('ended', () => {
                    audio.pause();
                    setIsPlaying(false);
                });
            };
        }
    }, [audioFile]);

    const updateProgressBar = () => {
        const audio = audioRef.current
        const progress = (audio.currentTime / audio.duration) * 100
        setCurrentTime(audio.currentTime)
        progressBarRef.current.style.width = `${progress}%`
    }

    const downloadAudio = () => {
        if (audioFile) {
            const audioArrayBuffer = audioFile.AudioStream.buffer
            const audioURL = URL.createObjectURL(new Blob([audioArrayBuffer], { type: "audio/mpeg" }))

            const a = document.createElement('a')
            a.href = audioURL

            a.download = "audio.mp3"
            a.style.display = "none"
            document.body.appendChild(a)

            a.click()

            document.body.removeChild(a)
            URL.revokeObjectURL(audioURL)
        }
    }

    const togglePlay = () => {
        const audio = audioRef.current
        if (isPlaying) {
            audio.pause()
        } else {
            audio.play()
        }
        setIsPlaying(!isPlaying)
    }

    return (
        <Flex w={'100%'} p={2} bg={'gray.600'} rounded={'md'} display="none">
            <audio ref={audioRef} />
            <Flex w={'100%'}>
                <Progress value={`${(currentTime / duration) * 100}%`} />
                {/* <div ref={progressBarRef} className="progress-bar" style={{width: `${(currentTime / duration) * 100}%`}} /> */}
            </Flex>
            <Flex w={'100%'}>
                <Button disabled={!audioFile} onClick={() => togglePlay()}>
                    <Icon w={5} h={5} as={isPlaying ? BsPauseCircleFill : AiFillPlayCircle} />
                </Button>

                {/* <button className="audio-button download" disabled={!audioFile} onClick={() => downloadAudio()}>
                    <BiSolidDownload className="icon-btn" />
                </button> */}
            </Flex>
        </Flex>
    )
}

export default AudioPlayer;
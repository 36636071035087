import { Box, Card, CardBody, CardFooter, Flex, Heading, Image, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const InvestorTeam = () => {
    return (
        <Tabs variant={'unstyled'}>
            <Box mb={10} bg="teal.900">
                <Heading as="h2" size="4xl" p={5} color="white">
                    Team
                </Heading>
                <Flex mt="10" bg="blackAlpha.400">
                    <TabList>
                        <Tab _selected={{ bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)' }} _hover={{
                            '& svg': {
                                transform: 'translateX(4px)',
                            },
                            '& h3': {
                                transform: 'translateX(-4px)',
                            },
                            bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)',

                        }}
                            sx={{
                                borderRight: '0.5px solid gray',
                            }}
                        >
                            <Flex px={5} py={3} rowGap={10} color="white" alignItems="center">
                                <Heading as="h3" size='sm' style={{ transition: 'transform 0.3s ease' }}>SPECIALIST</Heading >
                                <ChevronRightIcon style={{ transition: 'transform 0.3s ease', fontSize: "23px" }} />
                            </Flex>
                        </Tab>
                        <Tab _selected={{ bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)' }} _hover={{
                            '& svg': {
                                transform: 'translateX(4px)',
                            },
                            '& h3': {
                                transform: 'translateX(-4px)',
                            },
                            bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)',

                        }}
                            sx={{
                                borderRight: '0.5px solid gray',
                            }}
                        >
                            <Flex px={5} py={3} rowGap={10} color="white" alignItems="center">
                                <Heading as="h3" size='sm' style={{ transition: 'transform 0.3s ease' }}>SPECIAL INVESTORS</Heading >
                                <ChevronRightIcon style={{ transition: 'transform 0.3s ease', fontSize: "23px" }} />
                            </Flex>
                        </Tab>
                        <Tab _selected={{ bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)' }} _hover={{
                            '& svg': {
                                transform: 'translateX(4px)',
                            },
                            '& h3': {
                                transform: 'translateX(-4px)',
                            },
                            bgGradient: 'linear(to-r, blackAlpha.200, whiteAlpha.400)',

                        }}
                            sx={{
                                borderRight: '0.5px solid gray',
                            }}
                        >
                            <Flex px={5} py={3} rowGap={10} color="white" alignItems="center">
                                <Heading as="h3" size='sm' style={{ transition: 'transform 0.3s ease' }}>INVESTORS</Heading >
                                <ChevronRightIcon style={{ transition: 'transform 0.3s ease', fontSize: "23px" }} />
                            </Flex>
                        </Tab>
                    </TabList>
                </Flex>
            </Box>
        <TabPanels>
            <TabPanel>
            <Box display="flex" flexDir="column" justifyContent="center" alignItems="center">
                <SimpleGrid columns={{ sm: 1, md: 3, lg: 4 }} spacing='40px'>
                    {[1, 2, 3, 4].map(item => (
                        <Card
                            key={item}
                            direction='column'
                            overflow='hidden'
                            maxW='72'
                            borderRadius="0 50px 0 0"
                            _hover={{
                                '& svg': {
                                    transform: 'translateX(8px)',
                                },
                            }}
                        >
                            <Image
                                objectFit='cover'
                                maxW={{ base: '100%', sm: '100%' }}
                                src='https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
                                alt='Caffe Latte'
                            />

                            <Stack>
                                <CardBody>
                                    <Heading size='md'>Investor Name</Heading>
                                </CardBody>
                                <hr />
                                <CardFooter>
                                    <Text>Learn More <ChevronRightIcon style={{ transition: 'transform 0.3s ease' }} /></Text>
                                </CardFooter>
                            </Stack>
                        </Card>
                    ))}
                </SimpleGrid>
            </Box>
            </TabPanel>
        </TabPanels>
        </Tabs>
    )
}

export default InvestorTeam

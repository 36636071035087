// Chakra Import
import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Icon,
  Spacer,
  Drawer,
  Spinner,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Switch,
  useColorModeValue,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField.js";
import { AiOutlineSync, AiOutlineHolder } from "react-icons/ai";

// Component Imports
import { transcriptionLanguages } from "../../../utils/TranscriptionLanguage.js";
import { toastFunctionToaster } from "../../../utils/toastFunction.js";

// Api Call Imports
import {
  getAllLinkCustomers,
  getCustomerAllLinks,
  addUpdateLinkCustomer,
  manageLinkCustomer,
} from "../../../services/businessServices.js";

function LSPManagement() {
  const toast = useToast();
  const { state } = useLocation();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [saveCustomerLoader, isSaveCustomerLoader] = useState(false);

  let lspRecordData = [
    {
      lspBusinessName: "lspBusinessName1",
      lspBusinessAdd: "lspBusinessadd1",
      lspUrl: "lspUrl1",
      lspPhone: 9890098298,
      lspEmail: "demo1@test.com",
    },
    {
      lspBusinessName: "lspBusinessName2",
      lspBusinessAdd: "lspBusinessadd2",
      lspUrl: "lspUrl2",
      lspPhone: 9890098298,
      lspEmail: "demo2@test.com",
    },
  ];

  const [customerUuid, setCustomerUuid] = useState(null);
  const [lspData, setLspData] = useState(lspRecordData);

  const [lspBusinessName, setlspBusinessName] = useState(null);
  const [lspBusinessAdd, setlspBusinessAdd] = useState(null);
  const [lspUrl, setlspUrl] = useState(null);
  const [lspPhone, setlspPhone] = useState(null);
  const [lspEmail, setlspEmail] = useState(null);
  const [proofEmailService, setProofEmailService] = useState(null);
  const [suppSrcLang, setSuppSrcLang] = useState([]);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  //   Todo:Fetch all projects
  useEffect(() => {
    // fetchLspData();
  }, []);

  const fetchLspData = () => {
    isLoading(true);
    let paramObj = {
      records_per_page: 0,
      page_id: 0,
    };
    getAllLinkCustomers(paramObj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.count) {
          isLoading(false);
          setLspData(res?.data[0]?.records);
        } else {
          isLoading(false);
          setLspData([]);
        }
      })
      .catch((err) => {
        isLoading(false);
        setLspData([]);
      });
  };

  const manageLspStatus = (customerUid, status) => {
    if (customerUid != null) {
      let objBody = {
        customerUuid: customerUid,
        status: status,
      };
      manageLinkCustomer(objBody)
        .then((res) => {
          if (res?.result) {
            onClose();
            resetCustomerDetails();
            fetchLspData();
            toast(toastFunctionToaster(res.message, "success"));
          } else {
            onClose();
            resetCustomerDetails();
            toast(toastFunctionToaster(res.message, "error"));
          }
        })
        .catch((err) => {
          onClose();
          resetCustomerDetails();
          toast(toastFunctionToaster(err.message, "error"));
        });
    } else {
    }
  };

  //   Todo:Add/Update New Customer
  const createUpdateNewCustomer = () => {
    let objData = [];
  };

  // Todo:Reset details on drawer closed
  const closeDrawer = () => {
    onClose();
    resetCustomerDetails();
  };
  // Todo:Reset Customer Details
  const resetCustomerDetails = () => {
    setCustomerUuid(null);
    setlspBusinessName(null);
  };

  const renderLspDetails =
    lspData !== null && lspData?.length > 0 ? (
      lspData?.map((item, index) => (
        <Flex
          w={"100%"}
          key={index}
          rounded={"md"}
          align={"center"}
          p={2}
          mt={1}
          borderWidth={"0.05px"}
          borderColor={item?.enabled ? "green.300" : "red.200"}
        >
          <Box px={2} minW={"20%"}>
            {item?.lspBusinessName}
          </Box>
          <Box px={2} minW={"20%"}>
            {item?.lspBusinessAdd}
          </Box>
          <Box px={2} minW={"20%"}>
            {item?.lspEmail}
          </Box>
          <Box px={2} minW={"20%"}>
            {item?.lspPhone}
          </Box>

          <Box
            px={2}
            borderLeftWidth={"1px"}
            borderLeftColor={"gray.400"}
            minW={"20%"}
            align={"end"}
          >
            <Switch
              onChange={() => {
                manageLspStatus(item?.uuid, !item?.enabled);
              }}
              isChecked={item?.enabled ? true : false}
              colorScheme="green"
            />
            <Button ml={1} bg={"gray.200"} rounded={"md"} size={"xs"}>
              <Icon
                as={AiOutlineHolder}
                onClick={() => {
                  editCustomerDetails(item);
                }}
              />
            </Button>
          </Box>
        </Flex>
      ))
    ) : (
      <Flex
        w={"100%"}
        p={5}
        bg="red.100"
        borderLeftWidth={"5px"}
        borderLeftColor={"red.400"}
      >
        <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
          No data available.
        </Heading>
      </Flex>
    );

  //  Todo:Edit/Update Customer Details
  const editCustomerDetails = (item) => {
    console.log("item", item);
    setlspBusinessName(item?.lspBusinessName);
    setlspBusinessAdd(item?.lspBusinessAdd);
    setlspEmail(item?.lspEmail);
    setlspPhone(item?.lspPhone);
    setlspUrl(item?.lspUrl);

    onOpen();
  };

  return (
    <>
      <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Flex w={"100%"} direction={"column"} align={"center"}>
          <Flex w={"100%"} direction={"column"}>
            <Flex
              w={"100%"}
              py="3"
              alignItems="center"
              borderBottomWidth="1px"
              justifyContent={"space-between"}
            >
              <Text fontWeight={"500"} fontSize={"18px"}>
                LSP (Language Service Proider)
              </Text>
              <Spacer />
              <Button
                bg="yellow.200"
                rounded={"md"}
                size="sm"
                onClick={() => {
                  fetchLspData();
                }}
              >
                <Icon as={AiOutlineSync} />
              </Button>
              <Button
                ml={1}
                bg="blue.100"
                rounded={"md"}
                _hover={{ bg: "blue.300" }}
                size="sm"
                onClick={() => {
                  onOpen();
                }}
              >
                Add LSP
              </Button>
            </Flex>
            {loading ? (
              <Flex w={"100%"} p={10} direction={"column"} align={"center"}>
                <Spinner size={"xl"} />
              </Flex>
            ) : (
              <Flex w={"100%"} direction={"column"}>
                <Flex
                  direction={"column"}
                  w={"100%"}
                  h="calc(100vh - 200px)"
                  overflow="auto"
                  borderTopWidth="0px"
                  className="custom-scrollbar"
                >
                  <Flex
                    w={"100%"}
                    bg={"gray.200"}
                    rounded={"sm"}
                    align={"center"}
                    p={2}
                    mt={1}
                    borderWidth={"0.05px"}
                    fontWeight={500}
                    borderColor={"gray.300"}
                  >
                    <Box px={2} minW={"20%"}>
                      <Text>Business Name</Text>
                    </Box>
                    <Box px={2} minW={"20%"}>
                      <Text>Business Address</Text>
                    </Box>
                    <Box px={2} minW={"20%"}>
                      <Text>Phone Number</Text>
                    </Box>
                    <Box px={2} minW={"20%"}>
                      <Text>Email</Text>
                    </Box>

                    <Box px={2} minW={"20%"} textAlign={"right"}>
                      Action
                    </Box>
                  </Flex>
                  {renderLspDetails}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
      {/* // Add New Subscriber */}
      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          closeDrawer();
        }}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth={"1px"}>
            {customerUuid ? "Update" : "Add New"} LSP
          </DrawerHeader>
          <DrawerBody overflow={"hidden"} overflowY="scroll" mt="5">
            <Flex w={"100%"} direction={"column"}>
              <InputField
                mb="0"
                me="30px"
                id="lspBusinessName"
                label="LSP Business Name"
                value={lspBusinessName}
                name="lspBusinessName"
                required="true"
                borderRadius="5px"
                onChange={(e) => {
                  setlspBusinessName(e.target.value);
                }}
                placeholder="Enter LSP Business Name"
                w="100%"
                maxlength={100}
              />
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                {lspBusinessName?.length == 0 ? (
                  <Text fontSize={"xs"} color={"red.200"}>
                    LSP Business Name is required
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Flex>
            </Flex>
            <Flex w={"100%"} direction={"column"} mt="5">
              <InputField
                mb="0"
                me="30px"
                id="lspBusinessAdd"
                label="LSP Business Address"
                value={lspBusinessAdd}
                name="lspBusinessAdd"
                required="true"
                borderRadius="5px"
                onChange={(e) => {
                  setlspBusinessAdd(e.target.value);
                }}
                placeholder="Enter LSP Business Address"
                w="100%"
                maxlength={100}
              />
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                {lspBusinessName?.length === 0 ? (
                  <Text fontSize={"xs"} color={"red.200"}>
                    LSP Business Address is required
                  </Text>
                ) : (
                  <></>
                )}
              </Flex>
            </Flex>
            <Flex w={"100%"} direction={"column"} mt="5">
              <InputField
                mb="0"
                me="30px"
                id="lspUrl"
                label="Enter Url"
                value={lspUrl}
                name="lspUrl"
                borderRadius="5px"
                onChange={(e) => {
                  setlspUrl(e.target.value);
                }}
                placeholder="Enter Url"
                w="100%"
                maxlength={100}
              />
            </Flex>
            <Flex w={"100%"} mt="5" direction={"column"}>
              <InputField
                mb="0"
                me="30px"
                id="lspPhone"
                label="Phone Number"
                value={lspPhone}
                name="lspPhone"
                borderRadius="5px"
                onChange={(e) => {
                  setlspPhone(e.target.value);
                }}
                placeholder="Enter Phone Number"
                w="100%"
                maxlength={100}
              />
            </Flex>
            <Flex w={"100%"} mt="5" direction={"column"}>
              <InputField
                mb="0"
                me="30px"
                id="lspEmail"
                label="Email"
                value={lspEmail}
                name="lspEmail"
                borderRadius="5px"
                onChange={(e) => {
                  setlspEmail(e.target.value);
                }}
                placeholder="Enter Email"
                w="100%"
                maxlength={100}
              />
            </Flex>
            <Flex w={"100%"} mt="5" direction={"column"}>
              <InputField
                mb="0"
                me="30px"
                id="proofEmailService"
                label="Proof Email Service"
                value={proofEmailService}
                name="proofEmailService"
                borderRadius="5px"
                onChange={(e) => {
                  setProofEmailService(e.target.value);
                }}
                placeholder="Enter Proof Email Service"
                w="100%"
                maxlength={100}
              />
            </Flex>
            <Box mt="5">
              <Text
                fontSize="sm"
                fontWeight="600"
                ms="2px"
                mb="3"
                color={textColorPrimary}
              >
                Supported Source Languages
              </Text>
              <Multiselect
                w={"100%"}
                options={transcriptionLanguages}
                displayValue="name"
                placeholder="Source Languages"
              />
            </Box>
            <Box mt="5">
              <Text
                fontSize="sm"
                fontWeight="600"
                ms="2px"
                mb="3"
                color={textColorPrimary}
              >
                Supported Target Language
              </Text>
              <Multiselect
                w={"100%"}
                options={transcriptionLanguages}
                displayValue="name"
                placeholder="Target Languages"
              />
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Flex w={"100%"} direction={"row"}>
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  onClose();
                  resetCustomerDetails();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  createUpdateNewCustomer();
                }}
                isDisabled={saveCustomerLoader ? true : false}
                isLoading={saveCustomerLoader ? true : false}
              >
                {customerUuid ? "Update" : "Create New"} Lsp
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default LSPManagement;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text, Center,
  Button,
  SimpleGrid, 
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import Card from '../../components/card/Card';
import Footer from '../../komponents/uilayout/Footer';

function CookiePolicy() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  

    const [loading, isLoading] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Center>
                <Text fontWeight={'bold'} fontSize={'24px'}>Lingolet Connect Cookie Policy</Text>
            </Center>
            <Card w={'96%'} px={20} mt={10}>
                This Cookie Policy explains how Lingolet AI ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at <a href="https://lingolet.ai">https://lingolet.ai</a> and associated domains (collectively, the “Websites”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.

                <Text fontWeight={'bold'} fontSize={'20px'} mt={5}>Introduction</Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                What are cookies?
                </Text>
                <Text>
                Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies serve different purposes, like helping us understand how a site is being used, letting you navigate between pages efficiently, remembering your preferences, and generally improving your browsing experience.
                <br/>
                Cookies can be either session cookies or persistent cookies. Session cookies only exist during a single session and disappear when you close your browser. Persistent cookies remain even after you close your browser and allow us to remember you when you return to a site.
                <br/>
                We use both session and persistent cookies on the Websites for the purposes set out below.
                </Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                Types of cookies we use
                </Text>
                <Text>
                The main types of cookies we use on our Websites are:
                </Text>
                <Box pl={'10px'}>
                    <ul>
                        <li>Essential cookies – These are cookies that are required for the operation of our Websites. They include cookies that allow you to log into secure areas of our Websites. </li>
                        <li>Analytical/performance cookies – These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Websites when they are using them. This helps us to improve the way our Websites work, for example, by ensuring that users are finding what they are looking for easily.</li>
                        <li>Functionality cookies – These are used to recognize you when you return to our Websites. They enable us to personalize our content for you and remember your preferences.</li>
                        <li>Targeting cookies – These cookies record your visit to our Websites, the pages you have visited, and the links you have followed. We will use this information to make our Websites more relevant to your interests.</li>
                        <li>Third party cookies – Third party services may use cookies to help you share our content across various social media platforms. These cookies aren't set by us.</li>
                    </ul>
                </Box>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                Your cookie preferences
                </Text>
                <Text>
                You can block cookies by activating the setting on your browser that allows you to refuse all or some cookies. However, if you do so, you may not be able to access all or parts of our Websites. Unless you have adjusted your browser settings to block cookies, our system will issue cookies when you use our Websites.
                </Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                Changes to our Cookie Policy
                </Text>

                <Text>
                We keep our Cookie Policy under review and will place any updates on this webpage. This policy was last updated on [date].
                </Text>

                <Text  fontWeight={'bold'} fontSize={'20px'} mt={5}>
                Contact us
                </Text>

                <Text>
                If you have any questions about our use of cookies, please email us at support@lingolet.ai
                </Text>
            </Card>
            <Box w="100%">
                <Footer />
            </Box>
        </Flex>
    )
}

export default CookiePolicy;

import React, { useEffect, useState } from "react";

// Chakra Import
import {
  Flex,
  Box,
  Text,
  Avatar,
  Spinner,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

// Component Import
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// API Call
import { fetchModuleList } from "../../../services/adminServices";

function ModuleBasedList(props) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const user_uuId = props?.userId;
  const [loading, isLoading] = useState(false);
  const [moduleList, setModuleListData] = useState([]);
  const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");

  useEffect(() => {
    if (props?.userId?.length) {
      fetchModuleDetails();
    }
  }, [props?.userId]);

  const fetchModuleDetails = () => {
    isLoading(true);
    let obj = {
      user_uuid: user_uuId,
    };
    fetchModuleList(obj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.records?.length) {
          setModuleListData(res?.data[0]?.records);
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          setModuleListData([]);
          isLoading(false);
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster(err?.message, "error"));
      });
  };

  const renderModuleList =
    moduleList !== null && moduleList?.length
      ? moduleList?.map((data, index) => (
          <Box
            key={index}
            borderRadius={"10px"}
            p="2"
            mb="3"
            cursor={"pointer"}
            boxShadow={"md"}
            bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
            borderColor={data?.is_disabled === true ? "green.200" : "red.200"}
            borderWidth={"1px"}
          >
            <Flex w={"100%"}>
              <Flex alignItems={"center"}>
                <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                  <Avatar
                    h={{ base: "40px", "2xl": "50px" }}
                    w={{ base: "40px", "2xl": "50px" }}
                    src={avatar}
                    me="16px"
                  />
                </Box>
              </Flex>
              <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
              >
                <Text fontWeight={"bold"} wordBreak={"break-all"}>
                  {data?.email}
                </Text>
                <Flex>
                  <Text as="span" mr="1">
                    {data?.first_name}
                  </Text>
                  <Text as="span">{data?.last_name}</Text>
                </Flex>
                <Text fontSize={"11px"}>{data?.user_type}</Text>
              </Flex>
            </Flex>
          </Box>
        ))
      : null;

  return (
    <>
      {moduleList !== null && moduleList?.length ? (
        <>
          <Box
            w={"100%"}
            p="2"
            mb={5}
            borderBottomWidth={"1px"}
            borderBottomStyle="dotted"
          >
            <Text fontWeight={"500"}>Module List</Text>
          </Box>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" h="60vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Box p="2">{renderModuleList}</Box>
          )}
        </>
      ) : (
        <Text p="2" fontWeight={"500"}>
          No Record Found
        </Text>
      )}
    </>
  );
}

export default ModuleBasedList;

import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { accessControlDocContent } from '../../../services/accountServices'
import { useEffect } from 'react'
import { Box, 
    Button, 
    InputGroup,
    Input,
    InputLeftAddon, InputLeftElement,
    Icon,
    Badge,
    Spinner,
    Text,
    Table, Tr, Td, Th, Tbody, Thead,
    Alert, AlertIcon, AlertTitle, AlertDescription,
    Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, 
    DrawerHeader, DrawerOverlay, Flex, 
    Spacer, useDisclosure } from '@chakra-ui/react'
import { ViewIcon, SmallAddIcon, AttachmentIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle, BiStopCircle } from "react-icons/bi";
import moment from "moment";

import { useSortBy, useTable, usePagination } from 'react-table';
import { TbCaretDown, TbCaretUp, TbFilter, TbFilterFilled } from 'react-icons/tb';
import { HiOutlineSearch } from "react-icons/hi";
import { FaFilePdf, FaFileAlt } from 'react-icons/fa6';


import { AiFillBuild } from "react-icons/ai";
import Card from "../../../components/card/Card";
import ControlSpaceAccessContents from '../components/ControlSpaceAccessContents'
import DisplayContent from '../components/DisplayContent'
import Pagination from '../../../components/pagination/Pagination';


const ContentList = () => {
    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [fileDetails, setFileDetails] = useState(null)

    const path = window.location.pathname
    const subroute = path.split("/")[1]

    const controlUuid = location?.state?.access_control_uuid;
    const projectData = location?.state?.projectData
    function handleGetContents() {
        setLoading(true)
        const paramObj = {
            "page_id": pageId,
            "records_per_page": recordsPerPage,
            "doc_control_uuid": controlUuid
        }

        accessControlDocContent(paramObj).then(resp => {
            if (resp?.data[0]?.data?.data) {
                setData(resp?.data[0]?.data?.data)
                setTotalPages(resp?.data[0]?.data?.total_pages)
            } else {
                setData([])
                setTotalPages(0)
            }
        }).catch(e => {
            setData([])
            setTotalPages(0)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        handleGetContents()
    }, [controlUuid, pageId])

    useEffect(() => {
        setPageId(1)
    }, [recordsPerPage])

    function handleModalClose() {
        onClose()
        setFileDetails(null)
    }

    function handleModalOpen(file) {
        setFileDetails(file)
        onOpen()
    }
    return (
        <Box pt={{ sm: "125px", lg: "75px" }}>
            <Flex flexDir="column">
                <Flex
                    p="3"
                    gap={1}
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent={"space-between"}
                >
                    <Spacer />
                    <Button
                        colorScheme="blue" size="sm"
                        onClick={() => {
                            navigate(`/${subroute}/projects`)
                        }}>
                        Back to Projects list
                    </Button>
                    <Button
                        colorScheme="blue" size="sm"
                        onClick={() => navigate(`/${subroute}/project/share-control`, {
                            state: { projectData }
                        })}>
                        Back to shared Access
                    </Button>
                    <Button
                        colorScheme="blue" size="sm"
                        onClick={() => {
                            handleGetContents();
                        }}>
                        Refresh
                    </Button>
                </Flex>
                <Flex h="calc(100vh - 200px)">
                    <ControlSpaceAccessContents data={data} pageId={pageId} recordsPerPage={recordsPerPage} setPageId={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} loading={loading} handleModalOpen={handleModalOpen} />
                    
                    <Drawer isOpen={isOpen} placement="right" onClose={handleModalClose} size="lg">
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerHeader>View Content</DrawerHeader>
                            <DrawerCloseButton color={"red.100"} />
                            <DrawerBody overflow={"hidden"}>
                                    <DisplayContent projectUuid={projectData?.project_uuid} pageContent={fileDetails} />
                            </DrawerBody>
                            <DrawerFooter>
                                <Button variant="outline" mr={3} onClick={handleModalClose}>
                                    Cancel
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Flex>
            </Flex>

        </Box>
    )
}

export default ContentList


{/* <Card direction="column" p="0" mt="5">
<Box w={"100%"} h="calc(100vh - 200px)">
    <Card px="0" h="full">
        {data && data?.length > 0 &&
            <Flex alignItems="center" p="2">
                <InputGroup w="30%">
                    <InputLeftElement pointerEvents='none'>
                        <Icon as={HiOutlineSearch} color="gray.400" />
                    </InputLeftElement>
                    <Input type='search' placeholder='Search..' borderRadius="lg" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                </InputGroup>
            </Flex>
        }

        {loading ? (
            <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
                <Spinner size="xl" />
            </Flex>
        ) : filterData && filterData.length > 0 ? (
            <Box overflow="hidden" p={4} position="relative" h="full">
                <Box
                    overflowY="auto"
                    maxH="calc(100% - 50px)"
                    className="custom-scrollbar"
                >
                    <Table variant="simple">
                        <Thead position="sticky" top="0" bg="white" zIndex="1">
                            <Tr borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                    TITLE
                                </Th>
                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                    Date Added
                                </Th>
                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                    OVERWRITTEN
                                </Th>
                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                    ACTION
                                </Th>
                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                    TRANSLATED CONTENT
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filterData.map((item, index) => (
                                <Tr
                                    key={item?.content_uuid}
                                    borderBottomWidth="1px"
                                    borderBottomColor="#d7d7d7"
                                >
                                    <Td display="flex" alignItems="center">
                                        <Icon
                                            as={
                                                item.type === "application/pdf"
                                                    ? FaFilePdf
                                                    : FaFileAlt
                                            }
                                            color={
                                                item.type === "application/pdf"
                                                    ? "blue.500"
                                                    : "green.500"
                                            }
                                            mr={2}
                                        />
                                        {item?.content_title}
                                    </Td>
                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                        {<Text>{moment.utc(item?.datetime_created).local().format("MMM DD,YYYY,HH:mm a")}</Text>}
                                    </Td>
                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                        <Badge
                                            colorScheme={!item.overwritten ? "red" : "green"}
                                        >
                                            {item?.overwritten ? "YES" : "NO"}
                                        </Badge>
                                    </Td>
                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                        <Button size="sm" colorScheme="green">
                                            View Original
                                        </Button>
                                    </Td>
                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                        <Button size="sm" colorScheme="green">
                                            View Translation
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                <Flex position="absolute" bottom="0" right="0" w="full">
                    <Pagination
                        currentPage={pageId}
                        setCurrentPage={setPageId}
                        setRecordsPerPage={setRecordsPerPage}
                        totalPages={totalPages}
                        recordsPerPage={recordsPerPage}
                    />
                </Flex>
            </Box>
        ) : (
            <Box w={"100%"} p={5}>
                <Alert borderRadius="8px" status="error" variant="subtle">
                    <Flex>
                        <AlertIcon />
                        <Flex direction="column">
                            <AlertTitle mr="12px">Empty Resources List</AlertTitle>
                            <AlertDescription>
                                No Data Found!!
                            </AlertDescription>
                        </Flex>
                    </Flex>
                </Alert>
            </Box>
        )}
    </Card>
</Box>
</Card> */}
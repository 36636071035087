import React, { useState, useEffect } from "react";
import { ChakraProvider, Box, Heading, Button, useToast } from "@chakra-ui/react";
import ReportingForm from "./components/ReportingForm";
import ReportingTable from "./components/ReportingTable";
import { 
  addReportSetupAccAdmin, 
  updateReportSetupAccAdmin, 
  manageReportSetupAccAdmin, 
  fetchReportExecutionListAccAdmin 
} from "../../../services/accountServices"

function ReportingSetup() {
  const [reports, setReports] = useState([]);
  const [editData, setEditData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    loadReports();
  }, []);

  const loadReports = async () => {
    try {
      let _data = {};
      const data = await fetchReportExecutionListAccAdmin(_data);

      setReports(data?.data[0]?.data);
    } catch (error) {
      toast({
        title: "Error fetching reports.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreateOrUpdate = async (formData) => {
    try {
      if (editData) {
        await updateReportSetupAccAdmin(editData.reporting_uuid, formData);
        toast({ title: "Report updated successfully.", status: "success" });
      } else {
        await addReportSetupAccAdmin(formData);
        toast({ title: "Report created successfully.", status: "success" });
      }
      setEditData(null);
      loadReports();
    } catch (error) {
      toast({
        title: "Error creating/updating report.",
        description: "Operation failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
      <Box maxW="1200px" mx="auto" pr={6} pl={6} pb={6}>
        <ReportingForm onSubmit={handleCreateOrUpdate} editData={editData} />
        <ReportingTable reports={reports} onEdit={setEditData} />
      </Box>
  );
}

export default ReportingSetup;

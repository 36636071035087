// Chakra imports
import {
    Icon,
    Portal,
    Box,
    useDisclosure,
    useColorModeValue,
  } from "@chakra-ui/react";
  import { useLocation } from "react-router-dom";
  import Footer from "../components/footer/FooterAdmin";

  import Navbar from "../components/navbar/NavbarAdmin";
  import Sidebar from "../components/sidebar/Sidebar";
  import { SidebarContext } from "../contexts/SidebarContext";
  import { useState, useEffect } from "react";
  import { Route, Routes, Navigate } from "react-router-dom";
  import { BiCaretRightCircle, BiCaretLeftCircle } from "react-icons/bi";
  

  import routes from "./ApplicantRoutes";


  export default function ApplicantLayout(props) {
    const { ...rest } = props;
    const location = useLocation();
  
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [mini, setMini] = useState(false);
    const [hovered, setHovered] = useState(false);
    // functions for changing the states from components
    const getRoute = () => {
      return window.location.pathname !== "ailab/applicant/full-screen-maps";
    };
    const getActiveRoute = (routes) => {
      let activeRoute = "Applicants";
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].items);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            return routes[i].name;
          }
        }
      }
      return activeRoute;
    };
    const getActiveNavbar = (routes) => {
      let activeNavbar = false;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveNavbar = getActiveNavbar(routes[i].items);
          if (collapseActiveNavbar !== activeNavbar) {
            return collapseActiveNavbar;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            return routes[i].secondary;
          }
        }
      }
      return activeNavbar;
    };
    const getRoutes = (routes) => {
      return routes.map((route, key) => {
        if (route.layout === "/ailab/applicant") {
          return (
            <Route path={`${route.path}`} element={route.component} key={key} />
          );
        }
        if (route.collapse) {
          return getRoutes(route.items);
        } else {
          return null;
        }
      });
    };
    console.log(mini);
    document.documentElement.dir = "ltr";
    useEffect(() => {
      getActiveRoute(routes);
    }, [location]);
    const { onOpen } = useDisclosure();
    const bg = useColorModeValue("background.100", "background.900");
    return (
      <Box bg={bg} h="100vh" w="100vw" position={"relative"}>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            hovered={hovered}
            setHovered={setHovered}
            mini={mini}
            setMini={setMini}
            routes={routes}
            display="none"
            {...rest}
          />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={
              mini === false
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : mini === true && hovered === true
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : { base: "100%", xl: "calc( 100% - 120px )" }
            }
            maxWidth={
              mini === false
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : mini === true && hovered === true
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : { base: "100%", xl: "calc( 100% - 120px )" }
            }
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  hovered={hovered}
                  setMini={setMini}
                  mini={mini}
                  onOpen={onOpen}
                  logoText={"Horizon UI Dashboard PRO"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  theme={props.theme}
                  setTheme={props.setTheme}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
  
            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "30px" }}
                pe="20px"
                minH="100vh"
                pt="50px"
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/ailab/applicant/home" replace />}
                  />
                </Routes>
              </Box>
            ) : null}
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    );
  }
  
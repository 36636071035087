export const translationLanguages = [
    { "code": "af", "name": "Afrikaans" },
    { "code": "sq", "name": "Albanian" },
    { "code": "am", "name": "Amharic" },
    { "code": "ar", "name": "Arabic" },
    { "code": "hy", "name": "Armenian" },
    { "code": "az", "name": "Azerbaijani" },
    { "code": "bn", "name": "Bengali" },
    { "code": "bs", "name": "Bosnian" },
    { "code": "bg", "name": "Bulgarian" },
    { "code": "ca", "name": "Catalan" },
    { "code": "zh", "name": "Chinese (Simplified)" },
    { "code": "zh-TW", "name": "Chinese (Traditional)" },
    { "code": "hr", "name": "Croatian" },
    { "code": "cs", "name": "Czech" },
    { "code": "da", "name": "Danish" },
    { "code": "fa-AF", "name": "Dari" },
    { "code": "nl", "name": "Dutch" },
    { "code": "en", "name": "English" },
    { "code": "et", "name": "Estonian" },
    { "code": "fa", "name": "Farsi (Persian)" },
    { "code": "tl", "name": "Filipino, Tagalog" },
    { "code": "fi", "name": "Finnish" },
    { "code": "fr", "name": "French" },
    { "code": "fr-CA", "name": "French (Canada)" },
    { "code": "ka", "name": "Georgian" },
    { "code": "de", "name": "German" },
    { "code": "el", "name": "Greek" },
    { "code": "gu", "name": "Gujarati" },
    { "code": "ht", "name": "Haitian Creole" },
    { "code": "ha", "name": "Hausa" },
    { "code": "he", "name": "Hebrew" },
    { "code": "hi", "name": "Hindi" },
    { "code": "hu", "name": "Hungarian" },
    { "code": "is", "name": "Icelandic" },
    { "code": "id", "name": "Indonesian" },
    { "code": "ga", "name": "Irish" },
    { "code": "it", "name": "Italian" },
    { "code": "ja", "name": "Japanese" },
    { "code": "kn", "name": "Kannada" },
    { "code": "kk", "name": "Kazakh" },
    { "code": "ko", "name": "Korean" },
    { "code": "lv", "name": "Latvian" },
    { "code": "lt", "name": "Lithuanian" },
    { "code": "mk", "name": "Macedonian" },
    { "code": "ms", "name": "Malay" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mt", "name": "Maltese" },
    { "code": "mr", "name": "Marathi" },
    { "code": "mn", "name": "Mongolian" },
    { "code": "no", "name": "Norwegian (Bokmål)" },
    { "code": "ps", "name": "Pashto" },
    { "code": "pl", "name": "Polish" },
    { "code": "pt", "name": "Portuguese (Brazil)" },
    { "code": "pt-PT", "name": "Portuguese (Portugal)" },
    { "code": "pa", "name": "Punjabi" },
    { "code": "ro", "name": "Romanian" },
    { "code": "ru", "name": "Russian" },
    { "code": "sr", "name": "Serbian" },
    { "code": "si", "name": "Sinhala" },
    { "code": "sk", "name": "Slovak" },
    { "code": "sl", "name": "Slovenian" },
    { "code": "so", "name": "Somali" },
    { "code": "es", "name": "Spanish" },
    { "code": "es-MX", "name": "Spanish (Mexico)" },
    { "code": "sw", "name": "Swahili" },
    { "code": "sv", "name": "Swedish" },
    { "code": "ta", "name": "Tamil" },
    { "code": "te", "name": "Telugu" },
    { "code": "th", "name": "Thai" },
    { "code": "tr", "name": "Turkish" },
    { "code": "uk", "name": "Ukrainian" },
    { "code": "ur", "name": "Urdu" },
    { "code": "uz", "name": "Uzbek" },
    { "code": "vi", "name": "Vietnamese" },
    { "code": "cy", "name": "Welsh" }
]

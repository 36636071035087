import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack, Heading, Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import HubspotForm from "react-hubspot-form";

const WaitListingComp = () => {
  const [showHeader, setShowHeader] = useState(true);

  return (
    <Box p={4}>
      <VStack spacing={4} align="left" target="_blank">
        {showHeader
        ?<Flex w={'100%'} direction={'column'}>
            <Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
              Request for{" "}
              <Text as="span" fontWeight={"600"} mr="1">
                Lingolet Demo
              </Text>
            </Heading>
            <Text mt="3" fontSize={"17px"} color="gray.700">
              With the power of AI and human interpreters Lingolet Technology is here to serve you.<br />
              Please reach out to us and we will show you the future of AI translation and interpretation.
            </Text>
        </Flex>:null}
        <Box w={"100%"}>
          <HubspotForm
            portalId="44706308"
            formId="8062bbce-9f9f-4646-83cc-dfec117e54fb"
            onSubmit={() => setShowHeader(false)}
            onFormSubmitted={() => setShowHeader(false)}
            // onSubmit={() => console.log('Submit!')}
            // onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
            formFieldGroups={[
                {
                  "fields": [
                    {name: "email", required: true, enabled: true, placeholder: 'Email'},
                    {name: "first_name", required: true, enabled: true},
                    {name: "lastname", required: true, enabled: true},
                    {name: "companyname", required: true, enabled: true}
                  ]
                }
              ]}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default WaitListingComp;

import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch, AiOutlineRobot, AiOutlineUserSwitch } from "react-icons/ai";
import {
  Stack,
  Icon,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormLabel,
  Heading,
  Flex,
  Text,
  Button,
  Drawer,
  Spinner,
  InputGroup,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  InputLeftElement,
  useDisclosure,
  Input,
  useColorModeValue
} from "@chakra-ui/react";

import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { defaultThemeColor } from "../../constants";

import Card from "../../components/card/Card";
// import GettingStartedStatus from "./GettingStartedStatus";

const LADashboard = (props) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, isLoading] = useState(false);

    const brandBg = useColorModeValue("blue.50", "navy.900");
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'}  mt={2}>
                <Flex h="80vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "70%"}
                        bg={brandBg}
                        p="5"
                        borderRadius={"10px"}
                        overflowY="scroll"
                    >
                        <Text> Language Associate Home</Text>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="30%"
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%">
                                <Flex w={'100%'} direction={'column'} p={5}>
                                    <Text fontSize={'lg'}>
                                        Help Topics
                                    </Text>
                                    <Text ml={5}>
                                    <ul>
                                        <li>Update Personal Profile</li>
                                        <li>Update Business Profile</li>
                                        <li>Team Roles and Configuration</li>
                                        <li>Add & Manage Team Members</li>
                                        <li>Add Your Business Customers</li>
                                        <li>Add LingoCard and Assign to Customers</li>
                                        <li>Add LingoLink and Assign to Customers</li>
                                        <li>Using LingoLink</li>
                                        <li>Using DocumentAI</li>
                                    </ul>
                                    </Text>
                                </Flex>
                                {/* <Tabs w="100%" h="100%" borderBottom={"0px"} mx="auto">
                                    <TabList border="0px" w="100%" h="8%">
                                        <Tab
                                            _selected={{
                                                color: defaultThemeColor,
                                                fontWeight: 600,
                                                borderBottomColor: defaultThemeColor,
                                            }}
                                            fontSize="16px"
                                            px="6"
                                            py="2"
                                        >
                                            Admin
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                color: defaultThemeColor,
                                                fontWeight: 600,
                                                borderBottomColor: defaultThemeColor,
                                            }}
                                            fontSize="16px"
                                            px="6"
                                            py="2"
                                        >
                                            Client
                                        </Tab>
                                    </TabList>
                                    <TabPanels mt="4%" h="91%">
                                        <TabPanel p="0" h="100%">
                                            <Text>Basic</Text>
                                        </TabPanel>
                                        <TabPanel p="0" h="100%">
                                            <Text>Advance</Text>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs> */}
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Expand Help Topics
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default LADashboard;

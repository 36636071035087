import React, { useState, useEffect } from "react";

// Chakra Import
import {
  Box,
  Flex,
  Text,
  Button,
  Alert,
  Drawer,
  Spinner,
  AlertIcon,
  FormLabel,
  SimpleGrid,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import InputField from "../../components/fields/InputField";

// Api Call Imports
import {
  addNewProject,
  getAllProject,
  updateProjectDetails,
} from "../../services/businessServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

function ProjectAutomation() {
  const toast = useToast();
  const btnRef = React.useRef();
  const [loading, isLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [projectList, setProjectList] = useState(null);

  return (
    <>
      <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Flex
          p="3"
          alignItems="center"
          borderBottomWidth="1px"
          justifyContent={"space-between"}
        >
          <Text fontWeight={"500"} fontSize={"18px"}>
            Project Automation
          </Text>
        </Flex>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : projectList?.length ? (
            <Flex w={'100%'}>

            </Flex>
        ) : (
          <Alert status="warning" mt="4">
            <AlertIcon />
            No Project Found
          </Alert>
        )}
      </Box>
    </>
  );
}

export default ProjectAutomation;



import React, { useState, useEffect } from "react";

// Chakra Import
import {
	Box,
	Flex,
	Image,
	Text,
	Button,
	Checkbox,
	Icon,
	Avatar,
	Switch,
	Spacer,
	Heading,
	Spinner,
	useToast,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import {
	AiFillEdit,
	AiFillLeftCircle,
	AiFillRightCircle,
} from "react-icons/ai";

// Api Call Imports
import {
	fetchAccountAdminList,
	manageAccountAdminStatus,
	resetAccountAdminPwd,
	manageAccountLingoCard,
} from "../../../../services/businessServices";

// Component Imports
import InviteAccountAdmin from "./InviteAccountAdmin";
import { useBrandBg, useNoResultFoundText } from "../../../../theme/globalColorTheme";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import UpdateAccountAdminApp from "./UpdateAccountAdminApp";

function AccountAdmin({ accountUuid, accountDetails }) {
	const toast = useToast();
	const brandBg = useBrandBg();
	const [loading, isLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [selectedAppData, setSelectedAppData] = useState(null);
	const [accountAdminList, setAccountAdminList] = useState([]);
	const [selectedAdminData, setSelectedAdminData] = useState({})
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

	const noAdminFoundText = useNoResultFoundText()

	useEffect(() => {
		fetchAccountAdminData();
	}, []);

	useEffect(() => {
		if (accountDetails?.length) {
			const featuredApp = accountDetails[0]?.link_apps?.length ? accountDetails[0]?.link_apps?.split(",") : []
			setSelectedAppData(featuredApp);
		}
	}, [accountDetails]);

	// Todo:Fetch Account Admin List Details
	const fetchAccountAdminData = () => {
		isLoading(true);
		let paramObj = {
			link_account_uuid: accountUuid,
			records_per_page: 10,
			page_id: currentPage
		};
		fetchAccountAdminList(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.total_records) {
					isLoading(false);
					setAccountAdminList(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.totalPages)
				} else {
					isLoading(false);
					setAccountAdminList([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountAdminList([]);
			});
	};

	// Todo: Update Account Admin Status
	const updateAccountAdminStatus = (user_uuid) => {
		if (user_uuid != null && accountUuid !== null) {
			let objBody = {
				link_account_uuid: accountUuid,
				user_uuid: user_uuid
			};
			manageAccountAdminStatus(objBody)
				.then(res => {
					if (res?.result) {
						fetchAccountAdminData();
						toast(toastFunctionToaster(res.message, "success"));
					} else {

						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
		}
	};

	// Todo: Update Account Admin Status
	const resetAccountAdminPassword = (user_uuid) => {
		if (user_uuid != null && accountUuid !== null) {
			let objBody = {
				link_account_uuid: accountUuid,
				user_uuid: user_uuid
			};
			resetAccountAdminPwd(objBody)
				.then(res => {
					if (res?.result) {
						fetchAccountAdminData();
						toast(toastFunctionToaster(res.message, "success"));
					} else {

						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
		}
	};

		
	const updateAdminFeatureApp = (item) => {
		return (
			<UpdateAccountAdminApp accountDetails={item} appDetails={selectedAppData} accountUuid={accountUuid} />
		)
	}

	function manageLingoCardAccess(admin_uuid){
		const paramObj = {
			acc_admin_uuid: admin_uuid,
    		acc_uuid: accountUuid
		}
		manageAccountLingoCard(paramObj).then(resp=>{
			if(resp?.data[0]?.status){
				fetchAccountAdminData()
				toast(toastFunctionToaster("LingoCard access updated successfully", "success"));
			}else{
				toast(toastFunctionToaster("LingoCard access updated successfully", "success"));
			}
		}).catch(err=>{
			toast(toastFunctionToaster("LingoCard access updated successfully", "success"));

		})

	}

	const renderAccountAdminListDetails =
		accountAdminList !== null && accountAdminList?.length > 0 ? (
			accountAdminList?.map((item, index) => (
				<Flex
					w={"100%"}
					key={index}
					rounded={"md"}
					align={"center"}
					p={1}
					mt={1}
					direction={'column'}
					borderWidth={"0.05px"}
					borderLeftWidth={'10px'}
					borderLeftColor={item?.is_disable ? "red.300" : "green.300"}
					borderColor={item?.is_disable ? "red.300" : "green.300"}>
						<Flex
							rounded={"md"}
							px={1}
							w={"100%"}
							align={"center"}>
							<Box minW={"60px"}>
								<Avatar src={item?.link_account_avatar?.length ? item?.link_account_avatar : null} />
							</Box>
							<Flex w={'100%'} direction={'column'} >
								<Flex minW={"100%"} p={1} direction={"column"} flexWrap={'wrap'} >
									<Text fontWeight={'bold'}>
										{item?.first_name} <Text as="span" fontWeight={'bold'}>{item?.last_name}</Text>
									</Text>
									<Text>
										{item?.email}
									</Text>
								</Flex>
								<Flex maxW={'100%'} p={1} alignItems={'center'}>
									<Text px={2} color={'gray.700'} rounded={'md'} bg={item?.logged ? 'green.400' : 'red.200'}>{item?.logged ? 'Logged' : 'Not Logged'}</Text>
									{item?.is_disable
										? <Text ml={1} w={'100px'} color={'gray.700'} bg={'red.100'} px={2} rounded={'md'}>
											Disabled
										</Text>
										: <Text ml={1} w={'100px'} color={'gray.700'} bg={'green.200'} px={2} rounded={'md'}>
											Enabled
										</Text>}
									<Button 
										onClick={() => resetAccountAdminPassword(item?.uuid)}
										ml={1} color={'gray.700'} rounded={'md'} size={'xs'} bg={'blue.200'}>
										Reset Password
									</Button>
								</Flex>
							</Flex>
							<Spacer />
							<Flex
								rounded={'md'}
								p={2}
								alignItems={"center"}
								borderWidth={"1px"}
								borderColor={"gray.400"}
								minW={"160px"} maxW={"160px"}
								align={"end"}>
								<Switch
									mr={2}
									onChange={() => {
										updateAccountAdminStatus(item?.uuid);
									}}
									isChecked={item?.is_disable ? true : false}
									colorScheme="green"
								/>
								{item?.is_disable
									? <Text>Enable Login</Text>
									: <Text>Disable Login</Text>}
								{/* <Icon
									as={AiOutlineHolder}
									ml={1}
									p={1}
									bg={"gray.200"}
									rounded={"md"}
									fontSize={"24px"}
									cursor={"pointer"}
									onClick={() => {
										// onOpen();
										// setSelectedAccountData(item)
									}}
								/> */}
							</Flex>
							<Flex
								rounded={'md'}
								p={2}
								alignItems={"center"}
								borderWidth={"1px"}
								borderColor={"gray.400"}
								minW={"160px"} maxW={"160px"}
								ml={2}
								fontSize="sm"
								align={"end"}>
								<Switch
									mr={2}
									onChange={() => {
										manageLingoCardAccess(item?.uuid);
									}}
									isChecked={item?.lingo_card_access}
									colorScheme="green"
								/>
								{!item?.lingo_card_access
									? <Text>Enable Lingocard</Text>
									: <Text>Disable Lingocard</Text>}
							</Flex>
							{!item?.is_disable ? (
								<Flex
									rounded={'md'}
									p={2}
									alignItems={"center"}
									borderWidth={"1px"}
									borderColor={"gray.400"}
									align={"end"}
									ml="4"
									cursor={'pointer'}
									onClick={() => {
										setSelectedAdminData(item)
										setConfiguationCollapseFlag(true);
									}}
								>
									<Icon
										as={AiFillEdit}
										ml={1}
										p={1}
										rounded={"md"}
										fontSize={"24px"}
										cursor={"pointer"}
										mr="1"

									/>
									Edit
								</Flex>) : null}
						</Flex>
						<Flex w={'100%'} mt={4} p={2} rounded={'lg'}  borderWidth={"0.5px"} borderColor={"gray.300"}>
							<Flex flexWrap='wrap' alignItems={'center'}>
								{updateAdminFeatureApp(item)}
							</Flex>
						</Flex>
				</Flex>
			))
		) : (
			<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
				<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={useNoResultFoundText}>
					You do not have any account admin added.
				</Heading>
			</Flex>
		);

	const handleAccountAdmin = (data) => {
		if (data) {
			fetchAccountAdminData()
		}
	}

	return (
		<>
			<Flex w={'100%'}>
				<Card w={'100%'} h={'calc(100vh - 200px)'} p={1} alignItems="center" rounded={'md'}>
					<Flex w={'100%'} h="100%">
						<Box
							w={!configurationCollapseFlag ? "96%" : "85%"}
							bg={brandBg}
							p={1}
							borderRadius={"10px"}
							overflow="hidden" h="100%">
							<Flex w={"100%"} h="100%" direction={"column"}>
								{loading ? (
									<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
										<Spinner size={"xl"} />
									</Flex>
								) : (
									<Flex w={"100%"} h="100%" direction={"column"}>
										<Flex
											direction={"column"}
											w={"100%"}
											h="100%"
											overflow="auto"
											borderTopWidth="0px"
											className="custom-scrollbar">
											{renderAccountAdminListDetails}
											{currentPage?.length > 1 ? (
												<Box mt="4" position={'absolute'} bottom="15px" width={"100%"}>
													<ResponsivePagination
														current={currentPage}
														total={totalPages}
														onPageChange={pageNumber => {
															setCurrentPage(pageNumber);
														}}
													/>
												</Box>) : null}
										</Flex>

									</Flex>
								)}
							</Flex>
						</Box>
						{configurationCollapseFlag ? (
							<Flex w="35%">
								<Box
									w="100%"
									position="relative"
									p="3"
									borderRadius="15px"
									bg={brandBg}
									ml="2">
									<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
										<AiFillRightCircle
											cursor="pointer"
											size="20px"
											onClick={() => {
												setConfiguationCollapseFlag(!configurationCollapseFlag);
											}}
										/>
									</Box>
									<Box w="100%" h="95%">
										<InviteAccountAdmin
											accountUuid={accountUuid}
											accountAdminData={selectedAdminData}
											reListAccountAdmin={handleAccountAdmin} />
									</Box>
								</Box>
							</Flex>
						) : (
							<Box w="4%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
								<Box w={"100%"} h="100%">
									<AiFillLeftCircle
										cursor="pointer"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
										size="20px"
									/>
									<Text
										fontSize="16px"
										mt="2"
										fontWeight='bold'
										css={{
											writingMode: "vertical-lr",
											textOrientation: "mixed",
										}}>
										Invite Account Administrator
									</Text>
								</Box>
							</Box>
						)}
					</Flex>
				</Card>
			</Flex>
		</>
	)
}

export default AccountAdmin;

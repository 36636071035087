import React, {useState, useEffect} from 'react'
import {Button,Text,Flex,Box, useToast, useColorModeValue} from "@chakra-ui/react";

// Component Import
import Card from "../../../components/card/Card";

import InputField from "../../../components/fields/InputField";
import { updateQuotaDetails } from '../../../services/accountServices';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import { useTextColor } from '../../../theme/globalColorTheme';

function CreateQuotaLocation(props) {
  const toast = useToast();
  const [hiValue, setHiValue] = useState(0);
  const [biValue, setBiValue] = useState(0);
  const [piValue, setPiValue] = useState(0);
  const [loading,isLoading] = useState(false)
  const accountId = props?.propsData?.locationDetails?.link_account_uuid;
  const locationId = props?.propsData?.locationDetails?.link_location_uuid;
  const accountQuota = props?.propsData?.quotaDetails?.account ? props?.propsData?.quotaDetails?.account : [];
  const locationQuota = props?.propsData?.quotaDetails?.location ? props?.propsData?.quotaDetails?.location : [];
  const blackWhiteColor = useTextColor()
  const pillButtonBg = useColorModeValue("blue.100", "gray.600");
  const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");


  const displaceQuotaValue = (quotaType) => {
    if (accountQuota?.length > 0){
        for(let i=0;i<accountQuota?.length;i++){
            if (accountQuota[i]?.quota_id == quotaType){
                return accountQuota[i]?.quota_value;
            }
        }
    }
    return 0;
}

const displaceLocationQuotaValue = (quotaType) => {
    if (locationQuota?.length > 0){
        for(let i=0;i<locationQuota?.length;i++){
            if (locationQuota[i]?.quota_id == quotaType){
                return locationQuota[i]?.quota_value;
            }
        }
    }
    return 0;
}

const AccountQuotaCreation = (quota_id) => {
  if (accountId != null && locationId != null) {
      isLoading(true);
      let paramObj = {
          account_uuid: accountId,
          location_uuid: locationId,
          quota_id: quota_id,
          quota_value: 3000,
          quota_unit: 'minutes',
          quota_duration: 'month'
      };
      if (quota_id == 'hi'){
          paramObj.quota_value = hiValue;
      } else if (quota_id == 'bi'){
          paramObj.quota_value = biValue;
      } else if (quota_id == 'pi'){
          paramObj.quota_value = piValue;
      }
      updateQuotaDetails(paramObj)
          .then(res => {
              isLoading(false);
              if (res?.data[0]?.status) {
                toast(toastFunctionToaster(res.message, "success"));
              }
              else {
                  toast(toastFunctionToaster(res.message, "error"));
              }
          })
          .catch(err => {
            if(err) {
              isLoading(false);
              toast(toastFunctionToaster('Something Went Wrong', "error"));
            }
          });
  }
};

  return (
    <Flex w={'100%'} flexWrap={'wrap'} mb="2">
      <Card w={'100%'} rounded={'lg'} m={1}>
          <Text fontWeight={'600'}>Human Interpretation</Text>
          {/* <Text fontWeight={'500'}>{displaceQuotaValue('hi') + ' Minute Monthly'}</Text> */}
          <Flex justifyContent={'space-between'}>
            <Flex w="49%" flexWrap={'wrap'}> 
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Account Quota</Text>
                    <Text fontWeight={'500'}>{displaceQuotaValue('hi') + ' Minutes Monthly'}</Text>
                </Flex>
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Assign Location Quota</Text>
                    <Text fontWeight={'500'}>{displaceLocationQuotaValue('hi') + ' Minutes Monthly'}</Text>
                </Flex> 
            </Flex>   
            <Flex w="49%" flexWrap={'wrap'} borderWidth={'1px'} borderColor={'gray.200'} p={1}>   
                <Flex w="100%">
                    <InputField
                        mb="0"
                        w={'150px'}
                        me="3px"
                        id="quota_value"
                        label="Quota Value"
                        name="quota_value"
                        value={hiValue}
                        onChange={e => {
                            setHiValue(e.target.value);
                        }}
                        placeholder="Enter Quota Value"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Button mt={8} px={2}
                        rounded={'lg'}
                        _hover={{ bg: pillButtonBgHighlight, color: "black" }} 
                        color={blackWhiteColor}
                        bg={pillButtonBg}
                        size='sm'
                        onClick={() => AccountQuotaCreation('hi')}
                        isDisabled={hiValue ? false : true}
                        isLoading={loading ? true : false}>
                        Update Quota
                    </Button>
                </Flex>
                <Box>
                    {hiValue ? null : (
                        <Text w='100%' fontSize="sm" fontWeight={'bold'} color={"red"}  m="1">
                            Value is required
                        </Text>
                    )}
                </Box>
            </Flex>
          </Flex>
      </Card>
      <Card w={'100%'} rounded={'lg'} m={1}>
          <Text fontWeight={'600'}>Secure AI</Text>
          <Flex justifyContent={'space-between'}>
            <Flex w="49%" flexWrap={'wrap'}> 
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Account Quota</Text>
                    <Text fontWeight={'500'}>{displaceQuotaValue('bi') + ' Minutes Monthly'}</Text>
                </Flex>
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Assign Location Quota</Text>
                    <Text fontWeight={'500'}>{displaceLocationQuotaValue('bi') + ' Minutes Monthly'}</Text>
                </Flex> 
            </Flex>
            <Flex w="49%" flexWrap={'wrap'} borderWidth={'1px'} borderColor={'gray.200'} p={1}> 
                <Flex w="100%">
                    <InputField
                        mb="0"
                        w={'150px'}
                        me="3px"
                        id="quota_value"
                        label="Quota Value"
                        name="quota_value"
                        value={biValue}
                        onChange={e => {
                            setBiValue(e.target.value);
                        }}
                        placeholder="Enter Quota Value"
                        required="true"
                        borderRadius={'10px'}
                        type='number'
                    />
                    
                    <Button mt={8} px={2}
                        rounded={'lg'}
                        _hover={{ bg: pillButtonBgHighlight, color: "black" }} 
                        color={blackWhiteColor}
                        bg={pillButtonBg}
                        size='sm'
                        onClick={() => AccountQuotaCreation('bi')}
                        isDisabled={biValue ? false : true}
                        isLoading={loading ? true : false}>
                        Update Quota
                    </Button>
                    
                </Flex>
                <Box>
                    {biValue ? null : (
                        <Text w='100%' fontSize="sm" fontWeight={'bold'} color={"red"}  m="1">
                            Value is required
                    </Text>
                    )}
                </Box>
            </Flex>
        </Flex>
      </Card>
      {/* <Card w={'100%'} rounded={'lg'} m={1}>
          <Text fontWeight={'600'}>Premium AI</Text>
          <Flex justifyContent={'space-between'}>
            <Flex w="49%" flexWrap={'wrap'}>
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Account Quota</Text>
                    <Text fontWeight={'500'}>{displaceQuotaValue('pi') + ' Minute Monthly'}</Text>
                </Flex>
                <Flex w={'100%'} borderWidth={'1px'} borderColor={'gray.200'} p={1} direction={'column'}>
                    <Text fontSize={'xs'}>Assign Location Quota</Text>
                    <Text fontWeight={'500'}>{displaceLocationQuotaValue('pi') + ' Minute Monthly'}</Text>
                </Flex>   
            </Flex>  
            <Flex w="49%" flexWrap={'wrap'} borderWidth={'1px'} borderColor={'gray.200'} p={1}>  
                <Flex w="100%" >
                    <InputField
                        w={'150px'}
                        mb="0"
                        me="3px"
                        id="quota_value"
                        label="Quota Value"
                        name="quota_value"
                        value={piValue}
                        onChange={e => {
                            setPiValue(e.target.value);
                        }}
                        placeholder="Enter Quota Value"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Button mt={8} px={2}
                        rounded={'lg'}
                        bg={'blue.100'}
                        size='sm'
                        onClick={() => AccountQuotaCreation('pi')}
                        isDisabled={piValue ? false : true}
                        isLoading={loading ? true : false}>
                        Update Quota
                    </Button>
                </Flex>
                <Box>
                    {piValue ? null : (
                        <Text w='100%' fontSize="sm" fontWeight={'bold'} color={"red"}  m="1">
                            Value is required
                    </Text>
                    )}
                </Box>
          </Flex>
          </Flex>
      </Card> */}
    </Flex>
  )
}

export default CreateQuotaLocation
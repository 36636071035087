import { AiOutlineSearch } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import {
	Box,
	Spinner,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Stack,
	Input,
	Text,
	useDisclosure,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";
import UploadDoc from "../UploadDoc";
import { translationLanguages } from "../../../utils/TranslationLanguages";

const TranslateSystem = props => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading, isLoading] = useState(false);

	const [sourceLanguage, setSourceLanguage] = useState("en");
	const [sourceLanguageName, setSourceLanguageName] = useState("English");
	const [targetLanguage, setTargetLanguage] = useState("es");
	const [targetLanguageName, setTargetLanguageName] = useState("Spanish");
	const [selectedValue, setSelectedValue] = useState("");
	const [searchLang, setSearchLang] = useState("");
	const [langArr, setLangArr] = useState(translationLanguages);
	const [filteredLangArr, setFilteredLangArr] = useState(translationLanguages);

	useEffect(() => {
		if (selectedValue?.length) {
			renderLangugageData();
		}
	}, [selectedValue]);

	// Todo:Handle Close
	const handlerCloser = () => {
		onClose();
		setLangArr(filteredLangArr);
		setSearchLang("");
	};

	const renderSourceLanguageData = () => {
		return (
			<Box p="2" 
				m={10}
				borderWidth={"1px"} borderRadius={"10px"} w="95%" bg="#fff">
				<Flex
					w="100%"
					mb="4"
					alignItems={"center"}
					borderWidth={"1px"}
					borderRadius={"10px"}
					justifyContent={"space-between"}
					p="2">
					<FormLabel mb="0px" w="25%">
						Source Language{" "}
					</FormLabel>
					<Text w="50%" borderWidth="1px" bg="#e2e8f0" p="2" borderRadius={"10px"} mr="3">
						{sourceLanguageName}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Source");
						}}>
						...
					</Button>
				</Flex>
				<Flex
					w="100%"
					mb="4"
					p="2"
					alignItems={"center"}
					borderWidth={"1px"}
					borderRadius={"10px"}
					justifyContent={"space-between"}>
					<FormLabel w="25%" mb="0px">
						Target Language{" "}
					</FormLabel>
					<Text w="50%" borderWidth="1px" bg="#e2e8f0" p="2" borderRadius={"10px"} mr="3">
						{targetLanguageName}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Target");
						}}>
						...
					</Button>
				</Flex>
				<Box w="100%" mb="4" p="2">
					<FormLabel w="100%" mb="2">
						Email Id
					</FormLabel>
					<Input type="text" w="100%" placeholder="Enter Email Id" />
				</Box>
				<Box w="100%" mb="4" p="2">
					<FormLabel w="100%" mb="2">
						Upload Document
					</FormLabel>
					<UploadDoc title={"Upload Pdf/Doc"} />
				</Box>
			</Box>
		);
	};

	const renderLangugageData = () => {
		return langArr?.map((data, index) => (
			<Text
				key={data?.code}
				value={data?.code}
				w="31%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				m="1%"
				onClick={() => {
					setSourceLanguage(data.code);
					setSourceLanguageName(data?.name);
				}}
				bg={data?.code === sourceLanguage ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.name}
			</Text>
		));
	};

	const renderToLanguage = () => {
		return langArr?.map((data, index) => (
			<Text
				value={data?.code}
				w="31%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				m="1%"
				onClick={() => {
					setTargetLanguage(data.code);
					setTargetLanguageName(data?.name);
				}}
				bg={data?.code === targetLanguage ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.name}
			</Text>
		));
	};

	// Todo:Search Language

	useEffect(() => {
		filterLanguageData(searchLang);
	}, [searchLang]);

	const filterLanguageData = () => {
		const clonedLangArr = [...filteredLangArr];
		const filteredData = clonedLangArr.filter(lang => {
			return lang.name.includes(searchLang);
		});
		setLangArr(filteredData);
	};

	return (
		<>
		{loading ? (
			<Flex
			alignItems="center"
			justifyContent="center"
			h="70vh"
			w="100%"
			color="#fff"
			>
				<Spinner size="xl" color="#000" />
			</Flex>
		) : (
			<Box w={"100%"} h="100%" overflow={"auto"}>
				<Box direction="column" pt={{ sm: "125px", lg: "80px" }}>
					{renderSourceLanguageData()}
				</Box>
				{/* Drawer Ui */}
				<Drawer
					isOpen={isOpen}
					placement="right"
					onClose={() => {
						handlerCloser();
					}}
					size="md">
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>
							Select {selectedValue} {selectedValue === "Service" ? "Type" : "Language"}{" "}
						</DrawerHeader>
						{/* Seach Bar */}
						{selectedValue === "Source" || selectedValue === "Target" ? (
							<Stack spacing={4} mb="4" w="95%" mx="auto">
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<AiOutlineSearch color="gray.300" />
									</InputLeftElement>
									<Input
										type="text"
										placeholder="Search Language"
										onChange={e => {
											setSearchLang(e.target.value);
										}}
									/>
								</InputGroup>
							</Stack>
						) : null}
						<DrawerBody>
							<Flex flexWrap={"wrap"}>
								{selectedValue === "Source" && langArr?.length
									? renderLangugageData()
									: selectedValue === "Target" && langArr?.length
									? renderToLanguage()
									: null}
							</Flex>
						</DrawerBody>

						<DrawerFooter>
							<Button
								variant="outline"
								size="sm"
								borderRadius="10px"
								mr={3}
								onClick={() => {
									handlerCloser();
								}}>
								Cancel
							</Button>
							<Button
								colorScheme="green"
								size="sm"
								borderRadius="10px"
								onClick={() => {
									handlerCloser();
								}}>
								Save
							</Button>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</Box>
 		)}
		</>
	);
};

export default TranslateSystem;

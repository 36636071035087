import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Box,
	Flex,
	Spacer,
	Spinner,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Icon,
	Text,
	Button,
	Textarea,
	FormLabel,
	FormControl,
	useToast,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField.js";
import { ViewIcon, CheckCircleIcon, SmallAddIcon, AttachmentIcon, ChevronLeftIcon } from "@chakra-ui/icons";
// API
import Card from "../../../components/card/Card.js";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { toastFunctionToaster } from "../../../utils/toastFunction.js";
import { addUpdateProjectContent, getTextFromUrl } from "../../../services/moduleServices.js";
import UploadAudio from "./UploadAudio.js";
import { useGrayTextColor, useTextColor } from "../../../theme/globalColorTheme.js";

function AddTextContent(props) {
	const toast = useToast();
	const { state } = useLocation();
	const navigate = useNavigate();

	const projectUuid = props.projectUuid ? props.projectUuid : null;
	const [contentType, setContentType] = useState("text");

	const [loading, isLoading] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [resourceUrl, setResourceUrl] = useState(null);
	const [resourceTitle, setResourceTitle] = useState(null);
	const [resourceText, setResourceText] = useState(null);

	const [resourceUrlFlag, setResourceUrlFlag] = useState(false);
	const [resourceTitleFlag, setResourceTitleFlag] = useState(false);
	const [resourceTextFlag, setResourceTextFlag] = useState(false);

	const buttonText = useGrayTextColor()
	const buttonTextHover = useTextColor()

	const saveDetails = () => {
		let valid = true;
		if (resourceTitle.trim() === "") {
			setResourceTitleFlag(true);
			valid = false;
		} else {
			setResourceTitleFlag(false);
		}
		if (resourceText.trim() === "") {
			setResourceTextFlag(true);
			valid = false;
		} else {
			setResourceTextFlag(false);
		}
		if (valid) {
			let objBody = {
				project_uuid: projectUuid,
				content_uuid: null,
				text_title: resourceTitle,
				text_content: resourceText,
			};
			setSpinner(true);
			setResourceTitleFlag(false);
			addUpdateProjectContent(objBody)
				.then(res => {
					setSpinner(false);
					if (res.result) {
						props?.handleChange(0);
						toast(toastFunctionToaster(res.message, "success"));
						setResourceTitle("");
						setResourceText("");
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
					setSpinner(false);
				});
		}
	};

	const processUrl = () => {
		if (projectUuid != null && resourceUrl?.length > 0) {
			let objData = {
				project_uuid: projectUuid,
				url: resourceUrl,
			};
			getTextFromUrl(objData)
				.then(res => {
					isLoading(false);
					if (res.result) {
						if (res.data.length > 0) {
							setResourceText(res.data[0]?.content);
						} else {
							setResourceTitle(null);
							setResourceText(null);
						}
					}
				})
				.catch(err => { });
		}
	};

	return (
		<Box w="100%">
			<Flex w={"100%"} h={"60px"} borderBottomWidth={"0.5px"} borderBottomColor={"gray.200"}>
				<Spacer />
				<Button bg={contentType === "text" ? "gray.400" : "gray.100"} onClick={() => setContentType("text")} color={'black'} _hover={{ color: buttonText }}>
					{contentType === "text" ? <Icon as={CheckCircleIcon} color={"blue.700"} mr={2} /> : null}
					Input Text Content
				</Button>
				<Button bg={contentType === "audio" ? "gray.400" : "gray.100"} onClick={() => setContentType("audio")} color={'black'} _hover={{ color: buttonText }}>
					{contentType === "audio" ? <Icon as={CheckCircleIcon} color={"blue.700"} mr={2} /> : null}
					Record Audio
				</Button>
				<Button
					bg={contentType === "html" ? "gray.400" : "gray.100"}
					color={'black'}
					_hover={{ color: buttonText }}

					onClick={() => {
						setContentType("html");
						setResourceTitle(null);
						setResourceText(null);
						setResourceTextFlag(false);
						setResourceTitleFlag(false);
					}}>
					{contentType === "html" ? <Icon as={CheckCircleIcon} color={"blue.700"} mr={2} /> : null}
					HTML URL
				</Button>
			</Flex>
			{contentType === "text" ? (
				<Flex alignItems={"start"} mt={5} justifyContent={"space-between"} direction={"column"}>
					<Flex w={"100%"}>
						<Box w={"80%"}>
							<FormControl isRequired w="100%">
								<Flex justifyContent={"space-between"}>
									<FormLabel>Content Title</FormLabel>
									<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
										{resourceTitle?.length}/300
									</Text>
								</Flex>
								<InputField
									borderRadius="10px"
									mb="0"
									value={resourceTitle}
									onChange={e => {
										setResourceTitle(e.target.value);
									}}
									placeholder="Enter Content Title"
									minLength={10}
									maxLength={300}
								/>
								{resourceTitleFlag ? (
									<Text color="red" fontWeight={"500"} mt="2">
										Content Title is required{" "}
									</Text>
								) : null}
							</FormControl>
						</Box>
						<Box w={"20%"} alignItems={"end"} align={"center"}>
							<Button
								disabled={resourceTitle != null ? false : true}
								colorScheme="green"
								onClick={() => {
									saveDetails();
								}}>
								Save Content
							</Button>
						</Box>
					</Flex>
					<FormControl isRequired mt="5">
						<Flex justifyContent={"space-between"}>
							<FormLabel>Content Text</FormLabel>
							<Text>{`${3000 - resourceText?.length} characters remaining`}</Text>
						</Flex>
						{resourceTextFlag ? (
							<Text color="red" fontWeight={"500"} mt="2">
								Content Text is required{" "}
							</Text>
						) : null}
						<Textarea
							value={resourceText}
							onChange={e => {
								setResourceText(e.target.value);
							}}
							placeholder="Enter Content Text"
							rows="20"
							maxLength={3000}></Textarea>
					</FormControl>
				</Flex>
			) : null}
			{contentType === "audio" ? (
				<Flex w={"100%"} direction={"column"}>
					<UploadAudio projectUuid={projectUuid} />
				</Flex>
			) : null}
			{contentType === "html" ? (
				<Flex w={"100%"} direction={"column"}>
					<Flex w={"100%"}>
						<FormControl isRequired w="100%">
							<Flex justifyContent={"space-between"}>
								<FormLabel>HTML URL</FormLabel>
								<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
									{resourceUrl?.length}/300
								</Text>
							</Flex>
							<Flex w={"100%"} align="center">
								<Box w={"100%"}>
									<InputField
										w={"100%"}
										borderRadius="10px"
										mb="0"
										value={resourceUrl}
										onChange={e => {
											setResourceUrl(e.target.value);
										}}
										placeholder="Enter full URL here..."
										minLength={10}
										maxLength={300}
									/>
								</Box>
								<Box w={"20%"} alignItems={"end"} align={"center"}>
									<Button
										mt={2}
										isDisabled={resourceUrl?.length > 0 ? false : true}
										bg={"green.100"}
										color={'black'}
										_hover={{ color: "white", bg: "green.400" }}
										onClick={() => {
											setResourceText(null);
											processUrl();
										}}>
										Process URL
									</Button>
								</Box>
							</Flex>
							{resourceUrlFlag ? (
								<Text color="red" fontWeight={"500"} mt="2">
									A fully qualified URL is required{" "}
								</Text>
							) : null}
						</FormControl>
					</Flex>
					<Flex w={"100%"}>
						<Tabs w={"100%"}>
							<TabList>
								<Tab>View Url Content</Tab>
								<Tab>Save Url Content</Tab>
							</TabList>
							<TabPanels>
								<TabPanel
									p={2}
									h="calc(100vh - 600px)"
									overflow="auto"
									borderTopWidth="0px"
									className="custom-scrollbar">
									{resourceText?.length > 0 ? (
										<Markdown remarkPlugins={[remarkGfm]}>{resourceText}</Markdown>
									) : (
										<Text p={2}>After your URL is processed, the content will be added here..</Text>
									)}
								</TabPanel>
								<TabPanel w={"100%"}>
									<FormControl w={"100%"} isRequired rounded={"md"}>
										<Flex justifyContent={"space-between"} align={"center"}>
											<Flex w={"100%"}>
												<Box w={"100%"}>
													<InputField
														w={"100%"}
														borderRadius="10px"
														mb="0"
														value={resourceTitle}
														onChange={e => {
															setResourceTitle(e.target.value);
														}}
														placeholder="Add resource title here.."
														minLength={10}
														maxLength={300}
													/>
												</Box>
											</Flex>
											<Spacer />
											{/* <Text>{`${3000 - resourceText?.length} characters remaining`}</Text> */}
											{resourceText?.length > 0 ? (
												<Text ml={1} w={"150px"}>{`${resourceText?.length} characters`}</Text>
											) : null}
											<Button
												onClick={() => saveDetails()}
												isDisabled={resourceText?.length > 0 ? false : true}
												ml={1}
												w={"140px"}
												bg={"green.200"}>
												Save Content
											</Button>
										</Flex>
										{resourceTextFlag ? (
											<Text color="red" fontWeight={"500"} mt="2">
												Content Text is required{" "}
											</Text>
										) : null}
										<Textarea
											mt={1}
											value={resourceText}
											onChange={e => {
												setResourceText(e.target.value);
											}}
											placeholder="After your URL is processed, the content will be added here.."
											rows="14"
											maxLength={3000}></Textarea>
									</FormControl>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>
				</Flex>
			) : null}
		</Box>
	);
}

export default AddTextContent;

export const LINK_SHARING_TAB = {
    CARD_FILTER: {
        ACTIVE: 'active',
        DISABLE: 'disable'
    },

    CARD_TYPE : {
        LINGO_CARD:'lingocard',
        LINGO_LINK:'lingolink'
    }
}

export const ACC_EVENT_TAB= {
    DURATION:{
        TODAY:"today",
        WEEK:"week",
        MONTH:"month"
    }
}

export const LOC_EVENT_TAB= {
    DURATION:{
        TODAY:"today",
        WEEK:"week",
        MONTH:"month"
    }
}

export const ACC_CALL_HISTORY={
    CALL_LIST_TYPE : {
        ONLY_CALLS:"callOnly",
        CALLS_WITH_SESSION:"callWithSession"
    },
    LINGO_LINKS_FILTER : {
        ACTIVE:"active",
        CLOSED:"closed"
    },
    LINGO_CARDS_FILTER : {
        ACTIVE:"active",
        CLOSED:"disable"
    },
    DURATION:{
        TODAY:"today",
        WEEK:"week",
        MONTH:"month"
    },
    FILTERS:{
        FEEDBACK:"feedback",
        TRANSCRIPT:'transcript',
        ALL:"all"
    }
}

export const LOC_CALL_HISTORY={
    CALL_LIST_TYPE : {
        ONLY_CALLS:"callOnly",
        CALLS_WITH_SESSION:"callWithSession"
    },
    LINGO_LINKS_FILTER : {
        ACTIVE:"active",
        CLOSED:"closed"
    },
    LINGO_CARDS_FILTER : {
        ACTIVE:"active",
        CLOSED:"disable"
    },
    DURATION:{
        TODAY:"today",
        WEEK:"week",
        MONTH:"month"
    },
    FILTERS:{
        FEEDBACK:"feedback",
        TRANSCRIPT:'transcript',
        ALL:"all"
    }
}
import axios from "../../services/axios"
import { serviceAPIs } from "./aiLabServicesApi"

export const addProfile = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().add_profile, data, config);
};
export const addCompany = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().add_company, data, config);
};
export const addApplication = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().add_application, data, config);
};
export const updateCompany = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().update_company, data, config);
};
export const getApplication = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().get_application, data, config);
};
export const getCompanyDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().get_company_details, data, config);
};

export const getAilabUserProfile = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().get_ailab_user_profile, data, config);
};

export const UpdateApplication = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().update_application, data, config);
};

export const applicationsList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().get_application_list, data, config);
};
import React, { useEffect, useState } from "react";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import { fetchBusinessList } from "../../../services/adminServices";
import {
  Flex,
  Box,
  Text,
  Avatar,
  Spinner,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

function BusinessList(props) {
  const { colorMode } = useColorMode();
  const [loading, isLoading] = useState(true);
  const [businessId, setBusinessId] = useState("");
  const [businessList, setBusinessList] = useState([]);
  const SchemeMsgBgColor = useColorModeValue("gray.200", "gray.800");
  const SchemeBorderColor = useColorModeValue("gray.100", "blue.900");

  useEffect(() => {
    fetchBusienessDetails();
  }, []);

  const fetchBusienessDetails = () => {
    isLoading(true);
    let obj = {
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchBusinessList(obj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.records?.length) {
          setBusinessList(res?.data[0]?.records);
        } else {
          setBusinessList([]);
          isLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        isLoading(false);
      });
  };

  const renderBusinessList =
    businessList !== null && businessList?.length
      ? businessList?.map((data, index) => (
          <Box
            key={index}
            borderRadius={"10px"}
            p="2"
            mb="3"
            cursor={"pointer"}
            boxShadow={"md"}
            bg={colorMode === "dark" ? SchemeMsgBgColor : "#f6f6f6"}
            borderColor={
              businessId === data?.business_uuid ? "grey" : SchemeBorderColor
            }
            borderWidth="3px"
            onClick={() => {
              props?.returnAssociateDetails(data);
              setBusinessId(data?.business_uuid);
            }}
          >
            <Flex w={"100%"}>
              <Box w="50px" minWidth={"50px"} px={1} pr={2}>
                <Avatar
                  h={{ base: "40px", "2xl": "50px" }}
                  w={{ base: "40px", "2xl": "50px" }}
                  src={avatar}
                  me="16px"
                />
              </Box>
              <Flex
                borderLeftWidth={"0.5px"}
                direction={"column"}
                w={"100%"}
                pl={3}
              >
                <Text fontWeight={"bold"} wordBreak={"break-all"}>
                  {data?.business_name}
                </Text>
                <Text>
                  {data?.city}
                  {data?.state} {data?.zip_code} {data?.country}
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))
      : null;

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" h="60vh">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <>
          <Box
            w={"100%"}
            p="2"
            mb={5}
            borderBottomWidth={"1px"}
            borderBottomStyle="dotted"
          >
            <Text fontWeight={"500"}>Business List</Text>
          </Box>
          {businessList !== null && businessList.length ? (
            <Box p="2">{renderBusinessList}</Box>
          ) : (
            <Text p="2" fontWeight={"500"}>
              No Record Found
            </Text>
          )}
        </>
      )}
    </>
  );
}

export default BusinessList;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex, Select, Image, Spacer, 
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
  Switch,
} from '@chakra-ui/react';
import { MdOutlineCircle, MdOutlinePeople, MdOutlinePerson, MdOutlinePersonOutline, MdOutlinePersonPinCircle, MdOutlinePersonalVideo, MdPlusOne, MdVerifiedUser } from 'react-icons/md';
import { GoArrowSwitch } from "react-icons/go";
import { RiRobotLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";

import { getEmailId, getGroupId } from '../../../utils/localStorageIndex';
import Card from '../../../components/card/Card';
import { languageList } from '../../../utils/languageList';
import lingo_logo from "../../../assets/landingPageImg/kb_logo.png";
import onemeta_logo from '../../../assets/landingPageImg/OM logo-blue-logo.png';

function SinglePartyDemo() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
    const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
  
    const [loading, isLoading] = useState(true);
    const [languageOne, setLanguageOne] = useState('English');
    const [languageTwo, setLanguageTwo] = useState('Spanish');
    const [translationStarted, setTranslationStarted] = useState(false);

    const [optionalLangs, setOptionalLangs] = useState(['Japanese', 'Chinese', 'Korean', 'Hindi', 'Spanish', 'English']);

    const jsonData = [
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'},
            {id: 'one', sourceText: 'What is time now?', translatedText:'¿Que hora es?'},
            {id: 'two', sourceText: 'How are you doing?', translatedText:'¿Cómo estás?'}
        ]
    const [audioTextData, setAudioTextData] = useState(jsonData);

    const renderLanguageListData = languageList?.map((countryData, index) => (
        <option
        key={countryData.code}
        value={countryData.name}
        style={{ color: 'black' }}
        >
        {countryData.name}
        </option>
    ));

    const onUpdateField = (id, e) => {
        if (id === 'one'){
            setLanguageOne(e.target.value);
        } else if (id === 'two'){
            setLanguageTwo(e.target.value);
        } 
    };
    
    const renderContent = (side) => {
        let keyValue = null;
        if (side == 'left'){
            keyValue = 'one';
        } else if (side == 'right'){
            keyValue = 'two';
        }
        if (audioTextData?.length > 0 && keyValue != null){
            let filteredData = audioTextData?.filter(data => {
                return data?.id === keyValue;
            });
            if (filteredData.length > 0){
                filteredData = filteredData.reverse();
                return(
                    filteredData.map( (item, index) => {
                        return(
                            <Flex key={index} w={'100%'} direction={'column'}>
                                <Text>{item.sourceText}</Text>
                                <Text color={side === 'one' ? 'blue.600' : 'green.600'} align={'end'}>{item.translatedText}</Text>
                            </Flex>
                        )
                    })
                )
            }
        }
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px" p={5}>
                Single Party Translation 
            </Card>
            <Card w={'100%'} mt={1} h="calc(100vh - 200px)">
                <Flex w={'100%'} h={'100%'}>
                    <Box w={'20%'} h={'100%'} p={2} align={'center'} verticalAlign={'center'}>
                        <Image src={lingo_logo} w={'200px'} />
                        <Icon as={FaPlus} />
                        <Image src={onemeta_logo} w={'200px'} />
                        <Spacer h="calc(100vh - 450px)" />
                        {translationStarted
                        ?<Card 
                            onClick={() => {
                                setTranslationStarted(false);
                            }} 
                            cursor={'pointer'}
                            _hover={{bg:'red.200'}}
                            bg={'red.100'}>
                            <Text fontSize={'xl'}>
                                Disconnect
                            </Text>
                        </Card>:null}
                    </Box>
                    <Box w={'80%'} borderLeftWidth={'0.5px'} borderLeftColor={'gray.300'}>
                        <Box w={'100%'} h={'100%'} hidden={translationStarted ? false : true}>
                            <Flex w={'100%'}
                                borderBottomWidth={'0.5px'} borderBottomColor={'gray.300'} 
                                h={'70px'} align={'center'} direction={'column'} p={2}>
                                <Text fontSize={'lg'} color={'gray.700'}>AI Translation</Text>
                                <Text fontSize={'xl'} color={'gray.700'}>
                                    {languageOne}
                                    {' '}
                                    <Icon as={GoArrowSwitch} />
                                    {' '}
                                    {languageTwo}
                                </Text>
                            </Flex>
                            <Flex w={'100%'} h="calc(100vh - 320px)" align={'center'} p={2}>
                                <Flex w={'50%'} h={'100%'} direction={'column'} align={'center'}>
                                    <Flex 
                                        bg={'blue.100'} rounded={'lg'}
                                        p={2}
                                        direction={'column'}
                                        w={'100%'}
                                        overflow="auto"
                                        borderTopWidth="0px"
                                        css={{
                                        '&::-webkit-scrollbar': {
                                            width: '4px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            width: '6px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            borderRadius: '24px',
                                        },
                                        }}     
                                        h={'100%'}>
                                            {renderContent('left')}
                                    </Flex>
                                    <Flex w={'100%'}
                                        borderTopWidth={'0.5px'} borderTopColor={'gray.300'}  
                                        h={'50px'} align={'center'}>
                                        <Box w={'100%'} align="center">
                                            <Text mr={2}>Enable Audio</Text>
                                            <Switch  colorScheme='blue' size={'lg'}>

                                            </Switch>
                                        </Box>
                                    </Flex>
                                </Flex>
                                <Flex w={'50%'} ml={'5px'}
                                    //borderLeftWidth={'0.5px'} borderLeftColor={'gray.300'}
                                    h={'100%'} direction={'column'}>
                                    <Flex 
                                        bg={'green.100'} rounded={'lg'}
                                        p={2}
                                        direction={'column'}
                                        overflow="auto"
                                        borderTopWidth="0px"
                                        css={{
                                        '&::-webkit-scrollbar': {
                                            width: '4px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            width: '6px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            borderRadius: '24px',
                                        },
                                        }}     
                                        w={'100%'} h={'100%'}>
                                            {renderContent('right')}
                                    </Flex>
                                    <Flex w={'100%'}
                                        borderTopWidth={'0.5px'} borderTopColor={'gray.300'}  
                                        h={'50px'} align={'center'}>
                                        <Box w={'100%'} align="center">
                                            <Text mr={2}>Enable Audio</Text>
                                            <Switch colorScheme='green' size={'lg'}>

                                            </Switch>
                                        </Box>
                                    </Flex>                                    
                                </Flex>
                            </Flex>
                        </Box>
                        <Box w={'100%'} h={'100%'} hidden={translationStarted ? true : false}>
                            <Flex w={'100%'}>
                                    <Flex w={'100%'} h={'100%'} align={'center'} direction={'column'} p={2}>
                                        <Text>Language One</Text>
                                        <Box w={'100%'} mt={4} h={'100px'} 
                                            rounded={'lg'} align={'center'}
                                            border={'1px'} borderColor={'gray.200'}>
                                                <Text mt={5} fontSize={'3xl'}>
                                                    {languageOne}
                                                </Text>
                                                <Text fontSize={'xs'} color={'gray.400'} >Selected Lanugage</Text>
                                        </Box>
                                        <Text mt={4} color={'gray.400'} fontSize={'md'}>Other Options</Text>
                                        <SimpleGrid w={'100%'} p={2} columns={2} columnGap={'20px'}>
                                            {optionalLangs.map( (item, index) => {
                                                return(
                                                    <Box 
                                                        cursor={'pointer'}
                                                        _hover={{bg:'blue.100'}}
                                                        onClick={() => setLanguageOne(item)}
                                                        key={index} w={'100%'} mt={2} h={'80px'} 
                                                        rounded={'lg'}
                                                        border={'1px'} borderColor={'gray.200'}>
                                                            <Text mt={5} 
                                                                align={'center'}
                                                                color={'gray.600'} fontSize={'3xl'}>{item}</Text>
                                                    </Box>
                                                )
                                            })}
                                        </SimpleGrid>
                                        <Text mt={2} color={'gray.400'} fontSize={'md'}>Select another language from the list below....</Text>
                                        <Flex w={'100%'} align={'center'} >
                                            <Select
                                                value={languageOne}
                                                name="language_one"
                                                placeholder="Select Language"
                                                size="sm"
                                                borderRadius="3px"
                                                borderColor="gray.200"
                                                fontSize={'14px'}
                                                mr="10px"
                                                onChange={e => {
                                                    onUpdateField('one', e);
                                                }}
                                            >
                                                {renderLanguageListData}
                                            </Select>
                                        </Flex>
                                    </Flex>
                                    <Flex w={'100%'} h={'100%'} direction={'column'} align={'center'} p={2}>
                                        <Text>Language Two</Text>
                                        <Box w={'100%'} mt={4} h={'100px'} 
                                            rounded={'lg'}  align={'center'}
                                            border={'1px'} borderColor={'gray.200'}>
                                                <Text mt={5} fontSize={'3xl'}>
                                                    {languageTwo}
                                                </Text>
                                                <Text fontSize={'xs'} color={'gray.400'} >Selected Lanugage</Text>
                                        </Box>
                                        <Text mt={4} color={'gray.400'} fontSize={'md'}>Other Options</Text>
                                        <SimpleGrid w={'100%'} p={2} columns={2} columnGap={'20px'}>
                                            {optionalLangs.map( (item, index) => {
                                                return(
                                                    <Box 
                                                        cursor={'pointer'}
                                                        _hover={{bg:'blue.100'}}
                                                        onClick={() => setLanguageTwo(item)}
                                                        key={index} w={'100%'} mt={2} h={'80px'} 
                                                        rounded={'lg'}
                                                        border={'1px'} borderColor={'gray.200'}>
                                                            <Text mt={5} 
                                                                align={'center'}
                                                                color={'gray.600'} fontSize={'3xl'}>{item}</Text>
                                                    </Box>
                                                )
                                            })}
                                        </SimpleGrid>
                                        <Text mt={2} color={'gray.400'} fontSize={'md'}>Select another language from the list below....</Text>
                                        <Flex w={'100%'}>
                                            <Box mb="15px">
                                            <Select
                                                value={languageTwo}
                                                name="language_two"
                                                placeholder="Select Language"
                                                size="sm"
                                                borderRadius="3px"
                                                borderColor="gray.200"
                                                fontSize={'14px'}
                                                mr="10px"
                                                onChange={e => {
                                                    onUpdateField('two', e);
                                                }}
                                            >
                                                {renderLanguageListData}
                                            </Select>
                                            </Box>
                                        </Flex>
                                    </Flex>
                            </Flex>
                            <Flex w={'100%'} maxHeight={'20%'} borderTopWidth={'0.5px'} borderTopColor={'gray.300'}>
                                <SimpleGrid w={'100%'} p={2} columns={2} columnGap={'20px'}>
                                    <Card bg={'gray.100'} w={'100%'} h={'150px'}>
                                        <Flex
                                            onClick={() => {
                                                setTranslationStarted(true);
                                            }} 
                                            w={'100%'} color={'gray.600'}  
                                                cursor={'pointer'}
                                                _hover={{bg:'blue.200'}}
                                                bg={'blue.100'} p={2} rounded={'lg'}>
                                            <Icon as={MdOutlinePeople} h={20} w={20} />
                                            <Flex w={'100%'} direction={'column'} pl={5}>
                                                <Text fontSize={'3xl'}>
                                                    Connect with Human Interpretation
                                                </Text>
                                                <Text fontSize={'sm'} align='end'>
                                                    {languageOne}
                                                    {' '}
                                                    <Icon as={GoArrowSwitch} />
                                                    {' '}
                                                    {languageTwo}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                    <Card bg={'gray.100'} w={'100%'} h={'150px'}>
                                        <Flex 
                                            onClick={() => {
                                                setTranslationStarted(true);
                                            }} 
                                            w={'100%'} 
                                            color={'gray.600'} 
                                                cursor={'pointer'}
                                                _hover={{bg:'blue.200'}}
                                                bg={'blue.100'} p={2} rounded={'lg'}>
                                            <Icon as={RiRobotLine} h={20} w={20} />
                                                <Flex w={'100%'} direction={'column'} pl={5}>
                                                    <Text fontSize={'3xl'}>
                                                        Connect with AI <br/> Translation
                                                    </Text>
                                                    <Text fontSize={'sm'} align='end'>
                                                        {languageTwo}
                                                        {' '}
                                                        <Icon as={GoArrowSwitch} />
                                                        {' '}
                                                        {languageOne}
                                                    </Text>
                                                </Flex>
                                        </Flex>
                                    </Card>
                                </SimpleGrid>
                            </Flex>
                        </Box>
                    </Box> 
                </Flex>
            </Card>
        </Flex>
    )
}

export default SinglePartyDemo;

// Chakra React Imports
import React, { useState, useEffect } from "react";
import {
	Box,
	Avatar,
	Heading,
	Text,
	Flex,
	Image,
	useColorModeValue,
} from "@chakra-ui/react";

// Component Imports
import Card from "../../components/card/Card";
import { useBlackWhiteBg } from "../../theme/globalColorTheme";
import HumanAIBg from "../../assets/communicator/HumanIcon.png";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import BasicAIBg from "../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../assets/communicator/DocumentAILight.png";

// Api Call Imports
import { fetchSubPersonalInfo } from "../../services/accountServices";

// Components
import LinkCustomerUpload from "./components/LinkCustomerUpload";
import SubscriberInfoDash from "./SubscriberInfoDash";

function SubscriberInfo() {
	const brandBg = useColorModeValue("blue.50", "navy.900");

	const [loading, isLoading] = useState(false);
	const [subscriberUuid, setSubscriberUuid] = useState(null);
	const [subscriberInfo, setSubscriberInfo] = useState([]);
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

	const blackWhiteBg = useBlackWhiteBg();

	useEffect(() => {
		collectAccountLocationInfo();
	}, []);

	const collectAccountLocationInfo = () => {
		isLoading(true);
		let paramObj = {
			key: "subscriber",
		};
		fetchSubPersonalInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setSubscriberUuid(res?.data[0]?.subUuid);
					setSubscriberInfo(res?.data[0]?.subInfo);
				} else {
					isLoading(false);
					setSubscriberInfo([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setSubscriberInfo([]);
			});
	};

	const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="50px" w="50px" mb="3" mr="1">
                <Image cursor={'pointer'} key={index} mr="2" borderRadius="10px" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		// } else if (name === "pi") {
		// 	return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};

	const renderSubscriberInfo =
		subscriberInfo !== null && subscriberInfo?.length > 0 ? (
			subscriberInfo?.map((item, index) => (
				<Flex w={'100%'} key={index}>
					<Flex w={"60%"}  rounded={"md"} align={"center"} p={1} mt={1}>
						<Flex align={"center"} direction={"column"} rounded={"md"} px={1} w={"100%"}>
							<Avatar mx="auto" src={item?.avatar} h="87px" w="87px" border="1px solid" />
							<Text fontWeight="bold" fontSize="xl">
								{item?.name}
							</Text>
							<Text fontSize="md">{item?.email}</Text>
							<Text fontSize="sm">{item?.phone}</Text>
						</Flex>
					</Flex>
					<Flex w={"60%"} rounded={"md"} direction={'column'}  p={1} mt={1}>
						<Text fontWeight="bold">Supported Link Apps</Text>
						{item?.apps != null
							? <Flex > 
								{renderAppData(item?.apps)} 
							</Flex>
							: <Text>No apps</Text>}
					</Flex>
				</Flex>
			))
		) : (
			<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
				<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
					No subscriber info collected.
				</Heading>
			</Flex>
		);

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} p="1px">
				<Flex h="85vh">
					<Box
						w={!configurationCollapseFlag ? "95%" : "80%"}
						bg={brandBg}
						p="3"
						borderRadius={"10px"}>
						{subscriberInfo !== null && subscriberInfo?.length ? (
							<>
								{renderSubscriberInfo}
								<SubscriberInfoDash />
							</>
						) : null}
					</Box>
					{configurationCollapseFlag ? (
						<Flex minW={"305px"} maxW={"605px"}>
							<Box minW="300px" maxW={"300px"} position="relative" p="3" borderRadius="15px" bg={brandBg} ml="2">
								<Text fontWeight={"500"}>Design Subscriber Details</Text>
								<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
									<AiFillRightCircle
										cursor="pointer"
										size="20px"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
									/>
								</Box>
								<Box w="100%" h="95%" bg={blackWhiteBg} borderRadius={"10px"} mt="3">
									<LinkCustomerUpload />
								</Box>
							</Box>
						</Flex>
					) : (
						<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
							<Box w={"100%"} h="100%">
								<AiFillLeftCircle
									cursor="pointer"
									onClick={() => {
										setConfiguationCollapseFlag(!configurationCollapseFlag);
									}}
									size="20px"
								/>
								<Text
									fontSize="16px"
									mt="2"
									css={{
										writingMode: "vertical-lr",
										textOrientation: "mixed",
									}}
								>
									Subscriber Design
								</Text>
							</Box>
						</Box>
					)}
				</Flex>
			</Card>
		</Flex>
	);
}

export default SubscriberInfo;

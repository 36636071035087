export const INFO_TYPES = {
    BASEINFO:"Base Info",
    CONTACT:"Contact Info",
    ADDRESS:"Address",
    BANK:"Bank Info",
    TAX:"Tax Info"
}

export const TABS = {
    CUSTOMER:"customers",
    ADDCUSTOMER:"addCustomer"
}
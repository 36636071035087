import { Icon } from "@chakra-ui/react";
import { AiOutlineBulb, AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";

export const ACCOUNT_DETAILS_TABS = [
    {name:'Account Details', icon:<Icon as={AiOutlineInfoCircle} mr={1} />},
    {name:"Applicable Lingo Apps", icon:  <Icon as={AiOutlineCheckCircle} mr={1} />},
    {name: "Account Quota", icon:<Icon as={AiOutlineBulb} mr={1} />},
    {name: "Account Languages", icon:<Icon as={AiOutlineBulb} mr={1} />},
    {name:"Document AI", icon:<Icon as={AiOutlineBulb} mr={1} />},
    {name:"Device Management", icon:<Icon as={AiOutlineBulb} mr={1} />},
    {name:"Account Design", icon:<Icon as={AiOutlineBulb} mr={1} />},
    {name:"Account Support", icon:<Icon as={AiOutlineBulb} mr={1} />}
]

export const ACCOUNT_ACCESS_CLASS = {
    ADVANCE:"advance",
    BASIC:"basic"
}

export const ALERT_TYPES = {
    ACCOUNT_ACCESS_LEVEL:"accAccess",
    ACCOUNT_SECURE_CALL:"accSecureCall"
}
import React, { useEffect, useState } from "react";
// Chakra Imports
import { Box, Text, Button, SimpleGrid, Spacer, Flex, Icon, useColorModeValue, Spinner } from "@chakra-ui/react";
import { MdEvent, MdLink, MdLinkOff, MdPhone, MdSensors, MdVideoCall } from "react-icons/md";

// Component Imports
import LinkTraffic from "./LinkTraffic";
import LinkRevenue from "./LinkRevenue";
import Card from "../../../../components/card/Card";
import IconBox from "../../../../components/icons/IconBox";
import StatisticsPill from "../../../common/widgets/StatisticsPill";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import { useTextColor } from "../../../../theme/globalColorTheme";

function LinkMetrics(props) {
	const linkStats = props.linkStats ? props.linkStats : [];
	const eventStats = props.eventStats ? props?.eventStats : [];
	const [loading, setLoading] = useState(false);
	const brandColor = useColorModeValue("brand.500", "white");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonText = useColorModeValue("white.100", "gray.800");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const blackWhiteColor = useTextColor()
	const triggerOnClick = props.triggerOnClick === false ? props.triggerOnClick : true

	console.log(triggerOnClick)

	const sendDurationValue = value => {
		if (value?.length) {
			props?.sendDurationType(value);
		}
	};

	useEffect(() => {
		setLoading(props?.loadingValue)
	}, [props?.loadingValue])

	return (
		<Flex w={"100%"} direction={"column"}>
			<Card w={"100%"} h="fit-content" mt={5}>
				<Flex w={"100%"}>
					<Text ml={4}>LingoLinks</Text>
					<Spacer />
					<Button
						size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor}
						bg={pillButtonBg}
						onClick={() => {
							sendDurationValue("all");
						}}

					>
						Overall
					</Button>
					{/* <Button
						size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor}
						bg={pillButtonBg}
						ml={1}
						onClick={() => {
							sendDurationValue("today");
						}}
					>
						Today
					</Button>
					<Button
						size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor}
						bg={pillButtonBg}
						ml={1}
						onClick={() => {
							sendDurationValue("week");
						}}
					>
						This Week
					</Button>
					<Button
						size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor}
						bg={pillButtonBg}
						ml={1}
						onClick={() => {
							sendDurationValue("month");
						}}
					>
						This Month
					</Button>
					<Button
						size={"sm"}
						_hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor}
						bg={pillButtonBg}
						ml={1}
						onClick={() => {
							sendDurationValue("year");
						}}
					>
						This Year
					</Button>
					<Button size={"sm"} _hover={{ bg: pillButtonBgHighlight, color: "black" }}
						color={blackWhiteColor} bg={pillButtonBg} ml={1}>
						Custom Range
					</Button> */}
				</Flex>
				<Box w={"100%"} mt={2} borderTopColor={"gray.300"} borderTopWidth={"0.5px"}>
					<SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }} gap="10px" mb="10px">
						<StatisticsPill
							launchUrl={ triggerOnClick ? '/subscriber/lingolinklisting' : ""}
							startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdLink} color={brandColor} />} />}
							name="Active Links"
							value={linkStats[0]?.active_links}
							loading={loading}
						/>
						<StatisticsPill
							launchUrl={triggerOnClick ? '/subscriber/lingolinklisting': ''}
							startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdLinkOff} color={"red.300"} />} />}
							name="Closed Links"
							value={linkStats[0]?.closed_links}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/callhistory' : ""}
							startContent={
								<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdSensors} color={brandColor} />} />
							}
							name="Sessions"
							value={linkStats[0]?.sessions}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/callhistory' : ''}
							startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="28px" h="28px" as={MdPhone} color={brandColor} />} />}
							name="Calls"
							value={linkStats[0]?.calls}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/callhistory' : ''}
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdPhone} color="white" />}
								/>
							}
							name="Audio Calls"
							value={linkStats[0]?.audio_calls}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/callhistory': ''}
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdVideoCall} color="white" />}
								/>
							}
							name="Video Calls"
							value={linkStats[0]?.video_calls}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/events' : ''}
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdEvent} color="white" />}
								/>
							}
							name="Events"
							value={linkStats[0]?.total_events}
							loading={loading}
						/>
						<MiniStatistics
							launchUrl={triggerOnClick ? '/subscriber/lingolinklisting' : ''}
							startContent={
								<IconBox
									w="56px"
									h="56px"
									bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
									icon={<Icon w="28px" h="28px" as={MdLink} color="white" />}
								/>
							}
							name="Total Links"
							value={linkStats[0]?.links}
							loading={loading}
						/>
					</SimpleGrid>
				</Box>
			</Card>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color="#000" />
				</Flex>) : (
				<Flex w={"100%"} mt={2}>
					<Flex w={"100%"} ml={1}>
						{eventStats?.length ? (
							<LinkTraffic eventStats={eventStats} />
						) : null}
					</Flex>
				</Flex>)}
		</Flex>
	);
}

export default LinkMetrics;

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Flex, HStack, Text, useColorModeValue, useColorMode, Icon } from "@chakra-ui/react";
import { getEmailId } from "../../../utils/localStorageIndex";
import { fetchPersonalInfoEx } from "../../../services/sharedServices";
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";

// Assets
import Banner from "./component/Banner";
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/dummy-avatar.jpg";
import GeneralInformation from "./component/General";
import Upload from "./component/Upload";
import Social from "./component/Social";
import Info from "./component/Info";
import Password from "./component/Password";
import Card from "../../../components/card/Card";

function PersonalInfo() {
	const emaiId = getEmailId();
	const [userDetails, setUserDetails] = useState({});
	const [currentPage, setCurrentPage] = useState("view");
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { profileLogoFlag } = sideMenuStatus;

	const textColor = useColorModeValue("navy.700", "white");
	const bgBadge = useColorModeValue("brand.50", "whiteAlpha.100");

	useEffect(() => {
		let objData = {
			email: emaiId,
		};
		fetchPersonalInfoEx(objData)
			.then(res => {
				if (res.result) {
					setUserDetails(res.data[0].user_info);
					setCurrentPage("view");
				} else {
				}
			})
			.catch(err => {});
	}, [profileLogoFlag]);

	function renderFormattedString(strtemp) {
		var finalString = "...";
		if (strtemp.trim().length > 0) {
			finalString = strtemp;
		}
		return finalString;
	}

	function renderFullName() {
		var fullName = null;
		if (
			userDetails.first_name !== null &&
			userDetails.first_name !== undefined &&
			userDetails.last_name !== null &&
			userDetails.last_name !== undefined
		) {
			fullName = renderFormattedString(userDetails.first_name);
			fullName = fullName + "  " + renderFormattedString(userDetails.last_name);
			return fullName;
		} else {
			return "...";
		}
	}

	// Todo:Return to view after data is stored
	const handleSaveValueFlag = value => {
		if (value) {
			setCurrentPage("view");
		}
	};

	return (
		<>
			<Box>
				<Card w={"100%"}>
					<Flex w={"100%"} justifyContent={"end"}>
						<Flex borderWidth={"1px"} borderRadius={"10px"}>
							<HStack
								w={"100px"}
								borderTopLeftRadius={"10px"}
								borderBottomLeftRadius={"10px"}
								_hover={{ cursor: "pointer" }}
								onClick={() => setCurrentPage("view")}
								bg={currentPage === "view" ? "gray.200" : "gray.500"}
								boxShadow={currentPage === "view" ? "md" : ""}
								color={currentPage === "view" ?  "black" : "white"}
								h={"40px"}
								m={0}
								px={2}
								fontSize={"18px"}>
								<Text w="100%" textAlign="center">
									View
								</Text>
							</HStack>
							<HStack
								w={"100px"}
								bg={currentPage === "edit" ? "gray.200" : "gray.500"}
								borderTopRightRadius={"10px"}
								borderBottomRightRadius={"10px"}
								_hover={{ cursor: "pointer" }}
								onClick={() => setCurrentPage("edit")}
								boxShadow={currentPage === "edit" ? "md" : ""}
								h={"40px"}
								px={2}
								color={currentPage === "edit" ?  "black" : "white"}
								fontSize={"18px"}>
								<Text w="100%" textAlign="center">
									Edit
								</Text>
							</HStack>
						</Flex>
					</Flex>
				</Card>
				{currentPage === "view" ? (
					<SimpleGrid mt={"10px"} mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: "20px", xl: "20px" }}>
						<Flex direction="column">
							<Banner
								gridArea="1 / 1 / 2 / 2"
								banner={banner}
								avatar={userDetails.photo_url ? userDetails.photo_url : avatar}
								name={renderFullName()}
								job={
									userDetails.role !== null && userDetails.role !== undefined
										? userDetails.role
										: "..."
								}
								department={userDetails.department_name}
								email={
									userDetails.email !== null && userDetails.email !== undefined
										? userDetails.email
										: "..."
								}
								phone={
									userDetails.phone_number !== null && userDetails.phone_number !== undefined
										? userDetails.phone_number
										: "..."
								}
								join_date={userDetails.date}
								social_about={userDetails.social_about}
								// posts='17'
								// followers='9.7k'
								// following='274'
							/>
						</Flex>
						{/* Column Right */}
						<Flex direction="column">
							<GeneralInformation
								gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
								minH="365px"
								pe="20px"
							/>
						</Flex>
					</SimpleGrid>
				) : null}
				{currentPage === "edit" ? (
					<Box w={"100%"}>
						<SimpleGrid
							mt={"10px"}
							mb="20px"
							columns={{ sm: 1, lg: 2 }}
							spacing={{ base: "20px", xl: "20px" }}>
							<Flex direction="column">
								<Info userType="admin" returnSaveValueFlag={handleSaveValueFlag} />
							</Flex>
							<Flex direction="column">
								{/* <Social /> */}
								<Password />
							</Flex>
						</SimpleGrid>
						<SimpleGrid mb="20px" columns={{ sm: 1, lg: 1 }} spacing={{ base: "20px", xl: "20px" }}>
							<Flex direction="column">
								<Upload />
							</Flex>
						</SimpleGrid>
					</Box>
				) : null}
			</Box>
		</>
	);
}

export default PersonalInfo;

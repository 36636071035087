// Chakra React Imports
import React, { useState } from "react";
import {
	Icon,
	Flex,
	Tab,
	Tabs,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";

import { AiOutlineBulb } from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";
import LinkSharingTab from "./reportingTab/accountSharing/LinkSharingTab";
import AccCallHistory from "./reportingTab/accountcallhistory/CallHistory";
import AccLingoCallHistory from "./reportingTab/accountcallhistory/LingoCallHistory";
import AccEventsTab from "./reportingTab/events/AccountEventsTab";

function AccountReporting() {
	const [activeTab, setActiveTab] = useState(0);
	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="1">
			{/* <Card w={"100%"} rounded={"md"} p={1} mb={2} mx={3}>
				<Box textAlign="right">
					<ReportingPanel />
				</Box>
			</Card> */}
			<Card w={"100%"} h={'100%'} rounded={"md"} p={1} mx={3}>
				<Tabs
					w="100%"
					borderBottom={"0px"}
					mx="auto"
					onChange={handleTabChange}
					index={activeTab}
				>
					<TabList border="0px" w="100%">
						<Flex justifyContent={"end"} w="100%">
							<Flex>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Call History
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Link Sharing
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									LingoCalls 
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Activity(Events)
								</Tab>
								
							</Flex>
						</Flex>
					</TabList>
					<TabPanels mt="1" h="100%">
						<TabPanel p="0">
							<AccCallHistory />
						</TabPanel>
						<TabPanel p="0" h="100%">
							<LinkSharingTab />
						</TabPanel>
						<TabPanel p="0" h="100%">
							<AccLingoCallHistory />
						</TabPanel>
						<TabPanel p="0" h="100%">
							<AccEventsTab />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default AccountReporting;

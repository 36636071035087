// Chakra imports
import React, { useEffect, useState } from "react";
import Card from "../../../../components/card/Card.js";
import InputField from "../../../../components/fields/InputField";
import TextField from "../../../../components/fields/TextField";
import { getEmailId } from "../../../../utils/localStorageIndex";
import {
  updateUserInfo,
  fetchPersonalInfo,
} from "../../../../services/sharedServices";
import {
  Button,
  Text,
  FormLabel,
  Select,
  Flex,
  useToast,
  FormControl,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function Info(props) {
  const toast = useToast();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [updateInfoObj, setUpdateInfoObj] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    social_about: "",
    email: emaiId,
  });
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  // Todo:Fetch User Details
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then((res) => {
        if (res.result) {
          setUpdateInfoObj(res.data[0]);
          isLoading(false);
        } else {
          isLoading(false);
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err) {
        }
      });
  };

  // on change update form value fields
  const onUpdateField = (e) => {
    const nextFormState = {
      ...updateInfoObj,
      [e.target.name]: e.target.value,
    };
    setUpdateInfoObj(nextFormState);
  };

  // form validation function
  const validateFieldForm = () => {
    let fields = updateInfoObj;
    let errors = {};
    let formIsValid = true;

    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*First Name is required.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Last Name is required.";
    }
    setError(errors);
    return formIsValid;
  };

  // Todo:Save User Details
  const submitUserData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      updateInfoObj.email = emaiId;
      isLoading(true);
      updateUserInfo(updateInfoObj)
        .then((res) => {
          if (res.result) {
            props?.returnSaveValueFlag(true)
            isLoading(false);
            // if (res?.data?.length > 0) {
            //   if (
            //     res?.data[0]?.group === "100"
            //   ) {
            //     navigate("/admin/profile");
            //   } else if (
            //     res?.data[0]?.group === "200"
            //   ) {
            //     navigate("/business/profile");
            //   } else if (
            //     res?.data[0]?.group === "300"
            //   ) {
            //     navigate("/associate/profile");
            //   }
            // }
            toastFunction(res.message, "success");
          } else {
            isLoading(false);
            toastFunction(res.error, "success");
          }
        })
        .catch((err) => {
          isLoading(false);
          toastFunction(err.message, "success");
        });
    }
  };

  // Todo:Toaster Function
  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  return (
    <FormControl>
      <Card pb="100px">
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Account Settings
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Flex flexWrap="wrap">
            <InputField
              mb="0"
              me="30px"
              id="first_name"
              label="First Name"
              value={updateInfoObj.first_name}
              name="first_name"
              onChange={onUpdateField}
              placeholder="Enter First Name"
              w="100%"
            />
            <Text fontSize="sm" color={"red"} mb="5" mt="1">
              {error?.first_name}
            </Text>
          </Flex>
          <Flex flexWrap={"wrap"}>
            <InputField
              mb="0px"
              id="last_name"
              label="Last Name"
              value={updateInfoObj.last_name}
              name="last_name"
              onChange={onUpdateField}
              placeholder="Enter Last Name"
              w="100%"
            />
            <Text fontSize="sm" color="red" mb="5" mt="1">
              {error?.last_name}
            </Text>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          mt={5}
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <InputField
            mb="0"
            me="30px"
            id="phone_number"
            label="Phone Number"
            value={updateInfoObj.phone_number}
            name="phone_number"
            onChange={onUpdateField}
            placeholder="Enter Phone Number"
          />
          <Text fontSize="sm" color={"red"} mb="1" mt="1">
            {error?.phone_number}
          </Text>
        </SimpleGrid>
        {props.userType != 'associate'
        ?<Flex direction="column">
          <FormLabel
            ms="10px"
            htmlFor="given_role"
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            Select your Role
          </FormLabel>
          <Select
            fontSize="sm"
            id="given_role"
            name="given_role"
            variant="main"
            h="44px"
            maxh="44px"
            me="20px"
            color={"GrayText"}
            onChange={onUpdateField}
            value={updateInfoObj.given_role}
            defaultValue={updateInfoObj.given_role}
          >
            <option value="Executive">Executive</option>
            <option value="Leadership">Leadership</option>
            <option value="Management">Management</option>
            <option value="Recruitment">Recruitment</option>
            <option value="Support">Support</option>
            <option value="Other">Other</option>
          </Select>
        </Flex>:null}
        {props.departmentList && props.departmentList.length > 0 ? (
          <Flex direction="column" mt={5}>
            <FormLabel
              ms="10px"
              htmlFor="given_role"
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              Select your Department
            </FormLabel>
            <Select
              fontSize="sm"
              id="department_name"
              name="department_name"
              variant="main"
              h="44px"
              maxh="44px"
              me="20px"
              color={"GrayText"}
              onChange={onUpdateField}
              value={updateInfoObj.department_name}
              defaultValue={updateInfoObj.department_name}
            >
              {props.departmentList.map((dept) => {
                return <option value={dept.name}>{dept.name}</option>;
              })}
            </Select>
          </Flex>
        ) : null}
        <SimpleGrid
          mt={5}
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <TextField
            id="social_about"
            name="social_about"
            value={updateInfoObj.social_about}
            onChange={onUpdateField}
            label="About Me"
            h="100px"
            placeholder="Tell something about yourself in 250 characters!"
          />
        </SimpleGrid>

        <Button
          variant="brand"
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          ms="auto"
          onClick={(e) => {
            submitUserData(e);
          }}
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
        >
          Save changes
        </Button>
      </Card>
    </FormControl>
  );
}

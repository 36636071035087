import React from "react";
import { useNavigate } from "react-router-dom";
import { borderBottomColor } from "../../constants/index";
import { Flex, Box, Text, LinkBox, LinkOverlay, Icon } from "@chakra-ui/react";
import {
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";

function Footer() {
  const navigate = useNavigate();
  return (
    <Flex flexWrap="wrap" bg="#1b1a21" color="#fff" pt="10" mt={20}>
      <Flex justifyContent="space-between" w="100%" px="40" pb="10">
        <Box w="25%">
          <Text fontSize="16px" fontWeight="bold" mb="10px">
            Onboarding Solutions
          </Text>
          <Text fontSize="14px" mb="8px">
            HR Integration
          </Text>
          <Text fontSize="14px" mb="8px">
            Talent Onboarding
          </Text>
        </Box>
        <Box w="25%">
          <Text fontSize="16px" fontWeight="bold" mb="10px">
            Support
          </Text>
          <Text fontSize="14px" mb="8px">
            Contact Us
          </Text>
          <Text fontSize="14px" mb="8px">
            Waitlisting
          </Text>
          <Text fontSize="14px" mb="8px">
            Get Demo
          </Text>
        </Box>
        <Box w="25%">
          <Text fontSize="16px" fontWeight="bold" mb="10px">
            Company
          </Text>
          <Text
            fontSize="14px"
            mb="8px"
            cursor="pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Website
          </Text>
          <Text fontSize="14px" mb="8px">
            About
          </Text>
          <Text
            fontSize="14px"
            mb="8px"
            cursor="pointer"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </Text>
          <Text
            fontSize="14px"
            mb="8px"
            cursor="pointer"
            onClick={() => {
              navigate("/register");
            }}
          >
            Register
          </Text>

          {/* <Text fontSize="14px" mb="8px">
            Use Case
          </Text> */}
          {/* <Text fontSize="14px" mb="8px">
            Blogs
          </Text> */}
          <Text fontSize="14px" mb="8px">
            Careers
          </Text>
        </Box>
        <Box w="25%">
          <Text fontSize="16px" fontWeight="bold" mb="10px">
            Legal
          </Text>
          <Text
            cursor="pointer"
            onClick={() => {
              navigate("/terms-of-use");
            }}
            fontSize="14px"
            mb="8px"
          >
            Terms of Use
          </Text>
          <Text
            fontSize="14px"
            mb="8px"
            cursor="pointer"
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </Text>
          <Text
            fontSize="14px"
            mb="8px"
            cursor="pointer"
            onClick={() => {
              navigate("/cookie-policy");
            }}
          >
            Cookie Policy
          </Text>
        </Box>
      </Flex>
      <Box w="100%" borderTop={"1px"} borderColor={borderBottomColor}>
        <Flex mt="10" justifyContent="center">
          <LinkBox as="article" isExternal mr={3}>
            <LinkOverlay
              href="https://www.linkedin.com/company/lingoletai/"
              target="_blank"
            >
              <Icon fontSize={30} color="white.500" as={AiFillLinkedin}></Icon>
            </LinkOverlay>
          </LinkBox>
          <LinkBox as="article" isExternal mr={3}>
            <LinkOverlay
              href="https://www.facebook.com/profile.php?id=lingoletai"
              target="_blank"
            >
              <Icon fontSize={30} color="white.500" as={AiFillFacebook}></Icon>
            </LinkOverlay>
          </LinkBox>
          <LinkBox as="article" isExternal mr={3}>
            <LinkOverlay
              href="https://www.instagram.com/lingoletai/"
              target="_blank"
            >
              <Icon fontSize={30} color="white.500" as={AiFillInstagram}></Icon>
            </LinkOverlay>
          </LinkBox>
          <LinkBox as="article" isExternal mr={3}>
            <LinkOverlay href="https://twitter.com/lingoletai" target="_blank">
              <Icon
                fontSize={30}
                color="white.500"
                as={AiFillTwitterCircle}
              ></Icon>
            </LinkOverlay>
          </LinkBox>
        </Flex>
      </Box>
      <Box w="100%">
        <Flex my="10" justifyContent="center">
          <Text mr="3">© 2023 Lingolet AI Inc, All rights reserved.</Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Footer;

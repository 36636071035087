import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Stack,
  Input,
  Icon,
  Text,
  FormControl,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "../../../services/axios";

// Custom components
import { MdUpload } from "react-icons/md";
import "react-dropzone-uploader/dist/styles.css";
import { getEmailId } from "../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { uploadLinkDesignImages } from "../../../services/businessServices";
import { setBannerFlag } from "../../../store/actions/topNotificationActions";
// Component Imports

export default function UploadLogoBanner({ linkKey, linkUuid, choice, title, fileInfo, renderLogoData, customerUuid }) {
  const toast = useToast();
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  // Todo:Declared Use-State
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const emaiId = getEmailId();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

  function postObjectData(s3Data, selectedFile) {
    const { url, fields } = s3Data;
    const payload = new FormData();
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append("file", selectedFile);
    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common["Authorization"];
    const { data: result } = ax_instance
      .post(url, payload)
      .then((res) => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setUploadStatus("File is uploaded successfully");
          dispatch(setBannerFlag(true));
          setUploadFile();
          renderLogoData('successfull')
        } else {
          setUploadStatus("File is uploaded successfully");
          dispatch(setBannerFlag(true));
          setUploadFile();
          setUploadFileUrl();
          renderLogoData('successfull')
        }
      })
      .catch((err) => {
        isLoading(false);
        setUploadFile();
        setUploadFileUrl();
        setUploadStatus("Upload failed with Error :" + err);
      });
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size <= 2048000) {
        setUploadFile(e.target.files[0]);
        setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        toast(
          toastFunctionToaster(
            "The maximum supported file size is 2048KB or 2MB",
            "error"
          )
        );
      }
    }
  };

  const handleSubmitNew = async () => {
    if (choice === 'logo' || choice === 'banner') {
      setUploadStatusLoading(true);
      if (uploadFile) {
        let objData = {
          email: emaiId,
          linkKey: linkKey,
          linkUuid: linkUuid,
          content_choice: choice,
          file_name: uploadFile.name,
          file_type: uploadFile.type,
        };
        setUploadStatus("Getting upload url for the selected file..");
        const response = await uploadLinkDesignImages(objData);
        setUploadStatusLoading(false);
        if (response && response.data && response.data.length > 0) {
          setUploadStatusLoading(false);
          const finalUrl = response.data[0].signedPackage.signedUrl;
          setUploadStatus("Trying to upload the selected file..");
          const s3UploadResult = await postObjectData(finalUrl, uploadFile);
        } else {
          setUploadStatusLoading(false);
          setUploadStatus("Unable to get the upload signed url..");
        }
      }
    }
  };

  return (
    <Box>
      {linkUuid != null && linkKey != null && choice != null
        ? <Box w={"100%"}>
          <FormControl cursor="pointer">
            <Text fontSize={"12px"}>{uploadFileUrl}</Text>
            <Text mb={4} fontSize={"12px"}>
              {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
            </Text>
            <Box
              position="relative"
              height="200px"
              width="100%"
              borderWidth="2px"
              borderColor={brandColor}
              borderRadius={"10px"}
              cursor="pointer"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                cursor="pointer"
              >
                <Stack
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justify="center"
                  spacing="4"
                  cursor="pointer"
                >
                  <Box textAlign="center">
                    <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                    <Flex justify="center" mx="auto" mb="12px">
                      <Text fontSize="xl" fontWeight="700" color={brandColor}>
                        {title}
                      </Text>
                    </Flex>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                    >
                      PNG, JPG and JPEG files are allowed (maximum 2MB)
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.700"
                    >
                      {fileInfo}
                    </Text>
                  </Box>
                </Stack>
              </Box>
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept=".png, .jpg, .jpeg"
                cursor="pointer"
                onChange={handleFileChange}
              />
            </Box>
          </FormControl>
        </Box>
        : <Box w={"100%"}>
          <Text textAlign="center" mt="3" color="green.200">
            {uploadStatus}
          </Text>
        </Box>}
      {uploadFile ? (
        <Button
          mt={5}
          size="sm"
          bg={"blue.100"}
          onClick={handleSubmitNew}
          isDisabled={uploadStatusLoading ? true : false}
          isLoading={uploadStatusLoading ? true : false}
        >
          {title}
        </Button>
      ) : null}
      <Box w={"100%"}>
        <Text textAlign="center" mt="3" color="green.200">
          {uploadStatus}
        </Text>
      </Box>
    </Box>
  );
}

import React from "react";
import QRCode from "react-qr-code";
// Chakra Import
import {
	Flex,
	Text,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";


function LinkQRCodeWidget(props) {

	const modalQrCode = props?.modalQrCode
	const qrRef = props?.modalQrCode
	const isOpenQr = props?.isOpenQr
	const onCloseQr = props?.onCloseQr
	const currentHost = props?.currentHost

	const setModalQrCode = () => {
		props?.handleQrCode('null')
	}
	return (
		<>
			<Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>QR Code for the LingoLink</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{modalQrCode != null ? (
							<Flex w={"100%"} direction={"column"}>
								<QRCode
									title={currentHost + modalQrCode}
									size={90}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={currentHost + modalQrCode}
									viewBox={`0 0 90 90`}
								/>
								<Text p={1} bg={"blue.100"}>
									{currentHost + modalQrCode}
								</Text>
							</Flex>
						) : (
							<Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							size={"sm"}
							colorScheme="blue"
							onClick={() => {
								onCloseQr();
							}}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default LinkQRCodeWidget;

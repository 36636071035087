// React Chakra Imports
import React from "react";
import { MdFileDownload } from "react-icons/md";
import { AiOutlineSearch, AiFillLock } from "react-icons/ai";
import {
	Icon,
	Flex,
	Text,
	Stack,
	InputGroup,
	Box,
	Input,
	Button,
	InputLeftElement,
} from "@chakra-ui/react";

function TrustResource() {
	return (
		<Box>
			<Flex mt="3" alignItems={"center"} justifyContent={"space-between"}>
				<Text fontSize={"18px"} fontWeight={"500"}>
					Resources{" "}
				</Text>
				<Flex>
					<Button
						leftIcon={<Icon as={MdFileDownload} />}
						size="sm"
						colorScheme="gray"
						mr="3"
						isDisabled="true"
						borderWidth={"1px"}
						borderRadius="5px"
					>
						Bulk Download
					</Button>
					<Stack spacing={4} w="60%" alignItems={"center"}>
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<Icon
									mt="-1"
									as={AiOutlineSearch}
									color="gray.300"
								/>
							</InputLeftElement>
							<Input
								size="sm"
								type="text"
								placeholder="Search Resources"
							/>
						</InputGroup>
					</Stack>
				</Flex>
			</Flex>
			<Flex
				alignItems={"center"}
				borderWidth={"1px"}
				borderRadius={"5px"}
				h="80px"
				mt="3"
				px="20px"
				justifyContent={"space-between"}
			>
				<Text>SOC 2 Type II</Text>
				{/* <Icon as={AiFillLock}></Icon> */}
				<Button
					leftIcon={<Icon as={AiFillLock} />}
					size="sm"
					colorScheme="purple"
					mr="3"
					borderWidth={"1px"}
					borderRadius="5px"
				>
					Request Access
				</Button>
			</Flex>
			<Flex
				alignItems={"center"}
				borderWidth={"1px"}
				borderRadius={"5px"}
				h="80px"
				borderTop={"0px"}
				px="20px"
				justifyContent={"space-between"}
			>
				<Text>2023 Penetration Test</Text>
				<Button
					leftIcon={<Icon as={AiFillLock} />}
					size="sm"
					colorScheme="purple"
					mr="3"
					borderWidth={"1px"}
					borderRadius="5px"
				>
					Request Access
				</Button>
			</Flex>
		</Box>
	);
}

export default TrustResource;

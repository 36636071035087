import React, { useState, useEffect } from 'react'
// Chakra Import
import {
    Box,
    Flex,
    Text,
    Button,
    Drawer,
    FormLabel,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Textarea,
    useToast,
    Select,
    useColorModeValue
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";

import {
    addAccountDetails,
    updateAccountDetails,
} from "../../../services/businessServices";

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useTextColor } from "../../../theme/globalColorTheme";

function AddUpdateAccountList(props) {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [accountTitle, setAccountTitle] = useState(null);
    const [accountDetails, setAccountDetails] = useState(null);
    const astrikStar = useColorModeValue("red.500", "red.400");
    const [accountNameErrFlag, setAccountNameFlag] = useState(null)
    const [accountTittleErrFlag, setAccountTitleErrFlag] = useState(null)
    const [accountDetErrFlag, setAccDetErrFlag] = useState(null)
    const [accountType, setAccountType] = useState('School District');
    const optionsTextColor = useTextColor()
    // Todo:Update data when edit is clicked from AccountLisitng Component
    useEffect(() => {
        if (Object.keys(props?.accountData)?.length) {
            const accountData = props?.accountData
            setAccountId(accountData?.link_account_uuid ? accountData?.link_account_uuid : null)
            setAccountName(accountData?.link_account_name ? accountData?.link_account_name : null)
            setAccountTitle(accountData?.link_account_title ? accountData?.link_account_title : null)
            setAccountDetails(accountData?.link_account_details ? accountData?.link_account_details : null)
            setAccountType(accountData?.link_account_type ? accountData?.link_account_type : 'School District')
        }
    }, [props?.accountData])

    // Todo:Reset details on drawer closed
    const closeDrawer = () => {
        props?.close()
        resetAccountDetails();
    };

    // Todo:Validate Name And Email
    const validateFieldForm = () => {
        let formIsValid = true;
        if (!accountName) {
            formIsValid = false;
            setAccountNameFlag("*Name Cannot Be Empty");
        } else {
            setAccountNameFlag("");
        }
        if (!accountTitle) {
            formIsValid = false;
            setAccountTitleErrFlag("*Title Cannot Be Empty");
        } else {
            setAccountTitleErrFlag("");
        }
        if (!accountDetails) {
            formIsValid = false;
            setAccDetErrFlag("*Details Cannot Be Empty");
        } else {
            setAccDetErrFlag("");
        }
        return formIsValid;
    };

    //   Todo:Add/Update New Account
    const createNewAccountDetail = () => {
        setLoading(true)
        if (validateFieldForm()) {
            let objBody = {
                "link_account_title": accountTitle,
                "link_account_name": accountName,
                "link_account_type": accountType,
                "link_account_details": accountDetails
            };
            addAccountDetails(objBody)
                .then(res => {
                    setLoading(false)
                    if (res?.result) {
                        props?.callList('reset')
                        resetAccountDetails();
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {
                        props?.callList('reset')
                        resetAccountDetails();
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                    resetAccountDetails();
                })
                .catch(err => {
                    setLoading(false)
                    props?.callList('reset')
                    resetAccountDetails();
                    toast(toastFunctionToaster(err.message, "error"));
                });
        }
        else {
            setLoading(false)

        }
    };

    // Todo:Update Account Details
    const updateAccountData = () => {
        setLoading(true)
        if (validateFieldForm()) {
            if (accountId?.length) {
                let objBody = {
                    "link_account_uuid": accountId,
                    "link_account_title": accountTitle,
                    "link_account_name": accountName,
                    "link_account_type": accountType,
                    "link_account_details": accountDetails

                };
                updateAccountDetails(objBody)
                    .then(res => {
                        setLoading(false)
                        if (res?.result) {
                            props?.callList('reset')
                            resetAccountDetails();
                            toast(toastFunctionToaster(res.message, "success"));
                        } else {
                            props?.callList('reset')
                            resetAccountDetails();
                            toast(toastFunctionToaster(res.message, "error"));
                        }
                        resetAccountDetails();
                    })
                    .catch(err => {
                        props?.callList('reset')
                        resetAccountDetails();
                        toast(toastFunctionToaster(err.message, "error"));
                    });
            }
        }
        else {
            setLoading(false)
        }
    };

    // Todo:Reset Account Details
    const resetAccountDetails = () => {
        setAccountId(null);
        setAccountName(null)
        setAccountTitle(null)
        setAccDetErrFlag(null);
        setAccountDetails(null)
        setAccountNameFlag(null)
        setAccountTitleErrFlag(null);
        setAccountType('School District')
    };

    return (
        <Drawer
            size="md"
            isOpen={props?.isOpen}
            placement="right"
            onClose={() => {
                closeDrawer();
            }}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={"1px"}>
                    {accountId ? "Update" : "Add New"} Account
                </DrawerHeader>
                <DrawerBody mt="5" overflow={'hidden'} overflowY={'auto'}>
                    {/* Todo:Account Name */}
                    <Flex w={"100%"} direction={"column"}>
                        <InputField
                            mb="0"
                            me="30px"
                            ml="0"
                            id="accountName"
                            label="Account Name"
                            value={accountName}
                            name="accountName"
                            required="true"
                            borderRadius="5px"
                            onChange={e => {
                                setAccountName(e.target.value);
                            }}
                            placeholder="Enter Account Name"
                            w="100%"
                            maxlength={100}
                        />
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize="sm" color={"red"} mt="1" mb="5">
                                {accountNameErrFlag}
                            </Text>
                        </Flex>
                    </Flex>
                    {/* Todo:Account Title */}
                    <Flex w={"100%"} direction={"column"}>
                        <InputField
                            mb="0"
                            me="30px"
                            id="accountTitle"
                            label="Account Title"
                            value={accountTitle}
                            name="accountTitle"
                            required="true"
                            borderRadius="5px"
                            onChange={e => {
                                setAccountTitle(e.target.value);
                            }}
                            placeholder="Enter Title"
                            w="100%"
                            maxlength={100}
                        />
                        {accountTittleErrFlag ? (
                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                <Text fontSize="sm" color={"red"} mt="1">
                                    {accountTittleErrFlag}
                                </Text>
                            </Flex>
                        ) : null}
                    </Flex>

                    {/* Todo:Account Type */}
                    <Box my="30px">
                        <Flex>
                            <Text mb={2} fontSize={'sm'} fontWeight={'bold'}>Select Account Type:</Text>
                            <Text ml="1" color={astrikStar}>*</Text>
                        </Flex>
                        <Select
                            value={accountType}
                            name="language_one"

                            size="md"
                            borderRadius="3px"
                            borderColor="gray.200"
                            fontSize={'14px'}
                            mr="10px"
                            onChange={e => {
                                setAccountType(e.target.value);
                            }}
                        > <option value="" disabled style={{ color: optionsTextColor }}  >
                                Select Account Type
                            </option>
                            <option value="School District" style={{ color: optionsTextColor }}>School District</option>
                            <option value="LSP" style={{ color: optionsTextColor }}>LSP</option>
                            <option value="Other" style={{ color: optionsTextColor }}>Other</option>
                        </Select>
                    </Box>

                    {/* Todo:Account Details */}
                    <FormLabel mt="3" ml="2" required>
                        <Flex>
                            <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                Account Details
                            </Text>
                            <Text ml="1" color={astrikStar}>*</Text>
                        </Flex>
                        <Textarea
                            mb="0"
                            me="30px"
                            rows="5"
                            id="accountDetails"
                            value={accountDetails}
                            name="accountDetails"
                            onChange={e => {
                                setAccountDetails(e.target.value);
                            }}
                            placeholder="Enter Account Details"
                            w="100%"
                            maxlength={200}
                        />
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize="sm" color={"red"} mt="1" mb="5">
                                {accountDetErrFlag}
                            </Text>
                            <Text textAlign={"end"} fontWeight={"500"} fontSize={"sm"} mt="2">
                                {accountDetails?.length}/200
                            </Text>
                        </Flex>
                    </FormLabel>
                </DrawerBody>
                <DrawerFooter>
                    <Flex w={"100%"} direction={"row"}>
                        <Button
                            variant="outline"
                            mr={3}
                            onClick={() => {
                                closeDrawer()
                            }}>
                            Cancel
                        </Button>
                        {accountId ? (
                            <Button
                                size="sm"
                                borderRadius={'10px'}
                                colorScheme="green"
                                isDisabled={loading ? true : false}
                                isLoading={loading ? true : false}
                                onClick={() => {
                                    updateAccountData();
                                }}>
                                Update Account Details
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                borderRadius={'10px'}
                                colorScheme="green"
                                isDisabled={loading ? true : false}
                                isLoading={loading ? true : false}
                                onClick={() => {
                                    createNewAccountDetail();
                                }}>
                                Add New Account
                            </Button>
                        )}
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer >
    )
}

export default AddUpdateAccountList
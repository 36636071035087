import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { useLocation, useNavigate, location } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps, SimpleGrid } from '@chakra-ui/react'
import {
  extendTheme,
  Container,
  Heading,
  Icon,
  Button,
  Box,
  Flex,
  Image,
  Link,
  Text,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Center,
} from "@chakra-ui/react";
// import theme from "./theme";
// import HeroCarousel from "./HeroCarausel";
import { FaPlus, FaArrowRightArrowLeft, FaBilibili, FaRocketchat, 
  FaArrowsDownToPeople, FaArrowsTurnToDots, FaCircleDot,
  FaAlignJustify } from "react-icons/fa6";

import Card from '../../components/card/Card';
import LingoletFooter from "../landinghome/LingoletFooter";

import HybridScreen from "../../assets/landingPageImg/HybridScreen.png";
import logo from "../../assets/landingPageImg/kb_logo.png";

function HybridAppPage() {
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const launchPage = (url) => {
      navigate(url, {
          state: {
              sourcePage:'hybridapp',
          },
      });
    };

    const contentArr = [
      {
        heading: "Remote Video Interpretation",
        subheading:
          "When onsite face-to-face interpretation is neither feasible nor practical, VRI technology is the ideal format for professional real-time language access with a visual video solution.",
        // img: contentImg1,
      },
      {
        heading: "Remote Over-the-Phone Interpretation",
        subheading:
          "Over-the-Phone Interpreting (OPI) allows users to quickly connect to a qualified professional interpreter wherever they are within 30 seconds at a fraction of the cost of traditional onsite interpreting.",
        // img: contentImg2,
      },
      {
        heading: "Document Translation",
        subheading:
          "Translate the written word into another language. Upload text files in any format and get translation in twelve major languages. Translations keep the original structure and format.",
        // img: contentImg3,
      },
      {
        heading: "AI Transcription & Translation",
        subheading:
          "Upload audio files from your computer or Google drive and get a quick cost and delivery estimate. Real-time speech-to-text transcription for webinars, lectures, and other live events. Share a streaming link of your real-time transcript with others.",
        // img: contentImg3,
      },
      {
        heading: "Human Proofreading",
        subheading:
          "AI transcription with optional professional human proofreading for maximum accuracy. Match with top professional translators for your industry.",
        // img: contentImg3,
      },
      {
        heading: "Hybrid Multilingual Engines",
        subheading:
          "“Hybrid” means Lingolet combines AI and humans. AI language technology is fast and scalable. Human interpretation guarantees 100% accuracy, nuance, and culturally appropriate.",
        // img: contentImg3,
      }


    ];
  
    const renderContent = contentArr?.map((content, index) => (
      <Flex mb="16" flexDirection={index % 2 === 1 ? "row-reverse" : ""}>
        <Card _hover={{'bg': 'gray.300'}} bg={'gray.200'}>
        <Box>
          <Heading
            fontSize={{
              base: "",
              md: "",
              xl: "",
              sm: "20px",
            }}
            fontWeight={"500"}
            mb="6"
          >
            {content?.heading}
          </Heading>
          <Text
            fontSize={{
              base: "14px",
              md: "14px",
              xl: "14px",
              sm: "14px",
            }}
            fontWeight={"500"}
          >
            {content?.subheading}
          </Text>
        </Box>
        <Box>
          {/* <Image src={content?.img}></Image> */}
        </Box>
        </Card>
      </Flex>
    ));

    return (
        <>
          <Box bg="#fff" color="#000">
            <Box bg="#fff">
              <Flex
                w={{
                  base: "50%",
                  md: "90%",
                  xl: "50%",
                  sm: "100%",
                }}
                mx="auto"
                justifyContent={"space-between"}
                alignItems={"center"}
                h="12vh"
              >
                <Box>
                  <Image
                    _hover={{'cursor':'pointer'}}
                    onClick={() => launchPage('/')}
                    w={{
                      base: "50%",
                      md: "50%",
                      xl: "50%",
                      sm: "50%",  
                    }}
                    src={logo}
                  ></Image>
                </Box>
                <Flex
                  w={'60%'}
                  alignItems={"center"}
                  fontSize={{
                    base: "18px",
                    md: "18px",
                    xl: "18px",
                    sm: "10px",
                  }}
                  bg="white"
                >
                  <Text
                    m="0"
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    color="#000"
                  >
                    Lingolet Human and AI Hybrid Interpreter.
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
                w={{
                  base: "80%",
                  md: "90%",
                  xl: "50%",
                  sm: "90%",
                }}
                mx="auto"
                my="1"
                bg="#fff"
              >
                <Heading
                  textAlign={"center"}
                  my="50px"
                  fontSize={{
                    base: "20px",
                    md: "20px",
                    xl: "50px",
                    sm: "20px",
                  }}
                  fontWeight={"normal"}
                >
                  Why Use{" "}
                  <Text as="span" fontWeight={"bold"}>
                  Lingolet Hybrid Interpreter 
                  </Text>
                  {' '}?
                </Heading>
                <Image src={HybridScreen} />
                <Text fontWeight={"500"} mt={2}>
                Lingolet Hybrid Interpreter is AI language technology plus humans.
                </Text>
                <Text  mt={4}>
                The Lingolet Hybrid Interpreter platform is the most complete and innovative interpretation language solution on the market today. With the Lingolet Hybrid Interpreter, it’s easier to break the language barrier by connecting interpreters and AI technology via an app and web applications.
                </Text>
                <Box mt={5}>
                  <SimpleGrid 
                    spacing={5}
                    columns={{
                     base: "2",
                     md: "2",
                     xl: "2",
                     sm: "1",
                  }}>
                  {renderContent}
                  </SimpleGrid>
                </Box>
              <Flex
                  w={'100%'}
                  direction={'column'}
                  fontSize={{
                    base: "18px",
                    md: "18px",
                    xl: "18px",
                    sm: "14px",
                  }}
                  px={10}
                  bg="white"
                >
                  <Text
                    m="0"
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    color="#000"
                  >
                     Never Get Lost in Audio Transcription & Translation with Hybrid Intelligence.
                  </Text>
                  <Text>
                    <ul>
                      <li>Transcribe audio files to text with our AI transcriber instantly. Get real-time speech-to-text transcripts in twelve languages.</li>
                      <li>Benefit from professional proofreading to get 100% certified accuracy. Get live-streaming transcription as you need.</li>
                    </ul>
                  </Text>
                  <Box w={'100%'} align="center">
                    <ChakraLink as={ReactRouterLink} isExternal to={'https://lingolet.io/login'}>
                      <Button 
                        mt={5} bg={'blue.100'} alignSelf={'center'} w={'160px'}> 
                        Visit Live Demo
                      </Button>
                    </ChakraLink>
                  </Box>
                </Flex>
              </Box>
            <LingoletFooter />
            <Box bg="#fff">
                <Text color="#000" textAlign={"center"} p="8">
                    &copy; Copyright 2024 Lingolet AI, Inc. All rights reserved.
                </Text>
            </Box>

        </Box>
    </>
    )
}

  
export default HybridAppPage;

// Chakra Imports
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch, AiOutlineRobot, AiOutlineUserSwitch } from "react-icons/ai";
import {
	Stack,
	Icon,
	Box,
	FormLabel,
	Heading,
	Flex,
	Text,
	Button,
	Drawer,
	Spinner,
	InputGroup,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	InputLeftElement,
	useDisclosure,
	Input,
} from "@chakra-ui/react";

// Api Call Imports
import { accessibilityPageEx } from "../../../services/dynamicPageSvc";

// Component Imports
import BLCommunicate from "./BLCommunicate";

const BoostLingoSystem = props => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading, isLoading] = useState(false);
	const launchKey = props.launchKey ? props.launchKey : null;
	const sessionKey = props.sessionKey ? props.sessionKey : null;
	const blObj = props.blObj ? props.blObj : null;
	const [propResult, setPropResult] = useState(null);
	const [boostlingoOk, setBoostlingoOk] = useState(false);
	const [boostlingoData, setBoostlingoData] = useState({});
	const [timeLeft, setTimeLeft] = useState([]);
	const [callReady, setCallReady] = useState(false);
	const [callTrial, setCallTrial] = useState(false);
	const [callData, setCallData] = useState([]);
	const [callLocked, setCallLocked] = useState(true);
	// Todo:Store Dropdown Data
	const [sourceLanguage, setSourceLanguage] = useState(9);
	const [sourceLanguageName, setSourceLanguageName] = useState("English");
	const [targetLanguage, setTargetLanguage] = useState(35);
	const [targetLanguageName, setTargetLanguageName] = useState("Spanish");
	const [serviceType, setServiceType] = useState(20); // 20 is for Education and 17 is for Customer Services
	const [serviceTypeName, setServiceTypeName] = useState("Education"); //useState("Customer Service");
	const [selectGender, setSelectGender] = useState(0);
	const [genderTypeName, setGenderTypeName] = useState("No preference");
	const [selectedSettings, setSelectedSettings] = useState({});
	const [transcriptionFlag, setTranscriptionFlag] = useState(true);
	const [basicAIFlag, setBasicAIFlag] = useState(false);
	const [selectedValue, setSelectedValue] = useState("");
	const [searchLang, setSearchLang] = useState("");
	const [langArr, setLangArr] = useState([]);
	const [filteredLangArr, setFilteredLangArr] = useState([]);

	const [currentSelectedValue, setCurrentSelectedValue] = useState(null);

	useEffect(() => {
		if (props?.selectedOption?.length) {
			setCurrentSelectedValue(props?.selectedOption);
		}
	}, [props?.selectedOption]);


	const getDict = async () => {
		isLoading(true);
		if (blObj != null) {
			let dic = await blObj.getCallDictionaries();
			let demoObj = {
				code: "demo",
				enabled:true,
				englishName:"Demo",
				id:1000,
				isSignLanguage:false,
				isVideoBackstopStaffed:false,
				localizedName:"Demo",
				name:"Demo - Demo",
				nativeName:"Demo",
				opiPolicyOrder:'null',
				priority:'null',
				vriPolicyOrder:'null'
			}
			let dicLangArr = [...dic?.languages,demoObj]
			setLangArr(dicLangArr);
			setFilteredLangArr(dicLangArr);
			setBoostlingoData(dic);
			if (dic != null && dic != undefined) {
				isLoading(false);
				setBoostlingoOk(true);
				setPropResult(dic.version);
			} else {
				isLoading(false);
			}
		}
	};

	useEffect(() => {
		getDict();
		runPageAccessibility();
	}, []);

	useEffect(() => {
		if (selectedValue?.length) {
			renderSelectedValue();
		}
	}, [selectedValue]);

	const renderSelectedValue = () => {
		if (selectedValue === "Source") {
			renderLangugageData();
		}
	};

	useEffect(() => {
		let obj = {
			sourceLangCode: sourceLanguage,
			sourceLangName: sourceLanguageName,
			targetLangCode: targetLanguage,
			targetLangName: targetLanguageName,
			serviceTypeCode: serviceType,
			serviceName: serviceTypeName,
			genderCode: selectGender,
		};
		setSelectedSettings(obj);
	}, [sourceLanguage, sourceLanguageName, targetLanguage, targetLanguageName, serviceType, selectGender]);

	const renderHumanAISelectionForm = () => {
		if (Object.keys(boostlingoData)?.length) {
			return renderSelectionFormData();
		}
	};

	const runPageAccessibility = () => {
		if (launchKey != null && sessionKey != null) {
			let paramObj = {
				feature: 'hi',
				launchKey: launchKey,
				launchSession: sessionKey,
			};
			accessibilityPageEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						// Load the Page only when True
						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
						setTimeLeft(res?.data[0]?.timeLeft);
						setCallReady(res?.data[0]?.ready);
					} else {
						// Generate Error -> this feature is not enabled to back to DynamicHome Page
					}
				})
				.catch(err => {
					isLoading(false);
				});
		}
	};

	const renderSelectionFormData = () => {
		return (
			<Box p="2" borderWidth={"1px"} borderRadius={"10px"} w="100%" bg="#fff" color={'black'}>
				<Flex
					w="100%"
					mb="4"
					alignItems={"center"}
					borderWidth={"1px"}
					borderColor={'gray.200'} 
					borderRadius={"10px"}
					justifyContent={"space-between"}
					p="2">
					<FormLabel mb="0px" w="25%">
						Source Language{" "}
					</FormLabel>
					<Text w="50%" borderWidth="1px" bg="#e2e8f0" p="2" borderRadius={"10px"} mr="3">
						{sourceLanguageName}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Source");
						}}>
						...
					</Button>
				</Flex>
				<Flex
					w="100%"
					mb="4"
					p="2"
					alignItems={"center"}
					borderWidth={"1px"}
					borderColor={'gray.200'} 
					borderRadius={"10px"}
					justifyContent={"space-between"}>
					<FormLabel w="25%" mb="0px">
						Target Language{" "}
					</FormLabel>
					<Text w="50%" borderWidth="1px" bg="#e2e8f0" p="2" borderRadius={"10px"} mr="3">
						{targetLanguageName}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Target");
						}}>
						...
					</Button>
				</Flex>
				<Flex
					w="100%"
					mb="4"
					p="2"
					alignItems={"center"}
					borderWidth={"1px"}
					borderColor={'gray.200'} 
					borderRadius={"10px"}
					justifyContent={"space-between"}>
					<FormLabel w="25%" mb="0px">
						Service Type{" "}
					</FormLabel>
					<Text w="50%" borderWidth="1px" bg="#e2e8f0" p="2" borderRadius={"10px"} mr="3">
						{serviceTypeName}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Service");
						}}>
						...
					</Button>
				</Flex>
				<Flex
					w="100%"
					mb="4"
					p="2"
					alignItems={"center"}
					borderColor={'gray.200'} 
					borderWidth={"1px"}
					borderRadius={"10px"}
					justifyContent={"space-between"}>
					<FormLabel w="25%">Select Gender</FormLabel>

					{renderGenderData()}
					{/* </Select> */}
				</Flex>
				<Flex justifyContent={"end"}>
					{currentSelectedValue === "ai" ? (
						<Button
							type="button"
							bg="blue.200"
							borderRadius={"10px"}
							onClick={() => {
								setBasicAIFlag(true);
								setTranscriptionFlag(false);
							}}
							mr="2">
							<Icon as={AiOutlineRobot} mr={1} />
							Secure AI Interpretation
						</Button>
					) : null}
					{currentSelectedValue === "human" ? (
						<Button
							size={{
								base: "sm",
								md: "sm",
								lg: "sm",
								xl: "sm",
							}}
							colorScheme="green"
							borderRadius={"10px"}
							onClick={() => {
								setBasicAIFlag(false);
								setTranscriptionFlag(false);
							}}
							mr="2">
							<Icon as={AiOutlineUserSwitch} mr={1} />
							Start Human Interpretation
						</Button>
					) : null}
					<Button
						size={{
							base: "sm",
							md: "sm",
							lg: "sm",
							xl: "sm",
						}}
						colorScheme="red"
						borderRadius={"10px"}
						onClick={() => {
							navigate(-1);
						}}>
						Back
					</Button>
				</Flex>
			</Box>
		);
	};

	const renderLangugageData = () => {
		return langArr?.map((data, index) => (
			<Text
				key={data?.codef}
				value={data?.id}
				w="31%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				m="1%"
				onClick={() => {
					setSourceLanguage(data.id);
					setSourceLanguageName(data?.englishName);
				}}
				bg={data?.id === sourceLanguage ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.englishName}
			</Text>
		));
	};

	const renderToLanguage = () => {
		return langArr?.map((data, index) => (
			<Text
				value={data?.id}
				w="31%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				m="1%"
				onClick={() => {
					setTargetLanguage(data.id);
					setTargetLanguageName(data?.englishName);
				}}
				bg={data?.id === targetLanguage ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.englishName}
			</Text>
		));
	};

	const renderServiceDropDown = () => {
		return boostlingoData?.serviceTypes?.map((data, index) => (
			<Text
				key={data?.id}
				value={data?.id}
				w="31%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				m="1%"
				onClick={() => {
					setServiceType(data.id);
					setServiceTypeName(data?.code);
				}}
				bg={data?.id === serviceType ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.code}
			</Text>
		));
	};

	const renderGenderData = () => {
		return boostlingoData?.genders?.map((data, index) => (
			<Text
				key={data?.name}
				value={data?.id}
				w="30%"
				borderWidth="1px"
				p="2"
				borderRadius={"10px"}
				mr="3"
				onClick={() => {
					setSelectGender(data.id);
					setGenderTypeName(data?.name);
				}}
				bg={data?.id === selectGender ? "green.200" : "#e2e8f0"}
				cursor={"pointer"}>
				{data?.name}
			</Text>
		));
	};

	// Todo:Search Language

	useEffect(() => {
		filterLanguageData(searchLang);
	}, [searchLang]);

	const filterLanguageData = () => {
		const clonedLangArr = [...filteredLangArr];
		const filteredData = clonedLangArr.filter(lang => {
			return lang.englishName.toLowerCase().includes(searchLang.toLowerCase());
		});
		setLangArr(filteredData);
	};

	// Todo:Handle Close
	const handlerCloser = () => {
		onClose();
		setLangArr(filteredLangArr);
		setSearchLang("");
	};

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="70vh" w="100%" color="#fff">
					<Spinner size="xl" color="#000" />
				</Flex>
			) : (
				<Box w={"100%"} h="100%" overflow={"auto"}>
					{launchKey != null && sessionKey != null && boostlingoOk ? (
						<>
							<Flex w="100%" h="100%" flexWrap={"wrap"}>
								{transcriptionFlag ? (
									<Flex
										w={{
											base: "100%",
											md: "100%",
											lg: "50%",
										}}
										p="10px"
										mx="auto"
										alignItems={"center"}
										justifyContent={"center"}>
										{renderHumanAISelectionForm()}
									</Flex>
								) : (
									<Box w="100%" borderLeftWidth={"0px"}>
										<BLCommunicate
												blObj={blObj}
												launchKey={launchKey}
												sessionKey={sessionKey}
												callTrialVal={callTrial}
												callDataVal={callData}
												callReadyVal={callReady}
												selectedSettings={selectedSettings}
											/>
									</Box>
								)}
							</Flex>
						</>
					) : (
						<Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
							<Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
								Hmm....{" "}
								<Text as="spam" fontWeight={"600"} mr="1">
									Something is not right!!
								</Text>
							</Heading>
							<Text mt="3" fontSize={"17px"} color="gray.700">
								Looks like, there is some issues with Human Translation...
							</Text>
						</Flex>
					)}
				</Box>
			)}
			{/* Drawer Ui */}
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					handlerCloser();
				}}
				size="md">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						Select {selectedValue} {selectedValue === "Service" ? "Type" : "Language"}{" "}
					</DrawerHeader>
					{/* Seach Bar */}
					{selectedValue === "Source" || selectedValue === "Target" ? (
						<Stack spacing={4} mb="4" w="95%" mx="auto">
							<InputGroup>
								<InputLeftElement pointerEvents="none">
									<AiOutlineSearch color="gray.300" />
								</InputLeftElement>
								<Input
									type="text"
									placeholder="Search Language"
									onChange={e => {
										setSearchLang(e.target.value);
									}}
								/>
							</InputGroup>
						</Stack>
					) : null}
					<DrawerBody>
						<Flex flexWrap={"wrap"}>
							{selectedValue === "Source"
								? renderLangugageData()
								: selectedValue === "Target"
									? renderToLanguage()
									: renderServiceDropDown()}
						</Flex>
					</DrawerBody>

					<DrawerFooter>
						<Button
							variant="outline"
							size="sm"
							borderRadius="10px"
							mr={3}
							onClick={() => {
								handlerCloser();
							}}>
							Cancel
						</Button>
						<Button
							colorScheme="green"
							size="sm"
							borderRadius="10px"
							onClick={() => {
								handlerCloser();
							}}>
							Save
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default BoostLingoSystem;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Text, Spacer, Flex, Box, Spinner } from "@chakra-ui/react";

// Api Call Imports
import { getSubDbClientLinkCount, getSubDbClientEventsCount } from "../../../services/accountServices";

// Component Imports
import LinkMetrics from "./component/LinkMetrics";
import SubscriberEventMetrics from "./component/SubscriberEventMetrics";
import { usePillButtonBg, usePillButtonBgSelected, useTextColor } from "../../../theme/globalColorTheme";

function SubDashboardTab() {
	const boostlingoExp = process.env.REACT_APP_BOOSTBOOST_EXP;
	const textColor = useTextColor();
	const pillButtonBg = usePillButtonBg;
	const [loading, isLoading] = useState(true);
	const [eventLoading, isEventLoading] = useState(true);
	const [durationValue, setDurationValue] = useState("all");
	const [linkStats, setLinkStats] = useState([]);
	const [eventStats, setEventStats] = useState([]);
	const pillButtonBgSelected = usePillButtonBgSelected;
	const [currentSelection, setCurrentSelection] = useState("links");

	//   Todo:Fetch all projects
	useEffect(() => {
		collectSubLinkCount();
		collectEventsCount();
	}, [durationValue]);

	const collectSubLinkCount = () => {
		isLoading(true);
		let paramObj = {
			duration: durationValue,
			key:"location"
		};
		getSubDbClientLinkCount(paramObj)
			.then(res => {
				if (res?.data?.length > 0) {
					setLinkStats(res?.data);
					isLoading(false);
				} else {
					setLinkStats([]);
					isLoading(false);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	const collectEventsCount = () => {
		isEventLoading(true);
		let paramObj = {
			duration: durationValue,
		};
		getSubDbClientEventsCount(paramObj)
			.then(res => {
				if (res?.data?.length > 0) {
					setEventStats(res?.data);
					isEventLoading(false);
				} else {
					setEventStats([]);
					isEventLoading(false);
				}
			})
			.catch(err => {
				isEventLoading(false);
			});
	};

	const showExpDate = () => {
		let result = "error";
		if (boostlingoExp != null && boostlingoExp != undefined) {
			var expDate = JSON.parse(boostlingoExp);
			if (moment(expDate.expiresAt).isValid()) {
				result = moment(expDate.expiresAt).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
			}
		}
		return result;
	};

	const handleDurationType = type => {
		if (type?.length) {
			setDurationValue(type);
		}
	};

	return (
		<Box>
			<Flex w={"100%"} direction={"column"}>
				<Flex w={"100%"} py="1" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
					<Flex w={"100%"} direction={"column"}>
						<Text fontWeight={"500"} ml={1} fontSize={"18px"}>
							Dashboard
						</Text>
						<Text fontSize={"xs"} ml={1}>
							{showExpDate()}
						</Text>
					</Flex>
					<Spacer />
					<Flex minW={"100px"} maxW={"100px"} bg={pillButtonBg} rounded={"md"} p={1} pl={2}>
						<Text
							_hover={{ cursor: "pointer" }}
							onClick={() => {
								collectSubLinkCount();
								setCurrentSelection("links");
							}}
							p={2}
							rounded={"md"}
							color={currentSelection === "links" ? "white" : textColor}
							bg={currentSelection === "links" ? pillButtonBgSelected : pillButtonBg}
						>
							LingoLink
						</Text>
						{/* <Text
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => {
                                setCurrentSelection('workflows');
                                collectEventsCount();
                            }}
                            color={currentSelection === 'workflows' ? 'white' : textColor}
                            p={2} roundedRight={'md'} bg={currentSelection === 'workflows' ? pillButtonBgSelected : pillButtonBg} pl={5} pr={5}>
                                Document AI
                        </Text> */}
					</Flex>
				</Flex>
			</Flex>
			{currentSelection === "links" ? (
				<LinkMetrics 
					linkStats={linkStats} 
					eventStats={eventStats} 
					sendDurationType={handleDurationType} 
					loadingValue={loading || eventLoading ? true : false}/>
			) : currentSelection === "workflows" ? (
				<SubscriberEventMetrics 
					eventStats={eventStats} />
			) : null}
		</Box>
	);
}

export default SubDashboardTab;

import moment from "moment";
import Card from "../../../../components/card/Card";
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useGradientColor } from "../../../../theme/globalColorTheme";

export default function Banner(props) {
	const {
		banner,
		avatar,
		name,
		job,
		email,
		department,
		join_date,
		phone,
		social_about,
		posts,
		followers,
		following,
		...rest
	} = props;

	const gradientColor = useGradientColor();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const textColorSecondary = "gray.400";
	const borderColor = useColorModeValue("white !important", "#111C44 !important");

	return (
		<Card mb={{ base: "0px", lg: "20px" }} alignItems="center" {...rest}>
			<Box bgGradient={gradientColor} bgSize="cover" borderRadius="16px" h="131px" w="100%" />
			<Avatar mx="auto" src={avatar} h="87px" w="87px" mt="-43px" border="4px solid" borderColor={borderColor} />
			<Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
				{name}
			</Text>
			<Text color={textColorSecondary} fontSize="sm">
				Role: <b>{job}</b>
				{department != null ? " - (Department: " + department + " )" : ""}
			</Text>
			<Text color={textColorSecondary} fontSize="md">
				{email}
			</Text>
			<Text color={textColorSecondary} fontSize="sm">
				{phone}
			</Text>
			<Text color={textColorSecondary} fontSize="sm">
				Date Joined: <b>{moment(join_date).format("MMM DD, YYYY HH:mm")}</b>
			</Text>
			{social_about != null ? (
				<Box m={5} border={"gray.100"}>
					<Text fontSize={"sm"}>{social_about}</Text>
				</Box>
			) : null}
			<Flex w="max-content" mx="auto" mt="26px"></Flex>
		</Card>
	);
}

import React, { useState } from 'react'
// Chakra Imports
import {
    Button,
    Icon,
    Flex,
    IconButton
} from "@chakra-ui/react";

import { FaFemale } from "react-icons/fa";
import { FaMale } from "react-icons/fa";
import { AiOutlineAudio } from "react-icons/ai";
import { AiOutlineAudioMuted } from "react-icons/ai";
// Component Imports
import AmazonPolyTranslateList from "../../../../utils/AmazonPolyTanslateList"
import { useEffect } from 'react';

function BasicAITranslateSelector({ targetLanguage, returnSelectedAudioType }) {
    const [textSpeech, setTextSpeech] = useState(true)
    const [voiceButtons, setVoiceButtons] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState('')


    useEffect(() => {
        if (targetLanguage?.length) {
            renderAssignableTextToSpeech(targetLanguage)
        }
    }, [targetLanguage])


    useEffect(() => {
        renderAssignableTextToSpeech(targetLanguage)
        returnSelectedAudioType(selectedVoice)
    }, [selectedVoice])

    useEffect(() => {
        if (textSpeech) {
            setSelectedVoice('')
        }
    }, [textSpeech])

    const renderAssignableTextToSpeech = (targetValue) => {
        const value = AmazonPolyTranslateList?.filter((data) => {
            return data && data.Language === targetValue;
        });

        const buttons = value?.[0]?.Voices?.map((data) => (
            <Icon bg={selectedVoice === data?.Name ? 'green.200' : "#fff"}
                color={selectedVoice === data?.Name ? '#fff' : "green.200"}
                p="2px"
                borderRadius={'5px'}
                h="30px"
                w="30px"
                mr="2" as={data?.Gender === "Female" ? FaFemale : FaMale} fontSize="20px" size="sm"
                key={data?.Name}
                onClick={() => { setSelectedVoice(data?.Name) }}
                cursor="pointer"
            />
        ));
        setVoiceButtons(buttons);
    };

    return (
        <>
            <Flex alignItems={'end'} px="10px">
                {textSpeech ? (
                    <IconButton
                        color="green"
                        onClick={() => { setTextSpeech(!textSpeech) }}
                        icon={<AiOutlineAudio />}
                        bg="#fff"
                        borderRadius={'5px'}
                        h="30px"
                        w="30px"
                        mr="2"
                        fontWeight="600"
                        fontSize={'26px'}
                    />
                ) : (
                    <IconButton
                        color="red"
                        onClick={() => { setTextSpeech(!textSpeech) }}
                        icon={<AiOutlineAudioMuted />}
                        bg="#fff"
                        borderRadius={'5px'}
                        h="30px"
                        w="30px"
                        mr="2"
                        fontWeight="600"
                        fontSize={'26px'}
                    />)}
                {textSpeech ? voiceButtons : null}
            </Flex>
        </>
    )
}

export default BasicAITranslateSelector
import {
    Box,
    Flex,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import AddUpdateCompany from '../../components/AddUpdateCompany'
import AddUpdateApplication from '../../components/AddUpdateApplication'
import { getAilabUserProfile } from '../../services/profileServices'
import { useParams } from 'react-router-dom'

const steps = [
    { title: 'Company details', description: 'Add company details', isStepDone: false },
    { title: 'Second', description: 'Date & Time', isStepDone: false },
]

const StepContent = React.memo((props) => {
    switch (props.step) {
        case 0:
            return <AddUpdateCompany {...props}/>
        case 1:
            return <AddUpdateApplication {...props}/>
        case 2:
            return <Box>Content for Select Rooms</Box>
        default:
            return null
    }
})

const AddProposal = () => {
    const [validSteps, setValidSteps] = useState(steps)
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })
    const { application_id } = useParams();
    const [companyUuid, setCompanyUuid] = useState(null)
    const [userUuid, setUserUuid] = useState(null)

    const handleStepChange = (index) => {
        if(index>activeStep){
            if (validSteps[activeStep].isStepDone) {
                setActiveStep(index)
            }
        }else{
            setActiveStep(index)
        }
    }

    const getAilabUserDetails = () => {
        const paramObj = {
            key:""
        }
        getAilabUserProfile(paramObj).then(resp=>{
            if(resp?.data[0]?.status?.company_id){
                setCompanyUuid(resp?.data[0]?.status?.company_id)
                setUserUuid(resp?.data[0]?.status?.user_uuid)
            }else{
                setCompanyUuid(null)
            }
        })
    }


    const renderStepContent = useCallback((step, setValidSteps, setActiveStep, companyUuid, userUuid,application_id) => {
        return <StepContent step={step} setValidSteps={setValidSteps} setActiveStep={setActiveStep} companyUuid={companyUuid} userUuid={userUuid} application_id={application_id}/>
    }, [])

    useEffect(()=>{
        getAilabUserDetails()
    }, [])

    return (
        <Flex pt={{ sm: '125px', lg: '90px' }} pl={3} alignItems="flex-start" bg="#fff">
            <Box w="20%">
                <Stepper index={activeStep} orientation='vertical' size="lg" height="60vh" position="fixed">
                    {validSteps.map((step, index) => (
                        <Step key={index} onClick={() => handleStepChange(index)}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink='0'>
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>
                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box ml='14' flex={1}>
                {renderStepContent(activeStep, setValidSteps, setActiveStep, companyUuid, userUuid, application_id)}
            </Box>
        </Flex>
    )
}

export default AddProposal

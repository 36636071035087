import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    Avatar,
    Image,
    Heading,
    Flex,
    Text,
    useColorModeValue,
    Input,
    Spinner,
    Spacer,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Select,
    Tbody,
    Td
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Imports
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import Card from "../../../components/card/Card";
import { setParentLogo } from "../../../store/actions/topNotificationActions";

// Api Call Imports
import { fetchAccAdminEventsForSub, fetchAccLocInfo, fetchSubPersonalInfo } from "../../../services/accountServices";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useGreenBg, useRedBg, useNoDataBandBg, useTextColor } from "../../../theme/globalColorTheme";
import moment from "moment";
import { format } from "date-fns";
import Pagination from "../../../components/pagination/Pagination";

const SubscriberEvents = (props) => {
    const dispatch = useDispatch()
    const [loading, isLoading] = useState(false);
    const brandBg = useColorModeValue("blue.50", "navy.900");
    const buttonTextColor = useColorModeValue("navy.700", "gray.700");


    const [accountUuid, setAccountUuid] = useState(null);
    const [locationUuid, setLocationUuid] = useState(null);
    const [subscriberUuid, setSubscriberUuid] = useState(null);
    const [accountInfo, setAccountInfo] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);
    const [subscriberInfo, setSubscriberInfo] = useState([]);
    const [subAppDetails,setSubAppDetails] = useState([]);
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    const [eventsList, setEventsList] = useState([]);
    const [cloneEventList, setCloneEventList] = useState([])
    const [eventLoader, setEventLoader] = useState(false)
    const [eventValue, setEventValue] = useState("");
    const [tableHeaders, setTableHeaders] = useState({ Email: [], Route: [], Status: [], Date: [] })
    const [selectedDates, setSelectedDates] = useState([new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()])
    const [emailColFilter, setEmailColFilter] = useState('')
    const [routeColFilter, setRouteColFilter] = useState('')
	const [statusColFilter, setStatusColFilter] = useState('')
    const [filteredEventsData, setFilteredEventsData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(10)
    const blackWhiteColor = useTextColor()



    const redBg = useRedBg()
    const greenBg = useGreenBg()

    useEffect(()=>{
        // collectSubscriberInfo();
        handleGetEvents()
    },[])

    //Fetch Parent Account and Location details
    useEffect(() => {
        collectAccountLocationInfo();
    }, [accountUuid]);

    const collectAccountLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'subscriber',
        };
        fetchAccLocInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.accUuid);
                    setLocationUuid(res?.data[0]?.locUuid);
                    setSubscriberUuid(res?.data[0]?.subUuid);
                    setAccountInfo(res?.data[0]?.accInfo);
                    setLocationInfo(res?.data[0]?.locInfo);
                    setSubscriberInfo(res?.data[0]?.subInfo);
                    dispatch(setParentLogo(res?.data[0]?.accInfo[0]?.avatar));
                } else {
                    isLoading(false);
                    setAccountInfo([]);
                    setLocationInfo([]);
                    dispatch(setParentLogo(''));
                }
            })
            .catch(err => {
                isLoading(false);
                setAccountInfo([]);
                setLocationInfo([]);
                dispatch(setParentLogo(''));
            });
    };
    
    // const collectSubscriberInfo = () => {
	// 	isLoading(true);
	// 	let paramObj = {
	// 		key: "subscriber",
	// 	};
	// 	fetchSubPersonalInfo(paramObj)
	// 		.then(res => {
	// 			isLoading(false);
	// 			if (res?.data[0]?.status) {
	// 				isLoading(false);
	// 				setSubAppDetails(res?.data[0]?.subInfo);
	// 			} else {
	// 				isLoading(false);
	// 				setSubAppDetails([]);
	// 			}
	// 		})
	// 		.catch(err => {
	// 			isLoading(false);
	// 			setSubAppDetails([]);
	// 		});
	// };

    const handleGetEvents = () => {
        setEventLoader(true);
        const paramObj = {
            "start_date": format(new Date(selectedDates[0]),'yyyy-MM-dd'),
            "end_date": format(new Date(selectedDates[1]),'yyyy-MM-dd'),
            "page": currentPage,
            "records_per_page": recordsPerPage
        }
        fetchAccAdminEventsForSub(paramObj).then(res=>{
            setEventLoader(false);
            if(res?.data[0]?.records){
                setEmailColFilter('')
                setStatusColFilter('')
                setRouteColFilter('')
                setEventsList(res?.data[0]?.records);
                setCloneEventList(res?.data[0]?.records)
                setTotalPages(res?.data[0]?.total_pages)
            }else{
                setEventsList([]);
                setCloneEventList([]);
            }
            setEventLoader(false)
        }).catch(err=>{
            setEventLoader(false)
            setEventsList([]);
            setCloneEventList([])})
    }

    const renderAccountInfo = accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex key={index} w={'100%'} rounded={'md'} h={'100%'} align={'center'} p={2}>
                <Flex w={'150px'} direction={'column'}>
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                </Flex>
                <Flex w={'150px'} direction={'column'}>
                    <Text w={'150px'} bg='blue.100' p={1} rounded={'md'} as="span" ml="3" color="black">{accountData?.type}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'} align={'top'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={'150px'} direction={'column'}>
                    <Box minW={"100px"}>
                        {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={'100%'} bg={brandBg} p={1} h={'100%'}>
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                    Unable to collect Account and Location Info
                </Heading>
            </Flex>
        </Flex>
    );


    const renderLocationInfo = locationInfo !== null && locationInfo?.length > 0 ? (
        locationInfo?.map((item, index) => (
            <Box mt="1" textAlign={'center'}>
                <Box minW={"100px"} maxW={'100px'} mx="auto" align='center'>
                    <Avatar w={'80px'} h={'80px'} src={item?.link_location_avatar?.length ? item?.link_location_avatar : null} />
                </Box>
            <Flex
                w={"100%"}
                key={index}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}
            >
                <Flex
                    rounded={"md"}
                    px={1}
                    w={"100%"}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
                            <Text fontWeight={'bold'}>
                                {item?.link_location_title}
                            </Text>
                            <Text w="100%">{item?.link_location_name}</Text>
                            <Text w="100%" fontSize={'sm'}>{item?.link_location_title}</Text>
                        </Flex>
                        <Flex minW={"100px"} p={1} ml="2" pl="2" direction={"column"} flexWrap={'wrap'}>
                            <Text >
                                {item?.link_location_address1}
                            </Text>
                            <Text w="100%">{item?.link_location_address2}</Text>
                            <Box >

                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            </Box>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                No location info collected.
            </Heading>
        </Flex>
    );

    const renderSubscriberInfo = subscriberInfo !== null && subscriberInfo?.length > 0 ? (
        subscriberInfo?.map((item, index) => (
            <Flex
                w={"100%"}
                key={index}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}>
                <Flex
                    align={'center'}
                    direction={'column'}
                    rounded={"md"}
                    px={1}
                    w={"100%"}
                >
                    <Avatar mx="auto" src={item?.avatar} h="87px" w="87px" border="1px solid" />
                    <Text fontWeight="bold" fontSize="xl">
                        {item?.name}
                    </Text>
                    <Text fontSize="md">
                        {item?.email}
                    </Text>
                    <Text fontSize="sm">
                        {item?.phone}
                    </Text>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                No subscriber info collected.
            </Heading>
        </Flex>
    );

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};
    

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="100px" w="49%" mb="3">
                <Image cursor={'pointer'}  key={index} mr="2" borderRadius="10px" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }




    const handleUpdateFiltereEventdData = () => {
        const filteredEventData = eventsList.filter(
            event => (
                (emailColFilter === '' || event?.event_caller.includes(emailColFilter))
                && (routeColFilter === '' || event?.event_route.includes(routeColFilter))
                && (statusColFilter === '' || String(event?.event_status) === statusColFilter)))
        setFilteredEventsData(
            filteredEventData
        )
    }

    const handleUpdateColumnfilterOptions = () => {
		const newUniqueRouteTypes = new Set();
		const newUniqueEmails = new Set()
		const newUniqueStatus = new Set()

		filteredEventsData?.forEach(eventItem => {
			newUniqueRouteTypes.add(eventItem?.event_route);
			newUniqueEmails.add(eventItem?.event_caller)
			if (!eventItem.event_status) {
				newUniqueStatus.add('Fail')
			} else {
				newUniqueStatus.add('Success')
			}
		});
		setTableHeaders({ Email: [...newUniqueEmails], Route: [...newUniqueRouteTypes], Status: [...newUniqueStatus], Date: [] })
	}

    const handleColumnFilterChange = (value, col) => {
		switch (col) {
			case "Email":
				setEmailColFilter(value)
				break;
			case "Route":
				setRouteColFilter(value)
				break
			case "Status":
				setStatusColFilter(value)
				break
			default:
				break;
		}
	}

    const renderTableFilter = (placeholder, options) => {
		if(placeholder === "Date"){
			return(<RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />)
		}
		if (options.length) {
			if (placeholder === 'Status') {
				return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
					{options?.map(option => <option key={option} value={option === 'Success'}>{option}</option>)}
				</Select>)
			}
			return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
				{options?.map(option => <option key={option} value={option}>{option}</option>)}
			</Select>)
		}
	}

    const RenderEventsList = memo(() =>{
        return (
            filteredEventsData !== null && filteredEventsData?.length > 0 ? (
                filteredEventsData?.map((eventItem, index) => (
                    <Tr key={index + eventItem?.datetime_created}>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
                        <Td color='black' bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
                    </Tr>
                ))
            ) : (
                null
            )
        )
    })

    useEffect(() => {
        if (eventValue?.length) {
            const filteredEvents = eventsList.filter(event => {
                return Object.values(event).some(value =>
                    String(value).toLowerCase().includes(eventValue.toLowerCase())
                );
            });
            setCloneEventList(filteredEvents)
        }
        else {
            setCloneEventList(eventsList)
        }
    }, [eventValue])

    
    useEffect(() => {
		handleUpdateColumnfilterOptions()
	}, [filteredEventsData])

	useEffect(() => {
		handleUpdateFiltereEventdData()
	}, [emailColFilter, statusColFilter, routeColFilter, eventsList])



    useEffect(() => {
        if(selectedDates.length>1){

            handleGetEvents();
        }
    }, [currentPage, selectedDates]);

    useEffect(()=>{
        setCurrentPage(1)
        handleGetEvents()
    },[recordsPerPage])

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
           <Card w={"100%"} bg={brandBg} rounded={"lg"} mt={1} p={1} h={'80px'}>
                {renderAccountInfo}
            </Card>
            <Card w={'100%'} mt={1} p={1} rounded={"lg"}>
                <Flex h={'calc(100vh - 210px)'}>
                    <Box
                        // w={!configurationCollapseFlag ? "95%" : "95%"}
                        w={'100%'}
                        bg={brandBg}
                        p={2}
                        borderRadius={"10px"}
                        overflowY="scroll"
                    >
                    <Flex mb="3" alignItems="center" justifyContent={'space-between'}>
                            <Text fontWeight={'bold'} ml={1} >Events</Text>
                            <Spacer />
                            <Input w="40%" placeholder='Search...' value={eventValue} onChange={(e) => { setEventValue(e.target.value) }} color={blackWhiteColor}/>
                            <Button ml={2} bg={'blue.100'} color={buttonTextColor}
                                onClick={() => handleGetEvents()}
                                >Reload 
                            </Button>                            
                        </Flex>
                        {eventLoader ? (
                            <Flex alignItems="center" justifyContent="center" h="50vh">
                                <Spinner size="lg" />
                            </Flex>
                        ) :
                            cloneEventList?.length ? (
                                <TableContainer border="1px solid #d7d7d7" borderRadius="5px">
                                    <Table variant="simple">
                                        <Thead>
                                        <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
															{Object.keys(tableHeaders).map(header => (
																<Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
																	{header}
																</Th>
															))}
														</Tr>
														<Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
															{Object.entries(tableHeaders).map(([header, options]) => (
																<Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
																	{renderTableFilter(header, options)}
																</Th>
															))}
														</Tr>
                                        </Thead>
                                        <Tbody>
                                            <RenderEventsList />
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            ) : <Flex w={"100%"} p={5} bg={useNoDataBandBg} borderLeftWidth={"10px"}
                                borderLeftColor={"red.500"}>
                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                    No Event Data based on selection..
                                </Heading>
                            </Flex>}
                    </Box>


                </Flex>
            </Card>
            {cloneEventList?.length ? (<Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage}/>) : null}

        </Flex>
    );
}

export default SubscriberEvents;

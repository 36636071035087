import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'


const Home = () => {
    const navigate = useNavigate()
  return (
    <Flex pt={{ sm: '125px', lg: '75px' }} pl={3} alignItems="flex-end">
        <Box position="relative">
        <Heading as="h1" fontSize="5xl" mt="14">Help us get to know you and your company by answering a few questions.</Heading>
        <Box bg="green.300" color="white" p="6" borderRadius="50%" mb="-7" position="absolute" bottom={{sm:'-16', lg:"-6"}} right="60" onClick={()=>navigate("/ailab/applicant/proposal/create")}>
            <ArrowForwardIcon fontSize="4xl"/>
        </Box>
        </Box>
        
    </Flex>
  )
}

export default Home
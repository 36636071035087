import {
	Avatar,
	Button,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	VStack,
	Badge,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Switch,
	useToast,
	useColorModeValue,
} from "@chakra-ui/react";
import { SearchBar } from "../../../../components/navbar/searchBar/SearchBar";
import React, { useMemo, useState } from "react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { changeAssociateStatus, updateAssociateLevel, resetResentPwdCode } from "../../../../services/businessServices";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import moment from "moment";
import { useCustomColorModeValues } from "../../../../utils/ColorMode";

function EditableAssociatesList(props) {
	const { columnsData, tableData } = props;
	const toast = useToast();
	const [businessUserData, setBusinessUserData] = useState(tableData);

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => businessUserData, [businessUserData]);

	const tableInstance = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		gotoPage,
		pageCount,
		prepareRow,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		state,
	} = tableInstance;

	const createPages = count => {
		let arrPageCount = [];

		for (let i = 1; i <= count; i++) {
			arrPageCount.push(i);
		}

		return arrPageCount;
	};

	const { pageIndex, pageSize } = state;

	// Todo:Import custom color
	const { textColor, borderColor, brandColor, badgeBgRedColor, badgeBgBlueColor } = useCustomColorModeValues();

	const changeCurrentUserStatus = (uuId, status) => {
		const updatedArr = businessUserData.map(singleRowObj =>
			singleRowObj.user_uuid === uuId ? { ...singleRowObj, status: !status } : singleRowObj
		);
		setBusinessUserData(updatedArr);
		let objBody = {
			email: props.emailId,
			user_uuid: uuId, // Please add the select row UUID
			disabled: !status, // please add switch state
		};
		changeAssociateStatus(objBody)
			.then(res => {
				if (res.result) {
					toast({
						title: res?.message,
						status: "success",
						isClosable: true,
						duration: 3000,
					});
					props.onDataReceived();
				} else {
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			})
			.catch(err => {
				toast({
					title: err?.message,
					status: "error",
					isClosable: true,
					duration: 3000,
				});
			});
	};

	// API /v1/api/business/business_status' \
	// "email": "avkash@outlook.com",
	// "user_uuid": "44d7996e-617f-4c12-82dc-07b6e0bc0357",
	// "action": "up" (or down)

	const submitAdminRequest = (level, userUuid) => {
		let bodyObj = {
			email: props.emailId,
			user_uuid: userUuid,
			action: level,
		};
		updateAssociateLevel(bodyObj)
			.then(res => {
				if (res.result) {
					// isLoading(false);
					toast({
						title: res.message,
						status: "success",
						isClosable: true,
						duration: 3000,
					});
					// navigate('/business/teammanage');
					props.onDataUpdate();
				} else {
					// isLoading(false);
					toast({
						title: res.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			})
			.catch(err => {
				// isLoading(false);
			});
	};

	const resendCodeResetPassword = (userUuid) => {
		let bodyObj = {
			user_uuid: userUuid,
		};
		resetResentPwdCode(bodyObj)
			.then(res => {
				if (res.result) {
					toast({
						title: res.message,
						status: "success",
						isClosable: true,
						duration: 3000,
					});
					props.onDataUpdate();
				} else {
					toast({
						title: res.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			})
			.catch(err => {
				// isLoading(false);
			});
	};

	return (
		<>
			<Flex direction="column" w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
				<Flex
					align={{ sm: "flex-start", lg: "flex-start" }}
					justify={{ sm: "flex-start", lg: "flex-start" }}
					w="100%"
					px="22px"
					mb="36px">
					<SearchBar
						onChange={e => setGlobalFilter(e.target.value)}
						h="44px"
						w={{ lg: "390px" }}
						borderRadius="16px"
					/>
				</Flex>
				<Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
					<Thead>
						{headerGroups.map((headerGroup, index) => (
							<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
								{headerGroup.headers.map((column, index) => (
									<Th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										pe="10px"
										key={index}
										borderColor={borderColor}>
										<Flex
											justify="space-between"
											align="center"
											fontSize={{ sm: "10px", lg: "12px" }}
											color="gray.400">
											{column.render("Header")}
										</Flex>
									</Th>
								))}
							</Tr>
						))}
					</Thead>
					<Tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							return (
								<Tr {...row.getRowProps()} key={index}>
									{row.cells.map((cell, subIndex) => {
										let data = "";
										if (cell.column.Header === "USER NAME") {
											data = (
												<Flex align="center">
													<Avatar 
														src={cell.value[1] ? cell.value[1] : 'null'} 
													h="60px" w="60px" me="10px" />
													<Flex direction={'column'}>
														<Text color={textColor} fontSize="md" fontWeight="500">
															{cell.value[0]}
														</Text>
														{tableData[index]?.type === "Administrator" ? (
															<Text
																p={2}
																rounded={'md'}
																color={textColor}
																bg={'red.200'}>
																{tableData[index]?.type}
															</Text>
														) : (
															<Text
																p={1}
																rounded={"md"}
																color={textColor}
																bg={'green.200'}>
																{" "}
																{tableData[index]?.type}
															</Text>
														)}
													</Flex>
												</Flex>
											);
										} else if (cell.column.Header === "EMAIL") {
											data = (
												<Flex w={'100%'} direction={'column'}>
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value}
														<br />
														{tableData[index]?.dummy ? " (Not Logged yet)" : null}
													</Text>
													{tableData[index]?.user_uuid != null
													?<Flex w={'100%'}>
														{tableData[index]?.dummy
														?<Button 
															onClick={() => resendCodeResetPassword(tableData[index]?.user_uuid)}
															mt={1} bg={'blue.200'} w={'200px'} size={'xs'}>Resend Login Code</Button>
														:<Button 
															onClick={() => resendCodeResetPassword(tableData[index]?.user_uuid)}
															mt={1} bg={'blue.100'} w={'200px'} size={'xs'}>Reset Password</Button>}
													</Flex>:null}
												</Flex>
											);
										} else if (cell.column.Header === "GIVEN ROLE") {
											data = (
												<Text color={textColor} fontSize="md" fontWeight="500">
													{cell.value}
												</Text>
											);
										} else if (cell.column.Header === "DISABLED STATUS") {
											data = (
												<Text color={textColor} fontSize="md" fontWeight="500">
													{tableData[index]?.user_uuid != null
													?<Switch
														onChange={() => {
															changeCurrentUserStatus(
																tableData[index]?.user_uuid,
																cell.value
															);
														}}
														isChecked={cell.value ? true : false}
														colorScheme="green"
													/>:<Text fontSize={'xs'} bg={'red.50'}>Not Accessible</Text>}
												</Text>
											);
										} else if (cell.column.Header === "JOIN DATE") {
											data = (
												<Text color={textColor} fontSize="md" fontWeight="500">
													{moment(cell.value).format("MMM DD,YYYY,HH:mm a")}
												</Text>
											);
										} else if (cell.column.Header === "USER TYPE") {
											data = (
												<Flex>
													{cell.value === "Administrator" ? (
														<Text
															p={2}
															rounded={'md'}
															color={textColor}
															bg={'red.200'}>
															{cell.value}
														</Text>
													) : (
														<Text
															p={1}
															rounded={"md"}
															color={textColor}
															bg={'green.200'}>
															{" "}
															{cell.value}
														</Text>
													)}
												</Flex>
											);
										} else if (cell.column.Header === "ACTIONS") {
											data = (
												<Text
													cursor="pointer"
													color={brandColor}
													textDecoration="underline"
													fontSize="md"
													fontWeight="500"
													id={cell.value}>
													Edit user
												</Text>
											);
										} else if (cell.column.Header === "ADMIN STATUS") {
											data = (
												<Flex w={'100%'}>
													{tableData[index]?.user_uuid != null
													?<Button
														size={"xs"}
														color={cell.value[0] === "up" ? 'gray.600': 'gray.800'}
														bg={cell.value[0] === "up" ? 'red.200': 'blue.200'}
														fontSize="sm"
														fontWeight="500"
														ms="auto"
														onClick={e => {
															submitAdminRequest(cell.value[0], cell.value[1]);
														}}>
														{cell.value[0] === "up" ? "Upgrade to Admin" : "Demote to Non-admin"}
													</Button>
													:<Text fontSize={'xs'} bg={'red.50'}>Not Accessible</Text>}
												</Flex>
											);
										}
										return (
											<Td
												{...cell.getCellProps()}
												key={index}
												fontSize={{ sm: "14px" }}
												minW={{ sm: "150px", md: "200px", lg: "auto" }}
												borderColor={borderColor}>
												{data}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
				<Flex
					direction={{ sm: "column", md: "row" }}
					justify="space-between"
					align="center"
					w="100%"
					px={{ md: "22px" }}>
					<Text fontSize="sm" color="gray.500" fontWeight="normal" mb={{ sm: "24px", md: "0px" }}>
						Showing {pageSize * pageIndex + 1} to{" "}
						{pageSize * (pageIndex + 1) <= tableData.length ? pageSize * (pageIndex + 1) : tableData.length}{" "}
						of {tableData.length} entries
					</Text>
					<Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
						<Button
							variant="no-effects"
							onClick={() => previousPage()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue("gray.200", "white")}
							display={pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"}
							_hover={{
								bg: "whiteAlpha.100",
								opacity: "0.7",
							}}>
							<Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
						</Button>
						{pageSize === 5 ? (
							<NumberInput
								max={pageCount - 1}
								min={1}
								w="75px"
								mx="6px"
								defaultValue="1"
								onChange={e => gotoPage(e)}>
								<NumberInputField />
								<NumberInputStepper>
									<NumberIncrementStepper onClick={() => nextPage()} />
									<NumberDecrementStepper onClick={() => previousPage()} />
								</NumberInputStepper>
							</NumberInput>
						) : (
							createPages(pageCount).map((pageNumber, index) => {
								return (
									<Button
										variant="no-effects"
										transition="all .5s ease"
										onClick={() => gotoPage(pageNumber - 1)}
										w="40px"
										h="40px"
										borderRadius="50%"
										bg={pageNumber === pageIndex + 1 ? brandColor : "transparent"}
										border={pageNumber === pageIndex + 1 ? "none" : "1px solid lightgray"}
										_hover={
											pageNumber === pageIndex + 1
												? {
														opacity: "0.7",
												  }
												: {
														bg: "whiteAlpha.100",
												  }
										}
										key={index}>
										<Text fontSize="sm" color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
											{pageNumber}
										</Text>
									</Button>
								);
							})
						)}
						<Button
							variant="no-effects"
							onClick={() => nextPage()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue("gray.200", "white")}
							display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
							_hover={{
								bg: "whiteAlpha.100",
								opacity: "0.7",
							}}>
							<Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
						</Button>
					</Stack>
				</Flex>
			</Flex>
		</>
	);
}

export default EditableAssociatesList;

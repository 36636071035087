import React, { useState, useEffect } from 'react'

// Chakra Import
import {
    Box,
    Flex,
    Tab,
    Text,
    Icon,
    Image,
    Tabs,
    Button,
    Switch,
    Drawer,
    TabList,
    TabPanels,
    TabPanel,
    useToast,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
    DrawerCloseButton,
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";
import { AiFillCheckSquare, AiOutlineCheckSquare, } from "react-icons/ai";

// Component Imports
import { toastFunctionToaster } from '../../../utils/toastFunction';
import SubscriberLinkDesign from './configuration/SubscriberLinkDesign.js';
import { useGreenButton, useBrandBg, useRedBg } from '../../../theme/globalColorTheme';

// Api Services
import { setupSubscriberLinkDesign, getLinkSubscriberDesign } from '../../../services/accountServices.js';

function LinkDesignWidget(props) {
    const toast = useToast()
    const btnRef = React.useRef()
    const brandBg = useBrandBg()
    const redBg = useRedBg()
    const greenButtonBg = useGreenButton();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const linkInfo = props?.linkItem;
    const accountId = props?.accountId;
    const locationId = props?.locationId;

    const linkUuid = linkInfo?.uuid ? linkInfo?.uuid : null;
    const linkKey = linkInfo?.key ? linkInfo?.key : null;

    const [linkDesignUuid, setLinkDesignUuid] = useState(null);
    const [linkLogo, setLinkLogo] = useState(null);
    const [linkBackground, setLinkBackground] = useState(null);
    const [linkEnabled, setLinkEnabled] = useState(false);


    useEffect(() => {
        if (isOpen) {
            collectLinkConfiguration();
        }
    }, [isOpen]);

    const collectLinkConfiguration = () => {
        if (linkUuid != null && linkKey != null) {
            let paramObj = {
                link_account_uuid: accountId,
                link_location_uuid: locationId,
                linkUuid: linkUuid,
                linkKey: linkKey,
            };
            getLinkSubscriberDesign(paramObj)
                .then((res) => {
                    if (res?.data?.length && res?.result) {
                        setLinkDesignUuid(res?.data[0]?.design_uuid);
                        setLinkEnabled(res?.data[0]?.design_enabled);
                        if (res?.data[0]?.link_logo) {
                            setLinkLogo(res?.data[0]?.link_logo);
                        }
                        if (res?.data[0]?.link_background) {
                            setLinkBackground(res?.data[0]?.link_background);
                        }
                    }
                })
                .catch((err) => {

                });
        }
    };

    const manageDesignStatus = (status) => {
        if (linkDesignUuid != null) {
            let objBody = {
                link_account_uuid: accountId,
                link_location_uuid: locationId,
                linkUuid: linkUuid
            };
            setupSubscriberLinkDesign(objBody)
                .then((res) => {
                    if (res?.result) {
                        if (res?.data[0]?.status) {
                            collectLinkConfiguration()
                            setLinkEnabled(res?.data[0]?.enabled);
                            toast(toastFunctionToaster(res.message, "success"));
                        }
                    } else {
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch((err) => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {

        }
    };

    const reRenderImageData = (data) => {
        if (data) {
            collectLinkConfiguration()
        }
    }

    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} w='100%' size="sm" bg={greenButtonBg} borderRadius="0" onClick={onOpen}>Link Design </Button>

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size="xl"
            >
                <DrawerOverlay />
                <DrawerContent>

                    <DrawerBody p="0px" overflow={'hidden'}>
                        <Flex w={'100%'} direction={'column'} borderBottomWidth={'1px'} borderColor="grey.200" p="3">
                            <Text fontWeight={'600'}>{"Upload Link Design"}</Text>
                            <DrawerCloseButton />
                        </Flex>
                        {linkUuid != null && linkKey != null ? (
                            <Flex w={'100%'} p="3" bg={brandBg} h="94%" overflow={'hidden'} justifyContent={'space-between'}>
                                <Card w={'49%'} mt={1}>
                                    <Box p="3">
                                        <Tabs variant="soft-rounded" colorScheme="blue">
                                            <TabList>
                                                <Tab borderRadius={"10px"}>Upload Logo</Tab>
                                                <Tab borderRadius={"10px"}>Upload Background</Tab>
                                            </TabList>
                                            <TabPanels>
                                                <TabPanel w="100%">
                                                    <SubscriberLinkDesign
                                                        choice={'logo'}
                                                        linkKey={linkKey}
                                                        linkUuid={linkUuid}
                                                        accountId={accountId}
                                                        locationId={locationId}
                                                        // customerUuid={customerId}
                                                        title={"Upload LingoLink Logo Image"}
                                                        fileInfo={'The suggestive business logo size is 180x60 or multiple of this size.'}
                                                        renderLogoData={reRenderImageData}
                                                    />
                                                </TabPanel>
                                                <TabPanel w="100%">
                                                    <SubscriberLinkDesign
                                                        choice={'background'}
                                                        linkKey={linkKey}
                                                        linkUuid={linkUuid}
                                                        accountId={accountId}
                                                        locationId={locationId}
                                                        // customerUuid={customerId}
                                                        title={"Upload LingoLink Background Image"}
                                                        fileInfo={'The suggestive background size size is 800x600 or multiple of this size.'}
                                                        renderLogoData={reRenderImageData}
                                                    />
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </Box>
                                </Card>
                                <Card w={'49%'} mt={1} ml={1}>
                                    {linkLogo || linkBackground
                                        ? <Flex w={'100%'} p={2} rounded={'md'} bg={'gray.200'} align={'center'}>
                                            <Icon
                                                mr={2} h={6} w={6}
                                                color={linkEnabled ? 'green' : 'red.500'}
                                                as={linkEnabled ? AiFillCheckSquare : AiOutlineCheckSquare} />
                                            <Text>Currently the design is set as {linkEnabled ? 'enabled, means link customer see this design' : 'disabled. means it will not be applied'}, Change it</Text>
                                            <Switch
                                                ml={2}
                                                p={1}
                                                onChange={() => {
                                                    manageDesignStatus(
                                                        !linkEnabled
                                                    );
                                                }}
                                                isChecked={linkEnabled ? true : false}
                                                colorScheme="green"
                                            />
                                        </Flex> : null}
                                    {linkLogo
                                        ? <>
                                            <Text my="4" fontWeight={'600'}>Logo</Text>
                                            <Image src={linkLogo} w={'160px'} />
                                        </>
                                        : <Text bg={redBg} p={2}>You don't have LingoLink logo set.</Text>}
                                    {linkBackground
                                        ? <>
                                            <Text mt="4" fontWeight={'600'}>Background Image </Text>
                                            <Image mt="4" src={linkBackground} w={'50%'} />
                                        </>
                                        : <Text mt={1} bg={redBg} p={2}>You don't have LingoLink background set.</Text>}
                                </Card>
                            </Flex>) :
                            <Flex w={'100%'} p={2} bg={redBg}>
                                <Text>Something is not right. please go back...</Text>
                            </Flex>}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LinkDesignWidget
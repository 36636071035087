import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function UpdateSetting({ isOpen, close, handleOnsubmit, data, isUpdateInProgress }) {
  const [formData, setFormData] = useState({});
  const [intitialData, setInitialData] = useState({})
  const [disableUpdate, setDisableUpdate] = useState(true)

  useEffect(() => {
    const newInitData = {
      setting_name: data?.setting_name || '',
      setting_details: data?.setting_details || '',
      value_type: data?.value_type || '',
      setting_value: data?.setting_value || '',
      setting_type: data?.setting_type || '',
      setting_group: data?.setting_group || '',
      setting_component: data?.setting_component || '',
      setting_function: data?.setting_function || '',
    };
    setFormData(newInitData);
    setInitialData(newInitData);
  }, [data]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOnsubmit({...formData, setting_uuid:data.setting_uuid});
  };

  const checkValuesUpdated = () => {
    return JSON.stringify(formData) === JSON.stringify(intitialData)
  }

  useEffect(()=>{
    setDisableUpdate(checkValuesUpdated())
  }, [formData])

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={close} size="md">
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Update setting</DrawerHeader>

          <DrawerBody>
            <Box>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting name</FormLabel>
                <Input
                  placeholder="Enter setting name"
                  name="setting_name"
                  type="text"
                  value={formData.setting_name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Setting details</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting details"
                  name="setting_details"
                  value={formData.setting_details}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Value type</FormLabel>
                <Input
                  placeholder="Enter value type"
                  name="value_type"
                  value={formData.value_type}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting value</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting value"
                  name="setting_value"
                  value={formData.setting_value}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting type</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting type"
                  name="setting_type"
                  value={formData.setting_type}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting group</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting group"
                  name="setting_group"
                  value={formData.setting_group}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting component</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting component"
                  name="setting_component"
                  value={formData.setting_component}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>Setting function</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter setting function"
                  name="setting_function"
                  value={formData.setting_function}
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={close}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isDisabled={disableUpdate} isLoading={isUpdateInProgress}>
              Update
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}

export default UpdateSetting;

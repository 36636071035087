import {
  Box, Button, Flex, IconButton, Text, Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Card,
  Spacer,
  Spinner,
  Alert,
  AlertIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import { translationLanguages } from '../../../utils/TranslationLanguages'
import { BiDotsHorizontal } from 'react-icons/bi'
import { applyLangPreference } from '../../../services/accountServices'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import { useBlueButton, useGreenBg, useTextColor, useWhiteLightdarkBg } from '../../../theme/globalColorTheme'
import { useNavigate } from 'react-router-dom'

const RenderLanguages = memo(({ langs }) => {
  let langsArr = langs?.split(',')
  const filterData = translationLanguages.filter(item => langsArr?.indexOf(item.code) > -1)
  const blueColor = useBlueButton()
  return (
    filterData.length ? (
      <Flex flexWrap="wrap" gap={2} >
        {filterData.map((item) => (<Text bg={blueColor} p="1" fontSize="sm" borderRadius="md">{item.name}</Text>))}
      </Flex>
    ) :
      (<Flex flexWrap="wrap" gap={2} >
        <Text p="1" fontSize="sm" borderRadius="md" bg={blueColor}>All Languages</Text>
      </Flex>)
  )
})

const LinkPreferences = (props) => {
  const { linkItem, accountId, locationId, reListLink,langPrefList } = props;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const greenBg = useGreenBg()
  const [isApplyLoading, setIsApplyLoading] = useState(false)
  const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
  const blackWhiteColor = useTextColor()
  const pillButtonBg = useColorModeValue("blue.100", "gray.600");
  const whiteAndLightDarkColor = useWhiteLightdarkBg()

  const navigate = useNavigate()

  function handleOpenDrawer() {
    onOpen()
  }

  function handleApplyPrefrence(prefrenceid) {
    const paramObj = {
      "link_account_uuid": accountId,
      "link_location_uuid": locationId,
      "linkUuid": linkItem.uuid,
      "language_preference_uuid": prefrenceid
    }
    setIsApplyLoading(prefrenceid)
    applyLangPreference(paramObj).then(resp => {
      if (resp.data[0]?.status) {
        reListLink()
        toast(toastFunctionToaster("Successfully added language preference", "success"))
      } else {
        toast(toastFunctionToaster("Failed to add language preference", "error"))
      }
      setIsApplyLoading(false)
    }).catch(err => {
      setIsApplyLoading(false)
      toast(toastFunctionToaster("Failed to add language preference", "error"))
    })
  }

  function handleCloseDrawer() {
    onClose()
    setIsApplyLoading('')
  }


  return (
    <Flex gap={2} mt={3}>
      <Text w={'200px'}>
        <b>Language Preferences:</b>
      </Text>
      <RenderLanguages langs={linkItem?.source_langs} />
      <IconButton
        borderRadius={'5px'}
        size='sm'
        fontSize={'22px'}
        _hover={{
          color: "white",
          backgroundColor: "blue.300"
        }}
        ml='2'
        bg={'blue.100'}
        color="black"
        // colorScheme='blue'
        icon={<BiDotsHorizontal />}

        onClick={handleOpenDrawer}
      />


      <Drawer
        size="md"
        isOpen={isOpen}
        placement='right'
        onClose={handleCloseDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody>
            <Text>List of all enabled Languages preference</Text>
            <Flex flexDir="column" gap={2} p={1}>
              <>
                {
                  langPrefList && langPrefList.length ? langPrefList.map(item => (
                    <Flex alignItems="center" justifyContent="center" gap="1">
                      <Card w="100%" borderRadius="md" mb={2} p={4} bg={whiteAndLightDarkColor}>
                        <Flex alignItems='center' >
                          <Flex flexDir="column" gap={2} flex="1">
                            <Flex>
                              <Box
                                p={2}
                                bg={greenBg}
                                borderRadius="md">
                                <Text
                                  fontWeight={"500"}
                                  fontSize={"18px"}
                                  _firstLetter={{ textTransform: "capitalize" }}>
                                  {item?.language_preference_name}
                                </Text>
                              </Box>
                              <Spacer />
                            </Flex>

                            <Flex alignContent="center" gap={2}>Languages:<RenderLanguages langs={item?.supported_language_list} /></Flex>
                          </Flex>
                          <Button fontSize="sm" _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                            bg={pillButtonBg} isLoading={isApplyLoading === item.language_preference_uuid} onClick={() => handleApplyPrefrence(item.language_preference_uuid)}>Apply</Button>
                        </Flex>
                      </Card>
                    </Flex>
                  ))
                    : <Alert status="warning" mt="4">
                      <AlertIcon />
                      <Text>You dont have any Language Preferences</Text>
                      <Text
                        ml={5}
                        _hover={{ cursor: "pointer", textDecoration: "underline" }}
                        onClick={()=>navigate("/subscriber/languagepreference")}
                      >
                        <b>CREATE NEW Language Preference!!</b>
                      </Text>
                    </Alert>
                }
              </>

            </Flex>
          </DrawerBody>

          {/* <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>

    </Flex>
  )
}

export default LinkPreferences
// useColorModeValues.js

import { useColorModeValue } from "@chakra-ui/react";

export function useCustomColorModeValues() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const badgeBgRedColor = useColorModeValue("red.100", "red.800");
  const badgeBgBlueColor = useColorModeValue("blue.100", "blue.800");

  return {
    textColor,
    borderColor,
    brandColor,
    badgeBgRedColor,
    badgeBgBlueColor,
  };
}

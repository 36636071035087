import { Button } from "@chakra-ui/react";
import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import moment from 'moment'

const convertToCSV = (array) => {
    const headers = [
        "START TIME",
        "END TIME",
        "DURATION",
        "PROVIDER",
        "TARGET LANGUAGE",
        "CALL TYPE",
        "SUBSCRIBER EMAIL",
    ];

    // Generate rows
    const rows = array.map((item) => {
        return [
            item.start_time || "",
            item.end_time || "",
            item.duration || "",
            item.provider || "",
            item.target_language || "",
            item.call_type || "",
            item?.subscriber || "N/A",
        ].join(",");
    });

    return [headers.join(","), ...rows].join("\n");
};

function getFileName(selectedRange, prefix = "billing") {
    if (!selectedRange || selectedRange.length !== 2) {
      throw new Error("Invalid date range provided");
    }
  
    const [start, end] = selectedRange;
    const startDate = moment(start).format("MM-DD-YYYY");
    const endDate = moment(end).format("MM-DD-YYYY");
  
    const fileName = `${startDate} - ${endDate}_${prefix}.csv`;
    return fileName;
  }

const BillingCSV = ({ data, selectedRange }) => {
    const handleDownload = () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = getFileName(selectedRange);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button rounded={'lg'} size="sm" leftIcon={<AiOutlineFileText />} colorScheme="blue" onClick={handleDownload}>CSV</Button>
    );
};

export default BillingCSV;

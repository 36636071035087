import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Chakra Import
import {
	Box,
	Flex,
	Text,
	Badge,
	Image,
	Button,
	Icon,
	Spacer,
	Alert,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Drawer,
	Spinner,
	Tooltip,
	SimpleGrid,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	AlertIcon,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
	Checkbox,
	CheckboxGroup,
	useColorModeValue,
	RadioGroup,
	Stack,
	Radio,
	FormControl,
	Input,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	color,
	background,
	Select,
} from "@chakra-ui/react";
import moment from 'moment'
import InputField from "../../../components/fields/InputField";
import Card from "../../../components/card/Card";
import {
	AiOutlineCheckCircle,
	AiOutlineStop,
	AiOutlinePlus,
	AiFillCaretDown,
} from "react-icons/ai";
// Api Call Imports
import { createSubLingoLink, fetchSubLingoList, disableSubLingoLink, resetSubLingoLinkCode, fetchResetedLeaderCode, listLangPreference } from "../../../services/accountServices";
// Component Imports
import { useBrandBg, useGradientColor, useBlueButton, useRedButton, useYellowButton, useGreenButton, useTextColor, useCardHeaderActiveBg, useCardHeaderNonActiveBg, useCardTextHover } from "../../../theme/globalColorTheme";
import "react-responsive-pagination/themes/classic.css";

// Components
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";

import LinkEmailWidget from "./LinkEmailWidget";
import LinkItemWidget from "./LinkItemWidget";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import LinkDesignWidget from "./LinkDesignWidget";
import LinkEmailLeaderCode from "./LinkEmailLeaderCode";
import LinkSmsLeaderCode from "./LinkSmsLeaderCode";
import LinkQrPdf from "./LinkQrPdf";
import Pagination from "../../../components/pagination/Pagination";
import { LINGOLINK_TYPES } from "./constants";
import UpdateLink from "./UpdateLink";

function LinkCustomerLinksListing(props) {
	const toast = useToast();
	const btnRef = React.useRef();
	const accountUuid = props?.accountId ? props?.accountId : null
	const locationUuid = props?.locationId ? props?.locationId : null
	const subscriberDetails = props?.subscriberDetails ? props?.subscriberDetails : null;
	const subAppDetails = props?.subAppDetails?.length ? props?.subAppDetails.split(',') : []
	const [loading, isLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const type = props?.type
	const [dynamicLinks, setDynamicLinks] = useState([]);
	const [linkChoice, setLinkChoice] = useState("active");
	const navigate = useNavigate();
	const [linkTitle, setLinkTitle] = useState(null);
	const [durationValue, setDurationValue] = useState(48);
	const [showTooltip, setShowTooltip] = React.useState(false);
	const [linkTitleFlag, setLinkTitleFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
	const [voiceCallValue, setVoiceCallValue] = useState(5);
	const [videoCallValue, setVideoCallValue] = useState(5);
	const [callTimeValue, setCallTimeValue] = useState(5);
	const [isCallDurationUnlimited, setIsCallDurationUnlimited] = useState(false)
	const [selectedLangPref, setSelectedLangPref] = useState("none")


	const textColor = useColorModeValue("secondaryGray.700", "white");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
	const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");
	const blackWhiteColor = useTextColor()

	const [selectedValues, setSelectedValues] = useState(subAppDetails);
	const [linkStartDateTime, setLinkStartDatetime] = useState(null)
	const [linkEndDateTime, setLinkEndDateTime] = useState(null)
	const [linkMultichannel, setLinkMultichannel] = React.useState(false);
	const [multiPurposeFlag, setMultipurposeFlag] = useState(null);
	const [currentPage, setCurrentPage] = useState(0)
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [totalPages, setTotalPages] = useState(0)
	const [langPrefList, setLangPrefList] = useState([])

	const [newLingoLinkType, setNewLingoLinkType] = useState(LINGOLINK_TYPES.NO_LIMIT)
	const [errors, setErrors] = useState({})
	const touched =  useRef({})
	const { isOpen:isAlertOpen, onOpen:onAlertOpen, onClose:onAlertClose } = useDisclosure()
 	const cancelRef = React.useRef()
	const [activeLink, setActiveLink] = useState('')

	const [currentSelection, setCurrentSelection] = useState('Active');
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blueButtonBg = useBlueButton();
	const redButtonBg = useRedButton();
	const yellowButtonBg = useYellowButton();
	const greenButtonBg = useGreenButton();
	const localCardHeaderNonActiveBg = useCardHeaderNonActiveBg();
	const localCardHeaderActiveBg = useCardHeaderActiveBg();
	const grayColor = useCardTextHover()


	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};

	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	const DatetimePickerStyles =
	{
		height: "38.5px",
		padding: "0 16px",
		width: "100%",
		border: ".8px solid #cbd5e0",
		borderRadius: "5px",
		background:"transparent",
		color:blackWhiteColor
	}

	useEffect(()=>{
		getLanguagePreferencesList()
	}, [])

	// Todo:Differentiate based on type
	useEffect(() => {
		const buttonType = props?.button
		if (buttonType?.length) {
			switch (buttonType) {
				case 'active':
				case 'closed':
					getAllDynamicLinks(buttonType);
					break;
				case 'add':
					onOpen();
					break;
				default:
					break;
			}
			props?.resetButton('reset')
		}

	}, [props?.button])

	useEffect(() => {
		if (
			subAppDetails.includes('hi') ||
			subAppDetails.includes('bi') ||
			subAppDetails.includes('pi') ||
			subAppDetails.includes('di')
		) {
			setMultipurposeFlag(true);
		} else {
			setMultipurposeFlag(false);
			setLinkMultichannel(true)
		}
	}, [subAppDetails]);

	//   Todo:Fetch all projects
	useEffect(() => {
		getAllDynamicLinks(linkChoice);
	}, [currentPage, accountUuid, recordsPerPage]);

	function getLanguagePreferencesList() {
		const paramObj = {
		  pageId: 1,
		  records_per_page: 100,
		  status: 'active'
		}
		listLangPreference(paramObj).then(resp => {
		  if (resp.data[0]?.status) {
			setLangPrefList(resp.data[0]?.status)
		  } else {
			setLangPrefList(null)
			toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
		  }
		}).catch(err => {
		  setLangPrefList(null)
		  toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
		})
	  }

	const getAllDynamicLinks = choice => {
		if (accountUuid != null && locationUuid !== null) {
			isLoading(true);
			let paramObj = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				filter_key: "string",
				filter_value: choice,
				records_per_page: recordsPerPage,
				page_id: currentPage
			};
			setLinkChoice(choice);
			fetchSubLingoList(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data[0]?.records) {
						setDynamicLinks(res?.data[0]?.records);
						setTotalPages(res?.data[0]?.total_pages);
					} else {
						setDynamicLinks([]);
					}
				})
				.catch(err => {
					isLoading(false);
				});
		}
	};

	// Todo:Handle multi-channel value on tag change
	useEffect(() => {
		const filteredData = selectedValues.filter(
			item => linkMultichannel || item !== "mi"
		);
		setSelectedValues(filteredData);
	}, [linkMultichannel]);

	useEffect(() => {
		setCurrentPage(1)
	}, [recordsPerPage])

	useEffect(() => { resetProjectDetails(false, false) }, [newLingoLinkType])

	function calculateMinutes() {
		const differenceInMs = new Date(linkEndDateTime) - new Date(linkStartDateTime);
		const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
		return differenceInMinutes;
	}

	function validate() {
		if(linkMultichannel){
			return true
		}
		if (linkTitle?.length && accountUuid != null && locationUuid != null && selectedValues.length > 0) {
			setLinkTitleFLag(false)
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				if (!linkStartDateTime || !linkEndDateTime) {
					setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
					return false
				}
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return false
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > 43200) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 31 days" }))
					return false
				}
			}
			return true
		}
		else {
			setLinkTitleFLag(true);
			return false
		}
	}

	useEffect(()=>{
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	},[linkTitle])

	useEffect(() => {
		if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
			if (touched.current['link_start_date_time'] && touched.current['link_end_date_time']) {
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > 43200) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 31 days" }))
					return
				}
			}
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
	}, [linkStartDateTime, linkEndDateTime, newLingoLinkType])

	function handleBlur(name) {
		touched.current = { ...touched.current, [name]: true }
		if ((touched.current['link_start_date_time'] && !linkStartDateTime) || (touched.current['link_end_date_time'] && !linkEndDateTime)) {
			setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
		}
		else{
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	}

	//   Todo:API to create new LingoLink
	const createDynaLink = () => {
		if (validate()) {
			let locDurationValue = durationValue;
			let locVideoCallValue = videoCallValue;
			let locVoiceCallValue = voiceCallValue;
			let locCallTimeValue = callTimeValue;
			let linkStartTime = '';
			let isLinkTrial = newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL || newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION;
			if (newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT || linkMultichannel) {
				locDurationValue = 8760;
				locVideoCallValue = 1000;
				locVoiceCallValue = 10000;
				locCallTimeValue = 2920;
			}
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				let durationInMinutes = calculateMinutes()
				locDurationValue = durationInMinutes / 60
				locVideoCallValue = 9999;
				locVoiceCallValue = 9999;
				locCallTimeValue = isCallDurationUnlimited ? 9999 : callTimeValue
				linkStartTime = moment(new Date(linkStartDateTime)).utc().format('YYYY-MM-DD HH:mm:ss')
			}
			setLinkTitleFLag(false);
			let objBody = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				link_title: linkTitle,
				is_trail: isLinkTrial,
				active_hours: locDurationValue,
				videoCount: locVideoCallValue,
				voiceCount: locVoiceCallValue,
				callTime: locCallTimeValue,
				features: selectedValues.join(","),
				multichannel: linkMultichannel,
				start_time: linkStartTime,
				lang_pref: selectedLangPref
			};
			setSaveDetailsLoader(true);
			createSubLingoLink(objBody)
				.then(res => {
					setSaveDetailsLoader(false);
					if (res?.result) {
						onClose();
						resetProjectDetails();
						getAllDynamicLinks("active");
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						onClose();
						resetProjectDetails();
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					onClose();
					resetProjectDetails();
					setSaveDetailsLoader(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		}
	};

	// Todo:Reset Access Code
	const changeLinkAccessCode = (linkUuidx) => {
		if (linkUuidx != null && accountUuid != null && locationUuid !== null) {
			let objBody = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				link_uuid: linkUuidx
			};
			isLoading(true)
			resetSubLingoLinkCode(objBody)
				.then((res) => {
					if (res?.result) {
						if (res?.data[0]?.status) {
							getAllDynamicLinks('active');
							toast(
								toastFunctionToaster(
									"Link access code is successfully refreshed",
									"success"
								)
							);
						} else {
							toast(
								toastFunctionToaster(
									"Failed to refresh the access code...",
									"error"
								)
							);
						}
					} else {
						toast(
							toastFunctionToaster(
								"Failed to refresh the access code...",
								"error"
							)
						);
					}
				})
				.catch((err) => {
					toast(
						toastFunctionToaster(
							"Failed to refresh the access code...",
							"error"
						)
					);
				});
		} else {
			toast(
				toastFunctionToaster("Failed to refresh the access code...", "error")
			);
		}
	};

	// Todo:Reset Leader Code
	const changeLeaderCode = (linkUuidx) => {
		if (linkUuidx != null) {
			let objBody = {
				link_uuid: linkUuidx,
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
			};
			fetchResetedLeaderCode(objBody)
				.then((res) => {
					if (res?.result) {
						if (res?.data[0]?.status) {
							getAllDynamicLinks('active');
							toast(
								toastFunctionToaster(
									"Leader access code is successfully refreshed",
									"success"
								)
							);
						} else {
							toast(
								toastFunctionToaster(
									"Failed to refresh the leader access code...",
									"error"
								)
							);
						}
					} else {
						toast(
							toastFunctionToaster(
								"Failed to refresh the leader access code...",
								"error"
							)
						);
					}
				})
				.catch((err) => {
					toast(
						toastFunctionToaster(
							"Failed to refresh the leader access code...",
							"error"
						)
					);
				});
		} else {
			toast(
				toastFunctionToaster("Failed to refresh the leader access code...", "error")
			);
		}
	};

	// Change Status Of LingoLink
	const disableSelectedLink = () => {
		if (accountUuid != null && locationUuid !== null) {
			let paramObj = {
				link_uuid: activeLink,
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
			};
			handleAlertClose()
			disableSubLingoLink(paramObj)
				.then((res) => {
					if (res?.result && res?.data?.length > 0) {
						if (res?.data[0]?.status) {
							// Reload List
							getAllDynamicLinks("closed");
							setCurrentSelection('closed');
							toast(
								toastFunctionToaster(
									"Selected LingoLink is successfully disabled.",
									"success"
								)
							);
						} else {
							toast(
								toastFunctionToaster(
									"Unable to complete the selected request..",
									"error"
								)
							);
						}
					} else {
						toast(
							toastFunctionToaster(
								"Unable to complete the selected request..",
								"error"
							)
						);
					}
				})
				.catch((err) => {
					toast(
						toastFunctionToaster(
							"Unable to complete the selected request...",
							"error"
						)
					);
				});
		}
	};

	const handleLingoList = (data) => {
		if (data) {
			getAllDynamicLinks('active')
		}
	}

	// Todo:Route To Call History
	const routeToCallHistory = data => {
		navigate("/subscriber/callhistory", {
			state: {
				linkType: data?.active,
				linkUuid: data?.uuid,
				linkKey: data?.key,
			},
		});
	};

	// Todo:Route To Call History
	const routeToSubscriberTranscript = data => {
		navigate("/subscriber/transcripts", {
			state: {
				linkItem: data,
				accountId: accountUuid,
				locationId: locationUuid
			},
		});
	};

	function handleOpenAlert(uuid){
		setActiveLink(uuid)
		onAlertOpen()
	}

	const renderDynamicLinks =
		dynamicLinks !== null && dynamicLinks?.length > 0
			? dynamicLinks?.map((item, index) => (
				<Card
					bg={brandBg}
					key={index}
					w={"100%"}
					mb={2}
					p="0px"
					borderRadius='10px'
					boxShadow="none"
					>
					<Flex w={"100%"}
						bg={!item?.active ? localCardHeaderNonActiveBg : localCardHeaderActiveBg}
						direction={"column"} borderWidth={'0.5px'} borderColor={gradientColor} roundedTop="10px" className="abc">
						<Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'} roundedTop={'md'} p={'10px'} borderBottomWidth={'0.5px'} borderColor={gradientColor}>
							<Flex w={'130px'}>
								<Box w={'120px'} align={'center'} rounded={'md'} px={2}
									bg={item?.active ? 'green.200' : 'red.200'} color="black">
									{item?.class === 1 ? <Text>Unlimited Link</Text> : null}
									{item?.class === 2 ? <Text>Trial Link</Text> : null}
									{item?.class === 3 ? <Text>Fixed Link</Text> : null}
								</Box>
							</Flex>
							{item?.multichannel && item?.features.includes('mi')
								? <Flex w={'50px'}>
									<Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
								</Flex> : null}
							<Flex alignItems={'center'} ml={1} rounded={"md"} fontWeight={'500'} px={2} color={blackWhiteColor}>
								{item?.dead ? (
									<Icon as={AiOutlineStop} color={"red.600"} mr={2} />
								) : (
									<Icon
										as={AiOutlineCheckCircle}
										color={grayColor}
										mr={2}
									/>
								)}
								{item?.title}
							</Flex>
							<Spacer />
							{/* Todo:MultiChannel */}
							{item?.multichannel && item?.features.includes('mi') ? (
								<Menu>
									<MenuButton size="sm" bg={'blue.100'} _hover={{ bg: 'blue.200' }} borderRadius={'5px'} as={Button} rightIcon={<AiFillCaretDown />} mr='2' color='#000'>
										<Flex alignItems={'center'} p='5px' borderRightWidth='1px' borderColor={'#d7d7d7'}>
											<Text fontWeight='600'>Leader Code </Text>
											<Text fontWeight='500' ml='2'>{item?.leader_code} </Text>
										</Flex>
									</MenuButton>
									<MenuList p='0px'>
										<MenuItem borderBottomWidth={'1px'} p='0px'>
											<Button justifyContent={'start'} w='100%' onClick={() => changeLeaderCode(item?.uuid)} size="sm" bg={blueButtonBg} borderRadius="0">
												Change Leader Code
											</Button>
										</MenuItem>
										<MenuItem borderBottomWidth={'1px'} p='0px'>
											<LinkEmailLeaderCode subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
										</MenuItem>
										<MenuItem borderBottomWidth={'1px'} p='0px'>
											<LinkSmsLeaderCode subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
										</MenuItem>
									</MenuList>
								</Menu>
							) : null}

							{/* Todo:Access Code UI */}
							<Menu>
								<MenuButton size="sm" _hover={{ bg: 'blue.200' }} bg={'blue.100'} borderRadius={'5px'} as={Button} rightIcon={<AiFillCaretDown />} mr='2' color='#000'>
									<Flex alignItems={'center'} p='5px' borderRightWidth='1px' borderColor={'#d7d7d7'}>
										<Text fontWeight='600'>Access Code </Text>
										<Text fontWeight='500' ml='2'>{item?.code} </Text>
									</Flex>
								</MenuButton>
								<MenuList p='0px' >
									<MenuItem borderBottomWidth={'1px'} p='0px'>
										<Button justifyContent={'start'} w='100%' onClick={() => changeLinkAccessCode(item?.uuid)} size="sm" bg={blueButtonBg} borderRadius="0">
											Change Access Code
										</Button>
									</MenuItem>

								</MenuList>
							</Menu>

							{/* Todo:Link Control UI */}
							<Menu>
								<MenuButton size="sm" bg={'blue.100'} _hover={{ bg: 'blue.200' }} borderRadius={'5px'} as={Button} rightIcon={<AiFillCaretDown />} mr='2' color='#000'>
									<Flex alignItems={'center'} p='5px' borderRightWidth='1px' borderColor={'#d7d7d7'}>
										<Text fontWeight='600'>Link Control</Text>
									</Flex>
								</MenuButton>
								<MenuList p='0px' >
									<MenuItem borderBottomWidth={'1px'} p='0px'>
										<LinkDesignWidget linkItem={item} accountId={accountUuid} locationId={locationUuid} />
									</MenuItem>
									<MenuItem borderBottomWidth={'1px'} p='0px'>
										<LinkEmailWidget subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
									</MenuItem>
									<MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<LinkSmsLeaderCode subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
									</MenuItem>
									<MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<Button justifyContent={'start'} w='100%' size="sm" bg={greenButtonBg} borderRadius="0" onClick={() => routeToCallHistory(item)}>
											Call History
										</Button>
									</MenuItem>
									<MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<Button justifyContent={'start'} w='100%' size="sm" bg={yellowButtonBg} borderRadius="0" onClick={() => { routeToSubscriberTranscript(item) }}>Transcripts</Button>
									</MenuItem>
									{item?.active && <MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<Button justifyContent={'start'} w='100%' onClick={() => handleOpenAlert(item?.uuid)} bg={redButtonBg} size={"sm"} borderRadius={'0'}>
											Disable Link
										</Button>
									</MenuItem>}
									<MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<LinkQrPdf subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
									</MenuItem>
									<MenuItem borderBottomWidth={'1px'} p='0px' textAlign='left'>
										<UpdateLink subscriberEmail={subscriberDetails?.email} linkInfo={item} accountId={accountUuid} locationId={locationUuid} supportedApps={subAppDetails} refreshList={getAllDynamicLinks} currentSelection={currentSelection === "Active"? "active" : "closed" }/>
									</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
					</Flex>
					<LinkItemWidget
						linkItem={item}
						accountId={accountUuid}
						locationId={locationUuid}
						reRenderLingoList={handleLingoList}
						subAppDetails={subAppDetails}
						langPrefList={langPrefList}
						 />
				</Card>
			))
			: <Text>No LingoLinks</Text>;

	// Todo:Reset Project Details
	const resetProjectDetails = (resetLingoLinkType = true, resetLingoLinkTitle = true) => {
		resetLingoLinkTitle && setLinkTitle("");
		setDurationValue(48);
		setVoiceCallValue(5);
		setVoiceCallValue(5);
		setLinkTitleFLag(false);
		setSelectedValues(subAppDetails)
		resetLingoLinkType && setNewLingoLinkType(LINGOLINK_TYPES.NO_LIMIT)
		setIsCallDurationUnlimited(false)
		setErrors({})
		setLinkStartDatetime(null)
		setLinkEndDateTime(null)
		touched.current = {}
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetProjectDetails();
	};

	function handleAlertClose(){
		onAlertClose()
		setActiveLink('')
	}

	// Function to handle changes in the CheckboxGroup
	const handleChange = (values) => {
		setSelectedValues(values);
	};
	return (
		<>
			<Card w={"100%"} rounded={"md"} p="10px">
				<Box direction="column" pt={{ sm: "0px", lg: "0px" }}>
					<Card p="3" alignItems="center" rounded={'md'}>
						<Flex w={'100%'} direction={'row'} alignItems="center">
							<Text fontWeight={"600"} fontSize={"16px"} justifyContent={'center'}>
								LingoLink {dynamicLinks?.length > 0 ? dynamicLinks?.length : 0} ({linkChoice})
							</Text>
							<Spacer />
							<Flex minW={'440px'} maxW={'440px'} maxH={'50px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
								<Text
									minW={'70px'} maxW={'70px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('Active');
										getAllDynamicLinks("active")
									}}
									p={2} roundedLeft={'md'}
									color={currentSelection === 'Active' ? 'white' : textColor}
									bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
									Active
								</Text>
								<Text
									minW={'80px'} maxW={'80px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('closed');
										getAllDynamicLinks("closed")
									}}
									color={currentSelection === 'closed' ? 'white' : textColor}
									p={2}
									bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
									Closed
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										getAllDynamicLinks("active");
										setCurrentSelection('refresh');
									}}
									color={currentSelection === 'refresh' ? 'white' : textColor}
									p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>Refresh
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										onOpen();
										// setCurrentSelection('new');
									}}
									roundedRight={'md'}
									color={currentSelection === 'new' ? 'white' : textColor}
									p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>
									<Icon pt={1} as={AiOutlinePlus} mr={1} />
									Create New Link
								</Text>
							</Flex>
						</Flex>
					</Card>
					<AlertDialog
								isOpen={isAlertOpen}
								leastDestructiveRef={cancelRef}
								onClose={onAlertClose}
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize='lg' fontWeight='bold'>
											Disable Link
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure? Disabled link instantly becomes unusable
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button ref={cancelRef} onClick={onAlertClose}>
												Cancel
											</Button>
											<Button colorScheme='red' onClick={disableSelectedLink} ml={3}>
												Disable Link
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
					{loading ? (
						<Flex alignItems="center" justifyContent="center" h={type === 'lingoCustomer' ? '10vh' : '50vh'}>
							<Spinner size="xl" />
						</Flex>
					) : dynamicLinks?.length ? (
						<Card w={"100%"} h="calc(100vh - 300px)" overflow={'auto'}
							borderRadius='10px' mt={3} p={type === 'lingoCustomer' ? '0px' : '10px'}>
							<Flex w={"100%"} rounded={"md"} direction={"column"}>
								{renderDynamicLinks}
							</Flex>
						</Card>
					) : (
						<Flex h="calc(100vh - 250px)">
						<Alert status="warning" mt="4" h="fit-content">
							<AlertIcon />
							<Text>You dont have any LingoLink yet... </Text>
							<Text ml={5}>
								<b>CREATE NEW LINGOLINK!!</b>
							</Text>
						</Alert>
						</Flex>
					)}
				</Box>
			</Card>
			<Drawer
				size="lg"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					{subAppDetails?.length ? (
						<>
							<DrawerHeader borderBottomWidth={"1px"}>{"Create New LingoLink"}</DrawerHeader>
							<DrawerBody overflow="hidden" overflowY="scroll" mt="5">
								<Flex w={"100%"} direction={"column"}>
									<Input
										mb="0"
										me="30px"
										id="link_title"
										label="Link Title"
										value={linkTitle}
										name="link_title"
										required="true"
										borderRadius="5px"
										onChange={e => {
											setLinkTitle(e.target.value);
										}}
										onBlur={e => handleBlur(e.target.name)}
										placeholder="Enter Link Title"
										color={blackWhiteColor}
										w="100%"
										maxlength={100}
									/>
									<Flex alignItems={"center"} justifyContent={"space-between"}>
										{linkTitleFlag ? (
											<Text fontSize="sm" color={"red"} mt="1">
												Link Title is Required
											</Text>
										) : null}
										<Spacer />
										<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
											{linkTitle?.length}/100
										</Text>
									</Flex>

									<Flex w={'100%'} direction={'column'}>
										<Flex w={'100%'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
											{multiPurposeFlag ? (
												<Text
													minW={'50%'} maxW={'50%'}
													_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
													onClick={() => {
														setLinkMultichannel(false);
													}}
													p={2} roundedLeft={'md'}
													color={linkMultichannel === false ? 'white' : textColor}
													bg={linkMultichannel === false ? pillButtonBgSelected : pillButtonBg}>
													Multipurpose LingoLink
												</Text>
											) : null}
											{subAppDetails.includes('mi') ? (
												<Text
													minW={'50%'} maxW={'50%'}
													_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
													onClick={() => {
														setLinkMultichannel(true);
														setSelectedValues(['mi']);
													}}
													roundedRight={'md'}
													color={linkMultichannel === true ? 'white' : textColor}
													p={2}
													bg={linkMultichannel === true ? pillButtonBgSelected : pillButtonBg}>
													Multichannel LingoLink
												</Text>
											) : null}
										</Flex>
									</Flex>

									{linkMultichannel === true && subAppDetails.includes('mi')
										? <Flex w={'100%'} bg={'red.50'} rounded={'lg'} mt={1} direction={'column'} p={2}>
											{/* <CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
										<Checkbox value='mi'> */}
											<Flex alignItem='center'>
												<Image h="100px" w="100px" mx="1" borderRadius="50px" src={MultichannelBg} />
												<Flex w={'100%'} direction={'column'}>
													<Text as="span">
														MultiChannel AI
													</Text>
													<Text as="span">
														Main Language:
													</Text>
													<Text as="span">
														<b>English</b>
													</Text>
													<Text as="span">
														Other Language:
													</Text>
													<Text as="span">
														<b>Selected at Connecting Time</b>
													</Text>
												</Flex>
											</Flex>
											{/* </Checkbox>
									</CheckboxGroup> */}
										</Flex>
										: <Flex w={'100%'} rounded={'lg'} mt={1} direction={'column'}>
											<Flex
												w={"100%"}
												direction={"column"}
												p={1}
												rounded={"md"}
												borderWidth={"0.5px"}
												borderColor={"gray.200"} mt="3">
												<Flex w={"100%"} align={"center"} p={2} rounded={'md'}>
													<RadioGroup defaultValue={newLingoLinkType} onChange={value => setNewLingoLinkType(value)}>
														<Stack spacing={4} direction='row' color={blackWhiteColor}>
															<Radio value={LINGOLINK_TYPES.NO_LIMIT} variant="contained" >
																Unlimited LingoLink
															</Radio>
															{/* <Radio value={LINGOLINK_TYPES.SEVEN_DAYS_TRIAL}>7 Days Trial LingoLink</Radio> */}
															<Radio value={LINGOLINK_TYPES.FIXED_DURATION}>Fixed Day or Duration LingoLink</Radio>
														</Stack>
													</RadioGroup>
													<Spacer />
												</Flex>
												{newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL && (
													<Flex w={"100%"} direction={"column"}>
														<FormLabel my="3" ml="2" required>
															<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
																Link Activity Duration (Hours)
															</Text>
															<Flex
																mt={10}
																h={"100px"}
																w={"100%"}
																rounded={"md"}
																borderWidth={"0.5px"}
																borderColor={"gray.200"}>
																<Slider
																	aria-label="slider-ex-1"
																	colorScheme="teal"
																	min={2}
																	max={168}
																	step={1}
																	defaultValue={48}
																	onChange={v => setDurationValue(v)}
																	onMouseEnter={() => setShowTooltip(true)}
																	onMouseLeave={() => setShowTooltip(false)}>
																	<SliderMark value={24} {...labelStyles}>
																		24
																	</SliderMark>
																	<SliderMark value={48} {...labelStyles}>
																		48
																	</SliderMark>
																	<SliderMark value={72} {...labelStyles}>
																		72
																	</SliderMark>
																	<SliderMark value={96} {...labelStyles}>
																		96
																	</SliderMark>
																	<SliderMark value={120} {...labelStyles}>
																		120
																	</SliderMark>
																	<SliderMark value={144} {...labelStyles}>
																		144
																	</SliderMark>
																	<SliderMark value={168} {...labelStyles}>
																		168
																	</SliderMark>
																	<SliderTrack>
																		<SliderFilledTrack />
																	</SliderTrack>
																	<Tooltip
																		hasArrow
																		bg="teal.500"
																		color="white"
																		placement="top"
																		isOpen={showTooltip}
																		label={`${durationValue} Hours`}>
																		<SliderThumb />
																	</Tooltip>
																</Slider>
															</Flex>
														</FormLabel>
														<Flex w={"100%"} align={"center"}>
															<Flex
																w={"50%"}
																h={"100px"}
																direction={"column"}
																py={1}
																px={5}
																rounded={"md"}
																borderWidth={"0.5px"}
																borderColor={"gray.200"}>
																<Text>
																	Voice Call Count: <b>{voiceCallValue}</b>
																</Text>
																<Slider
																	mt={8}
																	aria-label="slider-ex-1"
																	colorScheme="teal"
																	min={1}
																	max={50}
																	step={1}
																	defaultValue={5}
																	onChange={v => setVoiceCallValue(v)}
																	onMouseEnter={() => setShowTooltip(true)}
																	onMouseLeave={() => setShowTooltip(false)}>
																	<SliderMark value={1} {...labelStylesVoice}>
																		1
																	</SliderMark>
																	<SliderMark value={25} {...labelStylesVoice}>
																		25
																	</SliderMark>
																	<SliderMark value={50} {...labelStylesVoice}>
																		50
																	</SliderMark>
																	<SliderTrack>
																		<SliderFilledTrack />
																	</SliderTrack>
																	<Tooltip
																		hasArrow
																		bg="teal.500"
																		color="white"
																		placement="top"
																		isOpen={showTooltip}
																		label={`${voiceCallValue}`}>
																		<SliderThumb />
																	</Tooltip>
																</Slider>
															</Flex>
															<Flex
																ml={1}
																w={"50%"}
																h={"100px"}
																direction={"column"}
																py={1}
																px={5}
																rounded={"md"}
																borderWidth={"0.5px"}
																borderColor={"gray.200"}>
																<Text>
																	Video Call Count: <b>{videoCallValue}</b>
																</Text>
																<Slider
																	mt={8}
																	aria-label="slider-ex-1"
																	colorScheme="teal"
																	min={1}
																	max={50}
																	step={1}
																	defaultValue={5}
																	onChange={v => setVideoCallValue(v)}
																	onMouseEnter={() => setShowTooltip(true)}
																	onMouseLeave={() => setShowTooltip(false)}>
																	<SliderMark value={1} {...labelStylesVoice}>
																		1
																	</SliderMark>
																	<SliderMark value={25} {...labelStylesVoice}>
																		25
																	</SliderMark>
																	<SliderMark value={50} {...labelStylesVoice}>
																		50
																	</SliderMark>
																	<SliderTrack>
																		<SliderFilledTrack />
																	</SliderTrack>
																	<Tooltip
																		hasArrow
																		bg="teal.500"
																		color="white"
																		placement="top"
																		isOpen={showTooltip}
																		label={`${videoCallValue}`}>
																		<SliderThumb />
																	</Tooltip>
																</Slider>
															</Flex>
														</Flex>
														<Flex
															mt="3"
															w={"100%"}
															h={"100px"}
															direction={"column"}
															py={1}
															px={5}
															rounded={"md"}
															borderWidth={"0.5px"}
															borderColor={"gray.200"}>
															<Text>
																Maximum minutes available in this Trial call: <b>{callTimeValue}</b>{" "}
																minutes.
															</Text>
															<Slider
																mt={10}
																aria-label="slider-ex-1"
																colorScheme="teal"
																min={5}
																max={600}
																step={5}
																defaultValue={5}
																onChange={v => setCallTimeValue(v)}
																onMouseEnter={() => setShowTooltip(true)}
																onMouseLeave={() => setShowTooltip(false)}>
																<SliderMark value={5} {...labelStylesVoice}>
																	5
																</SliderMark>
																<SliderMark value={100} {...labelStylesVoice}>
																	100
																</SliderMark>
																<SliderMark value={200} {...labelStylesVoice}>
																	200
																</SliderMark>
																<SliderMark value={300} {...labelStylesVoice}>
																	300
																</SliderMark>
																<SliderMark value={400} {...labelStylesVoice}>
																	400
																</SliderMark>
																<SliderMark value={500} {...labelStylesVoice}>
																	500
																</SliderMark>
																<SliderMark value={600} {...labelStylesVoice}>
																	600
																</SliderMark>
																<SliderTrack>
																	<SliderFilledTrack />
																</SliderTrack>
																<Tooltip
																	hasArrow
																	bg="teal.500"
																	color="white"
																	placement="top"
																	isOpen={showTooltip}
																	label={`${callTimeValue}`}>
																	<SliderThumb />
																</Tooltip>
															</Slider>
														</Flex>

													</Flex>
												)}
												{
													newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT && (
														<Box w="full" p={2} border="1px" borderColor="green.200" bg="green.100" borderRadius="lg" mt="4">
															<Text color="black">This LingoLink Type has no restrictions. It will be active as long as you do not disable it.</Text>
														</Box>
													)
												}
												{
													newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION && (
														<Flex w="full" p={3} mt={3} direction="column">
															<Flex w="full" direction="column">
																<Flex w="full" justifyContent="space-between">
																	<FormControl w="45%" color={blackWhiteColor}>
																		<FormLabel w="fit-content" htmlFor="startDate" >Start Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																		{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																		<Flatpickr options={{
																			enableTime: true,
																			dateFormat: "Y-m-d H:i",
																			minuteIncrement: 15,
																			minDate: 'today',
																		}}
																			altInputClass="flat-picker"
																			name="link_start_date_time"
																			onBlur={(e) => {
																				handleBlur(e.target.name)
																			}}
																			value={linkStartDateTime}

																			onChange={value => {setLinkStartDatetime(value[0]) }}
																			style={DatetimePickerStyles}
																			placeholder="Select Date Range"
																			id="startDate"
																		/>
																	</FormControl>
																	<FormControl w="45%">
																		<FormLabel w="fit-content" htmlFor="endDate" >End Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																		{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																		<Flatpickr options={{
																			enableTime: true,
																			dateFormat: "Y-m-d H:i",
																			minuteIncrement: 15,
																			minDate: 'today',
																		}}
																			onBlur={e => handleBlur(e.target.name)}
																			name="link_end_date_time"
																			value={linkEndDateTime}
																			onChange={value => (setLinkEndDateTime(value[0]))}
																			style={DatetimePickerStyles}
																			className="flatpickr-input"
																			placeholder="Select Date Range"
																			id="endDate"
																		/>
																	</FormControl>
																</Flex>
																{errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
															</Flex>
															<Flex
																mt="3"
																w={"100%"}
																h={"140px"}
																direction={"column"}
																py={1}
																px={5}
																rounded={"md"}
																borderWidth={"0.5px"}
																borderColor={"gray.200"}>
																<Text>
																	Maximum minutes available in this call: <b>{isCallDurationUnlimited ? 'unlimited' : callTimeValue}</b>{" "}
																	minutes.
																</Text>
																<Checkbox mt='2' isChecked={isCallDurationUnlimited} onChange={e => setIsCallDurationUnlimited(!isCallDurationUnlimited)}>Make it unlimited</Checkbox>
																<Slider
																	isDisabled={isCallDurationUnlimited}
																	mt={5}
																	aria-label="slider-ex-1"
																	colorScheme="teal"
																	min={5}
																	max={600}
																	step={5}
																	defaultValue={5}
																	onChange={v => setCallTimeValue(v)}
																	onMouseEnter={() => setShowTooltip(true)}
																	onMouseLeave={() => setShowTooltip(false)}>
																	<SliderMark value={5} {...labelStylesVoice}>
																		5
																	</SliderMark>
																	<SliderMark value={100} {...labelStylesVoice}>
																		100
																	</SliderMark>
																	<SliderMark value={200} {...labelStylesVoice}>
																		200
																	</SliderMark>
																	<SliderMark value={300} {...labelStylesVoice}>
																		300
																	</SliderMark>
																	<SliderMark value={400} {...labelStylesVoice}>
																		400
																	</SliderMark>
																	<SliderMark value={500} {...labelStylesVoice}>
																		500
																	</SliderMark>
																	<SliderMark value={600} {...labelStylesVoice}>
																		600
																	</SliderMark>
																	<SliderTrack>
																		<SliderFilledTrack />
																	</SliderTrack>
																	<Tooltip
																		hasArrow
																		bg="teal.500"
																		color="white"
																		placement="top"
																		isOpen={showTooltip}
																		label={`${callTimeValue}`}>
																		<SliderThumb />
																	</Tooltip>
																</Slider>
															</Flex>
														</Flex>
													)
												}
											</Flex>
											
										</Flex>}
										<Flex w={'100%'} rounded={'md'}
												mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
												<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
												<CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
													<SimpleGrid columns={2} gap="2px" mt="2" >
														<Checkbox value='hi' display={subAppDetails.includes('hi') ? 'flex' : 'none'}>
															<Flex alignItem='center'>
																<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={HumanAIBg} />
																<Text as="span">
																	Human Interpretation
																</Text>
															</Flex>
														</Checkbox>
														<Checkbox value='bi' display={subAppDetails.includes('bi') ? 'flex' : 'none'} ml={2}>
															<Flex alignItem='center'>
																<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={BasicAIBg} />
																<Text as="span" >
																	Secure AI
																</Text>
															</Flex>
														</Checkbox>
														<Checkbox value='pi' display={subAppDetails.includes('pi') ? 'flex' : 'none'}>
															<Flex alignItem='center'>
																<Image h="40px" w="40px" mx="1" borderRadius="50px" src={PremiumAIBg} />
																<Text as="span" >
																	Premium AI
																</Text>
															</Flex>
														</Checkbox>
														<Checkbox value='di' display={subAppDetails.includes('di') ? 'flex' : 'none'} ml={2}>
															<Flex alignItem='center'>
																<Image h="40px" w="40px" mx="1" borderRadius="50px" src={DocumentAiLightBg} />
																<Text as="span">
																	Document AI
																</Text>
															</Flex>
														</Checkbox>
													</SimpleGrid>
												</CheckboxGroup>
											</Flex>
											{langPrefList.length ? <Flex w={'100%'} rounded={'md'}
									mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
									<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select Language Preference</Text>
									<Select defaultValue={"none"} value={selectedLangPref} onChange={e=>setSelectedLangPref(e.target.value)}>
											<option style={{
															color:blackWhiteColor
														}} value={"none"} >Select language preference</option>
												{
													langPrefList.map(pref=>(
														<option style={{
															color:blackWhiteColor
														}} value={pref.language_preference_uuid} key={pref?.language_preference_uuid}>{pref?.language_preference_name}</option>
													))
												}
									</Select>
									
								</Flex> : null}
								</Flex>
							</DrawerBody>
							<DrawerFooter>
								<Flex w={"100%"} direction={"row"}>
									<Button
										variant="outline"
										mr={3}
										onClick={() => {
											onClose();
											resetProjectDetails();
										}}>
										Cancel
									</Button>
									<Button
										colorScheme="blue"
										onClick={() => {
											// resetProjectDetails();
											createDynaLink();
										}}
										isDisabled={
											!linkTitle || !selectedValues.length || saveDetailsLoader
										}
										isLoading={saveDetailsLoader ? true : false}>
										{"Create New Link"}
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					) : (<Box p={2} bg="yellow.300">
						<Text p={1} fontSize={'xl'} fontWeight={'600'}>LingoLink Application Access Error</Text>
						<Text p="2">Your account admin has not enabled anu LingoApp access for you. Thats why you are unable to create a LingoLink.</Text>
						<Text p="2">Please contact your Account Administrator and request them to give you LingoAll access so you can create, consume and share LingoLinks.</Text>
						<Text p="2">Thank you.</Text>
					</Box>)}
				</DrawerContent>
			</Drawer>

			{dynamicLinks.length ? <Pagination currentPage={1} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} recordsPerPage={recordsPerPage} /> : null}

		</>
	);
}

export default LinkCustomerLinksListing;
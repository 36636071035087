// Chakra Imports
import React from "react";
import {
  Select,
  Flex,
  FormLabel,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  Textarea,
} from "@chakra-ui/react";

function WorkflowServiceModal(props) {
  const btnRef = React.useRef();
  const { buttonName } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sliderValue, setSliderValue] = React.useState(100);
  const [showTooltip, setShowTooltip] = React.useState(true);

  //   Todo:Slider Functionality
  const renderSlider = () => {
    return (
      <Slider
        id="slider"
        defaultValue={200}
        min={0}
        max={500}
        colorScheme="teal"
        onChange={(v) => setSliderValue(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderMark value={0} mt="1" ml="-2.5" fontSize="sm">
          0%
        </SliderMark>

        <SliderMark value={200} mt="1" ml="-2.5" fontSize="sm">
          200%
        </SliderMark>

        <SliderMark value={500} mt="1" ml="-10" fontSize="sm">
          500%
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="teal.500"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={`${sliderValue}%`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    );
  };
  return (
    <>
      <Button
        ref={btnRef}
        colorScheme={buttonName === "Edit" ? "blue" : "gray"}
        size={buttonName === "Edit" ? "sm" : "md"}
        onClick={onOpen}
      >
        {buttonName}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth={"0.5px"}>
            {buttonName === "Edit" ? "Update" : "Add New"} Service
          </DrawerHeader>

          <DrawerBody mt="4">
            <Flex alignItems="center">
              <FormLabel w="30%">Provider</FormLabel>
              <Select placeholder="Select Provider">
                <option value="Provider1">Provider 1</option>
                <option value="Provider2">Provider 2</option>
                <option value="Provider3">Provider 3</option>
              </Select>
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Service Name</FormLabel>
              <Input placeholder="Enter Service Name" />
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Service ID</FormLabel>
              <Input placeholder="Enter Service ID" />
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Source Language</FormLabel>
              <Select value="SSL">
                <option value="SSL" disabled>
                  Select Source Language
                </option>
                <option value="SL1">Source Language 1</option>
                <option value="SL2">Source Language 2</option>
                <option value="SL3">Source Language 3</option>
              </Select>
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Target Language</FormLabel>
              <Select value="STL">
                <option value="STL" disabled>
                  Select Target Language
                </option>
                <option value="TL1">Target Language 1</option>
                <option value="TL2">Target Language 2</option>
                <option value="TL3">Target Language 3</option>
              </Select>
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Unit</FormLabel>
              <Select value="SU">
                <option value="SU" disabled>
                  Select Unit
                </option>
                <option value="U1">Unit 1</option>
                <option value="U2">Unit 2</option>
                <option value="U3">Unit 3</option>
              </Select>
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Price</FormLabel>
              <Input placeholder="Enter Price" type="number" min="0" />
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Quantity</FormLabel>
              <Input placeholder="Enter Quantity" type="number" min="0" />
            </Flex>

            <Flex alignItems="center" mt="4">
              <FormLabel w="30%">Currency</FormLabel>
              <Select value="SC">
                <option value="SC" disabled>
                  Select Currency
                </option>
                <option value="C1">Currency 1</option>
                <option value="C2">Currency 2</option>
                <option value="C3">Currency 3</option>
              </Select>
            </Flex>

            <Flex alignItems="center" mt="6">
              <FormLabel w="30%">Overhead %</FormLabel>
              {renderSlider()}
            </Flex>

            <Flex mt="8">
              <FormLabel w="30%">Overhead Info</FormLabel>
              <Textarea placeholder="Enter Overhead Info" />
            </Flex>
          </DrawerBody>

          <DrawerFooter borderTopWidth={"0.5px"}>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">
              {buttonName === "Edit" ? "Update" : "Save"} Service
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default WorkflowServiceModal;

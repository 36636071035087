// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner, Select, Tr, Td, InputGroup, InputLeftAddon, TableContainer, Input, Table, Thead, Th, Tbody, Heading, Menu, MenuButton, Button, MenuList, MenuItem, Tabs, TabList, Tab, TabPanels, TabPanel, Icon, useColorModeValue } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle, AiOutlineSearch } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg, useGreenBg, useNoDataBandBg, useRedBg, useTextColor } from "../../../../theme/globalColorTheme";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useEffect } from "react";
import { fetchAccountEvents, fetchLocationsAndSubs } from "../../../../services/accountServices";
import { format } from "date-fns";
import { memo } from "react";
import moment from 'moment'
import { ChevronDownIcon } from "@chakra-ui/icons";
import Card from "../../../../components/card/Card";
import Pagination from "../../../../components/pagination/Pagination";
import LocationAndSubscribers from "../components/LocationAndSubscribers";
import { LOC_EVENT_TAB } from "./constants";

function LocationEventsTab({ locationList, setLocationList, locationLoading }) {
    const brandBg = useBrandBg();

    const redBg = useRedBg()
    const greenBg = useGreenBg()
    const [eventValue, setEventValue] = useState("");
    const [eventsList, setEventsList] = useState([]);
    const [cloneEventList, setCloneEventList] = useState([])
    const [eventLoader, setEventLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [loading, isLoading] = useState(false)
    const [emailColFilter, setEmailColFilter] = useState('')
    const [routeColFilter, setRouteColFilter] = useState('')
    const [statusColFilter, setStatusColFilter] = useState('')
    const [tableHeaders, setTableHeaders] = useState({ Email: [], Route: [], Status: [], Date: [] })
    const [filteredEventsData, setFilteredEventsData] = useState([])
    const [subscriber, setSubscriber] = useState(null)
    const [selectedDates, setSelectedDates] = useState([new Date("05-01-2024"), new Date()])
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(false);
    const [duration, setDuration] = useState(LOC_EVENT_TAB.DURATION.TODAY)
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
    const blackWhiteColor = useTextColor()


    const handleUpdateColumnfilterOptions = () => {
        const newUniqueRouteTypes = new Set();
        const newUniqueEmails = new Set()
        const newUniqueStatus = new Set()

        filteredEventsData?.forEach(eventItem => {
            newUniqueRouteTypes.add(eventItem?.event_route);
            newUniqueEmails.add(eventItem?.event_caller)
            if (!eventItem.event_status) {
                newUniqueStatus.add('Fail')
            } else {
                newUniqueStatus.add('Success')
            }
        });
        setTableHeaders({ Email: [...newUniqueEmails], Route: [...newUniqueRouteTypes], Status: [...newUniqueStatus], Date: [] })
    }

    const handleUpdateFiltereEventdData = () => {
        const filteredEventData = eventsList[0]?.events?.filter(
            event => (
                (emailColFilter === '' || event?.event_caller.includes(emailColFilter))
                && (routeColFilter === '' || event?.event_route.includes(routeColFilter))
                && (statusColFilter === '' || String(event?.event_status) === statusColFilter)))
        setFilteredEventsData(
            filteredEventData
        )
    }

    const renderTableFilter = (placeholder, options) => {
        if (placeholder === "Date") {
            return (<RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />)
        }
        if (options.length) {
            if (placeholder === 'Status') {
                return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                    {options?.map(option => <option key={option} value={option === 'Success'}>{option}</option>)}
                </Select>)
            }
            return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                {options?.map(option => <option key={option} value={option}>{option}</option>)}
            </Select>)
        }
    }

    const handleColumnFilterChange = (value, col) => {
        switch (col) {
            case "Email":
                setEmailColFilter(value)
                break;
            case "Route":
                setRouteColFilter(value)
                break
            case "Status":
                setStatusColFilter(value)
                break
            default:
                break;
        }
    }

    useEffect(() => {
        handleUpdateColumnfilterOptions()
    }, [filteredEventsData])

    useEffect(() => {
        handleUpdateFiltereEventdData()
    }, [emailColFilter, statusColFilter, routeColFilter, eventsList])



    useEffect(() => {
        collectEventsData(subscriber?.location?.link_location_uuid, subscriber?.subscriber?.sub_uuid);
    }, [currentPage, recordsPerPage, subscriber]);


    function onSubscriberClick(locationData, subsData) {
        setSubscriber({
            location: locationData,
            subscriber: subsData
        })
    }

    const collectEventsData = (locationUuid, subscriberUuid) => {
        if (locationUuid != null && subscriberUuid != null) {
            setEventLoader(true)
            let paramObj = {
                location_uuid: locationUuid,
                subscriber_uuid: subscriberUuid,
                "filter_key": "string",
                "filter_value": "string",
                "filter_choice": duration,
                "start_date": format(selectedDates[0], 'dd-MM-yyyy'),
                "end_date": format(selectedDates[1], 'dd-MM-yyyy'),
                "duration": "all",
                page_id: currentPage,
                limit: recordsPerPage
            };
            fetchAccountEvents(paramObj)
                .then(res => {
                    setEventLoader(false)
                    if (res?.data?.length) {
                        isLoading(false);
                        setEmailColFilter('')
                        setStatusColFilter('')
                        setRouteColFilter('')
                        setEventsList(res?.data);
                        setCloneEventList(res?.data[0]?.events)
                        setTotalPages(res?.data[0]?.total_pages)
                    } else {
                        setEventsList([]);
                        setCloneEventList([]);
                    }
                })
                .catch(err => {
                    setEventLoader(false)
                    setLocationList([]);
                    setEventsList([]);
                    setCloneEventList([]);
                });
        }
    };

    useEffect(() => {
        if (eventValue?.length) {
            const filteredEvents = eventsList.filter(event => {
                return Object.values(event).some(value =>
                    String(value).toLowerCase().includes(eventValue.toLowerCase())
                );
            });
            setCloneEventList(filteredEvents)
        }
        else {
            setCloneEventList(eventsList)
        }
    }, [eventValue])

    const RenderEventsList = memo(() => {
        return (
            filteredEventsData !== null && filteredEventsData?.length > 0 ? (
                filteredEventsData?.map((eventItem, index) => (
                    <Tr key={index + eventItem?.datetime_created}>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
                        <Td color='black' bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
                    </Tr>
                ))
            ) : (
                null
            )
        )
    })

    return (
        <Flex h='calc(100vh - 185px)' w="full">
            <Flex
                flex="1"
                bg={brandBg}
                borderRadius={"10px"}
                h="full"
                p={1}
                w="72%"
            >
                <Box w={!configurationCollapseFlag ? "95%" : "80%"}>
                    <Card p='2' h="full" w="full">
                        <Flex mb="3" alignItems="center" justifyContent={'space-between'}>
                            <Text fontWeight={'bold'} ml={1} >Events</Text>
                            <InputGroup w="40%">
                                <InputLeftAddon><AiOutlineSearch /></InputLeftAddon>
                                <Input w="95%" placeholder='Search...' value={eventValue} onChange={(e) => { setEventValue(e.target.value) }} color={blackWhiteColor} />
                            </InputGroup>

                        </Flex>
                        {eventLoader ? (
                            <Flex alignItems="center" justifyContent="center" h="50vh">
                                <Spinner size="lg" />
                            </Flex>
                        ) :
                            cloneEventList?.length ? (
                                <Flex direction="column" gap='2' h="full" overflow="hidden">
                                    <TableContainer border="1px solid #d7d7d7" borderRadius="5px" h="83%" overflowY="auto" overflowX="auto" className="custom-scrollbar">
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                    {Object.keys(tableHeaders).map(header => (
                                                        <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                            {header}
                                                        </Th>
                                                    ))}
                                                </Tr>
                                                <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                    {Object.entries(tableHeaders).map(([header, options]) => (
                                                        <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                            {renderTableFilter(header, options)}
                                                        </Th>
                                                    ))}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <RenderEventsList />

                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                    {cloneEventList?.length ? (<Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage} />) : null}
                                </Flex>
                            ) : <Flex w={"100%"} p={5} bg={useNoDataBandBg} borderLeftWidth={"10px"}
                                borderLeftColor={"red.500"}>
                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                    No Event Data based on selection..
                                </Heading>
                            </Flex>}
                    </Card>
                </Box>
                {configurationCollapseFlag ? (
                    <Box
                        h="98%"
                        w="20%"
                        position="relative"
                        borderRadius="15px"
                        bg={brandBg}
                        ml="2"
                    >
                        <Flex w={'100%'}
                            p={2}
                            h="8%"
                            //bg={'gray.100'}
                            borderBottomWidth={'0.5px'}
                            borderBottomColor={'gray.300'}
                        >
                            <Box w={'80%'}>
                                <Text fontWeight="bold">Filters</Text>
                            </Box>
                            <Box
                                align={'end'}
                                w={'20%'}
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                        </Flex>
                        <Box h="92%" bg={brandBg}>
                            <Card h="99%"  p={1} mt={2}>
                                <Flex flexDir="column" w="full" alignItems="center" h="90%" overflowY="auto">
                                    <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                        <Text fontWeight="bold" mb="3">Duration</Text>
                                        <Flex alignItems="center" w="100%">
                                            <Box
                                                h="full"
                                                p={1}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedLeft={"lg"}
                                                bg={duration === LOC_EVENT_TAB.DURATION.TODAY ? "green.600" : useGreenBg}
                                                color={duration === LOC_EVENT_TAB.DURATION.TODAY ? "white" : "inherit"}
                                                size="sm"
                                                fontSize="sm"
                                                onClick={() => { setDuration(LOC_EVENT_TAB.DURATION.TODAY) }}
                                            >
                                                {duration === LOC_EVENT_TAB.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

                                                Today
                                            </Box>
                                            <Box
                                                h="full"
                                                p={1}
                                                borderRight="1px"
                                                borderLeft="1px"
                                                borderColor="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                bg={duration === LOC_EVENT_TAB.DURATION.WEEK ? "green.600" : useGreenBg}
                                                color={duration === LOC_EVENT_TAB.DURATION.WEEK ? "white" : "inherit"}
                                                size="sm"
                                                fontSize="sm"
                                                onClick={() => { setDuration(LOC_EVENT_TAB.DURATION.WEEK) }}
                                            >
                                                {duration === LOC_EVENT_TAB.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                This Week
                                            </Box>
                                            <Box
                                                p={1}
                                                h="full"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedRight={"lg"}
                                                fontSize="sm"
                                                bg={duration === LOC_EVENT_TAB.DURATION.MONTH ? "green.600" : useGreenBg}
                                                color={duration === LOC_EVENT_TAB.DURATION.MONTH ? "white" : "inherit"}
                                                size="sm"
                                                onClick={() => { setDuration(LOC_EVENT_TAB.DURATION.MONTH) }}
                                            >
                                                {duration === LOC_EVENT_TAB.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                This Month
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Button size={'sm'} ml={1}
                                    onClick={() => collectEventsData(subscriber?.location?.link_location_uuid, subscriber?.subscriber?.sub_uuid)}
                                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                    bg={pillButtonBg}>Get History</Button>
                            </Card>
                        </Box>

                    </Box>
                ) : (
                    <Card
                        w="5%"
                        p="3"
                        borderRadius="10px"
                        h="100%"
                        ml="2"
                        pl="5"
                    >
                        <Flex w={"100%"} h="100%" direction="column" alignItems="center">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(
                                        !configurationCollapseFlag
                                    );
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}
                            >
                                Expand Report Settings
                            </Text>
                        </Flex>
                    </Card>
                )}
            </Flex>
            <LocationAndSubscribers loading={locationLoading} locationList={locationList} setLocationList={setLocationList} subsId={subscriber?.subscriber?.user_uuid} onClick={onSubscriberClick} />

        </Flex>
    );
}

export default LocationEventsTab;
